import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'

const dropdownContainer = theme => css`
  width: ${rem(258)};
  color: ${theme.color.info['900']};

  [role='menuitem'] {
    padding-top: ${rem(8)};
    padding-bottom: ${rem(8)};
  }

  header {
    [role='separator'] {
      opacity: 0;
      margin-bottom: 0;
    }
  }

  footer {
    [role='separator'] {
      margin-top: 0;
    }
  }

  .footer {
    padding-left: ${rem(2)};
    display: flex;
    button {
      border-radius: ${rem(6)};
      & > * {
        white-space: nowrap;
      }
    }
    button:nth-of-type(2) {
      color: inherit;
    }
  }
`

const checkbox = css`
  flex-grow: 1;
  overflow: hidden;
  label {
    align-items: center;
  }
`

const dropdownBtn = css`
  min-width: unset;
`

export const styles = {
  dropdownContainer,
  checkbox,
  dropdownBtn,
}
