import { css } from '@emotion/react'

const fullWidth = css`
  max-width: 100%;
`

const colorLabel = css`
  & + * {
    width: 100%;
  }
`

const popoverContainer = css`
  left: 0 !important;
  right: unset !important;
`

export const styles = {
  fullWidth,
  colorLabel,
  popoverContainer,
}
