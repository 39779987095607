import { CONNECT_CHANNEL_SUCCESS } from './types'

const defaultState = {}
const reducers = {}

reducers[CONNECT_CHANNEL_SUCCESS] = (state, action) => {
  switch (action.payload.type) {
    case 'facebook':
      // TODO (jscheel): Lame, but good enough for now. We will need to bootstrap this as well.
      return {
        ...state,
        facebookConnected: true,
      }
    default:
      return state
  }
}

export default function channelReducer(state = defaultState, action) {
  const handler = reducers[action.type]
  if (handler) return handler(state, action)
  return state
}
