import { PureComponent } from 'react'
import { connect } from 'react-redux'
import { redirect } from 'redux-first-router'

import {
  selectDidError,
  selectIntegrationSettingsForId,
} from 'ducks/integrations/selectors'
import { doUpdateIntegrationProviderSettings } from 'ducks/integrations/operations/doUpdateIntegrationProviderSettings'
import { doRemoveIntegration } from 'ducks/integrations/operations/doRemoveIntegration'
import {
  selectContactProperties,
  selectCompanyProperties,
  selectDealProperties,
} from 'ducks/integrations/hubspot/selectors'
import { selectMailboxesIncludingInaccessible } from 'selectors/mailboxes/selectMailboxesIncludingInaccessible'
import doFetchContactProperties from 'ducks/integrations/hubspot/operations/doFetchContactProperties'
import doFetchCompanyProperties from 'ducks/integrations/hubspot/operations/doFetchCompanyProperties'
import doFetchDealProperties from 'ducks/integrations/hubspot/operations/doFetchDealProperties'
import { selectCanUseFeature } from 'ducks/billing/selectors/features'
import { FEATURE_INBOX_PREMIUM_INTEGRATIONS } from 'ducks/billing/featureTypes'

import { SETTINGS_INTEGRATIONS_PAGE } from '../../../../types'
import SettingsModal from './SettingsModal'
import InstallModal from './InstallModal'

function select(state, initialOwnProps) {
  const { id } = initialOwnProps
  return {
    initialValues: selectIntegrationSettingsForId(state, id),
    mailboxes: selectMailboxesIncludingInaccessible(state),
    error: selectDidError(state),
    contactProperties: selectContactProperties(state),
    dealProperties: selectDealProperties(state),
    companyProperties: selectCompanyProperties(state),
    // this should be using the selectIntegrationData to figure out if it's a premium feature or not
    showPlanLimitation: !selectCanUseFeature(
      state,
      FEATURE_INBOX_PREMIUM_INTEGRATIONS
    ),
  }
}

const perform = (dispatch, { id }) => {
  function doCloseModal() {
    dispatch(redirect({ type: SETTINGS_INTEGRATIONS_PAGE }))
  }
  return {
    doFetchContactProperties: () => dispatch(doFetchContactProperties()),
    doFetchCompanyProperties: () => dispatch(doFetchCompanyProperties()),
    doFetchDealProperties: () => dispatch(doFetchDealProperties()),
    doCloseModal,
    doUpdateIntegrationProviderSettings: (provider, values) =>
      dispatch(doUpdateIntegrationProviderSettings(provider, values)),
    doRemoveIntegration: () => {
      doCloseModal()
      dispatch(doRemoveIntegration(id))
    },
  }
}

class Modal extends PureComponent {
  render = () => {
    const {
      props,
      props: { id },
    } = this
    if (id) return <SettingsModal {...props} />

    return <InstallModal {...props} />
  }
}
export default connect(select, perform)(Modal)
