import { css } from '@emotion/react'

const fieldContainer = css`
  max-width: none;
`

const messageCardContainer = css`
  &:empty {
    display: none;
  }
`

export const styles = {
  fieldContainer,
  messageCardContainer,
}
