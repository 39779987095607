import { combineReducers } from 'redux'

// This reducer factory just wraps Redux's combineReducers, but will
// remove any undefined reducers to silence warnings. This is for
// reducers that are undefined during SSR for example the persisted
// reducer.
export default function createCombinedReducer(reducers) {
  const filteredReducers = Object.keys(reducers).reduce((result, key) => {
    // eslint-disable-next-line no-param-reassign
    if (reducers[key] !== undefined) result[key] = reducers[key]
    return result
  }, {})
  return combineReducers(filteredReducers)
}
