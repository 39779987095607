import React, { useCallback, useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Dropdown from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import Search from '@groovehq/internal-design-system/lib/components/Search/Search'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'
import {
  queryStringToQueryId,
  queryIdToQuery,
} from 'ducks/searches/utils/query'
import { selectSearchEntitiesDenormalizedByQueryId } from 'ducks/searches/selectors'
import {
  selectCurrentTagById,
  selectCurrentTagByName,
} from 'ducks/tags/selectors'
import { doFetchTagsV2, doFetchTagsV2ByIdsOrNames } from 'ducks/tags/actions'
import { debounce } from 'util/functions'
import { camelize } from 'util/strings'
import { DRAWER_TYPE_TAGS_UPDATE } from 'ducks/drawers/types'
import { useDrawer } from 'ducks/drawers/hooks'
import { selectRequestByType } from 'ducks/requests/selectors'
import { FETCH_TAGS, FETCH_TAGS_BY_IDS_NAMES } from 'ducks/tags/actionTypes'
import TagDropdownMenu from './menu'
import TagDropdownIndicator from './indicator'
import { styles } from './styles'
import TagDropdownCreateButton from './createButton'

const { Button } = Dropdown

const defaultQueryId = 'entityType:tag pageSize:20 cursor:1'
const pleaseSelectTagOption = {
  name: 'Select tag',
  color: '#000000',
  conversationCount: null,
}

const loadingOption = {
  name: 'loading...',
  conversationCount: null,
}

const missingOption = {
  name: '⚠️ TAG DELETED',
  conversationCount: null,
  missing: true,
}

export default function TagDropdown(props) {
  const dispatch = useDispatch()
  const {
    onTagSelect,
    tagId: propTagId,
    tagName: propTagName,
    onCreateTag,
    searchDelayMs = 200,
    size = 'big',
    menuHeight = 400,
    hasMinWidth,
  } = props
  const [isReady, setIsReady] = useState(false)
  const [queryId, setQueryId] = useState(defaultQueryId)
  const [selectedTagId, setSelectedTagId] = useState(null)
  const [selectedTagName, setSelectedTagName] = useState(null)
  const { search = '' } =
    queryIdToQuery(queryId, { decodeUriKeys: ['search'] }) || {}
  const filteredTags = useSelector(state =>
    selectSearchEntitiesDenormalizedByQueryId(state, queryId)
  )
  const currentTagById = useSelector(state =>
    selectCurrentTagById(state, selectedTagId)
  )
  const currentTagByName = useSelector(state =>
    selectCurrentTagByName(state, selectedTagName)
  )

  const { loaded: isDoFetchTagsV2Loaded } = useSelector(state =>
    selectRequestByType(state, FETCH_TAGS)
  )

  // we need to ensure that there is a unique request type for each tag searched via doFetchTagsV2By in this component
  // that way each search by id or name has its own loaded/loading status (unique to the tag id or tag name).
  // there are no reducers for FETCH_TAGS_BY_IDS_NAMES as well so it's fine to have dynamic request type
  const fetchByIdOrNameRequestType = useMemo(
    () => {
      if (propTagId) {
        return `${FETCH_TAGS_BY_IDS_NAMES}_${camelize(propTagId)}`
      } else if (propTagName) {
        return `${FETCH_TAGS_BY_IDS_NAMES}_${camelize(propTagName)}`
      }

      return FETCH_TAGS_BY_IDS_NAMES
    },
    [propTagId, propTagName]
  )

  const { loaded: isDoFetchTagsV2ByIdsNamesLoaded } = useSelector(state =>
    selectRequestByType(state, fetchByIdOrNameRequestType)
  )

  const hasTagProp = !!propTagId || !!propTagName

  const selectedTag = useMemo(
    () => {
      if (currentTagById) {
        return currentTagById
      } else if (currentTagByName) {
        return currentTagByName
      } else if (
        hasTagProp &&
        isDoFetchTagsV2Loaded &&
        isDoFetchTagsV2ByIdsNamesLoaded &&
        isReady
      ) {
        // we passed a tag id or name but no tag found (even with the backup search). tag is most likely deleted
        if (propTagName) {
          return {
            name: `⚠️ ${propTagName}`,
            conversationCount: null,
            missing: true,
          }
        }

        return missingOption
      } else if (hasTagProp) {
        // still loading
        return loadingOption
      }

      return pleaseSelectTagOption
    },
    [
      currentTagById,
      currentTagByName,
      hasTagProp,
      isDoFetchTagsV2Loaded,
      isDoFetchTagsV2ByIdsNamesLoaded,
      isReady,
      propTagName,
    ]
  )

  const isMissingFromDoFetchTagsV2 =
    !!isDoFetchTagsV2Loaded &&
    !selectedTag?.id &&
    (!!propTagId || !!propTagName)

  useEffect(
    () => {
      if (propTagId) {
        setSelectedTagId(propTagId)
      } else if (propTagName) {
        setSelectedTagName(propTagName)
      }
    },
    [propTagId, propTagName]
  )

  const doFetchTagsV2After = useMemo(
    () =>
      debounce(options => {
        dispatch(doFetchTagsV2(options))
      }, searchDelayMs),
    [dispatch, searchDelayMs]
  )

  useEffect(
    () => {
      async function fetchData() {
        await doFetchTagsV2After({ queryId, skipLoaded: true })
        if (!isReady) setIsReady(true)
      }
      fetchData()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, queryId, doFetchTagsV2After]
  )

  useEffect(
    () => {
      // because we do not load ALL tags from doFetchTagsV2/bootstrap API call,
      // there is a chance the selected tag is not loaded in the redux store
      // run an additional API request to fetch the missing tag by id or name depending on prop (only if indeed missing)
      if (!hasTagProp) return // no need to fetch if we're load loading a tag
      if (!isMissingFromDoFetchTagsV2 || !!isDoFetchTagsV2ByIdsNamesLoaded)
        return

      const options = { requestType: fetchByIdOrNameRequestType }
      const filterValues = []
      if (propTagId) {
        filterValues.push(propTagId)
      } else if (propTagName) {
        filterValues.push(propTagName)
      }
      dispatch(doFetchTagsV2ByIdsOrNames(filterValues, options))
    },
    [
      isMissingFromDoFetchTagsV2,
      propTagId,
      propTagName,
      hasTagProp,
      isDoFetchTagsV2ByIdsNamesLoaded,
      fetchByIdOrNameRequestType,
      dispatch,
    ]
  )

  const handleChangeSearch = useCallback(({ target }) => {
    const defaultQuery = queryIdToQuery(defaultQueryId)
    setQueryId(
      queryStringToQueryId(
        {
          ...defaultQuery,
          search: target.value,
        },
        { encodeUriKeys: ['search'] }
      )
    )
  }, [])

  useEffect(
    () => {
      if (isReady && selectedTag?.id && onTagSelect) {
        onTagSelect(selectedTag)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedTag]
  )

  return (
    <Dropdown
      overlay={<TagDropdownMenu tags={filteredTags} />}
      onSelect={setSelectedTagId}
      menuHeight={menuHeight}
      selectedKey={selectedTagId}
      hasMinWidth={hasMinWidth}
      header={
        <div css={styles.fullWith}>
          <Search
            name="search"
            placeholder="Search all tags..."
            onChange={handleChangeSearch}
            value={search}
            css={[styles.fullWith, styles.hackPadding]}
          />
          <TagDropdownCreateButton onCreateTag={onCreateTag} />
        </div>
      }
    >
      {/* deliberately have a wrapper here because PopperJS throws an error if Tooltip is the immediate child */}
      <span>
        <Tooltip
          title="No matching tag found for this name"
          position="bottom"
          disabled={!selectedTag?.missing}
          strategy="fixed"
        >
          <Button className="grui w-100" css={styles.dropdownBtn} size={size}>
            <TagDropdownIndicator {...selectedTag} />
          </Button>
        </Tooltip>
      </span>
    </Dropdown>
  )
}

export function TagDropdownWithDrawerCreate({ onCreateTag, ...rest }) {
  const { openDrawer: openEditDrawer } = useDrawer(DRAWER_TYPE_TAGS_UPDATE)
  const handleOnCreateTag = useCallback(
    () => {
      openEditDrawer('new')
    },
    [openEditDrawer]
  )

  return <TagDropdown onCreateTag={handleOnCreateTag} {...rest} />
}
