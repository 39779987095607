import React from 'react'
import { connect } from 'react-redux'

import { selectCurrentProviderTab } from 'ducks/integrations/selectors'

import InstallModal from './InstallModal'
import SettingsModal from './SettingsModal'

export default connect(state => ({
  tab: selectCurrentProviderTab(state),
}))(props => {
  const { id, tab } = props
  if (id || tab) return <SettingsModal {...props} />
  return <InstallModal {...props} />
})
