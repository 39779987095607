import { connect } from 'react-redux'

import { doOpenForwardPage } from 'actions/pages'
import {
  doMarkAsUnread,
  doMarkAsRead,
  doCurrentMailboxChanged,
  doToggleTicketAsSpam,
  doDeleteCurrentTicket,
  doRestoreTicket,
  doPrintWholeTicket,
  doOpenTicketMergeDrawer,
} from 'actions/tickets'

import { selectPossibleMailboxesForChange } from 'selectors/mailboxes'
import {
  selectCurrentTicketIsUnread,
  selectCurrentTicketIsSpam,
  selectCurrentTicketIsDeleted,
  selectCurrentTicketIsFollowing,
} from 'selectors/tickets'

import {
  doFollowCurrentTicket,
  doUnfollowCurrentTicket,
} from 'ducks/ticketing/operations/ticketFollow'

import MainDropdown from './view'

const select = state => ({
  isDeleted: selectCurrentTicketIsDeleted(state),
  isSpam: selectCurrentTicketIsSpam(state),
  isUnread: selectCurrentTicketIsUnread(state),
  isFollowing: selectCurrentTicketIsFollowing(state),
  mailboxes: selectPossibleMailboxesForChange(state),
})

const perform = dispatch => ({
  doToggleTicketAsSpam: ticketId => dispatch(doToggleTicketAsSpam(ticketId)),
  doMarkAsRead: ticketId => dispatch(doMarkAsRead(ticketId)),
  doMarkAsUnread: ticketId => dispatch(doMarkAsUnread(ticketId)),
  doChangeMailbox: (ticketId, mailboxId) =>
    dispatch(doCurrentMailboxChanged(ticketId, mailboxId)),
  doDeleteTicket: ({ deleteMode = 'SOFT' } = {}) =>
    dispatch(doDeleteCurrentTicket(deleteMode)),
  doRestoreTicket: ticketId => dispatch(doRestoreTicket(ticketId)),
  doFollowTicket: trackType => dispatch(doFollowCurrentTicket({ trackType })),
  doUnfollowTicket: trackType =>
    dispatch(doUnfollowCurrentTicket({ trackType })),
  doOpenForwardTicketPage: ticketId => {
    return dispatch(doOpenForwardPage(ticketId))
  },
  doPrintWholeTicket: ticketId => {
    return dispatch(doPrintWholeTicket(ticketId))
  },
  doOpenTicketMergeDrawer: ticketId => {
    return dispatch(doOpenTicketMergeDrawer(ticketId))
  },
})

export default connect(select, perform)(MainDropdown)
