import deepEqual from 'fast-deep-equal'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { all, any } from 'util/arrays'
import { promisify } from 'util/promises'
import { useDelayedFunction } from 'util/hooks/timeouts'

export const SUBMIT_PENDING = 'SUBMIT_PENDING'
export const SUBMIT_SUCCEEDED = 'SUBMIT_SUCCEEDED'
export const SUBMIT_FAILED = 'SUBMIT_FAILED'

export function useValidation({
  dynamicValidators,
  propValidators,
  staticValidators,
}) {
  // State
  const [errors, doSetErrors] = useState({})
  const doSetErrorsIfDifferent = useCallback(
    newErrors => {
      doSetErrors(oldErrors => {
        const calculatedNewErrors =
          typeof newErrors === 'function' ? newErrors(oldErrors) : newErrors
        const newErrorsKeys = Object.keys(calculatedNewErrors)
        if (
          Object.values(oldErrors).length !== newErrorsKeys.length ||
          newErrorsKeys.reduce((result, key) => {
            return result || calculatedNewErrors[key] !== oldErrors[key]
          }, false)
        ) {
          return calculatedNewErrors
        }
        return oldErrors
      })
    },
    [doSetErrors]
  )

  // Derived state
  const isValid = useMemo(() => all(error => !error, Object.values(errors)), [
    errors,
  ])
  const isValidating = useMemo(
    () => {
      return any(error => {
        const { then } = error || {}
        return !!then
      }, Object.values(errors))
    },
    [errors]
  )
  const validators = useMemo(
    () => {
      return (
        propValidators || {
          ...dynamicValidators,
          ...staticValidators,
        }
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [propValidators, dynamicValidators]
  )

  // State management
  const doResetErrors = useCallback(() => doSetErrorsIfDifferent({}), [
    doSetErrorsIfDifferent,
  ])
  const doSetError = useCallback(
    (key, value) => {
      doSetErrorsIfDifferent(currentErrors => {
        const calculatedValue =
          typeof value === 'function' ? value(currentErrors[key]) : value
        if (currentErrors[key] !== value) {
          return { ...currentErrors, [key]: calculatedValue }
        }
        return currentErrors
      })
    },
    [doSetErrorsIfDifferent]
  )
  const doValidate = useCallback(
    values => {
      const newErrors = Object.keys(validators).reduce((result, key) => {
        const validator = validators[key]
        const validationResult = validator(values[key], values)
        if (validationResult && validationResult.then) {
          // eslint-disable-next-line no-param-reassign
          result[key] = validationResult
          validationResult
            .then(promiseResult => {
              doSetError(key, currentValue => {
                // Check this is the latest promise
                return currentValue === validationResult
                  ? promiseResult
                  : currentValue
              })
            })
            .catch(error => {
              if (error && error.message) {
                doSetError(key, error.message)
              } else if (typeof error === 'string') {
                doSetError(key, error)
              } else {
                doSetError(key, 'Unable to validate')
              }
            })
        } else {
          // eslint-disable-next-line no-param-reassign
          result[key] = validationResult
        }
        return result
      }, {})

      doSetErrorsIfDifferent(newErrors)
      return newErrors
    },
    [doSetError, doSetErrorsIfDifferent, validators]
  )

  return {
    doValidate,
    doSetErrors,
    doResetErrors,
    errors,
    isValid,
    isValidating,
  }
}

export function useFormValues({
  delayReset = 0,
  initialValues = {},
  onChange,
  onResetForm,
  onSubmit,
  onSubmitted,
  resetOnSubmit = true,
}) {
  // State
  const [values, doSetValues] = useState(initialValues)
  const [changedValues, doSetChangedValues] = useState({})
  const [hasBeenSubmitted, doSetHasBeenSubmitted] = useState(null)

  // Derived state
  const currentlyChangedValues = useMemo(
    () => {
      return Object.keys(initialValues).reduce((result, key) => {
        // eslint-disable-next-line no-param-reassign
        result[key] = initialValues[key] !== values[key]
        return result
      }, {})
    },
    [initialValues, values]
  )
  const hasChanged = useMemo(
    () => {
      return any(x => !!x, Object.values(changedValues))
    },
    [changedValues]
  )
  const isChanged = useMemo(
    () => {
      return any(x => !!x, Object.values(currentlyChangedValues))
    },
    [currentlyChangedValues]
  )

  // State management
  const doSetValue = useCallback(
    (key, value) => {
      doSetValues(currentValues => {
        const calculatedValue =
          typeof value === 'function' ? value(currentValues[key]) : value
        if (currentValues[key] === calculatedValue) {
          return currentValues
        }
        const newValues = {
          ...currentValues,
          [key]: calculatedValue,
        }
        return newValues
      })
    },
    [doSetValues]
  )
  const doResetValues = useCallback(
    () => {
      doSetValues(initialValues)
    },
    [initialValues]
  )
  const doResetForm = useCallback(
    () => {
      if (onResetForm) onResetForm()
      doResetValues()
      doSetChangedValues({})
      doSetHasBeenSubmitted(null)
    },
    [doResetValues, onResetForm, doSetChangedValues, doSetHasBeenSubmitted]
  )

  const delayedDoResetForm = useDelayedFunction(doResetForm, delayReset)
  useEffect(
    () => {
      doSetChangedValues(currentValue => {
        const newValue = Object.keys(values).reduce((result, key) => {
          // eslint-disable-next-line no-param-reassign
          result[key] =
            currentValue[key] || !deepEqual(values[key], initialValues[key])
          return result
        }, {})
        if (!deepEqual(newValue, currentValue)) return newValue
        return currentValue
      })
      return undefined
    },
    [initialValues, values]
  )
  useEffect(
    () => {
      if (onChange) onChange(values)
    },
    [onChange, values]
  )
  useEffect(
    () => {
      doResetForm()
    },
    [doResetForm]
  )

  // Event handlers
  const handleSubmitted = useCallback(
    () => {
      doSetHasBeenSubmitted(SUBMIT_SUCCEEDED)
      if (onSubmitted) onSubmitted(values)
      if (resetOnSubmit) delayedDoResetForm()
    },
    [
      doSetHasBeenSubmitted,
      delayedDoResetForm,
      onSubmitted,
      resetOnSubmit,
      values,
    ]
  )
  const handleSubmit = useCallback(
    () => {
      doSetHasBeenSubmitted(SUBMIT_PENDING)
      promisify(onSubmit, values)
        .then(handleSubmitted)
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error)
          doSetHasBeenSubmitted(SUBMIT_FAILED)
          // Do nothing for now, but maybe one day it will able to provide errors to supplement validation
        })
    },
    [handleSubmitted, onSubmit, values]
  )
  const handleChange = useCallback(
    (event, { name, value }) => {
      doSetValue(name, value)
    },
    [doSetValue]
  )

  return {
    hasBeenSubmitted,
    changedValues,
    currentlyChangedValues,
    doResetForm,
    doResetValues,
    doSetHasBeenSubmitted,
    doSetValue,
    doSetValues,
    hasChanged,
    isChanged,
    onChange: handleChange,
    onSubmit: handleSubmit,
    values,
  }
}

export function useValidatedFormValues({ onSubmit, onResetForm, ...options }) {
  // State
  // Validation
  const {
    doSetErrors,
    doResetErrors,
    doValidate,
    errors,
    isValid,
    isValidating,
  } = useValidation(options)

  // Handlers
  const handleSubmit = useCallback(
    values => {
      const results = doValidate(values)
      return Promise.all(
        Object.values(results).map(error => {
          return promisify(error)
        })
      ).then(result => {
        if (!all(x => !x, result)) {
          throw new Error('Exception prevents submition')
        } else if (onSubmit) {
          onSubmit()
        }
      })
    },
    [doValidate, onSubmit]
  )
  const handleFormReset = useCallback(
    () => {
      if (onResetForm) onResetForm()
      doResetErrors()
    },
    [doResetErrors, onResetForm]
  )

  // Form values
  const {
    hasBeenSubmitted,
    changedValues,
    currentlyChangedValues,
    doResetForm,
    doResetValues,
    doSetHasBeenSubmitted,
    doSetValue,
    doSetValues,
    hasChanged,
    isChanged,
    onChange: handleChange,
    onSubmit: composedHandleSubmit,
    values,
  } = useFormValues({
    ...options,
    onResetForm: handleFormReset,
    onSubmit: handleSubmit,
  })

  // Derived state
  const errorMessages = useMemo(
    () => {
      return Object.keys(errors).reduce((result, key) => {
        // eslint-disable-next-line no-param-reassign
        result[key] = (hasBeenSubmitted || changedValues[key]) && errors[key]
        return result
      }, {})
    },
    [hasBeenSubmitted, changedValues, errors]
  )
  const isDisplayingErrors = useMemo(
    () => any(error => !!error, Object.values(errorMessages)),
    [errorMessages]
  )

  // State management
  const doValidateValues = useCallback(
    () => {
      doValidate(values)
    },
    [doValidate, values]
  )

  // Effects
  useEffect(
    () => {
      doValidateValues()
      return undefined
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [doValidateValues]
  )

  return {
    hasBeenSubmitted,
    changedValues,
    currentlyChangedValues,
    doResetForm,
    doResetValues,
    doSetErrors,
    doSetHasBeenSubmitted,
    doSetValue,
    doSetValues,
    doValidateValues,
    errors,
    errorMessages,
    hasChanged,
    isChanged,
    isDisplayingErrors,
    isValid,
    isValidating,
    onChange: handleChange,
    onSubmit: composedHandleSubmit,
    values,
  }
}
