import { isAuthenticatedSelector, selectAutoLoginUrl } from 'selectors/app'
import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'
import storage from 'util/storage'
import { trackPage } from 'util/tracking'
import { doSetOnboardingCompleteFlag } from 'subapps/onboarding/actions'
import { capture, stopRecording } from 'ducks/tracking/actions'
import { doUpdateUserPreferences } from 'ducks/currentUser/operations'
import { doFetchBillingData } from 'ducks/billing/operations'
import { selectOnboardingLayoutVariant } from '../selectors'

export function doRedirectIfAuthenticated() {
  return (_, getState) => {
    const state = getState()
    if (isAuthenticatedSelector(state)) {
      window.location = '/'
    }
  }
}

export const doFinishOnboarding = () => async (dispatch, getState) => {
  // Note trackPage pulls in some basic billing data when sending off the tracking request
  // This data is usually part of our bootstrap, but during onboarding we'll need to
  // specifically load the data to make it avaialble
  await dispatch(doFetchBillingData())

  const state = getState()
  const currentUser = selectCurrentUser(state)
  const autoLoginUrl = selectAutoLoginUrl(state)
  const variant = selectOnboardingLayoutVariant(state)

  stopRecording()
  capture('user signed up')
  const promises = [
    trackPage(
      'v6-completion',
      'onboarding',
      currentUser?.id,
      undefined,
      undefined,
      undefined,
      variant
    ),
    dispatch(doSetOnboardingCompleteFlag()),
  ]
  if (window.innerWidth < 1200) {
    promises.push(dispatch(doUpdateUserPreferences({ classic_view: true })))
  }
  await Promise.all(promises)
  if (storage.isAvailable()) {
    storage.cleanupAppStorage()
  }
  window.location = autoLoginUrl
}
