// Default filters applied to articles fetch
// See doFetchArticles() action on supported filters
export const defaultFetchFilters = {
  ids: [],
  isLoading: false,
  currentPage: 1,
  perPage: 20,
  totalPages: 1,
  totalCount: 0,
  selectedState: 'all',
  selectedCategoryId: null,
  selectedCategoryTitle: null,
  selectedAuthorId: null,
  selectedAuthorName: null,
  selectedArticleIds: [],
  keyword: '',
  sortByOrder: 'updatedAt desc', // recently updated
}
