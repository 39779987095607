import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  Fragment,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styles as modalBtnsStyles } from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns.styles'
import {
  divider,
  text,
} from '@groovehq/internal-design-system/lib/styles/elements'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import { selectBillingAccount } from 'ducks/billing/selectors/selectBillingAccount'
import { doFetchBillingData } from 'ducks/billing/operations'
import { isEmpty } from 'util/objects'
import debug from 'util/debug'
import { useBilling, usePricingChangeInfo } from 'ducks/billing/hooks'
import { useDrawer } from 'ducks/drawers/hooks'
import {
  DRAWER_TYPE_BILLING_EXTERNAL_APPROVE,
  DRAWER_TYPE_BILLING_DOWNGRADE_PLAN,
  DRAWER_TYPE_BILLING_DOWNGRADE_PLAN_CONFIRMATION,
} from 'ducks/drawers/types'
import { buildDrawerQueryParam } from 'ducks/drawers/util'
import { selectIsBootstrapTypedInStates } from 'selectors/bootstrap/makeSelectIsBootstrapTypesLoaded'
import { capitalize } from 'util/strings'
import { ADDON, INBOX, KB, ALL } from 'ducks/billing/productTypes'
import { uniq } from 'util/arrays'
import PlanRow from '../PlanRow'
import { AdminAccessDrawer } from '../../NoAccess'
import { styles } from '../styles'
import AnnualSavingsSection from './AnnualSavingsSection'
import TotalNextRecurringInvoice from './TotalNextRecurringInvoice'

const PRODUCT_TO_PRODUCT_NAME = {
  [KB]: 'Knowledge base',
  [INBOX]: app.t('Mailbox'),
  [ADDON]: 'Addons',
}

const changeVerb = verb =>
  verb.endsWith('e') ? `${verb.slice(0, -1)}ing` : `${verb}ing`

const BillingChangePlanDetails = ({
  open,
  onExit,
  drawerResourceId: newPricingIdsPipeDelimited,
  drawerBillingCycle,
  drawerLimitsConfirmed = false,
}) => {
  const dispatch = useDispatch()

  const inputNewPricingIds = useMemo(
    () => uniq(newPricingIdsPipeDelimited.split('|')),
    [newPricingIdsPipeDelimited]
  )
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { updateSubscription } = useBilling()

  const {
    currentPricingIds,
    totalTypeWithChanges,
    changesByType,
    changeType,
    currentBillingCycle,
    newBillingCycle,
    newPlansInfo,
    isLimitsExceeded,
    isUpgradingToAnnual,
    isCycleChange,
    nextBillDate,
    currency,
    newPricingIds,
  } = usePricingChangeInfo(inputNewPricingIds, drawerBillingCycle)

  const billingAccount = useSelector(selectBillingAccount)

  const [upgradeAt] = useState(new Date().getTime())

  const isLoading =
    !useSelector(state =>
      selectIsBootstrapTypedInStates(state, ['billing'], ['LOADED'])
    ) || isEmpty(billingAccount)

  const { openDrawer: openBillingExternalApprove } = useDrawer({
    type: DRAWER_TYPE_BILLING_EXTERNAL_APPROVE,
  })

  const {
    drawerId: downgradePlanDrawerId,
    openDrawer: openDowngradePlan,
  } = useDrawer({
    type: DRAWER_TYPE_BILLING_DOWNGRADE_PLAN,
  })

  const {
    drawerId: billingDowngradeConfirmationDrawerId,
    openDrawer: openBillingDowngradeConfirmation,
  } = useDrawer({
    type: DRAWER_TYPE_BILLING_DOWNGRADE_PLAN_CONFIRMATION,
  })

  const onSubmit = useCallback(
    async () => {
      setIsSubmitting(true)
      const data = {
        pricingIds: newPricingIds,
        cycle: newBillingCycle,
      }
      try {
        const response = await updateSubscription(data, {
          successMessage: 'Your upgrade was successful',
          failed: 'Your upgrade has failed',
          convertTrials: false,
        })
        const {
          updateSubscription: {
            pendingSubscription: { approvalUrl } = {},
          } = {},
        } =
          response || {}
        if (approvalUrl) {
          openBillingExternalApprove()
        } else if (changeType === 'downgrade') {
          openBillingDowngradeConfirmation(newPricingIds.join('|'), {
            query: {
              ...buildDrawerQueryParam(
                billingDowngradeConfirmationDrawerId,
                'drawerBillingCycle',
                newBillingCycle
              ),
            },
          })
        } else {
          onExit()
        }
      } catch (error) {
        debug(error)
        setIsSubmitting(false)
      }
    },
    [
      newBillingCycle,
      newPricingIds,
      onExit,
      openBillingExternalApprove,
      updateSubscription,
      billingDowngradeConfirmationDrawerId,
      openBillingDowngradeConfirmation,
      changeType,
    ]
  )

  useEffect(
    () => {
      dispatch(doFetchBillingData())
    },
    [dispatch]
  )

  useEffect(
    () => {
      if (
        changeType === 'downgrade' &&
        isLimitsExceeded &&
        !drawerLimitsConfirmed
      ) {
        openDowngradePlan(newPricingIdsPipeDelimited, {
          query: {
            ...buildDrawerQueryParam(
              downgradePlanDrawerId,
              'drawerBillingCycle',
              drawerBillingCycle
            ),
          },
        })
      }
    },
    [
      newPricingIdsPipeDelimited,
      openDowngradePlan,
      downgradePlanDrawerId,
      drawerLimitsConfirmed,
      drawerBillingCycle,
      isLimitsExceeded,
      changeType,
    ]
  )

  useEffect(
    () => {
      // If no new pricing ids are provided or the new pricing ids match the existing pricing ids
      // juse close the drawer
      if (newPricingIds.length === 0 || totalTypeWithChanges === 0) {
        onExit()
      }
    },
    [newPricingIds, onExit, totalTypeWithChanges]
  )

  const footer = (
    <div css={[modalBtnsStyles.base, styles.btns]}>
      {changeType === 'downgrade' && (
        <Button type="tertiary" size="big" onClick={onExit}>
          No thanks, I’d like to keep my current plan
        </Button>
      )}
      <Button
        type="info"
        size="big"
        onClick={onSubmit}
        disabled={isSubmitting || isLoading}
      >
        {isSubmitting
          ? `${changeVerb(capitalize(changeType))}...`
          : `${capitalize(changeType)} now`}
      </Button>
    </div>
  )

  const totalNewCyclePrice = useMemo(
    () => {
      return newPlansInfo.reduce((total, info) => {
        return total + info.lineItem.cyclePrice
      }, 0)
    },
    [newPlansInfo]
  )

  return (
    <>
      <AdminAccessDrawer
        title={`${capitalize(changeType)} subscription`}
        open={open}
        footer={footer}
        onClose={onExit}
        isLoading={isLoading}
      >
        <div className="grui pt-12" css={[styles.container]}>
          <div css={text.styles.textNormal}>
            <>
              You are about to{' '}
              {changeType === 'downgrade' ? 'schedule the ' : ''} {changeType}{' '}
              {changeType === 'downgrade' && 'of '}your{' '}
              <strong>{currentBillingCycle}</strong> subscription{isCycleChange && (
                <>
                  {' to '}
                  <strong>{newBillingCycle}</strong> billing
                </>
              )}.
            </>
          </div>
          <div
            css={styles.planChangesCard}
            className="grui pt-10 mt-12 -ml-7 -mr-7"
          >
            {ALL.filter(type => {
              const { isEmpty: isChangeEmpty, hasChanged } = changesByType[type]
              return !isChangeEmpty && hasChanged
            }).map(type => {
              const {
                currentPlansInfo: currentPlansInfoByType,
                newPlansInfo: newPlansInfoByType,
              } = changesByType[type]
              return (
                <Fragment key={type}>
                  {totalTypeWithChanges > 1 && (
                    <div className="grui relative">
                      <div css={[text.styles.text2Xs, styles.tag]}>
                        {PRODUCT_TO_PRODUCT_NAME[type]}
                      </div>
                    </div>
                  )}
                  <div className="grui pt-2 px-7">
                    {currentPlansInfoByType.map(info => {
                      const {
                        displayName,
                        pricing: { type: pricingType, pricingModel, usageFrom },
                        lineItem: { cyclePrice = 0, itemPrice = 0, quantity },
                        billingCycle,
                      } = info

                      return (
                        <PlanRow
                          key={`current-${info.pricing.id}`}
                          name={displayName}
                          product={pricingType}
                          cyclePrice={cyclePrice}
                          itemPrice={itemPrice}
                          quantity={quantity}
                          className="grui mb-8"
                          isUpgradingToAnnual={isUpgradingToAnnual}
                          pricingModel={pricingModel}
                          planCycle={billingCycle}
                          isCycleChange={isCycleChange}
                          usageFrom={usageFrom}
                        />
                      )
                    })}

                    {newPlansInfoByType.map(info => {
                      const {
                        displayName,
                        pricing: { type: pricingType, pricingModel, usageFrom },
                        lineItem: { cyclePrice, itemPrice = 0, quantity },
                        billingCycle,
                      } = info

                      return (
                        <PlanRow
                          key={`new-${info.pricing.id}`}
                          name={displayName}
                          product={pricingType}
                          cyclePrice={cyclePrice}
                          itemPrice={itemPrice}
                          quantity={quantity}
                          className="grui mb-8"
                          isNew
                          isUpgradingToAnnual={isUpgradingToAnnual}
                          pricingModel={pricingModel}
                          planCycle={billingCycle}
                          isCycleChange={isCycleChange}
                          usageFrom={usageFrom}
                        />
                      )
                    })}
                  </div>
                  <div
                    role="separator"
                    css={divider.styles.base}
                    className="grui my-9"
                  />
                </Fragment>
              )
            })}

            {changeType === 'upgrade' && (
              <AnnualSavingsSection
                isUpgradingToAnnual={isUpgradingToAnnual}
                currentPricingIds={currentPricingIds}
                newPricingIds={newPricingIds}
                currentBillingCycle={currentBillingCycle}
                newBillingCycle={newBillingCycle}
                upgradeAt={upgradeAt}
              />
            )}

            <TotalNextRecurringInvoice
              nextBillDate={nextBillDate}
              currency={currency}
              totalNewCyclePrice={totalNewCyclePrice}
              isUpgrade={changeType === 'upgrade'}
            />
          </div>
        </div>
      </AdminAccessDrawer>
    </>
  )
}

export default BillingChangePlanDetails
