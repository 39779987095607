import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { doDeleteArticle } from 'subapps/kb/actions/articles'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'

import EntityDelete from 'subapps/settings/components/drawers/EntityDelete'

const ArticleDelete = ({
  open,
  onClose,
  onExit,
  drawerResourceId: articleId,
}) => {
  const dispatch = useDispatch()

  const handleDelete = useCallback(
    () => {
      dispatch(doDeleteArticle(articleId))
    },
    [dispatch, articleId]
  )

  return (
    <div>
      <EntityDelete
        onRemove={handleDelete}
        onClose={onClose}
        onExit={onExit}
        onCancel={onClose}
        deleteBtn
        tertiaryBtnText="Cancel"
        title="Delete article"
        open={open}
        skipProceedQuestion
        confirmationHidden
      >
        <p css={text.styles.textNormal}>
          Are you sure you want to delete this article?
          <br />
          This cannot be undone.
        </p>
      </EntityDelete>
    </div>
  )
}

export default ArticleDelete
