import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util'

const fullWidth = css`
  max-width: 100%;
`

const content = theme => css`
  width: ${rem(400)};

  .tilt-highlight {
    background-color: ${theme.color.warningAlt['200']};
    font-weight: ${theme.fontWeight.medium};
  }
`

const instructions = css`
  & > * {
    min-width: 0;
  }
`

const confirmationText = css`
  max-width: ${rem(500)};
`

export const styles = {
  fullWidth,
  content,
  instructions,
  confirmationText,
}
