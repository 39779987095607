import { connect } from 'react-redux'
import { fetchingStatusesSelector } from 'selectors/app'
import {
  selectMergeableTickets,
  selectCurrentMergeSearch,
  selectCurrentMergeSearchResultTickets,
  selectHasCurrentMergeSearchErrored,
  selectIsCurrentMergeSearchQueryValid,
} from 'ducks/merging/selectors/search'
import { selectMergingSearchTerm } from 'ducks/merging/selectors/base'
import { selectCurrentTicket } from 'selectors/tickets/current/selectCurrentTicket'
import {
  selectSendingTicketIds,
  selectCurrentTicketIsDelayed,
} from 'selectors/undoSend'
import { emptyArr } from 'util/arrays'
import { emptyObj } from 'util/objects'

import View from './view'

const select = state => {
  const {
    fetchMergeableTickets: isFetchingMergeableTickets,
    fetchMergeSearch: isFetchingMoreSearch,
  } = fetchingStatusesSelector(state)

  return {
    mergeableTickets: selectMergeableTickets(state),
    currentMergeSearch: selectCurrentMergeSearch(state) || emptyObj,
    delayedTicketIds: selectSendingTicketIds(state),
    hasSearchErrored: selectHasCurrentMergeSearchErrored(state),
    isFetchingMoreSearch,
    isFetchingMergeableTickets,
    isQueryStringValid: selectIsCurrentMergeSearchQueryValid(state),
    isDelayed: selectCurrentTicketIsDelayed(state),
    queryString: selectMergingSearchTerm(state),
    searchResults: selectCurrentMergeSearchResultTickets(state) || emptyArr,
    ticket: selectCurrentTicket(state),
  }
}

export default connect(select, null)(View)
