import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'

const wrapper = theme => css`
  padding-bottom: ${theme.spacing[10]};
  display: flex;
  flex-direction: column;
`

const container = theme => css`
  color: ${theme.color.info['900']};

  h4 {
    font-family: inherit;
    color: inherit;
    font-weight: ${theme.fontWeight.semibold};
  }

  .tilt-highlight {
    background-color: #ffbfbf;
    line-height: ${theme.spacing[11]};
    padding: 0 3px;
  }
`

const row = theme => css`
  display: flex;
  justify-content: space-between;
  gap: ${theme.spacing[5]};
  white-space: nowrap;
`

const lightGrey = theme => css`
  color: ${theme.color.grey['300']};
`

const features = theme => css`
  margin-top: -${theme.spacing[3]};
  svg {
    fill: ${theme.color.primary.negative};
    width: 18px;
    height: 18px;
    flex-shrink: 0;
  }

  li {
    margin-top: ${theme.spacing[3]};
  }
`

const planChangesCard = theme => css`
  border-radius: ${theme.spacing[7]};
  background-color: ${theme.color.monochrome.white};
  border: 1px solid ${theme.color.monochrome.medium};
`

const note = theme => css`
  display: inline-block;
  padding: 0 3px;
  margin-left: -3px;
  background-color: ${theme.color.info['50']};
`

const footer = theme => css`
  background-color: ${theme.color.info['900']};
  border-bottom-right-radius: ${theme.spacing[7]};
  border-bottom-left-radius: ${theme.spacing[7]};
`

const savingRow = theme => css`
  color: ${theme.color.primary.info};
`

const contactCard = theme => css`
  background-color: ${theme.color.info['50']};
  border-radius: ${theme.spacing[7]};
  color: ${theme.color.info['900']};
  font-size: ${theme.fontSize.x2large};
`

const contactBtn = theme => css`
  border-radius: 9999px;
  font-weight: ${theme.fontWeight.semibold};
`

const btns = theme => css`
  button {
    font-weight: ${theme.fontWeight.medium};
  }
`

const btnsVariant = theme => css`
  button {
    font-weight: ${theme.fontWeight.medium};
    font-family: ${theme.font.secondary};
    border-radius: 9999px;
  }
`

const tag = theme => css`
  background-color: ${theme.color.info[900]};
  color: ${theme.color.monochrome.white};
  padding: ${rem(3)} ${rem(7)} ${rem(3)} ${rem(9)};
  border-radius: ${rem(4)} ${rem(4)} ${rem(4)} 0;
  position: relative;
  display: inline-block;
  left: 0;
  margin-left: ${rem(-6)};
  margin-bottom: ${rem(6)};
  font-weight: ${theme.fontWeight.semibold};
  &::before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    bottom: -${rem(6)};
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 ${rem(6)} ${rem(6)} 0;
    border-color: transparent ${theme.color.primary.info} transparent
      transparent;
  }
`

export const styles = {
  container,
  wrapper,
  row,
  lightGrey,
  planChangesCard,
  note,
  footer,
  btnsVariant,
  savingRow,
  features,
  contactCard,
  contactBtn,
  btns,
  tag,
}
