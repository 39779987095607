import channels from './channels'
import facebook from './facebook'

const ducks = {
  channels,
  facebook,
}

const validDucks = Object.keys(ducks).reduce((result, duckName) => {
  const duck = ducks[duckName]
  if (typeof duck === 'function') result[duckName] = duck
  return result
}, {})

export default validDucks
