import React, { useCallback } from 'react'
import Modal from '@groovehq/internal-design-system/lib/components/Modal/Modal'
import ProgressDots from '@groovehq/internal-design-system/lib/components/ProgressDots/ProgressDots'
import { CHANNEL_EMAIL_TYPES } from '../Channels.data'
import { styles } from './styles'
import useEmailChannelSelector from './useEmailChannelSelector'

const GoogleForwardingOptionAlert = ({
  onClose,
  onExit,
  previousDrawer,
  drawerSource: source = 'settings',
  drawerId,
}) => {
  const options = CHANNEL_EMAIL_TYPES

  const {
    creationStepCount,
    handleBack,
    handleSelect,
  } = useEmailChannelSelector({
    onClose,
    onExit,
    previousDrawer,
    drawerSource: source,
    drawerId,
    options,
  })

  const handleSelectOAuth = useCallback(
    () => {
      handleSelect(CHANNEL_EMAIL_TYPES.google.type, { provider: 'google' })
    },
    [handleSelect]
  )

  const handleSelectForwarding = useCallback(
    () => {
      handleSelect(CHANNEL_EMAIL_TYPES.forwarding.type, {
        provider: 'google',
        shouldUseTypeForNextDrawer: true,
      })
    },
    [handleSelect]
  )

  return (
    <Modal
      onClose={onExit}
      portal="#drawer-root"
      dataTestId="google-forwarding-option-alert"
      className="grui pb-14"
      open
      backdropTransparency="light"
    >
      <ProgressDots
        count={creationStepCount}
        now={1}
        className="grui mt-4 mb-13"
      />
      <Modal.Title className="grui text-center">
        Are you sure you want to set up a forwarding rule?
      </Modal.Title>
      <Modal.Description css={styles.description}>
        Less than 5% of Groove customers use a forwarding rule to connect Google
        Workspace Account email address to Groove. Using the{' '}
        <strong>
          <i>Connect with Google Workspace</i>
        </strong>{' '}
        option instead of a forwarding rule enables you to import your email
        history, proactively be alerted of any email disconnects, improve your
        email deliverability, and follow a simpler setup process.
      </Modal.Description>
      <Modal.Button
        type="info"
        className="grui mt-12"
        onClick={handleSelectOAuth}
      >
        Connect with Google Workspace
      </Modal.Button>
      <Modal.Button
        type="tertiary"
        className="grui my-5"
        onClick={handleSelectForwarding}
      >
        Set up a Forwarding Rule
      </Modal.Button>
      <div className="grui text-center">
        <Modal.Button type="link" onClick={handleBack}>
          Back
        </Modal.Button>
      </div>
    </Modal>
  )
}

export default GoogleForwardingOptionAlert
