import React from 'react'

import { useLink } from 'ducks/location/hooks'

import { SETTINGS_INTEGRATIONS_PROVIDER_PAGE } from '../../../../types'

import { Tabs } from '../../shared/SettingsModal'
import ActionsTab from './ActionsTab'
import AuthTab from './AuthTab'
import SidebarTab from './SidebarTab'

const toAuthTab = {
  type: SETTINGS_INTEGRATIONS_PROVIDER_PAGE,
  payload: { provider: 'jira_server', tab: 'auth' },
}
const toActionsTab = {
  type: SETTINGS_INTEGRATIONS_PROVIDER_PAGE,
  payload: { provider: 'jira_server', tab: 'actions' },
}
const toSidebarTab = {
  type: SETTINGS_INTEGRATIONS_PROVIDER_PAGE,
  payload: { provider: 'jira_server', tab: 'sidebar' },
}

export default function JiraServerSettingsForm({
  formFields,
  id,
  tab,
  onChange,
}) {
  const toAuthTabAction = useLink(toAuthTab, { preserveQuery: true })
  const toActionsTabAction = useLink(toActionsTab, { preserveQuery: true })
  const toSidebarTabAction = useLink(toSidebarTab, { preserveQuery: true })

  return (
    <Tabs>
      <Tabs.Header>
        <Tabs.Link
          active={(!tab && !id) || tab === 'auth'}
          to={toAuthTabAction}
        >
          Authentication
        </Tabs.Link>
        {id && (
          <React.Fragment>
            <Tabs.Link
              active={!tab || tab === 'actions'}
              to={toActionsTabAction}
            >
              Actions
            </Tabs.Link>
            <Tabs.Link active={tab === 'sidebar'} to={toSidebarTabAction}>
              Sidebar
            </Tabs.Link>
          </React.Fragment>
        )}
      </Tabs.Header>
      <AuthTab
        active={(!id && !tab) || tab === 'auth'}
        formFields={formFields}
        onChange={onChange}
      />
      <ActionsTab
        active={(id && !tab) || tab === 'actions'}
        formFields={formFields}
        onChange={onChange}
      />
      <SidebarTab
        active={tab === 'sidebar'}
        formFields={formFields}
        onChange={onChange}
      />
    </Tabs>
  )
}
