import Bugsnag from '@bugsnag/js'
import idbKeyval from 'idb-keyval'

const NAMESPACE = 'groove'
const errorHandler = actionType => err => {
  console.error(err) // eslint-disable-line no-console
  try {
    // eslint-disable-next-line no-param-reassign
    err.name = `IndexedDB Error: ${err.name}`
  } catch (e) {
    // suppress errors
  }

  const errorDetails = {}
  errorDetails.fromPromise = true
  errorDetails.indexedDB = true
  errorDetails.indexedDbAction = actionType
  errorDetails.errorType = typeof err
  errorDetails.objectKeys = Object.keys(err)

  Bugsnag.notify(err, event => {
    // eslint-disable-next-line no-param-reassign
    event.severity = 'error'

    event.addMetadata('metaData', {
      meta: {
        errorDetails,
      },
    })
  })
}

const idb = {
  get: key => idbKeyval.get(`${NAMESPACE}-${key}`).catch(errorHandler('get')),
  set: (key, val) => {
    idbKeyval.set(`${NAMESPACE}-${key}`, val).catch(errorHandler('set'))
  },
  clear: () => idbKeyval.clear().catch(errorHandler('clear')),
}

export default idb
