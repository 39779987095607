import { connect } from 'react-redux'
import {
  selectRawSearchResultTicket,
  selectCurrentTicketSearchMailboxes,
} from 'selectors/search'
import { selectCustomerProp } from 'ducks/customers/selectors'
import { doNavigateToTicket } from 'actions/navigation'

import {
  doCloseTicket,
  doOpenTicket,
  doMarkAsRead,
  doMarkAsUnread,
  doUnsnoozeAndUnread,
} from 'actions/tickets'
import { selectIsTicketSelectionMode } from 'selectors/ticket_list'
import { selectCurrentTicketId } from 'selectors/tickets/current/selectCurrentTicketId'
import { doToggleTicketSelection } from 'actions/app'

import { selectTicketAssignmentLabel } from 'selectors/assignment'

import { selectAgent } from 'selectors/agents/base'
import { selectLabelsByIds } from 'selectors/labels'
import {
  selectAccountPreferenceSortByCollaboratorCommentEnabled,
  selectAccountPreferenceSortByLastUnansweredUserMessageAtEnabled,
} from 'selectors/app/selectAccountPreferences'

import { selectMailboxesById } from 'selectors/mailboxes/selectMailboxesById'
import { sortByKey } from 'util/arrays'
import ListItemView from 'components/ConversationList/ListItem'
import { isSnoozed } from 'util/ticketState'
import { snoozeLabel } from 'util/snooze'
import { selectDraftBodyByTicketId } from 'ducks/drafts2/selectors'
import { unsafeStripTags } from 'util/strings'
import { selectConversationAgentCollisions } from 'ducks/collisions/selectors'
import { selectCurrentUserPrefersPreviewOnHover } from 'ducks/currentUser/selectors/preferences/selectCurrentUserPrefersPreviewOnHover'
import {
  lastUnansweredUserMessageDurationLevel,
  lastUnansweredUserMessageLabel,
} from 'util/lastUnansweredUserMessage'
import { selectCurrentUserIsAdminOrOwnerOrAgent } from 'ducks/currentUser/selectors'

const select = (initialState, initialOwnProps) => {
  const ticketId = initialOwnProps.conversationId
  const {
    location: { prev: { payload: { id: prevTicketId } = {} } = {} } = {},
  } = initialState

  return state => {
    const collision = selectConversationAgentCollisions(state, ticketId)
    const ticket = selectRawSearchResultTicket(state, ticketId)
    const isAdminOrOwnerOrAgent = selectCurrentUserIsAdminOrOwnerOrAgent(state)
    if (!ticket) {
      return {
        present: false,
      }
    }
    const conversationPreviewOnHoverEnabled = selectCurrentUserPrefersPreviewOnHover(
      state
    )
    const snippet = {
      type: ticket.bodyType || 'enduser',
      text: ticket.body,
    }
    let draftType = null
    let doesHaveDraft = false

    const noteBody = selectDraftBodyByTicketId(state, ticketId, 'note')
    if (noteBody) {
      snippet.type = 'draft'
      draftType = 'note'
      snippet.text = unsafeStripTags(noteBody).substr(0, 50)
      doesHaveDraft = true
    }

    const draftBody = selectDraftBodyByTicketId(state, ticketId, 'reply')
    if (draftBody) {
      snippet.type = 'draft'
      draftType = 'reply'
      snippet.text = unsafeStripTags(draftBody).substr(0, 50)
      doesHaveDraft = true
    }

    if (snippet && snippet.text) {
      snippet.text = snippet.text.replace(/&nbsp;/g, ' ')
    }

    const ticketAssignmentLabel = selectTicketAssignmentLabel(state, ticket)

    let snippetAuthor = null
    const isLastMessageLeftByAgent =
      ticket && ticket.bodyAuthor && ticket.bodyAuthor.type === 'Agent'
    if (isLastMessageLeftByAgent) {
      const agent = selectAgent(state, ticket.bodyAuthor.id)
      if (agent) {
        snippetAuthor = agent.label
      }
    }
    const searchMailboxIds = selectCurrentTicketSearchMailboxes(state)
    let mailboxColor = null
    if (searchMailboxIds.length !== 1) {
      const mailbox = selectMailboxesById(state)[ticket.mailboxId]
      mailboxColor = mailbox && mailbox.color
    }

    let labels = []
    if (ticket && ticket.labelIds) {
      labels = selectLabelsByIds(state, ticket.labelIds).map(label => ({
        name: label.name,
        color: label.color,
      }))
    }

    const isSelectionMode = selectIsTicketSelectionMode(state)
    const currentTicketId = selectCurrentTicketId(state)
    const sortByCollaborator = selectAccountPreferenceSortByCollaboratorCommentEnabled(
      state
    )

    const {
      latestCollaboratorCommentAtInHuman,
      updatedAtInHuman,
      last_unanswered_user_message_at: lastUnansweredUserMessageAt,
    } = ticket

    const timestamp = sortByCollaborator
      ? latestCollaboratorCommentAtInHuman
      : updatedAtInHuman

    const sortByLastUnansweredUserMessage = selectAccountPreferenceSortByLastUnansweredUserMessageAtEnabled(
      state
    )

    const unansweredSinceLabel = sortByLastUnansweredUserMessage
      ? lastUnansweredUserMessageLabel(lastUnansweredUserMessageAt)
      : null

    const unansweredSinceDurationLevel = sortByLastUnansweredUserMessage
      ? lastUnansweredUserMessageDurationLevel(lastUnansweredUserMessageAt)
      : null

    return {
      ...collision,
      present: true,
      customerLabelFull: selectCustomerProp(
        state,
        ticket.customerId,
        'labelFullOrYou'
      ),
      hasDraft: doesHaveDraft,
      isUnread: ticket.isUnread,
      assignmentLabel: ticketAssignmentLabel,
      timestamp,
      messageCount: ticket.message_count,
      hasAttachments: ticket.hasAttachments,
      isStarred: ticket.isStarred,
      subject: ticket.titleLabel,
      subjectEmptyPlaceholderText: 'There is no subject for this message',
      snippetType: doesHaveDraft ? 'draft' : snippet && snippet.type,
      snippetAuthor,
      snippetText: snippet && snippet.text,
      draftType,
      mailboxColor,
      labelIds: ticket.labelIds,
      isCloseable: ticket.isCloseable,
      shouldShowLabels: ticket.labelIds && ticket.labelIds.length > 0,
      labelsJSON:
        labels.length > 0 ? JSON.stringify(sortByKey(labels, 'name')) : null,
      active: !isSelectionMode && ticketId === currentTicketId,
      linkUrl: `/tickets/${ticketId}`,
      isLastViewed: prevTicketId && ticketId === prevTicketId,
      isSnoozed: isSnoozed(ticket),
      snoozedUntilLabel: snoozeLabel(ticket.snoozedUntil),
      conversationPreviewOnHoverEnabled,
      unansweredSinceLabel,
      unansweredSinceDurationLevel,
      canBulkSelect: isAdminOrOwnerOrAgent,
      canPerformQuickActions: isAdminOrOwnerOrAgent,
    }
  }
}

const perform = dispatch => {
  return {
    closeConversation: id => dispatch(doCloseTicket(id)),
    openConversation: id => dispatch(doOpenTicket(id)),
    openConversationPage: id => dispatch(doOpenTicket(id)),
    markAsRead: id => dispatch(doMarkAsRead(id)),
    markAsUnread: id => dispatch(doMarkAsUnread(id)),
    onClick: action => dispatch(action),
    navigateToConversation: (id, draftType) =>
      dispatch(doNavigateToTicket(id, draftType)),
    toggleConversationSelection: id => dispatch(doToggleTicketSelection(id)),
    unsoozeConversation: id => dispatch(doUnsnoozeAndUnread(id)),
  }
}

export default connect(select, perform)(ListItemView)
