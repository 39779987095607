import { selectIsCategoriesTitlesFetched } from 'subapps/kb/selectors/categories'
import { doFetchCategoriesTitles } from './doFetchCategoriesTitles'

export function doLoadCategoriesTitles() {
  return (dispatch, getState) => {
    const state = getState()

    if (selectIsCategoriesTitlesFetched(state)) return false
    return dispatch(doFetchCategoriesTitles())
  }
}
