import { css } from '@emotion/react'

import { rem } from '@groovehq/internal-design-system/lib/util'

const dropdownBtn = css`
  min-width: ${rem(200)};
`

const checkbox = css`
  label > span {
    margin-right: ${rem(16)};
  }
`

const tableCompact = theme => css`
  tbody tr {
    .row-controls {
      position: relative;

      .controls {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 10px;
      }
    }

    &:hover {
      .row-controls {
        .controls {
          opacity: 1;
          visibility: visible;
          color: ${theme.color.monochrome.mediumDark};
        }

        /* Only hide components if controls actually exist */
        .components:not(.controls-disabled) {
          display: none;
        }
      }
    }
  }
`

const tableStandard = theme => css`
  tbody tr:hover {
    td:last-of-type > * {
      opacity: 1;
      visibility: visible;
      color: ${theme.color.monochrome.mediumDark};
    }
  }
`

export const styles = {
  dropdownBtn,
  checkbox,
  tableCompact,
  tableStandard,
}
