import React, { useCallback, useMemo, useEffect } from 'react'
import Checkbox from '@groovehq/internal-design-system/lib/components/Checkbox/Checkbox'
import {
  text,
  paragraph,
} from '@groovehq/internal-design-system/lib/styles/elements'
import ChannelSelectionTable from 'subapps/settings/components/ChannelSelectionTable'
import {
  EMAIL_TYPE,
  LIVE_CHAT_WIDGET_TYPE,
} from 'subapps/settings/components/ChannelSelectionTable/types'
import {
  CHATS_PREFIX,
  MAILBOXES_PREFIX,
} from 'ducks/integrations/utils/integrationSettingPrefix'

import { buildId } from 'util/globalId'

const ALLOWED_TYPES = [EMAIL_TYPE, LIVE_CHAT_WIDGET_TYPE]

export default function RechargeUpdateStoreConfigure({ store, ...rest }) {
  const { reset, register, getValues, setValue } = rest

  /**
   * mailbox + widget for shopify store settings is in the format {mailboxes.1234: true, mailboxes.999: false}
   * react hook forms is NOT a fan of that as it thinks it's a nested property and can never set the values.
   * after numerous hacks, decided to change the format here to something more usable i.e. channels: {1234: true, 999: false}
   * plays better with TableWithCheckbox and react hook forms
   */
  const saneSettings = useMemo(
    () => {
      const { settings: { settings } = {} } = store || {}

      return Object.entries(settings).reduce((obj, [key, value]) => {
        if (
          !key.startsWith(MAILBOXES_PREFIX) &&
          !key.startsWith(CHATS_PREFIX)
        ) {
          // eslint-disable-next-line no-param-reassign
          obj[key] = value
          return obj
        }

        if (!obj?.channels) {
          // eslint-disable-next-line no-param-reassign
          obj.channels = {}
        }

        if (key.startsWith(MAILBOXES_PREFIX)) {
          const newKey = key.replace(MAILBOXES_PREFIX, '')
          // eslint-disable-next-line no-param-reassign
          obj.channels[buildId('Channel', newKey)] = value
        } else if (key.startsWith(CHATS_PREFIX)) {
          const newKey = key.replace(CHATS_PREFIX, '')
          // eslint-disable-next-line no-param-reassign
          obj.channels[buildId('Widget', newKey)] = value
        }

        return obj
      }, {})
    },
    [store]
  )

  useEffect(
    () => {
      reset({
        ...saneSettings,
      })
    },
    [saneSettings, reset]
  )

  const onSelectedRowIdsChange = useCallback(
    selectedRowIds => {
      // calculate a diff of previous selected channels state
      // and new state and only setValue on changed selectedIds
      const valuesStateBefore = { ...(getValues()?.channels || {}) }

      Object.entries(valuesStateBefore).forEach(([key, value]) => {
        const selectedValue = selectedRowIds[key] || false
        if (value !== selectedValue) {
          setValue(`channels.${key}`, selectedValue, { shouldDirty: true })
        }
      })
    },
    [setValue, getValues]
  )

  const defaultSelectedRowIds = useMemo(
    () => {
      return saneSettings?.channels || {}
    },
    [saneSettings]
  )

  return (
    <div className="grui mt-12">
      <div
        css={[text.styles.fontMedium, text.styles.textBlack]}
        className="grui mb-5"
      >
        Store field syncing
      </div>
      <Checkbox
        id="shopifyStoreSettingsSync"
        css={text.styles.textDark}
        {...register('sync')}
      >
        Update the Groove contact when the first name or last name changes in
        Recharge
      </Checkbox>
      <div
        css={[
          text.styles.text2Xs,
          text.styles.textMediumDark,
          text.styles.italic,
        ]}
        className="grui ml-12 mt-3"
      >
        Note: This will only update fields going forward
      </div>
      <div className="grui mt-10">
        <div css={[text.styles.fontMedium, text.styles.textBlack]}>
          Channels
        </div>
        <p className="grui mt-3 mb-10" css={paragraph.styles.preflight}>
          Choose which channels you want to display this store in.
        </p>
        <ChannelSelectionTable
          onSelectedRowIdsChange={onSelectedRowIdsChange}
          defaultSelectedRowIds={defaultSelectedRowIds}
          displayChannelTypes={ALLOWED_TYPES}
        />
      </div>
    </div>
  )
}
