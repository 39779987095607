import { diff } from 'util/date'
import debug from 'util/debug'
import ChangesetQueue from 'actions/tickets/changesetQueue'

// This middleware installs a method of making the
// next navigation navigate using location.href, effectively
// refreshing the whole page without impacting user experience
const MIN_REFRESH_MINS = 10
let shouldRefreshOnNextPage = false
let lastRefreshTime

export const refreshOnNextPage = () => {
  shouldRefreshOnNextPage = true
}

export const refreshMiddleware = () => next => action => {
  const now = new Date()

  if (!lastRefreshTime) lastRefreshTime = now

  const minutesSince = diff('minutes', lastRefreshTime, now)
  const shouldRefresh =
    shouldRefreshOnNextPage && minutesSince >= MIN_REFRESH_MINS

  if (shouldRefresh && action.meta && action.meta.location) {
    lastRefreshTime = now
    const { pathname } = action.meta.location.current
    if (!ChangesetQueue.shouldDisableRefreshNextPage()) {
      debug('Auto-refreshing...', { minutesSince, lastRefreshTime })
      window.location.href = pathname
    }
    return
  }

  if (shouldRefreshOnNextPage && !shouldRefresh) {
    debug('Auto-refresh throttled', { minutesSince, lastRefreshTime })
  }

  // eslint-disable-next-line consistent-return
  return next(action)
}
