import React, { useEffect } from 'react'

import { Loader } from 'shared/ui'

import { Tabs } from '../../../shared/SettingsModal'
import InstallOwner from './InstallOwner'

export default function InstallsTab({
  active,
  installPath,
  isLoading,
  onMount,
  owners,
}) {
  useEffect(onMount, [onMount])
  if (active && isLoading) return <Loader />
  return (
    <Tabs.Content active={active}>
      {owners.map(owner => (
        <InstallOwner {...owner} installPath={installPath} />
      ))}
    </Tabs.Content>
  )
}
