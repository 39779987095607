import React, { useCallback } from 'react'
import { useFieldArray } from 'react-hook-form'
import {
  text,
  button,
} from '@groovehq/internal-design-system/lib/styles/elements'
import { folderVariables } from './data'
import Condition from '../../Condition'

export default function FolderConditions({
  control,
  setValue,
  onConditionsChange,
}) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'conditions',
    keyName: 'key',
  })

  const onAddCondition = useCallback(
    () => {
      const newCondition = {
        operator: null,
        param: null,
        value: '',
      }
      append(newCondition)
    },
    [append]
  )

  const onRemoveCondition = useCallback(
    (_, index) => {
      remove(index)
    },
    [remove]
  )
  const onConditionChange = useCallback(
    (newCondition, index) => {
      // Kevin R (2022)
      // Note dont use the update from useFieldArray. This
      // completely replaces the object causing the whole
      // condition to be re-rendered.
      setValue(`conditions.${index}`, newCondition, {
        shouldDirty: true,
        shouldValidate: true,
      })
      onConditionsChange()
    },
    [onConditionsChange, setValue]
  )
  return (
    <>
      <div className="grui pt-10">
        {fields.map((item, index) => (
          <Condition
            control={control}
            name={`conditions.${index}`}
            key={item.key}
            itemKey={item.key}
            variables={folderVariables}
            index={index}
            totalConditions={fields.length}
            onRemoveCondition={onRemoveCondition}
            onConditionChange={onConditionChange}
            onInput={onConditionChange}
          />
        ))}
      </div>
      <div className="grui mt-5">
        <button
          type="button"
          css={[
            button.styles.link,
            text.styles.textXs,
            text.styles.textPrimary,
          ]}
          onClick={onAddCondition}
        >
          + Add another filter
        </button>
      </div>
    </>
  )
}
