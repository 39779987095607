import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import Table from '@groovehq/internal-design-system/lib/components/Table/Table'
import { selectCurrentUnarchivedAgentsFilteredByTeamId } from 'ducks/agents/selectors'
import { buildDrawerQueryParam } from 'ducks/drawers/util'
import { isBlank } from 'util/strings'
import { styles } from './styles'

const { CellWithAvatar } = Table

const formatName = ({ row: { original } }) => {
  const data = {
    value: isBlank(original.name) ? original.email : original.name,
    avatar: original.avatar_url,
    avatarSize: 'xl',
    userName: original.username,
  }
  return <CellWithAvatar data={data} />
}

const columns = [
  {
    Header: 'name',
    accessor: 'name',
    Cell: formatName,
    disableSortBy: true,
    width: 400,
  },
  {
    Header: 'email',
    accessor: 'email',
    disableSortBy: true,
  },
]

function TeamsAccordionAgentTable({ teamId, onEditTeam, editTeamDrawerId }) {
  const agents = useSelector(state =>
    selectCurrentUnarchivedAgentsFilteredByTeamId(state, teamId)
  )

  const handleOnAddUser = useCallback(
    () => {
      onEditTeam(teamId, {
        query: {
          ...buildDrawerQueryParam(editTeamDrawerId, 'drawerHideName', true),
        },
      })
    },
    [teamId, onEditTeam, editTeamDrawerId]
  )

  const handleOnEditTeam = useCallback(
    () => {
      onEditTeam(teamId, {
        query: {
          ...buildDrawerQueryParam(editTeamDrawerId, 'drawerHideName', false),
        },
      })
    },
    [teamId, onEditTeam, editTeamDrawerId]
  )

  return (
    <Table
      columns={columns}
      data={agents}
      tableWrapperStyles={styles.tableContainer}
    >
      <Button type="tertiary" size="small" onClick={handleOnEditTeam}>
        Edit {app.t('team')}
      </Button>
      <Button type="primary" size="small" onClick={handleOnAddUser}>
        Add {app.t('agents')}
      </Button>
    </Table>
  )
}

TeamsAccordionAgentTable.displayName = 'TeamsAccordionAgentTable'

export default TeamsAccordionAgentTable
