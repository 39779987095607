import Sidebar from 'shared/components/ui/Sidebar'
import { Notification } from 'shared/ui'
import { Button } from 'util/ui'

import { usePlanLimitationForDrawer } from 'ducks/integrations/hooks/usePlanLimitationForDrawer'
import AuthorizeModal from '../../shared/AuthorizeModal'
import Description from './Description'
import { ModalContent } from './styled'
import ToUpgradeButton from '../../shared/ToUpgradeButton'

export default function InstallModal({
  doCloseModal,
  error,
  open,
  showPlanLimitation,
}) {
  usePlanLimitationForDrawer(doCloseModal)

  return (
    <Sidebar
      headerChildren="Install a new App"
      footerChildren={
        !showPlanLimitation ? (
          <React.Fragment>
            <AuthorizeModal
              fluid
              message="Taking you to Hubspot"
              path="/auth/hubspot"
              params={{ return_to: window.location.href }}
              text="Install App"
              feature="premium_integrations"
            />
            <Button basic fluid onClick={doCloseModal}>
              Cancel
            </Button>
          </React.Fragment>
        ) : (
          <ToUpgradeButton />
        )
      }
      narrow
      onClose={doCloseModal}
      open={open}
    >
      <ModalContent>
        {error && (
          <Notification
            appear={false}
            fluid
            header={'Failed to Authenticate'}
            message={'We were unable to authenticate you with Hubspot.'}
            type="negative"
          />
        )}
        <Description showPlanLimitation={showPlanLimitation} />
      </ModalContent>
    </Sidebar>
  )
}
