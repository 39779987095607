import * as yup from 'yup'
import languages from 'util/languages'

// Rating wording displayed in email
export const emailInputsData = {
  'translations.email.intro': {
    schema: yup
      .string()
      .required('This field is required')
      .max(200, ({ max }) => `Please use ${max} characters or less`),
    props: {
      label: 'Rating heading',
      maxLength: 200,
    },
  },
  'translations.email.bad': {
    schema: yup
      .string()
      .required('This field is required')
      .max(40, ({ max }) => `Please use ${max} characters or less`),
    props: {
      label: 'Negative rating',
      maxLength: 40,
    },
  },
  'translations.email.ok': {
    schema: yup
      .string()
      .required('This field is required')
      .max(40, ({ max }) => `Please use ${max} characters or less`),
    props: {
      label: 'Mediocre rating',
      maxLength: 40,
    },
  },
  'translations.email.awesome': {
    schema: yup
      .string()
      .required('This field is required')
      .max(40, ({ max }) => `Please use ${max} characters or less`),
    props: {
      label: 'Positive rating',
      maxLength: 40,
    },
  },
}

// Wording displayed in web capture form
export const formInputsData = {
  'translations.form.header': {
    schema: yup
      .string()
      .required('This field is required')
      .max(200, ({ max }) => `Please use ${max} characters or less`),
    props: {
      label: 'Form header',
      maxLength: 200,
    },
  },
  'translations.form.placeholder': {
    schema: yup
      .string()
      .required('This field is required')
      .max(120, ({ max }) => `Please use ${max} characters or less`),
    props: {
      label: 'Comments field placeholder',
      maxLength: 120,
    },
  },
  'translations.form.submit': {
    schema: yup
      .string()
      .required('This field is required')
      .max(60, ({ max }) => `Please use ${max} characters or less`),
    props: {
      label: 'Submit button',
      maxLength: 60,
    },
  },
  'translations.form.thanks': {
    schema: yup
      .string()
      .required('This field is required')
      .max(200, ({ max }) => `Please use ${max} characters or less`),
    props: {
      label: 'Thanks message',
      maxLength: 200,
    },
  },
  'translations.form.bad': {
    schema: yup
      .string()
      .required('This field is required')
      .max(80, ({ max }) => `Please use ${max} characters or less`),
    props: {
      label: 'Negative rating',
      maxLength: 80,
    },
  },
  'translations.form.ok': {
    schema: yup
      .string()
      .required('This field is required')
      .max(80, ({ max }) => `Please use ${max} characters or less`),
    props: {
      label: 'Mediocre rating',
      maxLength: 80,
    },
  },
  'translations.form.awesome': {
    schema: yup
      .string()
      .required('This field is required')
      .max(80, ({ max }) => `Please use ${max} characters or less`),
    props: {
      label: 'Positive rating',
      maxLength: 80,
    },
  },
}

export const defaultValues = Object.keys({
  ...emailInputsData,
  ...formInputsData,
}).reduce((memo, k) => {
  // eslint-disable-next-line no-param-reassign
  memo[k] = ''
  return memo
}, {})

export const languagesData = []
Object.keys(languages).forEach(id => {
  const language = languages[id]
  if (!language.supported) return
  languagesData.push({
    id,
    name: language.name,
  })
})

const makeSchemaObject = data =>
  Object.keys(data).reduce((memo, fullKey) => {
    const key = fullKey.split('.').pop()
    // eslint-disable-next-line no-param-reassign
    memo[key] = data[fullKey].schema
    return memo
  }, {})

export const emailSchema = makeSchemaObject(emailInputsData)

export const formSchema = makeSchemaObject(formInputsData)
