import moment from 'moment'

const DEFAULT_USER_LANGUAGE = 'en-US'

export const browserLanguage = () => {
  const { language } = navigator || {}

  return language || DEFAULT_USER_LANGUAGE
}

// https://momentjs.com/docs/#/i18n/locale-data/ for list of formats
export const localeLongDateFormat = format => {
  return moment()
    .locale(browserLanguage())
    .localeData()
    .longDateFormat(format)
}

export function isLocalePercentageSymbolSuffixed() {
  const locale = browserLanguage()

  const parts = Intl.NumberFormat(locale, {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 4,
  }).formatToParts(0)

  return parts.findIndex(x => x.type === 'percentSign') === 1
}
