import Bugsnag from '@bugsnag/js'
// Push Subscriptions Browser API utilities

import { urlBase64ToUint8Array } from 'util/strings'

// We use a timeout for the case where serviceWorker.ready does not resolve
// (almost) immediately. Otherwise, since we optimistically show notifications
// as 'On' while this promise (sometimes never?) resolves.
export const getSWRegistration = () =>
  navigator.serviceWorker.ready.then(registration => registration)

export const getSubscription = () =>
  getSWRegistration()
    .then(registration => registration.pushManager.getSubscription())
    .catch(err => {
      console.error(`Error loading subscription ${JSON.stringify(err)}`)
      Bugsnag.notify(err)
      throw err
    })

export const subscribeOptions = publicKey => ({
  userVisibleOnly: true,
  applicationServerKey: urlBase64ToUint8Array(publicKey),
})

// Returns a promise which, when resolved, returns a new webPushSubscription object
export const subscribeUserToPush = publicKey =>
  getSWRegistration()
    .then(registration =>
      registration.pushManager.subscribe(subscribeOptions(publicKey))
    )
    .catch(err => {
      console.error(`Error creating subscription ${JSON.stringify(err)}`)
      Bugsnag.notify(err)
      throw err
    })
