import { css } from '@emotion/react'

import { rem } from '@groovehq/internal-design-system/lib/util'

const avatars = theme => css`
  display: flex;
  flex-direction: row-reverse;
  margin-left: ${rem(28)};
  & > * {
    margin-left: -${rem(12)};
    width: ${rem(26)};
    height: ${rem(26)};
    padding: 1px;
    background-color: ${theme.color.monochrome.white};
    border-radius: 50%;
    overflow: hidden;
  }
`

const tableContainer = css`
  && > * {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    thead th {
      border-radius: 0 !important;
    }
  }
`

const accordionPanel = css`
  & > *:first-of-type {
    padding-left: 18px;
  }
`

export const styles = {
  avatars,
  tableContainer,
  accordionPanel,
}
