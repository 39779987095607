import React, { useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentEntitiesSortedBy } from 'ducks/entities/selectors'
import Dropdown from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import {
  button,
  divider,
} from '@groovehq/internal-design-system/lib/styles/elements'
import { doFetchCannedReplyCategoriesV1 } from 'ducks/cannedReplies/operations'
import { selectCurrentUserIsAdminOrOwner } from 'ducks/currentUser/selectors'

const { Menu, Button } = Dropdown

const selectTitle = (all, key) => {
  const selected = all.find(item => item.id === key) || {
    name: 'Select category',
  }
  return selected.name
}

const allCategoriesId = 'all'
const allCategoriesItem = {
  id: allCategoriesId,
  name: 'All Categories',
}
const noCategoriesId = '0'
const noCategoriesItem = {
  id: noCategoriesId,
  name: 'Uncategorized',
}

const topSeperator = {
  id: 'topSeperator',
  name: '',
  type: 'seperator',
}

const CannedReplyCategorySelection = ({
  className,
  onSelectCategory,
  categoryId,
  size,
  showCreate = false,
  showAll = false,
  onCreateCategory,
  buttonRef,
  onButtonBlur,
  emptyHint,
}) => {
  const dispatch = useDispatch()
  const isManager = useSelector(selectCurrentUserIsAdminOrOwner)

  useEffect(
    () => {
      dispatch(
        doFetchCannedReplyCategoriesV1({
          skipLoaded: true,
        })
      )
    },
    [dispatch]
  )

  const stateCategories = useSelector(state =>
    selectCurrentEntitiesSortedBy(state, 'cannedReplyCategory', 'name', 'asc')
  )
  const categories = useMemo(
    () => {
      return showAll
        ? [
            allCategoriesItem,
            noCategoriesItem,
            topSeperator,
            ...stateCategories,
          ]
        : stateCategories
    },
    [stateCategories, showAll]
  )

  const selectedCategoryName = useMemo(
    () => selectTitle(categories, categoryId),
    [categories, categoryId]
  )

  // Create category button for category dropdown
  const dropdownHeader = useMemo(
    () => (
      <button type="button" css={button.styles.link} onClick={onCreateCategory}>
        + Create category
      </button>
    ),
    [onCreateCategory]
  )

  const overlay = useMemo(
    () => {
      return (
        <Menu>
          {categories.map(category => {
            const { id, name, type } = category
            if (type === 'seperator') {
              return <div key={id} css={divider.styles.base} role="separator" />
            }
            return (
              <Menu.Item key={id} itemKey={id} item={category}>
                {name}
              </Menu.Item>
            )
          })}
        </Menu>
      )
    },
    [categories]
  )

  return (
    <>
      <Dropdown
        emptyHint={emptyHint}
        overlay={overlay}
        onSelect={onSelectCategory}
        header={showCreate && isManager && dropdownHeader}
        menuHeight={400}
        selectedKey={categoryId}
      >
        <Button
          className={className}
          size={size}
          ref={buttonRef}
          onBlur={onButtonBlur}
        >
          {selectedCategoryName}
        </Button>
      </Dropdown>
    </>
  )
}

export default CannedReplyCategorySelection
