import { connect } from 'react-redux'
import { doInsertKbLink } from 'ducks/ticketing/operations'

import SearchKbView from 'ducks/ticketing/components/SearchKb'

const perform = {
  insertKbLink: doInsertKbLink,
}

export default connect(null, perform)(SearchKbView)
