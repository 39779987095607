import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'

const container = theme => css`
  background-color: ${theme.color.monochrome.superLight};
  border-radius: ${rem(12)};
  padding: ${rem(20)} ${rem(20)} ${rem(15)} ${rem(24)};
  position: relative;
  border: 1px solid transparent;
  min-height: 320px;
`

const spinner = theme => css`
  background-color: ${theme.color.monochrome.superLight};
  position: absolute;
  display: flex;
  top: 0;
  right: -1px;
  bottom: -120px;
  left: -1px;
  z-index: 2;
  border: 1px solid transparent;
  border-radius: ${rem(12)};
  transition: opacity 0.2ms;
`

const spinnerLoaded = css`
  opacity: 0;
`

export const styles = {
  container,
  spinner,
  spinnerLoaded,
}
