import { _splitQueryString, _processQueryStringPart } from './index'

export default function getFinalSearchQueryPart(queryString) {
  const parts = _splitQueryString(queryString)
  const part = parts && parts[parts.length - 1].replace(/:"$/, ':')

  if (!part || (!part.match(/ $/) && queryString.match(/ $/))) return null

  const partObj = part && _processQueryStringPart(part)
  const operator = partObj && partObj.key
  const value = partObj && partObj.values[0]

  return { operator, value, part }
}
