const decode = (str = '') => decodeURIComponent(str.replace(/\+/g, ' '))

export const parseQueryParams = string => {
  if (string === '') return {}
  const parts = string
    .split('?')
    .pop()
    .split('&')
    .map(p => {
      return p.split('=')
    })
  const params = {}
  parts.forEach(arr => {
    params[decode(arr[0])] = decode(arr[1])
  })
  return params
}

export const toQueryString = (params, prefix) => {
  if (!params) return ''
  const query = Object.keys(params).map(key => {
    const value = params[key]

    let queryStringKey = key
    if (params.constructor === Array) {
      queryStringKey = `${prefix}[]`
    } else if (params.constructor === Object) {
      queryStringKey = prefix ? `${prefix}[${key}]` : key
    }

    if (typeof value === 'object') return toQueryString(value, queryStringKey)
    return `${queryStringKey}=${encodeURIComponent(value)}`
  })
  return [...query].join('&')
}

export const queryParams = (url = window.location) => {
  if (typeof window === 'undefined') return {}
  return parseQueryParams(url.search)
}

// encodeURIComponent() will not encode: ~!*()'
// https://developer.mozilla.org/en/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent
export const fixedEncodeURIComponent = str =>
  encodeURIComponent(str).replace(
    /[!'()*]/g,
    char => `%${char.charCodeAt(0).toString(16)}`
  )

export const encodePeriods = str => str.replace(/\./g, '%2E')

export function replaceQueryParam(key, value, url = window.location) {
  const params = queryParams(url)
  params[key] = value
  return params
}

export function replaceParamInUrl(key, value, url = window.location) {
  const newParams = replaceQueryParam(key, value, url)
  // Using the name search here to keep it inline with the window.location
  // object
  const search = toQueryString(newParams)
  const { protocol, host, pathname } = url
  return `${protocol}//${host}${pathname}?${search}`
}
