// ========================================================================== //
//                                                                            //
//        ---------------------------------------------------------           //
//                                                                            //
//        __      ________ _____  ____   ____ _______ ______ _   _            //
//        \ \    / /  ____|  __ \|  _ \ / __ \__   __|  ____| \ | |           //
//         \ \  / /| |__  | |__) | |_) | |  | | | |  | |__  |  \| |           //
//          \ \/ / |  __| |  _  /|  _ <| |  | | | |  |  __| | . ` |           //
//           \  /  | |____| | \ \| |_) | |__| | | |  | |____| |\  |           //
//            \/   |______|_|  \_\____/ \____/  |_|  |______|_| \_|           //
//                                                                            //
//        ---------------------------------------------------------           //
//                                                                            //
//  Do NOT add any new triggers to the codebase. They can introduce infinite  //
//  loops and other pain points. If you need to trigger actions based on a    //
//  set of parameters in the data or component tree, you can either use the   //
//  routing/pages api or component lifecycle methods. You have been warned.   //
//                                                                            //
// ========================================================================== //

import { doFetchInboxBootstrapData } from 'actions/app'
import { doFetchCurrentTicket, doFetchRawEmail } from 'actions/tickets'
import {
  doSetCurrentFolder,
  doSetCurrentFolderFromTicket,
} from 'actions/bootstrap'
import { doFetchDefaultTicketSearch } from 'actions/search'
import { doSubscribeToRealtime } from 'actions/realtime'
import {
  shouldSetCurrentFolder,
  shouldSetCurrentFolderFromTicket,
} from 'selectors/bootstrap'
import {
  doFetchTopAssignments,
  doFetchRemainingTopAssignments,
} from 'actions/assignments'
import { shouldFetchTicketSelector } from 'selectors/tickets'
import { shouldFetchRawEmailSelector } from 'selectors/raw_emails'
import {
  shouldFetchInboxBootstrapData,
  shouldSubscribeToRealtimeSelector,
} from 'selectors/app'
import {
  selectShouldFetchDefaultTopAssignments,
  selectShouldFetchRemainingTopAssignments,
} from 'selectors/assignments'
import { shouldFetchDefaultPinnedSearch } from 'selectors/search'

const triggers = []

triggers.push({
  selector: shouldFetchInboxBootstrapData,
  action: doFetchInboxBootstrapData,
})

triggers.push({
  selector: selectShouldFetchDefaultTopAssignments,
  action: doFetchTopAssignments,
})

triggers.push({
  selector: selectShouldFetchRemainingTopAssignments,
  action: doFetchRemainingTopAssignments,
})

triggers.push({
  selector: shouldFetchDefaultPinnedSearch,
  action: doFetchDefaultTicketSearch,
})

triggers.push({
  selector: shouldFetchTicketSelector,
  action: doFetchCurrentTicket,
})

triggers.push({
  selector: shouldSetCurrentFolderFromTicket,
  action: doSetCurrentFolderFromTicket,
})

triggers.push({
  selector: shouldSetCurrentFolder,
  action: doSetCurrentFolder,
})

triggers.push({
  selector: shouldFetchRawEmailSelector,
  action: doFetchRawEmail,
})

triggers.push({
  selector: shouldSubscribeToRealtimeSelector,
  action: doSubscribeToRealtime,
})

const runTriggers = () =>
  triggers.forEach(trigger => {
    const state = app.store.getState()
    if (trigger.selector(state)) app.store.dispatch(trigger.action())
  })

module.exports = {
  triggers,
  runTriggers,
}
