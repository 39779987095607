import React, { useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { arrayOf, string, shape, oneOf, func } from 'prop-types'
import Dropdown from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import CheckboxGroup from '@groovehq/internal-design-system/lib/components/Checkbox/CheckboxGroup'

import { selectMailboxesIncludingInaccessible } from 'selectors/mailboxes/selectMailboxesIncludingInaccessible'

import { styles } from './MailboxSelection.styles'
import { mailboxTypes } from './MailboxSelection.data'

const { Menu, Button } = Dropdown

const menu = data => (
  <Menu>
    {data.map(({ title, key }) => (
      <Menu.Item key={key} itemKey={key}>
        {title}
      </Menu.Item>
    ))}
  </Menu>
)

const selectTitle = (all, key) => {
  const selected = all.find(item => item.key === key) || {
    title: '',
  }
  return selected.title
}

const MailboxSelection = ({
  className,
  dropdownBtnStyles,
  checkboxListStyles,
  selectedIds,
  usedMailboxIds = [],
  type,
  onMailboxListChange,
  onMailboxVisibilityTypeChange,
  dropdownBtnSize,
  mailboxVisibilityTypeRef,
  onMailboxVisibilityTypeBlur,
  showDropdown = true,
}) => {
  const mailboxes = useSelector(selectMailboxesIncludingInaccessible)

  const selectedMailboxTypeTitle = useMemo(
    () => selectTitle(mailboxTypes, type),
    [type]
  )

  const selectedDopdownBtnStyles = dropdownBtnStyles
    ? [dropdownBtnStyles]
    : [styles.dropdownBtn]

  const alreadySelectedMailboxes = useMemo(
    () =>
      mailboxes.filter(
        mailbox =>
          usedMailboxIds.indexOf(mailbox.gid) > -1 ||
          usedMailboxIds.indexOf('all') > -1
      ),
    [mailboxes, usedMailboxIds]
  )

  const mailboxCheckboxList = useMemo(
    () => {
      return mailboxes
        .filter(mailbox => {
          return alreadySelectedMailboxes.indexOf(mailbox) === -1
        })
        .map(mailbox => {
          return {
            id: mailbox.gid,
            label: mailbox.name,
            value: mailbox.name,
            checked: selectedIds.indexOf(mailbox.gid) > -1,
          }
        })
    },
    [mailboxes, selectedIds, alreadySelectedMailboxes]
  )

  // Handle mailboxes selection
  const handleSelectedMailboxesChange = useCallback(
    ids => {
      onMailboxListChange(ids.map(sm => sm.id))
    },
    [onMailboxListChange]
  )

  const handleOnMailboxVisibilityTypeChange = useCallback(
    mailboxType => {
      // reset all mailbox checkboxes to be empty (default)
      // so the form won't be dirty if switch back to the default type
      if (mailboxType === 'all') onMailboxListChange([])
      onMailboxVisibilityTypeChange(mailboxType)
    },
    [onMailboxVisibilityTypeChange, onMailboxListChange]
  )

  return (
    <div className={className}>
      {showDropdown && (
        <div css={styles.dropdownContainer}>
          <Dropdown
            overlay={menu(mailboxTypes)}
            onSelect={handleOnMailboxVisibilityTypeChange}
            selectedKey={type}
          >
            <Button
              css={selectedDopdownBtnStyles}
              size={dropdownBtnSize}
              ref={mailboxVisibilityTypeRef}
              onBlur={onMailboxVisibilityTypeBlur}
            >
              {selectedMailboxTypeTitle}
            </Button>
          </Dropdown>
        </div>
      )}
      {type !== 'all' && (
        <div css={checkboxListStyles}>
          <CheckboxGroup
            options={mailboxCheckboxList}
            onChange={handleSelectedMailboxesChange}
          />
        </div>
      )}
    </div>
  )
}

MailboxSelection.propTypes = {
  className: string,
  dropdownBtnStyles: shape({
    name: string,
    styles: string,
  }),
  checkboxListStyles: shape({
    name: string,
    styles: string,
  }),
  selectedIds: arrayOf(string),
  type: oneOf(['all', 'selected']),
  onMailboxListChange: func,
  onMailboxVisibilityTypeChange: func,
  dropdownBtnSize: oneOf(['big', 'small']),
}

MailboxSelection.defaultProps = {
  className: undefined,
  dropdownBtnStyles: undefined,
  checkboxListStyles: undefined,
  selectedIds: [],
  type: mailboxTypes[0].key,
  onMailboxListChange() {},
  onMailboxVisibilityTypeChange() {},
  dropdownBtnSize: 'big',
}

export default MailboxSelection
