import React, { useCallback } from 'react'
import { v4 as uuidv4 } from 'uuid'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import {
  list,
  button,
} from '@groovehq/internal-design-system/lib/styles/elements'

import { styles } from './styles'
import DropDownOption from './DropDownOption'

const DropdownOptions = ({
  className,
  options,
  move,
  remove,
  append,
  register,
  name,
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  const handleAddOption = useCallback(
    () => {
      append({
        value: uuidv4(),
        label: '',
      })
    },
    [append]
  )

  function handleDragEnd(event) {
    const { active, over } = event
    const oldIndex = options.findIndex(item => item.value === active.id)
    const newIndex = options.findIndex(item => item.value === over.id)
    move(oldIndex, newIndex)
  }
  return (
    <>
      <ul className={className} css={[list.styles.preflight, styles.list]}>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={options}
            strategy={verticalListSortingStrategy}
          >
            {options.map(({ value }, index) => (
              <DropDownOption
                key={value}
                value={value}
                index={index}
                register={register}
                name={name}
                onRemove={remove}
              />
            ))}
          </SortableContext>
        </DndContext>
      </ul>
      <button
        type="button"
        css={[button.styles.preflight, button.styles.link]}
        onClick={handleAddOption}
      >
        + Add option
      </button>
    </>
  )
}

export default DropdownOptions
