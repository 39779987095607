export const ASSIGNED_AGENT = 'ASSIGNED_AGENT'
export const ASSIGNED_GROUP = 'ASSIGNED_GROUP'

export const inputsData = {
  folderName: {
    name: 'folderName',
    label: 'Folder name',
    placeholder: 'Enter folder name...',
    value: '',
  },
}

export const rules = {
  folderName: { required: true, maxLength: 20 },
}

const isOperator = [
  {
    name: 'is',
    value: 'EQ',
  },
]

const isNotOperator = [
  {
    name: 'is not',
    value: 'NOT_EQ',
  },
]

const lessThanOperator = [
  {
    name: 'less than',
    value: 'LT',
  },
]

const lessThanOrEqOperator = [
  {
    name: 'less than or equal',
    value: 'LTEQ',
  },
]

const greaterThanOperator = [
  {
    name: 'greater than',
    value: 'GT',
  },
]

const greaterThanOrEqOperator = [
  {
    name: 'greater than or equal',
    value: 'GTEQ',
  },
]

const lessThanOrGreaterThanOperators = [
  ...lessThanOperator,
  ...greaterThanOperator,
]

const numberOperators = [
  ...lessThanOperator,
  ...lessThanOrEqOperator,
  ...greaterThanOperator,
  ...greaterThanOrEqOperator,
]

const containsDoesNotContainOperators = [
  {
    name: 'contains',
    value: 'CONTAINS',
  },
  {
    name: 'does not contain',
    value: 'DOES_NOT_CONTAIN',
  },
]

const isOrIsNotOperator = [...isOperator, ...isNotOperator]

export const folderVariables = {
  parameters: [
    {
      name: 'Tags',
      value: 'TAGS',
    },
    {
      name: 'Priority',
      value: 'PRIORITY',
    },
    {
      name: 'Starred',
      value: 'STARRED',
    },
    {
      name: 'Status',
      value: 'STATUS',
    },
    {
      name: 'Deleted',
      value: 'DELETED',
    },
    {
      name: 'Channel Type',
      value: 'CHANNEL',
    },
    {
      name: 'Snoozed by',
      value: 'SNOOZE_STATE',
    },
    {
      name: 'Snooze until',
      value: 'SNOOZE_UNTIL',
    },
    {
      name: `Assigned ${app.t('agent')}`,
      value: ASSIGNED_AGENT,
    },
    {
      name: 'Assigned group',
      value: ASSIGNED_GROUP,
    },
    {
      name: 'Mentions',
      value: 'MENTIONS',
    },
    {
      name: 'Drafts',
      value: 'DRAFTS',
    },
    {
      name: 'Hours since updated',
      value: 'HOURS_SINCE_UPDATED',
    },
    {
      name: 'Hours since status changed',
      value: 'HOURS_SINCE_STATUS_CHANGED',
    },
    {
      name: 'Hours since new',
      value: 'HOURS_SINCE_NEW',
    },
    {
      name: 'Hours since open',
      value: 'HOURS_SINCE_OPEN',
    },
    {
      name: 'Hours since pending',
      value: 'HOURS_SINCE_PENDING',
    },
    {
      name: 'Hours since closed',
      value: 'HOURS_SINCE_CLOSED',
    },
    {
      name: 'Hours since assigned',
      value: 'HOURS_SINCE_ASSIGNED',
    },
    {
      name: 'Hours since last customer message',
      value: 'HOURS_SINCE_LAST_UNANSWERED_USER_MESSAGE',
    },
    {
      name: 'Interaction count',
      value: 'INTERACTION_COUNT',
    },
    {
      name: `Following ${app.t('agents')}`,
      value: 'FOLLOWING_AGENTS',
    },
  ],
  param_operators: {
    TAGS: [...containsDoesNotContainOperators],
    PRIORITY: [...isOrIsNotOperator, ...numberOperators],
    STARRED: [...isOperator],
    STATUS: [...isOrIsNotOperator, ...numberOperators],
    DELETED: [...isOperator],
    CHANNEL: [...isOrIsNotOperator],
    SNOOZE_STATE: [...isOrIsNotOperator],
    SNOOZE_UNTIL: [...numberOperators],
    ASSIGNED_AGENT: [...isOrIsNotOperator],
    ASSIGNED_GROUP: [...isOrIsNotOperator],
    MENTIONS: [...containsDoesNotContainOperators],
    DRAFTS: [...isOrIsNotOperator],
    HOURS_SINCE_UPDATED: [...lessThanOrGreaterThanOperators],
    HOURS_SINCE_STATUS_CHANGED: [...lessThanOrGreaterThanOperators],
    HOURS_SINCE_NEW: [...lessThanOrGreaterThanOperators],
    HOURS_SINCE_OPEN: [...lessThanOrGreaterThanOperators],
    HOURS_SINCE_FOLLOW_UP: [...lessThanOrGreaterThanOperators],
    HOURS_SINCE_PENDING: [...lessThanOrGreaterThanOperators],
    HOURS_SINCE_CLOSED: [...lessThanOrGreaterThanOperators],
    HOURS_SINCE_ASSIGNED: [...lessThanOrGreaterThanOperators],
    HOURS_SINCE_LAST_UNANSWERED_USER_MESSAGE: [
      ...lessThanOrGreaterThanOperators,
    ],
    INTERACTION_COUNT: [...lessThanOrGreaterThanOperators],
    FOLLOWING_AGENTS: [...containsDoesNotContainOperators],
  },
  operators: {
    CONTAINS: 'contains',
    DOES_NOT_CONTAIN: 'does not contain',
    EQ: 'is',
    NOT_EQ: 'is not',
    LT: 'less than',
    LTEQ: 'less than or equal',
    GT: 'greater than',
    GTEQ: 'greater than or equal',
  },
  values: {
    labels: '',
    TAGS: { dataType: 'TAGS', paramType: 'names' },
    PRIORITY: [
      {
        name: 'Low',
        value: 'low',
      },
      {
        name: 'Medium',
        value: 'medium',
      },
      {
        name: 'High',
        value: 'high',
      },
      {
        name: 'Urgent',
        value: 'urgent',
      },
    ],
    STARRED: [
      {
        name: 'True',
        value: 'true',
      },
      {
        name: 'False',
        value: 'false',
      },
    ],
    STATUS: [
      {
        name: 'Unread',
        value: 'unread',
        disabled: false,
      },
      {
        name: 'Opened',
        value: 'opened',
        disabled: false,
      },
      {
        name: 'Closed',
        value: 'closed',
        disabled: false,
      },
      {
        name: 'Spam',
        value: 'spam',
        disabled: false,
      },
    ],
    DELETED: [
      {
        name: 'True',
        value: 'true',
      },
      {
        name: 'False',
        value: 'false',
      },
    ],
    CHANNEL: [
      {
        name: 'Widget',
        value: 'Widget',
      },
      {
        name: 'Facebook',
        value: 'Facebook',
      },
      {
        name: 'Twitter',
        value: 'Twitter',
      },
      {
        name: 'Chat',
        value: 'Chat',
      },
      {
        name: 'Email',
        value: 'Email',
      },
    ],
    SNOOZE_STATE: { valueType: 'Agent', currentUser: true, anyone: true },
    SNOOZE_UNTIL: [
      {
        name: '4 hours',
        value: '14400',
      },
      {
        name: '2 days',
        value: '172800',
      },
      {
        name: '4 days',
        value: '345600',
      },
      {
        name: '1 week',
        value: '604800',
      },
      {
        name: '2 weeks',
        value: '1209600',
      },
      {
        name: '1 month',
        value: '2592000',
      },
      {
        name: 'Indefinitely',
        value: 'indefinitely',
      },
    ],
    ASSIGNED_AGENT: { valueType: 'Agent', currentUser: true, unassigned: true },
    ASSIGNED_GROUP: { valueType: 'Group', unassigned: true },
    MENTIONS: { dataType: 'TEAMMATES', allowSelectCurrentUser: true },
    DRAFTS: { valueType: 'Agent', currentUser: true },
    HOURS_SINCE_UPDATED: '',
    HOURS_SINCE_STATUS_CHANGED: '',
    HOURS_SINCE_NEW: '',
    HOURS_SINCE_OPEN: '',
    HOURS_SINCE_FOLLOW_UP: '',
    HOURS_SINCE_PENDING: '',
    HOURS_SINCE_CLOSED: '',
    HOURS_SINCE_ASSIGNED: '',
    HOURS_SINCE_LAST_UNANSWERED_USER_MESSAGE: '',
    INTERACTION_COUNT: '',
    FOLLOWING_AGENTS: { dataType: 'TEAMMATES', allowSelectCurrentUser: true },
  },
}
