import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { string, bool, oneOf } from 'prop-types'
import { doDeleteRules } from 'ducks/rules/actions'
import EntityDeleteDrawer from '../EntityDelete'
import RuleDeleteContent from './Content'

const RuleDeleteDrawer = props => {
  const {
    title,
    deleteBtnText,
    open,
    drawerResourceId,
    drawerDeleteMode,
    onClose,
    onExit,
    onRemove,
  } = props

  const dispatch = useDispatch()

  const ids = useMemo(
    () => {
      return drawerResourceId.split(',')
    },
    [drawerResourceId]
  )
  const handleOnRemove = useCallback(
    () => {
      dispatch(doDeleteRules(ids))
      if (onRemove) onRemove()
    },
    [dispatch, ids, onRemove]
  )

  const handleOnClose = useCallback(
    () => {
      const closeOptions = {}
      onClose(closeOptions)
    },
    [onClose]
  )

  return (
    <EntityDeleteDrawer
      {...props}
      onRemove={handleOnRemove}
      onClose={onExit}
      tertiaryBtnText="Cancel"
      onCancel={handleOnClose}
      title={title}
      deleteBtnText={deleteBtnText}
      open={open}
      hardDelete
    >
      <RuleDeleteContent ids={ids} deleteMode={drawerDeleteMode} />
    </EntityDeleteDrawer>
  )
}

RuleDeleteDrawer.propTypes = {
  title: string,
  deleteBtnText: string,
  open: bool.isRequired,
  drawerDeleteMode: oneOf(['ids', 'query']),
  drawerResourceId: string,
}

RuleDeleteDrawer.defaultProps = {
  title: 'Delete rule',
  deleteBtnText: 'Delete',
  drawerDeleteMode: 'ids',
  drawerResourceId: '',
  queryId: null,
}

export default RuleDeleteDrawer
