import { connect } from 'react-redux'
import { selectCurrentInbox } from 'ducks/billing/selectors/selectCurrentInbox'
import { isAllowedAccessToFeature } from 'ducks/billing/util'

import AuthorizeModalView from './View'

const select = state => ({
  inboxPlan: selectCurrentInbox(state),
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    ...{
      isAllowedAccessToFeature: isAllowedAccessToFeature(
        stateProps.inboxPlan,
        ownProps.feature
      ),
    },
  }
}

export default connect(select, null, mergeProps)(AuthorizeModalView)
