import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util'

const tagIcon = css`
  && {
    width: ${rem(13)};
    height: ${rem(13)};
  }
`

export const styles = { tagIcon }
