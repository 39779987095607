import { browserLanguage } from 'util/locale'

export const convertFromCents = number => {
  let num = Number.parseFloat(number)
  if (Number.isNaN(num)) {
    num = 0
  }
  return (num / 100).toFixed(2)
}

export const currencyCodeToSymbol = currencyCode => {
  const locale = browserLanguage()

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
  })
    .formatToParts(0)
    .find(p => p.type === 'currency')?.value
}

export const moneyString = ({ amount, currencyCode }) => {
  const amountEmpty = amount === null || amount === undefined
  if (amountEmpty && !currencyCode) return ''

  if (amountEmpty) {
    return currencyCodeToSymbol(currencyCode)
  }

  const locale = browserLanguage()
  const value = convertFromCents(amount)
  if (!currencyCode) {
    return new Intl.NumberFormat(locale, {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value)
  }

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
  }).format(value)
}

export const shopifyMoneyString = ({ amount, currencyCode }) =>
  moneyString({ amount: amount * 100, currencyCode })
