import { createSelector } from 'reselect'
import { selectAccountUsageOnboarding } from 'selectors/app'
import { selectCanOnboardAccount } from '.'
import { selectShowOnboardingWorkflow } from './selectShowOnboardingWorkflow'

let isInitiallyCompleted = null
export const selectShowOnboardingWorkflowProgressIndicator = createSelector(
  selectCanOnboardAccount,
  selectShowOnboardingWorkflow,
  selectAccountUsageOnboarding,
  (canOnboardAccount, showOnboardingWorkflow, usageOnboarding) => {
    if (isInitiallyCompleted === null)
      isInitiallyCompleted = !!usageOnboarding.completed
    return (
      showOnboardingWorkflow || (canOnboardAccount && !isInitiallyCompleted)
    )
  }
)
