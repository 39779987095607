import React, { useMemo } from 'react'
import cn from 'classnames'
import Modal from '@groovehq/internal-design-system/lib/components/Modal/Modal'
import ProgressDots from '@groovehq/internal-design-system/lib/components/ProgressDots/ProgressDots'
import ChannelOptions from './ChannelOptions'
import { styles } from './styles'
import useEmailChannelSelector from './useEmailChannelSelector'
import { CHANNEL_EMAIL_OPTIONS } from '../Channels.data'

const SelectEmailChannel = ({
  onClose,
  onExit,
  previousDrawer,
  drawerSource: source = 'settings',
  drawerId,
}) => {
  const {
    creationStepCount,
    handleBack,
    handleSelect,
  } = useEmailChannelSelector({
    onClose,
    onExit,
    previousDrawer,
    drawerSource: source,
    drawerId,
    options: CHANNEL_EMAIL_OPTIONS,
  })

  const channelList = useMemo(() => {
    return Object.keys(CHANNEL_EMAIL_OPTIONS).map(provider => {
      const channel = {
        provider,
        ...CHANNEL_EMAIL_OPTIONS[provider],
      }
      return channel
    })
  }, [])

  return (
    <Modal
      onClose={onExit}
      portal="#drawer-root"
      dataTestId="drawer-select-email-channel"
      className={cn('grui pb-14', channelList.length > 2 && 'mt-18')}
      open
      backdropTransparency="light"
    >
      <ProgressDots
        count={creationStepCount}
        now={1}
        className="grui mt-4 mb-13"
      />
      <Modal.Title className="grui text-center">
        Who is your email provider?
      </Modal.Title>
      <Modal.Description css={styles.description}>
        Follow these steps to automatically get emails sent to your specific
        email inbox(es) in Groove.
      </Modal.Description>
      <ChannelOptions
        channelList={channelList}
        onSelect={handleSelect}
        className="grui mt-19 mb-13"
      />
      {previousDrawer && (
        <div className="grui text-center">
          <Modal.Button type="link" onClick={handleBack}>
            Back
          </Modal.Button>
        </div>
      )}
    </Modal>
  )
}

export default SelectEmailChannel
