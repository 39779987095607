import { ADD_CHANNEL_PAGE, CHANNEL_OVERVIEW_PAGE } from '../pages'

const defaultCustomerInformationState = {
  nextPage: ADD_CHANNEL_PAGE,
}

export default function customerInformationReducer(state, { type }) {
  switch (type) {
    case CHANNEL_OVERVIEW_PAGE:
      return {
        ...state,
        nextPage: CHANNEL_OVERVIEW_PAGE,
      }
    default:
      return state || defaultCustomerInformationState
  }
}
