import React, { useCallback, useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Accordion from '@groovehq/internal-design-system/lib/components/Accordion/Accordion'
import Skeleton from '@groovehq/internal-design-system/lib/components/Skeleton/Skeleton'
import { DRAWER_TYPE_TEAM_UPDATE } from 'ducks/drawers/types'
import { useDrawer } from 'ducks/drawers/hooks'
import doFetchAllAgents from 'actions/agents/doFetchAllAgents'

import { selectCurrentTeamsSortedByName } from 'ducks/teams/selectors'
import { styles } from './styles'

import AgentTable from './AgentTable'
import Title from './Title'

const TeamsAccordion = ({ loading, isEmpty }) => {
  const dispatch = useDispatch()
  const teams = useSelector(selectCurrentTeamsSortedByName)

  useEffect(
    () => {
      if (loading) {
        dispatch(doFetchAllAgents())
      }
    },
    [dispatch, loading]
  )

  const {
    drawerId: editTeamDrawerId,
    openDrawer: openEditTeamDrawer,
  } = useDrawer(DRAWER_TYPE_TEAM_UPDATE)

  const handleOnEditTeam = useCallback(
    (teamId, options) => {
      openEditTeamDrawer(teamId, options)
    },
    [openEditTeamDrawer]
  )

  const teamAccordionItems = useMemo(
    () => {
      return teams.map(({ id, name }) => ({
        key: id,
        title: <Title teamId={id} name={name} />,
        content: (
          <AgentTable
            teamId={id}
            onEditTeam={handleOnEditTeam}
            editTeamDrawerId={editTeamDrawerId}
          />
        ),
      }))
    },
    [teams, handleOnEditTeam, editTeamDrawerId]
  )

  return (
    <>
      <div className="grui px-1">
        {loading && <Skeleton className="grui mb-5" count={4} height={51} />}
        {!loading &&
          !isEmpty && (
            <Accordion list={teamAccordionItems} css={styles.accordionPanel} />
          )}
      </div>
    </>
  )
}

TeamsAccordion.displayName = 'TeamsAccordion'

export default TeamsAccordion
