import dot from 'dot-object'

// contact fields names
export const WIDGET_CONTACT_ID = 'contact.id'
export const WIDGET_CONTACT_ENABLED = 'contact.enabled'
export const WIDGET_CONTACT_ATTACHMENTS_ENABLED = 'contact.attachmentsEnabled'
export const WIDGET_CONTACT_ARTICLES_ENABLED =
  'contact.articleSuggestionsEnabled'
export const WIDGET_CONTACT_AVATARS_ENABLED = 'contact.avatarsEnabled'
export const WIDGET_CONTACT_FORM_FIELDS = 'contact.customFields'
export const WIDGET_CONTACT_CONSENT_ENABLED = 'contact.consentEnabled'
export const WIDGET_CONTACT_CONSENT_OPTIN_ENABLED =
  'contact.consentOptinEnabled'
export const WIDGET_CONTACT_CONSENT_OPTIN_SELECTED =
  'contact.consentOptinSelected'
export const WIDGET_CONTACT_CONSENT_MESSAGE = 'contact.consentMessage'

// React hook form will autogenerate an id and override the original id for each array item
// https://react-hook-form.com/api/usefieldarray
export const generateDefaultValueForFormFields = (data = [], isReverse) => {
  return data.map(item => {
    const itemToUpdate = { ...item }
    if (isReverse) {
      delete itemToUpdate.fieldId
    } else {
      itemToUpdate.fieldId = item.id
    }
    return itemToUpdate
  })
}

export const generateDefaultValues = settings =>
  // React-hook-form converts name with dot to be nested objects
  // so need to set the default values to be nested objects
  dot.object({
    [WIDGET_CONTACT_ID]: settings[WIDGET_CONTACT_ID],
    [WIDGET_CONTACT_ENABLED]: settings[WIDGET_CONTACT_ENABLED],
    [WIDGET_CONTACT_ATTACHMENTS_ENABLED]:
      settings[WIDGET_CONTACT_ATTACHMENTS_ENABLED],
    [WIDGET_CONTACT_ARTICLES_ENABLED]:
      settings[WIDGET_CONTACT_ARTICLES_ENABLED],
    [WIDGET_CONTACT_AVATARS_ENABLED]: settings[WIDGET_CONTACT_AVATARS_ENABLED],
    [WIDGET_CONTACT_FORM_FIELDS]: generateDefaultValueForFormFields(
      settings[WIDGET_CONTACT_FORM_FIELDS]
    ),
    [WIDGET_CONTACT_CONSENT_ENABLED]: settings[WIDGET_CONTACT_CONSENT_ENABLED],
    [WIDGET_CONTACT_CONSENT_OPTIN_ENABLED]:
      settings[WIDGET_CONTACT_CONSENT_OPTIN_ENABLED],
    [WIDGET_CONTACT_CONSENT_OPTIN_SELECTED]:
      settings[WIDGET_CONTACT_CONSENT_OPTIN_SELECTED],
    [WIDGET_CONTACT_CONSENT_MESSAGE]: settings[WIDGET_CONTACT_CONSENT_MESSAGE],
  })
