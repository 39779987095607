import { createSelector } from 'reselect'
import { memoize } from 'util/memoization'
import { pick } from 'util/objects'

import { selectPrimaryKnowledgeBaseId } from 'selectors/app'
import { selectCurrentPayload } from 'selectors/location'
import { selectById as selectThemesById } from 'subapps/kb/selectors/themes'
import { selectIds } from './selectIds'
import { base } from './base'

export const selectById = state => base(state).byId || {}

export const selectIsKbBootstrapped = state => !!base(state).bootstrapped

export const selectIsSaving = state => base(state).isSaving
export const selectIsKbsLoading = state => base(state).isKbsLoading
export const selectIsLoading = state => base(state).isLoading
export const selectError = state => base(state).error

const decorateKnowledgeBaseSEOSettings = memoize(knowledgeBase => {
  const metaRobots = (knowledgeBase.metaRobots || '').split(',')
  return {
    metaIndex: metaRobots.includes('INDEX'),
  }
})

const decorateKnowledgeBaseIpAddresses = memoize(knowledgeBase => {
  const ipAddresses = (knowledgeBase.ipAddresses || []).join('\n')
  return {
    ipAddresses,
  }
})

export const selectKnowledgeBases = createSelector(
  selectById,
  selectIds,
  (byId, ids) => ids.map(id => byId[id])
)

export const selectKnowledgeBaseTotal = createSelector(
  selectIds,
  ids => (ids ? ids.length : 0)
)

export const selectPrimaryKnowledgeBase = createSelector(
  selectPrimaryKnowledgeBaseId,
  selectKnowledgeBases,
  (id, kbs) => kbs.find(kb => kb.id === id)
)

export const selectCurrentKnowledgeBaseId = createSelector(
  selectCurrentPayload,
  payload => payload.knowledgeBaseId && payload.knowledgeBaseId.toString()
)

export const selectIsCurrentKnowledgeBasePrimary = createSelector(
  selectPrimaryKnowledgeBaseId,
  selectCurrentKnowledgeBaseId,
  (primaryId, currentId) => primaryId === currentId
)

export const selectLastCreatedKbId = state => base(state).lastCreatedKbId

export const selectCurrentKnowledgeBase = createSelector(
  selectKnowledgeBases,
  selectCurrentKnowledgeBaseId,
  (kbs, id) => {
    const knowledgeBase = kbs.find(kb => kb.id === id) || {}
    return {
      ...knowledgeBase,
      ...decorateKnowledgeBaseSEOSettings(knowledgeBase),
      ...decorateKnowledgeBaseIpAddresses(knowledgeBase),
    }
  }
)

export const selectCurrentKnowledgeBaseLocale = createSelector(
  selectCurrentKnowledgeBase,
  kb => kb.language
)

export const selectCurrentKnowledgeBaseCustomDomainFields = createSelector(
  selectCurrentKnowledgeBase,
  kb => {
    return pick(['customDomain', 'customDomainLetsencrypt'], kb, true)
  }
)

export const selectCurrentKnowledgeBaseUrl = createSelector(
  selectCurrentKnowledgeBase,
  kb => kb.siteUrl
)

export const selectIsCurrentKnowledgeBaseCustomScriptsEnabled = createSelector(
  selectCurrentKnowledgeBase,
  kb => kb.customScriptsEnabled === true
)

export const selectRatingsType = createSelector(
  selectCurrentKnowledgeBase,
  kb => kb.ratingsType
)

export const selectActiveTheme = createSelector(
  selectCurrentKnowledgeBase,
  selectThemesById,
  (kb, themes) => themes[kb.themeId] || {}
)

export const selectIsThemeChanged = state =>
  state.themeId !== state.publishedThemeId

export const selectKnowledgeBasesForDropdown = createSelector(
  selectKnowledgeBases,
  kbs =>
    kbs.sort((a, b) => a.title >= b.title).map(kb => {
      return {
        key: kb.id,
        text: kb.title,
        value: kb.id,
      }
    })
)
