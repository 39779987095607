import React, { useEffect, useRef, forwardRef } from 'react'
import SearchField from '@groovehq/internal-design-system/lib/components/Search/Search'
import { useCombinedRefs } from '@groovehq/internal-design-system/lib/hooks'
import { styles } from './styles'

const Search = forwardRef(({ shouldFocus, ...rest }, ref) => {
  const searchRef = useRef()
  const combinedRefs = useCombinedRefs(ref, searchRef)
  useEffect(
    () => {
      if (shouldFocus) {
        searchRef.current.focus()
      }
    },
    [shouldFocus]
  )
  return (
    <SearchField
      {...rest}
      ref={combinedRefs}
      css={styles.search}
      className="grui w-100 px-3"
    />
  )
})

export default Search
