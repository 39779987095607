import confetti from 'canvas-confetti'

// https://www.color-hex.com/color-palette/11374
export const colors = ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a']

// https://www.kirilv.com/canvas-confetti/#snow
export const snow = ({
  duration: inputDuration,
  colors: inputColors = colors,
}) => {
  const duration = inputDuration * 1000
  const animationEnd = Date.now() + duration
  let skew = 1
  ;(function frame() {
    const timeLeft = animationEnd - Date.now()
    const ticks = Math.max(30, 75 * (timeLeft / duration))
    skew = Math.max(0.8, skew - 0.001)

    confetti({
      particleCount: 1,
      startVelocity: 0,
      ticks,
      origin: {
        x: Math.random(),
        // since particles fall down, skew start toward the top
        y: Math.random() * skew - 0.4,
      },
      colors: [inputColors[Math.floor(Math.random() * inputColors.length)]],
    })

    if (timeLeft > 0) {
      requestAnimationFrame(frame)
    }
  })()
}
