import logo from 'assets/integrations/jira-cloud/icon-256.png'
import actionsScreenShot from 'assets/integrations/jira-cloud/screenshot-actions.jpg'
import linkScreenShot from 'assets/integrations/jira-cloud/screenshot-link.jpg'
import sidebarScreenShot from 'assets/integrations/jira-cloud/screenshot-sidebar.jpg'

import IntegrationsModal from '../../shared/Modal'
import JiraCloudSettingsForm from './JiraCloudSettingsForm'
import JiraCloudInstallDescription from './JiraCloudInstallDescription'

export default function IntegrationsJiraCloudModal(props) {
  return (
    <IntegrationsModal
      {...props}
      InstallDescription={JiraCloudInstallDescription}
      installPath={'/auth/atlassian_oauth2'}
      logo={logo}
      oauth_provider="atlassian_oauth2"
      premium
      provider="jira_cloud"
      providerStylized="Jira Cloud"
      tagLine="Create and link Jira Cloud issues right from your conversations, and get updated on any changes."
      screenShots={{
        'A screen shot of the Jira Cloud actions in Groove': actionsScreenShot,
        'A screen shot of a linking Jira issues in Groove': linkScreenShot,
        'A screen shot of Jira Cloud issues in Groove': sidebarScreenShot,
      }}
      SettingsForm={JiraCloudSettingsForm}
    />
  )
}
