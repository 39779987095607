import { connect } from 'react-redux'
import { selectSelectedTicketIds } from 'selectors/ticket_list/base'
import BulkActionBarView from 'components/ConversationList/Header/TitleBar/BulkActionBar'

const select = state => {
  const selectedTicketIds = selectSelectedTicketIds(state)
  return {
    selectedCount: selectedTicketIds.length,
    conversationIds: selectedTicketIds,
  }
}

export default connect(select)(BulkActionBarView)
