import config from 'config'
/* eslint-disable no-underscore-dangle */
import { getValueByPath } from 'util/objects'

// this has private storage to not modify root store
// when not needed (perf optimization)
const _queryHistory = []

const wantsMore = config.isAlpha || config.isDevelopment || config.isDocker

// this controls how much we want to store
const MAX_REMEMBERED_SEARCHES = wantsMore ? 20 : 5

// how many searches to remove with each GC run
const REMOVE_SEARCH_COUNT = 2

const garbageCollectSearches = (n, state, queryHistory) => {
  const queryIdsToRemove = []
  // remove N queries from history, marking them for GC
  for (let i = 0; i < n; i += 1) {
    // yup, mutating here
    queryIdsToRemove.push(queryHistory.shift())
  }
  const ticketsToRemove = {}
  const ticketsToKeep = {}
  const customersToRemove = {}
  const customersToKeep = {}
  // copies of branches
  const newByQueryId = { ...state.search.byQueryId }
  const newTicketsById = { ...state.tickets.byId }
  const newCustomersById = { ...state.customers.byId }

  // find all queries that we have still stored in queryHistory
  // and mark their tickets/customers as good to keep
  queryHistory.forEach(queryId => {
    const query = newByQueryId[queryId]
    if (!query) return
    if (!query.sortedIds) return
    query.sortedIds.forEach(id => {
      const ticket = newTicketsById[id]
      ticketsToKeep[id] = true
      const customerId = getValueByPath('customer.id', ticket)
      if (customerId) customersToKeep[customerId] = true
    })
  })

  // iterate the queries marked for removal, nullify the searches
  // and mark tickets/customers for removal
  queryIdsToRemove.forEach(queryIdToRemove => {
    const removedQuery = newByQueryId[queryIdToRemove]
    if (removedQuery && removedQuery.sortedIds) {
      removedQuery.sortedIds.forEach(id => {
        const ticket = newTicketsById[id]
        ticketsToRemove[id] = true
        const customerId = getValueByPath('customer.id', ticket)
        if (customerId) customersToRemove[customerId] = true
      })
      // We set a new query to remove references
      // Rather than nullify specific fields, we whitelist the ones
      // which are necessary for a query to function and trigger a re-fetch
      newByQueryId[queryIdToRemove] = {
        queryId: removedQuery.queryId,
        queryObject: removedQuery.queryObject,
        totalCount: removedQuery.totalCount,
      }
    }
  })

  // actual removal
  Object.keys(ticketsToRemove).forEach(id => {
    if (!ticketsToKeep[id]) {
      delete newTicketsById[id]
    }
  })
  Object.keys(customersToRemove).forEach(id => {
    if (!customersToKeep[id]) {
      delete newCustomersById[id]
    }
  })

  // return new state
  return {
    ...state,
    search: {
      ...state.search,
      byQueryId: newByQueryId,
    },
    tickets: {
      ...state.tickets,
      byId: newTicketsById,
    },
    customers: {
      ...state.customers,
      byId: newCustomersById,
    },
  }
}

export const gcCheckAndRunForSearches = (state, queryId) => {
  // keep history unique
  if (_queryHistory.indexOf(queryId) === -1) _queryHistory.push(queryId)
  // only kickin once we cross the threshold
  if (_queryHistory.length > MAX_REMEMBERED_SEARCHES) {
    return garbageCollectSearches(REMOVE_SEARCH_COUNT, state, _queryHistory)
  }
  return state
}
