import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styles as modalBtnsStyles } from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns.styles'
import {
  heading,
  text,
} from '@groovehq/internal-design-system/lib/styles/elements'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import { selectBillingEstimateForPlanChange } from 'ducks/billing/selectors/selectBillingEstimateForPlanChange'
import { selectIsTrial } from 'ducks/billing/selectors/selectIsTrial'
import { useDrawer } from 'ducks/drawers/hooks'
import { DRAWER_TYPE_BILLING_BASIC_CHANGE_PLAN } from 'ducks/drawers/types'
import { PLAN_DOWNGRADABLE_DIFF } from 'constants/billing'
import { diff } from 'util/date'
import { doFetchBillingData } from 'ducks/billing/operations'
import { buildDrawerQueryParam } from 'ducks/drawers/util'
import { AdminAccessDrawer } from '../../NoAccess'
import Features from '../Features'
import { styles } from '../styles'
import ContactCard from '../ContactCard'

const BillingDownGradePlan = ({
  open,
  onExit,
  onClose,
  drawerResourceId: newPricingIdsPipeDelimited,
  drawerBillingCycle,
  previousDrawerId,
  previousDrawer,
}) => {
  const dispatch = useDispatch()
  const newPricingIds = useMemo(() => newPricingIdsPipeDelimited.split('|'), [
    newPricingIdsPipeDelimited,
  ])

  const { nextBillDate } = useSelector(selectBillingEstimateForPlanChange)
  const isTrial = useSelector(selectIsTrial)
  const overdue =
    isTrial ||
    diff('milliseconds', new Date(), nextBillDate * 1000) <
      PLAN_DOWNGRADABLE_DIFF

  const {
    drawerId: openDowngradePlanOverviewDrawerId,
    openDrawer: openDowngradePlanOverview,
  } = useDrawer({
    type: DRAWER_TYPE_BILLING_BASIC_CHANGE_PLAN,
  })

  const handleClose = useCallback(
    () => {
      onClose({
        ignoreStack: true,
      })
    },
    [onClose]
  )

  const handleContinue = useCallback(
    () => {
      if (previousDrawer === DRAWER_TYPE_BILLING_BASIC_CHANGE_PLAN) {
        onClose({
          query: {
            ...buildDrawerQueryParam(
              previousDrawerId,
              'drawerLimitsConfirmed',
              true
            ),
          },
        })
      } else {
        openDowngradePlanOverview(newPricingIdsPipeDelimited, {
          query: {
            ...buildDrawerQueryParam(
              openDowngradePlanOverviewDrawerId,
              'drawerLimitsConfirmed',
              true
            ),
            ...buildDrawerQueryParam(
              openDowngradePlanOverviewDrawerId,
              'drawerBillingCycle',
              drawerBillingCycle
            ),
          },
        })
      }
    },
    [
      newPricingIdsPipeDelimited,
      onClose,
      openDowngradePlanOverview,
      openDowngradePlanOverviewDrawerId,
      drawerBillingCycle,
      previousDrawer,
      previousDrawerId,
    ]
  )

  useEffect(
    () => {
      dispatch(doFetchBillingData())
    },
    [dispatch]
  )

  // useEffect(
  //   () => {
  //     if (!drawerResourceId || !newPlan) {
  //       onExit()
  //     }
  //   },
  //   [drawerResourceId, onExit, newPlan]
  // )

  const footer = (
    <div css={[modalBtnsStyles.base, styles.btns]}>
      <Button type="tertiary" size="big" onClick={handleClose}>
        {overdue ? 'Close' : 'No thanks, I’d like to keep my current plan'}
      </Button>
      {!overdue && (
        <Button
          type="info"
          size="big"
          onClick={handleContinue}
          disabled={!nextBillDate}
        >
          Continue to confirm
        </Button>
      )}
    </div>
  )

  return (
    <AdminAccessDrawer
      title="Downgrade plan"
      open={open}
      footer={footer}
      onClose={onExit}
      isLoading={!nextBillDate}
      contentStyles={styles.wrapper}
    >
      <div
        className="grui pt-12"
        css={[text.styles.textNormal, styles.container]}
      >
        <h4 className="grui" css={heading.styles.h4}>
          We’re sorry you want to downgrade
        </h4>
        <div>
          <p>
            You currently making more use of Groove than your new plans allow.
          </p>
          <p className="grui my-12">
            {overdue && (
              <>
                You’ll need to remove or disable the following features prior to
                the scheduled downgrade:
              </>
            )}

            {!overdue && (
              <>
                To downgrade to, you’ll need to remove or disable the following:
              </>
            )}
          </p>
          <Features
            newPricingIds={newPricingIds}
            newBillingCycle={drawerBillingCycle}
          />
        </div>
      </div>
      <ContactCard />
    </AdminAccessDrawer>
  )
}

export default BillingDownGradePlan
