import { css } from '@emotion/react'

const list = theme => css`
  margin-bottom: ${theme.spacing[8]};
  li {
    display: flex;
    align-items: center;
    & + li {
      margin-top: ${theme.spacing[5]};
    }

    & > * {
      flex-grow: 1;
      max-width: unset;
    }

    & > button {
      flex-grow: 0;
      color: ${theme.color.monochrome.mediumDark};
      margin-left: ${theme.spacing[5]};
    }
  }
`

const handle = theme => css`
  color: ${theme.color.monochrome.mediumDark};
  // Offset tag padding
  margin: 0 -${theme.spacing[8]};

  padding: 0 ${theme.spacing[5]};
  height: 100%;

  svg {
    vertical-align: middle;
  }

  &:hover {
    color: ${theme.color.monochrome.dark};
  }
`

export const styles = {
  list,
  handle,
}
