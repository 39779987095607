import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'

export default function TeamCreateEditFooter({
  isDisabled,
  isUpdate,
  allowDelete,
  onDelete,
  onClose,
}) {
  return (
    <ModalBtns
      saveBtnDisabled={isDisabled}
      saveBtnText={isUpdate ? 'Save' : 'Create'}
      // type="submit" will automatically trigger the form submit event when clicked
      saveBtnHtmlType="submit"
      warningBtnText={allowDelete ? 'Delete' : null}
      onClickWarningBtn={onDelete}
      tertiaryBtnText={'Cancel'}
      onClickTertiaryBtn={onClose}
    />
  )
}
