import { queryStringToQueryId } from 'ducks/searches/utils/query'

export function chatPageHandler(_props, locationState) {
  const { location: { current: { query = {} } = {} } = {} } =
    locationState || {}

  const queryId = queryStringToQueryId(query)
  const roomId = query.conversationId || null
  const eventId = query.eventId || null

  return {
    componentName: 'ChatPage',
    subapp: 'chat',
    props: {
      roomId: parseInt(roomId, 10),
      eventId,
      queryId,
    },
  }
}

export function socialPageHandler(_props, locationState) {
  const { location: { current: { query = {} } = {} } = {} } =
    locationState || {}

  const queryId = queryStringToQueryId(query)
  const roomId = query.conversationId || null
  const eventId = query.eventId || null

  return {
    componentName: 'SocialPage',
    subapp: 'chat',
    props: {
      roomId: parseInt(roomId, 10),
      eventId,
      queryId,
    },
  }
}
