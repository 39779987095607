import React, { useCallback, useEffect, useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useSelector, useDispatch } from 'react-redux'
import { useController, useFieldArray, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import Field from '@groovehq/internal-design-system/lib/components/Field/Field'
import Dropdown from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import Checkbox from '@groovehq/internal-design-system/lib/components/Checkbox/Checkbox'
import { styles as fieldStyles } from '@groovehq/internal-design-system/lib/components/Field/Field.styles'
import Help from '@groovehq/internal-design-system/lib/components/Field/Help/Help'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import { AdminAccessDrawer } from 'subapps/settings/components/drawers/NoAccess'
import DropdownMenu from 'subapps/settings/components/DropdownMenu'
import { selectWidgetSettingsById } from 'ducks/widgets/selectors/settings'
import { doUpdateSettings } from 'ducks/widgets/operations'
import { useRhfDirtyHold } from 'util/dirtyHolds'
import debug from 'util/debug'
import { useDrawer } from 'ducks/drawers/hooks'
import { DRAWER_TYPE_WIDGET_DELETE_CUSTOM_FIELD } from 'ducks/drawers/types'
import { buildDrawerQueryParam } from 'ducks/drawers/util'
import DropdownOptions from './DropdownOptions'
import { fieldTypes } from './data'
import { styles } from './styles'
import {
  WIDGET_CONTACT_FORM_FIELDS,
  generateDefaultValueForFormFields,
} from '../util'

const FORM_ID = 'widget-edit-custom-field-form'

const CUSTOM_FIELD_NAME = 'name'
const CUSTOM_FIELD_TYPE = 'fieldType'
const CUSTOM_FIELD_LABEL = 'label'
const CUSTOM_FIELD_REQUIRED = 'required'
const CUSTOM_FIELD_OPTIONS = 'options'
const CUSTOM_FIELD_ROWS = 'rows'

const FORM_SCHEMA = yup.object().shape({
  [CUSTOM_FIELD_NAME]: yup.string().required('Question name is required'),
  [CUSTOM_FIELD_LABEL]: yup.string().required('Placeholder is required'),
  [CUSTOM_FIELD_ROWS]: yup
    .number()
    .nullable()
    .when(CUSTOM_FIELD_TYPE, {
      is: 'textarea',
      then: yup
        .number()
        .required('Number of rows is required')
        .min(2, 'At least 2 rows'),
    }),
  [CUSTOM_FIELD_OPTIONS]: yup
    .array()
    .nullable()
    .when(CUSTOM_FIELD_TYPE, {
      is: 'select',
      then: yup
        .array()
        .of(
          yup.object({
            value: yup.string().required('Dropdown option value is required'),
            label: yup.string().required('Dropdown option text is required'),
          })
        )
        .required('Dropdown option is required')
        .min(1, 'Add at least 1 dropdown option'),
    }),
})

const defaultDropdownOptions = [
  {
    value: uuidv4(),
    label: '',
  },
  {
    value: uuidv4(),
    label: '',
  },
]

export const generateDefaultValues = (formField = {}) => ({
  [CUSTOM_FIELD_NAME]: formField[CUSTOM_FIELD_NAME] || '',
  [CUSTOM_FIELD_TYPE]: formField[CUSTOM_FIELD_TYPE] || 'input',
  [CUSTOM_FIELD_LABEL]: formField[CUSTOM_FIELD_LABEL] || '',
  [CUSTOM_FIELD_REQUIRED]: formField[CUSTOM_FIELD_REQUIRED] !== false,
  [CUSTOM_FIELD_OPTIONS]:
    formField[CUSTOM_FIELD_OPTIONS] || defaultDropdownOptions,
  [CUSTOM_FIELD_ROWS]: formField[CUSTOM_FIELD_ROWS] || 2,
  id: formField.id || uuidv4(),
})

const EditCustomField = ({
  drawerResourceId: formFieldId,
  drawerWidgetId: widgetId,
  drawerId,
  open,
  onClose,
  onExit,
  resetField,
  ...rest
}) => {
  const dispatch = useDispatch()
  const {
    handleSubmit,
    reset,
    register,
    control,
    formState: { errors, isDirty, isValid, isSubmitting },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(FORM_SCHEMA),
    defaultValues: generateDefaultValues(),
    delayError: 1000,
  })
  const { releaseHold } = useRhfDirtyHold(FORM_ID, control)
  const {
    field: { value: fieldType, onChange: handleSelectType },
  } = useController({
    name: CUSTOM_FIELD_TYPE,
    control,
  })

  const { fields: dropdownOptions, move, append, remove } = useFieldArray({
    control,
    name: CUSTOM_FIELD_OPTIONS,
  })
  const settings = useSelector(state =>
    selectWidgetSettingsById(state, widgetId)
  )

  const {
    openDrawer: openDeleteCustomFieldDrawer,
    drawerId: deleteCustomFieldDrawerId,
  } = useDrawer({
    type: DRAWER_TYPE_WIDGET_DELETE_CUSTOM_FIELD,
  })

  const formField = useMemo(
    () =>
      settings?.[WIDGET_CONTACT_FORM_FIELDS]?.find(
        item => item.id === formFieldId
      ),
    [settings, formFieldId]
  )
  const isEdit = formFieldId !== 'new'
  const saveBtnDisabled = !isDirty || !isValid || isSubmitting
  const isDropdownType = fieldType === 'select'
  const isTextareaType = fieldType === 'textarea'
  const isLoading = isEdit && !formField
  const isDefaultField = isEdit && formField?.default

  const onSubmit = useCallback(
    async data => {
      const dataToUpdate = { ...formField, ...data }
      delete dataToUpdate.value
      if (!isDropdownType) {
        delete dataToUpdate.options
      }
      if (!isTextareaType) {
        delete dataToUpdate.rows
      }
      let fieldsToUpdate = settings[WIDGET_CONTACT_FORM_FIELDS]
      if (isEdit) {
        fieldsToUpdate = fieldsToUpdate.map(item => {
          if (item.id === formFieldId) {
            return dataToUpdate
          }
          return item
        })
      } else {
        fieldsToUpdate.push(dataToUpdate)
      }
      const settingsData = {
        ...settings,
        [WIDGET_CONTACT_FORM_FIELDS]: fieldsToUpdate,
      }
      try {
        await dispatch(
          doUpdateSettings(settingsData, widgetId, {
            shouldShowToasts: true,
          })
        )
        releaseHold()
        onClose()
        if (resetField) {
          resetField(WIDGET_CONTACT_FORM_FIELDS, {
            defaultValue: generateDefaultValueForFormFields(fieldsToUpdate),
          })
        }
      } catch (error) {
        debug(error)
      }
    },
    [
      isTextareaType,
      isDropdownType,
      formField,
      widgetId,
      releaseHold,
      onClose,
      dispatch,
      resetField,
      settings,
      formFieldId,
      isEdit,
    ]
  )

  const handleDelete = useCallback(
    () => {
      openDeleteCustomFieldDrawer(formFieldId, {
        query: {
          ...buildDrawerQueryParam(
            deleteCustomFieldDrawerId,
            'drawerWidgetId',
            widgetId
          ),
        },
        additionalProps: {
          resetField,
        },
      })
    },
    [
      openDeleteCustomFieldDrawer,
      widgetId,
      formFieldId,
      deleteCustomFieldDrawerId,
      resetField,
    ]
  )

  const handleCancel = useCallback(
    e => {
      releaseHold()
      onClose(e)
    },
    [onClose, releaseHold]
  )

  const selectedFieldType = useMemo(
    () => fieldTypes.find(item => item.key === fieldType),
    [fieldType]
  )

  const footer = useMemo(
    () => {
      const moreBtn = {}
      if (isEdit && !isDefaultField) {
        // Edit
        moreBtn.warningBtnText = 'Delete question'
        moreBtn.onClickWarningBtn = handleDelete
      } else {
        moreBtn.tertiaryBtnText = 'Cancel'
        moreBtn.onClickTertiaryBtn = handleCancel
      }

      return (
        <ModalBtns
          saveBtnText="Save"
          saveBtnForm={FORM_ID}
          saveBtnHtmlType="submit"
          saveBtnDisabled={saveBtnDisabled}
          {...moreBtn}
        />
      )
    },
    [isEdit, saveBtnDisabled, handleDelete, handleCancel, isDefaultField]
  )

  useEffect(
    () => {
      if (isEdit && formField) {
        reset(generateDefaultValues(formField))
      }
    },
    // remove formField dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isEdit, reset]
  )

  const dropdownOptionsErrorMessage = dropdownOptions.some(
    (_, index) => errors[CUSTOM_FIELD_OPTIONS]?.[index]
  )
    ? 'Dropdown option text is required'
    : errors[CUSTOM_FIELD_OPTIONS]?.message

  return (
    <AdminAccessDrawer
      {...rest}
      open={open}
      title="Custom questions"
      footer={footer}
      onClose={onExit}
      isLoading={isLoading}
    >
      <form
        css={styles.fields}
        className="grui pt-12"
        id={FORM_ID}
        onSubmit={handleSubmit(onSubmit)}
      >
        {isDefaultField && (
          <div css={[text.styles.textDark, text.styles.italic]}>
            This is a built-in field. Some details are not editable.
          </div>
        )}
        <Field
          css={styles.field}
          label="Custom question name"
          placeholder="Enter question name"
          tooltipPosition="top"
          tooltipTitle="This will be only visible to you."
          readOnly={isDefaultField}
          validateStatus={errors[CUSTOM_FIELD_NAME] ? 'error' : undefined}
          help={errors[CUSTOM_FIELD_NAME]?.message}
          {...register(CUSTOM_FIELD_NAME)}
        />
        <div>
          <span css={fieldStyles.labelBox}>Field type</span>
          <div css={styles.dropdownContainer}>
            <Dropdown
              overlay={<DropdownMenu data={fieldTypes} />}
              onSelect={handleSelectType}
              selectedKey={fieldType}
              disabled={isDefaultField}
            >
              <Dropdown.Button>
                {selectedFieldType?.title || '- Please select -'}
              </Dropdown.Button>
            </Dropdown>
          </div>
        </div>
        <Field
          css={styles.field}
          label="Placeholder shown"
          placeholder="My custom field"
          tooltipPosition="top"
          tooltipTitle="This is what is shown in the input of your contact form."
          validateStatus={errors[CUSTOM_FIELD_LABEL] ? 'error' : undefined}
          help={errors[CUSTOM_FIELD_LABEL]?.message}
          {...register(CUSTOM_FIELD_LABEL)}
        />
        <div>
          <span css={fieldStyles.labelBox}>Required field</span>
          <Checkbox
            css={text.styles.textDark}
            id="requiredField"
            {...register(CUSTOM_FIELD_REQUIRED)}
          >
            Users need to answer this before submitting a query
          </Checkbox>
        </div>
        {isDropdownType && (
          <div>
            <span css={fieldStyles.labelBox}>Dropdown options</span>
            <DropdownOptions
              options={dropdownOptions}
              className="grui mt-10"
              move={move}
              append={append}
              remove={remove}
              register={register}
              name={CUSTOM_FIELD_OPTIONS}
            />
            {dropdownOptionsErrorMessage && (
              <Help validateStatus="error" help={dropdownOptionsErrorMessage} />
            )}
          </div>
        )}
        {isTextareaType && (
          <Field
            css={styles.field}
            label="Number of rows"
            htmlType="number"
            tooltipPosition="top"
            tooltipTitle="The number of rows in the textarea."
            validateStatus={errors[CUSTOM_FIELD_ROWS] ? 'error' : undefined}
            help={errors[CUSTOM_FIELD_ROWS]?.message}
            {...register(CUSTOM_FIELD_ROWS)}
          />
        )}
      </form>
    </AdminAccessDrawer>
  )
}

export default EditCustomField
