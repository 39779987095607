import { constructSearchQueryObject } from './index'

const VALID_OPERATORS = {
  assignee: '*',
  assigned: '*',
  state: ['closed', 'opened', 'unread', 'snoozed', 'spam'],
  rating: ['any', 'awesome', 'ok', 'bad'],
  assigned_group: '*',
  draft: '*',
  draft_type: '*',
  starred: ['all'],
  deleted: ['all'],
  label: '*',
  mailbox: '*',
  mentions: '*',
  keywords: '*',
  folder: '*',
  customer: '*',
  from: '*',
  before: '*',
  after: '*',
  between: '*',
}
export default function isSearchValid(search) {
  const queryObject = constructSearchQueryObject(search)
  return Object.keys(queryObject).reduce((isValid, key) => {
    if (Object.keys(VALID_OPERATORS).indexOf(key) < 0) return false // Invalid key
    const validValues = VALID_OPERATORS[key]
    const values = queryObject[key]
    const areValuesValid =
      values.reduce((isValueValid, value) => {
        if (validValues.indexOf(value) < 0) return false
        if (value === '') return false
        return isValueValid
      }, true) && values.length > 0
    if (validValues !== '*' && !areValuesValid) return false // Invalid values
    return isValid
  }, true)
}
