import Bugsnag from '@bugsnag/js'

const chain = leafNode => {
  try {
    let node = leafNode
    let tagName
    const parts = []
    // eslint-disable-next-line no-cond-assign
    while ((tagName = node.tagName)) {
      const part = [tagName]
      const className = node.className

      if (className && className !== '') {
        let classNamePart = null
        if (className.split) {
          classNamePart = `.${node.className.split(' ').join('.')}`
        } else if (className.baseVal) {
          classNamePart = className.baseVal
        } else {
          classNamePart = className
        }
        part.push(classNamePart)
      }
      if (node.id && node.id !== '') part.push(`#${node.id}`)
      parts.push(part.join(''))
      if (!node.parentNode) break
      node = node.parentNode
    }
    return parts.join(' > ')
  } catch (e) {
    return 'failed'
  }
}

if (typeof Node === 'function' && Node.prototype) {
  const originalRemoveChild = Node.prototype.removeChild
  Node.prototype.removeChild = function removeChild(child) {
    if (child.parentNode !== this) {
      const metaData = {
        childTag: child.tagName,
        childClass: child.className,
        childChain: chain(child),
        childId: child.id,
        parentTag: this.tagName,
        parentClass: this.className,
        parentChain: chain(this),
        parentId: this.id,
      }

      Bugsnag.notify(new Error('RemoveChildParentError'), event => {
        // eslint-disable-next-line no-param-reassign
        event.severity = 'info'

        event.addMetadata('metaData', {
          meta: metaData,
        })
      })
    }
    // eslint-disable-next-line prefer-rest-params
    return originalRemoveChild.apply(this, arguments)
  }
}
