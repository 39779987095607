import React from 'react'
import Sidebar from 'shared/components/ui/Sidebar'
import { Button } from 'util/ui'
import { inBody } from 'util/hoc'
import { Header } from 'shared/components/ui/Typography'
import premiumIntegrationsIcon from 'assets/integrations/premium-integrations.png'
import { ModalContent, Logo } from './styled'

function PremiumIntegrationsView({
  isOpen,
  redirectToBilling,
  handleSidebarInit,
  onClose,
}) {
  return (
    <Sidebar
      headerChildren="Premium integrations"
      footerChildren={
        <React.Fragment>
          <Button primary onClick={redirectToBilling} fluid size="large">
            Go to billing
          </Button>
          <Button size="large" fluid onClick={onClose} basic>
            Cancel
          </Button>
        </React.Fragment>
      }
      narrow
      open={isOpen}
      onInit={handleSidebarInit}
      noDim
      onClose={onClose}
    >
      <ModalContent>
        <Logo src={premiumIntegrationsIcon} />
        <Header size="small">
          Supercharge your account with premium integrations!
        </Header>
        <p>
          Our premium integrations allow for a deeper connection between Groove
          and the tools your business already uses.
        </p>
        <p>
          These integrations provide additional levels of ability than our
          standard integrations, providing your team with a host of features
          without having to leave Groove.
        </p>
        <p>
          Our premium integrations include:
          <ul>
            <li>Hubspot</li>
            <li>Jira (cloud &amp; server)</li>
            <li>SalesForce</li>
          </ul>
        </p>
      </ModalContent>
    </Sidebar>
  )
}

export default inBody(PremiumIntegrationsView)
