import { useCallback } from 'react'
import Table from '@groovehq/internal-design-system/lib/components/Table/Table'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import Plus from '@groovehq/internal-design-system/lib/assets/icons/Plus'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'

import { useDrawer } from 'ducks/drawers/hooks'
import {
  DRAWER_TYPE_INTEGRATION_SLACK_CHANNEL_UPDATE,
  DRAWER_TYPE_INTEGRATION_SLACK_CHANNEL_DELETE,
} from 'ducks/drawers/types'

import useColumns from './useColumns'
import { styles } from '../../styles'

const ChannelsTable = ({ channels }) => {
  const columns = useColumns()
  const { openDrawer: openUpdateDrawer } = useDrawer(
    DRAWER_TYPE_INTEGRATION_SLACK_CHANNEL_UPDATE
  )
  const { openDrawer: openDeleteDrawer } = useDrawer(
    DRAWER_TYPE_INTEGRATION_SLACK_CHANNEL_DELETE
  )

  const onAddClick = useCallback(
    () => {
      openUpdateDrawer('new')
    },
    [openUpdateDrawer]
  )

  return (
    <>
      <Table
        separate
        columns={columns}
        data={channels}
        onEdit={openUpdateDrawer}
        updateData={openUpdateDrawer}
        deleteData={openDeleteDrawer}
        css={styles.table}
        className="grui mb-8"
        emptyHintVisible={false}
        emptyContent={
          <div
            css={[
              text.styles.textMediumDark,
              text.styles.italic,
              text.styles.fontNormal,
            ]}
            className="grui mt-4 mb-10"
          >
            You have not set up any channels to receive notifications for yet...
          </div>
        }
      />
      <div className="grui flex flex-row justify-start">
        <Button
          type="secondary"
          size="small"
          customIcon={<Plus viewBox="0 0 18 18" />}
          onClick={onAddClick}
        >
          Add a channel
        </Button>
      </div>
    </>
  )
}

export default ChannelsTable
