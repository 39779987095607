import { css } from '@emotion/react'

const dropdownBtn = theme => css`
  padding-left: ${theme.spacing[5]};

  &&& > * {
    padding-right: ${theme.spacing[8]};
    svg {
      margin-right: -${theme.spacing[3]};
    }
  }
`

export const styles = {
  dropdownBtn,
}
