import React from 'react'
import Link from 'redux-first-router-link'
import cn from 'classnames'
import { heading } from '@groovehq/internal-design-system/lib/styles/elements'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import { SETTINGS_BILLING_PAGE } from 'subapps/settings/types'
import useOpenContact from 'util/hooks/useOpenContact'
import { styles } from './styles'

const TO_SETTINGS_BILLING_PLANS_PAGE = {
  type: SETTINGS_BILLING_PAGE,
  payload: {
    tab: 'plans',
  },
}

const TO_SETTINGS_BILLING_KB_PLANS_PAGE = {
  type: SETTINGS_BILLING_PAGE,
  payload: {
    tab: 'kb-plans',
  },
}

const BigPlanLimitationCard = ({
  children,
  className,
  title,
  img,
  imgAlt = 'Advanced features',
  shouldGoToKbPlans,
}) => {
  const openContact = useOpenContact({ left: true })
  return (
    <div
      css={styles.container}
      className={cn('grui flex items-end pr-15 pl-12 pb-11 pt-10', className)}
    >
      <div css={styles.main} className="grui mr-auto">
        <div className="grui mb-12 mr-auto">
          <h5 css={heading.styles.h5} className="grui mb-2">
            {title}
          </h5>
          {children}
        </div>
        <div className="grui flex flex-wrap items-center">
          <Button
            css={styles.btn}
            as={Link}
            to={
              shouldGoToKbPlans
                ? TO_SETTINGS_BILLING_KB_PLANS_PAGE
                : TO_SETTINGS_BILLING_PLANS_PAGE
            }
            type="info"
          >
            See plans
          </Button>
          <Button
            css={styles.btn}
            onClick={openContact}
            type="tertiary"
            className="grui ml-7"
          >
            Chat to sales
          </Button>
        </div>
      </div>
      {img && (
        <div className="grui pl-8 mb-7" css={styles.imgWrapper}>
          <img src={img} alt={imgAlt} className="grui block" />
        </div>
      )}
    </div>
  )
}

export default BigPlanLimitationCard
