import { connect } from 'react-redux'

import {
  doFetchGitHubInstalls,
  selectGitHubOwnerInstalls,
  selectGitHubOwnerInstallsAreLoading,
} from 'ducks/integrations/github'

import InstallsTab from './view'

const select = state => {
  return {
    isLoading: selectGitHubOwnerInstallsAreLoading(state),
    owners: selectGitHubOwnerInstalls(state),
  }
}

const perform = {
  onMount: doFetchGitHubInstalls,
}
export default connect(select, perform)(InstallsTab)
