import React, { useCallback, useMemo, useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Dropdown, {
  divider,
} from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import { emptyArr, areArraysEqual } from 'util/arrays'
import usePrevious from 'util/hooks/usePrevious'
import { DRAWER_TYPE_TAGS_UPDATE } from 'ducks/drawers/types'
import { useDrawer } from 'ducks/drawers/hooks'
import { doFetchTagsV2ByIdsOrNames } from 'ducks/tags/actions'
import { selectCurrentTags, selectCurrentTagsById } from 'ducks/tags/selectors'
import { isGid } from 'util/globalId'
import TagDropdownCreateButton from '../TagDropdown/createButton'
import Option from './Option'
import useSearchTags from './useSearchTags'
import { Footer, styles, useMultiSelect } from '../MultiSelectMenu'
import MenuWithSearch, {
  useShowHeaderShadow,
  styles as menuWithSearchStyles,
} from '../MenuWithSearch'
import Content from './Content'

const TagsDropdown = ({
  fieldType = 'ids',
  value: inputValues,
  setValue,
  selectedIds,
  onSelectedIdsChange,
}) => {
  const searchRef = useRef()
  const dispatch = useDispatch()
  const values = inputValues || emptyArr
  const [menuVisible, setMenuVisible] = useState(false)
  const { handleScroll, shouldShowHeaderShadow } = useShowHeaderShadow(
    menuVisible
  )
  const currentTagsById = useSelector(selectCurrentTagsById)
  const currentTags = useSelector(selectCurrentTags)

  const unsavedTags = useMemo(
    () => {
      if (fieldType !== 'ids') return []

      return values.filter(v => !!v && !isGid(v))
    },
    [fieldType, values]
  )

  const {
    search,
    handleChangeSearch,
    filteredTags,
    loading,
    isEmpty,
  } = useSearchTags({
    searchDelayMs: 200,
    field: fieldType,
    prependNames: unsavedTags,
  })
  const { handleToggleItem } = useMultiSelect(
    filteredTags,
    onSelectedIdsChange,
    selectedIds
  )
  const { openDrawer: openEditDrawer } = useDrawer(DRAWER_TYPE_TAGS_UPDATE)
  const handleOnCreateTag = useCallback(
    () => {
      openEditDrawer('new')
    },
    [openEditDrawer]
  )

  const handleMenuVisibleChange = useCallback(visible => {
    setMenuVisible(visible)
  }, [])
  const handleSelect = useCallback(
    () => {
      setValue(selectedIds)
      setMenuVisible(false)
    },
    [setValue, selectedIds]
  )
  const handleCancel = useCallback(() => {
    setMenuVisible(false)
  }, [])

  const menu = useMemo(
    () => {
      return (
        <MenuWithSearch
          search={search}
          onScroll={handleScroll}
          focusElementRef={searchRef}
          onApply={handleSelect}
        >
          <TagDropdownCreateButton
            onCreateTag={handleOnCreateTag}
            className="grui px-10 pb-5"
          />
          {divider}
          <div className="grui pt-3 pb-5">
            <Content loading={loading} isEmpty={isEmpty}>
              {filteredTags.map(({ id, name, conversationCount, color }) => (
                <MenuWithSearch.Item
                  itemKey={id}
                  key={id}
                  onSelect={handleToggleItem}
                >
                  <Option
                    css={styles.checkbox}
                    id={id}
                    checked={selectedIds.some(itemId => itemId === id)}
                    name={name}
                    conversationCount={conversationCount || 0}
                    color={color}
                  />
                </MenuWithSearch.Item>
              ))}
            </Content>
          </div>
        </MenuWithSearch>
      )
    },
    [
      filteredTags,
      selectedIds,
      handleOnCreateTag,
      handleScroll,
      search,
      handleToggleItem,
      loading,
      isEmpty,
      handleSelect,
    ]
  )
  const header = useMemo(
    () => (
      <MenuWithSearch.Search
        placeholder="Search all tags"
        onChange={handleChangeSearch}
        value={search}
        ref={searchRef}
        shouldFocus={menuVisible}
      />
    ),
    [handleChangeSearch, search, menuVisible]
  )
  const footer = useMemo(
    () => (
      <Footer
        onSelect={handleSelect}
        onCancel={handleCancel}
        saveBtnTitle="Select tags"
      />
    ),
    [handleSelect, handleCancel]
  )

  const selectedNames = useMemo(
    () => {
      if (fieldType === 'ids') {
        return values
          .map(
            idOrName =>
              (isGid(idOrName) && currentTagsById[idOrName]?.name) ||
              (!isGid(idOrName) &&
                (currentTags.find(ct => ct?.name === idOrName) || idOrName))
          )
          .filter(name => !!name)
          .join(', ')
      }

      return values.filter(name => !!name).join(', ')
    },
    [fieldType, values, currentTagsById, currentTags]
  )

  useEffect(
    () => {
      if (!menuVisible) {
        onSelectedIdsChange(values)
        handleChangeSearch({ target: { value: '' } })
      }
    },
    // Including onSelectedIdsChange causes circular renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [menuVisible, values]
  )

  const previousValues = usePrevious(values)
  useEffect(
    () => {
      let filter = []

      if (fieldType === 'ids') {
        filter = values.filter(tagId => !currentTagsById[tagId])
      } else if (fieldType === 'names') {
        filter = values.filter(
          tagName => !currentTags.find(ct => ct?.name === tagName)
        )
      }

      if (
        filter &&
        filter.length &&
        !areArraysEqual(filter, previousValues || [])
      ) {
        dispatch(doFetchTagsV2ByIdsOrNames(filter))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fieldType, values, previousValues]
  )

  const normalizedValues = useMemo(
    () => {
      if (fieldType !== 'ids') return values

      // if any of the tags in values is a not a gid, try find it from current tags
      return values.map(v => {
        if (isGid(v)) return v

        return currentTags.find(ct => ct?.name === v)?.id || v
      })
    },
    [fieldType, currentTags, values]
  )

  useEffect(
    () => {
      if (fieldType !== 'ids') return

      // if a string non-id was passed as a value and a match was found in currenttags,
      // update values to use id instead
      if (!areArraysEqual(values, normalizedValues)) {
        setValue(normalizedValues)
      }
    },
    [fieldType, setValue, values, normalizedValues]
  )

  return (
    <Dropdown
      overlay={menu}
      header={header}
      footer={footer}
      css={[
        text.styles.textSm,
        styles.dropdownContainer,
        shouldShowHeaderShadow && menuWithSearchStyles.showHeaderShadow,
      ]}
      visible={menuVisible}
      onVisibleChange={handleMenuVisibleChange}
      emptyHint=""
      autoHeight
      isNavByArrowsDisabled
    >
      <Dropdown.Button css={styles.dropdownBtn} size="small">
        {selectedNames || `Select tags`}
      </Dropdown.Button>
    </Dropdown>
  )
}

export default TagsDropdown
