import React, { useCallback } from 'react'
import * as yup from 'yup'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import Field from '@groovehq/internal-design-system/lib/components/Field/Field'
import MessageCard from '@groovehq/internal-design-system/lib/components/MessageCard/MessageCard'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'

import { AdminAccessDrawer } from 'subapps/settings/components/drawers/NoAccess'
import { doCreateSpammer } from 'ducks/spammers'

const FORM_SCHEMA = yup.object().shape({
  email: yup
    .string()
    .email()
    .required(`Email address is required`),
})

export default function SpammerCreateDrawer({
  drawerId,
  drawerResourceId: teamId,
  previousDrawer,
  open,
  onClose,
  onExit,
  ...rest
}) {
  const dispatch = useDispatch()

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(FORM_SCHEMA),
    defaultValues: {
      email: '',
    },
    delayError: 300,
  })

  const onSubmit = useCallback(
    data => {
      dispatch(doCreateSpammer(data.email))
      onClose()
    },
    [dispatch, onClose]
  )

  const DrawerForm = useCallback(
    props => {
      return <form onSubmit={handleSubmit(onSubmit)} {...props} />
    },
    [handleSubmit, onSubmit]
  )

  return (
    <AdminAccessDrawer
      {...rest}
      open={open}
      onClose={onExit}
      title="Block an address"
      footer={
        <>
          <div>
            <MessageCard type="negative" closeable className="grui my-10 mx-12">
              Note: This will block any future emails from this address going
              forward. These emails will not be processed within Groove and will
              not be accessible in any way.
            </MessageCard>
            <ModalBtns
              saveBtnDisabled={!isDirty || !isValid}
              saveBtnText="Block address"
              saveBtnHtmlType="submit"
            />
          </div>
        </>
      }
      container={DrawerForm}
    >
      <div className="grui mt-7">
        <Field
          label="Email address"
          placeholder={`Enter email address...`}
          validateStatus={errors?.email ? 'error' : undefined}
          help={errors?.email?.message}
          className="grui mw-100"
          {...register('email')}
        />
      </div>
    </AdminAccessDrawer>
  )
}
