import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  text,
  paragraph,
} from '@groovehq/internal-design-system/lib/styles/elements'
import toast from '@groovehq/internal-design-system/lib/components/Toast'

import { string, bool } from 'prop-types'
import { selectCurrentAgentById } from 'ducks/agents/selectors'
import { doChangeToViewer } from 'ducks/agents/operations'
import { emptyFunc } from 'util/functions'

import EntityDeleteDrawer from '../EntityDelete'
import TeammatesRemovalLock from '../../TeammatesRemovalLock'

const AgentSwitchToLite = props => {
  const {
    title,
    open,
    drawerResourceId: agentId,
    onClose,
    onExit,
    onRemove,
  } = props

  const dispatch = useDispatch()
  const [openLockCard, setOpenLockCard] = useState(false)

  const agent = useSelector(state => selectCurrentAgentById(state, agentId))

  const handleOnRemove = useCallback(
    async () => {
      try {
        await dispatch(
          doChangeToViewer(agentId, {
            throwOnError: true,
            toastsEnabled: false,
          })
        )
        if (onRemove) onRemove(agentId)
        toast.notify({
          content: 'Access changed',
          type: 'positive',
        })
        onExit({ isAfterDelete: true })
      } catch (e) {
        if (e.activity && e.activity[0]?.includes('irregular activity')) {
          setOpenLockCard(true)
        } else {
          toast.notify({
            content: `${app.t('Agent')} action failed`,
            type: 'negative',
          })
          onExit({ isAfterDelete: true })
        }
      }
    },
    [dispatch, agentId, onRemove, onExit]
  )

  const handleCloseRemovalLock = useCallback(
    () => {
      setOpenLockCard(false)
      onExit({ isAfterDelete: true })
    },
    [onExit]
  )

  if (!agent) return null

  const { email } = agent

  return (
    <>
      {!openLockCard && (
        <EntityDeleteDrawer
          {...props}
          onRemove={handleOnRemove}
          onClose={emptyFunc}
          onCancel={onClose}
          title={title}
          deleteBtnText={`Switch to ${app.t('viewer')}`}
          deletingBtnText={`Switching to ${app.t('viewer')}...`}
          tertiaryBtnText="Cancel"
          open={open}
        >
          <div className="grui mt-7">
            <div css={text.styles.textNormal}>
              <p css={[paragraph.styles.preflight, text.styles.fontMedium]}>
                You are about to change {email} to a {app.t('viewer')}.
              </p>
              <br />
              <p css={paragraph.styles.preflight}>
                A {app.t('viewer')} is only able to view and collaborate on
                conversations, view reports, and view existing knowledge base
                articles.
              </p>
            </div>
          </div>
        </EntityDeleteDrawer>
      )}
      <TeammatesRemovalLock
        onExit={handleCloseRemovalLock}
        open={openLockCard}
      />
    </>
  )
}

AgentSwitchToLite.propTypes = {
  title: string,
  open: bool.isRequired,
  drawerResourceId: string,
}

AgentSwitchToLite.defaultProps = {
  title: 'Switch to Lite user',
  drawerResourceId: '',
}

export default AgentSwitchToLite
