import React from 'react'
import { useSelector } from 'react-redux'
import Table from '@groovehq/internal-design-system/lib/components/Table/Table'
import { selectPendingIntegrationInstallStateProviderValuesByProviderId } from 'ducks/integrations/selectors/settings'

const StoresDataTable = ({ providerId, columns, onEditStore }) => {
  const stores = useSelector(state =>
    selectPendingIntegrationInstallStateProviderValuesByProviderId(
      state,
      providerId
    )
  )

  return <Table columns={columns} data={stores} separate onEdit={onEditStore} />
}

export default StoresDataTable
