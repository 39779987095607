import BatchJobsAppBar from './index'

export default function BatchJobsAppBarList({
  jobTypes = [],
  className,
  roundBorder,
}) {
  return (
    <div className={className}>
      {jobTypes.map(JOB_TYPE => (
        <BatchJobsAppBar
          key={JOB_TYPE}
          jobType={JOB_TYPE}
          roundBorder={roundBorder}
        />
      ))}
    </div>
  )
}
