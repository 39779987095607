let version = ''

export const getUncachedVersion = function() {
  const versionMeta = document.querySelector('meta[name="groove-app-version"]')
  version = versionMeta ? versionMeta.content : ''
  return version
}

export const captureVersion = function() {
  version = getUncachedVersion()
  return version
}

export const getVersion = function() {
  return version
}
