import { BEGIN, REVERT } from 'redux-optimist'
import { v4 as uuidV4 } from 'uuid'
import React, { forwardRef } from 'react'
import { connect } from 'react-redux'

// Redux way of dispatching multiple actions at once
// https://github.com/reactjs/redux/issues/911
export function batchActions(...actions) {
  // eslint-disable-next-line no-param-reassign
  if (actions.length === 1 && Array.isArray(actions[0])) actions = actions[0]
  return {
    type: 'BATCH_ACTIONS',
    actions,
  }
}

// Poor mans promise middleware. Returns a promiseThunk that wraps the given
// promiseThunk in BEGIN/COMMIT/REVERT actions
export function withOptimist(
  promiseThunk,
  { payload, actions: { pending, fulfilled, rejected } }
) {
  return dispatch => {
    const transactionId = uuidV4()

    if (pending) {
      dispatch({
        type: pending,
        data: payload,
        optimist: { type: BEGIN, id: transactionId },
      })
    }

    return dispatch(promiseThunk)
      .then(res => {
        dispatch({
          type: fulfilled,
          data: { ...payload, res },
          // We revert, not commit, since we assume the optimistic update is
          // superseded by the result of a successful response.
          optimist: { type: REVERT, id: transactionId },
        })
        return res
      })
      .catch(err => {
        // If the request fails, we revert our optimistic request to restore the
        // original draft
        dispatch({
          type: rejected,
          data: { ...payload, err },
          optimist: { type: REVERT, id: transactionId },
        })
        throw err
      })
  }
}

export const connectAndForwardRef = (
  mapStateToProps = null,
  mapDispatchToProps = null,
  mergeProps = null,
  options = {}
) => Component =>
  connect(mapStateToProps, mapDispatchToProps, mergeProps, {
    ...options,
    forwardRef: true,
    // eslint-disable-next-line react/jsx-filename-extension
  })(forwardRef((props, ref) => <Component {...props} forwardedRef={ref} />))

// Using createSelector from re-select for all selectors actually hurts performance
// more than it helps because memoization isnt free. In order to keep a consistent
// api for declaring selectors I've created createBasicSelector which mimics the api
// of createSelector but doesnt actually do any memoization. We can then easily
// switch between the 2 functions base on if memoization is required.
// The rule to follow is. If you are just selecting from state, use createBasicSelector,
// if you mutate the response in anyway, use createSelector
export const createBasicSelector = (...args) => (...selectorArgs) => {
  if (args.length === 0)
    throw new Error(
      'InvalidArgumentExcpetion - Expected at least one argument, received none'
    )
  if (args.length === 1) return args[0](...selectorArgs)
  const data = args
    .slice(0, args.length - 1)
    .map(selector => selector(...selectorArgs))
  return args[args.length - 1](...data)
}
