import styled from '@emotion/styled'

import Sidebar from 'shared/components/ui/Sidebar'
import { Flex } from 'shared/ui'
import { Button, Form } from 'util/ui'

import IntegrationRemoveModal from '../../shared/IntegrationRemoveModal'

export default styled(SettingsModal)`
  .CancelButton {
    display: block;
    flex-grow: 1;
    flex-shrink: 1;
  }

  form {
    padding: 0 ${props => props.theme.spacing['12']}
      ${props => props.theme.spacing['12']};

    h2 {
      margin-top: 0;
    }
    .sui &.form.ui > p {
      margin-top: 0;
    }
  }
  .UninstallButton {
    display: block;
    flex-grow: 0.1;
    flex-shrink: 0;
  }
`

function SettingsModal({
  animateModal,
  className,
  doRemoveIntegration,
  doSubmitFormAndClose,
  formFields,
  id,
  installPath,
  SettingsForm,
  open,
  onChange,
  onClose,
  providerStylized,
  tab,
}) {
  const isValidForm = id || formFields['auth.access_token']

  return (
    <Sidebar
      animateInitiallyOpen={animateModal}
      className={className}
      headerChildren={`Configure ${providerStylized}`}
      footerChildren={
        <div>
          <Button
            disabled={!isValidForm}
            fluid
            onClick={doSubmitFormAndClose}
            primary
          >
            Save changes
          </Button>
          <Flex>
            {id && (
              <IntegrationRemoveModal
                id={id}
                name={providerStylized}
                onConfirm={doRemoveIntegration}
                trigger={
                  // last-child class since the flex means it is a last child from top to bottom
                  <Button className="UninstallButton last-child" negative>
                    Uninstall App
                  </Button>
                }
              />
            )}
            <Button basic className="CancelButton grui mr-0" onClick={onClose}>
              Cancel
            </Button>
          </Flex>
        </div>
      }
      onClose={onClose}
      open={open}
    >
      <Form>
        <SettingsForm
          formFields={formFields}
          id={id}
          installPath={installPath}
          onChange={onChange}
          tab={tab}
        />
      </Form>
    </Sidebar>
  )
}
