import React from 'react'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import Bugsnag from '@bugsnag/js'
import { getFormattedPrice } from 'util/numbers'
import { capitalize, pluralize } from 'util/strings'
import {
  PRICING_MODEL_USAGE,
  USAGE_FROM_TO_PRODUCT_NAME,
  USAGE_FROM_TO_PER_ITEM,
  PRODUCT_TO_PRODUCT_NAME,
} from 'ducks/billing/constants'
import { styles } from './styles'

const PlanRow = ({
  name,
  product,
  cyclePrice,
  itemPrice,
  quantity,
  className,
  isNew,
  isUpgradingToAnnual,
  pricingModel,
  planCycle,
  isCycleChange,
  usageFrom,
  disableNewCurrentIndicator,
}) => {
  if (!itemPrice && itemPrice !== 0) {
    Bugsnag.notify(new Error(`Plan item price shouldn't be null or undefined`))
    return null
  }
  const formattedItemPrice = getFormattedPrice(itemPrice, 'USD')
  const formattedItemPriceForAnnualUpgrade =
    isUpgradingToAnnual && isNew
      ? getFormattedPrice(itemPrice / 12, 'USD')
      : formattedItemPrice
  const formattedCyclePrice = getFormattedPrice(cyclePrice, 'USD')
  const formattedCyclePriceForOneYear = isNew
    ? getFormattedPrice(cyclePrice, 'USD')
    : getFormattedPrice(cyclePrice * 12, 'USD')
  const priceUnit = pricingModel === PRICING_MODEL_USAGE ? 'each' : ''

  return (
    <div className={className}>
      <div css={[styles.row, text.styles.fontMedium]} className="grui mb-2">
        <div className="grui truncate">
          {!disableNewCurrentIndicator && (isNew ? 'New ' : 'Current ')}
          {PRODUCT_TO_PRODUCT_NAME[product]}: {name}{' '}
          {isCycleChange && capitalize(planCycle)}
        </div>
        <div>
          {isUpgradingToAnnual
            ? formattedItemPriceForAnnualUpgrade
            : formattedCyclePrice}{' '}
          {isUpgradingToAnnual && `per ${USAGE_FROM_TO_PER_ITEM[usageFrom]}`}
        </div>
      </div>
      <div css={[styles.row, text.styles.text2Xs, styles.lightGrey]}>
        <div className="grui truncate">
          {quantity}{' '}
          {pluralize(quantity, ...USAGE_FROM_TO_PRODUCT_NAME[usageFrom])}{' '}
          {isUpgradingToAnnual ? `billed annually` : ''}
        </div>
        <div>
          {isUpgradingToAnnual
            ? formattedCyclePriceForOneYear
            : formattedItemPrice}{' '}
          {isUpgradingToAnnual ? 'per year' : priceUnit}
        </div>
      </div>
    </div>
  )
}

export default PlanRow
