import React from 'react'
import cn from 'classnames'
import { FEATURE_INBOX_CANNED_REPLIES_TIER_1 } from 'ducks/billing/featureTypes'
import PlanLimitationCard from '../../PlanLimitationCard'

const featuresLeft = ['Add & Remove tags', 'Set the conversation to Snooze']

const featuresRight = [
  'Change the conversation state',
  `Assign the right ${app.t('agent')} or team`,
]

const PlanLimitationForCannedReply = ({ className }) => {
  return (
    <PlanLimitationCard
      title={`Supercharge ${app.t('canned_replies')} with Actions`}
      className={cn('pb-12 pt-11', className)}
      trackingFeatureType={FEATURE_INBOX_CANNED_REPLIES_TIER_1}
    >
      Remove tedious clicking by automatically adding actions to your{' '}
      {app.t('canned_replies')}:
      <div className="grui mt-7 flex">
        <PlanLimitationCard.List data={featuresLeft} />
        <PlanLimitationCard.List data={featuresRight} />
      </div>
    </PlanLimitationCard>
  )
}

export default PlanLimitationForCannedReply
