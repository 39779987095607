import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { func, checkPropTypes } from 'prop-types'
import {
  selectCurrentUserIsAdminOrOwner,
  selectCurrentUserIsOwner,
} from 'ducks/currentUser/selectors/base'

const accessPropTypes = {
  onClose: func.isRequired,
}

export const useAdminAccess = onClose => {
  const isCurrentUserManager = useSelector(selectCurrentUserIsAdminOrOwner)
  useEffect(
    () => {
      checkPropTypes(accessPropTypes, { onClose }, 'prop', 'useAdminAccess')
      if (!isCurrentUserManager) onClose()
    },
    [onClose, isCurrentUserManager]
  )
}

export const useOwnerAccess = onClose => {
  const isCurrentUserOwner = useSelector(selectCurrentUserIsOwner)
  useEffect(
    () => {
      checkPropTypes(accessPropTypes, { onClose }, 'prop', 'useOwnerAccess')
      if (!isCurrentUserOwner) onClose()
    },
    [onClose, isCurrentUserOwner]
  )
}
