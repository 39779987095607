import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useConfirmHoldsCallback } from 'util/dirtyHolds'
import { selectSubscriptionForId } from 'ducks/integrations/recharge/selectors/subscriptions'
import { capture } from 'ducks/tracking/actions'

import Drawer from '@groovehq/internal-design-system/lib/components/Drawer/UnmanagedDrawer'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import doReactivateSubscription from 'ducks/integrations/recharge/operations/doReactivateSubscription'

const RechargeReactivateSubscription = ({
  drawerResourceId,
  drawerIntegrationId,
  open,
  onClose,
  onExit,
  drawerId,
  queryId,
  ...rest
}) => {
  const dispatch = useDispatch()
  const [customerId, subscriptionId] = drawerResourceId.split('-')

  const subscription = useSelector(state =>
    selectSubscriptionForId(state, { customerId, subscriptionId })
  )

  const isSubscriptionsLoading = useMemo(
    () => {
      return subscription?.isLoading
    },
    [subscription]
  )

  const isUpdating = useMemo(
    () => {
      return subscription?.isUpdating
    },
    [subscription]
  )

  const isLoading = useMemo(
    () => isSubscriptionsLoading || subscription === undefined,
    [isSubscriptionsLoading, subscription]
  )

  const handleOnClose = useConfirmHoldsCallback(drawerId, onClose, [onClose])

  const onSave = useCallback(
    async () => {
      await dispatch(
        doReactivateSubscription({
          customerId,
          subscriptionId,
          integrationId: drawerIntegrationId,
        })
      )

      capture('Recharge Subscription Reactivated')

      onExit()
    },
    [dispatch, onExit, customerId, subscriptionId, drawerIntegrationId]
  )

  return (
    <Drawer
      {...rest}
      onClose={onExit}
      isLoading={isLoading}
      size="narrow"
      title="Reactivate subscription"
      open={open}
      footer={
        <ModalBtns
          saveBtnDisabled={isLoading || isUpdating}
          tertiaryBtnText="Cancel"
          onClickTertiaryBtn={handleOnClose}
          saveBtnText={
            isUpdating
              ? 'Reactivating subscription...'
              : 'Reactivate subscription'
          }
          onSave={onSave}
          saveBtnHtmlType="submit"
        />
      }
    >
      <div className="grui pt-12">
        Are you sure you want to reactivate this subscription?
      </div>
    </Drawer>
  )
}

export default RechargeReactivateSubscription
