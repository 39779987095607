import { smartSort } from 'util/arrays/sorting'

/**
 * To ID, creates a unique and predicatble ID for the object.
 * Objects with the same key value combinations will produce
 * the same ID regardless of order.
 *
 * NB this isn't throughly tested against various value type
 * serialization.
 */
export function objectToId(object, prefix = null) {
  if (typeof object === 'object' && object !== null) {
    const keys = Object.keys(object)
    const keysSorted = keys.sort(smartSort)
    return keysSorted
      .map(key => {
        const prefixedKey = `${prefix || ''}${prefix ? '.' : ''}${key}`
        const value = object[key]
        if (
          value === null ||
          value === undefined ||
          typeof value === 'function'
        )
          return ''
        return `${prefixedKey}:${objectToId(value, prefixedKey)}`
      })
      .join(';')
  }
  return JSON.stringify(object)
}
