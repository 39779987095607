import { css } from '@emotion/react'

import { rem } from '@groovehq/internal-design-system/lib/util'

const dropdownBox = css`
  margin-left: -${rem(16)};
`

const dropdownBtn = css`
  min-width: ${rem(200)};
`

const checkbox = css`
  label > span {
    margin-right: ${rem(16)};
  }
`

const table = theme => css`
  tbody tr:hover {
    td:last-of-type > * {
      opacity: 1;
      visibility: visible;
      color: ${theme.color.monochrome.mediumDark};
    }
  }
`

export const styles = {
  dropdownBox,
  dropdownBtn,
  checkbox,
  table,
}
