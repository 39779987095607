import { createSelector } from 'reselect'
import { memoize } from 'util/memoization'
import {
  selectCurrentPath as currentPathSelector,
  selectCurrentPayload,
} from 'selectors/location'
import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'
import { selectAgentsByIdIncludingArchived } from 'selectors/agents/base'
import { selectCurrentKnowledgeBaseId } from 'subapps/kb/selectors/knowledge_bases'
import { emptyObj, isEmpty, pick, mapObject } from 'util/objects'
import { emptyArr } from 'util/arrays'
import { defaultFetchFilters } from 'subapps/kb/constants/categories'

const base = state => state.kb.categories || emptyObj

const DEFAULT_CATEGORY = {
  articleIds: emptyArr,
  authorId: null,
  createdAt: null,
  description: '',
  id: 'new',
  knowledgeBaseId: null,
  metaDescription: '',
  metaRobots: 'INDEX,FOLLOW',
  ogDescription: '',
  ogImageUrl: '',
  ogTitle: '',
  pageTitle: '',
  position: 1,
  slug: '',
  state: 'draft',
  title: '',
  updatedAt: '',
}

// TODO (jscheel): This should not be here. Eventually, we either need to move
// this logic to GQL, or we need to change the data model so that index and
// follow are stored separately and the final robots tag value is computed at
// render time in the backend.
const decorateCategorySEOSettings = memoize(category => {
  const metaRobots = (category.metaRobots || '').split(',')
  return {
    metaIndex: metaRobots.includes('INDEX'),
  }
})

const decorateCategory = memoize(agentsById => {
  return memoize(category => {
    return {
      ...DEFAULT_CATEGORY,
      ...category,
      ...decorateCategorySEOSettings(category),
      author: agentsById[category.authorId],
    }
  })
})

export const selectCurrentCategoriesState = createSelector(
  selectCurrentPayload,
  payload => payload.state || defaultFetchFilters.selectedState
)

// TODO (jscheel): I think the page props should be the source of this.
export const selectActiveCategoryId = createSelector(
  currentPathSelector,
  path => {
    if (!path) return null
    if (path.match(/\/kb\/[0-9]+\/categories\/create/)) {
      return 'new'
    }
    if (path.match(/\/kb\/[0-9]+\/categories\/edit\/[0-9]+/)) {
      return path.match(/\/kb\/[0-9]+\/categories\/edit\/([0-9]+)/)[1]
    }
    return null
  }
)

export const selectById = state => base(state).byId || emptyObj
export const selectIds = state => base(state).ids || emptyArr
export const selectByArticleId = state => base(state).byArticleId || emptyObj
export const selectArticleIds = state => base(state).articleIds || emptyArr
export const selectCounts = state => base(state).counts || emptyObj

export const selectCategoriesById = createSelector(
  selectById,
  selectAgentsByIdIncludingArchived,
  (byId, agentsById) => {
    return mapObject(decorateCategory(agentsById))(byId || emptyObj)
  }
)

export const selectCategories = createSelector(
  selectCategoriesById,
  selectIds,
  (byId, ids) => ids.map(id => byId[id])
)

export const selectCategoriesTitles = state => base(state).byTitle || emptyArr

export const selectActiveCategory = createSelector(
  selectCategoriesById,
  selectActiveCategoryId,
  selectCurrentUser,
  selectCurrentKnowledgeBaseId,
  (byId, categoryId, currentUser, knowledgeBaseId) => {
    let found = byId[categoryId]
    if (categoryId === 'new') {
      found = {
        ...DEFAULT_CATEGORY,
        id: 'new',
        authorId: currentUser.id,
        state: 'draft',
        knowledgeBaseId,
      }
    }
    return found || emptyObj
  }
)

export const selectCategoriesState = state => base(state).selectedState

export const selectIsCategoriesTitlesFetched = createSelector(
  selectCategoriesTitles,
  categories => categories.length > 0
)

export const selectCategoriesKeyword = state => base(state).keyword

export const selectCategoriesStateCounts = createSelector(
  selectCounts,
  counts => counts.state || emptyObj
)

export const selectCategoryIsSaving = state => base(state).isSaving

export const selectCategoryIsSaved = createSelector(
  selectActiveCategory,
  category => !isEmpty(category)
)

export const selectIsLoading = state => base(state).isLoading

export const selectIsLoaded = state => base(state).isLoaded

export const selectCategoriesStale = state => !!base(state).stale

export const selectCategoryIsPublished = createSelector(
  selectActiveCategory,
  category => category.state === 'published'
)

export const selectCategoryIsWip = createSelector(
  selectActiveCategory,
  category => category.state === 'wip'
)

export const selectCategoryIsDraft = createSelector(
  selectActiveCategory,
  category => category.state === 'draft'
)

export const selectSortByOrder = state => base(state).sortByOrder

export const selectCurrentPage = state => base(state).currentPage
export const selectTotalPages = state => base(state).totalPages
export const selectTotalCount = state => base(state).totalCount
export const selectPerPage = state => base(state).perPage

// NOTE (jscheel): Creating selectors for each of these slices of the category
// lets us easily scope updates without defining SCU all over the place in
// our form components. This approach is totally up for discussion.

export const selectActiveCategorySEOSettings = createSelector(
  selectActiveCategory,
  category => {
    return pick(
      ['pageTitle', 'metaDescription', 'metaIndex'],
      { ...DEFAULT_CATEGORY, ...category },
      true
    )
  }
)

export const selectActiveCategoryOgSettings = createSelector(
  selectActiveCategory,
  category => {
    return pick(
      ['ogTitle', 'ogDescription', 'ogImageUrl'],
      { ...DEFAULT_CATEGORY, ...category },
      true
    )
  }
)

export const selectActiveCategoryCategorySettings = createSelector(
  selectActiveCategory,
  category => {
    return pick(
      ['categoryId', 'featured'],
      { ...DEFAULT_CATEGORY, ...category },
      true
    )
  }
)

export const selectActiveCategorySlug = createSelector(
  selectActiveCategory,
  category => {
    return category.slug === undefined || category.slug === null
      ? DEFAULT_CATEGORY.slug
      : category.slug
  }
)

export const selectActiveCategorySlugSettings = createSelector(
  selectActiveCategorySlug,
  slug => {
    return { slug }
  }
)

export const selectActiveCategoryAuthorSettings = createSelector(
  selectActiveCategory,
  category => {
    return pick(['authorId'], { ...DEFAULT_CATEGORY, ...category }, true)
  }
)

export const selectActiveCategoryContentFields = createSelector(
  selectActiveCategory,
  category => {
    return pick(
      ['title', 'description', 'body'],
      { ...DEFAULT_CATEGORY, ...category },
      true
    )
  }
)
