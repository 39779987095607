import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'

const itemWidth = css`
  max-width: 72%;
`

const item = theme => css`
  background-color: ${theme.color.monochrome.white};
  min-height: ${rem(48)};
  width: 100%;
  border-radius: ${rem(6)};
  padding: ${rem(8)} ${rem(12)} ${rem(8)} ${rem(16)};
  display: flex;
  align-items: center;
  ${itemWidth};

  &,
  .ui.input input {
    font-weight: ${theme.fontWeight.medium};
    font-size: ${theme.fontSize.large};
  }
`

const base = theme => css`
  ${item(theme)};
  padding-left: ${rem(12)};
  border: 1px solid ${theme.color.monochrome.medium};
  box-shadow: 0 ${rem(2)} ${rem(2)} 0
    rgba(${theme.color.monochrome.black_rgba}, 0.05);
  min-width: 0;
  transition: border-color 0.175s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;

  &.error {
    border-color: ${theme.color.primary.negative};
  }

  &:hover {
    border-color: ${theme.color.brand['800']};
  }

  [role='presentation'] {
    max-width: 100%;
  }

  .trigger {
    cursor: pointer;
    min-width: ${rem(80)};

    & > * {
      padding-right: ${rem(20)};
    }
  }

  // Show full width in a new line
  .automation-full-field {
    flex-basis: 100%;
    flex-grow: 1;
    max-width: unset;
    font-weight: ${theme.fontWeight.normal};
    margin-right: ${rem(-20)};

    input,
    textarea {
      padding-left: ${rem(8)};
      min-height: ${rem(38)};
    }
  }

  .automation-full-field.error input:not(:focus),
  .automation-full-field.error textarea:not(:focus),
  .automation-field-error {
    color: ${theme.color.primary.negative};
  }

  .help {
    font-weight: ${theme.fontWeight.normal};
    font-size: ${theme.fontSize.base};
    font-style: italic;
    justify-content: flex-start;
    margin: 0 0 ${rem(-4)};

    svg {
      display: none;
    }
  }

  // Overide the styles for dropdown buttons
  button.trigger,
  .trigger > button {
    border: none;
    background-color: ${theme.color.monochrome.superLight};
    font-weight: inherit;
    color: inherit;

    svg {
      opacity: 0.2;
      transition: opacity 0.2s;
    }

    &:hover {
      svg {
        opacity: 1;
      }
    }
  }

  .form-control,
  button.trigger,
  .ui.input input,
  .ui.input input:focus {
    border: none;
    background-color: ${theme.color.monochrome.superLight};
    min-height: 31px;
  }

  .ui.input {
    max-width: 140px;
    input {
      color: ${theme.color.info['900']};
      width: 100%;
    }
  }

  &&& .ui.dropdown {
    .menu {
      min-width: 240px !important;
    }
  }
`

const addButton = theme => css`
  ${itemWidth};
  ${item(theme)};
  color: ${theme.color.brand['800']};
  border: 1px dashed rgba(${theme.color.monochrome.black_rgba}, 0.19);

  &:hover {
    border-color: currentColor;
  }
`

const addButtonError = theme => css`
  border-color: ${theme.color.primary.negative};
`

const icon = css`
  width: ${rem(24)};
  height: ${rem(24)};

  &&&,
  &&& + * {
    flex-shrink: 0;
  }
`

const plusIcon = theme => css`
  color: ${theme.color.monochrome.white};
  background-color: ${theme.color.brand['800']};
  width: ${rem(16)};
  height: ${rem(16)};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: ${theme.fontSize.x2large};
  line-height: 1;
  padding-bottom: 1px;
  flex-shrink: 0;
`

const operator = theme => css`
  font-size: ${theme.fontSize.xsmall};
  font-weight: ${theme.fontWeight.medium};
  opacity: 0.8;
  color: ${theme.color.info['900']};
  text-align: center;
  padding: ${rem(6)};
  ${itemWidth};
`

const removeBtn = theme => css`
  width: ${rem(24)};
  height: ${rem(24)};

  svg {
    opacity: 0.2;
    color: ${theme.color.info['900']};
  }
`

const content = css`
  min-width: 0;
  flex: 1;
  gap: ${rem(8)};

  & > * {
    min-width: 0;
    white-space: nowrap;

    * {
      white-space: initial;
    }
  }
`

export const styles = {
  itemWidth,
  base,
  addButton,
  addButtonError,
  plusIcon,
  icon,
  operator,
  removeBtn,
  content,
}
