import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'

export const editorWrapper = css`
  & .message-template-body {
    margin-top: ${rem(25)};
  }
`

export const dropdownContainer = css`
  & > * {
    width: 100%;
  }
`

export const topFields = theme => css`
  display: flex;
  margin-left: -${theme.spacing[7]};
  margin-right: -${theme.spacing[7]};

  & > * {
    flex-basis: 50%;
    max-width: 50% !important;
    padding-left: ${theme.spacing[7]};
    padding-right: ${theme.spacing[7]};
  }
`

export const fullWidth = css`
  min-width: 100%;
`

export const copy = theme => css`
  color: rgba(${theme.color.monochrome.black_rgba}, 0.8);
  line-height: ${theme.lineHeight.leadingNone};
  margin-bottom: ${rem(13)};
`

export const checkboxList = css`
  margin-top: 0;

  & > * {
    margin-top: ${rem(14)};
  }
`

export const selection = css`
  flex-direction: column;

  & > *:first-of-type {
    width: 240px;
  }
`
