import React, { PureComponent, Fragment } from 'react'
import { Button } from 'util/ui'
import PremiumIntegrationsSidebar from '../PremiumIntegrations'

export default class PaywallButton extends PureComponent {
  state = {
    isOpen: false,
  }

  handleSidebarInit = (component, open, close) => {
    this.sideBar = {
      component,
      open,
      close,
    }
  }

  handleClose = () => {
    this.setState({ isOpen: false })
  }

  handleOpen = () => {
    this.setState({ isOpen: true })
  }

  render() {
    const { text } = this.props
    const { isOpen } = this.state
    return (
      <Fragment>
        <Button positive onClick={this.handleOpen} fluid size="large">
          {text}
        </Button>
        <PremiumIntegrationsSidebar
          onInit={this.handleSidebarInit}
          onClose={this.handleClose}
          isOpen={isOpen}
        />
      </Fragment>
    )
  }
}
