// matches Image Mime types e.g. 'image/jpeg'
export const isImageMimeType = (fileType = '') =>
  fileType.match(/^image\//) && !fileType.match(/\/vnd\./)

export const shortMimeType = fileType => fileType.split('/')[1]

export const fileClass = fileType => {
  switch (fileType) {
    case 'application/pdf':
    case 'application/x-pdf':
      return 'pdf'
    case 'image/gif':
    case 'image/png':
    case 'image/jpeg':
    case 'image/jpg':
    case 'image/bmp':
    case 'image/webp':
    case 'image/svg+xml':
      return 'image'
    case 'application/x-7z-compressed':
    case 'application/x-ace-compressed':
    case 'application/vnd.android.package-archive':
    case 'application/vnd.ms-cab-compressed':
    case 'application/x-apple-diskimage':
    case 'application/x-lzh':
    case 'application/x-rar-compressed':
    case 'application/x-stuffit':
    case 'application/x-stuffitx':
    case 'application/x-gtar':
    case 'application/zip':
    case 'application/gzip':
    case 'application/x-bzip2':
    case 'application/x-lzip':
    case 'application/x-lzma':
    case 'application/x-compress':
      return 'archive'
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.template':
    case 'application/vnd.ms-word.document.macroEnabled.12':
    case 'application/vnd.ms-word.template.macroEnabled.12':
      return 'msword'
    case 'text/plain':
      return 'text'
    default:
      return 'generic'
  }
}

export default {
  isImageMimeType,
  shortMimeType,
  fileClass,
}
