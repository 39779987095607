import { css } from '@emotion/react'

const item = css`
  display: flex;

  &:not(:first-of-type) {
    margin-top: 12px;
  }
`

const textWrapper = css`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
`

const img = css`
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 4px;
`

const title = theme => css`
  font-weight: ${theme.fontWeight.medium};
  color: ${theme.color.monochrome.dark};
`

const shippingText = theme => css`
  color: ${theme.color.monochrome.mediumDark};
`

export const styles = {
  item,
  textWrapper,
  img,
  title,
  shippingText,
}
