// Dropdown Switcher that emulates an in-place 'sub-menu'
//
// Implemented as a connected component since we need to control the dropdown
// menus by both mouse and keyboard. (onV and onPeriod key handlers need a way
// of popping these menus open)
//
import { connect } from 'react-redux'

import {
  doCloseChangeInboxMenu,
  doCloseMoreTicketOptionsMenu,
  doOpenChangeInboxMenu,
  doOpenMoreTicketOptionsMenu,
} from 'actions/menus'
import { selectCurrentTicketId } from 'selectors/tickets/current/selectCurrentTicketId'

import {
  selectIsMoreTicketOptionsMenuOpen,
  selectIsChangeInboxMenuOpen,
} from 'selectors/menus'

import { rAF } from 'util/functions'

import Switcher from './view'

const select = state => ({
  ticketId: selectCurrentTicketId(state),
  isChangeMenuOpen: selectIsChangeInboxMenuOpen(state),
  isMoreTicketOptionsOpen: selectIsMoreTicketOptionsMenuOpen(state),
})

const perform = dispatch => ({
  doCloseChangeInboxMenu: (...args) =>
    dispatch(doCloseChangeInboxMenu(...args)),
  doCloseMoreTicketOptionsMenu: (...args) =>
    rAF(() => dispatch(doCloseMoreTicketOptionsMenu(...args))),
  doOpenChangeInboxMenu: (...args) => dispatch(doOpenChangeInboxMenu(...args)),
  doOpenMoreTicketOptionsMenu: (...args) =>
    dispatch(doOpenMoreTicketOptionsMenu(...args)),
})

export default connect(select, perform)(Switcher)
