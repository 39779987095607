import { css } from '@emotion/react'

const channelOptions = theme => css`
  gap: ${theme.spacing[10]};
`

const optionCard = theme => css`
  color: inherit;
  background-color: ${theme.color.monochrome.superLight};
  max-width: 252px;
  border-radius: 6px;
  border: 2px solid transparent;

  h5 {
    color: inherit;
  }

  svg {
    width: 64px;
    height: 64px;
    display: block;
    margin: 0 auto;
  }

  &:hover {
    border-color: ${theme.color.primary.info};
  }
`

const description = css`
  max-width: 522px;
`

const recommended = () => theme => css`
  top: 4px;
  right: -10px;
  position: absolute;
  z-index: 1;
  color: ${theme.color.monochrome.white};
  background-color: ${theme.color.primary.info};
  border-radius: 4px 4px 0 4px;

  &:before {
    content: '';
    bottom: -8px;
    right: 0;
    width: 8px;
    height: 8px;
    position: absolute;
    border-top: 6px solid ${theme.color.info['200']};
    border-right: 8px solid transparent;
  }
`

export const styles = {
  channelOptions,
  optionCard,
  description,
  recommended,
}
