import React from 'react'
import { useSelector } from 'react-redux'
import { string, arrayOf, oneOf } from 'prop-types'
import {
  text,
  paragraph,
} from '@groovehq/internal-design-system/lib/styles/elements'
import { selectSearchByQueryId } from 'ducks/searches/selectors'
import useFetchEntitiesByIds from 'ducks/entities/hooks/useFetchEntitiesByIds'
import { doFetchThirdPartyAccessTokensV0 } from 'ducks/accessTokens/actions'
import { FETCH_THIRDPARTY_ACCESSTOKENS } from 'ducks/accessTokens/actionTypes'

const ThirdPartyAccessTokenContent = ({ ids, queryId, deleteMode }) => {
  const { requestState, entities } = useFetchEntitiesByIds(
    ids,
    'accessToken',
    doFetchThirdPartyAccessTokensV0,
    FETCH_THIRDPARTY_ACCESSTOKENS
  )
  const entitiesArr = Object.values(entities)

  const search = useSelector(state => selectSearchByQueryId(state, queryId))
  const { totalCount: searchTotal } = search

  const isIdDelete = deleteMode === 'ids'

  if (entitiesArr.length === 0 && requestState.loading) {
    return <span css={text.styles.fontMedium}>Loading...</span>
  }

  if (entitiesArr.length === 0) {
    return <span css={text.styles.fontMedium}>No results...</span>
  }

  if (isIdDelete && ids.length === 1) {
    const entity = entitiesArr[0]
    return (
      <>
        <p css={[paragraph.styles.preflight, text.styles.textNormal]}>
          You are about to revoke the token for “<span
            css={text.styles.fontMedium}
          >
            {entity.name}
          </span>”.
        </p>
      </>
    )
  }
  const totalToBeRemoved = isIdDelete ? ids.length : searchTotal
  const additional = totalToBeRemoved - entitiesArr.length

  return (
    <div css={text.styles.textNormal}>
      You are about to revoke the tokens for:
      {entitiesArr.map(row => (
        <p key={row.id} css={paragraph.styles.preflight}>
          <span css={text.styles.fontMedium}>
            <>“</>
            {row.name}
            <>”</>
          </span>
        </p>
      ))}
      {additional > 0 && <p>and {additional} more items.</p>}
    </div>
  )
}

ThirdPartyAccessTokenContent.propTypes = {
  deleteMode: oneOf(['ids', 'query']).isRequired,
  ids: arrayOf(string).isRequired,
  queryId: string,
}

ThirdPartyAccessTokenContent.defaultProps = {
  queryId: null,
}

export default ThirdPartyAccessTokenContent
