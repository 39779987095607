import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Drawer from '@groovehq/internal-design-system/lib/components/Drawer/UnmanagedDrawer'
import TableWithCheckbox from '@groovehq/internal-design-system/lib/components/TableWithCheckbox/TableWithCheckbox'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import { doFetchMailboxesIncludingInaccessible } from 'ducks/mailboxes/actions'
import {
  doFetchEmailMarketingIntegrationById,
  doUpdateEmailMarketingMailboxSettings,
  doUninstallEmailMarketingIntegration,
  doRebuildEmailMarketingIntegration,
} from 'ducks/integrations/emailMarketing/operations'
import { selectEmailMarketingIntegrationByProviderId } from 'ducks/integrations/emailMarketing/selectors'
import { selectCurrentChannels } from 'ducks/channels/selectors'
import AnimatedEllipsis from '@groovehq/internal-design-system/lib/components/AnimatedEllipsis/AnimatedEllipsis'

import {
  text,
  paragraph,
  button,
} from '@groovehq/internal-design-system/lib/styles/elements'
import { useFetchIntegrationProvider } from 'ducks/integrations/hooks'

const columns = [
  { Header: 'Mailboxes', accessor: 'mailboxes', disableSortBy: true },
  { Header: 'email', accessor: 'email', disableSortBy: true },
]

const PROVIDERID = 'MAILCHIMP'

const MailchimpManageDrawer = ({
  drawerResourceId: providerId,
  open,
  onClose,
  onExit,
  drawerId,
  queryId,
  ...rest
}) => {
  const dispatch = useDispatch()
  const [isLoadingDrawerData, setIsLoading] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [selectedRowIds, setSelectedRowIds] = useState([])

  const channels = useSelector(selectCurrentChannels)
  const mailchimpIntegration = useSelector(state =>
    selectEmailMarketingIntegrationByProviderId(state, PROVIDERID)
  )

  const fields = useMemo(
    () => {
      if (!mailchimpIntegration || !channels) return []

      const { mailboxSettings = [] } = mailchimpIntegration

      return channels.map(({ id, name, email }) => ({
        id,
        mailboxes: name,
        email,
        isSelected: mailboxSettings.some(ms => ms.mailboxId === id),
      }))
    },
    [channels, mailchimpIntegration]
  )

  const defaultSelectedRowIds = useMemo(
    () => {
      if (!mailchimpIntegration || !channels) return []

      const { mailboxSettings = [] } = mailchimpIntegration

      return channels.reduce((obj, { id }) => {
        // eslint-disable-next-line no-param-reassign
        obj[id] = mailboxSettings.some(ms => ms.mailboxId === id)

        return obj
      }, {})
    },
    [channels, mailchimpIntegration]
  )

  useEffect(
    () => {
      const defaultIds = Object.keys(defaultSelectedRowIds).filter(
        rowId => defaultSelectedRowIds[rowId]
      )
      setSelectedRowIds(defaultIds)
    },
    [setSelectedRowIds, defaultSelectedRowIds]
  )

  const loadData = useCallback(
    async () => {
      // Need to do these seperately, because there is dodgy data in the db
      // for mailchimp causes mailboxes to get "hydrated" in state which
      // doesnt actually exist
      await dispatch(doFetchMailboxesIncludingInaccessible())
      await dispatch(doFetchEmailMarketingIntegrationById(PROVIDERID))
      setIsLoading(false)
    },
    [dispatch]
  )

  useEffect(
    () => {
      loadData()
    },
    [loadData]
  )

  const {
    isLoading: isLoadingIntegration,
    hasError,
    isMissing,
  } = useFetchIntegrationProvider(providerId)

  const isLoading = isLoadingDrawerData || isLoadingIntegration

  const handleOnSelectedRowIdsChange = useCallback(selection => {
    // Selection passed in as a object with an id true false map
    // eg {id1: true, id2: false, id3: true}
    const ids = Object.keys(selection).filter(rowId => selection[rowId])
    setSelectedRowIds(ids)
  }, [])

  const hasChanged = useMemo(
    () => {
      const defaultIds = Object.keys(defaultSelectedRowIds).filter(
        rowId => defaultSelectedRowIds[rowId]
      )
      return !(
        defaultIds.length === selectedRowIds.length &&
        defaultIds.every(val => selectedRowIds.includes(val))
      )
    },
    [selectedRowIds, defaultSelectedRowIds]
  )

  const handleOnSave = useCallback(
    async () => {
      setIsSaving(true)
      await dispatch(
        doUpdateEmailMarketingMailboxSettings(PROVIDERID, selectedRowIds)
      )
      setIsSaving(false)
    },
    [dispatch, selectedRowIds]
  )

  const handleOnUninstall = useCallback(
    () => {
      dispatch(doUninstallEmailMarketingIntegration(PROVIDERID))
      onExit()
    },
    [dispatch, onExit]
  )

  const handleRebuildLists = useCallback(
    () => {
      dispatch(doRebuildEmailMarketingIntegration(PROVIDERID))
    },
    [dispatch]
  )

  return (
    <Drawer
      {...rest}
      onClose={onExit}
      isLoading={isLoading}
      isError={hasError}
      isNoResultFound={isMissing}
      size="wide"
      title="Configure Mailchimp"
      open={open}
      footer={
        <ModalBtns
          saveBtnText={
            <>
              {isSaving && (
                <span>
                  Saving<AnimatedEllipsis />
                </span>
              )}
              {!isSaving && 'Save'}
            </>
          }
          saveBtnDisabled={!hasChanged || isSaving}
          onSave={handleOnSave}
          warningBtnText="Uninstall MailChimp"
          warningButtonType="warningOutline"
          onClickWarningBtn={handleOnUninstall}
        />
      }
    >
      <p
        className="grui mt-10"
        css={[
          paragraph.styles.preflight,
          text.styles.textNormal,
          text.styles.textDark,
        ]}
      >
        Display the Mailchimp integration on the following mailboxes.
      </p>
      <div className="grui my-12">
        <TableWithCheckbox
          onSelectedRowIdsChange={handleOnSelectedRowIdsChange}
          columns={columns}
          data={fields}
          defaultSelectedRowIds={defaultSelectedRowIds}
        />
      </div>
      <div css={[text.styles.fontMedium, text.styles.textBlack]}>Cache</div>
      <p className="grui mb-10 mt-3" css={paragraph.styles.preflight}>
        Seeing recently deleted lists, or have lists missing?{' '}
        <a
          href="https://help.groovehq.com/help/setting-up-the-mailchimp-integration"
          target="_blank"
          rel="noopener noreferrer"
          css={button.styles.link}
        >
          Read this article
        </a>
      </p>
      <Button size="small" type="secondary" onClick={handleRebuildLists}>
        Rebuild lists &amp; webhooks
      </Button>
    </Drawer>
  )
}

export default MailchimpManageDrawer
