import { useCallback, useContext } from 'react'
import { button } from '@groovehq/internal-design-system/lib/styles/elements'
import { DrawerContext } from 'subapps/settings/components/drawers/contexts'

export default function TagDropdownCreateButton({ onCreateTag, className }) {
  const { onSaveDraft } = useContext(DrawerContext)

  const handleOnCreateTag = useCallback(
    () => {
      onSaveDraft()
      onCreateTag()
    },
    [onSaveDraft, onCreateTag]
  )

  return (
    <button
      type="button"
      css={button.styles.link}
      onClick={handleOnCreateTag}
      className={className}
    >
      + Create a tag
    </button>
  )
}
