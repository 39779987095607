import { useEffect, useCallback, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'
import useIsMounted from 'util/hooks/useIsMounted'

// Widget setup for React: https://github.com/Frill-co/frill-widget-examples/blob/master/react/src/components/FrillWidget.tsx
// API doc: https://github.com/Frill-co/frill-widget-examples/tree/master/api

const scriptId = 'frill-script'

// NOTE (jscheel): Frill is kind of a hot mess. It does not handle async loading
// well, and it does not clean up after itself. We have to store a pending queue
// of widget configurations while we asynchronously loading the script. Then we
// keep track of widgets that have been loaded before, and we do not try to
// reload them. We cannot rely on Frill's widget.destroy() method, because it
// doesn't clean up after itself. We also track the loaded widgets outside the
// context of the hook to ensure that multiple calls to the hook will not create
// differing contexts of loading statuses. Otherwise, we can easily cause Frill
// to load multiple instances of each widget every time the hook's component is
// re-rendered. Again, this wouldn't be terrible (though still quite expensive)
// if we could reliably clean up the widget, but we can't.

global.app.frill = global.app.frill || {
  pendingWidgets: {},
  loadedWidgets: {},
}

function loadWidget(widgetKey, config) {
  if (!window.Frill) {
    global.app.frill.pendingWidgets[widgetKey] = config
  } else if (!global.app.frill.loadedWidgets[widgetKey]) {
    const widget = window.Frill.widget(config)
    global.app.frill.loadedWidgets[widgetKey] = widget
  }
}

function flushPending() {
  Object.keys(global.app.frill.pendingWidgets).forEach(widgetKey => {
    loadWidget(widgetKey, global.app.frill.pendingWidgets[widgetKey])
  })
  global.app.frill.pendingWidgets = {}
}

// NOTE (jscheel): This hook never removes a widget once it is added. Frill is
// incredibly sloppy, and they do not properly clean up the resources they load.
const useFrillWidget = (widgetKey, options = {}) => {
  const { identifyUser = true, onBadgeCount } = options
  const [scriptLoaded, setScriptLoaded] = useState(!!window.Frill)
  const isMounted = useIsMounted()
  const isLoadEventAttachedRef = useRef()
  const currentUser = useSelector(selectCurrentUser)
  const onScriptLoaded = useCallback(
    () => {
      setScriptLoaded(true)
      flushPending()
    },
    [setScriptLoaded]
  )

  useEffect(
    () => {
      if (widgetKey) {
        const existing = document.getElementById(scriptId)
        if (existing) {
          if (!isLoadEventAttachedRef.current) {
            // If Frill is loading in another component's useEffect
            existing.addEventListener('load', onScriptLoaded)
          }
          return
        }
        const scriptTag = document.createElement('script')
        scriptTag.src = 'https://widget.frill.co/v2/widget.js'
        scriptTag.id = scriptId
        scriptTag.async = 'async'
        scriptTag.addEventListener('load', onScriptLoaded)
        document.body.appendChild(scriptTag)
        isLoadEventAttachedRef.current = true
      }
    },
    [widgetKey, onScriptLoaded]
  )

  useEffect(
    () => {
      if (!widgetKey || (identifyUser && !currentUser?.email)) return
      const config = {
        key: widgetKey,
        callbacks: {
          ...(identifyUser && {
            onReady: frillWidget => {
              frillWidget.identify({
                email: currentUser.email,
                name: `${currentUser.first_name} ${currentUser.last_name}`,
              })
            },
          }),
          ...(onBadgeCount && {
            onBadgeCount: ({ count }) => {
              if (isMounted()) onBadgeCount(count)
            },
          }),
        },
        settings: {
          // Because we are using our own launcher, we need to use the `null` launcher type
          launcher: {
            type: 'null',
          },
        },
      }

      loadWidget(widgetKey, config)
    },
    [widgetKey, identifyUser, currentUser, onBadgeCount, isMounted]
  )

  const toggle = useCallback(
    () => {
      const widget = global.app.frill.loadedWidgets[widgetKey]
      if (widget) widget.toggle()
    },
    [widgetKey]
  )

  const onNotify = useCallback(
    slug => {
      const widget = global.app.frill.loadedWidgets[widgetKey]
      if (!widget) return

      widget.onNotification({
        message: {
          type: 'notification',
          payload: {
            options: {
              type: 'modal',
              announcementSlug: slug,
            },
          },
        },
      })
    },
    [widgetKey]
  )

  return { toggle, onNotify, loaded: scriptLoaded }
}

export default useFrillWidget
