import SearchErrorMessage from 'components/App/Pages/SearchPage/SearchErrorMessage'
import InvalidSearchMessage from 'components/App/Pages/SearchPage/InvalidSearchMessage'
import Spinner from '@groovehq/internal-design-system/lib/components/Spinner/Spinner'

import { getLength, isEmpty } from 'util/arrays'

import Options from './Options'
import NoResults from './NoResults'

import { styles } from '../styles'

const List = ({
  mergeableTickets,
  delayedTicketIds,
  hasMoreSearch,
  hasMoreMergeableTickets,
  hasSearchErrored,
  ignoreTabEnter,
  isFetchingMoreSearch,
  isFetchingMergeableTickets,
  isQueryStringValid,
  onDown,
  onSelect,
  onUp,
  onLoadMoreSearch,
  onLoadMoreMergeableTickets,
  searchTerm,
  searchResults,
}) => {
  if (hasSearchErrored)
    return (
      <div css={styles.errorContainer}>
        <SearchErrorMessage />
      </div>
    )

  if (!isQueryStringValid) {
    return (
      <div className="grui mb-8" css={styles.errorContainer}>
        <InvalidSearchMessage />
      </div>
    )
  }

  if (searchTerm) {
    if (isFetchingMoreSearch && getLength(searchResults) <= 0) {
      return <Spinner className="grui mt-14 mb-14" />
    }

    if (getLength(searchResults) <= 0) return <NoResults />

    return (
      <Options
        hasMore={hasMoreSearch}
        delayedTicketIds={delayedTicketIds}
        ignoreTabEnter={ignoreTabEnter}
        isLoadingMore={isFetchingMoreSearch}
        items={searchResults}
        loadMore={onLoadMoreSearch}
        onDown={onDown}
        onSelect={onSelect}
        onUp={onUp}
      />
    )
  }

  if (isFetchingMergeableTickets && mergeableTickets.length === 0) {
    return <Spinner className="grui mt-14 mb-14" />
  }

  if (isEmpty(mergeableTickets)) {
    return <NoResults />
  }

  return (
    <Options
      hasMore={hasMoreMergeableTickets}
      delayedTicketIds={delayedTicketIds}
      ignoreTabEnter={ignoreTabEnter}
      isLoadingMore={isFetchingMergeableTickets}
      items={mergeableTickets}
      loadMore={onLoadMoreMergeableTickets}
      onDown={onDown}
      onSelect={onSelect}
      onUp={onUp}
    />
  )
}

export default List
