import React, { useCallback } from 'react'
import config from 'config'

import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import { AdminAccessDrawer } from 'subapps/settings/components/drawers/NoAccess'
import { useSelector } from 'react-redux'
import { selectIsBillingDataLoading } from 'ducks/billing/selectors'

export default function BillingLinkShopify({
  drawerId,
  drawerResourceId: teamId,
  previousDrawer,
  open,
  onClose,
  onExit,
  ...rest
}) {
  const isLoading = useSelector(selectIsBillingDataLoading)
  const onLink = useCallback(
    () => {
      window.location = config.shopify.appStoreGrooveAppUrl
      onClose({ ignoreStack: true })
    },
    [onClose]
  )

  return (
    <AdminAccessDrawer
      {...rest}
      open={open}
      onClose={onExit}
      title="Billing confirmation"
      loading={isLoading}
      footer={
        <ModalBtns
          onSave={onLink}
          saveBtnText="Go to Shopify"
          tertiaryBtnText="Cancel"
          onClickTertiaryBtn={onClose}
        />
      }
    >
      <div className="grui mt-10">
        <p
          className="grui mt-4"
          css={[text.styles.textNormal, text.styles.textDark]}
        >
          Your Groove account was created from a Shopify store. In order to
          continue using it, you’ll need to link it to another store. You will
          be redirected to Shopify to confirm.
        </p>
        <p
          className="grui mt-4"
          css={[text.styles.textNormal, text.styles.textDark]}
        >
          If you prefer to use Groove without Shopify, please reach out to{' '}
          <a css={[text.styles.textPrimary]} href="mailto:support@groovehq.com">
            support@groovehq.com
          </a>{' '}
          instead.
        </p>
      </div>
    </AdminAccessDrawer>
  )
}
