import DOMPurify from 'dompurify'
import config from 'config'

export function sanitize(string, opts = {}) {
  if (!DOMPurify.isSupported) {
    if (!config.isTest) {
      console.error(
        'DOMPurify is disabled, sanitizing all html tags for security reasons'
      )
    }
    try {
      return string.replace(/<[^>]+>/g, '').replace('<', '')
    } catch (e) {
      return string
    }
  }
  return DOMPurify.sanitize(string, opts || {})
}
