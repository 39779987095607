// Sorts input value regardless of type and caters for null and undefined
// Input: [10,"20",null,"1","bar", undefined,"-2",-3,null,5,"foo"]
// Output: [-3, "-2", "1", 5, 10, "20", "bar", "foo", null, null, undefined]
export function smartSort(
  a,
  b,
  { sensitivity = 'base', direction = 'asc' } = {}
) {
  /* Default ascending order */
  const sortAsc = ['asc', 'ASC'].includes(direction)

  if ((a === null || a === undefined) && (b === null || b === undefined))
    return 0
  if (a === null || a === undefined) return 1
  if (b === null || b === undefined) return -1

  const result = a - b

  if (isNaN(result)) {
    return sortAsc
      ? a.toString().localeCompare(b, { sensitivity })
      : b.toString().localeCompare(a, { sensitivity })
  }

  return sortAsc ? result : -result
}

export const sortByKey = (array, key, options) => {
  if (!array) return []
  return [].concat(array).sort((a, b) => {
    return smartSort(a[key], b[key], options)
  })
}
