import { useCallback, useMemo } from 'react'
import { useFieldArray } from 'react-hook-form'
import {
  text,
  button,
} from '@groovehq/internal-design-system/lib/styles/elements'
import { SNOOZED_INDEFINITELY } from 'util/snooze'

import Action from 'subapps/settings/components/Action'
import { cannedReplyActions } from './data'

const NOT_TOGEHTER = {
  assignee_id: 'assignee_group_id',
  assignee_group_id: 'assignee_id',
}

const prepareAutomaticActionsParameters = fields => {
  const pickedFieldTypes = fields.map(f => f.type)

  const parameters = cannedReplyActions.parameters.map(p => ({
    name: p.name,
    value: p.value,
    disabled:
      pickedFieldTypes.includes(p.value) ||
      (NOT_TOGEHTER[p.value] &&
        pickedFieldTypes.includes(NOT_TOGEHTER[p.value])),
  }))

  return parameters
}

const CannedReplyAutomaticActions = ({ control, trigger, setValue }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'automatic_actions',
  })
  const filteredFields = fields.filter(f => f.type !== 'labels_by_id')

  const onAdd = useCallback(
    () => {
      const newEntity = {
        type: null,
        value: '',
      }
      append(newEntity)
    },
    [append]
  )

  const onRemove = useCallback(
    (action, index) => {
      remove(index)
    },
    [remove]
  )

  const onChange = useCallback(
    (newEntity, index) => {
      setValue(`automatic_actions.${index}`, newEntity, {
        shouldDirty: true,
        shouldValidate: true,
      })
      trigger(`automatic_actions.${index}`)
    },
    [setValue, trigger]
  )

  const automaticActionsVariables = useMemo(
    () => {
      const parameters = prepareAutomaticActionsParameters(fields)

      const indefinitelyOption = cannedReplyActions.values.snooze_until.options.find(
        o => o.value === 'indefinitely'
      )
      if (indefinitelyOption) indefinitelyOption.value = SNOOZED_INDEFINITELY

      return {
        parameters,
        values: cannedReplyActions.values,
      }
    },
    [fields]
  )

  return (
    <div>
      <div>
        {fields.map((item, index) => {
          const { id, key } = item

          if (item.type === 'labels_by_id') {
            return null
          }

          return (
            <Action
              control={control}
              name={`automatic_actions.${index}`}
              key={`automatic-action-${id}-${key}`}
              itemKey={key}
              variables={automaticActionsVariables}
              index={index}
              onRemoveAction={onRemove}
              onActionChange={onChange}
              onInput={onChange}
              totalActions={filteredFields.length + 1}
            />
          )
        })}
      </div>

      <div className="grui mt-5">
        <button
          type="button"
          css={[
            button.styles.link,
            text.styles.textXs,
            text.styles.textPrimary,
          ]}
          onClick={onAdd}
        >
          + Add action
        </button>
      </div>
    </div>
  )
}

export default CannedReplyAutomaticActions
