import ScatterSwap from 'scatter-swap'

export function reverseHashInt(val) {
  if ([null, undefined].includes(val)) return null
  return parseInt(new ScatterSwap(val).reverseHash(), 10)
}

export function hash(val) {
  if ([null, undefined].includes(val)) return null
  return new ScatterSwap(val).hash()
}

export function convertScatterIdToRaw(id) {
  if (id === 'new') return id
  return reverseHashInt(id)
}

export function convertGidToRaw(gid) {
  if (gid === 'new') return gid
  return convertScatterIdToRaw(gid.split('_').pop())
}

export function convertGidToScatterId(gid) {
  if ([null, undefined].includes(gid)) return null
  if (gid === 'new') return gid
  return gid.split('_').pop()
}
