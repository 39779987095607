import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import * as logos from '@groovehq/internal-design-system/lib/assets/icons/logos'
import Drawer from '@groovehq/internal-design-system/lib/components/Drawer/UnmanagedDrawer'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import {
  heading,
  text,
  button,
  paragraph,
} from '@groovehq/internal-design-system/lib/styles/elements'
import { useFetchIntegrationProvider } from 'ducks/integrations/hooks'
import { doClearIntegrationDraft } from 'ducks/integrations/operations/settings'
import { useZapier } from 'ducks/integrations/zapier/hooks'

import { styles } from '../../Install/styles'

const ZapierInstallDrawer = ({
  drawerResourceId: providerId,
  open,
  onClose,
  onExit,
  drawerId,
  queryId,
  ...rest
}) => {
  const dispatch = useDispatch()

  const {
    provider,
    isLoading,
    hasError,
    isMissing,
  } = useFetchIntegrationProvider(providerId)

  const { logo, description, name } = provider || {}

  const beforeDrawerClose = useCallback(
    () => {
      dispatch(doClearIntegrationDraft(providerId))
    },
    [providerId, dispatch]
  )

  const handleOnExit = useCallback(
    () => {
      beforeDrawerClose()
      onExit()
    },
    [onExit, beforeDrawerClose]
  )

  const appLogo = useMemo(
    () => {
      const Logo = logos[logo]

      if (!Logo) return null

      return <Logo />
    },
    [logo]
  )

  const {
    loaded,
    isInstalled,
    request: { loading: isRemoving = false } = {},
    deleteAccessTokens,
  } = useZapier()

  return (
    <Drawer
      {...rest}
      onClose={handleOnExit}
      isLoading={isLoading}
      isError={hasError}
      isNoResultFound={isMissing}
      size="wide"
      title="Zapier"
      open={open}
      footer={null}
    >
      <div className="grui flex items-center my-10">
        {appLogo && <div css={styles.imageBox}>{appLogo}</div>}
        <h3 css={heading.styles.h3}>{name}</h3>
      </div>
      <div
        className="grui mb-10"
        css={[paragraph.styles.preflight, text.styles.textNormal]}
      >
        <p>{description}</p>

        <p>
          For detailed instructions, check out our Knowledge Base article on{' '}
          <a
            href="https://help.groovehq.com/help/setting-up-the-zapier-integration"
            rel="noopener noreferrer"
            target="_blank"
            css={[button.styles.link, text.styles.textPrimary]}
          >
            setting up your Zapier integration here.
          </a>
        </p>
        <p className="grui mb-14">
          <Button
            size="big"
            as="a"
            href="https://zapier.com/apps/groove/integrations"
            target="_blank"
          >
            Connect Groove to 5000+ apps with Zapier
          </Button>
          {isInstalled && (
            <Button
              size="big"
              type="warning"
              onClick={deleteAccessTokens}
              disabled={isRemoving}
              className="grui ml-4"
            >
              {!isRemoving && 'Uninstall'}
              {isRemoving && 'One moment...'}
            </Button>
          )}
        </p>
        <p css={[paragraph.styles.preflight, text.styles.fontMedium]}>
          You can also install any of the Zaps below with just a few clicks:
        </p>
      </div>
      <div>
        {loaded && (
          <zapier-zap-templates
            apps="groove"
            create-without-template="hide"
            limit="20"
            link-target="new-tab"
            presentation="row"
            use-this-zap="show"
            theme="light"
          />
        )}
      </div>
    </Drawer>
  )
}

export default ZapierInstallDrawer
