import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'
import { useController, useForm } from 'react-hook-form'
import { useConfirmHoldsCallback } from 'util/dirtyHolds'
import { styles as fieldStyles } from '@groovehq/internal-design-system/lib/components/Field/Field.styles'
import { selectSubscriptionForId } from 'ducks/integrations/recharge/selectors/subscriptions'
import { capture } from 'ducks/tracking/actions'

import Drawer from '@groovehq/internal-design-system/lib/components/Drawer/UnmanagedDrawer'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import DropdownMenu from 'subapps/settings/components/DropdownMenu'
import Dropdown from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import Textarea from '@groovehq/internal-design-system/lib/components/Textarea/Textarea'
import Checkbox from '@groovehq/internal-design-system/lib/components/Checkbox/Checkbox'
import * as yup from 'yup'
import doCancelSubscription from 'ducks/integrations/recharge/operations/doCancelSubscription'

const { Button: DropdownButton } = Dropdown

const reasons = [
  {
    id: 'too-expensive',
    value: 'This is too expensive',
    name: 'This is too expensive',
  },
  {
    id: 'created-by-accident',
    value: 'This was created by accident',
    name: 'This was created by accident',
  },
  {
    id: 'already-have-more',
    value: 'I already have more than I need',
    name: 'I already have more than I need',
  },
  {
    id: 'need-it-sooner',
    value: 'I need it sooner',
    name: 'I need it sooner',
  },
  {
    id: 'no-longer-use',
    value: 'I no longer use this product',
    name: 'I no longer use this product',
  },
  {
    id: 'want-a-different-product',
    value: 'I want a different product or variety',
    name: 'I want a different product or variety',
  },
  { id: 'other-reason', value: 'Other reason', name: 'Other reason' },
]

const RechargeCancelSubscription = ({
  drawerResourceId,
  drawerIntegrationId,
  open,
  onClose,
  onExit,
  drawerId,
  queryId,
  ...rest
}) => {
  const dispatch = useDispatch()
  const [customerId, subscriptionId] = drawerResourceId.split('-')

  const subscription = useSelector(state =>
    selectSubscriptionForId(state, { customerId, subscriptionId })
  )

  const isSubscriptionsLoading = useMemo(
    () => {
      return subscription?.isLoading
    },
    [subscription]
  )

  const isUpdating = useMemo(
    () => {
      return subscription?.isUpdating
    },
    [subscription]
  )

  const isLoading = useMemo(
    () => isSubscriptionsLoading || subscription === undefined,
    [isSubscriptionsLoading, subscription]
  )

  const FORM_SCHEMA = useMemo(() => {
    return yup.object().shape({
      reason: yup
        .string()
        .trim()
        .required('Cancelation reason is required'),
    })
  }, [])

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    control,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(FORM_SCHEMA),
    defaultValues: {
      reason: '',
      reasonComment: '',
      sendEmail: false,
    },
  })

  const {
    field: {
      onChange: handleSelectReason,
      ref: eventRef,
      value: selectedReason,
    },
  } = useController({
    name: 'reason',
    control,
  })

  const handleOnClose = useConfirmHoldsCallback(drawerId, onClose, [onClose])

  const onSubmit = useCallback(
    async ({ reason, reasonComment, sendEmail }) => {
      await dispatch(
        doCancelSubscription({
          customerId,
          subscriptionId,
          integrationId: drawerIntegrationId,
          reason,
          reasonComment,
          sendEmail,
        })
      )
      capture('Recharge Subscription Canceled')
      onExit()
    },
    [dispatch, onExit, customerId, subscriptionId, drawerIntegrationId]
  )

  const DrawerForm = useCallback(
    props => <form onSubmit={handleSubmit(onSubmit)} {...props} />,
    [handleSubmit, onSubmit]
  )

  return (
    <Drawer
      {...rest}
      onClose={onExit}
      isLoading={isLoading}
      size="narrow"
      title="Cancel subscription"
      open={open}
      footer={
        <ModalBtns
          saveBtnDisabled={!isValid || isLoading || isUpdating}
          tertiaryBtnText="Cancel"
          onClickTertiaryBtn={handleOnClose}
          saveBtnText={
            isUpdating ? 'Cancelling subscription...' : 'Cancel subscription'
          }
          saveBtnHtmlType="submit"
          saveButtonType="warningOutline"
        />
      }
      container={DrawerForm}
    >
      <div className="grui pt-12">
        <div className="grui mb-12">
          <div css={fieldStyles.labelBox}>Cancelation reason</div>
          <Dropdown
            overlay={<DropdownMenu data={reasons} />}
            onSelect={handleSelectReason}
            selectedKey={selectedReason}
            validateStatus={errors?.reason ? 'error' : undefined}
            help={errors?.reason?.message}
          >
            <DropdownButton ref={eventRef}>
              {selectedReason || '- Please select -'}
            </DropdownButton>
          </Dropdown>
        </div>

        <Textarea
          {...register('reasonComment')}
          label="Cancelation reason comment (optional)"
          name="reasonComment"
        />

        <div className="grui mt-12">
          <Checkbox id="bcc" {...register('sendEmail')}>
            Send a cancelation email
          </Checkbox>
        </div>
      </div>
    </Drawer>
  )
}

export default RechargeCancelSubscription
