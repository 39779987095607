import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useController, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import Field from '@groovehq/internal-design-system/lib/components/Field/Field'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import Dropdown from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import Search from '@groovehq/internal-design-system/lib/components/Search/Search'
import { styles as fieldStyles } from '@groovehq/internal-design-system/lib/components/Field/Field.styles'
import { AdminAccessDrawer } from 'subapps/settings/components/drawers/NoAccess'
import countryByAbbreviation from 'country-json/src/country-by-abbreviation.json'
import DropdownMenu from 'subapps/settings/components/DropdownMenu'
import { selectBillingInformation } from 'ducks/billing/selectors'
import {
  doFetchBillingInformation,
  doUpdateBillingInformation,
} from 'ducks/billing/operations'
import { isEmpty } from 'util/objects'
import debug from 'util/debug'
import { useBilling } from 'ducks/billing/hooks'

import { styles } from './styles'

const countryOptions = countryByAbbreviation
  .filter(
    item => item.abbreviation !== null || item.country === 'Northern Ireland'
  )
  .map(item => ({
    key: `country-option-${item.abbreviation}`,
    text:
      item.abbreviation === 'GB'
        ? 'United Kingdom of Great Britain and Northern Ireland'
        : item.country,
    value: item.abbreviation,
  }))

const FORM_SCHEMA = yup.object().shape({
  name: yup.string().required('Name is required'),
})

const BillingInformation = ({ open, onExit }) => {
  const dispatch = useDispatch()
  const billingInformation = useSelector(selectBillingInformation)
  const {
    providerSettings: { supportBillingInformation },
  } = useBilling()

  const {
    handleSubmit,
    formState: { isDirty, isValid, isSubmitting, errors },
    register,
    control,
    reset,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(FORM_SCHEMA),
    defaultValues: billingInformation,
  })

  const [countryList, setCountryList] = useState(countryOptions)
  const [countrySearch, setCountrySearch] = useState('')
  const btnDisabled = !isValid || isSubmitting || !isDirty

  const {
    field: { onChange: handleSelectCountry, value: selectedCountry },
  } = useController({
    name: 'country',
    control,
  })

  const onSubmit = useCallback(
    async data => {
      try {
        await dispatch(doUpdateBillingInformation(data))
        onExit()
      } catch (error) {
        debug(error)
      }
    },
    [dispatch, onExit]
  )

  const handleSearch = useCallback(e => {
    const { value } = e.target
    const loweredCaseValue = value.toLowerCase()
    setCountrySearch(value)
    setCountryList(
      countryOptions.filter(option => {
        return (
          option.text.toLowerCase().includes(loweredCaseValue) ||
          option.value.toLowerCase().includes(loweredCaseValue)
        )
      })
    )
  }, [])

  const footer = (
    <ModalBtns
      saveBtnText="Update"
      saveBtnHtmlType="submit"
      saveBtnForm="billing-information-form"
      saveBtnDisabled={btnDisabled}
    />
  )

  const overlay = useMemo(
    () => {
      return <DropdownMenu data={countryList} />
    },
    [countryList]
  )

  const selectedCountryName = useMemo(
    () => {
      return countryOptions.find(option => option.value === selectedCountry)
        ?.text
    },
    [selectedCountry]
  )

  useEffect(
    () => {
      if (!supportBillingInformation) {
        onExit()
        return
      }
      dispatch(doFetchBillingInformation())
    },
    [dispatch, supportBillingInformation, onExit]
  )

  useEffect(
    () => {
      if (!isEmpty(billingInformation)) {
        reset(billingInformation)
      }
    },
    [billingInformation, reset]
  )

  return (
    <AdminAccessDrawer
      title="Billing information"
      footer={footer}
      open={open}
      onClose={onExit}
    >
      <form
        id="billing-information-form"
        className="grui pb-12"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Field
          label="Name"
          placeholder="Name"
          {...register('name')}
          validateStatus={errors?.name ? 'error' : undefined}
          help={errors?.name?.message}
          className="grui my-12"
        />
        <Field
          label="Phone"
          placeholder="Phone (including extension)"
          {...register('phone')}
          validateStatus={errors?.phone ? 'error' : undefined}
          help={errors?.phone?.message}
          className="grui my-12"
        />
        <Field
          label="Address Line 1"
          placeholder="Street address/PO Box/Organization name"
          {...register('line1')}
          validateStatus={errors?.line1 ? 'error' : undefined}
          help={errors?.line1?.message}
          className="grui my-12"
        />
        <Field
          label="Address Line 2"
          placeholder="Apartment/Suite/Unit/Building"
          {...register('line2')}
          validateStatus={errors?.line2 ? 'error' : undefined}
          help={errors?.line2?.message}
          className="grui my-12"
        />
        <Field
          label="City"
          placeholder="City/District/Suburb/Town/Village."
          {...register('city')}
          validateStatus={errors?.city ? 'error' : undefined}
          help={errors?.city?.message}
          className="grui my-12"
        />
        <Field
          label="State"
          placeholder="State/County/Province/Region."
          {...register('state')}
          validateStatus={errors?.state ? 'error' : undefined}
          help={errors?.state?.message}
          className="grui my-12"
        />
        <div>
          <div css={fieldStyles.labelBox}>Country</div>
          <Dropdown
            overlay={overlay}
            onSelect={handleSelectCountry}
            selectedKey={selectedCountry}
            header={
              <Search
                value={countrySearch}
                onChange={handleSearch}
                className="grui mw-100 w-100"
              />
            }
            headerSticky
            css={styles.dropdownContainer}
          >
            <Dropdown.Button css={styles.dropdownBtn}>
              {selectedCountryName || 'Select country'}
            </Dropdown.Button>
          </Dropdown>
        </div>
        <Field
          label="Postal code"
          placeholder="ZIP or postal code."
          {...register('postalCode')}
          validateStatus={errors?.postalCode ? 'error' : undefined}
          help={errors?.postalCode?.message}
          className="grui my-12"
        />
        <Field
          label="Vat Number"
          placeholder="Organization vat number"
          {...register('vatId')}
          validateStatus={errors?.vatId ? 'error' : undefined}
          help={errors?.vatId?.message}
        />
      </form>
    </AdminAccessDrawer>
  )
}

export default BillingInformation
