function getMobileMQ() {
  if (typeof window === 'undefined') return null
  if (!window.matchMedia) return null
  return window.matchMedia('screen and (max-width: 1023px)')
}

export const mobileMQ = getMobileMQ()

export function isMobile() {
  return mobileMQ && !!mobileMQ.matches
}
