import { css } from '@emotion/react'

const optionCard = theme => css`
  color: inherit;
  background-color: ${theme.color.monochrome.superLight};
  max-width: 228px;
  border-radius: 6px;
  border: 2px solid transparent;

  h5 {
    color: inherit;
  }

  svg {
    width: 64px;
    height: 64px;
    display: block;
    margin: 0 auto;
  }

  & + * {
    margin-left: 16px;
  }

  &:hover {
    border-color: ${theme.color.primary.info};
  }
`

const description = css`
  max-width: 500px;
`

export const styles = {
  optionCard,
  description,
}
