// Shared Storage (Between App and ServiceWorker)
import idb from './idb'

const API_URL_KEY = 'graphQLUrl'
const API_AUTH_TOKEN_KEY = 'apiAuthToken'
const CURRENT_USER_ID_KEY = 'currentUserId'

const sharedStorage = {
  setGraphQLUrl: token => idb.set(API_URL_KEY, token),
  getGraphQLUrl: () => idb.get(API_URL_KEY),

  setAPIAuthToken: token => idb.set(API_AUTH_TOKEN_KEY, token),
  getAPIAuthToken: () => idb.get(API_AUTH_TOKEN_KEY),

  setCurrentUserId: token => idb.set(CURRENT_USER_ID_KEY, token),
  getCurrentUserId: () => idb.get(CURRENT_USER_ID_KEY),

  clear: () => idb.clear(),
}

export default sharedStorage
