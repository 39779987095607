import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import { getFormattedPrice } from 'util/numbers'
import { selectUpgradeBreakdownForPricingIds } from 'ducks/billing/selectors/selectUpgradeBreakdownForPricingIds'
import { areArraysEqual } from 'util/arrays'
import { styles as menuWithSearchStyles } from 'subapps/settings/components/MenuWithSearch'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'
import { selectPrimaryCreditCard } from 'ducks/billing/selectors'
import { useBilling } from 'ducks/billing/hooks'
import {
  BILLING_CYCLE_ANNUAL,
  BILLING_CYCLE_MONTHLY,
} from 'ducks/billing/constants'
import { styles } from '../styles'

const AnnualSavingsSection = ({
  isUpgradingToAnnual,
  currentPricingIds,
  newBillingCycle,
  currentBillingCycle,
  upgradeAt,
  newPricingIds,
}) => {
  const { last4 } = useSelector(selectPrimaryCreditCard) || {}
  const { calculateEstimatedTotalBill } = useBilling()

  const pricingIdsChanged = useMemo(
    () => {
      return !areArraysEqual(currentPricingIds, newPricingIds)
    },
    [currentPricingIds, newPricingIds]
  )

  const {
    prorataRefund: remainingPaid,
    prorataCharge: prorataCharges,
    refundDays,
    chargeDays,
  } = useSelector(state =>
    selectUpgradeBreakdownForPricingIds(
      state,
      currentPricingIds,
      newPricingIds,
      currentBillingCycle,
      newBillingCycle,
      upgradeAt
    )
  )

  const totalMonthlyBillForAYear = useMemo(
    () => calculateEstimatedTotalBill(BILLING_CYCLE_MONTHLY) * 12,
    [calculateEstimatedTotalBill]
  )

  const totalAnnualBill = useMemo(
    () => calculateEstimatedTotalBill(BILLING_CYCLE_ANNUAL),
    [calculateEstimatedTotalBill]
  )

  const totalSavings = Math.max(0, totalMonthlyBillForAYear - totalAnnualBill)
  const totalToBeCharged = prorataCharges - remainingPaid

  return (
    <>
      <div className="grui px-7">
        {isUpgradingToAnnual &&
          !pricingIdsChanged && (
            <div
              css={[styles.row, text.styles.textNormal, styles.savingRow]}
              className="grui mt-10 pb-4"
            >
              <strong>Your annual savings</strong>
              <strong>{getFormattedPrice(totalSavings, 'USD')}</strong>
            </div>
          )}
        {totalToBeCharged > 0 && (
          <>
            <div css={text.styles.fontMedium} className="grui mt-8 mb-7">
              <Tooltip
                title="Your breakdown credit and pro-rata charge are calculated to the minute and may differ slightly when billed."
                strategy="fixed"
                portal="#overlays"
                className="grui py-8"
              >
                <span>
                  Breakdown estimate
                  <span
                    css={menuWithSearchStyles.tooltipIcon}
                    className="grui ml-3"
                  >
                    i
                  </span>
                </span>
              </Tooltip>
            </div>
            <div css={styles.row}>
              <div className="grui mb-4 truncate">
                Credit for remaining days paid for: {refundDays} days
              </div>
              <div css={text.styles.fontMedium}>
                - {getFormattedPrice(remainingPaid, 'USD')}
              </div>
            </div>
            <div css={styles.row}>
              <div className="grui truncate mb-10">
                Pro-rata charge for upcoming days: ${chargeDays} days
              </div>
              <div css={text.styles.fontMedium}>
                + {getFormattedPrice(prorataCharges, 'USD')}
              </div>
            </div>
          </>
        )}
      </div>
      <div className="grui px-7 pb-8">
        <div>
          <div css={[styles.row, text.styles.fontMedium]}>
            <div>Total to be charged</div>
            <div>{getFormattedPrice(totalToBeCharged, 'USD')}</div>
          </div>
          <div css={[text.styles.text2Xs, styles.note]} className="grui mt-4">
            Note: Your credit card ending {last4} will be charged immediately
          </div>
        </div>
      </div>
    </>
  )
}
export default AnnualSavingsSection
