import React, { useEffect, useCallback } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import Drawer from '@groovehq/internal-design-system/lib/components/Drawer/Drawer'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'

import useFetchEntityById from 'ducks/entities/hooks/useFetchEntityById'
import { doUpdateUser } from 'ducks/agents/operations'
import { doFetchAgent } from 'actions/agents'
import { useRhfDirtyHold, useSimpleDrawerConfirmHolds } from 'util/dirtyHolds'
import UserAdvancedContent from './Content'

const FORM_SCHEMA = yup.object().shape({
  chat_allow_round_robin: yup.boolean(),
  include_in_analytics: yup.boolean(),
  visible_in_widget: yup.boolean(),
})

const curriedDoFetchAgent = ({ id }) => doFetchAgent(id)

export default function UserAdvancedDrawer({
  drawerResourceId: userId,
  previousDrawer,
  entityType,
  open,
  onClose,
  onExit,
  drawerId,
  ...rest
}) {
  const dispatch = useDispatch()

  const { requestState, entity: user } = useFetchEntityById(
    userId,
    'agent',
    curriedDoFetchAgent,
    'fetchAgent'
  )

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      chat_allow_round_robin: true,
      include_in_analytics: true,
      visible_in_widget: true,
    },
    resolver: yupResolver(FORM_SCHEMA),
  })
  const {
    handleSubmit,
    reset,
    control,
    formState: { hasError, isValid, isDirty, isSubmitting },
  } = methods

  const { releaseHold, holdKey } = useRhfDirtyHold(drawerId, control)

  useEffect(
    () => {
      if (user) {
        reset(user)
      }
    },
    [reset, user]
  )

  const onSubmit = useCallback(
    async fields => {
      dispatch(doUpdateUser(userId, fields))
      releaseHold()
      onClose()
    },
    [dispatch, onClose, userId, releaseHold]
  )

  const [handleOnClose, handleOnExit] = useSimpleDrawerConfirmHolds(
    holdKey,
    onClose,
    onExit
  )

  const DrawerForm = useCallback(
    props => <form onSubmit={handleSubmit(onSubmit)} {...props} />,
    [handleSubmit, onSubmit]
  )

  const disableSave =
    requestState.loading || isSubmitting || hasError || !isValid || !isDirty

  return (
    <FormProvider {...methods}>
      <Drawer
        {...rest}
        open={open}
        onClose={handleOnExit}
        size="narrow"
        title="Advanced settings"
        isLoading={requestState.loading}
        isError={requestState.error}
        isNoResultFound={requestState.loaded && !user}
        footer={
          <ModalBtns
            saveBtnDisabled={disableSave}
            saveBtnText="Save"
            saveBtnHtmlType="submit"
            tertiaryBtnText="Cancel"
            onClickTertiaryBtn={handleOnClose}
          />
        }
        container={DrawerForm}
      >
        <UserAdvancedContent onSubmit={onSubmit} />
      </Drawer>
    </FormProvider>
  )
}
