import React from 'react'
import Dropdown from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'

const { Menu } = Dropdown

const DropdownMenu = React.memo(({ data }) => (
  <Menu>
    {data.map(({ title, key }) => (
      <Menu.Item key={key} itemKey={key}>
        {title}
      </Menu.Item>
    ))}
  </Menu>
))

export default DropdownMenu
