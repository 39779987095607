import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { doCreateCategory } from 'subapps/kb/actions/categories'
import { selectCategoryIsSaving } from 'subapps/kb/selectors/categories'
import * as yup from 'yup'
import Drawer from '@groovehq/internal-design-system/lib/components/Drawer/UnmanagedDrawer'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import Field from '@groovehq/internal-design-system/lib/components/Field/Field'
import Textarea from '@groovehq/internal-design-system/lib/components/Textarea/Textarea'

import { styles } from './styles'

const FORM_SCHEMA = yup.object().shape({
  title: yup
    .string()
    .trim()
    .required('Category title is required'),
  description: yup.string().trim(),
})

const CategoryCreate = ({ onClose, onExit }) => {
  const dispatch = useDispatch()
  const isSaving = useSelector(selectCategoryIsSaving)

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm({
    mode: 'all',
    defaultValues: { title: '', description: '' },
    resolver: yupResolver(FORM_SCHEMA),
    delayError: 300,
  })

  const onCreate = useCallback(
    async data => {
      await dispatch(doCreateCategory(data, { shouldRedirect: false }))
      onClose()
    },
    [dispatch, onClose]
  )

  return (
    <Drawer
      onClose={onExit}
      title="New category"
      open
      footer={
        <ModalBtns
          saveBtnText={isSaving ? 'Creating...' : 'Create'}
          saveBtnDisabled={!isValid || isSaving}
          onSave={handleSubmit(onCreate)}
          tertiaryBtnText="Cancel"
          onClickTertiaryBtn={onClose}
        />
      }
    >
      <div className="grui flex flex-col pt-10">
        <Field
          {...register('title', { required: true })}
          css={styles.fullWidth}
          className="grui w-100"
          label="Title"
          placeholder="Enter category title..."
          validateStatus={errors?.title ? 'error' : undefined}
          help={errors?.title?.message}
        />

        <div className="grui mt-10 mb-12">
          <Textarea
            {...register('description', { required: true })}
            css={styles.fullWidth}
            label="Description"
            placeholder="Enter category description..."
            rows="5"
          />
        </div>

        <p className="grui mt-auto mb-0">
          You can edit this category&apos;s SEO settings from the categories
          page after creation.
        </p>
      </div>
    </Drawer>
  )
}

export default CategoryCreate
