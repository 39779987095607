import Popup from 'semantic-ui-react/dist/es/modules/Popup'
import styles from './styles.css'

class ImprovedPopup extends Popup {
  _scrollNode = null
  _scrollNodeTop = 0
  _scrollNodeLeft = 0

  constructor(props) {
    super(props)
    // NOTE (jscheel): We have to do this juggling here because fat arrow class
    // property method definitions do not keep the super context.
    this._originalHandlePortalMount = this.handlePortalMount
    this._originalHandlePortalUnmount = this.handlePortalUnmount
    this.handlePortalMount = this._handlePortalMount.bind(this)
    this.handlePortalUnmount = this._handlePortalUnmount.bind(this)
    // if (this.props.fixed) {
    //   ImprovedPopup.prototype.isStyleInViewport = () => {}
    // }
  }

  _handlePortalMount(e, data) {
    this._originalHandlePortalMount(...arguments)
    const { scrollNode } = this.props
    if (scrollNode) {
      this._scrollNodeTop = scrollNode.scrollTop
      this._scrollNodeLeft = scrollNode.scrollLeft
      //   scrollNode.addEventListener('scroll', this.handleMountNodeScroll)
      //   this.updateScrollNodePosition()
    }
  }

  _handlePortalUnmount(e, data) {
    this._originalHandlePortalUnmount(...arguments)
    // const { scrollNode } = this.props
    // if (scrollNode) {
    //   scrollNode.removeEventListener('scroll', this.handleMountNodeScroll)
    // }
  }

  handleMountNodeScroll = e => {
    // this.updateScrollNodePosition()
    // if (this.props.hideOnScroll) {
    //   this.hideOnScroll(e)
    // }
  }

  // updateScrollNodePosition = () => {
  //   this._scrollNodeTop = this._scrollNode.scrollTop
  //   this._scrollNodeLeft = this._scrollNode.scrollLeft
  // }

  // computePopupStyle(positions) {
  //   const style = super.computePopupStyle(positions)
  //   // if (this._scrollNode) {
  //   //   if (!Number.isNaN(parseInt(style.top, 10))) {
  //   //     style.top += this._scrollNodeTop
  //   //   } else {
  //   //     style.bottom += this._scrollNodeTop
  //   //   }
  //   //   if (!Number.isNaN(parseInt(style.right, 10))) {
  //   //     style.right -= this._scrollNodeLeft
  //   //   } else {
  //   //     style.left -= this._scrollNodeLeft
  //   //   }
  //   // }
  //   return style
  // }

  getContext = () => this.props.context || this.triggerRef

  computePopupStyle(positions) {
    const style = { position: 'absolute' }
    const context = this.getContext()

    const { horizontalOffset, verticalOffset, scrollNode } = this.props
    // const { pageYOffset, pageXOffset } = window
    // const { clientWidth, clientHeight } = document.documentElement

    let pageYOffset
    let pageXOffset
    let clientWidth
    let clientHeight
    if (scrollNode) {
      const mountNodeDimensions = scrollNode.getBoundingClientRect()
      // pageYOffset = mountNodeDimensions.scrollTop
      // pageXOffset = mountNodeDimensions.scrollLeft
      // pageYOffset = scrollNode.scrollTop
      // pageXOffset = scrollNode.scrollLeft
      pageYOffset = this._scrollNodeTop - scrollNode.scrollTop
      pageXOffset = this._scrollNodeLeft - scrollNode.scrollLeft
      clientWidth = mountNodeDimensions.width
      clientHeight = mountNodeDimensions.height
    } else {
      pageYOffset = window.pageYOffset
      pageXOffset = window.pageYOffset
      clientWidth = document.documentElement.clientWidth
      clientHeight = document.documentElement.clientHeight
    }

    const coords = this.coords || context.getBoundingClientRect()

    if (positions.indexOf('right') >= 0) {
      style.right = Math.round(clientWidth - (coords.right + pageXOffset))
      style.left = 'auto'
    } else if (positions.indexOf('left') >= 0) {
      style.left = Math.round(coords.left + pageXOffset)
      style.right = 'auto'
    } else {
      // if not left nor right, we are horizontally centering the element
      const xOffset = (coords.width - this.popupCoords.width) / 2
      style.left = Math.round(coords.left + xOffset + pageXOffset)
      style.right = 'auto'
    }

    if (positions.indexOf('top') >= 0) {
      style.bottom = Math.round(clientHeight - (coords.top + pageYOffset))
      style.top = 'auto'
    } else if (positions.indexOf('bottom') >= 0) {
      style.top = Math.round(coords.bottom + pageYOffset)
      style.bottom = 'auto'
    } else {
      // if not top nor bottom, we are vertically centering the element
      const yOffset = (coords.height + this.popupCoords.height) / 2
      style.top = Math.round(coords.bottom + pageYOffset - yOffset)
      style.bottom = 'auto'

      const xOffset = this.popupCoords.width + 8
      if (positions.indexOf('right') >= 0) {
        style.right -= xOffset
      } else {
        style.left -= xOffset
      }
    }

    if (horizontalOffset) {
      if (typeof style.right === 'number') {
        style.right -= horizontalOffset
      } else {
        style.left -= horizontalOffset
      }
    }

    if (verticalOffset) {
      if (typeof style.top === 'number') {
        style.top += verticalOffset
      } else {
        style.bottom += verticalOffset
      }
    }

    return style
  }

  getPortalProps() {
    const portalProps = super.getPortalProps()
    // NOTE (jscheel): We have to add this class because semantic has some
    // overly-zealous selectors, especially inside of its grid elements.
    portalProps.className = styles.portal
    return portalProps
  }
}

export default ImprovedPopup
