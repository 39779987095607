import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useFormState } from 'react-hook-form'
import MessageCard from '@groovehq/internal-design-system/lib/components/MessageCard/MessageCard'
import Modal from '@groovehq/internal-design-system/lib/components/Modal/Modal'
import {
  text,
  paragraph,
} from '@groovehq/internal-design-system/lib/styles/elements'
import { selectIsInInbox } from 'selectors/location'
import {
  useChannelDetailsConfig,
  useRebuildLeftNavMenu,
  useRedirectToChannel,
} from 'ducks/channels/hooks'
import { isBridgeChannelType } from 'ducks/channels/channelTypes'
import { CHANNEL_TYPES } from '../../Channels.data'
import { styles } from '../styles'

const ActionsWithMessage = ({
  formId,
  channelType,
  onClose,
  isInstalling,
  errorCode,
  control,
  firstStep,
  isOAuthSccessful,
  channelId,
  onExit,
  completed,
}) => {
  const isInInbox = useSelector(selectIsInInbox)
  const { name, icon } = CHANNEL_TYPES[channelType]
  const { oAuthErrors, oAuthSuccess } = useChannelDetailsConfig(channelType)
  const { rebuildMenuAndExit } = useRebuildLeftNavMenu({
    channelId, // channel id after mailbox is created
    onExit,
  })
  const { rebuildExitAndRedirect } = useRedirectToChannel({
    channelId, // channel id after mailbox is created
    onExit,
  })

  const { isValid } = useFormState({ control })

  const handleBack = useCallback(
    () => {
      onClose({ ignoreStack: false })
    },
    [onClose]
  )

  const oauthErrorMessage = useMemo(
    () => {
      if (!errorCode) return null

      return oAuthErrors[errorCode] || oAuthErrors.DEFAULT
    },
    [errorCode, oAuthErrors]
  )

  const isAuthenticate = !isBridgeChannelType(channelType)

  const saveBtnText = (
    <>
      {isAuthenticate && (
        <>
          <span css={[styles.btnIcon, styles.btn]} className="grui mr-4">
            {icon}
          </span>
          Connect your {name} account
        </>
      )}
      {!isAuthenticate && 'Next step'}
    </>
  )

  return (
    <>
      {!isOAuthSccessful && (
        <Modal.Button
          type="info"
          htmlType="submit"
          data-test-id={`authenticate-${channelType}-button`}
          disabled={!isValid || isInstalling}
          form={formId}
          css={styles.oauthBtn}
          backdropTransparency="light"
        >
          {saveBtnText}
        </Modal.Button>
      )}

      {oauthErrorMessage && (
        <MessageCard
          type="negative"
          closeable
          className="grui my-10 flex-center"
          css={styles.messageCard}
          isIconHidden
        >
          <p css={[paragraph.styles.preflight, text.styles.fontMedium]}>
            Authentication error
          </p>
          <p css={paragraph.styles.preflight} className="grui mt-4">
            {oauthErrorMessage}
          </p>
        </MessageCard>
      )}
      {isOAuthSccessful && (
        <MessageCard
          type="positive"
          className="grui my-10 flex-center"
          css={[
            paragraph.styles.preflight,
            text.styles.fontMedium,
            styles.messageCard,
          ]}
        >
          {oAuthSuccess}
        </MessageCard>
      )}
      {!isOAuthSccessful &&
        !firstStep && (
          <Modal.Button type="link" className="grui mt-11" onClick={handleBack}>
            Back
          </Modal.Button>
        )}
      {!isInInbox &&
        channelId &&
        completed && (
          <>
            <Modal.Button
              type="info"
              className="grui mt-5"
              onClick={rebuildExitAndRedirect}
              data-test-id="finish-and-redirect-inbox"
            >
              Take me to my {`${app.t('mailbox')}`}.
            </Modal.Button>
            <Modal.Button
              type="tertiary"
              className="grui mt-5"
              onClick={rebuildMenuAndExit}
            >
              Close
            </Modal.Button>
          </>
        )}
    </>
  )
}

export default ActionsWithMessage
