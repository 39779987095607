import styled from '@emotion/styled'
import { connect } from 'react-redux'
import Link from 'redux-first-router-link'

import ValidatedField from 'shared/components/ui/ValidatedField'
import { Label } from 'shared/ui'
import { SETTINGS_INTEGRATIONS_PROVIDER_PAGE } from 'subapps/settings/types'
import { Input, Icon, Message } from 'util/ui'

import { selectIntegrationCredentialsForProvider } from 'ducks/integrations/selectors'

import { SettingRow, Tabs } from '../../shared/SettingsModal'

const PasswordHint = styled('p')`
  .sui & {
    margin-top: 5px;
  }
`
const FlexMessage = styled(Message)`
  .sui .ui.form &.warning.message {
    display: flex;
  }
`
const FloatedLink = styled(Link)`
  float: right;
`
export default connect(state => ({
  credentials: selectIntegrationCredentialsForProvider(state, {
    provider: 'JIRA_SERVER',
  }),
}))(AuthTab)

const ATLASSIAN_URL_REGEX = /^https?:\/\/[^/]*atlassian.((net)|(com))/
const jiraCloudPageAction = {
  type: SETTINGS_INTEGRATIONS_PROVIDER_PAGE,
  payload: { provider: 'jira_cloud' },
}
function AuthTab({
  active,
  credentials: { url, username } = {},
  formFields,
  onChange,
}) {
  const urlValue =
    formFields['auth.url'] === undefined ? url || '' : formFields['auth.url']
  const usernameValue =
    formFields['auth.username'] === undefined
      ? username || ''
      : formFields['auth.username']
  const cloudUrlDetected = urlValue.match(ATLASSIAN_URL_REGEX)

  return (
    <Tabs.Content active={active}>
      <p>Add your authentication details for your Jira Server instance.</p>
      <SettingRow>
        <Label as="div">Jira Server URL</Label>
        <ValidatedField
          fluid
          name="auth.url"
          onChange={onChange}
          validatedFieldComponent={Input}
          value={urlValue}
        />
        {cloudUrlDetected && (
          <FlexMessage icon warning>
            <Icon name="warning sign" />
            <Message.Content>
              <Message.Header>Jira Cloud URL Detected</Message.Header>
              <p>
                It looks like you are trying to connect to a{' '}
                <strong>Jira Cloud</strong> instance. This isn&apos;t possible
                with the <strong>Jira Server</strong> integration. Consider
                using the <Link to={jiraCloudPageAction}>Jira Cloud</Link>{' '}
                integration instead.
              </p>
              <FloatedLink to={jiraCloudPageAction} primary>
                Install Jira Cloud Integration
              </FloatedLink>
            </Message.Content>
          </FlexMessage>
        )}
      </SettingRow>
      <SettingRow>
        <Label as="div">Username</Label>
        <ValidatedField
          fluid
          name="auth.username"
          onChange={onChange}
          validatedFieldComponent={Input}
          value={usernameValue}
        />
      </SettingRow>
      <SettingRow>
        <Label as="div">Password</Label>
        <ValidatedField
          fluid
          name="auth.access_token"
          onChange={onChange}
          type="password"
          validatedFieldComponent={Input}
          value={formFields['auth.access_token'] || ''}
        />
        <PasswordHint>
          Your password is encypted and stored on our server. It is at no time
          delivered to the Groove client, so we are unable to display it here.
          Even if you have set a password the input field will appear empty.
        </PasswordHint>
      </SettingRow>
    </Tabs.Content>
  )
}
