import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { string, arrayOf, oneOf } from 'prop-types'
import {
  text,
  paragraph,
} from '@groovehq/internal-design-system/lib/styles/elements'
import { selectCurrentCannedReplyCategoriesById } from 'ducks/cannedReplies/selectors'
import { selectSearchByQueryId } from 'ducks/searches/selectors'
import { selectRawAgentsById } from 'selectors/agents/base'
import MessageCard from '@groovehq/internal-design-system/lib/components/MessageCard/MessageCard'

const CannedReplyCategoryDeleteContent = ({ ids, queryId, deleteMode }) => {
  const entitiesById = useSelector(selectCurrentCannedReplyCategoriesById)
  const search = useSelector(state => selectSearchByQueryId(state, queryId))
  const agentsById = useSelector(selectRawAgentsById)
  const { totalCount: searchTotal } = search

  const entitiesForIds = useMemo(
    () => {
      return ids.map(id => entitiesById[id]).filter(e => !!e)
    },
    [ids, entitiesById]
  )
  const isIdDelete = deleteMode === 'ids'

  // There will always be atleast 1 id. If there isnt then it means we're currently
  // loading the entities after a page refresh
  if (entitiesForIds.length === 0) {
    return <span css={text.styles.fontMedium}>Loading...</span>
  }

  // We display a slightly differnt message for single deletes
  if (isIdDelete && ids.length === 1) {
    const total = entitiesForIds[0].total
    const { name: agentName } = agentsById[entitiesForIds[0].creator_id] || {
      name: `an unknown ${app.t('agent')}`,
    }
    return (
      <>
        <p css={[paragraph.styles.preflight, text.styles.textNormal]}>
          You are about to delete “
          <span css={text.styles.fontMedium}>{entitiesForIds[0].name}</span>”
          which was created by{' '}
          <span css={text.styles.fontMedium}>{agentName}</span> and has{' '}
          {total === 0 && <>no {app.t('canned_replies')}.</>}
          <span css={text.styles.fontMedium}>
            {app.t('n_canned_replies', { count: total })}.
          </span>
        </p>
        <p css={paragraph.styles.preflight} className="grui mt-14 mb-8">
          Are you sure you want to proceed with this action?
        </p>
        <MessageCard type="negative">
          Warning! Deleting this category will delete all the{' '}
          {app.t('canned_replies')} it contains.
        </MessageCard>
      </>
    )
  }
  const totalToBeRemoved = isIdDelete ? ids.length : searchTotal
  const additional = totalToBeRemoved - entitiesForIds.length

  return (
    <div css={text.styles.textNormal}>
      You are about to delete categories:
      {entitiesForIds.map(row => (
        <p key={row.id} css={paragraph.styles.preflight}>
          <span css={text.styles.fontMedium}>
            <>“</>
            {row.name}
            <>”</>
          </span>
        </p>
      ))}
      {additional > 0 && <p>and {additional} more items.</p>}
    </div>
  )
}

CannedReplyCategoryDeleteContent.propTypes = {
  deleteMode: oneOf(['ids', 'query']).isRequired,
  ids: arrayOf(string).isRequired,
  queryId: string,
}

CannedReplyCategoryDeleteContent.defaultProps = {
  queryId: null,
}

export default CannedReplyCategoryDeleteContent
