import graphql from 'api/graphql'
import * as types from 'subapps/kb/actions'
import { oauthTokenSelector } from 'selectors/app'
import { selectCurrentKnowledgeBaseId } from 'subapps/kb/selectors/knowledge_bases'

export function doFetchCategoriesTitles() {
  return (dispatch, getState) => {
    const state = getState()
    const token = oauthTokenSelector(state)
    const knowledgeBaseId = selectCurrentKnowledgeBaseId(state)

    dispatch({
      type: types.FETCH_CATEGORIES_TITLES_REQUEST,
    })

    const query = `
      query CategoriesQuery($knowledgeBaseId: ID!) {
        categories(
          knowledgeBaseId: $knowledgeBaseId,
          keyword: "*",
          unpaginated: true,
          order: "title asc"
        ){
          data {
            id
            title
          }
        }
      }
    `

    const variables = {
      knowledgeBaseId,
    }

    return graphql(token, query, variables)
      .then(res => {
        const data = res.json.data
        dispatch({
          type: types.FETCH_CATEGORIES_TITLES_SUCCESS,
          data: data.categories.data,
        })
      })
      .catch(() => {
        dispatch({
          type: types.FETCH_CATEGORIES_TITLES_FAILURE,
        })
      })
  }
}
