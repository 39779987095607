import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import Drawer from '@groovehq/internal-design-system/lib/components/Drawer/UnmanagedDrawer'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import Tabbed from 'subapps/settings/components/drawers/Tabbed'
import { useFetchIntegrationProvider } from 'ducks/integrations/hooks'
import { selectPendingIntegrationInstallStateProviderValueById } from 'ducks/integrations/selectors/settings'
import { doUpdateStoreSettings } from 'ducks/integrations/operations/settings'
import {
  CHATS_PREFIX,
  MAILBOXES_PREFIX,
} from 'ducks/integrations/utils/integrationSettingPrefix'
import { omit } from 'util/objects'
import { getType, getRawId } from 'util/globalId'
import { useRhfDirtyHold, useSimpleDrawerConfirmHolds } from 'util/dirtyHolds'
import AdvancedConfigure from 'subapps/settings/components/drawers/integrations/common/StoreUpdate/Advanced'
import Configure from './Configure'

const tabsData = [
  {
    key: 'configure',
    tabName: 'Configure',
    component: Configure,
  },
  {
    key: 'advanced',
    tabName: 'Advanced',
    component: AdvancedConfigure,
  },
]

export default function RechargeUpdateStore({
  drawerResourceId: integrationId,
  drawerTabId = tabsData[0].key,
  open,
  onClose,
  onExit,
  drawerId,
  drawer,
  updateDrawerParameter,
  ...rest
}) {
  const dispatch = useDispatch()

  const { isLoading, hasError, isMissing } = useFetchIntegrationProvider(
    'RECHARGE'
  )

  const store = useSelector(state =>
    selectPendingIntegrationInstallStateProviderValueById(state, integrationId)
  )

  const isNoResultFound = !store || isMissing

  const useFormMethods = useForm({
    mode: 'all',
  })

  const {
    handleSubmit,
    formState: { isValid, isDirty },
    control,
  } = useFormMethods

  const { releaseHold, holdKey } = useRhfDirtyHold(drawerId, control)

  const onSubmit = useCallback(
    data => {
      const channels = Object.entries(data.channels).reduce(
        (obj, [key, value]) => {
          if (getType(key) === 'Channel') {
            // eslint-disable-next-line no-param-reassign
            obj[`${MAILBOXES_PREFIX}${getRawId(key)}`] = value
          } else if (getType(key) === 'Widget') {
            // eslint-disable-next-line no-param-reassign
            obj[`${CHATS_PREFIX}${getRawId(key)}`] = value
          }
          return obj
        },
        {}
      )

      const entity = {
        ...store,
        settings: {
          settings: {
            ...omit(['channels'], data),
            ...channels,
          },
        },
      }

      dispatch(doUpdateStoreSettings(store.id, entity))
      releaseHold()
      onClose()
    },
    [store, dispatch, onClose, releaseHold]
  )

  const DrawerForm = useCallback(
    subProps => <form onSubmit={handleSubmit(onSubmit)} {...subProps} />,
    [handleSubmit, onSubmit]
  )

  const handleTabKeyChange = useCallback(
    key => {
      if (key === drawerTabId) return
      updateDrawerParameter('drawerTabId', key)
    },
    [drawerTabId, updateDrawerParameter]
  )

  const [handleOnClose, handleOnExit] = useSimpleDrawerConfirmHolds(
    holdKey,
    onClose,
    onExit
  )

  return (
    <Drawer
      {...rest}
      onClose={handleOnExit}
      size="wide"
      title="Configure store"
      open={open}
      isLoading={isLoading}
      isError={hasError}
      isNoResultFound={isNoResultFound}
      footer={
        <ModalBtns
          tertiaryBtnText="Cancel"
          onClickTertiaryBtn={handleOnClose}
          saveBtnDisabled={
            !isValid || !isDirty || isNoResultFound || isLoading || hasError
          }
          saveBtnText="Save"
          saveBtnHtmlType="submit"
        />
      }
      container={DrawerForm}
    >
      <Tabbed
        tabsData={tabsData}
        activeTabKey={drawerTabId}
        onTabKeyChange={handleTabKeyChange}
        store={store}
        {...useFormMethods}
      />
    </Drawer>
  )
}
