import {
  COLLABORATOR_CONVERSATION,
  CUSTOMER_CONVERSATION,
} from 'constants/conversation_types'
import DOMPurify from 'dompurify'

import { all, reverseFindIndex, findFirst, isEmpty, last } from 'util/arrays'
import { diff } from 'util/date'
import { getValueByPath } from 'util/objects'
import { embeddableFileType } from 'util/attachments'
import { unsafeStripTags } from 'util/strings'

// utility function for determining whether a given changeset contains a
// message (ie. note or reply)
export const hasMessageAction = changeset =>
  changeset && changeset.actions.find(isMessage)

export const hasMultipleMessageActions = changesets => {
  if (!changesets || changesets.length <= 1) return false

  const value = changesets.reduce((result, changeset) => {
    if (result === true) return true
    if (hasMessageAction(changeset)) {
      if (result === 1) return true
      return 1
    }
    return result
  }, 0)

  return value === true || false
}

export function containsNote(changeset) {
  if (!changeset || isEmpty(changeset.actions)) return false

  return changeset.actions.some(isNote)
}

const isMessageChange = changeType => changeType === 'Message'

// change type is sometimes nil in db for comments
const isMessageSubType = messageType =>
  messageType === 'Comment' ||
  messageType === 'BounceComment' ||
  messageType === 'email' ||
  messageType === null

export function isMessage(action) {
  if (isMessageChange(action.change_type)) return true
  if (action.change_type === 'CollapsedChangeset') {
    return Boolean(
      findFirst(
        getValueByPath('change.subchangeset_types', action),
        isMessageSubType
      )
    )
  }
  return false
}

export function undoSecondsRemaining(messageAction) {
  const {
    change: { deliverBy },
  } = messageAction
  if (!deliverBy) return null
  return diff('seconds', new Date(), deliverBy)
}

export function isReply(action) {
  return isMessage(action) && !isNote(action) && !isForward(action)
}

export function isNote(action) {
  return isMessage(action) && (action.change.note || action.change.is_note)
}

function isCollapsedForward(action) {
  return action.change_type === 'CollapsedChangeset' && action.change.is_forward
}

export function isForward(action) {
  return (
    isMessage(action) && (action.change.forward || isCollapsedForward(action))
  )
}

function noteActions(actions) {
  return actions.filter(isNote)
}

export function lastNoteAction(actions) {
  return last(noteActions(actions))
}

export function isCollapsable(action) {
  if (action.change_type === 'Separator') return true
  if (action.change_type === 'CollapsedChangeset') return true
  if (action.change_type === 'Ticket::Merger') return true
  return isReply(action) || isNote(action) || isForward(action)
}

export const isStateChange = action => action && action.change_type === 'State'

export const isStateChangeOpen = action =>
  isStateChange(action) && action.change.state === 'opened'

export const isStateChangeClosed = action =>
  isStateChange(action) && action.change.state === 'closed'

export const isSnooze = action =>
  action && action.change_type === 'Snooze::State'

export const isUnsnooze = action =>
  action && action.change_type === 'Unsnooze::State'

export const isLabel = action => action && action.change_type === 'Label'

export const isTitle = action => action && action.change_type === 'Title'

export const isUserChange = action => action && action.change_type === 'Enduser'

export const isIncomingEmail = change =>
  ['email', 'BounceComment'].indexOf(change.message_type) !== -1

export const isTwitterComment = ({ message_type: type }) =>
  type === 'TwitterComment' || type === 'twitter'

const isConversationType = (change, conversationType) =>
  change.conversationType === conversationType

export const isWithCollaborator = change =>
  isConversationType(change, COLLABORATOR_CONVERSATION)

export const isWithCustomer = change =>
  isConversationType(change, CUSTOMER_CONVERSATION)

const attachmentsByCid = attachments => {
  const byCid = {}
  if (attachments) {
    attachments.forEach(attachment => {
      const contentId = attachment.content_id
      if (contentId) byCid[contentId] = attachment
    })
  }
  return byCid
}

// Iterates parts and replaces CID attachments with the correct URL
export const embedAttachments = (parts, attachments) => {
  if (attachments === undefined) return parts
  // this is so tests don't crash
  if (typeof document === 'undefined' || !document.createElement) return parts

  const container = document.createElement('div')
  container.id = 'image-container'
  const newParts = []
  const filteredAttachments = attachmentsByCid(attachments)
  parts.forEach(part => {
    const pure = DOMPurify.sanitize(part.text, {})
    container.innerHTML = pure
    const images = container.getElementsByTagName('img')
    if (images && images.length > 0) {
      Array.from(images).forEach(elem => {
        const match = elem.src.match(/^cid:[^"]*/)
        if (match && match.length > 0) {
          const cid = match[0].split(':')[1]
          const attachment = filteredAttachments[cid]
          if (attachment) elem.src = attachment.url // eslint-disable-line no-param-reassign
        }
      })
      const newPart = { ...part, text: container.innerHTML }
      container.remove()
      newParts.push(newPart)
    } else {
      newParts.push({ ...part })
    }
  })

  return newParts
}

export const nonEmbeddedAttachments = change => {
  if (!change || !change.attachments) return []
  if (!change.parsedBody) return []
  if (!change.attachments) return []

  const text = (change.parsedBody || []).map(part => part.text).join('') || ''

  return (change.attachments || []).filter(
    attachment =>
      !text.match(`${attachment.url}`) ||
      !embeddableFileType(attachment.file_type)
  )
}

export const isAgentResponse = change => !!change.agent_response

export const getLatestReply = actions => {
  if (!actions || isEmpty(actions)) return undefined

  const latestReplyIndex = reverseFindIndex(actions, isReply)

  if (latestReplyIndex === -1) return undefined

  return actions[latestReplyIndex]
}

export const getLatestUserChange = actions => {
  if (!actions || isEmpty(actions)) return null
  const latestUserChangeIndex = reverseFindIndex(actions, isUserChange)
  return latestUserChangeIndex > 0 ? actions[latestUserChangeIndex] : null
}

export const isMerged = changeset => {
  if (!changeset || isEmpty(changeset.actions)) return false

  if (changeset.isFromMerge) return true
  return changeset.actions[0].change_type === 'Ticket::Merger'
}

export const isFirstMessageInAWidgetTicket = (ticket, groupIndex) =>
  ticket.type === 'Widget' && groupIndex === 0

export const fullParsedBodyText = change => {
  const { parsedBody } = change
  return parsedBody && parsedBody.length > 0
    ? parsedBody.map(part => part.text).join()
    : change.body
}

// Optimistic payload formats
const isUnsnoozing = ({ changesetId = '' }) => changesetId.match(/-unsnooze-/)
const isSnoozing = ({ changesetId = '' }) => changesetId.match(/-snooze-/)
export const isUnsnoozingAll = tickets => all(isUnsnoozing, tickets)
export const isSnoozingAll = tickets => all(isSnoozing, tickets)

export const parseBody = body => {
  const regex = /(<blockquote[\s\S]+)/
  const parts = []
  if (!body) return parts

  const matches = body.match(regex)
  if (matches) {
    body.split(regex).forEach(m => {
      // Protect against blockquotes containing markup but no text
      if (unsafeStripTags(m).trim() !== '') {
        parts.push({
          type: matches.indexOf(m) !== -1 ? 'quoted' : 'text',
          text: m,
        })
      }
    })
  } else {
    parts.push({ type: 'text', text: body })
  }
  return parts
}
