import Spinner from '@groovehq/internal-design-system/lib/components/Spinner/Spinner'

const Loader = () => {
  return (
    <div className="grui py-20">
      <Spinner />
    </div>
  )
}

export default Loader
