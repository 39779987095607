import { useCallback, useEffect } from 'react'
import { useFormState } from 'react-hook-form'
import debug from 'util/debug'
import storage from 'util/storage'

const DEFAULT_MESSAGE = 'Changes you made may not be saved.'

const holds = new Map()

function retainHold(
  key,
  cb = () => {
    return DEFAULT_MESSAGE
  }
) {
  debug(`✅  RETAIN HOLD: ${key}`)
  holds.set(key, cb)
}

function releaseHold(key) {
  debug(`❌  RELEASE HOLD: ${key}`)
  holds.delete(key)
}

function flushHolds() {
  debug(`🚽  FLUSH HOLDS`)
  holds.clear()
}

function getHoldMessage(keyOrKeys = null) {
  let holdMessage
  let filteredCheck = false
  const keys = new Set()

  if (Array.isArray(keyOrKeys)) {
    if (keyOrKeys.length > 0) {
      keyOrKeys.forEach(k => keys.add(k))
      filteredCheck = true
    }
  } else if (keyOrKeys) {
    keys.add(keyOrKeys)
    filteredCheck = true
  }

  Array.from(holds)
    .reverse()
    .some(([key, cb]) => {
      if (filteredCheck && !keys.has(key)) return null
      holdMessage = cb()
      return holdMessage
    })

  return holdMessage
}

function hasHolds(keyOrKeys = null) {
  return !!getHoldMessage(keyOrKeys)
}

function confirmHolds(keyOrKeys = null) {
  const holdMessage = getHoldMessage(keyOrKeys)
  if (holdMessage) {
    // eslint-disable-next-line no-alert
    return global.confirm(holdMessage)
  }

  return true
}

function useHold(key) {
  const curriedRetainHold = useCallback(
    (...args) => {
      retainHold(key, ...args)
    },
    [key]
  )

  const curriedReleaseHold = useCallback(
    (...args) => {
      releaseHold(key, ...args)
    },
    [key]
  )

  const curriedHasHolds = useCallback(
    (...args) => {
      hasHolds(key, ...args)
    },
    [key]
  )

  const curriedConfirmHolds = useCallback(
    (...args) => {
      confirmHolds(key, ...args)
    },
    [key]
  )

  return {
    retainHold: curriedRetainHold,
    releaseHold: curriedReleaseHold,
    hasHold: curriedHasHolds,
    confirmHold: curriedConfirmHolds,
    hasHolds,
    flushHolds,
    confirmHolds,
    holdKey: key,
  }
}

function useRhfDirtyHold(key, control, msg = DEFAULT_MESSAGE) {
  const { isDirty } = useFormState({ control })

  const curriedRetainHold = useCallback(
    (...args) => {
      retainHold(key, ...args)
    },
    [key]
  )

  const curriedReleaseHold = useCallback(
    (...args) => {
      releaseHold(key, ...args)
    },
    [key]
  )

  const curriedHasHolds = useCallback(
    (...args) => {
      hasHolds(key, ...args)
    },
    [key]
  )

  const curriedConfirmHolds = useCallback(
    (...args) => {
      confirmHolds(key, ...args)
    },
    [key]
  )

  useEffect(
    () => {
      if (isDirty) {
        retainHold(key, () => {
          return isDirty ? msg : null
        })
      } else {
        releaseHold(key)
      }
    },
    [key, isDirty, msg]
  )

  return {
    retainHold: curriedRetainHold,
    releaseHold: curriedReleaseHold,
    hasHold: curriedHasHolds,
    confirmHold: curriedConfirmHolds,
    hasHolds,
    flushHolds,
    confirmHolds,
    holdKey: key,
  }
}

function useConfirmHoldsCallback(keyOrKeys, callback, deps = []) {
  return useCallback(
    (...args) => {
      if (confirmHolds(keyOrKeys)) {
        if (Array.isArray(keyOrKeys)) {
          keyOrKeys.forEach(k => releaseHold(k))
        } else if (keyOrKeys) {
          releaseHold(keyOrKeys)
        } else {
          flushHolds()
        }
        callback(...args)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [keyOrKeys, ...deps]
  )
}

function useSimpleDrawerConfirmHolds(holdKey, onClose, onExit) {
  const handleOnClose = useConfirmHoldsCallback(holdKey, onClose, [onClose])
  const handleOnExit = useConfirmHoldsCallback(null, onExit, [onExit])

  return [onClose && handleOnClose, onExit && handleOnExit]
}

const displayConfirmLeave = (_, callback) => {
  const leave = confirmHolds()
  if (leave) {
    flushHolds()
  }

  callback(leave)
}

function onBeforeUnload(e) {
  // TODO (jscheel): Also should look at saving states from entities to determine
  // if there is still a request in flight that we need to warn about.

  // NOTE (jscheel): We cannot control the message displayed in most browsers
  // in beforeunload, so we don't event bother trying. The assumption is the
  // browser will always control the message in this case. We also don't want to
  // prevent unloading if the reload is coming from HMR, so we exit early if
  // that is the case. Finally, we have to set the event's retun value to an
  // empty string to make Chrome happy.

  if (module?.hot && module.hot.status() === 'abort') {
    return
  }

  if (hasHolds() && storage.get('testDisableUnload') !== true) {
    // eslint-disable-next-line no-param-reassign
    e.returnValue = ''
    e.preventDefault()
  }
}

if (global?.addEventListener) {
  global.addEventListener('beforeunload', onBeforeUnload)
}

export {
  retainHold,
  releaseHold,
  hasHolds,
  confirmHolds,
  flushHolds,
  useHold,
  useRhfDirtyHold,
  useConfirmHoldsCallback,
  useSimpleDrawerConfirmHolds,
  displayConfirmLeave,
}
