import Sidebar from 'shared/components/ui/Sidebar'
import { Flex, withFormFields } from 'shared/ui'
import { Menu, Checkbox, Button } from 'util/ui'
import Accordion from 'util/ui/Accordion'
import React from 'react'

import IntegrationRemoveModal from 'subapps/settings/components/Integrations/shared/IntegrationRemoveModal'

import {
  CancelButton,
  StyledForm,
  SettingRow,
  Tab,
  TabContent,
  TabHeader,
  TabLink,
  UninstallButton,
  AccordionContainer,
} from './styled'
import Description from './Description'
import PropertySection from './PropertySection'

function generateTitle(title, prefix, properties, formFields) {
  if (!properties) return null
  if (!formFields) return null
  let counter = 0
  let total = 0
  const keys = Object.keys(properties)
  keys.forEach(key => {
    total += 1
    const subkey = [prefix, key].join('.')
    if (formFields[subkey]) counter += 1
  })
  return (
    <span>
      <span className="accordion_title">{title} </span>
      <span className="accordion_subtitle">
        {counter} out of {total} displayed
      </span>
    </span>
  )
}

class SettingsModal extends React.PureComponent {
  // eslint-disable-next-line react/sort-comp
  CONTACT_PRIORITIZED_FIELDS = [
    'firstname',
    'lastname',
    'email',
    'phone',
    'website',
    'jobtitle',
  ]
  COMPANY_PRIORITIZED_FIELDS = ['name']
  DEAL_PRIORITIZED_FIELDS = ['dealname']

  state = {
    currentTab: 'main',
  }

  componentDidMount() {
    this.props.doFetchContactProperties()
    this.props.doFetchCompanyProperties()
    this.props.doFetchDealProperties()
  }

  changeTab = newTab => {
    this.setState({ currentTab: newTab })
  }

  changeTabToMain = () => this.changeTab('main')
  changeTabToDescription = () => this.changeTab('description')
  changeTabToSidebar = () => this.changeTab('sidebar')

  handleChange = (_, attrs) => {
    const { name, value, type, checked } = attrs
    let val = value
    if (type === 'checkbox') {
      val = checked
    }
    return this.props.onChange(name, val, null, { validate: true })
  }

  doSubmitFormAndClose = () => {
    const {
      doCloseModal,
      doUpdateIntegrationProviderSettings,
      formFields,
    } = this.props

    doUpdateIntegrationProviderSettings('hubspot', formFields)
    doCloseModal()
  }

  render() {
    const {
      id,
      doCloseModal,
      doRemoveIntegration,
      formFields,
      open,
      mailboxes,
      contactProperties,
      dealProperties,
      companyProperties,
    } = this.props
    const { currentTab } = this.state
    return (
      <Sidebar
        headerChildren="Configure HubSpot"
        footerChildren={
          <div>
            <Button fluid onClick={this.doSubmitFormAndClose} primary>
              Save changes
            </Button>
            <Flex>
              <IntegrationRemoveModal
                id={id}
                name="HubSpot"
                onConfirm={doRemoveIntegration}
                trigger={
                  // last-child class since the flex means it is a last child from top to bottom
                  <UninstallButton className="last-child" negative>
                    Uninstall App
                  </UninstallButton>
                }
              />
              <CancelButton basic onClick={doCloseModal}>
                Cancel
              </CancelButton>
            </Flex>
          </div>
        }
        narrow
        onClose={doCloseModal}
        open={open}
      >
        <StyledForm>
          <TabHeader>
            <TabLink
              onClick={this.changeTabToDescription}
              className={currentTab === 'description' ? 'active' : null}
            >
              Description
            </TabLink>
            <TabLink
              onClick={this.changeTabToMain}
              className={currentTab === 'main' ? 'active' : null}
            >
              {app.t('Mailboxes')}
            </TabLink>
            <TabLink
              onClick={this.changeTabToSidebar}
              className={currentTab === 'sidebar' ? 'active' : null}
            >
              Sidebar
            </TabLink>
          </TabHeader>
          <Tab visible={currentTab === 'description'}>
            <TabContent className="description">
              <Description />
            </TabContent>
          </Tab>
          <Tab visible={currentTab === 'main'}>
            <TabContent>
              <p>
                Choose which {app.t('Mailboxes')} you want to display this
                integration in.
              </p>
              {mailboxes.map(mailbox => {
                const key = `mailboxes.${mailbox.id}`
                return (
                  <SettingRow key={key}>
                    <Checkbox
                      checked={formFields[key]}
                      name={key}
                      onChange={this.handleChange}
                    />{' '}
                    <span>{mailbox.name}</span>
                  </SettingRow>
                )
              })}
            </TabContent>
          </Tab>
          <Tab visible={currentTab === 'sidebar'}>
            <TabContent>
              <p>
                Choose which fields you want to display in the sidebar of
                HubSpot contacts
              </p>
              <AccordionContainer>
                <Accordion.Managed fluid styled vertical as={Menu}>
                  <Accordion.Section
                    name="contact"
                    title={generateTitle(
                      'Contact',
                      'showSidebarField.Contact',
                      contactProperties,
                      formFields
                    )}
                  >
                    <PropertySection
                      label="Contact"
                      prefix="showSidebarField.Contact"
                      formFields={formFields}
                      properties={contactProperties}
                      onChange={this.handleChange}
                      prioritizedFields={this.CONTACT_PRIORITIZED_FIELDS}
                    />
                  </Accordion.Section>
                  <Accordion.Section
                    name="company"
                    title={generateTitle(
                      'Company',
                      'showSidebarField.Company',
                      companyProperties,
                      formFields
                    )}
                  >
                    <PropertySection
                      label="Company"
                      prefix="showSidebarField.Company"
                      formFields={formFields}
                      properties={companyProperties}
                      onChange={this.handleChange}
                      prioritizedFields={this.COMPANY_PRIORITIZED_FIELDS}
                    />
                  </Accordion.Section>
                  <Accordion.Section
                    name="lists"
                    title={generateTitle(
                      'List Membership',
                      'showSidebarField',
                      { ListMembership: true },
                      formFields
                    )}
                  >
                    <SettingRow>
                      <Checkbox
                        checked={formFields['showSidebarField.ListMembership']}
                        name={'showSidebarField.ListMembership'}
                        onChange={this.handleChange}
                      />{' '}
                      <span>List memberships</span>
                    </SettingRow>
                  </Accordion.Section>
                  <Accordion.Section
                    name="deals"
                    title={generateTitle(
                      'Deal',
                      'showSidebarField.Deal',
                      dealProperties,
                      formFields
                    )}
                  >
                    <PropertySection
                      label="Deal"
                      prefix="showSidebarField.Deal"
                      formFields={formFields}
                      properties={dealProperties}
                      onChange={this.handleChange}
                      prioritizedFields={this.DEAL_PRIORITIZED_FIELDS}
                    />
                  </Accordion.Section>
                </Accordion.Managed>
              </AccordionContainer>
            </TabContent>
          </Tab>
        </StyledForm>
      </Sidebar>
    )
  }
}

export default withFormFields(SettingsModal)
