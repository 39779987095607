import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  text,
  heading,
} from '@groovehq/internal-design-system/lib/styles/elements'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import { selectWidgetById } from 'ducks/widgets/selectors'
import { useDrawer } from 'ducks/drawers/hooks'
import { buildDrawerQueryParam } from 'ducks/drawers/util'
import { AdminAccessDrawer } from 'subapps/settings/components/drawers/NoAccess'
import {
  DRAWER_TYPE_LIVE_CHAT_CONFIRM_DELETE,
  DRAWER_TYPE_WIDGET_DELETE,
} from 'ducks/drawers/types'
import { isBridgeChannelType } from 'ducks/channels/channelTypes'
import { styles } from './styles'

const LiveChatDelete = ({
  open,
  drawerResourceId,
  onClose,
  onExit,
  onRemove,
}) => {
  const widget = useSelector(state => selectWidgetById(state, drawerResourceId))
  const channelName = widget?.name
  const isBridge = isBridgeChannelType(widget?.kind)
  const {
    drawerId: deleteLiveChatDrawerId,
    openDrawer: openDeleteLiveChatDrawer,
  } = useDrawer({
    type: DRAWER_TYPE_LIVE_CHAT_CONFIRM_DELETE,
    closeIgnoresStack: isBridge,
  })

  const {
    drawerId: deleteWidgetDrawerId,
    openDrawer: openDeleteWidgetDrawer,
  } = useDrawer(DRAWER_TYPE_WIDGET_DELETE)

  const handleRemoveLiveChatDelete = useCallback(
    () => {
      openDeleteLiveChatDrawer(drawerResourceId, {
        query: {
          ...buildDrawerQueryParam(
            deleteLiveChatDrawerId,
            'drawerDeleteMode',
            'id'
          ),
        },
        additionalProps: {
          onRemove,
        },
      })
    },
    [
      onRemove,
      drawerResourceId,
      deleteLiveChatDrawerId,
      openDeleteLiveChatDrawer,
    ]
  )

  const handleOpenWidgetDelete = useCallback(
    () => {
      openDeleteWidgetDrawer(drawerResourceId, {
        query: {
          ...buildDrawerQueryParam(
            deleteWidgetDrawerId,
            'drawerDeleteMode',
            'id'
          ),
        },
        additionalProps: {
          onRemove,
          title: 'Delete Channel',
          children: (
            <>
              <div className="grui mb-11">
                {`You are about to delete your Live Chat channel “${channelName}” and all associated conversations.`}
              </div>
              <div className="grui mb-11">{`You will also be deleting the widget “${channelName}”.`}</div>
            </>
          ),
        },
      })
    },
    [
      onRemove,
      drawerResourceId,
      deleteWidgetDrawerId,
      openDeleteWidgetDrawer,
      channelName,
    ]
  )

  useEffect(
    () => {
      if (isBridge) {
        handleRemoveLiveChatDelete()
      }
    },
    [isBridge, handleRemoveLiveChatDelete]
  )

  if (!widget) return null

  return (
    <AdminAccessDrawer
      title="Delete Channel"
      open={open}
      onClose={onExit}
      footer={
        <ModalBtns onClickTertiaryBtn={onClose} tertiaryBtnText="Cancel" />
      }
    >
      <div className="grui mt-10">
        <h4 css={heading.styles.h4}>What would you like to do</h4>
        <div css={text.styles.textNormal} className="grui mt-5">
          <div className="grui mb-11">
            {`You are about to delete your Live Chat channel “${channelName}” and
          all associated conversations.`}
          </div>
          This action is permanent and cannot be undone.
        </div>
      </div>
      <div className="grui mt-14" css={styles.controls}>
        <Button type="tertiary" onClick={handleRemoveLiveChatDelete}>
          Delete Live Chat channel only
        </Button>
        <Button type="tertiary" value="true" onClick={handleOpenWidgetDelete}>
          Delete Live Chat channel and delete widget
        </Button>
      </div>
    </AdminAccessDrawer>
  )
}

export default LiveChatDelete
