import Dropdown from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import { firstNonUndefined } from 'util/arrays'
import Item from './Item'

const { Menu: DropdownMenu } = Dropdown

export default function Menu({
  data,
  checkProductFeatureLimit,
  tooltipForAdvancedFeature,
}) {
  return (
    <DropdownMenu>
      {data.map((itemData, index) => (
        <Item
          {...itemData}
          key={itemData?.id || itemData?.key || index}
          // Need to pass itemKey prop to DropdownMenu's children for arrow keys navigations
          itemKey={firstNonUndefined([
            itemData?.value,
            itemData?.id,
            itemData?.key,
            itemData?.name,
          ])}
          checkProductFeatureLimit={checkProductFeatureLimit}
          tooltipForAdvancedFeature={tooltipForAdvancedFeature}
        />
      ))}
    </DropdownMenu>
  )
}
