import { combineReducers } from 'core/redux'

import sharedForm from './sharedForm/reducer'
import signup from './v1/signupPage/reducer'
import customerInformation from './v1/customerInformationPage/reducer'
import channelSelection from './v1/channelOverviewPage/reducer'
import addTeamMembers from './v1/addTeamMembersPage/reducer'
import addChannel from './v1/addChannelPage/reducer'
import setupEmail from './v1/setupEmailPage/reducer'
import acceptInvite from './v1/acceptInvitePage/reducer'
import completed from './v1/completedPage/reducer'
import ducksReducers from './ducks'

import * as types from './types'

const rootState = {}

const rootReducer = (state = rootState, action) => {
  switch (action.type) {
    case types.BOOTSTRAP_ONBOARDING_SUCCESS:
    case types.BOOTSTRAP_ONBOARDING_FAILED:
      return { ...state, bootstrapped: true }
    default:
      return state
  }
}

export default combineReducers({
  rootReducer,
  signup,
  customerInformation,
  channelSelection,
  addTeamMembers,
  addChannel,
  setupEmail,
  sharedForm,
  acceptInvite,
  completed,
  ...ducksReducers,
})
