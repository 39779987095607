import React from 'react'
import { DropdownItem } from 'util/ui'

const MergeDropdownOption = React.memo(props => {
  return (
    <DropdownItem
      data-test-id="MoreMenuMergeTicketOption"
      aria-checked="false"
      {...props}
    />
  )
})

export default MergeDropdownOption
