import React, { useCallback, useMemo, useState } from 'react'
import Dropdown from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import Checkbox from '@groovehq/internal-design-system/lib/components/Checkbox/Checkbox'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import { emptyArr } from 'util/arrays'
import {
  Footer,
  styles as multiSelectMenuStyles,
  useMultiSelect,
} from '../MultiSelectMenu'
import MenuWithSearch, {
  useShowHeaderShadow,
  styles as menuWithSearchStyles,
} from '../MenuWithSearch'
import { styles } from './styles'

const ticketSources = [
  {
    id: 'Email',
    name: 'Email',
  },
  {
    id: 'Widget',
    name: 'Widget',
  },
  {
    id: 'API',
    name: 'Api',
  },
  {
    id: 'Facebook',
    name: 'Facebook',
    deprecated: true,
  },
  {
    id: 'Twitter',
    name: 'Twitter',
    deprecated: true,
  },
  {
    id: 'Chat',
    name: 'Chat',
    deprecated: true,
  },
]

const TicketSourcesDropdown = ({
  value: inputValue,
  setValue,
  selectedIds,
  onSelectedIdsChange,
}) => {
  const value = inputValue || emptyArr
  const [menuVisible, setMenuVisible] = useState(false)
  const sources = useMemo(
    () => {
      return ticketSources.filter(
        s => !(s.deprecated === true && !selectedIds.includes(s.id))
      )
    },
    [selectedIds]
  )
  const { handleToggleItem } = useMultiSelect(
    sources,
    onSelectedIdsChange,
    selectedIds
  )
  const { handleScroll, shouldShowHeaderShadow } = useShowHeaderShadow(
    menuVisible
  )

  const handleMenuVisibleChange = useCallback(visible => {
    setMenuVisible(visible)
  }, [])
  const handleSelect = useCallback(
    () => {
      setValue(selectedIds)
      setMenuVisible(false)
    },
    [setValue, selectedIds]
  )

  const handleCancel = useCallback(() => {
    setMenuVisible(false)
  }, [])

  const menu = useMemo(
    () => {
      return (
        <MenuWithSearch onScroll={handleScroll} onApply={handleSelect}>
          <div className="grui pt-3 pb-5">
            {sources.map(({ name, id }) => (
              <MenuWithSearch.Item
                itemKey={id}
                key={id}
                onSelect={handleToggleItem}
              >
                <Checkbox
                  css={multiSelectMenuStyles.checkbox}
                  truncate
                  id={id}
                  checked={selectedIds.some(itemId => itemId === id)}
                >
                  <div className="grui truncate">{name}</div>
                </Checkbox>
              </MenuWithSearch.Item>
            ))}
          </div>
        </MenuWithSearch>
      )
    },
    [selectedIds, handleToggleItem, handleScroll, sources, handleSelect]
  )
  const footer = useMemo(
    () => (
      <Footer
        onSelect={handleSelect}
        onCancel={handleCancel}
        saveBtnTitle={`Select sources`}
      />
    ),
    [handleSelect, handleCancel]
  )

  const drodownTitle = useMemo(
    () => {
      if (
        sources.length &&
        sources.length === value.filter(v => v !== '0').length
      ) {
        return `All sources`
      }

      const filteredValues = value.filter(v => !!v)

      if (filteredValues.length) {
        return filteredValues
          .map(id => sources.find(s => s.id === id).name)
          .join(',')
      }

      return `Select sources`
    },
    [value, sources]
  )

  return (
    <Dropdown
      overlay={menu}
      footer={footer}
      css={[
        text.styles.textSm,
        multiSelectMenuStyles.dropdownContainer,
        shouldShowHeaderShadow && menuWithSearchStyles.showHeaderShadow,
      ]}
      visible={menuVisible}
      onVisibleChange={handleMenuVisibleChange}
      emptyHint=""
      autoHeight
      isNavByArrowsDisabled
    >
      <Dropdown.Button
        css={[multiSelectMenuStyles.dropdownBtn, styles.dropdownBtn]}
        size="small"
      >
        {drodownTitle}
      </Dropdown.Button>
    </Dropdown>
  )
}

export default TicketSourcesDropdown
