import {
  tagV2,
  snoozeV2,
  message,
  teammates,
  box,
  notify,
  conversationType,
  conversationStatus,
  recipient,
  interactionCount,
} from 'assets/icons/groove/v2'
import {
  FEATURE_INBOX_RULES,
  FEATURE_INBOX_RULES_TIER_1,
  FEATURE_INBOX_RULES_TIER_2,
} from 'ducks/billing/featureTypes'
import { INBOX } from 'ducks/billing/productTypes'
import { generateDeprecatedConditions } from './util'

const isOperator = [
  {
    name: 'is',
    value: 'is',
  },
]

const isOrIsNotOperators = [
  ...isOperator,
  {
    name: 'is not',
    value: 'is_not',
  },
]

const containsDoesNotContainOperators = [
  {
    name: 'contains',
    value: 'contains',
  },
  {
    name: 'does not contain',
    value: 'does_not_contain',
  },
]

const containsDoesNotContainWordsOperators = [
  {
    name: 'contains full word/phrase',
    value: 'contains_words',
  },
  {
    name: 'does not contain full word/phrase',
    value: 'does_not_contain_words',
  },
]

const startsWithEndsWithOperators = [
  {
    name: 'starts with',
    value: 'starts_with',
  },
  {
    name: 'ends with',
    value: 'ends_with',
  },
]

const matchesOperator = [
  {
    name: 'matches',
    value: 'matches',
  },
]

const domainOperators = [
  {
    name: 'domain is',
    value: 'domain_is',
  },
  {
    name: 'domain is not',
    value: 'domain_is_not',
  },
]

const textAndPartialTextOperators = [
  ...isOrIsNotOperators,
  ...containsDoesNotContainOperators,
  ...containsDoesNotContainWordsOperators,
  ...startsWithEndsWithOperators,
  ...matchesOperator,
]

const textAndDomainOperators = [
  ...domainOperators,
  ...isOrIsNotOperators,
  ...containsDoesNotContainOperators,
  ...startsWithEndsWithOperators,
  ...matchesOperator,
]

const contactNameOperators = [
  ...isOrIsNotOperators,
  ...containsDoesNotContainOperators,
  ...containsDoesNotContainWordsOperators,
  ...startsWithEndsWithOperators,
]

const ltGtEqOperators = [
  {
    name: 'less than',
    value: 'lt',
  },
  {
    name: 'less than or equal',
    value: 'lteq',
  },
  {
    name: 'greater than',
    value: 'gt',
  },
  {
    name: 'greater than or equal',
    value: 'gteq',
  },
]

const numberOperators = [...isOrIsNotOperators, ...ltGtEqOperators]

export const ruleConditionsParemetersSectionTitles = {
  conversationStatus: {
    title: 'Update conversation status',
    icon: box,
  },
  conversationType: {
    title: 'Conversation type',
    icon: conversationType,
  },
  recipientInfo: {
    title: 'Recipient info',
    icon: recipient,
  },
  tag: {
    title: 'Tagging',
    icon: tagV2,
  },
  assignee: {
    title: 'Assignee',
    icon: teammates,
  },
  interactionCount: {
    title: 'Interaction count',
    icon: interactionCount,
  },
  message: {
    title: 'Message details',
    icon: message,
  },
  time: {
    title: 'Time',
    icon: conversationStatus,
  },
}

// NOTE: make sure to change for_rule_conditions.rb if you change the product/feature for below
// NOTE: make sure to change useAddFeatureUsageIndicator if we changed parameters's structure
export const ruleConditions = {
  parameters: {
    conversationStatus: [
      {
        name: 'Conversation is Open',
        selectedName: 'Conversation is',
        param: 'status',
        operator: 'contains',
        value: '1000,2000',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'Conversation is Closed',
        selectedName: 'Conversation is',
        param: 'status',
        operator: 'contains',
        value: '5000',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'Conversation is Snoozed',
        selectedName: 'Conversation is',
        param: 'status',
        operator: 'contains',
        value: '4000',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'Conversation is in Trash',
        selectedName: 'Conversation is',
        param: 'status',
        operator: 'contains',
        value: '7000',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: `Conversation is in Spam`,
        selectedName: 'Conversation is',
        param: 'status',
        operator: 'contains',
        value: '6000',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'Conversation is Unread',
        selectedName: 'Conversation is',
        param: 'status',
        operator: 'contains',
        value: '1000',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      ...generateDeprecatedConditions('priority', numberOperators),
    ],
    conversationType: [
      {
        name: `Conversation ${app.t('Mailbox')} is one of...`,
        param: 'mailbox',
        operator: 'contains',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: `Conversation ${app.t('Mailbox')} is not one of...`,
        param: 'mailbox',
        operator: 'does_not_contain',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: `Conversation source is one of...`,
        param: 'source',
        operator: 'contains',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: `Conversation source is not one of...`,
        param: 'source',
        operator: 'does_not_contain',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
    ],
    recipientInfo: [
      {
        name: 'From field',
        param: 'from_email',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'To field',
        param: 'to_email',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'CC field',
        param: 'cc',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'Bcc field',
        param: 'bcc',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'Contact name',
        param: 'contact_name',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
    ],
    tag: [
      {
        name: 'Has specific Tags…',
        param: 'labels',
        operator: 'contains',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'Does not have specific Tags…',
        param: 'labels',
        operator: 'does_not_contain',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'Has no tags',
        param: 'has_labels',
        operator: 'is',
        value: 'false',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'Has tags',
        param: 'has_labels',
        operator: 'is',
        value: 'true',
        deprecated: true,
      },
    ],
    assignee: [
      {
        name: 'Assignee is currently one of…',
        param: 'assignee',
        operator: 'contains',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'Assignee is currently not one of…',
        param: 'assignee',
        operator: 'does_not_contain',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'Conversation is unassigned',
        param: 'assigned',
        operator: 'is',
        value: 'false',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'Conversation is assigned',
        param: 'assigned',
        operator: 'is',
        value: 'true',
        deprecated: true,
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
    ],
    interactionCount: [
      {
        name: 'Total message count',
        param: 'comments_count',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
      {
        name: 'Customer reply count',
        param: 'enduser_message_count',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
      {
        name: 'Agent reply count',
        param: 'agent_message_count',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
    ],
    message: [
      {
        name: 'Subject',
        param: 'subject',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      ...generateDeprecatedConditions(
        'description',
        textAndPartialTextOperators
      ),
      ...generateDeprecatedConditions(
        'subject_or_description',
        textAndPartialTextOperators
      ),
      {
        name: 'Message content',
        param: 'message_content',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'Subject or message content',
        param: 'subject_or_message_content',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'Has an attachment',
        param: 'attachments',
        operator: 'is',
        value: 'true',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'Has no attachment',
        param: 'attachments',
        operator: 'is',
        value: 'false',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'Last message sender',
        param: 'last_message_sender_type',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
    ],
    time: [
      {
        name: 'Day of the week',
        param: 'time_day_of_week',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_2,
      },
      {
        name: 'Current time',
        param: 'time_current',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_2,
      },
      {
        name: 'Current date',
        param: 'date_current',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_2,
      },
      {
        name: 'Is in business hours',
        param: 'time_business_hours',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_2,
      },
      {
        name: 'Is customer message unreplied?',
        param: 'enduser_unreplied',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_2,
      },
      {
        name: 'Is agent message unreplied?',
        param: 'agent_unreplied',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_2,
      },
    ],
  },
  param_operators: {
    to_email: textAndDomainOperators,
    from_email: textAndDomainOperators,
    cc: textAndDomainOperators,
    bcc: textAndDomainOperators,
    contact_name: contactNameOperators,
    comments_count: numberOperators,
    enduser_message_count: numberOperators,
    agent_message_count: numberOperators,
    subject: textAndPartialTextOperators,
    message_content: textAndPartialTextOperators,
    subject_or_message_content: textAndPartialTextOperators,
    time_day_of_week: isOrIsNotOperators,
    time_current: ltGtEqOperators,
    time_business_hours: isOrIsNotOperators,
    date_current: numberOperators,
    enduser_unreplied: isOrIsNotOperators,
    agent_unreplied: isOrIsNotOperators,
    last_message_sender_type: isOrIsNotOperators,
  },
  values: {
    mailbox: {
      dataType: 'MAILBOXES',
    },
    status: {
      dataType: 'STATUSES',
    },
    from_email: {
      dataType: 'TEXT',
    },
    to_email: {
      dataType: 'TEXT',
    },
    cc: {
      dataType: 'TEXT',
    },
    bcc: {
      dataType: 'TEXT',
    },
    subject: {
      dataType: 'TEXT',
    },
    description: {
      dataType: 'TEXTAREA',
    },
    message_content: {
      dataType: 'TEXTAREA',
    },
    subject_or_description: {
      dataType: 'TEXTAREA',
    },
    subject_or_message_content: {
      dataType: 'TEXTAREA',
    },
    add_note: {
      dataType: 'TEXTAREA',
    },
    contact_name: {
      dataType: 'TEXT',
    },
    labels: { dataType: 'TAGS', paramType: 'names' },
    TAG: { dataType: 'TAG', paramType: 'name' },
    TAGS: { dataType: 'TAGS', paramType: 'names' },
    priority: [
      {
        name: 'Low',
        value: 'low',
      },
      {
        name: 'Medium',
        value: 'medium',
      },
      {
        name: 'High',
        value: 'high',
      },
      {
        name: 'Urgent',
        value: 'urgent',
      },
    ],
    source: { dataType: 'TICKETSOURCES' },
    assignee: { dataType: 'TEAMMATES' },
    comments_count: {
      dataType: 'NUMBER',
    },
    enduser_message_count: {
      dataType: 'NUMBER',
    },
    agent_message_count: {
      dataType: 'NUMBER',
    },
    time_day_of_week: [
      {
        name: 'Monday',
        value: '1',
      },
      {
        name: 'Tuesday',
        value: '2',
      },
      {
        name: 'Wednesday',
        value: '3',
      },
      {
        name: 'Thursday',
        value: '4',
      },
      {
        name: 'Friday',
        value: '5',
      },
      {
        name: 'Saturday',
        value: '6',
      },
      {
        name: 'Sunday',
        value: '0',
      },
    ],
    time_current: { dataType: 'TIME' },
    time_business_hours: [
      {
        name: 'True',
        value: 'true',
      },
      {
        name: 'False',
        value: 'false',
      },
    ],
    date_current: { dataType: 'DATE' },
    enduser_unreplied: [
      {
        name: 'True',
        value: 'true',
      },
      {
        name: 'False',
        value: 'false',
      },
    ],
    agent_unreplied: [
      {
        name: 'True',
        value: 'true',
      },
      {
        name: 'False',
        value: 'false',
      },
    ],
    last_message_sender_type: [
      {
        name: 'Agent',
        value: 'agent',
      },
      {
        name: 'Customer',
        value: 'enduser',
      },
    ],
  },
}

export const ruleActionsParemetersSectionTitles = {
  conversationStatus: {
    title: 'Update conversation status',
    icon: box,
  },
  snooze: {
    title: 'Snooze',
    icon: snoozeV2,
  },
  teammate: {
    title: app.t('Agents'),
    icon: teammates,
  },
  tag: {
    title: 'Tagging',
    icon: tagV2,
  },
  message: {
    title: 'Message actions',
    icon: message,
  },
  notification: {
    title: 'Notify',
    icon: notify,
  },
}

const ruleActions = isPaidAccount => {
  const parameters = {
    conversationStatus: [
      {
        name: 'Close conversation',
        type: 'status',
        value: 'closed',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'Reopen conversation',
        type: 'status',
        value: 'opened',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'Move conversation to Trash',
        type: 'status',
        value: 'trash',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'Move conversation to Spam',
        type: 'status',
        value: 'spam',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'Set Priority as',
        type: 'priority',
        deprecated: true,
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
    ],
    snooze: [
      {
        name: 'Snooze conversation',
        type: 'snooze_until',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'Unsnooze conversation',
        type: 'unsnooze',
        value: '',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
    ],
    teammate: [
      {
        name: `Assign to ${app.t('agent')}`,
        type: 'assignee_id',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
      {
        name: `Assign to available ${app.t('agent')}`,
        type: 'next_available_agent',
        value: '',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
      {
        name: `Assign to ${app.t('group')}`,
        type: 'assignee_group_id',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
      {
        name: `Assign to available ${app.t('group')}`,
        type: 'next_available_agent_in_group',
        value: '',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
      {
        name: 'Unassign conversation',
        type: 'unassign',
        value: '',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
    ],
    tag: [
      {
        name: 'Add tag',
        type: 'label',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
        deprecated: true,
      },
      {
        name: 'Add tags',
        type: 'labels',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'Remove tag',
        type: 'remove_label',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'Clear tags',
        type: 'remove_all_labels',
        value: '',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
    ],
    message: [
      {
        name: `Move ${app.t('mailbox')}`,
        type: 'mailbox',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
      {
        name: 'Reply to message',
        type: 'send_email_reply',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
      {
        name: 'Forward conversation',
        type: 'forward_conversation',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
      {
        name: 'Add a star',
        type: 'star',
        value: '4000',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'Remove star',
        type: 'star',
        value: '1000',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'Post a note',
        type: 'add_note',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
      {
        name: 'Replace subject',
        type: 'subject',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
    ],
    notification: [
      {
        name: 'Send in-app notification to...',
        type: 'send_in_app_notification_to_agents',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
      {
        name: 'Add followers to conversation',
        type: 'add_agents_as_followers',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
    ],
  }

  const values = {
    priority: {
      dataType: 'LIST',
      options: [
        {
          name: 'Low',
          value: '1000',
        },
        {
          name: 'Medium',
          value: '2000',
        },
        {
          name: 'High',
          value: '3000',
        },
        {
          name: 'Urgent',
          value: '4000',
        },
      ],
    },
    label: { dataType: 'TAG', paramType: 'name' },
    labels: { dataType: 'TAGS', paramType: 'ids' },
    remove_label: { dataType: 'TAG', paramType: 'id' },
    remove_all_labels: { dataType: null },
    next_available_agent: { dataType: null },
    unassign: { dataType: null },
    channel: {
      dataType: 'LIST',
      options: [
        {
          name: 'Widget',
          value: 'Widget',
        },
        {
          name: 'Facebook',
          value: 'Facebook',
        },
        {
          name: 'Twitter',
          value: 'Twitter',
        },
        {
          name: 'Chat',
          value: 'Chat',
        },
        {
          name: 'Email',
          value: 'Email',
        },
      ],
    },
    snooze_until: {
      dataType: 'LIST',
      options: [
        {
          name: '4 hours',
          value: '14400',
        },
        {
          name: '2 days',
          value: '172800',
        },
        {
          name: '4 days',
          value: '345600',
        },
        {
          name: '1 week',
          value: '604800',
        },
        {
          name: '2 weeks',
          value: '1209600',
        },
        {
          name: '1 month',
          value: '2592000',
        },
        {
          name: 'Indefinitely',
          value: 'indefinitely',
        },
      ],
    },
    unsnooze: { dataType: null },
    subject: { dataType: 'SUBJECT' },
    mailbox: { dataType: 'LIST', valueType: 'Mailbox' },
    assignee_id: { dataType: 'LIST', valueType: 'Agent' },
    assignee_group_id: { dataType: 'LIST', valueType: 'Group' },
    next_available_agent_in_group: { dataType: 'LIST', valueType: 'Group' },
    add_agents_as_followers: { dataType: 'TEAMMATES', dataOperator: 'and' },
    send_email_to_agents: {
      dataType: 'MESSAGE_TEMPLATE',
      dataOperator: 'and',
      toAgentDataType: 'TEAMMATES',
      allowSelectAssigned: true,
    },
    send_in_app_notification_to_agents: {
      dataType: 'TEXT',
      label: 'Notification message',
      dataOperator: 'and',
      toAgentDataType: 'TEAMMATES',
      allowSelectAssigned: true,
    },
    send_email_to_requester: {
      dataType: 'MESSAGE_TEMPLATE',
    },
    send_email_reply: {
      dataType: 'MESSAGE_TEMPLATE',
      toAgentDataType: 'REPLY_AGENT',
    },
    add_note: { dataType: 'NOTE', toAgentDataType: 'REPLY_AGENT' },
    forward_conversation: { dataType: 'EMAIL' },
    time_current: { dataType: 'TIME' },
    date_current: { dataType: 'DATE' },
  }

  if (isPaidAccount) {
    parameters.message.push({
      name: 'Send a new email to the customer',
      type: 'send_email_to_requester',
      product: INBOX,
      feature: FEATURE_INBOX_RULES_TIER_1,
    })

    parameters.notification.unshift({
      name: 'Send email notification to...',
      type: 'send_email_to_agents',
      product: INBOX,
      feature: FEATURE_INBOX_RULES_TIER_1,
    })
  }

  return {
    parameters,
    values,
  }
}

export const ruleActionsForPaid = {
  ...ruleActions(true),
}

export const ruleActionsForUnpaid = {
  ...ruleActions(false),
}

export const ruleTriggersParemetersSectionTitles = {
  message: {
    title: 'Message triggers',
    icon: message,
  },
  conversationStatus: {
    title: 'State changes',
    icon: box,
  },
  teammate: {
    title: 'Teammates',
    icon: teammates,
  },
  snooze: {
    title: 'Snooze',
    icon: snoozeV2,
  },
  tag: {
    title: 'Tags',
    icon: tagV2,
  },
}

// NOTE: make sure to change for_rule_triggers.rb if you change the product/feature for below
export const ruleTriggers = {
  parameters: {
    message: [
      {
        name: 'New conversation is received',
        value: 'created',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'Inbound message received',
        value: 'new_inbound_message',
        product: INBOX,
        feature: FEATURE_INBOX_RULES,
      },
      {
        name: 'Outbound message sent',
        value: 'new_outbound_message',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
      {
        name: `New conversation is created by ${app.t('agent')}`,
        value: 'created_by_agent',
        inputType: 'teammate',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
      {
        name: `Conversation moves to ${app.t('mailbox')}`,
        value: 'mailbox_changed',
        inputType: 'mailbox',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
      {
        name: 'Conversation is rated',
        value: 'conversation_rated',
        inputType: 'rating',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
      {
        name: 'Any update occurs on a conversation',
        value: 'updated',
        tooltip:
          'This will trigger the rule when anything happens on the conversation.',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
    ],
    conversationStatus: [
      {
        name: 'Conversation changes to Closed',
        value: 'conversation_closed',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
      {
        name: 'Conversation is Opened/Reopened',
        value: 'conversation_opened',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
      {
        name: 'Conversation is sent to Trash',
        value: 'conversation_trashed',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
      {
        name: 'Conversation is sent to Spam',
        value: 'conversation_spammed',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
      {
        name: 'Conversation is restored from Trash',
        value: 'conversation_untrashed',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
    ],
    teammate: [
      {
        name: `${app.t('Agent')} is mentioned`,
        value: 'agent_mentioned',
        inputType: 'teammate',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
      {
        name: `${app.t('Agent')} is assigned`,
        value: 'agent_assigned',
        inputType: 'teammate',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
      {
        name: `${app.t('Agent')} is unassigned`,
        value: 'agent_unassigned',
        inputType: 'teammate',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
      {
        name: 'New note is added by...',
        value: 'new_note',
        inputType: 'teammate',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
    ],
    snooze: [
      {
        name: 'Conversation Snoozed',
        value: 'conversation_snoozed',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
      {
        name: 'Conversation Snooze expires',
        value: 'conversation_automatic_unsnoozed',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
    ],
    tag: [
      {
        name: 'Tag is added',
        value: 'label_added',
        inputType: 'tag',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
      {
        name: 'Tag is removed',
        value: 'label_removed',
        inputType: 'tag',
        product: INBOX,
        feature: FEATURE_INBOX_RULES_TIER_1,
      },
    ],
  },
}

export const ruleTriggersBySettingInputNeeded = Object.fromEntries(
  Object.values(ruleTriggers.parameters)
    .flat(1)
    .filter(obj => !['created_by_agent', 'mailbox_changed'].includes(obj.value))
    .map(obj => [obj.value, obj.inputType !== undefined])
)

export const ruleTriggersRequireConditions = [
  'created',
  'updated',
  'new_inbound_message',
  'new_outbound_message',
  'new_note',
]
