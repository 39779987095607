import {
  V5_SIGNUP_PAGE,
  V5_EMAIL_PAGE,
  V5_USER_PAGE,
  V5_COMPANY_PAGE,
  V5_VERIFICATION_PAGE,
} from './pages'

// NOTE (jscheel): Simple configuration object for onboarding routes to load

const V5_ROUTES_CONFIG = {
  [V5_SIGNUP_PAGE]: {
    route: {
      path: '/signup/f5',
    },
    handler: () => ({
      componentName: 'V5SignupPage',
      subapp: 'onboarding',
    }),
  },
  [V5_EMAIL_PAGE]: {
    route: {
      path: '/signup/f5/email',
    },
    handler: () => ({
      componentName: 'V5EmailPage',
      subapp: 'onboarding',
    }),
  },
  [V5_USER_PAGE]: {
    route: {
      path: '/signup/me',
    },
    handler: () => ({
      componentName: 'V5UserPage',
      subapp: 'onboarding',
    }),
  },
  [V5_COMPANY_PAGE]: {
    route: {
      path: '/signup/f5/organization',
    },
    handler: () => ({
      componentName: 'V5CompanyPage',
      subapp: 'onboarding',
    }),
  },
  [V5_VERIFICATION_PAGE]: {
    route: {
      path: '/signup/f5/verification',
    },
    handler: () => ({
      componentName: 'V5VerificationPage',
      subapp: 'onboarding',
    }),
  },
}

export default V5_ROUTES_CONFIG
