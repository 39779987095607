import React, { useMemo } from 'react'
import cn from 'classnames'
import Modal from '@groovehq/internal-design-system/lib/components/Modal/Modal'
import ProgressDots from '@groovehq/internal-design-system/lib/components/ProgressDots/ProgressDots'
import ChannelOptions from './ChannelOptions'
import { CHANNEL_EMAIL_OPTIONS } from '../Channels.data'
import { styles } from './styles'
import useEmailChannelSelector from './useEmailChannelSelector'

const SelectGoogleEmailChannel = ({
  onClose,
  onExit,
  previousDrawer,
  drawerSource: source = 'settings',
  drawerId,
}) => {
  const channelList = useMemo(() => {
    const types = CHANNEL_EMAIL_OPTIONS.google.secondaryTypes
    return Object.keys(types).map(provider => {
      const channel = {
        provider,
        ...types[provider],
      }
      return channel
    })
  }, [])

  const {
    creationStepCount,
    handleBack,
    handleSelect,
  } = useEmailChannelSelector({
    onClose,
    onExit,
    previousDrawer,
    drawerSource: source,
    drawerId,
    options: CHANNEL_EMAIL_OPTIONS.google.secondaryTypes,
  })

  return (
    <Modal
      onClose={onExit}
      portal="#drawer-root"
      dataTestId="drawer-select-google-email-channel"
      className={cn('grui pb-14', channelList.length > 2 && 'mt-18')}
      open
      backdropTransparency="light"
    >
      <ProgressDots
        count={creationStepCount}
        now={1}
        className="grui mt-4 mb-13"
      />
      <Modal.Title className="grui text-center">
        What type of Google email address would you like to connect to Groove?
      </Modal.Title>
      <Modal.Description css={styles.description}>
        Follow these steps to automatically get emails sent to your specific
        email inbox(es) in Groove.
      </Modal.Description>
      <ChannelOptions
        channelList={channelList}
        onSelect={handleSelect}
        className="grui mt-19 mb-13"
      />
      <div className="grui text-center">
        <Modal.Button type="link" onClick={handleBack}>
          Back
        </Modal.Button>
      </div>
    </Modal>
  )
}

export default SelectGoogleEmailChannel
