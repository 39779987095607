import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util'

const messageCard = css`
  padding-right: ${rem(30)};
`

const googleButton = css`
  height: 40px;
  width: 189px;

  svg {
    width: 100%;
    height: 100%;
  }
`

export const styles = {
  messageCard,
  googleButton,
}
