import { useMemo } from 'react'
import MessageCard from '@groovehq/internal-design-system/lib/components/MessageCard/MessageCard'
import {
  text,
  paragraph,
  button,
} from '@groovehq/internal-design-system/lib/styles/elements'
import { isBridgeChannelType } from 'ducks/channels/channelTypes'
import { rem } from '@groovehq/internal-design-system/lib/util'
import { css } from '@emotion/react'
import useOpenContact from 'util/hooks/useOpenContact'
import { CHANNEL_TYPES } from '../Channels.data'

const btnIcon = theme => css`
  svg {
    width: ${rem(24)};
    height: ${rem(24)};
    background: ${theme.color.monochrome.white};
    border-radius: 50%;
    vertical-align: middle;
  }
`

export default function useSourceWording(source, channelTypeConfig = {}) {
  const openContact = useOpenContact({ left: true })

  return useMemo(
    () => {
      if (source === 'inbox') {
        const { name, company } = channelTypeConfig
        return {
          drawerTitle: `Reconnect ${name}`,
          title: `Re-authenticate your ${name} ${app.t('mailbox')}.`,
          intro: (
            <>
              <p>
                {`Occasionally ${name} may require re-authentication if ${company} has
                changed their permissions or if your ${name} account has updated
                your security settings or changed your password.`}
              </p>

              <MessageCard type="warning" className="grui mt-11">
                <div css={text.styles.fontMedium}>Heads up</div>
                <p css={paragraph.styles.preflight} className="grui my-5">
                  Once re-authenticated, Groove will review the past 10,000
                  emails sent to this email address and reprocess any emails
                  that didn&apos;t reach Groove due to the disconnect. You will
                  see these reprocessed emails in Groove within 10 minutes.
                </p>
                <p>
                  Please{' '}
                  <button
                    className="grui"
                    css={button.styles.link}
                    onClick={openContact}
                    type="button"
                  >
                    contact our support team
                  </button>{' '}
                  if you have any questions.
                </p>
              </MessageCard>
            </>
          ),
        }
      }
      if (isBridgeChannelType(source)) {
        const { name, icon } = CHANNEL_TYPES[source] || {}
        return {
          drawerTitle: `Reconnect ${name}`,
          title: `Re-authenticate ${name}`,
          intro: (
            <>
              <p>
                Reconnect {name} to give Groove access to your page again. In
                addition to{' '}
                <span css={[text.styles.fontSemibold]}>
                  selecting any new pages
                </span>{' '}
                that you want to use, make sure to{' '}
                <span css={[text.styles.fontSemibold]}>
                  keep all currently connected pages
                </span>.
              </p>
            </>
          ),
          btnText: (
            <>
              <span css={btnIcon} className="grui mr-4">
                {icon}
              </span>
              Re-authenticate {name}
            </>
          ),
        }
      }
      return {}
    },
    [source, channelTypeConfig, openContact]
  )
}
