export const actionVariableTypes = [
  {
    key: 'conversation_subject',
    title: 'Conversation subject',
  },
  {
    key: 'conversation_number',
    title: 'Conversation number',
  },
  {
    key: 'contact_full_name',
    title: 'Contact full name',
  },
  {
    key: 'contact_first_name',
    title: 'Contact first name',
  },
  {
    key: 'contact_last_name',
    title: 'Contact last name',
  },
  {
    key: 'contact_email',
    title: 'Contact email',
  },
  {
    key: 'company_name',
    title: 'Company name',
  },
  {
    key: 'agent_full_name',
    title: `${app.t('Agent')} full name`,
  },
  {
    key: 'agent_first_name',
    title: `${app.t('Agent')} first name`,
  },
  {
    key: 'agent_last_name',
    title: `${app.t('Agent')} last name`,
  },
  {
    key: 'comment',
    title: 'Conversation comment',
  },
  {
    key: 'organization_name',
    title: 'Organization name',
  },
  {
    key: 'mailbox_name',
    title: `${app.t('Mailbox')} sender name`,
  },
  {
    key: 'mailbox_email',
    title: `${app.t('Mailbox')} email`,
  },
]

export const subjectVariableTypes = [
  {
    key: 'conversation_subject',
    title: 'Conversation subject',
  },
  {
    key: 'conversation_number',
    title: 'Conversation number',
  },
  {
    key: 'contact_first_name',
    title: 'Contact first name',
  },
  {
    key: 'contact_last_name',
    title: 'Contact last name',
  },
  {
    key: 'company_name',
    title: 'Company name',
  },
  {
    key: 'mailbox_name',
    title: `${app.t('Mailbox')} sender name`,
  },
]
