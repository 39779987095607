import React from 'react'
import cn from 'classnames'
import {
  DELETE_TAGS,
  MERGE_TAGS,
  DELETE_MESSAGE_TEMPLATES,
  UPDATE_MESSAGE_TEMPLATES,
} from 'ducks/batchJobs/jobTypes'
import { useBatchJobs } from 'ducks/batchJobs/hooks'
import AppBar from '@groovehq/internal-design-system/lib/components/AppBar/AppBar'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'

const jobAppBarConfig = {
  [DELETE_TAGS]: {
    title: 'Deleting tags',
    description:
      'Note, certain tags will still be visible until the process is complete.',
    type: 'warning',
  },
  [MERGE_TAGS]: {
    title: 'Merging tags',
    description:
      'Note, certain tags will still be visible until the process is complete.',
    type: 'warning',
  },
  [DELETE_MESSAGE_TEMPLATES]: {
    title: `Deleting ${app.t('canned_replies')}`,
    description: `Note, certain ${app.t(
      'canned_replies'
    )} will still be visible until the process is complete.`,
    type: 'warning',
  },
  [UPDATE_MESSAGE_TEMPLATES]: {
    title: `Changing ${app.t('canned_replies')} category!`,
    description: `Note, certain ${app.t(
      'canned_replies'
    )} will still be in the same category until the process is complete.`,
    type: 'warning',
  },
}

export default function BatchJobsAppBar({ jobType, roundBorder }) {
  const { jobs } = useBatchJobs(jobType)
  const hasJobs = (jobs || []).length > 0

  // If the job type doesnt exist, dont render it
  if (!jobAppBarConfig[jobType] || !hasJobs) return null

  const { title, description, type } = jobAppBarConfig[jobType]

  return (
    <AppBar
      close={!hasJobs}
      type={type}
      closable={false}
      roundBorder={roundBorder}
      className={cn({ 'grui mb-8': hasJobs })}
    >
      <span css={text.styles.fontMedium}>{title}&nbsp;</span>
      {description}
    </AppBar>
  )
}
