import React, { useCallback, Fragment, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useDataTable } from 'ducks/tables/hooks'
import Table from '@groovehq/internal-design-system/lib/components/Table/Table'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import MainMenu from '@groovehq/internal-design-system/lib/components/MainMenu/MainMenu'
import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'
import { filterQueryByTargetId } from 'ducks/searches/utils/query'

import {
  doFetchAgentsInMemory,
  doResendInvitation,
  doResetPassword,
  doResetLoginAttempts,
} from 'ducks/agents/operations'
import { searchInvalidateEntity } from 'ducks/searches/utils/action'
import { TABLE_CACHE_INVALIDATE } from 'ducks/tables/actionTypes'
import { SETTINGS_USER_TABLE_ID } from 'ducks/tables/ids'
import {
  DRAWER_TYPE_SAMPLE,
  DRAWER_TYPE_AGENT_ARCHIVE,
  DRAWER_TYPE_AGENT_ADD,
  DRAWER_TYPE_AGENT_OWNER,
  DRAWER_TYPE_USER_ADVANCED,
  DRAWER_TYPE_ADMIN_ADD,
  DRAWER_TYPE_ADMIN_REMOVE,
  DRAWER_TYPE_LITE_USER_ADD,
  DRAWER_TYPE_REVOKE_INVITATION,
  DRAWER_TYPE_UNARCHIVE,
} from 'ducks/drawers/types'
import { useDrawer } from 'ducks/drawers/hooks'
import { selectQueryParams } from 'selectors/location'
import { buildDrawerQueryParam } from 'ducks/drawers/util'
import { useFeature } from 'ducks/billing/hooks'
import { FEATURE_INBOX_MAX_USERS } from 'ducks/billing/featureTypes'
import useColumns from './useColumns'
import { styles } from './styles'
import PlanLimitationCard from '../PlanLimitationCard'

const mainMenuTabs = [
  { key: 'active', tabName: 'Active' },
  { key: 'invited', tabName: 'Invited' },
  { key: 'archived', tabName: 'Archived' },
]

const UsersDataTable = ({ scope }) => {
  const {
    pagination: {
      currentDenormalizedEntities: agents,
      gotoPage,
      changePageSize,
    },
    pagination,
    filtering: { changeFilterDelayed, changeFilter },
    sorting: { changeSort },
    query,
    queryId,
    sorting,
    isLoading,
    shouldLoad,
    isError,
    scroll,
  } = useDataTable(SETTINGS_USER_TABLE_ID, 'agent', doFetchAgentsInMemory, {
    pagination: {
      defaultPageSize: 20,
      defaultCursor: 1,
      showSizeChanger: true,
      pageSizeOptions: [10, 20, 30],
      filterDelay: 1000,
      parseToIntKeys: ['cursor'],
      defaultOrderBy: 'name_ASC',
    },
    filtering: {
      baseFilters: {
        scope,
      },
    },
    scroll: {
      persistScrollPosition: true,
    },
  })

  const currentUser = useSelector(selectCurrentUser)
  const { canUseFeature, featureLimit: agentsLimit } = useFeature(
    FEATURE_INBOX_MAX_USERS
  )
  const showPlanLimitationForAgents = !canUseFeature
  const columns = useColumns({ scope, currentUser })
  const dispatch = useDispatch()

  useEffect(
    () => {
      // refetch agents on table load and when query scope changes
      dispatch({
        type: TABLE_CACHE_INVALIDATE,
        ...searchInvalidateEntity('agent'),
      })
    },
    [dispatch, query.scope]
  )

  const handleOnTableStateChange = useCallback(
    (newState, action = {}) => {
      const { type } = action || {}
      if (type === 'gotoPage') {
        const { pageIndex } = action
        gotoPage(pageIndex + 1)
      } else if (type === 'setPageSize') {
        const { pageSize } = action
        changePageSize(pageSize)
      } else if (type === 'toggleSortBy' || type === 'setSortBy') {
        const { id, desc } = newState.sortBy[0] || {}
        changeSort(id ? `${id}_${desc ? 'DESC' : 'ASC'}` : null)
      } else if (type === 'setGlobalFilter') {
        const { filterValue } = action
        // When setting filter, debounce for 1 second, but when removing a filter
        // trigger that immediately
        if (filterValue) {
          changeFilterDelayed('search', filterValue, { resetPage: true })
        } else {
          changeFilter('search', filterValue, { resetPage: true })
        }
      }
    },
    [changeSort, changeFilterDelayed, changeFilter, gotoPage, changePageSize]
  )

  const { drawerId: deleteDrawerId, openDrawer: openDeleteDrawer } = useDrawer(
    DRAWER_TYPE_AGENT_ARCHIVE
  )

  const { openDrawer: openChangeOwnerDrawer } = useDrawer(
    DRAWER_TYPE_AGENT_OWNER
  )

  const { openDrawer: openAddAdminDrawer } = useDrawer(DRAWER_TYPE_ADMIN_ADD)
  const { openDrawer: openRemoveAdminDrawer } = useDrawer(
    DRAWER_TYPE_ADMIN_REMOVE
  )
  const { openDrawer: openLiteUserDrawer } = useDrawer(
    DRAWER_TYPE_LITE_USER_ADD
  )
  const { openDrawer: openRevokeDrawer } = useDrawer(
    DRAWER_TYPE_REVOKE_INVITATION
  )
  const { openDrawer: openUnarchiveDrawer } = useDrawer(DRAWER_TYPE_UNARCHIVE)

  const { openDrawer: openEditDrawer } = useDrawer(DRAWER_TYPE_SAMPLE)

  const { openDrawer: openAdvancedDrawer } = useDrawer(
    DRAWER_TYPE_USER_ADVANCED
  )

  const handleDelete = useCallback(
    id => {
      openDeleteDrawer(id, {
        query: {
          ...buildDrawerQueryParam(deleteDrawerId, 'drawerDeleteMode', 'ids'),
        },
        additionalProps: {
          title: `Archive ${app.t('Agent')}`,
          queryId,
        },
      })
    },
    [openDeleteDrawer, deleteDrawerId, queryId]
  )

  const handleEdit = useCallback(
    props => {
      const { id, type } = props
      if (type === 'resetPassword') {
        return dispatch(doResetPassword(id))
      } else if (type === 'resetLoginAttempts') {
        return dispatch(doResetLoginAttempts(id))
      } else if (type === 'beAdmin') {
        return openAddAdminDrawer(id)
      } else if (type === 'beAgent') {
        return openRemoveAdminDrawer(id)
      } else if (type === 'beViewer') {
        return openLiteUserDrawer(id)
      } else if (type === 'beOwner') {
        return openChangeOwnerDrawer(id)
      } else if (type === 'advanced') {
        return openAdvancedDrawer(id, {
          additionalProps: {
            queryId,
            title: 'Advanced',
          },
        })
      } else if (type === 'unarchiveUser') {
        return openUnarchiveDrawer(id, {
          additionalProps: {
            queryId,
          },
        })
      } else if (type === 'resendInvitation') {
        return dispatch(doResendInvitation(id))
      } else if (type === 'revokeInvitation') {
        return openRevokeDrawer(id, {
          additionalProps: {
            queryId,
          },
        })
      }
      return openEditDrawer(id, {
        additionalProps: {
          queryId,
          title: `Edit ${app.t('Agent')}`,
        },
      })
    },
    [
      openAdvancedDrawer,
      openChangeOwnerDrawer,
      openEditDrawer,
      dispatch,
      queryId,
      openAddAdminDrawer,
      openRemoveAdminDrawer,
      openLiteUserDrawer,
      openRevokeDrawer,
      openUnarchiveDrawer,
    ]
  )

  const emptyContent = (
    <Table.Empty
      title={`There are no ${app.t('agents')}`}
      subtitle={`There are no ${app.t('agents')} matching your criteria`}
    />
  )

  return (
    <>
      {showPlanLimitationForAgents && (
        <PlanLimitationCard
          className="grui mb-9 mt-3"
          title={`Need to bring on more ${app.t('agents')}?`}
          trackingFeatureType={FEATURE_INBOX_MAX_USERS}
        >
          You’ve reached your{' '}
          <strong>
            limit of {agentsLimit} {app.t('agents')}
          </strong>{' '}
          on your account.
          <br />
          To add more, please upgrade your account.
        </PlanLimitationCard>
      )}
      <Table
        separate
        columns={columns}
        sorting={sorting}
        css={styles.table}
        data={agents}
        showPagination
        pagination={pagination}
        onTableStateChange={handleOnTableStateChange}
        emptyContent={emptyContent}
        loading={shouldLoad || !!isLoading}
        emptyHintVisible={false}
        hasError={isError}
        updateData={handleEdit}
        deleteData={handleDelete}
        onEdit={handleEdit}
        dataType="agents"
        scroll={scroll}
        disableSortRemove
      />
    </>
  )
}

export const UsersDataTableWithMenu = () => {
  const { openDrawer: openAddDrawer } = useDrawer(DRAWER_TYPE_AGENT_ADD)
  const { canUseFeature } = useFeature(FEATURE_INBOX_MAX_USERS)
  const showPlanLimitationForAgents = !canUseFeature
  const fullQuery = useSelector(selectQueryParams)
  const query = filterQueryByTargetId(SETTINGS_USER_TABLE_ID, fullQuery)
  const [scope, setScope] = useState(query.scope || 'active')
  const handleScopeChange = useCallback(newScope => {
    setScope(newScope)
  }, [])

  const handleCreate = useCallback(
    () => {
      openAddDrawer('new', {
        additionalProps: {
          title: `Add ${app.t('Agent')}`,
        },
      })
    },
    [openAddDrawer]
  )

  return (
    <Fragment>
      <MainMenu
        tabsData={mainMenuTabs}
        onTabChange={handleScopeChange}
        defaultActiveTabKey={scope}
      >
        <Button
          size="small"
          onClick={handleCreate}
          disabled={showPlanLimitationForAgents}
        >
          Add {app.t('agents')}
        </Button>
      </MainMenu>
      <div className="grui mt-12">
        <UsersDataTable scope={scope} />
      </div>
    </Fragment>
  )
}

UsersDataTable.displayName = 'UsersDataTable'

export default UsersDataTable
