import { slugify } from 'util/url'
import {
  FIRST_LAST_NAME_CHANGE,
  EMAIL_CHANGE,
  PASSWORD_CHANGE,
  COMPANY_CHANGE,
  SUBDOMAIN_CHANGE,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  CHECK_DOMAIN_REQUEST,
  CHECK_DOMAIN_SUCCESS,
  CHECK_DOMAIN_FAILURE,
} from './types'

const defaultSignupState = {
  focusInput: null,
  isDomainValid: null,
}

export default function signupReducer(
  state,
  {
    type,
    payload: {
      inputValue,
      signupAccount,
      error,
      checkSubdomainAvailability,
    } = {},
  }
) {
  switch (type) {
    case FIRST_LAST_NAME_CHANGE:
      return {
        ...state,
        firstLastNameInput: inputValue,
      }
    case EMAIL_CHANGE:
      return {
        ...state,
        emailInput: inputValue,
      }
    case PASSWORD_CHANGE:
      return {
        ...state,
        passwordInput: inputValue,
      }
    case COMPANY_CHANGE:
      return {
        ...state,
        companyInput: inputValue,
        subDomainInput: slugify(inputValue),
        isDomainValid: null,
        domainErrorMessage: '',
      }
    case SUBDOMAIN_CHANGE:
      return {
        ...state,
        subDomainInput: inputValue,
        isDomainValid: null,
        domainErrorMessage: '',
      }
    case SIGNUP_REQUEST:
      return {
        ...state,
        isCreatingAccount: true,
        hasSignupError: false,
        newAccount: null,
      }
    case SIGNUP_SUCCESS:
      return {
        ...state,
        isCreatingAccount: false,
        newAccount: signupAccount,
      }
    case SIGNUP_FAILURE:
      return {
        ...state,
        isCreatingAccount: false,
        hasSignupError: true,
        signupError: error,
      }
    case CHECK_DOMAIN_REQUEST:
      return {
        ...state,
        isCheckingDomain: true,
        isDomainValid: null,
        domainErrorMessage: null,
      }
    case CHECK_DOMAIN_SUCCESS:
      return {
        ...state,
        isCheckingDomain: false,
        isDomainValid: checkSubdomainAvailability.valid,
        domainErrorMessage: checkSubdomainAvailability.message,
      }
    case CHECK_DOMAIN_FAILURE:
      return {
        ...state,
        isCheckingDomain: false,
        isDomainValid: false,
        domainErrorMessage: error,
      }
    default:
      return state || defaultSignupState
  }
}
