import grooveAPI from 'api/groove'
import config from 'config'
import storage from 'util/storage'
import { toQueryString } from 'util/params'

export default function navigateToLegacy(
  token,
  subdomain,
  path = '',
  target,
  opts
) {
  const params = opts ? { ...opts } : {}
  const showSettings =
    storage.get('showSettings') === true ? true : config.showSettings
  if (!showSettings) {
    params.mode = 'legacy'
  } else if (showSettings) {
    params.mode = 'beta'
    params.origin = window.location.host.replace(/\.groovehq\.\w+$/, '')
  }

  if (!subdomain) throw new Error('subdomain not available')

  if (!token) throw new Error('token not available')

  setupLegacySession({ token }).then(newToken => {
    const destination = buildAutoLoginPath({
      token: newToken,
      subdomain,
      path,
      params,
    })

    if (target) {
      window.open(destination, target)
    } else {
      window.location.href = destination
    }
  })
}

export function redirectToLegacyLink(token, subdomain, path = '') {
  if (!subdomain) throw new Error('subdomain not available')
  if (!token) throw new Error('token not available')
  const apiUrl = config.autologin_url || config.api_url
  const host = apiUrl.replace('api', subdomain)
  const redirectPath = encodeURI(
    `${path}${path.includes('?') ? '&' : '?'}mode=beta`
  )
  return `${host}/v1/autologin?token=${token}&path=${redirectPath}`
}

export async function setupLegacySession({ token }) {
  const data = await grooveAPI.post(token, 'v1/autologin')
  return data.json.token
}

export function buildAutoLoginPath({ token, subdomain, path, params }) {
  const apiUrl = config.autologin_url || config.api_url
  const host = apiUrl.replace('api', subdomain)
  let finalPath = path.charAt(0) === '/' ? path : `/groove_client/${path}`
  if (params) {
    finalPath = `${finalPath}?${toQueryString(params)}`
  }
  finalPath = encodeURIComponent(finalPath)

  return `${host}/users/sign_in/${token}?path=${finalPath}`
}
