/* eslint-disable no-param-reassign  */

import createFpsCapturer from 'util/fpsCapture'
import metrics from 'util/metrics'

export default function extend(app) {
  let fpsCapturer
  app.startFpsCapture = () => {
    fpsCapturer = createFpsCapturer()
    fpsCapturer.start()
  }
  app.stopFpsCapture = (subname, slowdown, skipSend) => {
    fpsCapturer.stop()
    const payload = {
      average: fpsCapturer.getAverage(),
      median: fpsCapturer.getMedian(),
      percentile_75th: fpsCapturer.getPercentile(75),
      percentile_95th: fpsCapturer.getPercentile(95),
      percentile_99th: fpsCapturer.getPercentile(99),
      metric: ['fps', subname].join('-'),
      logname: 'main',
      slowdown,
    }
    if (!skipSend) {
      metrics.log(payload)
      // force send
      metrics.send()
    }
    fpsCapturer = null
    return payload
  }
  app.runFolderTest = () => {
    app.startFpsCapture()
    setTimeout(() => {
      const list = Array.from(document.getElementsByClassName('folder-link'))
      const first = list.shift()
      list.push(first)
      const queue = list.concat(list)
      function runNext() {
        const element = queue.shift()
        if (!element) {
          setTimeout(() => {
            app.stopFpsCapture('folders')
          }, 1000)
          return
        }
        element.click()
        setTimeout(runNext, 1000)
      }
      runNext()
    }, 2000)
  }
  app.runTicketListTest = () => {
    app.startFpsCapture()
    setTimeout(() => {
      const list = Array.from(
        document.getElementsByClassName('conversation-list-item')
      )
      const first = list.shift()
      list.push(first)
      const queue = list.concat(list)
      function runNext() {
        const element = queue.shift()
        if (!element) {
          setTimeout(() => {
            app.stopFpsCapture('ticket-list')
          }, 1000)
          return
        }
        element.lastChild.click()
        setTimeout(runNext, 2000)
      }
      runNext()
    }, 2000)
  }
}
