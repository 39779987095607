import { FEATURE_INBOX_RULES } from 'ducks/billing/featureTypes'
import { INBOX } from 'ducks/billing/productTypes'
import { ordinal } from 'util/ordinal'
import { pluralize } from 'util/strings'

const conditionNameTemplates = operatorName => ({
  subject: `Subject ${operatorName}…`,
  subject_or_description: `Subject or description ${operatorName}…`,
  description: `Body ${operatorName}…`,
  message_content: `Message content ${operatorName}…`,
  subject_or_message_content: `Subject or message content ${operatorName}…`,
  contact_name: `Contact name ${operatorName}`,
  channel: `Conversation Channel type ${operatorName}…`,
  priority: `Priority ${operatorName}…`,
})

// Generate deprecated param + operator
// if the existing rule condition uses it, we still show it in the dropdown
export const generateDeprecatedConditions = (
  param,
  operators,
  omitOperators
) => {
  const filteredOperators = operators.filter(
    ({ value }) => !omitOperators?.includes(value)
  )
  return filteredOperators.map(({ value, name }) => ({
    name: conditionNameTemplates(name)[param],
    param,
    operator: value,
    deprecated: true,
    product: INBOX,
    feature: FEATURE_INBOX_RULES,
  }))
}

export const getOnDayOptions = () => {
  return [...Array(28)].map((_, index) => ({
    id: index + 1,
    name: ordinal(index + 1),
  }))
}

export const getBeforeMonthEndOptions = () => {
  return [...Array(14)].map((_, index) => ({
    id: index + 1,
    name: `${index + 1} ${pluralize(index + 1, 'day')}`,
  }))
}

export const getNameById = (options, id) =>
  options.find(option => option.id === id)?.name || options[0].name
