import React, { useMemo } from 'react'
import cn from 'classnames'
import Modal from '@groovehq/internal-design-system/lib/components/Modal/Modal'
import ProgressDots from '@groovehq/internal-design-system/lib/components/ProgressDots/ProgressDots'
import ChannelOptions from './ChannelOptions'
import { CHANNEL_EMAIL_OPTIONS } from '../Channels.data'
import { styles } from './styles'
import useEmailChannelSelector from './useEmailChannelSelector'

const SelectGoogleWorkspaceEmailChannel = ({
  onClose,
  onExit,
  previousDrawer,
  drawerSource: source = 'settings',
  drawerId,
}) => {
  const options =
    CHANNEL_EMAIL_OPTIONS.google.secondaryTypes.google.secondaryTypes
  const channelList = useMemo(
    () => {
      return Object.keys(options).map(provider => {
        const channel = {
          provider,
          ...options[provider],
        }
        return channel
      })
    },
    [options]
  )

  const {
    creationStepCount,
    handleBack,
    handleSelect,
  } = useEmailChannelSelector({
    onClose,
    onExit,
    previousDrawer,
    drawerSource: source,
    drawerId,
    options,
  })

  return (
    <Modal
      onClose={onExit}
      portal="#drawer-root"
      dataTestId="drawer-select-google-workspace-email-channel"
      className={cn('grui pb-14', channelList.length > 2 && 'mt-18')}
      open
      backdropTransparency="light"
    >
      <ProgressDots
        count={creationStepCount}
        now={1}
        className="grui mt-4 mb-13"
      />
      <Modal.Title className="grui text-center">
        What email connection method would you like to use?
      </Modal.Title>
      <Modal.Description css={styles.description}>
        We recommend using the{' '}
        <strong>
          <i>Connect with Google Workspace</i>
        </strong>{' '}
        method
      </Modal.Description>
      <ChannelOptions
        channelList={channelList}
        onSelect={handleSelect}
        className="grui mt-19 mb-13"
      />
      <div className="grui text-center">
        <Modal.Button type="link" onClick={handleBack}>
          Back
        </Modal.Button>
      </div>
    </Modal>
  )
}

export default SelectGoogleWorkspaceEmailChannel
