import { connect } from 'react-redux'
import {
  selectCurrentPage,
  selectTotalPages,
  selectIsTicketSelectionMode,
  selectIsLoadingMoreTickets,
} from 'selectors/ticket_list'
import {
  selectCurrentSortOrder,
  selectIsTicketsCurtainVisible,
  selectPreviousScrollY,
} from 'selectors/app'
import { selectHasCurrentTicketSearchLoaded } from 'selectors/search/base'
import {
  selectCurrentTicketSearchResultTicketIds,
  selectCurrentTicketSearchQueryString,
  selectCurrentTicketSearchResultTicketCount,
} from 'selectors/search'
import { selectCurrentTicketId } from 'selectors/tickets/current/selectCurrentTicketId'

import { selectSelectedTicketIds } from 'selectors/ticket_list/base'
import {
  doToggleTicketSelection,
  doRangedTicketSelection,
  doListTopChanged,
} from 'actions/app'
import { doNavigateToTicket } from 'actions/navigation'
import { doOpenTicketPage } from 'actions/pages'
import {
  doMarkBulkSelectionMode,
  doCloseTicket,
  doOpenTicket,
} from 'actions/tickets'
import { doFetchCurrentFolderWithTicketsNextPage } from 'actions/tickets/doFetchCurrentFolderWithTicketsNextPage'
import { doLoadUnloadedSearchTickets } from 'actions/load'
import { doLoadIncompleteSearch } from 'actions/search'
import { selectQueryParams } from 'selectors/location'

import ConversationList from 'components/ConversationList'
import { snow } from 'util/ui/confetti'
import TicketListItem from './ListItem'
import TicketEmptyState from './EmptyState'

let firstLoad = true

const select = state => {
  return {
    hasLoaded: selectHasCurrentTicketSearchLoaded(state),
    canLoadMore: selectCurrentPage(state) < selectTotalPages(state),
    isLoadingMore: selectIsLoadingMoreTickets(state),
    isSelectionMode: selectIsTicketSelectionMode(state),
    isConversationsCurtainVisible: selectIsTicketsCurtainVisible(state),
    scrollY: selectPreviousScrollY(state),
    selectedConversationIds: selectSelectedTicketIds(state),
    conversationIds: selectCurrentTicketSearchResultTicketIds(state),
    currentConversationId: selectCurrentTicketId(state),
    ListItem: TicketListItem,
    EmptyState: TicketEmptyState,
    conversationListQueryKey: selectCurrentTicketSearchQueryString(state),
    totalCount: selectCurrentTicketSearchResultTicketCount(state),
    currentSortOrder: selectCurrentSortOrder(state),
  }
}

const onListLoaded = () => {
  return (dispatch, getState) => {
    dispatch(doLoadUnloadedSearchTickets())
    dispatch(doLoadIncompleteSearch())

    const { created } = selectQueryParams(getState())
    if (firstLoad && created === 'inbox') {
      snow({ duration: 1 })
    }
    firstLoad = false
  }
}

const onComponentDidMount = () => {
  return () => {
    firstLoad = true
  }
}

const perform = {
  markBulkSelectionMode: doMarkBulkSelectionMode,
  openConversationPage: doOpenTicketPage,
  toggleConversationSelection: doToggleTicketSelection,
  onRangedSelection: doRangedTicketSelection,
  loadMore: doFetchCurrentFolderWithTicketsNextPage,
  onTopChanged: doListTopChanged,
  closeConversation: doCloseTicket,
  openConversation: doOpenTicket,
  navigateToConversation: doNavigateToTicket,
  onListLoaded,
  onComponentDidMount,
}

export default connect(select, perform)(ConversationList)
