import Spinner from '@groovehq/internal-design-system/lib/components/Spinner/Spinner'
import Scroller from 'components/Scroller'

const MAX_HEIGHT = 240

const ScrollPane = ({ children, hasMore, loadMore, isLoadingMore }) => {
  return (
    <Scroller
      style={{ maxHeight: MAX_HEIGHT }}
      loadMore={loadMore}
      hasMore={hasMore}
      loadingMore={isLoadingMore}
      loader={<Spinner className="grui mt-14 mb-14" />}
    >
      {children}
    </Scroller>
  )
}

export default ScrollPane
