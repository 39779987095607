import { Flex, Label } from 'shared/ui'
import { Checkbox } from 'util/ui'

import ActionAccessWarning from '../../shared/ActionAccessWarning'
import {
  CheckboxColumn,
  LabelColumn,
  SettingRow,
  Tabs,
} from '../../shared/SettingsModal'

export default function ActionsTab({ active, formFields, onChange }) {
  return (
    <Tabs.Content active={active}>
      <p>
        Set actions to <strong>Re-open</strong> again if {app.t('a_ticket')} has
        been closed and/or <strong>Show</strong> actions in the conversation
        view.
      </p>
      <ActionAccessWarning />
      <Flex>
        <LabelColumn>
          <Label as="h4">&nbsp;</Label>
          <SettingRow>Issue summary changed</SettingRow>
          <SettingRow>Issue status changed</SettingRow>
          <SettingRow>Issue type changed</SettingRow>
          <SettingRow>Issue resolved</SettingRow>
          <SettingRow>Issue unresolved</SettingRow>
          <SettingRow>Issue due date changed</SettingRow>
          <SettingRow>Issue assignee changed</SettingRow>
          <SettingRow>Issue labels changed</SettingRow>
          <SettingRow>Issue project changed</SettingRow>
          <SettingRow>Issue deleted</SettingRow>
          <SettingRow>Comment added to issue</SettingRow>
        </LabelColumn>
        <CheckboxColumn>
          <Label as="h4">Re-open</Label>
          <SettingRow>
            <Checkbox
              checked={formFields['reopenOn.issueSummaryChanged']}
              name="reopenOn.issueSummaryChanged"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['reopenOn.issueStatusChanged']}
              name="reopenOn.issueStatusChanged"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['reopenOn.issueTypeChanged']}
              name="reopenOn.issueTypeChanged"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['reopenOn.issueResolved']}
              name="reopenOn.issueResolved"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['reopenOn.issueUnresolved']}
              name="reopenOn.issueUnresolved"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['reopenOn.issueDueDateChanged']}
              name="reopenOn.issueDueDateChanged"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['reopenOn.issueAssigneeChanged']}
              name="reopenOn.issueAssigneeChanged"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['reopenOn.issueLabelsChanged']}
              name="reopenOn.issueLabelsChanged"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['reopenOn.issueProjectChanged']}
              name="reopenOn.issueProjectChanged"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['reopenOn.issueDeleted']}
              name="reopenOn.issueDeleted"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['reopenOn.issueCommentAdded']}
              name="reopenOn.issueCommentAdded"
              onChange={onChange}
            />
          </SettingRow>
        </CheckboxColumn>
        <CheckboxColumn>
          <Label as="h4">Show</Label>
          <SettingRow>
            <Checkbox
              checked={formFields['showAction.issueSummaryChanged']}
              name="showAction.issueSummaryChanged"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['showAction.issueStatusChanged']}
              name="showAction.issueStatusChanged"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['showAction.issueTypeChanged']}
              name="showAction.issueTypeChanged"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['showAction.issueResolved']}
              name="showAction.issueResolved"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['showAction.issueUnresolved']}
              name="showAction.issueUnresolved"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['showAction.issueDueDateChanged']}
              name="showAction.issueDueDateChanged"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['showAction.issueAssigneeChanged']}
              name="showAction.issueAssigneeChanged"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['showAction.issueLabelsChanged']}
              name="showAction.issueLabelsChanged"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['showAction.issueProjectChanged']}
              name="showAction.issueProjectChanged"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['showAction.issueDeleted']}
              name="showAction.issueDeleted"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['showAction.issueCommentAdded']}
              name="showAction.issueCommentAdded"
              onChange={onChange}
            />
          </SettingRow>
        </CheckboxColumn>
      </Flex>
    </Tabs.Content>
  )
}
