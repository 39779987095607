import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { string, bool, oneOf } from 'prop-types'
import { doDeleteTagsV2 } from 'ducks/tags/actions'
import { SETTINGS_TAG_TABLE_ID } from 'ducks/tables/ids'
import { emptyArr } from 'util/arrays'
import EntityDeleteDrawer from '../EntityDelete'
import TagDeleteContent from './Content'

const TagDeleteDrawer = props => {
  const {
    queryId,
    deleteBtnText,
    open,
    drawerResourceId,
    drawerDeleteMode,
    onExit,
    onClose,
    onRemove,
  } = props
  const dispatch = useDispatch()

  const ids = useMemo(
    () => {
      return drawerResourceId ? drawerResourceId.split(',') : emptyArr
    },
    [drawerResourceId]
  )

  const handleOnRemove = useCallback(
    () => {
      const response = dispatch(
        doDeleteTagsV2({
          ids,
          queryId,
          mode: drawerDeleteMode,
        })
      )
      if (onRemove) onRemove()
      return response
    },
    [dispatch, ids, queryId, drawerDeleteMode, onRemove]
  )

  const closeOptions = useCallback(
    ({ isAfterDelete } = {}) => {
      const options = {}
      // We cant optimistically delete all the items in a query. To combat this
      // a batch update banner will be displayed at the top of the grid and we'll
      // simply reset any filters on the grid
      if (isAfterDelete && drawerDeleteMode === 'query') {
        options.removeQueryByKeys = [`${SETTINGS_TAG_TABLE_ID}-search`]
      }

      return options
    },
    [drawerDeleteMode]
  )

  const handleOnClose = useCallback(
    options => {
      onClose(closeOptions(options))
    },
    [onClose, closeOptions]
  )

  const handleOnExit = useCallback(
    options => {
      onExit(closeOptions(options))
    },
    [onExit, closeOptions]
  )

  const title =
    drawerDeleteMode === 'query' || ids.length > 1
      ? 'Delete tags'
      : 'Delete tag'

  return (
    <EntityDeleteDrawer
      {...props}
      onRemove={handleOnRemove}
      onClose={handleOnExit}
      tertiaryBtnText="Cancel"
      onCancel={handleOnClose}
      title={title}
      deleteBtnText={deleteBtnText}
      open={open}
      hardDelete
    >
      <TagDeleteContent
        ids={ids}
        queryId={queryId}
        deleteMode={drawerDeleteMode}
      />
    </EntityDeleteDrawer>
  )
}

TagDeleteDrawer.propTypes = {
  deleteBtnText: string,
  open: bool.isRequired,
  drawerDeleteMode: oneOf(['ids', 'query']),
  drawerResourceId: string,
  queryId: string,
}

TagDeleteDrawer.defaultProps = {
  deleteBtnText: 'Delete',
  drawerDeleteMode: 'ids',
  drawerResourceId: '',
  queryId: null,
}

export default TagDeleteDrawer
