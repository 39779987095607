import Drawer from '@groovehq/internal-design-system/lib/components/Drawer/UnmanagedDrawer'

const Footer = () => {
  return null
}
const SampleDrawer = ({ drawerResourceId, open, title, onExit }) => {
  const drawerTitle = title || 'Sample'
  return (
    <Drawer
      title={drawerTitle}
      open={open}
      onClose={onExit}
      footer={<Footer />}
    >
      Sample: {drawerResourceId}
    </Drawer>
  )
}

SampleDrawer.propTypes = {}

SampleDrawer.defaultProps = {}

export default SampleDrawer
