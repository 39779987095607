import { createActionTypeReducer } from 'util/reducers'

import {
  FETCH_SUBDOMAIN_AVAILABILITY_STARTED,
  FETCH_SUBDOMAIN_AVAILABILITY_SUCCESS,
  FETCH_SUBDOMAIN_AVAILABILITY_FAILED,
  FETCH_SLUG_AVAILABILITY_STARTED,
  FETCH_SLUG_AVAILABILITY_SUCCESS,
  FETCH_SLUG_AVAILABILITY_FAILED,
} from 'subapps/kb/actions'

const defaultState = {
  subdomain: null,
  slug: '',
  value: '',
  suggestion: null,
  isLoading: false,
}

export default createActionTypeReducer(
  {
    [FETCH_SUBDOMAIN_AVAILABILITY_STARTED]: draftState => {
      draftState.isLoading = true

      return draftState
    },
    [FETCH_SUBDOMAIN_AVAILABILITY_SUCCESS]: (draftState, action) => {
      const { kbSubdomainAvailability } = action.payload
      const { requestParameters } = action.meta
      const { available, suggestion } = kbSubdomainAvailability
      const { value } = requestParameters

      draftState.subdomain = available
      draftState.value = value
      draftState.suggestion = suggestion
      draftState.isLoading = false

      return draftState
    },
    [FETCH_SUBDOMAIN_AVAILABILITY_FAILED]: draftState => {
      draftState.isLoading = false

      return draftState
    },
    [FETCH_SLUG_AVAILABILITY_STARTED]: draftState => {
      draftState.isLoading = true

      return draftState
    },
    [FETCH_SLUG_AVAILABILITY_SUCCESS]: (draftState, action) => {
      const { kbSlugAvailability } = action.payload
      const { requestParameters } = action.meta
      const { available, suggestion } = kbSlugAvailability
      const { value } = requestParameters

      draftState.slug = available
      draftState.value = value
      draftState.suggestion = suggestion
      draftState.isLoading = false

      return draftState
    },
    [FETCH_SLUG_AVAILABILITY_FAILED]: draftState => {
      draftState.isLoading = false

      return draftState
    },
  },
  defaultState
)
