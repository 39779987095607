import { connect } from 'react-redux'
import { compose } from 'recompose'
import { redirect } from 'redux-first-router'

import { SETTINGS_INTEGRATIONS_PAGE } from 'subapps/settings/types'
import { selectLocationKind } from 'selectors/location'
import {
  selectCurrentProviderTab,
  selectCurrentIntegrationSettings,
} from 'ducks/integrations/selectors'
import { doUpdateIntegrationProviderSettings } from 'ducks/integrations/operations/doUpdateIntegrationProviderSettings'
import { doRemoveIntegration } from 'ducks/integrations/operations/doRemoveIntegration'
import { withFormFields } from 'shared/ui'

import InstallModal from './view'

export { default as CheckboxColumn } from './CheckboxColumn'
export { default as LabelColumn } from './LabelColumn'
export { default as SettingRow } from './SettingRow'
export { default as Tabs } from 'components/Tabs'

function select(state) {
  return {
    animateModal: selectLocationKind(state) !== 'load',
    initialValues: selectCurrentIntegrationSettings(state),
    tab: selectCurrentProviderTab(state),
  }
}

const perform = (dispatch, { id, provider, formFields, onChange }) => {
  function doCloseModal() {
    dispatch(redirect({ type: SETTINGS_INTEGRATIONS_PAGE }))
  }
  return {
    onChange: (event, { checked, name, value }) => {
      onChange(name, checked === undefined ? value : checked)
    },
    onClose: doCloseModal,
    doRemoveIntegration: () => {
      doCloseModal()
      dispatch(doRemoveIntegration(id))
    },
    doSubmitFormAndClose: () => {
      dispatch(doUpdateIntegrationProviderSettings(provider, formFields))
      doCloseModal()
    },
  }
}

export default compose(connect(select), withFormFields, connect(null, perform))(
  InstallModal
)
