import { connect } from 'react-redux'
import { redirect } from 'redux-first-router'

import { SETTINGS_INTEGRATIONS_PAGE } from 'subapps/settings/types'
import { selectLocationKind } from 'selectors/location'
import { selectDidError } from 'ducks/integrations/selectors'
import { selectCanUseFeature } from 'ducks/billing/selectors/features'
import { FEATURE_INBOX_PREMIUM_INTEGRATIONS } from 'ducks/billing/featureTypes'

import SettingsModal from './view'

function select(state) {
  return {
    animateModal: selectLocationKind(state) !== 'load',
    error: selectDidError(state),
    showPlanLimitation: !selectCanUseFeature(
      state,
      FEATURE_INBOX_PREMIUM_INTEGRATIONS
    ),
  }
}

const perform = dispatch => {
  return {
    onClose: function doCloseModal() {
      dispatch(redirect({ type: SETTINGS_INTEGRATIONS_PAGE }))
    },
  }
}

export default connect(select, perform)(SettingsModal)
