export const FIRST_LAST_NAME_CHANGE = 'onboarding/signup/FIRST_LAST_NAME_CHANGE'
export const EMAIL_CHANGE = 'onboarding/signup/EMAIL_CHANGE'
export const PASSWORD_CHANGE = 'onboarding/signup/PASSWORD_CHANGE'
export const COMPANY_CHANGE = 'onboarding/signup/COMPANY_CHANGE'
export const SUBDOMAIN_CHANGE = 'onboarding/signup/SUBDOMAIN_CHANGE'
export const SIGNUP_REQUEST = 'onboarding/signup/SIGNUP_REQUEST'
export const SIGNUP_SUCCESS = 'onboarding/signup/SIGNUP_SUCCESS'
export const SIGNUP_FAILURE = 'onboarding/signup/SIGNUP_FAILURE'
export const CHECK_DOMAIN_REQUEST = 'onboarding/signup/CHECK_DOMAIN_REQUEST'
export const CHECK_DOMAIN_SUCCESS = 'onboarding/signup/CHECK_DOMAIN_SUCCESS'
export const CHECK_DOMAIN_FAILURE = 'onboarding/signup/CHECK_DOMAIN_FAILURE'
