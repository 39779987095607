import { css } from '@emotion/react'

import { rem } from '@groovehq/internal-design-system/lib/util'

const field = css`
  max-width: 100%;
  margin-bottom: ${rem(26)};
  label {
    margin-bottom: ${rem(5)};
  }
`

const checkboxWrapper = css`
  display: flex;
  flex-direction: column;
  & > * {
    margin-top: ${rem(12)};
  }
`

const hidden = css`
  display: none;
`

export const styles = {
  field,
  hidden,
  checkboxWrapper,
}
