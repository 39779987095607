import { doGoogleOauth, doOfficeOauth } from 'actions/auth'
import { doBootstrapOnboarding } from 'subapps/onboarding/actions'
import { ACTIVE_SIGNUP_VARIATION } from 'subapps/onboarding/constants'
import { getClickIdFromStorage } from 'ducks/affiliates/util'
import { capture } from 'ducks/tracking/actions'
import { selectQueryParams } from 'selectors/location'
import { V6_EMAIL_PAGE, V6_COMPANY_PAGE } from '../pages'
import { doFinishOnboarding } from '../actions'

export function doEmailSignup() {
  return (dispatch, getState) => {
    const state = getState()
    const query = selectQueryParams(state)

    capture('user signup email or OAuth')
    capture('user signup email')
    dispatch({ type: V6_EMAIL_PAGE, query })
    return Promise.resolve()
  }
}

export function doGoogleSignup(options = {}) {
  return async dispatch => {
    const isShortSignup = ACTIVE_SIGNUP_VARIATION === 'short'
    capture('user signup email or OAuth')
    capture('user signup google')
    const success = await dispatch(
      doGoogleOauth(
        V6_COMPANY_PAGE,
        {
          signup: true,
          ignore_existing: !!options.ignore_existing,
          affiliate_click_id: getClickIdFromStorage(),
        },
        {
          rethrowErrorOnClosedPopup: true,
          disableRedirect: isShortSignup,
        }
      )
    )
    if (success && isShortSignup) {
      await dispatch(doBootstrapOnboarding({ disableRedirect: true }))
      dispatch(doFinishOnboarding())
    }
    return success
  }
}

export function doOfficeSignup(options = {}) {
  return async dispatch => {
    const isShortSignup = ACTIVE_SIGNUP_VARIATION === 'short'
    capture('user signup email or OAuth')
    const type = options.type || 'office'
    capture(`user signup ${type}`)
    const success = await dispatch(
      doOfficeOauth(
        V6_COMPANY_PAGE,
        {
          signup: true,
          ignore_existing: !!options.ignore_existing,
          affiliate_click_id: getClickIdFromStorage(),
        },
        {
          rethrowErrorOnClosedPopup: true,
          disableRedirect: isShortSignup,
          service: type === 'outlook' ? 'Outlook' : undefined,
        }
      )
    )
    if (success && isShortSignup) {
      await dispatch(doBootstrapOnboarding({ disableRedirect: true }))
      dispatch(doFinishOnboarding())
    }
    return success
  }
}
