import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Checkmark } from '@groovehq/internal-design-system/lib/assets/icons'
import * as logos from '@groovehq/internal-design-system/lib/assets/icons/logos'
import MessageCard from '@groovehq/internal-design-system/lib/components/MessageCard/MessageCard'
import {
  heading,
  text,
  list,
  paragraph,
} from '@groovehq/internal-design-system/lib/styles/elements'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import toast from '@groovehq/internal-design-system/lib/components/Toast'
import { useDrawer } from 'ducks/drawers/hooks'
import { doOpenDrawer } from 'ducks/drawers/actions'
import { buildDrawerQueryParam } from 'ducks/drawers/util'
import { useFetchIntegrationProvider } from 'ducks/integrations/hooks'
import { usePlanLimitationForDrawer } from 'ducks/integrations/hooks/usePlanLimitationForDrawer'
import { doClearIntegrationDraft } from 'ducks/integrations/operations/settings'
import { doOpenEditIntegrationDrawer } from 'ducks/integrations/operations'
import { doOauthIntegrationFlow } from 'ducks/integrations/operations/oauth'
import { AdminAccessDrawer } from 'subapps/settings/components/drawers/NoAccess'
import { ACCOUNT_PREFERENCES } from 'ducks/integrations/utils/credentialsType'
import {
  INSTALLATION_ACTION_TYPE_ACTIVATE,
  INSTALLATION_ACTION_TYPE_DRAWER_ACTION,
  INSTALLATION_ACTION_TYPE_OAUTH_LINK,
  INSTALLATION_ACTION_TYPE_EXTERNAL_LINK,
  INSTALLATION_ACTION_TYPE_EMAIL_MARKETING_OAUTH_LINK,
} from 'ducks/integrations/utils/installationActionType'
import { doInstallEmailMarketingIntegration } from 'ducks/integrations/emailMarketing/operations'
import { ERR_POPUP_BLOCKED, ERR_USER_CLOSED_POPUP } from 'util/oauth_window'
import { doTryFetchAccountUsageOnboardingForOnboarding } from 'ducks/accountPreferences/operations'
import { selectFeatureBasedOnboardingWorkflowData } from 'subapps/onboarding/selectors'

import { styles } from './styles'

const InstallDrawer = ({
  drawerResourceId: providerId,
  open,
  onClose,
  onExit,
  drawerId,
  queryId,
  ...rest
}) => {
  usePlanLimitationForDrawer(onClose)
  const dispatch = useDispatch()

  const {
    provider,
    isLoading,
    hasError,
    isMissing,
    isAlreadyInstalled,
  } = useFetchIntegrationProvider(providerId)

  const {
    features,
    featuresFooter,
    logo,
    description,
    name,
    installActionType,
    installActionTypeConfig = {},
    installStateConfig: {
      type: installStateConfigType,
      accountPreferenceKey,
    } = {},
  } =
    provider || {}

  const hasFeatures = !!features && !!features.length

  const [errorMessage, setErrorMessage] = useState(null)

  const { openDrawer: openInstallDrawer } = useDrawer(
    installActionTypeConfig.drawerType
  )

  const beforeDrawerClose = useCallback(
    () => {
      dispatch(doClearIntegrationDraft(providerId))
    },
    [providerId, dispatch]
  )

  const handleOnClose = useCallback(
    () => {
      beforeDrawerClose()
      onClose()
    },
    [onClose, beforeDrawerClose]
  )

  const handleOnExit = useCallback(
    () => {
      beforeDrawerClose()
      onExit()
    },
    [onExit, beforeDrawerClose]
  )

  const appLogo = useMemo(
    () => {
      const Logo = logos[logo]

      if (!Logo) return null

      return <Logo />
    },
    [logo]
  )

  const onboardingWorkflowData = useSelector(
    selectFeatureBasedOnboardingWorkflowData
  )
  const onInstall = useCallback(
    async () => {
      if (installActionType === INSTALLATION_ACTION_TYPE_DRAWER_ACTION) {
        openInstallDrawer(providerId, {
          additionalProps: {
            queryId,
          },
        })
      } else if (installActionType === INSTALLATION_ACTION_TYPE_OAUTH_LINK) {
        if (!installActionTypeConfig?.oauth) {
          throw new Error(
            'Invalid oauth config provided to installActionTypeConfig, please see doOauthIntegrationFlow for necessary config'
          )
        }
        dispatch(
          doOauthIntegrationFlow({
            providerId,
            ...installActionTypeConfig.oauth,
          })
        )
          .then(() => {
            if (installActionTypeConfig.onSuccessConfig?.drawerType) {
              dispatch(
                doOpenDrawer(
                  drawerId,
                  installActionTypeConfig.onSuccessConfig.drawerType,
                  installActionTypeConfig.onSuccessConfig.drawerResourceId,
                  {
                    clearStack: true,
                    query: {
                      ...buildDrawerQueryParam(
                        drawerId,
                        'drawerPostIntegrationInstall',
                        '1'
                      ),
                    },
                  }
                )
              )
            }
            dispatch(
              doTryFetchAccountUsageOnboardingForOnboarding(
                onboardingWorkflowData.integration?.usageKey
              )
            )
          })
          .catch(e => {
            if (e === ERR_POPUP_BLOCKED) {
              setErrorMessage(
                'Your browser is blocking the authentication popup window. Please allow popups for this site and try again.'
              )
            } else if (e !== ERR_USER_CLOSED_POPUP) {
              setErrorMessage(
                'There was an error setting up your account. Please try again or contact support.'
              )
            }
          })
      } else if (
        installActionType ===
        INSTALLATION_ACTION_TYPE_EMAIL_MARKETING_OAUTH_LINK
      ) {
        await dispatch(doInstallEmailMarketingIntegration(providerId))
        dispatch(doOpenEditIntegrationDrawer(providerId))
        dispatch(
          doTryFetchAccountUsageOnboardingForOnboarding(
            onboardingWorkflowData.integration?.usageKey
          )
        )
      } else if (
        [
          INSTALLATION_ACTION_TYPE_EXTERNAL_LINK,
          INSTALLATION_ACTION_TYPE_ACTIVATE,
        ].includes(installActionType)
      ) {
        // these typically do not open another drawer
        if (
          installStateConfigType === ACCOUNT_PREFERENCES &&
          accountPreferenceKey
        ) {
          // TODO: check if it's an account preference and then also make the post to update the account preference
          // eslint-disable-next-line no-console
          console.log('TODO: doSaveAccountPreference')
          // await doSaveAccountPreference
        }

        handleOnClose()
        // TODO: This might need to be moved to the save operation on _SUCCESS
        if (installActionType === INSTALLATION_ACTION_TYPE_ACTIVATE) {
          toast.notify({
            content: `${name} installed successfully`,
            type: 'positive',
          })
        }
      }
    },
    [
      dispatch,
      installActionType,
      installActionTypeConfig,
      openInstallDrawer,
      providerId,
      drawerId,
      queryId,
      handleOnClose,
      name,
      installStateConfigType,
      accountPreferenceKey,
      onboardingWorkflowData,
    ]
  )

  return (
    <AdminAccessDrawer
      {...rest}
      onClose={handleOnExit}
      isLoading={isLoading}
      isError={hasError}
      isNoResultFound={isMissing}
      size="narrow"
      title="Install a new app"
      open={open}
      footer={
        <ModalBtns
          saveBtnDisabled={
            isMissing || isAlreadyInstalled || isLoading || hasError
          }
          saveBtnText={
            installActionTypeConfig.installButtonText || 'Install app'
          }
          saveBtnAs={installActionTypeConfig.path ? 'a' : undefined}
          saveBtnHref={installActionTypeConfig.path}
          saveBtnTarget={installActionTypeConfig.target}
          tertiaryBtnText="Cancel"
          onClickTertiaryBtn={handleOnClose}
          onSave={onInstall}
        />
      }
    >
      <div className="grui flex flex-col h-100">
        <div className="grui flex items-center my-10">
          {appLogo && <div css={styles.imageBox}>{appLogo}</div>}
          <h3 css={heading.styles.h3}>{name}</h3>
        </div>
        <p
          className="grui mb-12"
          css={[paragraph.styles.preflight, text.styles.textNormal]}
        >
          {description}
        </p>
        {isAlreadyInstalled && (
          <div
            className="grui mb-12 mt-12"
            css={[
              text.styles.textNormal,
              text.styles.textNegative,
              text.styles.fontMedium,
            ]}
          >
            This app is already installed
          </div>
        )}
        {!isAlreadyInstalled &&
          hasFeatures && (
            <>
              <div
                className="grui mb-10"
                css={[text.styles.textNormal, text.styles.fontMedium]}
              >
                How it works
              </div>
              <ul
                css={[
                  list.styles.preflight,
                  text.styles.textDark,
                  styles.features,
                ]}
              >
                {features.map(({ id, content }) => (
                  <li key={id}>
                    <span css={styles.checkmark}>
                      <Checkmark />
                    </span>
                    <p css={paragraph.styles.preflight}>{content}</p>
                  </li>
                ))}
              </ul>
            </>
          )}
        <div className="grui mt-auto">
          {errorMessage && (
            <MessageCard type="negative">{errorMessage}</MessageCard>
          )}
          {!!featuresFooter?.content && (
            <MessageCard type={featuresFooter?.type} className="grui mt-8">
              {featuresFooter.content}
            </MessageCard>
          )}
        </div>
      </div>
    </AdminAccessDrawer>
  )
}

export default InstallDrawer
