import { useCallback } from 'react'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import { css } from '@emotion/react'

const cursorStyle = css`
  cursor: pointer;
`

export default function FormatCountColumn({ id, onClick, value }) {
  const handleOnClick = useCallback(
    () => {
      if (onClick) onClick(id)
    },
    [id, onClick]
  )

  if (value === 0) {
    return (
      <div css={[text.styles.textMediumDark, text.styles.italic]}>
        {app.t('N_canned_replies_short', { count: value })}…
      </div>
    )
  }
  return (
    <span css={[text.styles.textPrimary, cursorStyle]} onClick={handleOnClick}>
      {app.t('N_canned_replies_short', { count: value })}
    </span>
  )
}
