import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'
import { isEmpty } from 'util/objects'

const useOpenContact = ({ left } = {}) => {
  const currentUser = useSelector(selectCurrentUser)

  const openContact = useCallback(
    async () => {
      if (!window.groove?.widget) return
      if (left) {
        await window.groove.widget.updateSettings({
          iconPosition: 'left',
        })
      }
      window.groove.widget.pushRoute('/contact')
      window.groove.widget.open()
      if (isEmpty(currentUser)) return
      window.groove.widget.identifyContact('contact_email', currentUser.email)
      window.groove.widget.setContact({
        contact_first_name: currentUser.first_name,
        contact_last_name: currentUser.last_name,
      })
    },
    [currentUser, left]
  )

  return openContact
}

export default useOpenContact
