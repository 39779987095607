import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { string, bool, oneOf } from 'prop-types'
import { doDeleteCannedReplies } from 'ducks/cannedReplies/operations'
import EntityDeleteDrawer from '../EntityDelete'
import CannedReplyDeleteContent from './Content'

const CannedReplyDeleteDrawer = props => {
  const {
    queryId,
    deleteBtnText,
    open,
    drawerResourceId,
    drawerDeleteMode,
    onClose,
    onExit,
    onRemove,
  } = props
  const dispatch = useDispatch()

  const ids = useMemo(
    () => {
      return drawerResourceId.split(',')
    },
    [drawerResourceId]
  )
  const handleOnRemove = useCallback(
    () => {
      dispatch(
        doDeleteCannedReplies({
          ids,
          queryId,
          mode: drawerDeleteMode,
        })
      )
      if (onRemove) onRemove()
    },
    [dispatch, ids, queryId, drawerDeleteMode, onRemove]
  )

  const closeOptions = useCallback(
    ({ isAfterDelete } = {}) => {
      const options = {}
      // We cant optimistically delete all the items in a query. To combat this
      // a batch update banner will be displayed at the top of the grid and we'll
      // simply reset any filters on the grid
      if (isAfterDelete && drawerDeleteMode === 'query') {
        options.removeQueryByKeys = ['scr-categoryId', 'scr-search']
      }

      return options
    },
    [drawerDeleteMode]
  )

  const handleOnExit = useCallback(
    options => {
      onExit(closeOptions(options))
    },
    [onExit, closeOptions]
  )

  const handleOnClose = useCallback(
    options => {
      onClose(closeOptions(options))
    },
    [onClose, closeOptions]
  )

  const title =
    drawerDeleteMode === 'query' || ids.length > 1
      ? `Delete ${app.t('canned_replies')}`
      : `Delete ${app.t('canned_reply')}`

  return (
    <EntityDeleteDrawer
      {...props}
      onRemove={handleOnRemove}
      onClose={handleOnExit}
      tertiaryBtnText="Cancel"
      onCancel={handleOnClose}
      title={title}
      deleteBtnText={deleteBtnText}
      open={open}
      hardDelete
    >
      <CannedReplyDeleteContent
        ids={ids}
        queryId={queryId}
        deleteMode={drawerDeleteMode}
      />
    </EntityDeleteDrawer>
  )
}

CannedReplyDeleteDrawer.propTypes = {
  deleteBtnText: string,
  open: bool.isRequired,
  drawerDeleteMode: oneOf(['ids', 'query']),
  drawerResourceId: string,
  queryId: string,
}

CannedReplyDeleteDrawer.defaultProps = {
  deleteBtnText: `Delete`,
  drawerDeleteMode: 'ids',
  drawerResourceId: '',
  queryId: null,
}

export default CannedReplyDeleteDrawer
