import React, { useEffect, useState, useMemo, useCallback } from 'react'
import KeyboardNavigator from 'components/KeyboardNavigator'
import ListenToKeyboard from 'components/ListenToKeyboard'
import Scroller from 'components/Scroller'
import { ENTER, TAB } from 'constants/keys'
import { throttle } from 'util/functions'
import Item from './Item'
import Header from './Header'
import Search from './Search'
import useShowHeaderShadow from './useShowSearchBorder'
import { styles } from './styles'
import useSearchItems from './useSearchItems'
import Loader from './Loader'
import Empty from './Empty'

const KEYBOARD_NAVIGATOR_KEYS = { select: [ENTER, TAB] }

const MenuWithSearch = ({
  focusElementRef,
  children,
  search,
  className,
  onScroll,
  onApply,
}) => {
  const [focusElement, setFocusElement] = useState()

  const throttledHandleScroll = useMemo(
    () => {
      return throttle(scrollTop => {
        onScroll(scrollTop)
      }, 100)
    },
    [onScroll]
  )

  const handleScroll = useCallback(
    e => {
      const scrollTop = e.scrollTop >= 0 ? e.scrollTop : e.target.scrollTop
      throttledHandleScroll(scrollTop)
    },
    [throttledHandleScroll]
  )

  useEffect(
    () => {
      if (focusElementRef?.current) {
        setFocusElement(focusElementRef.current)
      } else if (focusElement) {
        focusElement.focus()
      }
    },
    [focusElementRef, focusElement]
  )

  return (
    <ListenToKeyboard hijack={ListenToKeyboard.DROPDOWN} onShiftEnter={onApply}>
      <KeyboardNavigator
        activeKey={search}
        keys={KEYBOARD_NAVIGATOR_KEYS}
        focusElement={focusElement}
        preventClearActiveOnSelect
      >
        <Scroller
          css={styles.container}
          onScroll={onScroll ? handleScroll : undefined}
        >
          <div
            role="menu"
            className={className}
            ref={focusElementRef?.current ? undefined : setFocusElement}
            tabIndex={0}
          >
            {children}
          </div>
        </Scroller>
      </KeyboardNavigator>
    </ListenToKeyboard>
  )
}

MenuWithSearch.Item = Item
MenuWithSearch.Header = Header
MenuWithSearch.Search = Search
MenuWithSearch.styles = styles
MenuWithSearch.useShowHeaderShadow = useShowHeaderShadow
MenuWithSearch.useSearchItems = useSearchItems
MenuWithSearch.Loader = Loader
MenuWithSearch.Empty = Empty

export default MenuWithSearch
