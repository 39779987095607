import React from 'react'
import { useSelector } from 'react-redux'
import { selectAvailableFeaturesByPricingIds } from 'ducks/billing/selectors/selectAvailableFeaturesByPricingIds'
import { usePricingChangeInfo } from 'ducks/billing/hooks'
import FeaturesView from './Features'
import RemovedView from './Removed'

const Features = ({
  newPricingIds: inputNewPricingIds,
  newBillingCycle,
  showOnlyRemoved,
  className,
}) => {
  const { planLimitsExceeded } = usePricingChangeInfo(
    inputNewPricingIds,
    newBillingCycle
  )
  const newFeatures = useSelector(state =>
    selectAvailableFeaturesByPricingIds(state, inputNewPricingIds)
  )

  if (!planLimitsExceeded) return null

  if (showOnlyRemoved) {
    return <RemovedView {...newFeatures} className={className} />
  }
  return <FeaturesView {...newFeatures} className="grui mt-10" />
}

export default Features
