import React, { useCallback } from 'react'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import { useDispatch, useSelector } from 'react-redux'
import { doDeleteKnowledgeBase } from 'subapps/kb/actions/knowledge_bases'
import EntityDelete from 'subapps/settings/components/drawers/EntityDelete'
import { selectCurrentKnowledgeBase } from 'subapps/kb/selectors/knowledge_bases'

const KBDelete = ({ open, onClose, onExit, onRemove }) => {
  const dispatch = useDispatch()
  const currentKB = useSelector(selectCurrentKnowledgeBase)

  const handleRemove = useCallback(
    async () => {
      const response = await dispatch(doDeleteKnowledgeBase())
      if (onRemove) onRemove()
      return response
    },
    [dispatch, onRemove]
  )

  if (!currentKB) return null

  return (
    <div>
      <EntityDelete
        onRemove={handleRemove}
        onClose={onClose}
        onExit={onExit}
        onCancel={onClose}
        deleteBtn
        tertiaryBtnText="Cancel"
        open={open}
        skipProceedQuestion
        hardDelete
      >
        <div css={text.styles.textNormal}>
          {`You are about to delete the knowledge "${currentKB.title ||
            ''}". This action is permanent and cannot be
          undone.`}
        </div>
      </EntityDelete>
    </div>
  )
}

export default KBDelete
