import * as yup from 'yup'

export const BULK_FORM_SCHEMA = yup.object().shape({
  bulkEmails: yup
    .string()
    .trim()
    .lowercase()
    .required(`${app.t('Agents')} emails are required`),
  role: yup.string().required('Role is required'),
})

export const AGENT_DESCRIPTIONS = {
  agent: {
    short:
      'Agents can view specific Inboxes, create and edit Knowledge Bases, view Integrations, but can not change any settings or manage Rules.',
    long:
      'An Agent only has access to specified Inboxes and can not change the Inbox settings. They can create and edit articles in the Knowledge Base but cannot change any settings. Additionally, Agents cannot create other Agents or access their profiles. They can view third-party integrations on the Apps pages but are unable to install them. Managing Rules, Custom Fields, or Instant Replies is also beyond their capabilities.',
  },
  admin: {
    short:
      'Admins can access all settings and data, except account owner details.',
    long:
      'Administrators have access to everything. An Administrator can access the Company settings, every Inbox and its settings, every Knowledge Base and its settings, and every User and their settings except the Account Owner.',
  },
  viewer: {
    short:
      'Lite users can collaborate internally with Private Notes, access Inboxes, view Knowledge Bases and Reporting, but can not reply to conversations.',
    long:
      "A lite user can view information and collaborate in Groove with your team, but not interact directly with your customers. Lite users are available on Plus and Pro plans only. They can access and leave notes on all conversations in an Inbox to which they've been granted permission, but cannot reply to customers themselves or take other actions on those conversations. They can view all Knowledge Base articles, but cannot edit them, and can view Reporting and Customers, but cannot edit or delete any data.",
  },
}

export const AGENT_DROPDOWN_MENU = [
  {
    key: 'admin',
    title: 'Admin',
  },
  {
    key: 'agent',
    title: 'Agent',
  },
  {
    key: 'viewer',
    title: 'Lite',
  },
]
