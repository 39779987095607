import { css } from '@emotion/react'

const table = theme => css`
  overflow: hidden;
  tbody tr td:first-of-type {
    font-weight: ${theme.fontWeight.medium};
  }
  tbody tr td:last-of-type > * {
    opacity: 1;
    visibility: visible;
    color: ${theme.color.monochrome.mediumDark};
    padding-right: 0;
  }
`

const unsetButton = css`
  opacity: 0;
  td:hover & {
    opacity: 1;
  }
`

export const styles = {
  table,
  unsetButton,
}
