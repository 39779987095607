import React from 'react'
import { Dropdown } from 'shared/ui'
import Trigger from '../shared/Trigger'

// The 'shared/ui' Dropdown always re-renders, and the WrappedDropdown is here
// to prevent the constant re-render when not necessary
export default class WrappedDropdown extends React.PureComponent {
  render() {
    const {
      className,
      options,
      open,
      onClose,
      onOpen,
      trigger = <Trigger />,
    } = this.props

    return (
      <Dropdown
        className={className}
        header="Other"
        icon={null}
        open={open}
        options={options}
        selectOnBlur={false}
        trigger={trigger}
        onClose={onClose}
        onOpen={onOpen}
        lazyLoad
      />
    )
  }
}
