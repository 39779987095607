export const ports = [
  {
    key: '587',
  },
  {
    key: '465',
  },
  {
    key: '25',
  },
  {
    key: '2525',
  },
]

export const authTypes = [
  {
    id: 'plain',
    name: 'PLAIN',
  },
  {
    id: 'login',
    name: 'LOGIN',
  },
  {
    id: 'cram_md5',
    name: 'CRAM-MD5',
  },
  {
    id: 'digest_md5',
    name: 'DIGEST-MD5',
  },
]
