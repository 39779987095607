import React, { useMemo } from 'react'
import CellWithButton from '@groovehq/internal-design-system/lib/components/Table/CellWithButton/CellWithButton'
import Table from '@groovehq/internal-design-system/lib/components/Table/Table'
import { propFunc } from 'util/functions'
import CellWithAgentAvatar from '../CellWithAgentAvatar'

import FormatCountColumn from './formatCountColumn'

const { RowControls } = Table

const useColumns = ({ currentUser, onCannedReplyCategoryClick }) => {
  const columns = useMemo(
    () => {
      const currentUserIsManager = ['admin', 'owner'].includes(currentUser.role)

      const formatName = ({ row: { original }, value, onEdit }) => {
        return (
          <CellWithButton onClick={propFunc(onEdit, original.id)}>
            {value}
          </CellWithButton>
        )
      }

      const formatCount = ({
        row: {
          original: { id },
        },
        value,
      }) => {
        return (
          <FormatCountColumn
            id={id}
            onClick={onCannedReplyCategoryClick}
            value={value}
          />
        )
      }

      const formatCreator = ({ value: creatorId }) => {
        return <CellWithAgentAvatar agentId={creatorId} />
      }

      const createRowControls = ({ value, deleteData, onEdit }) => (
        <RowControls
          value={value}
          deleteData={currentUserIsManager && deleteData}
          onEdit={onEdit}
        />
      )

      return [
        {
          Header: 'NAME',
          accessor: 'name',
          width: '30%',
          manualSortBy: true,
          Cell: formatName,
        },
        {
          Header: `COUNT`,
          accessor: 'total',
          Cell: formatCount,
          width: '40%',
          manualSortBy: true,
        },
        {
          Header: 'CREATOR',
          accessor: 'creator_id',
          Cell: formatCreator,
          id: 'creator_id',
          width: '22%',
          manualSortBy: true,
        },
        {
          Header: '',
          accessor: 'id',
          Cell: createRowControls,
          disableSortBy: true,
          width: '8%',
        },
      ]
    },
    [currentUser?.role, onCannedReplyCategoryClick]
  )

  return columns
}

export default useColumns
