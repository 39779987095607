import { isBlank } from './strings'

export const labelObjFor = user => {
  if (!user) return {}
  const { name, email } = user
  if (isBlank(name)) return { name: email }
  if (name === email) return { name: email }
  return { name, email }
}

export const getDisplayLabel = (user, allUsers) => {
  if (!user || (isBlank(user.name) && isBlank(user.email))) {
    return 'n/a'
  }

  const { name, email } = labelObjFor(user)

  if (!email) return name

  const isOtherUserWithSameName =
    allUsers &&
    allUsers
      .map(labelObjFor)
      .find(otherUser => otherUser.name === name && otherUser.email !== email)

  if (!isOtherUserWithSameName) return name

  return `${name} <${email}>`
}
