import { css } from '@emotion/react'

import { rem } from '@groovehq/internal-design-system/lib/util'

const imageBox = theme => css`
  width: ${rem(56)};
  height: ${rem(56)};
  border: 1px solid ${theme.color.monochrome.medium};
  border-radius: ${rem(6)};
  padding: ${rem(8)};
  margin-right: ${rem(16)};

  svg {
    height: 100%;
    width: 100%;
  }
`

const features = css`
  li {
    display: flex;

    & > p {
      margin-top: ${rem(2)};
    }
  }
  li:not(:last-of-type) {
    margin-bottom: ${rem(11)};
  }
`

const checkmark = theme => css`
  color: ${theme.color.primary.brand};
  margin-right: ${rem(8)};
  display: flex;
  svg {
    height: ${rem(24)};
    width: ${rem(24)};
  }
`

const appList = theme => css`
  border: 1px solid ${theme.color.monochrome.medium};
  background-color: ${theme.color.monochrome.superLight};

  & > li {
    border: 1px solid ${theme.color.monochrome.medium};
    background-color: ${theme.color.monochrome.white};
    padding: ${rem(8)} ${rem(12)};
    display: flex;
    align-items: center;

    button {
      background-color: ${theme.color.primary.negativeAlt};
      border-color: ${theme.color.primary.negativeAlt};
      flex-shrink: 0;

      &:hover {
        background-color: ${theme.color.negativeAlt['600']};
        border-color: ${theme.color.negativeAlt['600']};
      }

      &:active {
        background-color: ${theme.color.negativeAlt['700']};
        border-color: ${theme.color.negativeAlt['700']};
      }
    }
    p {
      flex-grow: 1;
      padding: 0 ${rem(8)} 0 ${rem(16)};
    }
  }

  & > li:not(:last-of-type) {
    margin-bottom: ${rem(8)};
  }
`

const services = theme => css`
  display: inline-flex;
  align-items: center;
  color: ${theme.color.monochrome.mediumDark};

  span {
    width: ${rem(32)};
    height: ${rem(32)};
    border: 1px solid ${theme.color.monochrome.medium};
    background-color: ${theme.color.monochrome.light};
    border-radius: ${rem(4)};
  }

  svg {
    width: ${rem(20)};
    height: ${rem(20)};
    margin: 0 ${rem(2)};
  }
`

export const styles = {
  imageBox,
  features,
  checkmark,
  appList,
  services,
}
