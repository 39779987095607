import cn from 'classnames'
import React, { Fragment } from 'react'
import LegacyLink from 'components/LegacyLink'
import PremiumIntegrationsPaywallButton from '../../../BillingSidebars/PaywallButton'

export default function AuthorizeModal({
  fluid,
  message,
  params,
  path,
  primary = false,
  text = 'Connect',
  isAllowedAccessToFeature,
  disabled = false,
}) {
  return (
    <Fragment>
      {!isAllowedAccessToFeature && (
        <PremiumIntegrationsPaywallButton text={text} />
      )}
      {isAllowedAccessToFeature && (
        <LegacyLink
          as="button"
          className={cn('ui button positive', {
            fluid,
          })}
          modalMessage={message}
          params={params}
          path={path}
          primary={primary}
          disabled={disabled}
        >
          {text}
        </LegacyLink>
      )}
    </Fragment>
  )
}
