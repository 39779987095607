// filter condition operator functions

export const eq = (a, b) => a === b
export const notEq = (a, b) => !eq(a, b)
export const lt = (a, b) => a < b
export const gt = (a, b) => a > b
export const lteq = (a, b) => a <= b
export const gteq = (a, b) => a >= b

export const contains = (arr = [], str = '') =>
  str && arr.findIndex(elem => elem.toLowerCase() === str.toLowerCase()) > -1

export const doesNotContain = (arr = [], str) => !contains(arr, str)

export const getOperatorFn = operator => {
  switch (operator) {
    case 'eq':
      return eq
    case 'not_eq':
      return notEq
    case 'lt':
      return lt
    case 'lteq':
      return lteq
    case 'gt':
      return gt
    case 'gteq':
      return gteq
    case 'contains':
      return contains
    case 'does_not_contain':
      return doesNotContain
    default:
      return null
  }
}
