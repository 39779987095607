import { useSelector } from 'react-redux'
import { selectCustomerProp } from 'ducks/customers/selectors'

import SanitizedHTML from 'shared/components/ui/SanitizedHTML'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import {
  ArrowLeft,
  Close,
  FileText,
} from '@groovehq/internal-design-system/lib/assets/icons'

import { styles } from './styles'

const SelectedTicket = ({ ticket, onDelete }) => {
  const { bodyAuthor, bodyType } = ticket
  const isNote = bodyType === 'internal'

  const customer = useSelector(state =>
    selectCustomerProp(state, ticket.customerId, 'labelFullOrYou')
  )
  const bodyHasAgentAuthor = bodyAuthor && bodyAuthor.type === 'Agent'

  return (
    <div
      className="grui grid justify-between"
      css={[styles.wrapper, onDelete && styles.clickableWrapper]}
    >
      <div className="grui flex flex-col w-100">
        <span className="grui mb-4" css={styles.customer}>
          {customer}
        </span>

        <span className="grui truncate">
          {ticket.titleLabel || 'There is no subject for this message'}
        </span>

        <div
          className="grui flex items-center mt-4"
          css={[styles.bottomContent, isNote && styles.bottomNote]}
        >
          {isNote && <FileText className="grui mr-2" />}

          {bodyHasAgentAuthor && (
            <span className="grui flex items-center">
              {bodyAuthor.label}
              {isNote ? ':' : <ArrowLeft size="large" css={styles.arrow} />}
            </span>
          )}

          <SanitizedHTML
            className="grui truncate"
            html={ticket.body}
            css={isNote && styles.note}
          />
        </div>
      </div>

      <div
        className="grui flex flex-col justify-between whitespace-nowrap ml-auto text-right"
        css={styles.rightSide}
      >
        <span css={styles.date}>{ticket.updatedAtInHuman}</span>
        <span
          className="grui flex justify-center ml-auto py-3"
          css={styles.messageCount}
        >
          {ticket.message_count}
        </span>
        <span css={styles.ticketNumber}>#{ticket.id}</span>
      </div>

      {onDelete && (
        <Button
          type="icon"
          size="small"
          className="grui ml-4"
          css={styles.deleteButton}
          onClick={onDelete}
        >
          <Close />
        </Button>
      )}
    </div>
  )
}

export default SelectedTicket
