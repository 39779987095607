import debug, { logError } from 'util/debug'
import {
  STORAGE_KEY_CAN_LOG_REDUX_ACTIONS,
  STORAGE_KEY_REDUX_ACTIONS,
  STORAGE_KEY_REALTIME_REDUX_ACTIONS,
} from 'constants/performance_sim/storage_keys'
import storage from 'util/storage'

const logAction = (storageKey, action) => {
  try {
    const canLog = storage.get(STORAGE_KEY_CAN_LOG_REDUX_ACTIONS) === true

    if (canLog) {
      const actions = storage.get(storageKey) || []
      actions.push(action)

      storage.set(storageKey, actions)
      debug('logAction', action)
    }
  } catch (e) {
    logError(e)
  }
}

export const logRealtimeAction = action => {
  try {
    if (!action) {
      return
    }
    const value = typeof action === 'object' ? JSON.stringify(action) : action

    logAction(STORAGE_KEY_REALTIME_REDUX_ACTIONS, value)
  } catch (e) {
    logError(e)
  }
}

export const logReduxActionMiddleware = () => next => action => {
  const returnedValue = next(action)

  logAction(STORAGE_KEY_REDUX_ACTIONS, action.type)

  return returnedValue
}
