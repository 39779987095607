import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'

const content = css`
  width: ${rem(400)};
`

export const styles = {
  content,
}
