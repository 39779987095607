import React, { useCallback, useMemo, useRef, useState } from 'react'
import Dropdown from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import cn from 'classnames'
import Link from 'redux-first-router-link'
import { useController } from 'react-hook-form'
import NumberField from '@groovehq/internal-design-system/lib/components/NumberField/NumberField'
import Play from '@groovehq/internal-design-system/lib/assets/icons/Play'
import {
  button,
  text,
} from '@groovehq/internal-design-system/lib/styles/elements'
import { SUI } from 'shared/ui'
import DateTimeInput from 'shared/components/ui/DateTimeInput'
import {
  dateToNearestHalfHour,
  strftime,
  toDate,
  DATE_UNITS_SECONDS,
} from 'util/date'
import { FEATURE_INBOX_RULES_TIER_2 } from 'ducks/billing/featureTypes'
import { INBOX } from 'ducks/billing/productTypes'
import { SETTINGS_BUSINESS_HOURS_PAGE } from 'subapps/settings/types'
import {
  snoozeV2,
  calendarV2 as Calendar,
  business as Business,
} from 'assets/icons/groove/v2'
import { isDefined, isNullOrUndefined } from 'util/nullOrUndefinedChecks'
import DropdownMenu from '../../DropdownMenu'
import MenuWithSearch, {
  useShowHeaderShadow,
  useSearchItems,
  styles as menuWithSearchStyles,
} from '../../MenuWithSearch'
import AutomationItem from '../../AutomationItem'
import { styles } from './styles'
import { getOnDayOptions, getNameById, getBeforeMonthEndOptions } from './util'

const TO_SETTINGS_BUSINESS_HOURS_PAGE = {
  type: SETTINGS_BUSINESS_HOURS_PAGE,
}

const sectionTitles = {
  time: {
    title: 'Wait some time',
    icon: snoozeV2,
  },
  businessHours: {
    title: (
      <>
        Business hours&nbsp;
        <span
          css={[
            text.styles.text2Xs,
            text.styles.italic,
            text.styles.fontNormal,
          ]}
        >
          (manage&nbsp;<Link
            to={TO_SETTINGS_BUSINESS_HOURS_PAGE}
            css={button.styles.link}
            className="grui underline"
          >
            business hours
          </Link>)
        </span>
      </>
    ),
    icon: ({ className }) => (
      <Business className={cn(className, 'section-title-icon-lg')} />
    ),
  },
  days: {
    title: 'Days',
    icon: ({ className }) => (
      <Calendar className={cn(className, 'section-title-icon-lg')} />
    ),
  },
}

export const scheduleTypeSections = {
  time: [
    {
      id: 'immediately',
      name: 'Immediately',
    },
    {
      id: 'after',
      name: 'Wait a set amount of time',
      product: INBOX,
      feature: FEATURE_INBOX_RULES_TIER_2,
    },
    {
      id: 'after_business_hours',
      name: 'Wait for a set time within business hours',
      product: INBOX,
      feature: FEATURE_INBOX_RULES_TIER_2,
    },
  ],
  businessHours: [
    {
      id: 'business_hours_start',
      name: 'When business hours start',
      product: INBOX,
      feature: FEATURE_INBOX_RULES_TIER_2,
    },
    {
      id: 'business_hours_end',
      name: 'When business hours end',
      product: INBOX,
      feature: FEATURE_INBOX_RULES_TIER_2,
    },
    {
      id: 'after_business_hours_start',
      name: 'Time after business hours start',
      product: INBOX,
      feature: FEATURE_INBOX_RULES_TIER_2,
    },
    {
      id: 'before_business_hours_end',
      name: 'Time before business hours end',
      product: INBOX,
      feature: FEATURE_INBOX_RULES_TIER_2,
    },
  ],
  days: [
    {
      id: 'on_day',
      name: 'When the day of the month is',
      product: INBOX,
      feature: FEATURE_INBOX_RULES_TIER_2,
    },
    {
      id: 'on_next',
      name: 'When the upcoming weekday is',
      product: INBOX,
      feature: FEATURE_INBOX_RULES_TIER_2,
    },
    {
      id: 'before_month',
      name: 'Days before month end is',
      product: INBOX,
      feature: FEATURE_INBOX_RULES_TIER_2,
    },
  ],
}

export const scheduleTypes = Object.values(scheduleTypeSections).flat(1)

const afterUnits = [
  {
    id: 'seconds',
    name: 'Seconds',
  },
  {
    id: 'minutes',
    name: 'Minutes',
  },
  {
    id: 'hours',
    name: 'Hours',
  },
  {
    id: 'days',
    name: 'Days',
  },
  {
    id: 'weeks',
    name: 'Weeks',
  },
]

const daysOfWeek = [
  {
    id: '1',
    name: 'Monday',
  },
  {
    id: '2',
    name: 'Tuesday',
  },
  {
    id: '3',
    name: 'Wednesday',
  },
  {
    id: '4',
    name: 'Thursday',
  },
  {
    id: '5',
    name: 'Friday',
  },
  {
    id: '6',
    name: 'Saturday',
  },
  {
    id: '0',
    name: 'Sunday',
  },
]

const onDayOptions = getOnDayOptions()
const beforeMonthEndOptions = getBeforeMonthEndOptions()

function getUnitAndValue(seconds, defaultUnit = 'seconds') {
  if (isNullOrUndefined(seconds)) {
    return {
      unit: defaultUnit,
      value: seconds,
    }
  } else if (seconds && seconds % DATE_UNITS_SECONDS.weeks === 0) {
    return {
      unit: 'weeks',
      value: seconds / DATE_UNITS_SECONDS.weeks,
    }
  } else if (seconds && seconds % DATE_UNITS_SECONDS.days === 0) {
    return {
      unit: 'days',
      value: seconds / DATE_UNITS_SECONDS.days,
    }
  } else if (seconds && seconds % DATE_UNITS_SECONDS.hours === 0) {
    return {
      unit: 'hours',
      value: seconds / DATE_UNITS_SECONDS.hours,
    }
  } else if (seconds && seconds % DATE_UNITS_SECONDS.minutes === 0) {
    return {
      unit: 'minutes',
      value: seconds / DATE_UNITS_SECONDS.minutes,
    }
  }
  return {
    unit: 'seconds',
    value: seconds,
  }
}

const ScheduleSection = ({ control }) => {
  // this is just to hold the selected unit value incase unit is selected before value in inputted
  const [selectedUnit, setSelectedUnit] = useState('seconds')
  const {
    field: { value: scheduleTypeValue, onChange: onScheduleTypeChange },
  } = useController({
    name: 'schedule_type',
    control,
  })

  const {
    field: {
      name: secondsName,
      value: secondsValue,
      onChange: onSecondsChange,
    },
  } = useController({
    name: 'schedule_settings.seconds',
    control,
  })

  const { unit: afterUnit, value: afterValue } = getUnitAndValue(
    secondsValue,
    selectedUnit
  )

  const searchRef = useRef()
  const [parameterMenuVisible, setParameterMenuVisible] = useState(false)
  const [filteredScheduleTypes, setfilteredScheduleTypes] = useState(
    scheduleTypeSections
  )
  const [search, setSearch] = useState('')
  const { handleScroll, shouldShowHeaderShadow } = useShowHeaderShadow(
    parameterMenuVisible
  )

  useSearchItems({
    data: scheduleTypeSections,
    search,
    onSearchResultChange: setfilteredScheduleTypes,
  })

  const handleOnSecondsChange = useCallback(
    (value, unit) => {
      if (
        isDefined(value) &&
        isDefined(unit) &&
        isDefined(DATE_UNITS_SECONDS[unit])
      ) {
        onSecondsChange(value * DATE_UNITS_SECONDS[unit])
      }
    },
    [onSecondsChange]
  )

  const handleOnAfterValueChange = useCallback(
    e => handleOnSecondsChange(e.target.value, afterUnit),
    [afterUnit, handleOnSecondsChange]
  )

  const handleOnAfterUnitChange = useCallback(
    unit => {
      setSelectedUnit(unit)
      handleOnSecondsChange(afterValue, unit)
    },
    [afterValue, handleOnSecondsChange]
  )

  const {
    field: { value: dayValue, onChange: onDayChange },
  } = useController({
    name: 'schedule_settings.day',
    control,
  })

  const {
    field: { value: dayOfWeekValue, onChange: onDayOfWeekChange },
  } = useController({
    name: 'schedule_settings.day_of_week',
    control,
  })

  const {
    field: { value: daysValue, onChange: onDaysChange },
  } = useController({
    name: 'schedule_settings.days',
    control,
  })

  const {
    field: { name: timeName, value: timeValue, onChange: onTimeChange },
  } = useController({
    name: 'schedule_settings.time',
    control,
  })

  const handleOnTimeChange = useCallback(
    date => {
      onTimeChange(strftime('%HO:%M', date))
    },
    [onTimeChange]
  )

  const handleOpenParameterMenu = useCallback(
    () => {
      if (!parameterMenuVisible) {
        setParameterMenuVisible(true)
      }
    },
    [parameterMenuVisible]
  )

  const handleChangeSearch = useCallback(({ target }) => {
    setSearch(target.value)
  }, [])

  const handleScheduleTypeChange = useCallback(
    value => {
      onScheduleTypeChange(value)
      setParameterMenuVisible(false)

      // Set default value for some types
      switch (value) {
        case 'on_day':
          onDayChange(onDayOptions[0].id)
          break
        case 'before_month':
          onDaysChange(beforeMonthEndOptions[0].id)
          break
        case 'on_next':
          onDayOfWeekChange(daysOfWeek[0].id)
          break
        default:
      }
    },
    [onScheduleTypeChange, onDayChange, onDaysChange, onDayOfWeekChange]
  )

  const scheduleType = useMemo(
    () => getNameById(scheduleTypes, scheduleTypeValue),
    [scheduleTypeValue]
  )

  const afterUnitName = useMemo(() => getNameById(afterUnits, afterUnit), [
    afterUnit,
  ])

  const dayOfWeekValueName = useMemo(
    () => getNameById(daysOfWeek, dayOfWeekValue),
    [dayOfWeekValue]
  )

  const timeValueDate = useMemo(
    () => dateToNearestHalfHour(toDate(timeValue)),
    [timeValue]
  )

  const dayValueName = useMemo(() => getNameById(onDayOptions, dayValue), [
    dayValue,
  ])

  const daysValueName = useMemo(
    () => getNameById(beforeMonthEndOptions, daysValue),
    [daysValue]
  )

  const sectionIcon = useMemo(
    () => {
      const sectionKey = Object.keys(scheduleTypeSections).find(key => {
        return scheduleTypeSections[key].some(
          item => item.id === scheduleTypeValue
        )
      })
      return sectionTitles[sectionKey]?.icon
    },
    [scheduleTypeValue]
  )

  const header = useMemo(
    () => (
      <MenuWithSearch.Search
        placeholder="Search..."
        value={search}
        onChange={handleChangeSearch}
        ref={searchRef}
        shouldFocus={parameterMenuVisible}
      />
    ),
    [search, handleChangeSearch, parameterMenuVisible]
  )

  const parametersMenu = useMemo(
    () => (
      <MenuWithSearch
        focusElementRef={searchRef}
        search={search}
        className="grui pt-3 pb-8"
        onScroll={handleScroll}
      >
        {Object.keys(filteredScheduleTypes)
          ?.filter(key => filteredScheduleTypes[key]?.length)
          .map(key => {
            const { title, icon } = sectionTitles[key] || {}
            return (
              <div key={key}>
                <MenuWithSearch.Header icon={icon}>
                  {title}
                </MenuWithSearch.Header>
                {filteredScheduleTypes[key]?.map(
                  ({ id, name, tooltip, feature, product }) => (
                    <MenuWithSearch.Item
                      key={id}
                      itemKey={id}
                      tooltip={tooltip}
                      onSelect={handleScheduleTypeChange}
                      selectedKey={scheduleTypeValue}
                      visible={parameterMenuVisible}
                      feature={feature}
                      product={product}
                      checkProductFeatureLimit
                      type="feature"
                    >
                      {name}
                    </MenuWithSearch.Item>
                  )
                )}
              </div>
            )
          })}
      </MenuWithSearch>
    ),
    [
      filteredScheduleTypes,
      handleScheduleTypeChange,
      scheduleTypeValue,
      parameterMenuVisible,
      search,
      handleScroll,
    ]
  )

  return (
    <AutomationItem
      icon={sectionIcon}
      onClick={handleOpenParameterMenu}
      css={styles.scheduleContainer}
      shouldWrapInnerItems={false}
    >
      <Dropdown
        header={header}
        overlay={parametersMenu}
        css={[
          menuWithSearchStyles.dropdownContainer,
          shouldShowHeaderShadow && menuWithSearchStyles.showHeaderShadow,
        ]}
        onVisibleChange={setParameterMenuVisible}
        visible={parameterMenuVisible}
        autoHeight
        // Don't let dropdown's arrow event handlers handle the keyboard events
        // MenuWithSearch will do it
        isNavByArrowsDisabled
      >
        <span className="schedule-dropdown-text grui truncate">
          {scheduleType || 'Select a schedule...'}
        </span>
      </Dropdown>
      {[
        'after',
        'after_business_hours',
        'after_business_hours_start',
        'before_business_hours_end',
      ].includes(scheduleTypeValue) && (
        <>
          <NumberField
            name={secondsName}
            value={afterValue || ''}
            onChange={handleOnAfterValueChange}
            css={styles.field}
            autoFocus
          />
          <Dropdown
            overlay={<DropdownMenu data={afterUnits} />}
            onSelect={handleOnAfterUnitChange}
            selectedKey={afterUnit}
          >
            <Dropdown.Button size="small">{afterUnitName}</Dropdown.Button>
          </Dropdown>
        </>
      )}
      {scheduleTypeValue === 'on_day' && (
        <Dropdown
          overlay={<DropdownMenu data={onDayOptions} />}
          onSelect={onDayChange}
          selectedKey={dayValue}
          hasMinWidth
        >
          <Dropdown.Button size="small">{dayValueName}</Dropdown.Button>
        </Dropdown>
      )}
      {scheduleTypeValue === 'on_next' && (
        <Dropdown
          overlay={<DropdownMenu data={daysOfWeek} />}
          onSelect={onDayOfWeekChange}
          selectedKey={dayOfWeekValue}
          hasMinWidth
        >
          <Dropdown.Button size="small">{dayOfWeekValueName}</Dropdown.Button>
        </Dropdown>
      )}
      {scheduleTypeValue === 'before_month' && (
        <Dropdown
          overlay={<DropdownMenu data={beforeMonthEndOptions} />}
          onSelect={onDaysChange}
          selectedKey={daysValue}
          hasMinWidth
        >
          <Dropdown.Button size="small">{daysValueName}</Dropdown.Button>
        </Dropdown>
      )}
      {['on_day', 'on_next', 'before_month'].includes(scheduleTypeValue) && (
        <SUI css={styles.dateField}>
          <DateTimeInput
            name={timeName}
            onChange={handleOnTimeChange}
            setDefaultValue={handleOnTimeChange}
            justTime
            value={timeValueDate}
            css={styles.dateField}
          />
        </SUI>
      )}
      {scheduleTypeValue === 'immediately' && <Play css={styles.arrowIcon} />}
    </AutomationItem>
  )
}

export default React.memo(ScheduleSection)
