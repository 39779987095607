import { createSelector } from 'reselect'
import { selectAccountUsageOnboarding } from 'selectors/app'
import {
  selectCanOnboardAccount,
  selectFeatureBasedOnboardingWorkflowData,
  selectOnboardingWorkflowCompletedAndSkippedList,
} from '.'

let originalCompletedCount = 0

export const selectShowOnboardingWorkflow = createSelector(
  selectCanOnboardAccount,
  selectOnboardingWorkflowCompletedAndSkippedList,
  selectAccountUsageOnboarding,
  selectFeatureBasedOnboardingWorkflowData,
  (
    canOnboardAccount,
    onboardingWorkflowCompletedAndSkippedList,
    usageOnboarding,
    onboardingWorkflowData
  ) => {
    const totalCount = Object.keys(onboardingWorkflowData).length
    const completedCount = onboardingWorkflowCompletedAndSkippedList.length
    if (originalCompletedCount === 0) {
      originalCompletedCount = completedCount
    }
    return (
      canOnboardAccount &&
      !usageOnboarding?.completed &&
      completedCount < totalCount
    )
  }
)
