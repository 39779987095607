import deepEqual from 'fast-deep-equal'
import GTM from 'react-gtm-module'
import config from 'config'
import { selectAccount } from 'ducks/accounts/selectors/selectAccount'
import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'
import { selectBillingExternalAccountId } from 'ducks/billing/selectors'
import { toTimestamp } from 'util/date'
import { sleep } from 'util/functions'
import { selectIsLoggedInPath } from 'selectors/location'
import { selectCurrentTotalPerCycle } from 'ducks/billing/selectors/selectCurrentTotalPerCycle'

export const DATA_LAYER_NAME = 'PageDataLayer'

function generateDeferredPromise() {
  let resolve
  let reject
  const promise = new Promise((res, rej) => {
    ;[resolve, reject] = [res, rej]
  })
  return { promise, reject, resolve }
}

export function initializeTracking() {
  // Initialize Google Tag Manager
  const tagManagerArgs = {
    gtmId: config.gtm_containerid,
    auth: config.gtm_auth,
    preview: config.gtm_preview,
    dataLayerName: DATA_LAYER_NAME,
  }
  GTM.initialize(tagManagerArgs)
}

async function trackingDataFromState() {
  let counter = 0
  const data = {}
  while (!app?.store?.getState && counter < 30) {
    // eslint-disable-next-line no-await-in-loop
    await sleep(1000)
    counter += 1
  }
  if (!app?.store?.getState) return data

  counter = 0
  let isRequiredDataAvailable = false
  while (isRequiredDataAvailable === false && counter < 30) {
    const state = app.store.getState()
    const isLoggedInPath = selectIsLoggedInPath(state)
    const { email, confirmedAt } = selectCurrentUser(state)
    const { id, subdomain, activated_at: createdAt } = selectAccount(state)
    const billingId = selectBillingExternalAccountId(state)
    const currentTotalPerCycle = selectCurrentTotalPerCycle(state)

    if (email) data.agentEmail = email
    if (confirmedAt) {
      data.userSignupDate = confirmedAt
      data.userSignupTimestamp = toTimestamp(confirmedAt)
    }
    if (id) data.accountId = id
    if (subdomain) data.accountSubdomain = subdomain
    if (createdAt) data.accountCreatedAtTimestamp = toTimestamp(createdAt)
    if (billingId) data.accountBillingExternalId = billingId
    if (currentTotalPerCycle) data.currentTotalPerCycle = currentTotalPerCycle

    if (!isLoggedInPath || !!data.accountSubdomain) {
      isRequiredDataAvailable = true
    } else {
      // eslint-disable-next-line no-await-in-loop
      await sleep(1000)
      counter += 1
    }
  }

  return data
}

let lastData = null
let isLoaded = false
export async function trackPage(
  pageType,
  pageGroup = '',
  userId = '',
  segment = config.gtm_segment,
  version = config.gtm_version,
  event = 'groove_nav_event',
  layoutVariant = ''
) {
  if (!pageType) {
    throw new Error(
      'pageType, pageGroup, segment and version is required to track a page load'
    )
  }

  if (!window[DATA_LAYER_NAME]) {
    return null
  }

  const data = {
    segment,
    version,
    pageType,
    pageGroup,
    event,
    userId,
    layoutVariant,
  }

  const stateTrackingData = await trackingDataFromState()
  Object.assign(data, stateTrackingData)

  // Fire the load event once
  if (!isLoaded) {
    isLoaded = true
    dataLayer({
      ...data,
      event: 'groove_load',
    })
  }
  // All tracking in this module is "page" level tracking. You cant
  // fire the same page tracking twice because pages cant "navigate" to
  // themselves
  if (!deepEqual(lastData, data)) {
    lastData = data
    return dataLayer(data)
  }

  return null
}

export function dataLayer(data) {
  const dfd = generateDeferredPromise()
  let resolved = false
  // uBlock and other ad-blockers screw with eventCallback in a way that
  // prevents the eventTimeout from triggering the event callback, which
  // completely breaks the whole point of having the stupid timeout in the
  // first place. So, we have to create our own timeout instead.
  setTimeout(() => {
    if (!resolved) {
      resolved = true
      dfd.resolve()
    }
  }, 2000)
  GTM.dataLayer({
    dataLayer: {
      ...data,
      eventCallback: id => {
        // Another container ID is calling this for some reason, so we want
        // to ensure that we are only firing on the right container.
        if (id === config.gtm_containerid) {
          resolved = true
          dfd.resolve()
        }
      },
    },
    dataLayerName: DATA_LAYER_NAME,
  })
  return dfd.promise
}
