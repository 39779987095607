import {
  isFeatureHiddenInPlanCards,
  isInboxPlanVersionSmallerThan18,
} from 'ducks/billing/util'
import { capitalize, pluralize } from 'util/strings'
import {
  FEATURE_INBOX_CANNED_REPLIES_TIER_1,
  FEATURE_INBOX_CUSTOM_FIELDS,
  FEATURE_INBOX_CUSTOM_PROFILE,
  FEATURE_INBOX_EXPORTS,
  FEATURE_INBOX_MAX_CANNED_REPLIES,
  FEATURE_INBOX_MAX_CHANNELS,
  FEATURE_INBOX_MAX_INTEGRATIONS,
  FEATURE_INBOX_MAX_MAILBOXES,
  FEATURE_INBOX_MAX_REPORTING_DAYS,
  FEATURE_INBOX_MAX_RULES,
  FEATURE_INBOX_MAX_USERS,
  FEATURE_INBOX_MAX_VIEWER_USERS,
  FEATURE_INBOX_PREMIUM_INTEGRATIONS,
  FEATURE_INBOX_RULES_TIER_1,
  FEATURE_INBOX_RULES_TIER_2,
  FEATURE_INBOX_TEAMS,
  FEATURE_KB_BRANDING,
  FEATURE_KB_CUSTOM_SCRIPTS,
  FEATURE_KB_MAX_ARTICLES,
  FEATURE_KB_MAX_KBS,
  FEATURE_KB_PRIVATE,
  FEATURE_KB_SEO,
  FEATURE_ROUND_ROBIN,
  FEATURE_INBOX_REPORTS,
  FEATURE_INBOX_REPORTS_TIER_1,
  FEATURE_INBOX_REPORTS_TIER_2,
  FEATURE_INBOX_LIVE_CHAT,
  FEATURE_AI_TIER_1,
  FEATURE_INBOX_WEBHOOKS,
  FEATURE_INBOX_API_ACCESS_RATE_LIMIT,
  FEATURE_INBOX_API_ACCESS_TIER_1,
  FEATURE_INBOX_2FA,
  FEATURE_USER_CUSTOM_PERMISSIONS,
  FEATURE_ENTERPRISE_SECURITY,
  FEATURE_INBOX_BRANDING,
  FEATURE_INBOX_CRM_FIELDS,
  FEATURE_INBOX_PRIORITY_SUPPORT,
  FEATURE_AI_DRAFT_CREDITS,
} from 'ducks/billing/featureTypes'
import { compact } from 'util/objects'

export const normalizeDays = count => {
  if (count === 'unlimited') return capitalize(count)
  const months = parseInt(count / 30, 10)
  if (months <= 1) {
    return `${count} ${pluralize(count, 'day')}`
  }
  if (months < 12 || months % 12 !== 0) {
    return `${months} ${pluralize(months, 'month')}`
  }
  const years = months / 12
  return `${years} ${pluralize(years, 'year')}`
}

export const normalizeToBool = count => {
  return !!count
}

export const normalizeToComingSoon = value => {
  // eslint-disable-next-line react/jsx-filename-extension
  const r = value ? 'Coming Soon' : ''
  return r
}

export const normalizeToHasAddonForFeature = (_value, addonInfo) => {
  return !!addonInfo
}

export const normalizeToCallsPerMinute = count => {
  return `${count} calls/minute`
}

export const normalizeToCreditsPerMonthForAiDraft = count => {
  if (!count) return ''
  if (count === true) return normalizeToComingSoon(count)
  return `${count} credits/month`
}

export const normalizeToNumberOrX = count => {
  return typeof count === 'number' ? count : ''
}

const planVersionsWithMinAgents = ['v2', 'v3', 'v14']

export const getFeatureTitles = ({
  isKb,
  showKbPlans,
  versions,
  pricings,
  isAiDraftGenerationFeatureEnabled,
} = {}) => {
  const showNewFeatures = versions.some(
    version => !isInboxPlanVersionSmallerThan18(version)
  )
  const showMinAgents = versions.some(version =>
    planVersionsWithMinAgents.includes(version)
  )
  if (isKb) {
    const KbFeatures = [
      {
        name: 'Knowledge Bases',
        key: FEATURE_KB_MAX_KBS,
      },
      {
        name: 'Articles',
        key: FEATURE_KB_MAX_ARTICLES,
      },
      {
        name: 'Reporting History',
        key: FEATURE_INBOX_MAX_REPORTING_DAYS,
        normalizer: normalizeDays,
      },
      {
        name: 'SEO settings',
        key: FEATURE_KB_SEO,
      },
      {
        name: 'Custom Scripts',
        key: FEATURE_KB_CUSTOM_SCRIPTS,
      },
      {
        name: 'Access Control',
        key: FEATURE_KB_PRIVATE,
      },
      {
        name: 'Remove Groove branding',
        key: FEATURE_KB_BRANDING,
      },
    ]
    return {
      header: {
        key: 'header',
        list: KbFeatures,
      },
    }
  }
  const headerGroup = [
    {
      name: `Minimum ${app.t('agent')}`,
      key: 'minimumQuantity',
      hidden: !showMinAgents,
    },
    {
      name: `Maximum ${app.t('Agents')}`,
      key: FEATURE_INBOX_MAX_USERS,
    },
    {
      name: 'Lite users',
      key: FEATURE_INBOX_MAX_VIEWER_USERS,
      hidden: isFeatureHiddenInPlanCards(
        pricings,
        FEATURE_INBOX_MAX_VIEWER_USERS
      ),
    },
  ]

  const omnichannelGroup = [
    {
      name: `Included channels`,
      key: !showNewFeatures
        ? FEATURE_INBOX_MAX_MAILBOXES
        : FEATURE_INBOX_MAX_CHANNELS,
    },
    {
      name: `Email inboxes`,
      key: !showNewFeatures
        ? FEATURE_INBOX_MAX_MAILBOXES
        : FEATURE_INBOX_MAX_CHANNELS,
      normalizer: normalizeToBool,
    },
    {
      name: `Live chat`,
      key: !showNewFeatures
        ? FEATURE_INBOX_MAX_MAILBOXES
        : FEATURE_INBOX_MAX_CHANNELS,
      normalizer: normalizeToBool,
    },
    {
      name: `Instagram direct messages`,
      key: !showNewFeatures
        ? FEATURE_INBOX_MAX_MAILBOXES
        : FEATURE_INBOX_MAX_CHANNELS,
      normalizer: normalizeToBool,
    },
    {
      name: `Facebook messenger`,
      key: !showNewFeatures
        ? FEATURE_INBOX_MAX_MAILBOXES
        : FEATURE_INBOX_MAX_CHANNELS,
      normalizer: normalizeToBool,
    },
    {
      name: `Web widget with contact form & knowledge base`,
      key: !showNewFeatures
        ? FEATURE_INBOX_MAX_MAILBOXES
        : FEATURE_INBOX_MAX_CHANNELS,
      normalizer: normalizeToBool,
    },
    {
      name: `Call logging`,
      key: !showNewFeatures
        ? FEATURE_INBOX_MAX_MAILBOXES
        : FEATURE_INBOX_MAX_CHANNELS,
      normalizer: normalizeToBool,
    },
    {
      name: `iOS & Android mobile apps`,
      key: !showNewFeatures
        ? FEATURE_INBOX_MAX_MAILBOXES
        : FEATURE_INBOX_MAX_CHANNELS,
      normalizer: normalizeToBool,
    },
  ]

  const collaborationGroup = [
    {
      name: `Included free lite users`,
      key: FEATURE_INBOX_MAX_VIEWER_USERS,
    },
    {
      name: `Conversation statuses`,
      key: FEATURE_INBOX_MAX_CHANNELS,
      normalizer: normalizeToBool,
    },
    {
      name: `Unified "all inboxes" view`,
      key: FEATURE_INBOX_MAX_CHANNELS,
      normalizer: normalizeToBool,
    },
    {
      name: `Teammate assignment`,
      key: FEATURE_INBOX_MAX_CHANNELS,
      normalizer: normalizeToBool,
    },
    {
      name: `Collision detection`,
      key: FEATURE_INBOX_MAX_CHANNELS,
      normalizer: normalizeToBool,
    },
    {
      name: `Internal notes with @mentions`,
      key: FEATURE_INBOX_MAX_CHANNELS,
      normalizer: normalizeToBool,
    },
    {
      name: `Follow conversations`,
      key: FEATURE_INBOX_MAX_CHANNELS,
      normalizer: normalizeToBool,
    },
    {
      name: `Agent availability`,
      key: FEATURE_INBOX_MAX_CHANNELS,
      normalizer: normalizeToBool,
    },
    {
      name: `Teams`,
      key: FEATURE_INBOX_TEAMS,
    },
  ]

  const inboxFeaturesGroup = [
    {
      name: 'Custom smart folders',
      key: FEATURE_INBOX_MAX_CHANNELS,
      normalizer: normalizeToBool,
    },
    {
      name: 'Conversation tags',
      key: FEATURE_INBOX_MAX_CHANNELS,
      normalizer: normalizeToBool,
    },
    {
      name: 'Conversation snoozing',
      key: FEATURE_INBOX_MAX_CHANNELS,
      normalizer: normalizeToBool,
    },
    {
      name: 'Open tracking',
      key: FEATURE_INBOX_MAX_CHANNELS,
      normalizer: normalizeToBool,
    },
    {
      name: 'Search with variables',
      key: FEATURE_INBOX_MAX_CHANNELS,
      normalizer: normalizeToBool,
    },
    {
      name: 'Satisfaction ratings (CSAT)',
      key: FEATURE_INBOX_MAX_CHANNELS,
      normalizer: normalizeToBool,
    },
    {
      name: 'Customer feedback',
      key: FEATURE_INBOX_MAX_CHANNELS,
      normalizer: normalizeToBool,
    },
    {
      name: 'Spam & blacklist handling',
      key: FEATURE_INBOX_MAX_CHANNELS,
      normalizer: normalizeToBool,
    },
    {
      name: 'Customer overview with custom fields',
      key: FEATURE_INBOX_MAX_CHANNELS,
      normalizer: normalizeToBool,
    },
    {
      name: 'Conversation history',
      key: FEATURE_INBOX_MAX_CHANNELS,
      normalizer: normalizeToBool,
    },
    {
      name: 'Customer notes',
      key: FEATURE_INBOX_MAX_CHANNELS,
      normalizer: normalizeToBool,
    },
    {
      name: 'Merge conversations',
      key: FEATURE_INBOX_MAX_CHANNELS,
      normalizer: normalizeToBool,
    },
    {
      name: 'Custom profile',
      key: FEATURE_INBOX_MAX_CHANNELS,
      normalizer: normalizeToBool,
    },
    {
      name: 'Custom auto-replies',
      key: FEATURE_INBOX_MAX_CHANNELS,
      normalizer: normalizeToBool,
    },
    {
      name: 'Custom signatures',
      key: FEATURE_INBOX_MAX_CHANNELS,
      normalizer: normalizeToBool,
    },
    {
      name: 'Slack notifications',
      key: FEATURE_INBOX_MAX_CHANNELS,
      normalizer: normalizeToBool,
    },
    {
      name: 'Undo send',
      key: FEATURE_INBOX_MAX_CHANNELS,
      normalizer: normalizeToBool,
    },
    {
      name: 'Keyboard shortcuts',
      key: FEATURE_INBOX_MAX_CHANNELS,
      normalizer: normalizeToBool,
    },
    {
      name: 'IMAP email connection',
      key: FEATURE_INBOX_MAX_CHANNELS,
      normalizer: normalizeToBool,
    },
    {
      name: 'Custom SMTP sending',
      key: FEATURE_INBOX_MAX_CHANNELS,
      normalizer: normalizeToBool,
    },
    {
      name: 'Advanced tracking with custom fields for conversations',
      key: FEATURE_INBOX_CUSTOM_FIELDS,
      normalizer: normalizeToComingSoon,
    },
  ]

  const automationsGroup = [
    {
      name: 'Instant replies',
      key: FEATURE_INBOX_MAX_CANNED_REPLIES,
      normalizer: normalizeToBool,
    },
    {
      name: 'Rules',
      key: FEATURE_INBOX_MAX_RULES,
      normalizer: normalizeToBool,
    },
    {
      name: 'Rule templates',
      key: FEATURE_INBOX_MAX_RULES,
      normalizer: normalizeToBool,
    },
    {
      name: 'Interaction escalation',
      key: FEATURE_INBOX_MAX_RULES,
      normalizer: normalizeToBool,
    },
    {
      name: 'Custom rule builder',
      key: FEATURE_INBOX_MAX_RULES,
      normalizer: normalizeToBool,
    },
    {
      name: 'Round robin assignment',
      key: FEATURE_INBOX_MAX_RULES,
      normalizer: normalizeToBool,
    },
    {
      name: 'Round robin assignment with load balancing',
      key: FEATURE_INBOX_RULES_TIER_1,
    },
    { name: 'Time-based rules', key: FEATURE_INBOX_RULES_TIER_2 },
    { name: 'SLA management', key: FEATURE_INBOX_RULES_TIER_2 },
    { name: 'Advanced rule automations', key: FEATURE_INBOX_RULES_TIER_2 },
    { name: 'Instant reply actions', key: FEATURE_INBOX_CANNED_REPLIES_TIER_1 },
  ]

  const reportingGroup = [
    {
      name: 'Reporting history',
      key: FEATURE_INBOX_MAX_REPORTING_DAYS,
      normalizer: normalizeDays,
    },
    { name: 'Average conversations & resolutions', key: FEATURE_INBOX_REPORTS },
    { name: 'First response times', key: FEATURE_INBOX_REPORTS },
    { name: 'Tag reporting', key: FEATURE_INBOX_REPORTS },
    { name: 'Total customers helped', key: FEATURE_INBOX_REPORTS },
    { name: 'Average resolution time', key: FEATURE_INBOX_REPORTS },
    { name: 'Productivity reports', key: FEATURE_INBOX_REPORTS },
    { name: 'Performance reports', key: FEATURE_INBOX_REPORTS },
    { name: 'Customer happiness (CSAT) reporting', key: FEATURE_INBOX_REPORTS },
    { name: 'Knowledge base reporting', key: FEATURE_INBOX_REPORTS },
    { name: 'User specific reporting', key: FEATURE_INBOX_REPORTS },
    { name: 'Team specific reporting', key: FEATURE_INBOX_TEAMS },
    {
      name: 'Advanced report filtering',
      key: FEATURE_INBOX_REPORTS_TIER_1,
      normalizer: normalizeToComingSoon,
    },
    {
      name: 'Report exporting',
      key: FEATURE_INBOX_REPORTS_TIER_1,
      normalizer: normalizeToComingSoon,
    },
    {
      name: 'Advanced analytics',
      key: FEATURE_INBOX_REPORTS_TIER_2,
      normalizer: normalizeToComingSoon,
    },
  ]

  const liveChatGroup = [
    {
      name: 'Branded chat widget for web & mobile',
      key: FEATURE_INBOX_LIVE_CHAT,
    },
    { name: 'End user identification', key: FEATURE_INBOX_LIVE_CHAT },
    { name: 'User and visit info', key: FEATURE_INBOX_LIVE_CHAT },
    { name: 'Transcripts', key: FEATURE_INBOX_LIVE_CHAT },
    { name: 'Email continuation', key: FEATURE_INBOX_LIVE_CHAT },
    { name: 'Maximum conversation handling', key: FEATURE_INBOX_LIVE_CHAT },
    { name: 'Unattended message handling', key: FEATURE_INBOX_LIVE_CHAT },
    { name: 'Welcome bot', key: FEATURE_INBOX_LIVE_CHAT },
    { name: 'No online agent automations', key: FEATURE_INBOX_LIVE_CHAT },
    {
      name: 'Hide teammate avatar from participants',
      key: FEATURE_INBOX_LIVE_CHAT,
    },
    { name: 'Custom chat availability settings', key: FEATURE_INBOX_LIVE_CHAT },
    {
      name: 'Round robin routing with load balancing',
      key: FEATURE_ROUND_ROBIN,
    },
  ]

  const kbGroup = [
    {
      name: 'Included knowledge base sites',
      key: FEATURE_KB_MAX_KBS,
      normalizer: normalizeToNumberOrX,
    },
    {
      name: 'Rich text editor',
      key: FEATURE_KB_MAX_KBS,
      normalizer: normalizeToBool,
    },
    {
      name: 'Prebuilt & SEO optimized themes',
      key: FEATURE_KB_MAX_KBS,
      normalizer: normalizeToBool,
    },
    {
      name: 'Mobile ready',
      key: FEATURE_KB_MAX_KBS,
      normalizer: normalizeToBool,
    },
    {
      name: 'Groove inbox integration',
      key: FEATURE_KB_MAX_KBS,
      normalizer: normalizeToBool,
    },
    {
      name: 'Knowledge base widget',
      key: FEATURE_KB_MAX_KBS,
      normalizer: normalizeToBool,
    },
    {
      name: 'Custom domain',
      key: FEATURE_KB_MAX_KBS,
      normalizer: normalizeToBool,
    },
    {
      name: 'One-click SSL',
      key: FEATURE_KB_MAX_KBS,
      normalizer: normalizeToBool,
    },
    {
      name: 'Advanced metrics',
      key: FEATURE_KB_MAX_KBS,
      normalizer: normalizeToBool,
    },
    {
      name: 'Article rating system',
      key: FEATURE_KB_MAX_KBS,
      normalizer: normalizeToBool,
    },
    {
      name: 'Successful & failed search tracking',
      key: FEATURE_KB_MAX_KBS,
      normalizer: normalizeToBool,
    },
    {
      name: 'SEO & open graph settings',
      key: FEATURE_KB_SEO,
      normalizer: normalizeToBool,
    },
    {
      name: 'White labeling',
      key: FEATURE_KB_BRANDING,
      normalizer: normalizeToBool,
    },
    {
      name: 'Custom scripts',
      key: FEATURE_KB_CUSTOM_SCRIPTS,
      normalizer: normalizeToBool,
    },
    {
      name: 'Full branding control',
      key: FEATURE_KB_BRANDING,
    },
    {
      name: 'Password & IP access restrictions',
      key: FEATURE_KB_PRIVATE,
    },
  ]

  const aiGroup = [
    { name: 'AI assist', key: FEATURE_AI_TIER_1 },
    { name: 'AI summarize', key: FEATURE_AI_TIER_1 },
    { name: 'AI sentiment', key: FEATURE_AI_TIER_1 },
    { name: 'AI tag suggest', key: FEATURE_AI_TIER_1 },
    {
      name: app.t('AI_drafts'),
      key: isAiDraftGenerationFeatureEnabled
        ? FEATURE_AI_DRAFT_CREDITS
        : FEATURE_AI_TIER_1,
      normalizer: normalizeToCreditsPerMonthForAiDraft,
    },
  ]

  const integrationsGroup = [
    {
      name: '40+ Native integrations',
      key: FEATURE_INBOX_MAX_INTEGRATIONS,
      normalizer: normalizeToBool,
    },
    {
      name: 'API access',
      key: FEATURE_INBOX_API_ACCESS_RATE_LIMIT,
      normalizer: normalizeToCallsPerMinute,
    },
    { name: 'Webhooks', key: FEATURE_INBOX_WEBHOOKS },
    { name: 'Custom profile app builder', key: FEATURE_INBOX_CUSTOM_PROFILE },
    {
      name: 'Zapier access',
      key: FEATURE_INBOX_MAX_INTEGRATIONS,
      normalizer: normalizeToBool,
    },
    {
      name: 'Slack integration & notifications',
      key: FEATURE_INBOX_MAX_INTEGRATIONS,
      normalizer: normalizeToBool,
    },
    { name: 'Salesforce integration', key: FEATURE_INBOX_PREMIUM_INTEGRATIONS },
    { name: 'HubSpot integration', key: FEATURE_INBOX_PREMIUM_INTEGRATIONS },
    { name: 'Jira integration', key: FEATURE_INBOX_PREMIUM_INTEGRATIONS },
    { name: 'Advanced API access', key: FEATURE_INBOX_API_ACCESS_TIER_1 },
  ]

  const securityGroup = [
    { name: 'Two-factor authentication (2FA)', key: FEATURE_INBOX_2FA },
    {
      name: 'Mailbox access permissions',
      key: FEATURE_INBOX_MAX_CHANNELS,
      normalizer: normalizeToBool,
    },
    {
      name: 'Teammate roles',
      key: FEATURE_INBOX_MAX_CHANNELS,
      normalizer: normalizeToBool,
    },
    {
      name: 'Public downtime reporting',
      key: FEATURE_INBOX_MAX_CHANNELS,
      normalizer: normalizeToBool,
    },
    {
      name: 'Custom user permissions',
      key: FEATURE_USER_CUSTOM_PERMISSIONS,
      normalizer: normalizeToComingSoon,
    },
    {
      name: 'Custom enterprise security',
      key: FEATURE_ENTERPRISE_SECURITY,
      normalizer: normalizeToComingSoon,
    },
  ]

  const setupGroup = [
    { name: 'Remove Groove branding', key: FEATURE_INBOX_BRANDING },
    { name: 'Data export', key: FEATURE_INBOX_EXPORTS },
    { name: 'Variables', key: FEATURE_INBOX_CRM_FIELDS },
    { name: 'Auto bcc', key: FEATURE_INBOX_CRM_FIELDS },
    { name: 'Timezone configuration', key: FEATURE_INBOX_CRM_FIELDS },
    {
      name: 'Contact & business custom fields',
      key: FEATURE_INBOX_CRM_FIELDS,
      normalizer: normalizeToComingSoon,
    },
  ]

  const supportGroup = [
    {
      name: 'Onboarding assistance',
      key: FEATURE_INBOX_MAX_CHANNELS,
      normalizer: normalizeToBool,
    },
    {
      name: '24/7 support',
      key: FEATURE_INBOX_MAX_CHANNELS,
      normalizer: normalizeToBool,
    },
    { name: 'Concierge onboarding', key: FEATURE_INBOX_PRIORITY_SUPPORT },
    { name: 'Dedicated account manager', key: FEATURE_INBOX_PRIORITY_SUPPORT },
    {
      name: 'Quarterly account optimization reviews',
      key: FEATURE_INBOX_PRIORITY_SUPPORT,
    },
    { name: 'Quarterly team training', key: FEATURE_INBOX_PRIORITY_SUPPORT },
    { name: 'Call back support', key: FEATURE_INBOX_PRIORITY_SUPPORT },
    { name: 'Engineer support', key: FEATURE_INBOX_PRIORITY_SUPPORT },
  ]

  return compact({
    header: {
      key: 'header',
      list: headerGroup.filter(item => !item.hidden),
    },
    omnichannel: {
      title: 'Omnichannel Inboxes',
      key: 'omnichannel',
      list: omnichannelGroup.filter(item => !item.hidden),
    },
    collaboration: {
      title: 'Collaboration Features',
      key: 'collaboration',
      list: collaborationGroup.filter(item => !item.hidden),
    },
    inboxFeatures: {
      title: 'Powerful Inbox Features',
      key: 'inboxFeatures',
      list: inboxFeaturesGroup.filter(item => !item.hidden),
    },
    automations: {
      title: 'Automations & Workflows',
      key: 'automations',
      list: automationsGroup.filter(item => !item.hidden),
    },
    reporting: {
      title: 'Reporting & Analytics',
      key: 'reporting',
      list: reportingGroup.filter(item => !item.hidden),
    },
    liveChat: {
      title: 'Live Chat',
      key: 'liveChat',
      list: liveChatGroup.filter(item => !item.hidden),
    },
    kb: !showKbPlans && {
      title: 'Knowledge Base',
      key: 'kb',
      list: kbGroup.filter(item => !item.hidden),
    },
    ai: {
      title: 'AI Features',
      key: 'ai',
      list: aiGroup.filter(item => !item.hidden),
    },
    integrations: {
      title: 'API & Integrations',
      key: 'integrations',
      list: integrationsGroup.filter(item => !item.hidden),
    },
    security: {
      title: 'Security & Permissions',
      key: 'security',
      list: securityGroup.filter(item => !item.hidden),
    },
    setup: {
      title: 'Advanced Setup',
      key: 'setup',
      list: setupGroup.filter(item => !item.hidden),
    },
    support: {
      title: 'Groove Customer Support',
      key: 'support',
      list: supportGroup.filter(item => !item.hidden),
    },
  })
}
