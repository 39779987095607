import { logError } from 'util/debug'
import { getMatrixClient } from 'ducks/chat/utils/client'
import { NOTIFICATIONS_STATE_UPDATE } from 'ducks/chat/actionTypes/notifications'
import { selectNotificationsEnabled } from 'ducks/chat/selectors/notifications'
import NOTIFICATION_SOUND_URL from 'assets/tada_01.mp3'
import * as storage from './storage'
import { isGranted } from './webPush/permissions'

export const playAudio = url => {
  if (!url) return Promise.resolve()
  const audio = new Audio(url)
  try {
    return audio.play().catch(() => {
      logError(`Failed to play ${url}`)
      // do nothing
    })
  } catch {
    // do nothing
    logError(`Failed to play ${url}`)
    return Promise.resolve()
  }
}

const AUDIO_NOTIFICATION_TIMEOUT = 15 * 1000 // 15 sec

// NOTE (jscheel): Deal with mismatched data in localStorage and the browser
if (!isGranted() && getAudioNotificationsState()) {
  setAudioNotificationsState(false)
}

// NOTE (jscheel): We need to sync the redux store to the localStorage, even
// when the localStorage is changed in another window. This listens to storage
// events (which are only broadcast when another document changes the same local
// storage), and updates the redux store if needed.
window.addEventListener('storage', () => {
  if (!app?.store) return
  const state = app.store.getState()
  const enabledInLocalStorage = getAudioNotificationsState()
  const enabledInRedux = selectNotificationsEnabled(state)
  if (enabledInRedux !== enabledInLocalStorage) {
    app.store.dispatch({
      type: NOTIFICATIONS_STATE_UPDATE,
      payload: {
        enabled: enabledInLocalStorage,
      },
    })
  }
})

export function getAudioNotificationsState() {
  return !!storage.get('chatAudioNotificationsState')
}

export function getAudioNotificationsStateTs() {
  return storage.get('chatAudioNotificationsStateTs')
}

export function setAudioNotificationsState(enabled, ts = Date.now()) {
  if (
    enabled !== storage.get('chatAudioNotificationsState') &&
    ts > storage.get('chatAudioNotificationsStateTs')
  ) {
    storage.set('chatAudioNotificationsState', enabled)
    storage.set('chatAudioNotificationsStateTs', ts)
    storage.remove('chatAudioNotificationLatestPlayTs')
  }
}

export function playAudioNotification(currentEventTs = Date.now()) {
  if (!getAudioNotificationsState()) return
  const latestEventTs = storage.get('chatAudioNotificationLatestEventTs')
  const latestPlayTs = storage.get('chatAudioNotificationLatestPlayTs')
  if (currentEventTs < latestEventTs) return
  const currentTs = Date.now()
  if (currentTs - AUDIO_NOTIFICATION_TIMEOUT >= latestPlayTs) {
    storage.set('chatAudioNotificationLatestPlayTs', currentTs)
    playAudio(NOTIFICATION_SOUND_URL)
  }
}

export function setMatrixAudioNotificationState(enabled, ts = Date.now()) {
  const client = getMatrixClient()
  return client
    .setAccountData('g.notifications.audio', { enabled, ts })
    .then(() => {
      setAudioNotificationsState(enabled, ts)
    })
    .catch(() => {
      setAudioNotificationsState(!enabled)
    })
}
