import {
  CREATE_MAILBOX_REQUEST,
  CREATE_MAILBOX_SUCCESS,
  CREATE_MAILBOX_FAILURE,
} from 'constants/action_types'
import {
  UPDATE_MAILBOX_STARTED,
  UPDATE_MAILBOX_FAILED,
  DESTROY_MAILBOX_SUCCESS,
} from 'ducks/mailboxes/actionTypes'
import { emptyObj } from 'util/objects'
import { COPY_TO_CLIPBOARD, CLEAR_COPY_TO_CLIPBOARD_RESPONSE } from './types'
import { SETUP_EMAIL_PAGE } from '../pages'

const defaultSetupEmailState = {
  forwardingInput: 'amazingaddress@groovehq.com',
  copyToClipboardStatus: null,
  mailbox: null,
  isCreatingMailbox: false,
}

function copyForwardEmailToClipboard(selector) {
  let succeeded = false

  // Fix, in chrome you need to remove the ranges first before
  // creating a new range, otherwise it'll say that everything worked
  // but nothing will be copied to the clipboard
  window.getSelection().removeAllRanges()
  try {
    // https://css-tricks.com/native-browser-copy-clipboard/
    // Select the email link anchor text
    const textNode = document.querySelector(selector)
    const range = document.createRange()
    range.selectNode(textNode)
    window.getSelection().addRange(range)

    // Now that we've selected the anchor text, execute the copy command
    succeeded = document.execCommand('copy')
  } catch (err) {
    // do nothing
  }
  // Remove the selections - NOTE: Should use
  // removeRange(range) when it is supported
  window.getSelection().removeAllRanges()
  return succeeded
}

export default function setupEmailReducer(
  state,
  { type, payload: { selector, mailbox, error, id } = {} }
) {
  switch (type) {
    case COPY_TO_CLIPBOARD:
      return {
        ...state,
        copyToClipboardStatus: copyForwardEmailToClipboard(selector),
      }
    case CLEAR_COPY_TO_CLIPBOARD_RESPONSE:
      return {
        ...state,
        copyToClipboardStatus: null,
      }
    case CREATE_MAILBOX_REQUEST:
      return {
        ...state,
        isCreatingMailbox: true,
        mailBoxError: null,
      }
    case CREATE_MAILBOX_SUCCESS:
      return {
        ...state,
        isCreatingMailbox: false,
        mailbox,
      }
    case CREATE_MAILBOX_FAILURE:
      return {
        ...state,
        isCreatingMailbox: false,
        mailBoxError: error,
      }
    case UPDATE_MAILBOX_STARTED:
      return {
        ...state,
        shouldIgnoreCleanup: true,
      }
    case UPDATE_MAILBOX_FAILED:
      return {
        ...state,
        mailBoxError: error,
      }
    case SETUP_EMAIL_PAGE:
      return {
        ...state,
        mailbox: null,
        shouldIgnoreCleanup: false,
      }
    case DESTROY_MAILBOX_SUCCESS: {
      const stateMailbox = state.mailbox || emptyObj
      if (id === stateMailbox.id) {
        return {
          ...state,
          mailbox: null,
          shouldIgnoreCleanup: false,
        }
      }
      return state
    }
    default:
      return state || defaultSetupEmailState
  }
}
