import React from 'react'
import { string } from 'prop-types'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import { useChannel } from 'ducks/channels/hooks'

const DeleteChannelContent = ({ channelId }) => {
  const { channel, isLoading } = useChannel(channelId, {
    useCachedIfAvailable: true,
  })

  if (!channel || isLoading) {
    return <span css={text.styles.fontMedium}>Loading...</span>
  }

  return (
    <>
      <p css={text.styles.textNormal}>
        You are about to delete the {app.t('mailbox')} &quot;{channel.name}&quot;
        and associated conversations.
      </p>
      <p css={text.styles.textNormal}>
        This action is permanent and cannot be undone.
      </p>
    </>
  )
}

DeleteChannelContent.propTypes = {
  channelId: string.isRequired,
}

DeleteChannelContent.defaultProps = {}

export default DeleteChannelContent
