import {
  // V3_SIGNUP_PAGE,
  V3_COMPANY_PAGE,
  V3_EMAIL_PAGE,
  V3_VERIFICATION_PAGE,
} from './pages'

// NOTE (jscheel): Simple configuration object for onboarding routes to load

const V3_ROUTES_CONFIG = {
  // NOTE (jscheel): Disabled for experiment
  // [V3_SIGNUP_PAGE]: {
  //   route: {
  //     path: '/signup',
  //   },
  //   handler: () => ({
  //     componentName: 'V3SignupPage',
  //     subapp: 'onboarding',
  //   }),
  // },
  [V3_EMAIL_PAGE]: {
    route: {
      // path: '/signup/email',
      path: '/signup/f3',
    },
    handler: () => ({
      componentName: 'V3EmailPage',
      subapp: 'onboarding',
    }),
  },
  [V3_COMPANY_PAGE]: {
    route: {
      path: '/signup/f3/organization',
    },
    handler: () => ({
      componentName: 'V3CompanyPage',
      subapp: 'onboarding',
    }),
  },
  [V3_VERIFICATION_PAGE]: {
    route: {
      path: '/signup/f3/verification',
    },
    handler: () => ({
      componentName: 'V3VerificationPage',
      subapp: 'onboarding',
    }),
  },
}

export default V3_ROUTES_CONFIG
