import React from 'react'
import { useSelector } from 'react-redux'
import {
  heading,
  text,
} from '@groovehq/internal-design-system/lib/styles/elements'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import { AdminAccessDrawer } from 'subapps/settings/components/drawers/NoAccess'
import { useBilling } from 'ducks/billing/hooks'
import selectIntegrationCredentialsForId from 'ducks/integrations/selectors/selectIntegrationCredentialsForId'

export default function BillingExternalApproveDrawer({
  drawerId,
  drawerResourceId: teamId,
  previousDrawer,
  open,
  onClose,
  onExit,
  ...rest
}) {
  const {
    externalApprovePendingSubscription,
    isBillingLinked,
    externalAccountId,
  } = useBilling()
  const actionWording = isBillingLinked ? 'update' : 'start'

  const { storeDomain } =
    useSelector(state =>
      selectIntegrationCredentialsForId(state, { id: externalAccountId })
    ) || {}

  return (
    <AdminAccessDrawer
      {...rest}
      open={open}
      onClose={onExit}
      title="Billing confirmation"
      footer={
        <ModalBtns
          onSave={externalApprovePendingSubscription}
          saveBtnText="Go to Shopify"
          tertiaryBtnText="Cancel"
          onClickTertiaryBtn={onClose}
        />
      }
    >
      <div className="grui mt-10">
        <h1 css={heading.styles.h4}>Start your subscription</h1>
        <div
          className="grui mt-6"
          css={[text.styles.textNormal, text.styles.textDark]}
        >
          Your Groove account{"'"}s billing payments are managed by your linked
          Shopify store <br />(<strong>{storeDomain}.myshopify.com</strong>).
        </div>
        <div
          className="grui mt-6"
          css={[text.styles.textNormal, text.styles.textDark]}
        >
          To {actionWording} your subscription, you will be redirected to
          Shopify to confirm.
        </div>
      </div>
    </AdminAccessDrawer>
  )
}
