import React, { useCallback } from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import Field from '@groovehq/internal-design-system/lib/components/Field/Field'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import { button } from '@groovehq/internal-design-system/lib/styles/elements'
import { Grab, Trash } from '@groovehq/internal-design-system/lib/assets/icons'

import { styles } from './styles'

const DropDownOption = ({
  className,
  onRemove,
  index,
  register,
  name,
  value,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: value })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  const handleRemove = useCallback(() => onRemove(index), [index, onRemove])

  return (
    <li className={className} ref={setNodeRef} style={style}>
      <Field
        tagPosition="left"
        tagContent={
          <button
            type="button"
            css={[button.styles.preflight, styles.handle]}
            className="sorting-handle"
            {...attributes}
            {...listeners}
          >
            <Grab className="grui cursor-grabbing" />
          </button>
        }
        placeholder="Enter dropdown option"
        {...register(`${name}.${index}.label`)}
      />
      <Button
        customIcon={<Trash />}
        type="tertiary"
        size="small"
        onClick={handleRemove}
      />
    </li>
  )
}

export default DropDownOption
