import React, { useEffect, useState, useCallback } from 'react'
import config from 'config'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useDispatch, useSelector } from 'react-redux'
import { selectSetupIntents } from 'ducks/billing/selectors/selectSetupIntents'
import {
  doCreateSetupIntent,
  doFetchSetupIntents,
} from 'ducks/billing/operations'
import debug from 'util/debug'
import useIsMounted from 'util/hooks/useIsMounted'
import { emptyFunc } from 'util/functions'
import Spinner from '@groovehq/internal-design-system/lib/components/Spinner/Spinner'
import { useSetupIntent } from 'ducks/billing/hooks'
import CheckoutForm from './CheckoutForm'

import { styles } from './styles'

const stripePromise = loadStripe(config.stripePublicKey)

const SetupPaymentMethodCard = ({
  className,
  onCancel,
  showFormButtons,
  formId,
  saveSubscription,
  convertTrials,
  pricingIds,
  billingCycle,
  onReady = emptyFunc,
  onSubmitting = emptyFunc,
  onSubmitted = emptyFunc,
  onError = emptyFunc,
}) => {
  const [isReady, setIsReady] = useState(false)
  const [showSpinner, setShowSpinner] = useState(true)
  const dispatch = useDispatch()
  const [state, setState] = useState('pending')
  const setupIntents = useSelector(selectSetupIntents)
  const clientSecret = setupIntents[0]?.clientSecret

  const isMounted = useIsMounted()

  const options = {
    // passing the client secret obtained from the server
    clientSecret,
    loader: 'never',
  }

  useEffect(
    () => {
      dispatch(doFetchSetupIntents())
    },
    [dispatch]
  )

  const handleOnReady = useCallback(
    () => {
      // Some loading appears to happen even after onReady is called
      // from the payment method. Here we're just waiting an additional
      // 500 ms for that to finish so that no "empty" or "loading" state
      // shows to the customer after the groove spinner is removed
      setTimeout(() => {
        if (isMounted()) {
          setIsReady(true)
          onReady()
        }
      }, 500)
    },
    [setIsReady, onReady, isMounted]
  )

  const { onSetupSuccess: handleOnSubmitted } = useSetupIntent({ onSubmitted })

  useEffect(
    () => {
      const ensureClientSecret = async () => {
        try {
          if (state === 'pending') {
            if (clientSecret) {
              setState('loaded')
            } else {
              if (isMounted()) setState('loading')

              const fetchSetupResponse = await dispatch(doFetchSetupIntents())
              if (fetchSetupResponse.billing.cardSetupIntents.length > 0) {
                setState('loaded')
              } else if (isMounted()) {
                setState('creating')
                const createResponse = await dispatch(doCreateSetupIntent())
                if (
                  isMounted() &&
                  createResponse.cardSetupIntentCreate.setupIntent
                ) {
                  setState('loaded')
                } else {
                  setState('failed')
                }
              }
            }
          }
        } catch (error) {
          setState('failed')
          // handle errors as needed
          // eslint-disable-next-line no-console
          debug('Error fetching data:', error)
        }
      }

      ensureClientSecret()
    },
    [clientSecret, state, dispatch, isMounted]
  ) // Dependencies array, adjust as needed

  const isLoaded = state === 'loaded'

  useEffect(
    () => {
      if (isLoaded && isReady) {
        setTimeout(() => {
          if (isMounted()) {
            setShowSpinner(false)
          }
        }, 250)
      }
    },
    [isLoaded, isMounted, isReady]
  )

  return (
    <div className={className} css={styles.container}>
      {showSpinner && (
        <div
          css={[styles.spinner, isLoaded && isReady && styles.spinnerLoaded]}
        >
          <Spinner className="grui self-center" size="medium" />
        </div>
      )}
      {isLoaded && (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm
            onCancel={onCancel}
            showFormButtons={showFormButtons}
            formId={formId}
            onReady={handleOnReady}
            onSubmitting={onSubmitting}
            onSubmitted={handleOnSubmitted}
            onError={onError}
            saveSubscription={saveSubscription}
            convertTrials={convertTrials}
            pricingIds={pricingIds}
            billingCycle={billingCycle}
          />
        </Elements>
      )}
    </div>
  )
}

export default SetupPaymentMethodCard
