import { css } from '@emotion/react'

const table = theme => css`
  overflow: hidden;
  tbody tr:hover {
    td:last-of-type > * {
      opacity: 1;
      visibility: visible;
      color: ${theme.color.monochrome.mediumDark};
    }
  }
`
const compactTable = theme => css`
  tbody tr {
    .row-controls {
      position: relative;

      .controls {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 10px;
      }
    }

    &:hover {
      .row-controls {
        .controls {
          opacity: 1;
          visibility: visible;
          color: ${theme.color.monochrome.mediumDark};
        }

        /* Only hide components if controls actually exist */
        .components:not(.controls-disabled) {
          display: none;
        }
      }
    }
  }
`

const hiddenRuleNameCell = theme => css`
  color: ${theme.color.monochrome.mediumDark};
`

const limitedTooltip = theme => css`
  background-color: ${theme.color.info['900']};
  border-radius: ${theme.spacing[4]};
`

export const styles = {
  table,
  compactTable,
  hiddenRuleNameCell,
  limitedTooltip,
}
