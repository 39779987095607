export const mailboxTypes = [
  {
    key: 'selected',
    title: `Specific ${app.t('mailboxes')}`,
  },
  {
    key: 'all',
    title: `All ${app.t('mailboxes')}`,
  },
]
