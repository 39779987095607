import { css } from '@emotion/react'

const table = theme => css`
  tbody tr td:first-of-type {
    color: ${theme.color.monochrome.black};
  }
`

export const styles = {
  table,
}
