function createActionBuilder() {
  let store
  const actions = {}
  const actionReducers = {}
  function buildActionCreator(name, argNames) {
    return function() {
      const args = Array.prototype.slice.call(arguments)
      const data = {}
      let i
      for (i = 0; i < argNames.length; i++) {
        const name = argNames[i]
        data[name] = args[i]
      }
      return { type: name, data }
    }
  }

  function defineAction(name, argNames, reducerOperation, actionCreator) {
    if (typeof argNames === 'function') actionCreator = argNames
    if (!actionCreator) actionCreator = buildActionCreator(name, argNames)
    if (actions[name]) throw `Action '${name} is already defined!'`
    actions[name] = function() {
      const args = Array.prototype.slice.call(arguments)
      store.dispatch(actionCreator.apply(this, args))
    }
    if (reducerOperation) actionReducers[name] = reducerOperation
  }

  return {
    define: defineAction,
    actions,
    reducers: actionReducers,
    reduce(state, action) {
      const handler = actionReducers[action.type]
      return handler(state, action.data)
    },
    canReduce(name) {
      return !!actionReducers[name]
    },
    attach(newStore) {
      store = newStore
    },
  }
}

module.exports = createActionBuilder()
