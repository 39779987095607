import { selectApp, selectChannels } from 'ducks/integrations/slack/selectors'
import { useMemo } from 'react'
import { useController } from 'react-hook-form'
import { useSelector } from 'react-redux'
import SearchableSelect from 'subapps/settings/components/SearchableSelect'

const ChannelDropdown = ({ control, name, defaultValue }) => {
  const {
    field: { onChange, onBlur, value },
  } = useController({ control, name, defaultValue })

  const slackApp = useSelector(selectApp)
  const channels = useSelector(selectChannels)

  const items = useMemo(
    () => {
      const retVal = []
      const existingChannelIds = new Set(
        slackApp?.channels.map(c => c.slack_id)
      )
      channels.forEach(c => {
        // NOTE (jscheel): Only show channels that don't already exist.
        if (
          !existingChannelIds.has(c.slack_id) ||
          c.slack_id === defaultValue
        ) {
          retVal.push({
            key: c.slack_id,
            name: `#${c.name}`,
          })
        }
      })
      return retVal
    },
    [channels, slackApp, defaultValue]
  )

  return (
    <SearchableSelect
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      data={items}
      control={control}
      searchPlaceholder="Search channels..."
      placeholder="Select channel..."
      size="small"
    />
  )
}

export default ChannelDropdown
