import React from 'react'
import Dropdown from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import TagDropdownIndicator from './indicator'

const { Menu } = Dropdown

const TagDropdownMenu = ({ tags }) => {
  return (
    <Menu>
      {tags.map(tag => (
        <Menu.Item key={tag.id} itemKey={tag.id}>
          <TagDropdownIndicator {...tag} />
        </Menu.Item>
      ))}
    </Menu>
  )
}

export default React.memo(TagDropdownMenu)
