import { css } from '@emotion/react'

const fields = theme => css`
  & > * {
    & + * {
      margin-top: ${theme.spacing[12]};
    }
  }
`

const field = theme => css`
  max-width: 100%;
  input:read-only {
    color: ${theme.color.monochrome.mediumDark};
  }
`

const dropdownContainer = css`
  & > * {
    width: 100%;

    & > button {
      min-width: unset;
      width: inherit;
    }
  }
`

export const styles = {
  fields,
  field,
  dropdownContainer,
}
