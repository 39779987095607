import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { string, bool } from 'prop-types'
import { doDeleteSpammer } from 'ducks/spammers/operations'
import EntityDeleteDrawer from '../EntityDelete'
import SpammerDeleteContent from './Content'

const SpammerDeleteDrawer = props => {
  const {
    title,
    deleteBtnText,
    open,
    drawerResourceId,
    onClose,
    onExit,
    onRemove,
  } = props

  const dispatch = useDispatch()

  const handleOnRemove = useCallback(
    () => {
      dispatch(doDeleteSpammer(drawerResourceId))
      if (onRemove) onRemove()
    },
    [dispatch, drawerResourceId, onRemove]
  )

  return (
    <EntityDeleteDrawer
      {...props}
      onRemove={handleOnRemove}
      onClose={onExit}
      tertiaryBtnText="Cancel"
      onCancel={onClose}
      title={title}
      deleteBtnText={deleteBtnText}
      open={open}
    >
      <SpammerDeleteContent id={drawerResourceId} />
    </EntityDeleteDrawer>
  )
}

SpammerDeleteDrawer.propTypes = {
  title: string,
  deleteBtnText: string,
  open: bool.isRequired,
  drawerResourceId: string,
}

SpammerDeleteDrawer.defaultProps = {
  title: `Remove address from blacklist`,
  deleteBtnText: 'Remove',
  drawerResourceId: '',
  queryId: null,
}

export default SpammerDeleteDrawer
