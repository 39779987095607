import * as types from 'subapps/kb/actions'

const defaultState = {
  byId: {},
  ids: [],
}

const reducers = {}

reducers[types.FETCH_THEMES_SUCCESS] = (state, action) => {
  const byId = {}
  const ids = []

  action.data.forEach(theme => {
    byId[theme.id] = theme
    ids.push(theme.id)
  })

  return {
    ...state,
    byId,
    ids,
  }
}

export default function reducer(state = defaultState, action) {
  const handler = reducers[action.type]
  if (handler) return handler(state, action)
  return state
}
