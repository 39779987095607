import React from 'react'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'

const Footer = ({ saveBtnTitle, onSelect, onCancel }) => {
  return (
    <div className="footer">
      <Button
        size="small"
        type="info"
        css={text.styles.fontMedium}
        className="grui mr-5"
        onClick={onSelect}
      >
        {saveBtnTitle}
      </Button>
      <Button
        type="tertiary"
        size="small"
        css={text.styles.fontMedium}
        onClick={onCancel}
      >
        Cancel
      </Button>
    </div>
  )
}

export default Footer
