import { connect } from 'react-redux'
import doRedirectToBilling from 'subapps/settings/actions/doRedirectToBilling'

import PremiumIntegrationsView from './View'

const perform = {
  redirectToBilling: doRedirectToBilling,
}

export default connect(null, perform)(PremiumIntegrationsView)
