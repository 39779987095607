import cn from 'classnames'
import styled from '@emotion/styled'

import { Avatar, Button, Tooltip } from 'shared/ui'
import themeVars from 'ui_theme/site/globals/site.variables'

import AuthorizeModal from '../../../shared/AuthorizeModal'

export default styled(InstallOwner)`
  border: 1px solid ${props => props.theme.color.monochrome.medium};
  border-radius: ${themeVars.defaultBorderRadius};
  margin-bottom: ${props => props.theme.spacing.base};
  opacity: ${themeVars.disabledOpacity};
  padding: ${props => props.theme.spacing['5']}
    ${props => props.theme.spacing['7']} ${themeVars.mini}
    ${props => props.theme.spacing['5']};

  &.canAdminister,
  &.installed {
    opacity: 1;
  }

  .header {
    align-items: center;
    display: flex;
    margin-bottom: ${props => props.theme.spacing['4']};
    h4 {
      margin: 0;
      line-height: ${props => props.theme.spacing['14']};
      flex-grow: 1;
    }
  }

  .disabledText {
    font-size: ${props => props.theme.spacing['6']};
  }

  .installedOn {
    font-size: ${props => props.theme.spacing['7']};

    h6 {
      font-size: ${props => props.theme.spacing['7']};
      font-weight: normal;
      margin: 0;
    }

    ul {
      font-size: ${props => props.theme.spacing['7']};
      margin: 0 0 0;
      padding-left: ${props => props.theme.spacing['0']};
      list-style: none;
    }
  }

  .ui.button {
    margin-right: 0;
  }
`

function InstallOwner({
  avatarUrl,
  className,
  databaseId,
  install,
  installPath,
  isViewer,
  install: {
    id: installId,
    repositories,
    repository_selection: repositorySelection,
  } = {},
  login,
  name,
  viewerCanAdminister,
}) {
  const canAdminister = isViewer || viewerCanAdminister
  const disabledText = `You must be an organization administrator on GitHub to ${
    install ? 'configure' : 'install'
  } the Groove GitHub app for an
  organization. Please contact your GitHub organization administrator.`
  return (
    <Tooltip
      className={cn(className, {
        canAdminister,
        installed: !!install,
      })}
      enabled={!canAdminister}
      portal
      position="left"
      tooltip={disabledText}
    >
      <div className="grui flex flex-row">
        <Avatar
          url={avatarUrl}
          size={32}
          style={{
            float: 'left',
            marginRight: themeVars.small,
          }}
        />
        <div className="grui flex-1">
          <div className="header">
            <h4>{name}</h4>
            {canAdminister &&
              (install ? (
                <Button
                  as="a"
                  basic
                  href={
                    isViewer
                      ? `https://github.com/settings/installations/${installId}`
                      : `https://github.com/organizations/${login}/settings/installations/${installId}`
                  }
                  rel="noopener noreferrer"
                  size="tiny"
                  target="_blank"
                >
                  Configure
                </Button>
              ) : (
                <AuthorizeModal
                  className="tiny"
                  message="Taking you to GitHub"
                  path={installPath}
                  params={{
                    return_to: window.location.href,
                    target_id: databaseId,
                  }}
                  primary
                  text="Install"
                />
              ))}
          </div>
          {!canAdminister && <p className="disabledText">{disabledText}</p>}
          {install && (
            <div className="installedOn">
              {repositorySelection === 'all' ? (
                'Installed on all repositories.'
              ) : (
                <React.Fragment>
                  <h6>Installed on:</h6>
                  <ul>
                    {repositories.map(
                      ({ html_url: htmlUrl, name: repoName }) => (
                        <li>
                          <a
                            href={htmlUrl}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {repoName}
                          </a>
                        </li>
                      )
                    )}
                  </ul>
                </React.Fragment>
              )}
            </div>
          )}
        </div>
      </div>
    </Tooltip>
  )
}
