import React, { useEffect } from 'react'
import Link from 'redux-first-router-link'
import cn from 'classnames'
import styled from '@emotion/styled'
import { heading } from '@groovehq/internal-design-system/lib/styles/elements'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import { SETTINGS_BILLING_PAGE } from 'subapps/settings/types'
import useOpenContact from 'util/hooks/useOpenContact'
import { bool, node, number, oneOf, string } from 'prop-types'
import { capture } from 'ducks/tracking/actions'
import BigPlanLimitationCard from './Big'
import List from './List'
import { styles } from './styles'

const ButtonGroup = styled.div`
  ${styles.buttonGroup};
`

const TO_SETTINGS_BILLING_PLANS_PAGE = {
  type: SETTINGS_BILLING_PAGE,
  payload: {
    tab: 'plans',
  },
}

const TO_SETTINGS_BILLING_KB_PLANS_PAGE = {
  type: SETTINGS_BILLING_PAGE,
  payload: {
    tab: 'kb-plans',
  },
}

const PlanLimitationCard = ({
  children,
  className,
  title,
  size,
  btnGap,
  left,
  shouldGoToKbPlans,
  trackingFeatureType = null,
  primaryBtnText,
  chatBtnHidden,
}) => {
  const openContact = useOpenContact({ left })
  useEffect(
    () => {
      if (trackingFeatureType)
        capture(`features impression ${trackingFeatureType}`)
    },
    [trackingFeatureType]
  )

  return (
    <div
      css={styles.sizes[size]}
      className={cn('grui flex flex-wrap items-end', className)}
    >
      <div className={cn('grui mr-auto')}>
        {title && (
          <h5 css={heading.styles.h5} className="grui mb-2">
            {title}
          </h5>
        )}
        {children}
      </div>
      <ButtonGroup className="grui flex flex-wrap items-center" gap={btnGap}>
        <Button
          css={styles.btn}
          as={Link}
          to={
            shouldGoToKbPlans
              ? TO_SETTINGS_BILLING_KB_PLANS_PAGE
              : TO_SETTINGS_BILLING_PLANS_PAGE
          }
          type="info"
        >
          {primaryBtnText}
        </Button>
        {!chatBtnHidden && (
          <Button css={styles.btn} onClick={openContact} type="tertiary">
            Chat to sales
          </Button>
        )}
      </ButtonGroup>
    </div>
  )
}

PlanLimitationCard.Big = BigPlanLimitationCard
PlanLimitationCard.List = List

PlanLimitationCard.propTypes = {
  size: oneOf(['small', 'medium']),
  className: string,
  children: node,
  title: node,
  btnGap: number,
  left: bool,
  primaryBtnText: string,
  chatBtnHidden: bool,
}

PlanLimitationCard.defaultProps = {
  size: 'medium',
  className: undefined,
  children: undefined,
  title: undefined,
  btnGap: 8,
  left: true,
  primaryBtnText: 'See plans',
  chatBtnHidden: false,
}

export default PlanLimitationCard
