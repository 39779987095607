import React, { useState, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { redirect } from 'redux-first-router'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import { heading } from '@groovehq/internal-design-system/lib/styles/elements'
import ButtonList from '@groovehq/internal-design-system/lib/components/ButtonList/ButtonList'
import {
  ChatBotOutlinedV2 as ChatBotOutlined,
  Plus,
} from '@groovehq/internal-design-system/lib/assets/icons'
import { AdminAccessDrawer } from 'subapps/settings/components/drawers/NoAccess'
import {
  SETTINGS_CHANNELS_PAGE,
  SETTINGS_WIDGET_CREATE_NEW_PAGE,
} from 'subapps/settings/types'
import { useDrawer } from 'ducks/drawers/hooks'
import { buildDrawerQueryParam, getCurrentDrawerId } from 'ducks/drawers/util'
import { selectQueryParams } from 'selectors/location'
import { DRAWER_ID_FOLDERS_CHANNELS_SELECT_WIDGET } from 'ducks/drawers/ids'
import { DRAWER_TYPE_CHANNELS_SELECT_WIDGET } from 'ducks/drawers/types'
import { FEATURE_INBOX_LIVE_CHAT } from 'ducks/billing/featureTypes'
import { useFeature } from 'ducks/billing/hooks'

const creationOptions = [
  {
    icon: <ChatBotOutlined />,
    title: 'Select an existing widget',
    type: 'old',
  },
  {
    icon: <Plus viewBox="0 0 18 18" />,
    title: 'Create a new widget',
    type: 'new',
  },
]

const AddLiveChat = ({ onClose, onExit, previousDrawer }) => {
  const dispatch = useDispatch()
  const [selectedType, setSelectedType] = useState(null)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const queryParams = useSelector(selectQueryParams)
  // get the current drawer id from the url string in browser address bar
  const currentDrawerId = getCurrentDrawerId(queryParams)
  const { canUseFeature } = useFeature(FEATURE_INBOX_LIVE_CHAT)

  const { drawerId: nextDrawerId, openDrawer: openNextDrawer } = useDrawer({
    id: DRAWER_ID_FOLDERS_CHANNELS_SELECT_WIDGET,
    type: DRAWER_TYPE_CHANNELS_SELECT_WIDGET,
    closeIgnoresStack: true,
  })

  useEffect(
    () => {
      if (isDrawerOpen && selectedType && currentDrawerId !== nextDrawerId) {
        // prevent drawer from being triggered to open again if the drawer is closed
        // i.e. closed being, the previous state's drawer is missing/changed from address bar query
        setSelectedType(null)
        setIsDrawerOpen(false)
      } else if (selectedType && currentDrawerId !== nextDrawerId) {
        // now useDrawer is initialised with value from currentDrawer
        // invoke openDrawer
        openNextDrawer('new', {
          query: {
            ...buildDrawerQueryParam(nextDrawerId, 'drawerChannelType', 'chat'),
          },
        })
        setIsDrawerOpen(true)
      }
    },
    [
      currentDrawerId,
      nextDrawerId,
      setSelectedType,
      openNextDrawer,
      isDrawerOpen,
      selectedType,
    ]
  )

  useEffect(
    () => {
      if (!canUseFeature) {
        onExit()
        dispatch(
          redirect({
            type: SETTINGS_CHANNELS_PAGE,
          })
        )
      }
    },
    [onExit, canUseFeature, dispatch]
  )

  const handleNext = useCallback(
    type => {
      if (type === 'new') {
        dispatch({
          type: SETTINGS_WIDGET_CREATE_NEW_PAGE,
        })
      } else {
        setSelectedType(type)
      }
    },
    [dispatch]
  )

  const handleBack = useCallback(
    () => {
      onClose({ ignoreStack: false })
    },
    [onClose]
  )

  const footer = (
    <ModalBtns
      tertiaryBtnText={previousDrawer ? 'Back' : 'Close'}
      onClickTertiaryBtn={handleBack}
    />
  )

  return (
    <AdminAccessDrawer title="Add a channel" footer={footer} onClose={onExit}>
      <h4 className="grui mt-14 mb-10" css={heading.styles.h4}>
        Choose where to add Live Chat to:
      </h4>
      <ButtonList
        list={creationOptions}
        onSelect={handleNext}
        selected={selectedType}
      />
    </AdminAccessDrawer>
  )
}

export default AddLiveChat
