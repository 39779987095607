export const categoryFields = `
  articlesCount
  articleIds
  authorId
  author {
    avatar_url
    name
  }
  createdAt
  description
  id
  knowledgeBaseId
  metaDescription
  metaRobots
  ogDescription
  ogImageUrl
  ogTitle
  pageTitle
  position
  slug
  state
  title
  updatedAt
`
