import React, { useCallback, useMemo } from 'react'
import { AdminAccessDrawer } from 'subapps/settings/components/drawers/NoAccess'
import RuleDataTable from 'subapps/settings/components/RuleDataTable'
import Tabbed from 'subapps/settings/components/drawers/Tabbed'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import { useDrawer } from 'ducks/drawers/hooks'
import { DRAWER_TYPE_RULES_UPDATE } from 'ducks/drawers/types'
import { FEATURE_INBOX_MAX_RULES } from 'ducks/billing/featureTypes'
import { useFeature } from 'ducks/billing/hooks'

const tabsData = [
  {
    key: 'active',
    tabName: 'Active',
    component: RuleDataTable,
    active: true,
  },
  {
    key: 'inactive',
    tabName: 'Inactive',
    component: RuleDataTable,
    active: false,
  },
]

const RulesOverview = ({
  drawerTabId = tabsData[0].key,
  open,
  onExit,
  updateDrawerParameter,
}) => {
  const { canUseFeature } = useFeature(FEATURE_INBOX_MAX_RULES)
  const showPlanLimitationForRules = !canUseFeature
  const activeTab = useMemo(
    () => {
      return tabsData.find(t => t.key === drawerTabId) || tabsData[0]
    },
    [drawerTabId]
  )

  const { openDrawer: openCreateDrawer } = useDrawer({
    type: DRAWER_TYPE_RULES_UPDATE,
  })

  const handleTabKeyChange = useCallback(
    key => {
      if (key === drawerTabId) return
      updateDrawerParameter('drawerTabId', key)
    },
    [drawerTabId, updateDrawerParameter]
  )

  const handleOnCreate = useCallback(
    () => {
      openCreateDrawer('new')
    },
    [openCreateDrawer]
  )

  const HeaderButtons = useCallback(
    () => {
      return (
        <div>
          <Button
            size="small"
            onClick={handleOnCreate}
            disabled={showPlanLimitationForRules}
          >
            Create rule
          </Button>
        </div>
      )
    },
    [handleOnCreate, showPlanLimitationForRules]
  )

  return (
    <AdminAccessDrawer title="Rules" open={open} onClose={onExit} size="wide">
      <Tabbed
        tabsData={tabsData}
        activeTabKey={drawerTabId}
        onTabKeyChange={handleTabKeyChange}
        HeaderButtons={HeaderButtons}
        compact
        active={activeTab.active}
      />
    </AdminAccessDrawer>
  )
}

export default RulesOverview
