import { useRef, useEffect } from 'react'
import isEqual from 'fast-deep-equal'

function deepCompareEquals(a, b) {
  return isEqual(a, b)
}

function useDeepCompareMemoize(value) {
  const ref = useRef()
  // it can be done by using useMemo as well
  // but useRef is rather cleaner and easier

  if (!deepCompareEquals(value, ref.current)) {
    ref.current = value
  }

  return ref.current
}

export default function useDeepCompareEffect(callback, dependencies) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callback, dependencies.map(useDeepCompareMemoize))
}
