import { css } from '@emotion/react'

const errorContainer = css`
  font-size: 13px;
`

const divider = theme => css`
  height: 1px;
  background-color: ${theme.color.monochrome.medium};
`

export const styles = {
  errorContainer,
  divider,
}
