import { connect } from 'react-redux'
import { doFetchTopLabels, doSearchLabels } from 'actions/labels'
import {
  doUpdateTicketSearchQuery,
  doToggleListSearchBoxFocused,
  doSubmitTicketsSearch,
  doClearSearch,
} from 'actions/search'
import { selectCurrentFolderContext } from 'selectors/app'
import { selectLabelsByMailboxId } from 'selectors/labels'
import { selectLabelSearchResultsByTerm } from 'selectors/labelings'
import {
  selectCurrentCommittedTicketSearchQueryString,
  selectDisplayedSearchQueryString,
} from 'selectors/search/base'
import { selectTicketSearchSuggestions } from 'selectors/search_suggestions'
import { selectIsFreeFormSearch } from 'selectors/search'
import { selectCustomersByEmail } from 'ducks/customers/selectors'
import { doSearchRecipients } from 'ducks/drafts2/operations'

import SearchView from 'components/ConversationList/Header/Search'
import { selectCurrentSubmittedTicketSearchFilterMatches } from 'selectors/search/searchFilters'

const select = state => {
  return {
    committedSearchQuery: selectCurrentCommittedTicketSearchQueryString(state),
    folderContext: selectCurrentFolderContext(state),
    isFreeFormSearch: selectIsFreeFormSearch(state),
    labelsByTerm: selectLabelSearchResultsByTerm(state),
    searchQuery: selectDisplayedSearchQueryString(state),
    customersByEmail: selectCustomersByEmail(state),
    suggestions: selectTicketSearchSuggestions(state),
    topLabels: selectLabelsByMailboxId(state).null,
    currentSubmittedTicketSearchFilterMatches: selectCurrentSubmittedTicketSearchFilterMatches(
      state
    ),
  }
}

const perform = {
  doFetchTopLabels,
  doSearchLabels,
  doToggleListSearchBoxFocused,
  doUpdateTicketSearchQuery,
  doSearchRecipients,
  doSubmitTicketsSearch,
  doClearSearch,
}

export default connect(select, perform)(SearchView)
