import React, { useCallback, useMemo, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Drawer from '@groovehq/internal-design-system/lib/components/Drawer/UnmanagedDrawer'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import HeaderWithImage from '@groovehq/internal-design-system/lib/components/HeaderWithImage/HeaderWithImage'
import { useDrawer } from 'ducks/drawers/hooks'
import { buildDrawerQueryParam } from 'ducks/drawers/util'
import {
  useRedirectToChannel,
  useChannelDetailsConfig,
} from 'ducks/channels/hooks'
import { isBridgeChannelType } from 'ducks/channels/channelTypes'
import { doBuildInboxMenuFromMailboxes } from 'ducks/folders/operations/collections'
import { CHANNEL_TYPES, drawerConfig } from '../Channels.data'
import { styles } from './styles'

const { DEFAULT_DRAWER_TITLE } = drawerConfig

const AuthenticateSuccess = ({
  onExit,
  drawerChannelType: channelType,
  drawerResourceId: channelId,
  drawerSource: source = 'settings',
}) => {
  const dispatch = useDispatch()
  const { name } = CHANNEL_TYPES[channelType] || {}
  const { drawer: nextDrawer } = useChannelDetailsConfig(channelType)
  const { drawerId: nextDrawerId, openDrawer: openNextDrawer } = useDrawer(
    nextDrawer
  )
  const { rebuildExitAndRedirect } = useRedirectToChannel({ channelId, onExit })

  useEffect(
    () => {
      dispatch(doBuildInboxMenuFromMailboxes())
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [channelId]
  )

  const handleOpenNextDrawer = useCallback(
    () => {
      if (!nextDrawer) {
        rebuildExitAndRedirect()
        return
      }

      openNextDrawer(channelId, {
        query: {
          ...buildDrawerQueryParam(
            nextDrawerId,
            'drawerChannelType',
            channelType
          ),
        },
      })
    },
    [
      openNextDrawer,
      channelId,
      channelType,
      nextDrawerId,
      nextDrawer,
      rebuildExitAndRedirect,
    ]
  )

  const footer = useMemo(
    () => {
      const title = isBridgeChannelType(channelType) ? 'Channel' : 'Inbox'
      return (
        <ModalBtns
          saveBtnText={source === 'settings' && `Setup access to this ${title}`}
          onSave={handleOpenNextDrawer}
          tertiaryBtnText={source === 'inbox' && 'Close'}
          onClickTertiaryBtn={onExit}
        />
      )
    },
    [handleOpenNextDrawer, onExit, channelType, source]
  )

  if (channelType === 'facebook') {
    return (
      <Drawer title="Add a Messenger channel" onClose={onExit} footer={footer}>
        <HeaderWithImage
          className="grui mt-14"
          css={styles.header}
          title="Authentication successful"
          intro={`Your new widget is now linked to Facebook and can receive new messages.`}
        />
      </Drawer>
    )
  }

  if (channelType === 'instagram') {
    return (
      <Drawer
        title="Add a Instagram Messenger channel"
        onClose={onExit}
        footer={footer}
      >
        <HeaderWithImage
          className="grui mt-14"
          css={styles.header}
          title="Authentication successful"
          intro={`Your new widget is now linked to Instagram and can receive new messages.`}
        />
      </Drawer>
    )
  }

  return (
    <Drawer title={DEFAULT_DRAWER_TITLE} onClose={onExit} footer={footer}>
      <HeaderWithImage
        className="grui mt-14"
        css={styles.header}
        title="Authentication successful"
        intro={`Your ${name} account is now linked up to Groove and you’ll
          receive new incoming email.`}
      />
    </Drawer>
  )
}

export default AuthenticateSuccess
