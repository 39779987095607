import graphql from 'api/graphql'
import { isBlank } from 'util/strings'
import * as types from 'subapps/kb/actions'

import { oauthTokenSelector } from 'selectors/app'
import { selectCategoriesStale } from 'subapps/kb/selectors/categories'
import { selectCurrentKnowledgeBaseId } from 'subapps/kb/selectors/knowledge_bases'
import { doFetchArticlesCounts } from '../articles/doFetchArticlesCounts'
import { doFetchCategoriesCounts } from './doFetchCategoriesCounts'
import { categoryFields } from './categoryFields'

/*
 * Fetch categories
 *
 * opts:
 *   keyword - The String keyword to search by, default: ''
 *   selectedState - The String state to filter by
 *   sortByOrder - The String, sort by field and order: [asc, desc]
 *   currentPage - The Integer page number to fetch
 *   perPage - The Integer number of results per page
 *   refetch - The Boolean, whether to refetch
 */

export function doFetchCategories(_opts) {
  return (dispatch, getState) => {
    const opts = _opts || {}
    const state = getState()
    const token = oauthTokenSelector(state)
    const knowledgeBaseId = selectCurrentKnowledgeBaseId(state)
    const isStale = selectCategoriesStale(state)

    const {
      ids,
      keyword,
      selectedState,
      sortByOrder,
      currentPage,
      perPage,
    } = state.kb.categories

    // these options may come as nulls to reset the filters
    const newKeyword = opts.keyword !== undefined ? opts.keyword : keyword
    const newSelectedState =
      opts.selectedState !== undefined ? opts.selectedState : selectedState

    const newSortByOrder = opts.sortByOrder || sortByOrder
    const newPerPage = opts.perPage || perPage

    // When filters change, do a clean fetch, otherwise fetch next page
    const filtersChanged =
      opts.refetch === true ||
      newSortByOrder !== sortByOrder ||
      newKeyword !== keyword ||
      newSelectedState !== selectedState ||
      newPerPage !== perPage

    const pageChanged = opts.currentPage && currentPage !== opts.currentPage

    // To avoid extra requests, do not re-fetch if filters or
    // page are unchanged and we have fetched some records before
    if (!filtersChanged && !pageChanged && ids.length > 0 && !isStale) {
      return
    }

    // If any of the filters changed, reset back to first page
    const newCurrentPage = filtersChanged ? 1 : opts.currentPage || currentPage

    dispatch({
      type: types.FETCH_CATEGORIES_REQUEST,
      selectedState: newSelectedState,
    })

    // fetch article counts for current kb
    dispatch(doFetchArticlesCounts())

    let states = [newSelectedState]

    if (newSelectedState === 'all') {
      states = ['published', 'wip', 'draft']
    } else if (newSelectedState === 'published') {
      states = ['published', 'wip']
    }

    const query = `
      query CategoriesQuery($knowledgeBaseId: ID!, $state: [String]) {
        categories(
          knowledgeBaseId: $knowledgeBaseId
          state: $state
          keyword: "${isBlank(newKeyword) ? '*' : newKeyword}"
          page: ${newCurrentPage}
          perPage: ${newPerPage}
          order: "${newSortByOrder}"
        ){
          data {
            ${categoryFields}
          }
          meta {
            totalCount
            currentPage
            totalPages
          }
        }
      }
    `

    const variables = {
      knowledgeBaseId,
      state: states,
    }

    graphql(token, query, variables)
      .then(res => {
        const data = res.json.data
        dispatch({
          type: types.FETCH_CATEGORIES_SUCCESS,
          data: data.categories.data,
          meta: data.categories.meta,
          keyword: newKeyword,
          sortByOrder: newSortByOrder,
          perPage: newPerPage,
        })

        dispatch(
          doFetchCategoriesCounts({
            keyword: newKeyword,
          })
        )
      })
      .catch(() => {
        dispatch({
          type: types.FETCH_CATEGORIES_FAILURE,
        })
      })
  }
}
