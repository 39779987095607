import React, { useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CellWithAvatar from '@groovehq/internal-design-system/lib/components/Table/CellWithAvatar/CellWithAvatar'
import { selectAgentById } from 'selectors/agents/base'
import { doLoadAgents } from 'actions/agents'

const CellWithAgentAvatar = ({ className, agentId }) => {
  const { name, avatar_url: avatarUrl } =
    useSelector(state => selectAgentById(state, agentId)) || {}
  const dispatch = useDispatch()

  useEffect(
    () => {
      dispatch(doLoadAgents())
    },
    [dispatch]
  )
  const data = useMemo(
    () => ({
      value: name,
      avatarSize: 'xl',
      avatar: avatarUrl,
    }),
    [name, avatarUrl]
  )
  return <CellWithAvatar className={className} data={data} />
}

CellWithAgentAvatar.defaultProps = {
  className: undefined,
  agentId: '',
}

export default CellWithAgentAvatar
