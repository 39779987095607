import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { redirect } from 'redux-first-router'
import { AdminAccessDrawer } from 'subapps/settings/components/drawers/NoAccess'
import Field from '@groovehq/internal-design-system/lib/components/Field/Field'
// import Help from '@groovehq/internal-design-system/lib/components/Field/Help/Help'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import Radio from '@groovehq/internal-design-system/lib/components/Radio/Radio'
import Checkbox from '@groovehq/internal-design-system/lib/components/Checkbox/Checkbox'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, useController } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { styles as fieldStyles } from '@groovehq/internal-design-system/lib/components/Field/Field.styles'
import AnimatedEllipsis from '@groovehq/internal-design-system/lib/components/AnimatedEllipsis/AnimatedEllipsis'
import { selectIsInInbox } from 'selectors/location'
import {
  selectPendingFolderById,
  selectIsLoadingFolderById,
  selectHasLoadedFolderById,
  selectHasErrorFolderById,
} from 'ducks/folders/selectors/folders'
import {
  doUpdateSingleFolder,
  doCreateSingleFolder,
  doFetchFolderByIdsV2,
  doFetchFoldersForDataTable,
} from 'ducks/folders/operations/folders'
import { doBuildInboxMenuFromMailboxes } from 'ducks/folders/operations/collections'
import { DRAWER_TYPE_FOLDERS_OVERVIEW } from 'ducks/drawers/types'
import { doFetchMailboxesAndBuildInboxMenu } from 'ducks/mailboxes/actions'
import { getRawId } from 'util/globalId'
import { useRhfDirtyHold, useSimpleDrawerConfirmHolds } from 'util/dirtyHolds'
import { SETTINGS_FOLDERS_PAGE } from 'subapps/settings/types'
import { useFeature } from 'ducks/billing/hooks'
import { FEATURE_INBOX_MAX_FOLDERS } from 'ducks/billing/featureTypes'
import MessageCard from '@groovehq/internal-design-system/lib/components/MessageCard/MessageCard'

import { styles } from './styles'
import UserSelection from '../../UserSelection'
import MailboxSelection from '../../MailboxSelection'
import FolderConditions from './FolderConditions'
import StateSwitch from './StateSwitch'
import { isConflicting } from '../../Condition/util'
import { folderVariables } from './data'

// keys should preferably match the object being stored in the entity store
// that way for updates, you can just spread the entity in reset() and not have to set each property individually
const FORM_SCHEMA = yup.object().shape({
  id: yup.string().nullable(),
  name: yup.string().required('Name is required'),
  conditions: yup
    .array(
      yup.object().shape({
        id: yup.string().nullable(),
        operator: yup.string().required('Filter operator is required'),
        param: yup.string().required('Filter is required'),
        value: yup.string().nullable(),
      })
    )
    .required('Filters are required')
    .min(1, 'Need at least one filter'),
})

const EMPTY_FORM_STATE = {
  // 1. defaultValue has to match yup form schema
  // 2. don't set key value for array fields, useFieldArray will set it
  // so the array fields won't be dirty if only their keys are different
  id: null,
  name: '',
  state: 'ACTIVE',
  displayCountWhenInactive: true,
  hideIfZeroConversations: false,
  viewAccess: 'all-agents',
  channelVisibility: 'all',
  matchType: 'ALL',
  agentIds: [],
  groupIds: [],
  channelIds: [],
  conditions: [
    {
      operator: null,
      param: null,
      value: '',
    },
  ],
}

export default function FolderEditDrawer({
  open,
  onClose,
  onExit,
  drawerId,
  drawerResourceId: folderId,
  previousDrawer,
  ...rest
}) {
  const [areFormFieldsLoaded, setAreFormFieldsLoaded] = useState(false)
  const [isConflictingParam, setIsConflictingParam] = useState([])
  const [isSaving, setIsSaving] = useState(null)
  const dispatch = useDispatch()
  const isUpdate = !!folderId && folderId !== 'new'
  const formId = `${rest?.drawer}-${rest?.drawerId}-${folderId}`

  useEffect(
    () => {
      if (!open) return

      if (isUpdate) {
        dispatch(
          doFetchFolderByIdsV2([folderId], { targetStore: 'pending', isUpdate })
        )
      }
    },
    [dispatch, isUpdate, folderId, open]
  )

  const folder = useSelector(state => selectPendingFolderById(state, folderId))
  const isLoadingFolderForUpdate = useSelector(selectIsLoadingFolderById)
  const hasLoadedFolderForUpdate = useSelector(selectHasLoadedFolderById)
  const hasErrorFolderForUpdate = useSelector(selectHasErrorFolderById)
  const isInInbox = useSelector(selectIsInInbox)
  const { canUseFeature } = useFeature(FEATURE_INBOX_MAX_FOLDERS)
  const showPlanLimitation = !canUseFeature

  const isLoading = isUpdate ? isLoadingFolderForUpdate : false
  const hasError = isUpdate ? hasErrorFolderForUpdate : false
  const notFound = isUpdate ? hasLoadedFolderForUpdate && !folder : false
  const isConflict = isConflictingParam.length > 0

  // form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty },
    control,
    setValue,
    watch,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(FORM_SCHEMA),
    defaultValues: EMPTY_FORM_STATE,
  })

  const matchTypeValue = watch('matchType')
  const conditionsValue = watch('conditions')

  const {
    field: {
      onChange: onDisplayCountWhenInactiveChange,
      value: displayCountWhenInactiveValue,
      ref: displayCountWhenInactiveRef,
    },
  } = useController({ name: 'displayCountWhenInactive', control })

  const {
    field: { onChange: onAgentIdsChange, value: agentIdsValue },
  } = useController({ name: 'agentIds', control })

  const {
    field: { onChange: onGroupIdsChange, value: groupIdsValue },
  } = useController({ name: 'groupIds', control })

  const {
    field: {
      onChange: onViewAccessChange,
      value: viewAccessValue,
      ref: viewAccessRef,
      onBlur: onViewAccessBlur,
    },
  } = useController({ name: 'viewAccess', control })

  const {
    field: { onChange: onChannelIdsChange, value: channelIdsValue },
  } = useController({ name: 'channelIds', control })

  const {
    field: {
      onChange: onChannelVisibilityChange,
      value: channelVisibilityValue,
      ref: channelVisibilityRef,
      onBlur: onChannelVisibilityBlur,
    },
  } = useController({ name: 'channelVisibility', control })

  const { releaseHold, holdKey } = useRhfDirtyHold(formId, control)

  const onToggleHideCount = useCallback(
    e => {
      onDisplayCountWhenInactiveChange(!e.target.checked)
    },
    [onDisplayCountWhenInactiveChange]
  )

  const onConditionsChange = useCallback(
    () => {
      if (matchTypeValue === 'ALL') {
        const unassignedConditions = conditionsValue.filter(condition => {
          return condition.value !== null && condition.operator !== 'NOT_EQ'
        })
        setIsConflictingParam(isConflicting(unassignedConditions))
      } else {
        setIsConflictingParam([])
      }
    },
    [conditionsValue, matchTypeValue]
  )

  useEffect(
    () => {
      onConditionsChange()
    },
    [matchTypeValue, onConditionsChange, conditionsValue]
  )

  const buildInboxMenuFromMailboxes = useCallback(
    async () => {
      // Fetch folders and rebuild inbox menu if it's in the inbox page
      // and this drawer was opened directly without through the overview drawer
      if (
        isInInbox &&
        previousDrawer?.drawer !== DRAWER_TYPE_FOLDERS_OVERVIEW
      ) {
        await dispatch(doFetchFoldersForDataTable())
        if (isUpdate) {
          dispatch(doBuildInboxMenuFromMailboxes())
        } else {
          // New folder
          dispatch(doFetchMailboxesAndBuildInboxMenu())
        }
      }
    },
    [isInInbox, dispatch, isUpdate, previousDrawer?.drawer]
  )

  const onSubmit = useCallback(
    async data => {
      setIsSaving(true)
      if (isUpdate) {
        await dispatch(doUpdateSingleFolder(data))
      } else {
        await dispatch(doCreateSingleFolder(data))
      }
      buildInboxMenuFromMailboxes()
      releaseHold()
      setIsSaving(false)
      onClose()
    },
    [dispatch, onClose, isUpdate, releaseHold, buildInboxMenuFromMailboxes]
  )

  const [handleOnClose, handleOnExit] = useSimpleDrawerConfirmHolds(
    holdKey,
    onClose,
    onExit
  )

  const conflictedParamsToHuman = useMemo(
    () => {
      const matchedVariables = folderVariables.parameters.filter(variable => {
        return isConflictingParam.includes(variable.value)
      })
      return matchedVariables.map(matchedVariable => matchedVariable.name)
    },
    [isConflictingParam]
  )

  // Effects
  useEffect(
    () => {
      if (!open) return
      if (isUpdate) {
        if (folder) {
          // load fields with template values
          const channelIds = Object.values(folder.channels).map(item => item.id)
          const agentIds = Object.values(folder.agents).map(item =>
            getRawId(item.id)
          )
          const groupIds = Object.values(folder.teams).map(item =>
            getRawId(item.id)
          )
          reset({
            // just a spread here cos the needed keys should match the form schema
            ...folder,
            channelIds,
            agentIds,
            groupIds,
          })
          setAreFormFieldsLoaded(true)
        }
      } else {
        setAreFormFieldsLoaded(true)
      }
    },
    // We intentionally exclude dependency props here to ensure that this
    // reset only triggers for the initial load
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [folder, isUpdate]
  )

  useEffect(
    () => {
      if (showPlanLimitation && !isUpdate) {
        dispatch(
          redirect({
            type: SETTINGS_FOLDERS_PAGE,
          })
        )
      }
    },
    [showPlanLimitation, dispatch, isUpdate]
  )

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)}>
      <AdminAccessDrawer
        {...rest}
        open={open}
        onClose={handleOnExit}
        size="wide"
        title={isUpdate ? `Edit ${app.t('folder')}` : `New ${app.t('folder')}`}
        isLoading={isLoading || !areFormFieldsLoaded}
        isError={hasError}
        isNoResultFound={notFound}
        footer={
          <ModalBtns
            saveBtnDisabled={!isValid || isSaving || !isDirty || isConflict}
            saveBtnText={
              <>
                {isSaving && (
                  <span>
                    Saving<AnimatedEllipsis />
                  </span>
                )}
                {!isSaving && isUpdate && 'Save'}
                {!isSaving && !isUpdate && 'Create'}
              </>
            }
            // type="submit" will automatically trigger the form submit event when clicked
            saveBtnHtmlType="submit"
            saveBtnForm={formId}
            tertiaryBtnText="Cancel"
            onClickTertiaryBtn={handleOnClose}
          />
        }
      >
        <div className="grui pt-10 pb-10">
          <div css={styles.topFields} className="grui mb-12">
            <Field
              {...register('name')}
              label="Name"
              placeholder="Enter name..."
              name="name"
              validateStatus={errors?.name ? 'error' : undefined}
              help={errors?.name?.message}
            />
            <div css={[fieldStyles.labelBox, styles.stateSwitch]}>
              <StateSwitch control={control} />
            </div>
          </div>
          <div className="grui mb-16">
            <div css={fieldStyles.labelBox} className="grui mb-10">
              Include in the {app.t('folder')}
            </div>

            <Radio
              {...register('matchType')}
              id="allConditions"
              value="ALL"
              className="grui mb-8"
            >
              {app.t('Tickets')} that match ALL of the filters set below
            </Radio>
            <br />
            <Radio
              {...register('matchType')}
              id="specificConditions"
              value="ANY"
            >
              {app.t('Tickets')} that match ANY of the filters set below
            </Radio>

            <FolderConditions
              control={control}
              setValue={setValue}
              onConditionsChange={onConditionsChange}
            />
            {isConflict && (
              <MessageCard type="negative" className="grui mt-8">
                You have conflicting conditions:
                <ul className="grui mt-0">
                  {conflictedParamsToHuman.map(conflictedParams => (
                    <li>{conflictedParams}</li>
                  ))}
                </ul>
              </MessageCard>
            )}
            {/* For some reason, errors.condition does not get cleared, even though isValid is true after adding a filter. Will look into after launch */}
            {/* {errors?.conditions && (
              <Help
                className="grui justify-start"
                validateStatus="error"
                help={errors.conditions?.message}
              />
            )} */}
          </div>

          <div className="grui mb-16">
            <div css={fieldStyles.labelBox} className="grui mb-8">
              Select which {app.t('agents')} have access
            </div>
            <UserSelection
              type={viewAccessValue}
              agentIds={agentIdsValue}
              groupIds={groupIdsValue}
              onUserListChange={onAgentIdsChange}
              onGroupListChange={onGroupIdsChange}
              onUserAccessibilityTypeChange={onViewAccessChange}
              css={styles.selection}
              userAccessibilityTypeRef={viewAccessRef}
              onUserAccessibilityTypeBlur={onViewAccessBlur}
              checkboxListStyles={styles.checkboxList}
            />
          </div>
          <div className="grui mb-16">
            <div css={fieldStyles.labelBox} className="grui mb-8">
              Select which {app.t('mailboxes')} to include the {app.t('folder')}{' '}
              in
            </div>
            <MailboxSelection
              selectedIds={channelIdsValue}
              type={channelVisibilityValue}
              onMailboxListChange={onChannelIdsChange}
              onMailboxVisibilityTypeChange={onChannelVisibilityChange}
              css={styles.selection}
              checkboxListStyles={styles.checkboxList}
              mailboxVisibilityTypeRef={channelVisibilityRef}
              onMailboxVisibilityTypeBlur={onChannelVisibilityBlur}
              dropdownBtnSize="small"
            />
          </div>
          <div className="grui mb-16">
            <div css={fieldStyles.labelBox} className="grui mb-8">
              Display
            </div>
            {/*
            * using controller instead of register for the checkbox because the value of
            * the checkbox is the inverse of the value that needs to be stored in formState
            */}
            <Checkbox
              id="hideCount"
              checked={!displayCountWhenInactiveValue}
              onChange={onToggleHideCount}
              className="grui mb-8"
              ref={displayCountWhenInactiveRef}
            >
              Hide conversation count in side navigation
            </Checkbox>
            <Checkbox
              {...register('hideIfZeroConversations')}
              id="hideIfZeroConvos"
            >
              Hide {app.t('folder')} in side navigation if conversation count is
              0
            </Checkbox>
          </div>
        </div>
      </AdminAccessDrawer>
    </form>
  )
}
