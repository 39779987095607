import { css } from '@emotion/react'

const list = theme => css`
  li {
    background-color: ${theme.color.monochrome.superLight};
    border: 1px solid ${theme.color.monochrome.medium};
    border-radius: ${theme.spacing[4]};
    display: flex;
    align-items: center;
    height: ${theme.spacing[16]};
    padding-right: ${theme.spacing[7]};

    & + li {
      margin-top: ${theme.spacing[5]};
    }
  }
`

const handle = theme => css`
  background-color: ${theme.color.monochrome.light};
  color: ${theme.color.monochrome.mediumDark};
  padding: 0 ${theme.spacing[5]};
  align-self: stretch;
  border-top-left-radius: ${theme.spacing[4]};
  border-bottom-left-radius: ${theme.spacing[4]};
  display: flex;
  align-items: center;
  margin-right: ${theme.spacing[7]};

  &:hover {
    color: ${theme.color.monochrome.dark};
  }

  svg {
    width: ${theme.spacing[12]};
    height: ${theme.spacing[12]};
  }
`

export const styles = {
  list,
  handle,
}
