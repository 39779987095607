import React, { useMemo } from 'react'
import Header from '@groovehq/internal-design-system/lib/components/Header/Header'

const Tabbed = ({
  tabsData,
  onTabKeyChange,
  activeTabKey,
  HeaderButtons,
  ...rest
}) => {
  const ActivePanel = useMemo(
    () => tabsData.find(t => t.key === activeTabKey).component,
    [tabsData, activeTabKey]
  )

  return (
    <div className="grui mb-10">
      <Header
        tabsData={tabsData}
        activeKey={activeTabKey}
        onTabChange={onTabKeyChange}
        tabType="small"
        className="grui mb-8"
        compact
      >
        {HeaderButtons && <HeaderButtons />}
      </Header>
      <ActivePanel {...rest} />
    </div>
  )
}

export default Tabbed
