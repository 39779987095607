import React, { useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { useFieldArray, useForm } from 'react-hook-form'
import {
  text,
  list,
} from '@groovehq/internal-design-system/lib/styles/elements'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import { AdminAccessDrawer } from 'subapps/settings/components/drawers/NoAccess'
import { selectWidgetSettingsById } from 'ducks/widgets/selectors/settings'
import debug from 'util/debug'
import { useRhfDirtyHold } from 'util/dirtyHolds'
import { doUpdateSettings } from 'ducks/widgets/operations'
import ReorderContactFormField from './ReorderContactFormField'
import { styles } from './styles'
import {
  generateDefaultValueForFormFields,
  WIDGET_CONTACT_FORM_FIELDS,
} from '../util'

const FORM_ID = 'reorder-custom-fields'

const ReorderContactFormFields = ({
  drawerResourceId: widgetId,
  open,
  onClose,
  onExit,
  resetField,
  replace,
  ...rest
}) => {
  const dispatch = useDispatch()
  const settings = useSelector(state =>
    selectWidgetSettingsById(state, widgetId)
  )

  const {
    handleSubmit,
    reset,
    control,
    formState: { isDirty, isSubmitting },
  } = useForm({
    mode: 'all',
    defaultValues: {
      customFields: generateDefaultValueForFormFields(
        settings[WIDGET_CONTACT_FORM_FIELDS]
      ),
    },
  })
  const { fields, move } = useFieldArray({
    control,
    name: 'customFields',
  })
  const { releaseHold } = useRhfDirtyHold(FORM_ID, control)
  const isLoading = !fields || !fields.length

  const onSubmit = useCallback(
    async data => {
      try {
        const settingsData = {
          ...settings,
          [WIDGET_CONTACT_FORM_FIELDS]: generateDefaultValueForFormFields(
            data.customFields,
            true
          ),
        }
        await dispatch(
          doUpdateSettings(settingsData, widgetId, { shouldShowToasts: true })
        )
        releaseHold()
        onClose()
        if (resetField && replace) {
          const customFieldsToReset = generateDefaultValueForFormFields(
            data.customFields
          )
          // resetField can't update the required value according to the new order (each item's register name is using index)
          // so need to replace the array values first
          replace(customFieldsToReset)
          resetField(WIDGET_CONTACT_FORM_FIELDS, {
            defaultValue: customFieldsToReset,
          })
        }
      } catch (error) {
        debug(error)
      }
    },
    [releaseHold, dispatch, widgetId, settings, onClose, resetField, replace]
  )

  const handleDragEnd = useCallback(
    event => {
      const { active, over } = event
      if (active.id !== over.id) {
        const oldIndex = fields.findIndex(item => item.id === active.id)
        const newIndex = fields.findIndex(item => item.id === over.id)
        move(oldIndex, newIndex)
      }
    },
    [move, fields]
  )

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  const handleCancel = useCallback(
    e => {
      releaseHold()
      onClose(e)
    },
    [onClose, releaseHold]
  )

  useEffect(
    () => {
      reset({
        customFields: settings[WIDGET_CONTACT_FORM_FIELDS],
      })
    },
    [settings, reset]
  )

  return (
    <AdminAccessDrawer
      {...rest}
      open={open}
      onClose={onExit}
      title="Reorder questions"
      isLoading={isLoading}
      footer={
        <ModalBtns
          saveBtnText="Save"
          tertiaryBtnText="Cancel"
          onClickTertiaryBtn={handleCancel}
          saveBtnDisabled={!isDirty || isSubmitting}
          saveBtnHtmlType="submit"
          saveBtnForm={FORM_ID}
        />
      }
    >
      <form
        className="grui mt-10"
        id={FORM_ID}
        onSubmit={handleSubmit(onSubmit)}
      >
        <p
          className="grui mt-4"
          css={[text.styles.textNormal, text.styles.textDark]}
        >
          Drag & drop the questions in the order in which you would like them
          display in your contact form.
        </p>
        <ul
          className="grui mt-12"
          css={[list.styles.preflight, styles.list, text.styles.textSm]}
        >
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={fields}
              strategy={verticalListSortingStrategy}
            >
              {fields.map(({ id, name }) => (
                <ReorderContactFormField key={id} id={id} name={name} />
              ))}
            </SortableContext>
          </DndContext>
        </ul>
      </form>
    </AdminAccessDrawer>
  )
}

export default ReorderContactFormFields
