import React from 'react'
import { useSelector } from 'react-redux'
import { selectIsImpersonating } from 'selectors/app/selectIsImpersonating'
import { styles as fieldStyles } from '@groovehq/internal-design-system/lib/components/Field/Field.styles'
import Accordion from '@groovehq/internal-design-system/lib/components/Accordion/Accordion'

import Checkbox from '@groovehq/internal-design-system/lib/components/Checkbox/Checkbox'

const SuperAdminOptions = ({
  prorate = null,
  scheduleChanges = null,
  onChange,
}) => {
  return (
    <Accordion className="grui -ml-7 -mr-7 mt-8">
      <Accordion.Panel panelKey="1" title="Super Admin">
        <div className="grui my-8 mx-8">
          <div className="grui mt-10">
            <div css={fieldStyles.labelBox} className="grui mb-5">
              Schedule changes
            </div>
            <Checkbox
              id="scheduleChanges"
              indeterminate={scheduleChanges === null}
              checked={!!scheduleChanges}
              onChange={onChange}
            >
              {scheduleChanges === null &&
                'Default: Upgrades immediate, downgrades scheduled'}
              {scheduleChanges === true && 'Schedule all changes'}
              {scheduleChanges === false && 'Immediately apply all changes'}
            </Checkbox>
          </div>
          <div className="grui mt-10">
            <div css={fieldStyles.labelBox} className="grui mb-5">
              Prorata bill changes
            </div>
            <Checkbox
              id="prorate"
              indeterminate={prorate === null}
              checked={!!prorate}
              onChange={onChange}
            >
              {prorate === null && 'Default immediate changes prorated'}
              {prorate === true && 'Prorate immediate and scheduled changes'}
              {prorate === false && 'Prorate disabled'}
            </Checkbox>
          </div>
        </div>
      </Accordion.Panel>
    </Accordion>
  )
}

export const BillingSuperAdminOptions = props => {
  const isImpersonating = useSelector(selectIsImpersonating)
  if (!isImpersonating) return null
  return <SuperAdminOptions {...props} />
}
