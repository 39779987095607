import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'

export const fullWidth = css`
  min-width: 100%;
`

export const hidden = css`
  display: none;
`

export const copy = theme => css`
  color: rgba(${theme.color.monochrome.black_rgba}, 0.8);
  line-height: ${theme.lineHeight.leadingNone};
  margin-bottom: ${rem(13)};
`
