import { useMemo } from 'react'

const useColumns = () => {
  const columns = useMemo(() => {
    return [
      {
        Header: 'Notifications',
        accessor: 'name',
        id: 'name',
        disableSortBy: true,
      },
    ]
  }, [])

  return columns
}

export default useColumns
