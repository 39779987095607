export const SIGNUP_PAGE = 'onboarding/SIGNUP_PAGE'
export const CUSTOMER_INFORMATION_PAGE = 'onboarding/CUSTOMER_INFORMATION_PAGE'
export const CHANNEL_OVERVIEW_PAGE = 'onboarding/CHANNEL_OVERVIEW_PAGE'
export const ADD_TEAM_MEMBERS_PAGE = 'onboarding/ADD_TEAM_MEMBERS_PAGE'
export const ADD_CHANNEL_PAGE = 'onboarding/ADD_CHANNEL_PAGE'
export const SETUP_EMAIL_PAGE = 'onboarding/SETUP_EMAIL_PAGE'
export const COMPLETED_PAGE = 'onboarding/COMPLETED_PAGE'
export const ACCEPT_INVITE_PAGE = 'onboarding/ACCEPT_INVITE_PAGE'
export const FACEBOOK_PAGE_SELECTION_PAGE =
  'onboarding/FACEBOOK_PAGE_SELECTION_PAGE'
