import React from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Grab } from '@groovehq/internal-design-system/lib/assets/icons'
import { button } from '@groovehq/internal-design-system/lib/styles/elements'
import { styles } from './styles'

const ReorderContactFormField = ({ id, name, className }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }
  return (
    <li className={className} ref={setNodeRef} style={style}>
      <button
        type="button"
        className="grui cursor-grabbing questions-sorting-handle"
        css={[button.styles.preflight, styles.handle]}
        {...attributes}
        {...listeners}
      >
        <Grab />
      </button>
      <div className="grui truncate">{name}</div>
    </li>
  )
}

export default ReorderContactFormField
