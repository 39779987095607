import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { string, bool, oneOf } from 'prop-types'
import { doDeleteTeams } from 'ducks/teams/actions'
import { buildDrawerQueryParam } from 'ducks/drawers/util'
import EntityDeleteDrawer from '../EntityDelete'
import TeamDeleteContent from './Content'

const TeamDeleteDrawer = props => {
  const {
    title,
    deleteBtnText,
    open,
    drawerResourceId,
    drawerDeleteMode,
    onClose,
    onExit,
    onRemove,
    previousDrawer,
  } = props

  const dispatch = useDispatch()

  const ids = useMemo(
    () => {
      return drawerResourceId.split(',')
    },
    [drawerResourceId]
  )
  const handleOnRemove = useCallback(
    () => {
      dispatch(doDeleteTeams(ids))
      if (onRemove) onRemove()
    },
    [dispatch, ids, onRemove]
  )

  const closeOptions = useCallback(
    inputCloseParams => {
      const { isAfterDelete } = inputCloseParams
      let closeParams = inputCloseParams
      if (isAfterDelete) {
        closeParams = {
          ...inputCloseParams,
          ...{
            query: {
              ...buildDrawerQueryParam(
                previousDrawer.drawerId,
                'drawerIsAfterDelete',
                true
              ),
            },
          },
        }
      }

      return closeParams
    },
    [previousDrawer]
  )

  const handleOnClose = useCallback(
    inputCloseParams => {
      onClose(closeOptions(inputCloseParams))
    },
    [onClose, closeOptions]
  )

  const handleOnExit = useCallback(
    inputCloseParams => {
      onExit(closeOptions(inputCloseParams))
    },
    [onExit, closeOptions]
  )

  return (
    <EntityDeleteDrawer
      {...props}
      onRemove={handleOnRemove}
      onClose={handleOnExit}
      tertiaryBtnText="Cancel"
      onCancel={handleOnClose}
      title={title}
      deleteBtnText={deleteBtnText}
      open={open}
    >
      <TeamDeleteContent ids={ids} deleteMode={drawerDeleteMode} />
    </EntityDeleteDrawer>
  )
}

TeamDeleteDrawer.propTypes = {
  title: string,
  deleteBtnText: string,
  open: bool.isRequired,
  drawerDeleteMode: oneOf(['ids', 'query']),
  drawerResourceId: string,
}

TeamDeleteDrawer.defaultProps = {
  title: `Delete ${app.t('team')}`,
  deleteBtnText: 'Delete',
  drawerDeleteMode: 'ids',
  drawerResourceId: '',
  queryId: null,
}

export default TeamDeleteDrawer
