import {
  isPageVisibilityApiEnabled,
  getVisibilityState,
  prefixedVisibilityChangeEventType,
  isStandalone,
} from 'util/browser'
import config from 'config'

class WindowVisibility {
  static PAGE_ACTIVE_INTERVAL = 2000
  static PAGE_ACTIVE_INTERVAL_ERROR_MARGIN = 500

  // NOTE (jscheel): Because we have three separate mechanisms for determining
  // page visibility, _isVisible is used to prevent duplicate event triggers.
  _isVisible = true
  // NOTE (jscheel): Do NOT use performance.now. This is a hack that relies on
  // Safari's bad handling of setInterval. Calls to performance.now are paused,
  // so the delta will not be wrong, meaning this will drift when it should.
  _intervalDriftingTime = Date.now()
  _intervalDriftingLock = false
  _intervalTimer = null
  _listeners = new Map([['focus', new Set()], ['blur', new Set()]])

  constructor() {
    this._attachListeners()
  }

  // NOTE (jscheel): We ignore capture, as it doesn't matter for this particular
  // application of what we are doing with page visibility.
  addEventListener(type, callback) {
    if (type !== 'focus' && type !== 'blur') {
      throw new Error(`Invalid event type: "${type}" for WindowVisibility`)
    }
    this._listeners.get(type).add(callback)
  }

  removeEventListener(type, callback) {
    if (type !== 'focus' && type !== 'blur') {
      throw new Error(`Invalid event type: "${type}" for WindowVisibility`)
    }
    this._listeners.get(type).delete(callback)
  }

  _triggerFocusCallbacks(thisContext = null, resetIntervalListener = true) {
    if (!this._isVisible) {
      this._listeners.get('focus').forEach(callback => {
        callback.apply(thisContext, arguments)
      })
      if (resetIntervalListener) {
        this._attachIntervalListeners()
      }
      this._isVisible = true
    }
  }

  _triggerBlurCallbacks(thisContext = null, resetIntervalListener = true) {
    if (this._isVisible) {
      this._listeners.get('blur').forEach(callback => {
        callback.apply(thisContext, arguments)
      })
      if (resetIntervalListener) {
        this._attachIntervalListeners()
      }
      this._isVisible = false
    }
  }

  _attachListeners() {
    this._attachPageVisibilityListeners()
    this._attachWindowFocusListeners()
    // NOTE (jscheel): Can't leave setInterval running in specs.
    if (config.env !== 'test') {
      this._attachIntervalListeners()
    }
  }

  // NOTE (jscheel): Page Visibility API only fires within the browser. It does
  // not detect important visibility changes such as the user switching
  // applications in the OS. Primarily, it only deals with tab switching.
  _attachPageVisibilityListeners() {
    if (!isPageVisibilityApiEnabled) {
      return
    }
    const _this = this
    document.addEventListener(
      prefixedVisibilityChangeEventType,
      function() {
        const cbThis = this
        if (getVisibilityState() === 'visible') {
          _this._triggerFocusCallbacks(cbThis)
        } else {
          _this._triggerBlurCallbacks(cbThis)
        }
      },
      false
    )
  }

  // NOTE (jscheel): Window focus listeners are more robust, but they also tend
  // to fire a bit to often (such as when switching to the dev tool while on the
  // same page). However, these fill in the gap of application switching left
  // open by the Page Visibility API, as well as fill in for any weird browsers
  // that may not support the Page Visibility API yet.
  _attachWindowFocusListeners() {
    const _this = this
    window.addEventListener('focus', function(e) {
      _this._triggerFocusCallbacks(this)
    })
    window.addEventListener('blur', function(e) {
      _this._triggerBlurCallbacks(this)
    })
  }

  // NOTE (jscheel): Interval listeners are used to detect when a tab is paused
  // by a browser's memory management, or when a mobile device switches off
  // before a Page Visibility API or window blur event can be fired. This relies
  // on the fact that timers are paused and then started back up when the page
  // reactivates. If there is sufficient drift between the expected interval and
  // the actual measured interval, we assume the page has become "refocused".
  _attachIntervalListeners() {
    if (this._intervalTimer) {
      clearInterval(this._intervalTimer)
    }
    this._intervalDriftingLock = false
    this._intervalDriftingTime = Date.now()
    const lowInterval =
      WindowVisibility.PAGE_ACTIVE_INTERVAL -
      WindowVisibility.PAGE_ACTIVE_INTERVAL_ERROR_MARGIN
    const highInterval =
      WindowVisibility.PAGE_ACTIVE_INTERVAL +
      WindowVisibility.PAGE_ACTIVE_INTERVAL_ERROR_MARGIN
    this._intervalTimer = setInterval(() => {
      const currentTime = Date.now()
      const delta = currentTime - this._intervalDriftingTime
      const isDrifting = delta < lowInterval || delta > highInterval
      // NOTE (jscheel): If we are drifting and were not previously...
      if (isDrifting && !this._intervalDriftingLock) {
        this._triggerBlurCallbacks(this, false)
        this._intervalDriftingLock = true
      } else if (!isDrifting && this._intervalDriftingLock) {
        // NOTE (jscheel): If we aren't drifting, but were previously...
        if (getVisibilityState() === 'visible') {
          this._triggerFocusCallbacks(this)
        } else {
          this._intervalDriftingLock = false
        }
      }
      this._intervalDriftingTime = Date.now()
    }, WindowVisibility.PAGE_ACTIVE_INTERVAL)
  }
}

export default new WindowVisibility()
