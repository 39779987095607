import {
  V2_SIGNUP_PAGE,
  V2_COMPANY_PAGE,
  V2_OWNER_PAGE,
  V2_PASSWORD_PAGE,
  V2_VERIFICATION_PAGE,
  V2_COMPLETION_PAGE,
} from './pages'

// NOTE (jscheel): Simple configuration object for onboarding routes to load

const V2_ROUTES_CONFIG = {
  [V2_SIGNUP_PAGE]: {
    route: {
      path: '/signup/f2',
    },
    handler: () => ({
      componentName: 'V2SignupPage',
      subapp: 'onboarding',
    }),
  },
  [V2_COMPANY_PAGE]: {
    route: {
      path: '/signup/f2/organization',
    },
    handler: () => ({
      componentName: 'V2CompanyPage',
      subapp: 'onboarding',
    }),
  },
  [V2_OWNER_PAGE]: {
    route: {
      path: '/signup/f2/me',
    },
    handler: () => ({
      componentName: 'V2OwnerPage',
      subapp: 'onboarding',
    }),
  },
  [V2_PASSWORD_PAGE]: {
    route: {
      path: '/signup/f2/password',
    },
    handler: () => ({
      componentName: 'V2PasswordPage',
      subapp: 'onboarding',
    }),
  },
  [V2_VERIFICATION_PAGE]: {
    route: {
      path: '/signup/f2/verification',
    },
    handler: () => ({
      componentName: 'V2VerificationPage',
      subapp: 'onboarding',
    }),
  },
  [V2_COMPLETION_PAGE]: {
    route: {
      path: '/signup/f2/done',
    },
    handler: () => ({
      componentName: 'V2CompletionPage',
      subapp: 'onboarding',
    }),
  },
}

export default V2_ROUTES_CONFIG
