import styled from '@emotion/styled'

export default styled('div')`
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: ${props => props.theme.spacing['14']};

  h4 {
    margin-bottom: ${props => props.theme.spacing['10']};
  }
`
