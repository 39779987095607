import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import Drawer from '@groovehq/internal-design-system/lib/components/Drawer/UnmanagedDrawer'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import Field from '@groovehq/internal-design-system/lib/components/Field/Field'
import MessageCard from '@groovehq/internal-design-system/lib/components/MessageCard/MessageCard'
import toast from '@groovehq/internal-design-system/lib/components/Toast'
import { styles as headingStyles } from '@groovehq/internal-design-system/lib/styles/elements/Heading/heading.styles'
import { styles as textStyles } from '@groovehq/internal-design-system/lib/styles/elements/Text/text.styles'
import { useFetchIntegrationProvider } from 'ducks/integrations/hooks'
import { doAddShopifyIntegration } from 'ducks/integrations/operations/oauth'
import { selectPendingInstalledStoreDomainsByProviderId } from 'ducks/integrations/selectors/settings'
import { useConfirmHoldsCallback, useRhfDirtyHold } from 'util/dirtyHolds'
import { SHOPIFY_DOMAIN } from 'ducks/integrations/shared/constants'
import { useDrawer } from 'ducks/drawers/hooks'
import { DRAWER_TYPE_SHOPIFY_CONFIGURE } from 'ducks/drawers/types'
import { styles } from './styles'

const AUTH_ERRORS = {
  ERR_USER_CLOSED_POPUP:
    'Oh no! Looks like you closed the Shopify authentication pop up. Please try again.',
  ALREADY_LINKED: 'This Shopify store has already been connected.',
  ERR_FETCH_OAUTH_URL:
    'An unknown error occurred. This could be due to network connectivity or an error from the authentication service.',
  DEFAULT:
    'The Shopify store could not be linked. Please check your store domain and your Shopify login credentials and try again.',
}

export default function ShopifyAddStore({
  drawerResourceId: providerId,
  open,
  onClose,
  onExit,
  onSuccess,
  drawerId,
  drawer,
  ...rest
}) {
  const dispatch = useDispatch()
  const [errorCode, setErrorCode] = useState(null)
  const [isInstalling, setIsInstalling] = useState(false)

  const { isLoading, hasError, isMissing } = useFetchIntegrationProvider(
    providerId
  )

  const storeDomains = useSelector(state =>
    selectPendingInstalledStoreDomainsByProviderId(state, providerId)
  )

  const { openDrawer: openConfigureDrawer } = useDrawer(
    DRAWER_TYPE_SHOPIFY_CONFIGURE
  )

  const FORM_SCHEMA = useMemo(
    () => {
      return yup.object().shape({
        storeName: yup
          .string()
          .trim()
          .lowercase()
          .required('Store name is required')
          .matches(
            /^[a-z0-9-]+$/,
            'Your store name only contains letters, numbers, and dashes. Please check your store name and try again.'
          )
          .notOneOf(
            storeDomains,
            'This Shopify store has already been connected.'
          ),
      })
    },
    [storeDomains]
  )

  const {
    register,
    formState: { errors, isValid, isDirty },
    setValue,
    handleSubmit,
    control,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(FORM_SCHEMA),
    defaultValues: {
      storeName: '',
    },
  })

  const { retainHold, releaseHold } = useRhfDirtyHold(drawerId, control)

  const onCloseErrorMessageCard = useCallback(() => {
    setErrorCode(null)
  }, [])

  const handleError = useCallback(error => {
    setErrorCode(error)
  }, [])

  const onSubmit = useCallback(
    async ({ storeName }) => {
      try {
        retainHold()
        setIsInstalling(true)
        await dispatch(
          doAddShopifyIntegration(providerId, `${storeName}${SHOPIFY_DOMAIN}`)
        )
        setIsInstalling(false)
        releaseHold()
        if (onSuccess) {
          onSuccess()
        } else {
          openConfigureDrawer('SHOPIFY_V2')
        }
        toast.notify({
          content: 'Shopify installed successfully!',
          type: 'positive',
        })
      } catch (error) {
        setIsInstalling(false)
        handleError(error)
      }
    },
    [
      dispatch,
      handleError,
      onSuccess,
      providerId,
      retainHold,
      releaseHold,
      openConfigureDrawer,
    ]
  )

  const handlePaste = useCallback(
    e => {
      e.preventDefault()
      const clean = (e.clipboardData.getData('Text') || '').replace(
        /(^https?:\/\/|\.myshopify\.com.*)/g,
        ''
      )
      setValue('storeName', clean, { shouldDirty: true, shouldValidate: true })
    },
    [setValue]
  )

  const handleOnClose = useConfirmHoldsCallback(drawerId, onClose, [onClose])
  const handleOnExit = useConfirmHoldsCallback(null, onExit, [onExit])

  const oauthErrorMessage = useMemo(
    () => {
      if (!errorCode) return null

      return AUTH_ERRORS[errorCode]
    },
    [errorCode]
  )

  const DrawerForm = useCallback(
    subProps => <form onSubmit={handleSubmit(onSubmit)} {...subProps} />,
    [handleSubmit, onSubmit]
  )

  return (
    <Drawer
      {...rest}
      open={open}
      size="narrow"
      onClose={handleOnExit}
      isLoading={isLoading}
      isError={hasError}
      isNoResultFound={isMissing}
      title="Connect Shopify"
      footer={
        <ModalBtns
          saveBtnDisabled={
            !isValid ||
            !isDirty ||
            isMissing ||
            isLoading ||
            hasError ||
            isInstalling
          }
          saveBtnText="Connect your store"
          tertiaryBtnText="Cancel"
          onClickTertiaryBtn={handleOnClose}
          saveBtnHtmlType="submit"
        />
      }
      container={DrawerForm}
    >
      <div>
        <h4 css={headingStyles.h4} className="grui pb-10">
          Connect your Shopify store
        </h4>
        {oauthErrorMessage && (
          <div css={styles.messageCardContainer} className="grui pb-10">
            <MessageCard
              type="negative"
              closeable
              onClose={onCloseErrorMessageCard}
            >
              {oauthErrorMessage}
            </MessageCard>
          </div>
        )}
        <span css={textStyles.textNormal}>
          To add your Shopify store to your Groove account, you will need to
          login to provide us with the URL of the store.<br />
          <br /> You will be redirected to Shopify in the next step to log in
          and connect your store.
        </span>
        <div className="grui pt-12">
          <Field
            {...register('storeName')}
            label="Your store URL"
            name="storeName"
            placeholder="Enter store domain"
            tagPosition="right"
            tagContent={SHOPIFY_DOMAIN}
            validateStatus={errors?.storeName ? 'error' : undefined}
            help={errors?.storeName?.message}
            css={styles.fieldContainer}
            onPaste={handlePaste}
          />
        </div>
      </div>
    </Drawer>
  )
}
