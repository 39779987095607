import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util'

const label = theme => css`
  font-size: ${rem(19)};
  color: ${theme.color.info['900']};
  font-weight: ${theme.fontWeight.medium};
`

const currentTicketNotice = theme => css`
  color: ${theme.color.monochrome.mediumDark};
`

const noticeMessage = theme => css`
  color: ${theme.color.monochrome.mediumDark};
`

const switchButton = css`
  right: ${rem(24)};
  position: absolute;
`

const arrowDown = theme => css`
  top: 4px;
  display: flex;
  position: relative;
  color: ${theme.color.monochrome.dark};

  &:before {
    content: '';
    top: -1px;
    left: 7.5px;
    width: 4px;
    height: 12px;
    position: absolute;
    background: ${theme.color.monochrome.dark};
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
`

export const styles = {
  label,
  currentTicketNotice,
  noticeMessage,
  switchButton,
  arrowDown,
}
