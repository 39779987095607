/* eslint-disable react/jsx-no-bind */
import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import HeaderWithImage from '@groovehq/internal-design-system/lib/components/HeaderWithImage/HeaderWithImage'
import ButtonList from '@groovehq/internal-design-system/lib/components/ButtonList/ButtonList'
import { AdminAccessDrawer } from 'subapps/settings/components/drawers/NoAccess'
import { useDrawer } from 'ducks/drawers/hooks'
import { buildDrawerQueryParam } from 'ducks/drawers/util'
import { doFetchMailbox } from 'actions/mailboxes'
import { selectCurrentChannelById } from 'ducks/channels/selectors'
import { CHANNEL_EMAIL_TYPES } from '../Channels.data'

const SetupEmailChannel = ({
  onClose,
  onExit,
  previousDrawer,
  drawerResourceId: channelId,
}) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(null)
  const stateChannel = useSelector(state =>
    selectCurrentChannelById(state, channelId)
  )

  // Ensure we load a fresh copy of mailbox and hydrate the draft
  // with the latest information
  useEffect(
    () => {
      if (isLoading !== null) return

      // Kevin R
      // This is what the linter said I should do
      async function loadChannel() {
        setIsLoading(true)
        const mailbox = await dispatch(doFetchMailbox(channelId))
        if (!mailbox) {
          onClose()
        } else {
          setIsLoading(false)
        }
      }
      loadChannel()
    },
    [dispatch, channelId, isLoading, onClose]
  )

  const [selectedChannel, setSelectedChannel] = useState('google')
  const { drawerId: nextDrawerId, openDrawer: openNextDrawer } = useDrawer(
    CHANNEL_EMAIL_TYPES[selectedChannel].editDrawer
  )

  const channelList = useMemo(() => {
    return Object.keys(CHANNEL_EMAIL_TYPES).map(type => {
      const channelListType = CHANNEL_EMAIL_TYPES[type]
      const channel = {
        ...CHANNEL_EMAIL_TYPES[type],
        type,
        title: channelListType.editTitle,
      }
      return channel
    })
  }, [])

  const handleOpenNextDrawer = useCallback(
    () => {
      openNextDrawer(channelId, {
        query: {
          ...buildDrawerQueryParam(
            nextDrawerId,
            'drawerChannelType',
            selectedChannel
          ),
          ...buildDrawerQueryParam(nextDrawerId, 'drawerSource', 'inbox'),
        },
      })
    },
    [openNextDrawer, selectedChannel, nextDrawerId, channelId]
  )

  const handleBack = useCallback(
    () => {
      onClose({ ignoreStack: false })
    },
    [onClose]
  )

  const footer = (
    <ModalBtns
      saveBtnText="Next step"
      onSave={handleOpenNextDrawer}
      tertiaryBtnText={previousDrawer && 'Back'}
      onClickTertiaryBtn={handleBack}
    />
  )

  return (
    <AdminAccessDrawer
      title={`Setup ${app.t('mailbox')}`}
      footer={footer}
      onClose={onExit}
    >
      <HeaderWithImage
        className="grui mt-14 mb-12"
        title={`Complete your ${app.t('mailbox')} setup`}
        intro={`Let’s complete the setup to get your email from ${stateChannel.email ||
          stateChannel.name} into Groove.`}
      />
      <ButtonList
        list={channelList}
        onSelect={setSelectedChannel}
        selected={selectedChannel}
      />
    </AdminAccessDrawer>
  )
}

export default SetupEmailChannel
