export function findMailboxByLabel(mailboxes, label) {
  const fullMailbox = mailboxes.find(mailbox => mailbox.label === label)
  if (!fullMailbox) return null
  return fullMailbox
}

// just because 'All Inboxes' is enabled does not mean we always show it in left nav
// we only show 'All Inboxes' if the user has more than 1 mailbox
export const showAllInboxesMailboxInLeftNav = (
  prefersAllMailboxesSectionVisible,
  mailboxes
) => {
  return prefersAllMailboxesSectionVisible && (mailboxes?.length || 0) > 1
}

// Find if the mailboxes has at least two of them with the same name
export function hasMailboxesWithSameName(mailboxes) {
  return mailboxes.some(
    mailbox => mailboxes.filter(m => m.name === mailbox.name).length > 1
  )
}

export function getMailboxNameOrEmail(mailbox, mailboxes, options = {}) {
  if (!mailbox) return ''
  if (mailboxes?.length < 2) return mailbox.name
  const { nameWithEmail } = options
  const hasSameNamedMailboxes = hasMailboxesWithSameName(mailboxes)
  if (!hasSameNamedMailboxes) return mailbox.name
  if (nameWithEmail) return `${mailbox.name} (${mailbox.email})`
  return mailbox.email
}
