import { isEmpty } from 'util/arrays'

export const hasStateQueryFilter = (queryObject, stateType) => {
  const { state: states } = queryObject || {}

  return !isEmpty(states) && states.includes(stateType)
}

export const hasDeletedQueryFilter = queryObject => {
  const { deleted } = queryObject || {}

  return !isEmpty(deleted)
}

export const hasFolderQueryFilter = queryObject => {
  const { folder: folders } = queryObject || {}

  return !isEmpty(folders)
}
