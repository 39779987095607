import React, { useMemo } from 'react'
import { useFormContext, useController } from 'react-hook-form'
import Field from '@groovehq/internal-design-system/lib/components/Field/Field'
import DropdownMenu from 'subapps/settings/components/DropdownMenu'
import Dropdown from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'

import { styles as fieldStyles } from '@groovehq/internal-design-system/lib/components/Field/Field.styles'
import { styles } from './styles'
import { webhookDropdown, inputsData } from './data'

const { webhookUrl } = inputsData
const { Button } = Dropdown

const selectTitle = (all, key) => {
  const selected = all.find(item => item.key === key) || {
    title: '',
  }
  return selected.title
}

export default function WebhookCreateEditContent() {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext()

  const {
    field: {
      onChange: handleSelectEvent,
      ref: eventRef,
      value: selectedWebhook,
    },
  } = useController({
    name: 'event',
    control,
  })

  const selectedWebhookTitle = useMemo(
    () => selectTitle(webhookDropdown, selectedWebhook),
    [selectedWebhook]
  )

  return (
    <div className="grui pt-10">
      <div>
        <div css={fieldStyles.labelBox}>Webhook</div>
        <div css={styles.dropdownContainer}>
          <Dropdown
            overlay={<DropdownMenu data={webhookDropdown} />}
            onSelect={handleSelectEvent}
            selectedKey={selectedWebhook}
          >
            <Button css={styles.dropdownBtn} ref={eventRef}>
              {selectedWebhookTitle || '- Please select -'}
            </Button>
          </Dropdown>
        </div>
      </div>
      <Field
        css={styles.fullWidth}
        className="grui mt-12"
        {...webhookUrl}
        validateStatus={errors[webhookUrl.name] ? 'error' : undefined}
        help={errors[webhookUrl.name]?.message}
        {...register(webhookUrl.name)}
      />
    </div>
  )
}
