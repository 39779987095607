import { getMailboxNameOrEmail } from 'util/mailboxes'
import { suggestionList } from './constants'
import { _processQueryStringPart } from '.'

const allQueries = suggestionList.map(suggestion => suggestion.searchQuery)
// See more examples at getFilterMatchForQuery tests in src/components/ConversationList/Header/Search/__tests__/util.test.js
const operatorsPattern = `(${allQueries.join('|')})`
const valuePattern = `\\s*\\S*(?!\\S\\s)`
const valueInQuotesPattern = `\\s*(")[^"]*\\4`
export const filterRegex = `(${operatorsPattern}(${valueInQuotesPattern}|${valuePattern}))`
export const createRegexForFilter = filter =>
  new RegExp(`((${filter})(${valueInQuotesPattern}|${valuePattern}))`, 'g')

export const getFilterMatchForQuery = searchQuery => {
  const regex = new RegExp(filterRegex)

  return regex.exec(searchQuery)
}

// Amend inbox: filter to show mailbox email or name
// if there're multiple mailboxes with the same name, replace id with email
export const convertMailboxIdToEmailForMailboxFilter = (
  filterString,
  mailboxsById,
  mailboxes
) => {
  const filter = _processQueryStringPart(filterString)
  const filterName = `${filter.key}:`
  const value = filter.values[0]
  if (filterName === 'inbox:') {
    const mailbox = mailboxsById[value]
    if (!mailbox) return filterString
    return [filter.key, getMailboxNameOrEmail(mailbox, mailboxes)].join(':')
  }
  return filterString
}
