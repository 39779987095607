import { constructSearchQueryId } from 'util/search'

const aggregationKeyMap = {
  assignee_id: 'assignee',
  mailbox_id: 'mailbox',
  'labels.id': 'label',
  assignee_group_id: 'assigned_group',
  is_starred: 'starred',
  is_deleted: 'deleted',
}

export function mapAggregationsToSearches(
  baseSearchQueryObject,
  ticketAggregations
) {
  const searches = {}
  ticketAggregations.forEach(ticketAggregation => {
    const aggregationKey =
      aggregationKeyMap[ticketAggregation.id] || ticketAggregation.id
    const mailboxId = ticketAggregation.mailboxId
    const timestamp = ticketAggregation.timestamp
    ticketAggregation.counts.forEach(count => {
      let componentSearchQueryObject
      if (aggregationKey === 'assigned_group' && count.id === '') {
        componentSearchQueryObject = { group_unassigned: true }
      } else if (aggregationKey === 'assignee' && count.id === '') {
        componentSearchQueryObject = { unassigned: true }
      } else if (aggregationKey === 'unassigned') {
        componentSearchQueryObject = {
          unassigned: true,
          group_unassigned: true,
        }
      } else if (count.id === '') {
        return
      } else if (aggregationKey === 'starred' && count.id === 'T') {
        componentSearchQueryObject = { starred: 'all' }
      } else if (aggregationKey === 'deleted' && count.id === 'T') {
        componentSearchQueryObject = { deleted: 'all' }
      } else {
        componentSearchQueryObject = { [aggregationKey]: count.id }
      }
      const searchQueryObject = Object.assign(
        { state: [] },
        baseSearchQueryObject,
        componentSearchQueryObject
      )
      if (mailboxId) searchQueryObject.mailbox = mailboxId
      const searchQueryId = constructSearchQueryId(searchQueryObject)
      searches[searchQueryId] = {
        count: count.count,
        timestamp,
      }
    })
  })

  return searches
}
