export const CREATE_PAGE = 'kb/CREATE_PAGE'
export const DASHBOARD_PAGE = 'kb/DASHBOARD_PAGE'
export const LANDING_PAGE = 'kb/LANDING_PAGE'
export const NO_ACCESS_PAGE = 'kb/NO_ACCESS_PAGE'
export const PRO_UPGRADE_PAGE = 'kb/PRO_UPGRADE_PAGE'

// Articles
export const ARTICLES_PAGE = 'kb/ARTICLES_PAGE'
export const ARTICLE_CREATE_PAGE = 'kb/ARTICLE_CREATE_PAGE'
export const ARTICLE_EDIT_PAGE = 'kb/ARTICLE_EDIT_PAGE'

// Categories
export const CATEGORIES_PAGE = 'kb/CATEGORIES_PAGE'
export const CATEGORY_CREATE_PAGE = 'kb/CATEGORY_CREATE_PAGE'
export const CATEGORY_EDIT_PAGE = 'kb/CATEGORY_EDIT_PAGE'

// Settings
export const SETTINGS_PAGE = 'kb/SETTINGS_PAGE'

// Theme
export const THEME_EDIT_PAGE = 'kb/THEME_EDIT_PAGE'
