import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { AdminAccessDrawer } from 'subapps/settings/components/drawers/NoAccess'
import { styles as drawerStyles } from '@groovehq/internal-design-system/lib/components/Drawer/Drawer.styles'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import {
  doClearAllAgentDrafts,
  doCreateBulkInvitationsByDraftUserIds,
} from 'ducks/agents/operations'
import { useDrawer } from 'ducks/drawers/hooks'
import { DRAWER_TYPE_AGENT_ADD } from 'ducks/drawers/types'
import { SETTINGS_USER_TABLE_ID } from 'ducks/tables/ids'
import { selectQueryParamsQueryId } from 'selectors/location'
import { filter } from 'util/objects'
import { useConfirmHoldsCallback, useRhfDirtyHold } from 'util/dirtyHolds'
import MailboxSelectionTable from './MailboxSelectionTable'
import { styles } from './styles'

const FORM_SCHEMA = yup.object().shape({
  mailboxIds: yup.array(),
})

const AgentAddMailboxAccess = ({
  drawerResourceId,
  drawerId,
  open,
  onClose,
  onExit,
  // additionalProps on which userIdsToInvite in pending store to assign mailboxes to
  userIdsToInvite,
}) => {
  const dispatch = useDispatch()
  const hasUsersToEdit = !!userIdsToInvite?.length

  // If access this drawer without through UsersDataTable, need to fetch agents after saving
  const shouldFetchAllAgents = !useSelector(state =>
    selectQueryParamsQueryId(state, {
      targetId: SETTINGS_USER_TABLE_ID,
    })
  )

  const { openDrawer: openAddAgentDrawer } = useDrawer({
    type: DRAWER_TYPE_AGENT_ADD,
    closeIgnoresStack: true,
  })

  const {
    handleSubmit,
    setValue,
    formState: { isValid },
    control,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(FORM_SCHEMA),
    defaultValues: {
      mailboxIds: [],
    },
  })

  const { releaseHold, holdKey } = useRhfDirtyHold(drawerId, control)

  const beforeDrawerClose = useCallback(
    () => {
      dispatch(doClearAllAgentDrafts())
    },
    [dispatch]
  )

  const handleOnClose = useConfirmHoldsCallback(
    holdKey,
    () => {
      beforeDrawerClose()
      onClose()
    },
    [onClose, beforeDrawerClose]
  )

  const handleOnExit = useConfirmHoldsCallback(
    null,
    () => {
      beforeDrawerClose()
      onExit()
    },
    [onExit, beforeDrawerClose]
  )

  const onClickAddAgent = useCallback(
    () => {
      dispatch(doClearAllAgentDrafts())
      openAddAgentDrawer('new')
    },
    [openAddAgentDrawer, dispatch]
  )

  const onSelectedRowIdsChange = useCallback(
    selectedRowIds => {
      setValue('mailboxIds', Object.keys(filter(v => !!v, selectedRowIds)))
    },
    [setValue]
  )

  const onSubmit = useCallback(
    ({ mailboxIds }) => {
      if (userIdsToInvite?.length) {
        dispatch(
          doCreateBulkInvitationsByDraftUserIds(userIdsToInvite, mailboxIds, {
            shouldFetchAllAgents,
          })
        )
      }
      releaseHold()
      onExit()
    },
    [dispatch, userIdsToInvite, onExit, releaseHold, shouldFetchAllAgents]
  )

  const formId = `${drawerId}-${drawerResourceId}`

  return (
    <AdminAccessDrawer
      title={`${app.t('Mailbox')} access`}
      open={open}
      onClose={handleOnExit}
      footer={
        <ModalBtns
          saveBtnDisabled={!hasUsersToEdit || !isValid}
          saveBtnText="Invite"
          saveBtnHtmlType="submit"
          tertiaryBtnText="Cancel"
          saveBtnForm={formId}
          onClickTertiaryBtn={handleOnClose}
        />
      }
      contentStyles={!hasUsersToEdit && styles.noUsersToEdit}
    >
      {hasUsersToEdit && (
        <form id={formId} onSubmit={handleSubmit(onSubmit)}>
          <div
            className="grui mt-12 mb-10"
            css={[text.styles.fontMedium, text.styles.textBlack]}
          >
            Select which {app.t('mailboxes')} they can access.
          </div>
          <MailboxSelectionTable
            onSelectedRowIdsChange={onSelectedRowIdsChange}
          />
        </form>
      )}
      {!hasUsersToEdit && (
        <div css={[drawerStyles.preLoadedState]}>
          <div>
            You need to invite new {app.t('agents')} to access this screen.
          </div>
          <div>
            <Button size="small" onClick={onClickAddAgent}>
              Add {app.t('Agents')}
            </Button>
          </div>
        </div>
      )}
    </AdminAccessDrawer>
  )
}

export default AgentAddMailboxAccess
