import { useCallback, useEffect, useMemo } from 'react'
import { bindActionCreators } from 'redux'
import { useDispatch } from 'react-redux'

export function useActions(actionOrActions, deps = []) {
  const dispatch = useDispatch()
  const isArray = Array.isArray(actionOrActions)
  const actions = isArray ? actionOrActions : [actionOrActions]
  return useMemo(() => {
    const boundActions = actions.map(action => {
      const actionCreator = typeof action === 'object' ? () => action : action
      return bindActionCreators(actionCreator, dispatch)
    })
    return isArray ? boundActions : boundActions[0]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps ? [dispatch, ...(Array.isArray(actions) ? actions : [actions]), ...deps] : [dispatch])
}

export function useAction(action, ...args) {
  const dispatch = useDispatch()
  return useCallback(
    (...callbackArgs) => {
      return dispatch(
        typeof action === 'function' ? action(...args, ...callbackArgs) : action
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [action, ...args]
  )
}

export function useActionEffect(action, deps = []) {
  const boundAction = useActions(action)
  useEffect(
    // This is intentional no just `boundAction`
    // since we need to swallow its return value
    () => {
      boundAction()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...deps, boundAction]
  )
}
