import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react'
import Modal from '@groovehq/internal-design-system/lib/components/Modal/Modal'
import Spinner from '@groovehq/internal-design-system/lib/components/Spinner/Spinner'
import { useSelector } from 'react-redux'
import { selectQueryParams } from 'selectors/location'
import { useSetupIntent, useSetupSubscription } from 'ducks/billing/hooks'
import { isBoostrappedSelector } from 'selectors/app/base'
import { selectRequestByType } from 'ducks/requests/selectors'
import { BOOTSTRAP_APP_DATA } from 'ducks/bootstrap/types'
import Error from '@groovehq/internal-design-system/lib/assets/icons/Error'
import Checkmark from '@groovehq/internal-design-system/lib/assets/icons/Checkmark'
import { useAdminAccess } from '../NoAccess/hooks'
import { styles } from './styles'

const BillingSetupPaymentMethodFeedback = props => {
  const {
    onClose,
    onExit,
    drawerSaveSubscription,
    drawerConvertTrials,
    drawerPricingIds,
    drawerBillingCycle,
  } = props
  const {
    redirect_status: redirectStatus,
    setup_intent: setupIntentId,
  } = useSelector(selectQueryParams)
  const isRedirectSuccess = redirectStatus === 'succeeded'

  const isBootstrapped = useSelector(isBoostrappedSelector)
  const [isBusy, setIsBusy] = useState(isRedirectSuccess)
  const ranOnce = useRef(false)

  const { saveSubscription } = useSetupSubscription({
    convertTrials: drawerConvertTrials,
    pricingIds: drawerPricingIds,
    billingCycle: drawerBillingCycle,
  })

  const { loaded: isAppBootstrapLoaded } = useSelector(state =>
    selectRequestByType(state, BOOTSTRAP_APP_DATA)
  )

  const onSubmitted = useCallback(
    async () => {
      if (drawerSaveSubscription) {
        await saveSubscription()
      }
      setIsBusy(false)
    },
    [drawerSaveSubscription, saveSubscription]
  )

  const { onSetupSuccess: saveSetupSuccess } = useSetupIntent({ onSubmitted })

  useAdminAccess(onClose)

  useEffect(
    () => {
      if (
        isBootstrapped &&
        isAppBootstrapLoaded &&
        setupIntentId &&
        isRedirectSuccess &&
        !ranOnce.current
      ) {
        ranOnce.current = true
        saveSetupSuccess(setupIntentId)
      }
    },
    [
      isBootstrapped,
      isAppBootstrapLoaded,
      setupIntentId,
      isRedirectSuccess,
      drawerSaveSubscription,
      saveSetupSuccess,
    ]
  )

  const messages = useMemo(
    () => {
      if (isRedirectSuccess && !drawerSaveSubscription) {
        return {
          heading: 'Thank you ❤️',
          message: 'Payment method successfully updated!',
          icon: <Checkmark css={[styles.icon, styles.success]} />,
        }
      } else if (isRedirectSuccess && drawerSaveSubscription) {
        return {
          heading: 'Thank you ❤️',
          message:
            'Payment method saved and your subscription successfully updated!',
          icon: <Checkmark css={[styles.icon, styles.success]} />,
        }
      }
      return {
        heading: 'Payment method',
        message:
          'Unable to update your payment method, please try again or contact support if the issue persists.',
        icon: <Error css={[styles.icon, styles.error]} />,
      }
    },
    [isRedirectSuccess, drawerSaveSubscription]
  )

  return (
    <Modal
      onClose={onExit}
      portal="#drawer-root"
      dataTestId="drawer-billing-setup-card"
      className="grui pb-14"
      open
      backdropTransparency="light"
    >
      <Modal.Title>
        {isBusy && 'One moment...'}
        {!isBusy && messages.heading}
      </Modal.Title>
      {isBusy && (
        <Spinner className="grui self-center mt-20 mb-20" size="medium" />
      )}
      {!isBusy && (
        <div className="grui mb-16 text-center">
          <>
            {messages.icon}
            <div>{messages.message}</div>
          </>
        </div>
      )}
      <Modal.Button
        onClick={onClose}
        type="info"
        disabled={isBusy}
        htmlType="submit"
      >
        Close
      </Modal.Button>
    </Modal>
  )
}

export default BillingSetupPaymentMethodFeedback
