import cn from 'classnames'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import { strftime } from 'util/date'
import { getFormattedPrice } from 'util/numbers'
import { styles } from '../styles'

const TotalNextRecurringInvoice = ({
  nextBillDate,
  currency,
  totalNewCyclePrice,
  isUpgrade,
  discount,
}) => {
  const { coupon: { name, percentOff = 0 } = {} } = discount || {}
  const discountAmount = totalNewCyclePrice * (percentOff / 100) * -1
  return (
    <div
      css={isUpgrade && styles.footer}
      className={cn('grui px-7 pb-8', {
        'pt-8': isUpgrade,
      })}
    >
      {discount && (
        <>
          <div
            css={[
              styles.row,
              text.styles.fontMedium,
              isUpgrade && text.styles.textWhite,
            ]}
          >
            <div>Subtotal</div>
            <div>{getFormattedPrice(totalNewCyclePrice, currency)}</div>
          </div>
          <div
            css={[
              styles.row,
              text.styles.fontMedium,
              isUpgrade && text.styles.textWhite,
            ]}
          >
            <div>{name}</div>
            <div>{getFormattedPrice(discountAmount, currency)}</div>
          </div>
        </>
      )}

      <div
        css={[
          styles.row,
          text.styles.fontMedium,
          isUpgrade && text.styles.textWhite,
        ]}
      >
        <div>Next Bill Amount Due</div>
        <div>
          {getFormattedPrice(totalNewCyclePrice + discountAmount, currency)}
        </div>
      </div>

      <div
        css={[text.styles.text2Xs, isUpgrade && text.styles.textWhite]}
        className="grui opacity-80 mt-2"
      >
        Bill on {strftime('%A, %Do %B ‘%y', nextBillDate * 1000)}
      </div>
    </div>
  )
}

export default TotalNextRecurringInvoice
