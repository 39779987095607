export function smoothScrollTo(element, targetY, duration) {
  if (duration <= 0) return
  const TICK = 1000 / 60
  const difference = targetY - element.scrollTop
  const perTick = difference / duration * TICK

  setTimeout(() => {
    element.scrollTop += perTick
    if (element.scrollTop === targetY) return
    requestAnimationFrame(() =>
      smoothScrollTo(element, targetY, duration - TICK)
    )
  }, TICK)
}

// FROM: https://davidwalsh.name/css-animation-callback
/* From Modernizr */
let animationEndEventName
export function getAnimationEndEventName() {
  // NOTE (jscheel): Changes to null if none exists but check has happened so
  // that cache still works if no event name was found for some reason.
  if (animationEndEventName !== undefined) {
    return animationEndEventName
  }
  const el = document.createElement('fakeelement')
  const animations = {
    animation: 'animationend',
    OAnimation: 'oAnimationEnd',
    MozAnimation: 'animationend',
    WebkitAnimation: 'webkitAnimationEnd',
  }
  const animationKey = Object.keys(animations).find(key => {
    return el.style[key] !== undefined
  })
  animationEndEventName = animations[animationKey] || null
  return animationEndEventName
}
// END FROM

export default {
  smoothScrollTo,
  getAnimationEndEventName,
}
