// Based on https://github.com/GrooveHQ/internal-design-system/blob/master/src/pages/components/DeleteRow/DeleteRow.jsx
import React, { useCallback, useMemo, useState } from 'react'
import { bool, func, oneOfType, string, node } from 'prop-types'
import { AdminAccessDrawer } from 'subapps/settings/components/drawers/NoAccess'
import Field from '@groovehq/internal-design-system/lib/components/Field/Field'
import Drawer from '@groovehq/internal-design-system/lib/components/Drawer/UnmanagedDrawer'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import AnimatedEllipsis from '@groovehq/internal-design-system/lib/components/AnimatedEllipsis/AnimatedEllipsis'

import {
  text,
  paragraph,
} from '@groovehq/internal-design-system/lib/styles/elements'
import { delay, isPromise } from 'util/functions'

const EntityDelete = ({
  title,
  deleteBtnText,
  deleteBtnDisabled,
  deletingBtnText,
  hardDelete,
  hardDeleteWord,
  open,
  onClose,
  onExit,
  onCancel,
  onRemove,
  tertiaryBtnText,
  children,
  isNoResultFound,
  isLoading,
  confirmationHidden,
  extraContent,
  adminAccess = true,
  ...rest
}) => {
  const [typed, setTyped] = useState(null)
  const [isRemoving, setIsRemoving] = useState(false)
  const [focused, setFocused] = useState(false)
  const hasValue = typed && typed !== ''

  let isValid = false
  if (typed === hardDeleteWord) isValid = true
  const isDeleteButtonDisabled =
    deleteBtnDisabled || (hardDelete && !isValid) || isRemoving

  const handleHardDeleteFieldChange = useCallback(
    e => {
      setTyped(e.target.value)
    },
    [setTyped]
  )

  const handleHardDeleteFieldFocus = useCallback(() => setFocused(true), [
    setFocused,
  ])
  const handleHardDeleteFieldBlur = useCallback(() => setFocused(false), [
    setFocused,
  ])

  const handleDelete = useCallback(
    async () => {
      if (isRemoving) return false
      setIsRemoving(true)
      const response = onRemove()
      if (isPromise(response)) {
        await response
        setIsRemoving(false)
      } else {
        await new Promise(resolve => {
          delay(() => {
            setIsRemoving(false)
            resolve()
          }, 200)
        })
      }
      onClose({ isAfterDelete: true })
      return true
    },
    [onRemove, onClose, setIsRemoving, isRemoving]
  )

  const footer = (
    <ModalBtns
      warningBtnText={
        <>
          {!isRemoving && <span>{deleteBtnText}</span>}
          {isRemoving && (
            <span>
              {deletingBtnText}
              <AnimatedEllipsis />
            </span>
          )}
        </>
      }
      warningBtnDisabled={isDeleteButtonDisabled}
      onClickWarningBtn={handleDelete}
      onClickTertiaryBtn={onCancel}
      tertiaryBtnText={tertiaryBtnText}
    />
  )

  const DrawerComponent = useMemo(
    () => (adminAccess ? AdminAccessDrawer : Drawer),
    [adminAccess]
  )

  return (
    <DrawerComponent
      title={title}
      open={open}
      // Hanlder for closing all drawers regardless of previous drawers
      onClose={onExit || onClose}
      footer={footer}
      isNoResultFound={isNoResultFound}
      isLoading={isLoading}
      {...rest}
    >
      <div css={text.styles.textNormal} className="grui pt-10">
        {children}
        {hardDelete !== true &&
          !confirmationHidden && (
            <>
              <br />
              <p css={paragraph.styles.preflight}>
                Are you sure you want to proceed with this action?
              </p>
            </>
          )}
        {hardDelete === true && (
          <Field
            required
            equals={hardDeleteWord}
            className="grui mt-14"
            label={`Type ${hardDeleteWord} to continue`}
            onChange={handleHardDeleteFieldChange}
            onFocus={handleHardDeleteFieldFocus}
            onBlur={handleHardDeleteFieldBlur}
            validateStatus={hasValue && !isValid ? 'error' : 'success'}
            help={
              hasValue && !isValid && !focused
                ? `The value must equal ${hardDeleteWord}`
                : ''
            }
          />
        )}
      </div>
      {extraContent}
    </DrawerComponent>
  )
}

EntityDelete.propTypes = {
  title: string,
  deleteBtnText: string,
  deletingBtnText: string,
  open: bool.isRequired,
  onClose: func.isRequired,
  onExit: func,
  onRemove: func,
  children: oneOfType([string, node]),
  confirmationHidden: bool,
  hardDeleteWord: string,
}

EntityDelete.defaultProps = {
  title: 'Delete',
  onExit: undefined,
  onRemove: () => {},
  children: undefined,
  deleteBtnText: 'Delete',
  deletingBtnText: 'Deleting',
  confirmationHidden: false,
  hardDeleteWord: 'DELETE',
}

export default EntityDelete
