import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { string, bool, oneOf } from 'prop-types'
import { doDeleteCannedReplyCategories } from 'ducks/cannedReplies/operations'
import EntityDeleteDrawer from '../EntityDelete'
import CannedReplyCategoryDeleteContent from './Content'

const CannedReplyCategoryDeleteDrawer = props => {
  const {
    queryId,
    title,
    deleteBtnText,
    open,
    drawerResourceId,
    drawerDeleteMode,
    onRemove,
    onClose,
  } = props
  const dispatch = useDispatch()

  const ids = useMemo(
    () => {
      return drawerResourceId.split(',')
    },
    [drawerResourceId]
  )
  const handleOnRemove = useCallback(
    () => {
      const deletePromise = dispatch(
        doDeleteCannedReplyCategories({
          ids,
          queryId,
          mode: drawerDeleteMode,
        })
      )
      if (onRemove) onRemove()
      return deletePromise
    },
    [dispatch, ids, queryId, drawerDeleteMode, onRemove]
  )

  return (
    <EntityDeleteDrawer
      {...props}
      onRemove={handleOnRemove}
      title={title}
      deleteBtnText={deleteBtnText}
      open={open}
      tertiaryBtnText="Cancel"
      onCancel={onClose}
      hardDelete
    >
      <CannedReplyCategoryDeleteContent
        ids={ids}
        queryId={queryId}
        deleteMode={drawerDeleteMode}
      />
    </EntityDeleteDrawer>
  )
}

CannedReplyCategoryDeleteDrawer.propTypes = {
  title: string,
  deleteBtnText: string,
  open: bool.isRequired,
  drawerDeleteMode: oneOf(['ids', 'query']),
  drawerResourceId: string,
  queryId: string,
}

CannedReplyCategoryDeleteDrawer.defaultProps = {
  title: 'Delete category',
  deleteBtnText: 'Delete',
  drawerDeleteMode: 'ids',
  drawerResourceId: '',
  queryId: null,
}

export default CannedReplyCategoryDeleteDrawer
