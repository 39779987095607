import { css } from '@emotion/react'

const imgBox = css`
  width: 240px;
  height: 240px;
  margin-left: auto;
  margin-right: auto;

  img {
    width: 100%;
  }
`

export const styles = {
  imgBox,
}
