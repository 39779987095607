import React, { useCallback } from 'react'
import {
  text,
  paragraph,
} from '@groovehq/internal-design-system/lib/styles/elements'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import { useDrawer } from 'ducks/drawers/hooks'
import { DRAWER_TYPE_INTEGRATION_UNINSTALL } from 'ducks/drawers/types'
import { buildDrawerQueryParam } from 'ducks/drawers/util'

export default function CommonStoreUpdateAdvanced({ store }) {
  const {
    drawerId: uninstallDrawerId,
    openDrawer: openUninstallDrawer,
  } = useDrawer(DRAWER_TYPE_INTEGRATION_UNINSTALL)

  const { id, provider, storeName } = store || {}

  const onRemoveClick = useCallback(
    () => {
      openUninstallDrawer(provider, {
        query: {
          ...buildDrawerQueryParam(
            uninstallDrawerId,
            'drawerIntegrationIdToRemove',
            id
          ),
        },
      })
    },
    [openUninstallDrawer, provider, id, uninstallDrawerId]
  )

  return (
    <div className="grui mt-12">
      <div css={text.styles.fontMedium}>Remove Store</div>
      <p css={paragraph.styles.preflight} className="grui mt-5 mb-10">
        This will remove the store &quot;{storeName}&quot; from your account.
      </p>
      <Button type="warning" size="small" onClick={onRemoveClick}>
        Remove Store
      </Button>
    </div>
  )
}
