// This HOC improves the perceived performance by delaying the mounting of an
// expensive component.
//
// sauces
//  - https://github.com/hanford/defer-render-hoc/blob/master/src/index.js
//  - https://medium.freecodecamp.org/what-i-learned-from-reading-defer-render-hoc-8c2e9dc2b07a
//  - https://medium.com/@paularmstrong/twitter-lite-and-high-performance-react-progressive-web-apps-at-scale-d28a00e780a3
//
// This component initially renders a given (cheap) placeholder. Then on the
// next available frame, it mounts the expensive component instead.
//
// The canonical use case for us is our Editor components, based on TinyMCE.
// These render on every single ticket page, so unmounting and remounting these
// can slow down overall (perceived) page responsiveness. By using this HOC, we
// allow the rest of the page to render, before we do the work of mounting an
// Editor. e.g.
//
//      withDeferedRender(SkinnedEditor, LoadingPlaceholder)
//
// Also the articles above use raf/requestAnimationFrame. However, we use
// requestIdleCallback per Henriks suggestion here:
// https://github.com/GrooveHQ/mobile-frontend/issues/1
//
// requestIdleCallback will schedule work when there is free time at the end of
// a frame, or when the user is inactive.  This allows other components to update
// and render before mounting and rendering our expensive `WrappedComponent`.
//
import React, { PureComponent } from 'react'
import requestIdleCallback, { cancelIdleCallback } from 'ric-shim'
import hoistNonReactStatic from 'hoist-non-react-statics'

import getDisplayName from 'util/hoc/getDisplayName'

export default function withDeferedRender(
  WrappedComponent,
  CheapComponent = null
) {
  class DeferredRenderWrapper extends PureComponent {
    state = { shouldRender: false }

    componentDidMount() {
      this.idleHandle = requestIdleCallback(() =>
        this.setState({ shouldRender: true })
      )
    }

    componentWillUnmount() {
      if (this.idleHandle) {
        cancelIdleCallback(this.idleHandle)
      }
    }

    idleHandle = null

    render() {
      const { forwardedRef, ...rest } = this.props

      if (this.state.shouldRender) {
        return <WrappedComponent ref={forwardedRef} {...rest} />
      }
      if (CheapComponent) {
        return (
          <CheapComponent
            className={rest.className}
            placeholderHeight={rest.placeholderHeight}
          />
        )
      }
      return null
    }
  }

  DeferredRenderWrapper.displayName = `withDeferedRender(${getDisplayName(
    WrappedComponent
  )})`

  // https://reactjs.org/docs/forwarding-refs.html#forwarding-refs-in-higher-order-components
  return React.forwardRef((props, ref) => {
    const Hoisted = hoistNonReactStatic(DeferredRenderWrapper, WrappedComponent)
    return <Hoisted {...props} forwardedRef={ref} />
  })
}
