import graphql from 'api/graphql'
import { oauthTokenSelector } from 'selectors/app'
import { selectCurrentKnowledgeBaseId } from 'subapps/kb/selectors/knowledge_bases'
import * as types from 'subapps/kb/actions'

export function doFetchTags(kind) {
  return (dispatch, getState) => {
    const state = getState()
    const token = oauthTokenSelector(state)
    const knowledgeBaseId = selectCurrentKnowledgeBaseId(state)

    dispatch({
      type: types.FETCH_ARTICLE_TAGS_REQUEST,
    })

    const query = `
      query TagsQuery($knowledgeBaseId: ID!) {
        tags(knowledgeBaseId: $knowledgeBaseId, kind: "${kind}") {
          data {
            id
            name
            createdAt
            updatedAt
            taggingsCount
          }
        }
      }
    `

    const variables = {
      knowledgeBaseId,
    }

    return graphql(token, query, variables)
      .then(res => {
        const data = res.json.data
        dispatch({
          type: types.FETCH_ARTICLE_TAGS_SUCCESS,
          data: data.tags.data,
        })
      })
      .catch(err => {
        dispatch({
          type: types.FETCH_ARTICLE_TAGS_FAILURE,
        })
      })
  }
}

export function doAddTag(tag) {
  return dispatch => {
    dispatch({
      type: types.ADD_ARTICLE_TAG,
      data: tag,
    })
  }
}
