import React, { useCallback, useState } from 'react'
import Link from 'redux-first-router-link'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { bool } from 'prop-types'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'

import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'
import { styles as modalBtnsStyles } from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns.styles'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import Radio from '@groovehq/internal-design-system/lib/components/Radio/Radio'
import { AdminAccessDrawer } from 'subapps/settings/components/drawers/NoAccess'
import HeaderWithImage from '@groovehq/internal-design-system/lib/components/HeaderWithImage/HeaderWithImage'
import Textarea from '@groovehq/internal-design-system/lib/components/Textarea/Textarea'
import { styles as fieldStyles } from '@groovehq/internal-design-system/lib/components/Field/Field.styles'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import { DRAWER_TYPE_AGENT_ADD_MAILBOX_ACCESS } from 'ducks/drawers/types'
import { useDrawer } from 'ducks/drawers/hooks'
import {
  doClearAllAgentDrafts,
  doSaveAgentDrafts,
} from 'ducks/agents/operations'
import { pluralize } from 'util/strings'
import { useConfirmHoldsCallback, useRhfDirtyHold } from 'util/dirtyHolds'
import { convertEmailsToAgents } from 'ducks/agents/utils'
import { selectAgentsIncludingArchived } from 'selectors/agents/base'
import {
  FEATURE_INBOX_MAX_USERS,
  FEATURE_INBOX_MAX_VIEWER_USERS,
} from 'ducks/billing/featureTypes'
import { useFeature } from 'ducks/billing/hooks'
import { TO_SETTINGS_BILLING_PAGE } from 'ducks/billing/constants'
import { selectCurrentInbox } from 'ducks/billing/selectors/selectCurrentInbox'
import { isVersionSmaller } from 'ducks/billing/util'
import { BULK_FORM_SCHEMA, AGENT_DESCRIPTIONS } from './constants'
import { styles } from './styles'
import { DropdownView } from './DropdownView'

const AgentAddDrawer = ({
  open,
  onClose,
  onExit,
  drawerId,
  drawerResourceId,
  queryId,
}) => {
  const dispatch = useDispatch()
  const currentInboxPlan = useSelector(selectCurrentInbox)
  const isLiteUserSupported = !isVersionSmaller(
    currentInboxPlan.pricing.version,
    'v20'
  )
  const existingAgents = useSelector(selectAgentsIncludingArchived)
  const [exceededLimit, setExceededLimit] = useState(false)
  const { canUseFeature, featureLimit, currentUsage } = useFeature(
    FEATURE_INBOX_MAX_USERS
  )
  const viewerFeature = useFeature(FEATURE_INBOX_MAX_VIEWER_USERS)
  const { isFeatureEnabled: canUseViewers } = viewerFeature
  const { openDrawer: openAddMailboxAccessDrawer } = useDrawer(
    DRAWER_TYPE_AGENT_ADD_MAILBOX_ACCESS
  )

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(BULK_FORM_SCHEMA),
    defaultValues: {
      role: null,
      bulkEmails: '',
    },
  })
  const {
    handleSubmit,
    formState: { isValid, isDirty, errors },
    register,
    setError,
    control,
  } = methods

  const { releaseHold, holdKey } = useRhfDirtyHold(drawerId, control)

  const beforeDrawerClose = useCallback(
    () => {
      dispatch(doClearAllAgentDrafts())
    },
    [dispatch]
  )

  const handleOnClose = useConfirmHoldsCallback(
    holdKey,
    () => {
      beforeDrawerClose()
      onClose()
    },
    [onClose, beforeDrawerClose]
  )

  const handleOnExit = useConfirmHoldsCallback(
    null,
    () => {
      beforeDrawerClose()
      onExit()
    },
    [onExit, beforeDrawerClose]
  )

  const onSubmit = useCallback(
    data => {
      const { bulkEmails, role } = data
      const { users, splitBulkEmails } = convertEmailsToAgents(bulkEmails, {
        role,
        agentIdPrefix: drawerResourceId,
      })
      if (users.length > 0 && users.length === splitBulkEmails.length) {
        const invitedPlusCurrentUsers = users.length + currentUsage

        if (
          featureLimit !== 'unlimited' &&
          featureLimit < invitedPlusCurrentUsers
        ) {
          setError('bulkEmails', {
            type: 'manual',
          })

          setExceededLimit(true)

          return
        }

        const alreadyExistingAgents = users.filter(u =>
          existingAgents.some(a => a.email === u.email)
        )
        if (alreadyExistingAgents.length) {
          setError('bulkEmails', {
            type: 'manual',
            message: `Existing ${app.t('agent')} email ${pluralize(
              splitBulkEmails.length,
              'address',
              'es'
            )} detected: ${alreadyExistingAgents
              .map(a => a.email)
              .join(', ')}. Please check invited or archived teammates first.`,
          })

          return
        }

        dispatch(doSaveAgentDrafts(users))
        releaseHold()
        openAddMailboxAccessDrawer('new', {
          additionalProps: {
            queryId,
            userIdsToInvite: users.map(u => u.id),
          },
        })
      } else {
        setError('bulkEmails', {
          type: 'manual',
          message: `Invalid ${app.t('agent')} email ${pluralize(
            splitBulkEmails.length,
            'address',
            'es'
          )} detected`,
        })
      }
    },
    [
      dispatch,
      drawerResourceId,
      openAddMailboxAccessDrawer,
      queryId,
      setError,
      releaseHold,
      currentUsage,
      featureLimit,
      existingAgents,
    ]
  )

  const DrawerForm = useCallback(
    subprops => <form onSubmit={handleSubmit(onSubmit)} {...subprops} />,
    [handleSubmit, onSubmit]
  )

  const upgradeNotice = (
    <div
      css={[text.styles.textXs, text.styles.fontMedium, text.styles.textWhite]}
    >
      Your plan only allows {featureLimit}{' '}
      {app.t(pluralize(featureLimit, 'agent'))} on your account
      <br />
      Upgrade to add more
      <Button
        as={Link}
        to={TO_SETTINGS_BILLING_PAGE}
        type="info"
        size="small"
        className="grui px-12 py-3 mt-6"
      >
        See Plans
      </Button>
    </div>
  )

  const footer = (
    <div css={modalBtnsStyles.base}>
      <Tooltip
        title={upgradeNotice}
        interactiveTooltip
        strategy="fixed"
        className="grui pt-6 pb-11 px-11"
        disabled={!exceededLimit}
      >
        <div>
          <ModalBtns.SaveBtn
            className="grui w-100"
            disabled={!isValid || !isDirty || !canUseFeature || exceededLimit}
            // type="submit" will automatically trigger the form submit event when clicked
            htmlType="submit"
          >
            Next
          </ModalBtns.SaveBtn>
        </div>
      </Tooltip>
      <Button type="tertiary" className="grui w-100" onClick={handleOnClose}>
        Cancel
      </Button>
    </div>
  )

  return (
    <FormProvider {...methods}>
      <AdminAccessDrawer
        title={`Add ${app.t('agents')}`}
        open={open}
        onClose={handleOnExit}
        footer={footer}
        container={DrawerForm}
      >
        <HeaderWithImage
          intro="Enter emails separated by commas, tabs or new lines."
          className="grui mb-12 mt-12"
        />
        <div>
          <Textarea
            {...register('bulkEmails')}
            className="grui my-7"
            css={styles.textArea}
            rows="10"
            placeholder={`name@email.com\nname@email.com\nname@email.com`}
            validateStatus={errors?.bulkEmails ? 'error' : undefined}
            help={errors?.bulkEmails?.message}
          />
        </div>
        <div className="grui mt-12">
          <span css={[fieldStyles.labelBox, text.styles.textSm]}>
            Invite as:
          </span>
          {canUseViewers && (
            <>
              <DropdownView />
            </>
          )}
          {!canUseViewers && (
            <>
              <Radio
                id="admin"
                {...register('role')}
                className="grui mb-8 block"
                value="admin"
              >
                Admin
                <span css={styles.agentTypeDescription}>
                  {AGENT_DESCRIPTIONS.admin.short}
                </span>
              </Radio>

              <Radio
                id="agent"
                {...register('role')}
                className="grui mb-8 block"
                value="agent"
              >
                Agent
                <span css={styles.agentTypeDescription}>
                  {AGENT_DESCRIPTIONS.agent.short}
                </span>
              </Radio>
              {isLiteUserSupported && (
                <Radio
                  id="viewer"
                  {...register('role')}
                  className="grui mb-8 block"
                  value="viewer"
                  disabled={!canUseViewers}
                >
                  Lite{' '}
                  <Button
                    type="info"
                    size="small"
                    as={Link}
                    to={TO_SETTINGS_BILLING_PAGE}
                    css={styles.upgradeButton}
                  >
                    Upgrade now
                  </Button>
                  <span css={styles.agentTypeDescription}>
                    {AGENT_DESCRIPTIONS.viewer.short}
                  </span>
                </Radio>
              )}
            </>
          )}
        </div>
      </AdminAccessDrawer>
    </FormProvider>
  )
}

AgentAddDrawer.propTypes = {
  open: bool.isRequired,
}

AgentAddDrawer.defaultProps = {}

export default AgentAddDrawer
