import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  text,
  paragraph,
} from '@groovehq/internal-design-system/lib/styles/elements'
import { useFetchIntegrationProvider } from 'ducks/integrations/hooks'
import { doClearIntegrationDraft } from 'ducks/integrations/operations/settings'
import { doRefreshIfNecessary } from 'ducks/tokens/actions'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { selectPendingInstalledFullStoreDomainsByProviderId } from 'ducks/integrations/selectors/settings'
import { platformsData } from 'ducks/integrations/recharge/contants'
import {
  BigCommerce,
  Shopify,
} from '@groovehq/internal-design-system/lib/assets/icons/logos'

import config from 'config'
import doCheckExistingIntegration from 'ducks/integrations/recharge/operations/doCheckExistingIntegration'
import Drawer from '@groovehq/internal-design-system/lib/components/Drawer/UnmanagedDrawer'
import Field from '@groovehq/internal-design-system/lib/components/Field/Field'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import MessageCard from '@groovehq/internal-design-system/lib/components/MessageCard/MessageCard'
import ButtonList from '@groovehq/internal-design-system/lib/components/ButtonList/ButtonList'

const PLATFORM_LIST = [
  {
    type: 'shopify',
    title: 'Shopify',
    name: 'Shopify',
    icon: <Shopify />,
  },
  {
    type: 'bigcommerce',
    title: 'BigCommerce',
    name: 'BigCommerce',
    icon: <BigCommerce />,
  },
]

const RechargeInstall = ({
  drawerResourceId: providerId,
  open,
  onClose,
  onExit,
  drawerId,
  queryId,
  ...rest
}) => {
  const dispatch = useDispatch()
  const [isInstalling, setIsInstalling] = useState(false)
  const [isExistingIntegrationError, setIsExistingIntegrationError] = useState(
    false
  )
  const [platform, setPlatform] = useState('shopify')

  const { isLoading, hasError, isMissing } = useFetchIntegrationProvider(
    providerId
  )

  const shopDomains = useSelector(state =>
    selectPendingInstalledFullStoreDomainsByProviderId(state, providerId)
  )

  const existingStoreDomains = useMemo(
    () => {
      const forPlatform = shopDomains.filter(domain =>
        domain.includes(platformsData.domains[platform])
      )

      return forPlatform.map(domain =>
        domain.replace(platformsData.domains[platform], '')
      )
    },
    [shopDomains, platform]
  )

  const beforeDrawerClose = useCallback(
    () => {
      dispatch(doClearIntegrationDraft(providerId))
    },
    [providerId, dispatch]
  )

  const handleOnExit = useCallback(
    () => {
      beforeDrawerClose()
      onExit()
    },
    [onExit, beforeDrawerClose]
  )

  const FORM_SCHEMA = useMemo(
    () => {
      return yup.object().shape({
        storeName: yup
          .string()
          .trim()
          .lowercase()
          .required('Store name is required')
          .matches(
            /^[a-z0-9-]+$/,
            'Your store name only contains letters, numbers, and dashes. Please check your store name and try again.'
          )
          .notOneOf(
            existingStoreDomains,
            'This store has already been connected.'
          ),
      })
    },
    [existingStoreDomains]
  )

  const {
    register,
    formState: { errors, isValid, isDirty },
    setValue,
    handleSubmit,
    reset,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(FORM_SCHEMA),
    defaultValues: {
      storeName: '',
    },
  })

  const handlePaste = useCallback(
    e => {
      e.preventDefault()

      let clean = e.clipboardData.getData('Text') || ''
      if (platform === 'shopify') {
        clean = clean.replace(/(^https?:\/\/|\.myshopify\.com.*)/g, '')
      } else if (platform === 'bigcommerce') {
        clean = clean.replace(/(^https?:\/\/|\.mybigcommerce\.com.*)/g, '')
      }
      setValue('storeName', clean, { shouldDirty: true, shouldValidate: true })
    },
    [setValue, platform]
  )

  const onCloseErrorMessageCard = useCallback(() => {
    setIsExistingIntegrationError(false)
  }, [])

  const onSubmit = useCallback(
    async ({ storeName }) => {
      setIsInstalling(true)

      const timestamp = new Date().getTime() + 1
      await dispatch(doRefreshIfNecessary(timestamp))

      const shopDomain = `${storeName}${platformsData.domains[platform]}`
      const { existsForAnyAccount } = await dispatch(
        doCheckExistingIntegration('recharge', shopDomain)
      )

      if (existsForAnyAccount) {
        setIsExistingIntegrationError(true)
        setIsInstalling(false)
        return
      }

      const rechargeClientId = config.rechargeClientId
      const url = `https://admin.rechargeapps.com/partners/app/${rechargeClientId}/install?${
        platformsData.installationDomainParamName[platform]
      }=${storeName}`
      window.location.href = url
    },
    [dispatch, platform]
  )

  const DrawerForm = useCallback(
    subProps => <form onSubmit={handleSubmit(onSubmit)} {...subProps} />,
    [handleSubmit, onSubmit]
  )

  const handlePlatformSelect = useCallback(
    selectedPlatform => {
      if (selectedPlatform === platform) {
        return
      }

      setPlatform(selectedPlatform)
      reset()
    },
    [platform, reset]
  )

  return (
    <Drawer
      {...rest}
      onClose={handleOnExit}
      isLoading={isLoading}
      isError={hasError}
      isNoResultFound={isMissing}
      size="narrow"
      title="Connect Recharge"
      open={open}
      footer={
        <ModalBtns
          saveBtnDisabled={
            !isValid ||
            !isDirty ||
            isMissing ||
            isLoading ||
            hasError ||
            isInstalling ||
            isExistingIntegrationError
          }
          saveBtnText="Install"
          tertiaryBtnText="Cancel"
          onClickTertiaryBtn={onClose}
          saveBtnHtmlType="submit"
        />
      }
      container={DrawerForm}
    >
      <div
        className="grui mb-10 mt-10"
        css={[paragraph.styles.preflight, text.styles.textNormal]}
      >
        <p
          className="grui mb-10"
          css={[
            paragraph.styles.preflight,
            text.styles.textNormal,
            text.styles.textDark,
          ]}
        >
          Select the e-commerce platform below:
        </p>

        <ButtonList
          list={PLATFORM_LIST}
          onSelect={handlePlatformSelect}
          selected={platform}
        />

        {platform && (
          <>
            <div className="grui pt-12">
              <Field
                {...register('storeName')}
                label="Your store name"
                name="storeName"
                placeholder="Enter store name"
                tagPosition="right"
                tagContent={platformsData.domains[platform]}
                validateStatus={errors?.storeName ? 'error' : undefined}
                help={errors?.storeName?.message}
                onPaste={handlePaste}
                className="grui mw-100"
              />
            </div>

            {isExistingIntegrationError && (
              <div className="grui mt-10">
                <MessageCard
                  type="negative"
                  closeable
                  onClose={onCloseErrorMessageCard}
                >
                  This store has already been added to another Groove account.
                  Remove it there before adding it here.
                </MessageCard>
              </div>
            )}
          </>
        )}
      </div>
    </Drawer>
  )
}

export default RechargeInstall
