import { useSelector } from 'react-redux'
import Table from '@groovehq/internal-design-system/lib/components/Table/Table'

import { selectAgents } from 'selectors/agents/base'

import useColumns from './useColumns'
import { styles } from '../../styles'

const AgentsTable = ({ control }) => {
  const agents = useSelector(selectAgents)
  const columns = useColumns({ control })

  return <Table columns={columns} data={agents} css={styles.table} />
}

export default AgentsTable
