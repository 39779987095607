import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Modal from '@groovehq/internal-design-system/lib/components/Modal/Modal'
import ProgressDots from '@groovehq/internal-design-system/lib/components/ProgressDots/ProgressDots'
import {
  useChannel,
  useRedirectToChannel,
  useRebuildLeftNavMenu,
} from 'ducks/channels/hooks'
import useOpenContact from 'util/hooks/useOpenContact'
import { selectIsInInbox } from 'selectors/location'
import { selectChannelCreationStepCount } from 'ducks/channels/selectors'
import { MIGRATING_STATE } from 'ducks/mailboxes/constants'
import { useAdminAccess } from '../../NoAccess/hooks'
import ForwardingGuides from '../EmailForward/ForwardingGuides'

import { styles } from './styles'
import { CHANNEL_TYPES } from '../Channels.data'

const ForwardingFailConfirmation = ({
  onRetry,
  drawerResourceId: channelId,
  drawerChannelType: channelType = 'forwarding',
  onClose,
  onExit,
}) => {
  const openContact = useOpenContact()

  const isInInbox = useSelector(selectIsInInbox)
  const { isLoading, isMissing, channel } = useChannel(channelId)

  const isMigrating = channel?.state === MIGRATING_STATE

  const creationStepCount = useSelector(
    storeState =>
      isMigrating
        ? 0
        : selectChannelCreationStepCount(storeState, CHANNEL_TYPES[channelType])
  )

  const { rebuildExitAndRedirect } = useRedirectToChannel({
    channelId,
    onExit,
  })

  const { rebuildMenuAndExit } = useRebuildLeftNavMenu({ channelId, onExit })

  useAdminAccess(onClose)

  useEffect(
    () => {
      if (isMissing) onClose()
    },
    [isMissing, onClose]
  )

  const handleOnExit = useCallback(
    () => {
      // we do not rebuild left nav as soon as channel is created anymore
      // if the user clicks the Exit button on top right, rebuild nav
      if (isInInbox) {
        rebuildExitAndRedirect()
        return
      }
      rebuildMenuAndExit()
    },
    [isInInbox, rebuildMenuAndExit, rebuildExitAndRedirect]
  )

  return (
    <Modal
      dataTestId="channels-forwarding-verification"
      portal="#drawer-root"
      onClose={handleOnExit}
      isLoading={isLoading}
      open
      backdropTransparency="light"
    >
      <ProgressDots
        count={creationStepCount}
        now={3}
        className="grui mt-4 mb-13"
      />
      <Modal.Title>Oh no, we didn’t receive the test!</Modal.Title>
      <Modal.Description css={styles.confirmationText}>
        The testing email, which confirms that email forwarding has been
        correctly set up, was not received. Please double-check settings or
        reach out to our support team if you{"'"}re stuck.
      </Modal.Description>
      <div css={styles.content} className="grui text-center">
        <div className="grui mt-20">
          <Modal.Button
            type="info"
            data-test-id="forwarding-verification-continue-button"
            onClick={onRetry}
          >
            Try Again
          </Modal.Button>
          <Modal.Button
            type="tertiary"
            className="grui mt-5"
            onClick={openContact}
          >
            Stuck? Don{"'"}t Worry we{"'"}re here to help
          </Modal.Button>
          <Modal.Button
            type="link"
            className="grui mt-11"
            onClick={handleOnExit}
          >
            I&apos;ll finish my setup later
          </Modal.Button>
        </div>
      </div>
      <ForwardingGuides />
    </Modal>
  )
}

export default ForwardingFailConfirmation
