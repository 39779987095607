import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { redirect } from 'redux-first-router'
import Bugsnag from '@bugsnag/js'
import { useDispatch, useSelector } from 'react-redux'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import ButtonList from '@groovehq/internal-design-system/lib/components/ButtonList/ButtonList'
import MessageCard from '@groovehq/internal-design-system/lib/components/MessageCard/MessageCard'
import { AdminAccessDrawer } from 'subapps/settings/components/drawers/NoAccess'
import { Plus } from '@groovehq/internal-design-system/lib/assets/icons'
import { useDrawer } from 'ducks/drawers/hooks'
import AnimatedEllipsis from '@groovehq/internal-design-system/lib/components/AnimatedEllipsis/AnimatedEllipsis'
import { buildDrawerQueryParam } from 'ducks/drawers/util'
import {
  useChannel,
  useEstablishChannelConnection,
  useChannelDetailsConfig,
  useRedirectToChannel,
} from 'ducks/channels/hooks'
import {
  paragraph,
  text,
} from '@groovehq/internal-design-system/lib/styles/elements'
import {
  doBulkUpdateFacebookPages,
  doFetchFacebookPages,
} from 'ducks/facebook/actions'
import { selectCurrentFacebookPages } from 'ducks/facebook/selectors'
import debug from 'util/debug'
import { oAuthErrorToErrorCode } from 'subapps/settings/components/drawers/Channels/ChannelDetails/oAuthError'
import { CHANNEL_TYPES } from 'subapps/settings/components/drawers/Channels/Channels.data'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import { doDeleteWidget } from 'ducks/widgets/operations'
import { doFetchChatChannels } from 'ducks/channels/actions'
import { getRawId } from 'util/globalId'
import channelsConfig from 'subapps/settings/components/drawers/Channels/ChannelDetails/config'
import { SETTINGS_CHANNELS_PAGE } from 'subapps/settings/types'
import { styles } from './styles'

const facebookMapAvailableItem = ({ id, name: pageName, about, picture }) => ({
  type: id,
  title: about,
  disabled: false,
  icon: (
    <img
      css={styles.img}
      className="grui mr-8"
      key={id}
      alt={pageName}
      title={pageName}
      src={picture}
    />
  ),
  name: pageName,
})

const facebookMapConnectedItem = ({ id, name: pageName, picture }) => ({
  type: id,
  title: pageName,
  disabled: true,
  icon: (
    <img
      css={styles.img}
      className="grui mr-8"
      key={id}
      alt={pageName}
      title={pageName}
      src={picture}
    />
  ),
  name: pageName,
})

const SelectFacebookPage = ({
  onExit,
  drawerResourceId: channelId,
  drawerChannelType: channelType = 'facebook',
  drawerAction = 'connect',
  drawerSource,
}) => {
  const isReconnect = drawerAction === 'reconnect'
  const isCreate = drawerSource === 'create'
  const dispatch = useDispatch()
  const [isSaving, setIsSaving] = useState(null)
  const [selectedPage, setSelectedPage] = useState(null)
  const [hasError, setHasError] = useState(false)
  const { channel, isLoading, isMissing, loadChannel } = useChannel(channelId)
  const { kind: channelKind } = channel || {}
  const [errorCode, setErrorCode] = useState(null)
  const facebookPages = useSelector(selectCurrentFacebookPages)
  const {
    oAuthErrors,
    drawer: nextDrawer,
    canUseFeature,
  } = useChannelDetailsConfig(channelType)
  const { icon } = CHANNEL_TYPES[channelKind] || {}
  const { rebuildExitAndRedirect } = useRedirectToChannel({ channelId, onExit })

  const handleOnExit = useCallback(
    () => {
      if (isCreate) {
        dispatch(doDeleteWidget(channelId))
      }
      onExit()
    },
    [dispatch, isCreate, channelId, onExit]
  )

  useEffect(
    () => {
      if (canUseFeature) return

      handleOnExit()
      dispatch(
        redirect({
          type: SETTINGS_CHANNELS_PAGE,
        })
      )
    },
    [handleOnExit, dispatch, canUseFeature, channelType]
  )

  useEffect(
    () => {
      dispatch(doFetchFacebookPages({ skipLoaded: false }))
    },
    [dispatch]
  )

  const oauthErrorMessage = useMemo(
    () => {
      if (!errorCode) return null

      return oAuthErrors[errorCode] || oAuthErrors.DEFAULT
    },
    [errorCode, oAuthErrors]
  )

  const { connectChannel } = useEstablishChannelConnection({
    channelId,
    channelType,
    source: null,
    action: drawerAction,
  })

  const handleError = useCallback(
    error => {
      setErrorCode(oAuthErrorToErrorCode(error, channelType))
      // eslint-disable-next-line no-console
      console.error(error)
    },
    [channelType]
  )

  const { drawerId: nextDrawerId, openDrawer: openNextDrawer } = useDrawer(
    nextDrawer
  )

  const handleOnReconnect = useCallback(
    async () => {
      try {
        await connectChannel()
        dispatch(doFetchChatChannels({ skipLoaded: false }))
        const { facebookPages: reloadedFacebookPages } = await dispatch(
          doFetchFacebookPages({ skipLoaded: false })
        )

        // If the customer connected a single page, then it will automatically
        // be linked and subscribed. In that case we should just imm
        if (
          reloadedFacebookPages.some(fb =>
            fb.widget_ids.includes(getRawId(channelId))
          )
        ) {
          if (!isCreate) {
            onExit()
          } else {
            if (!nextDrawer) {
              rebuildExitAndRedirect()
              return
            }

            openNextDrawer(channelId, {
              query: {
                ...buildDrawerQueryParam(
                  nextDrawerId,
                  'drawerChannelType',
                  channelType
                ),
              },
            })
          }
        }
      } catch (error) {
        handleError(error)
      }
    },
    [
      connectChannel,
      handleError,
      dispatch,
      channelId,
      channelType,
      isCreate,
      nextDrawerId,
      onExit,
      openNextDrawer,
      nextDrawer,
      rebuildExitAndRedirect,
    ]
  )

  const { availableList, connectedList } = useMemo(
    () => {
      const available = []
      const connected = []
      facebookPages.forEach(page => {
        if (page.available_bridge_types.includes(channelType)) {
          available.push(facebookMapAvailableItem(page))
        }
        if (page.connected_bridge_types.includes(channelType)) {
          connected.push(facebookMapConnectedItem(page))
        }
      })

      return {
        availableList: available,
        connectedList: connected,
      }
    },
    [facebookPages, channelType]
  )

  const handleSelectFacebookPage = useCallback(
    pageId => {
      const facebookPage = facebookPages.find(p => p.id === pageId)
      setSelectedPage(facebookPage)
    },
    [facebookPages]
  )

  const handleSaveAndProceed = useCallback(
    async () => {
      if (!selectedPage) return
      setIsSaving(true)

      // NOTE (jscheel): Double-checks that we have an instagram account id
      // during the instagram flow, to make sure we have the right scopes, etc.
      if (channelType === 'instagram' && !selectedPage.instagram_account_id) {
        handleOnReconnect()
        return
      }

      try {
        await dispatch(
          doBulkUpdateFacebookPages([
            {
              ...selectedPage,
              subscribed: true,
              widgetId: channelId,
            },
          ])
        )
        await loadChannel({ ignoreCache: true })
        dispatch(doFetchChatChannels({ skipLoaded: false }))
        setIsSaving(false)
        if (!isCreate) {
          handleOnExit()
        } else {
          if (!nextDrawer) {
            rebuildExitAndRedirect()
            return
          }
          openNextDrawer(channelId, {
            query: {
              ...buildDrawerQueryParam(
                nextDrawerId,
                'drawerChannelType',
                channelType
              ),
            },
          })
        }
      } catch (error) {
        setIsSaving(false)
        setHasError(true)
        debug(error)
        Bugsnag.notify(error)
      }
    },
    [
      dispatch,
      selectedPage,
      channelType,
      openNextDrawer,
      nextDrawerId,
      channelId,
      loadChannel,
      isCreate,
      handleOnExit,
      handleOnReconnect,
      nextDrawer,
      rebuildExitAndRedirect,
    ]
  )

  const footer = (
    <>
      {oauthErrorMessage && (
        <MessageCard
          type="negative"
          className="grui mx-12 my-10"
          css={styles.messageCard}
          isIconHidden
        >
          <p css={[paragraph.styles.preflight, text.styles.fontMedium]}>
            Authentication error
          </p>
          <p css={paragraph.styles.preflight} className="grui mt-4">
            {oauthErrorMessage}
          </p>
        </MessageCard>
      )}
      <ModalBtns
        saveBtnText={
          <>
            {isSaving && (
              <span>
                Saving<AnimatedEllipsis />
              </span>
            )}
            {!isSaving && (isCreate ? 'Next step' : 'Save')}
          </>
        }
        onSave={handleSaveAndProceed}
        saveBtnDisabled={!selectedPage || isLoading || isMissing}
      />
    </>
  )

  return (
    <AdminAccessDrawer
      title={channelsConfig[channelType].drawerTitle}
      footer={footer}
      onClose={handleOnExit}
      data-test-id="channels-select-facebookpage"
      hasError={hasError}
    >
      <p
        css={[
          paragraph.styles.preflight,
          text.styles.textNormal,
          text.styles.fontSemibold,
        ]}
        className="grui mt-10 mb-10"
      >
        Which page would you like to connect?
      </p>
      {isReconnect && (
        <span
          css={[
            paragraph.styles.preflight,
            text.styles.textXs,
            text.styles.fontSemibold,
          ]}
          className="grui mb-8 block"
        >
          Available
        </span>
      )}
      <ButtonList
        css={styles.buttonList}
        selected={selectedPage?.id}
        list={availableList}
        onSelect={handleSelectFacebookPage}
        size="big"
      />
      {isReconnect && (
        <>
          <div css={styles.reconnectButtonListItem}>
            <Plus viewBox="0 0 17 16" />
            <div>
              <span
                css={[
                  paragraph.styles.preflight,
                  text.styles.textNormal,
                  text.styles.fontSemibold,
                ]}
                className="grui mb-1 block"
              >
                Looking to connect another page?
              </span>
              Reconnect Messenger to update which pages Groove has access to. In
              addition to{' '}
              <span css={[text.styles.fontSemibold]}>
                selecting any new pages
              </span>{' '}
              that you want to use, make sure to{' '}
              <span css={[text.styles.fontSemibold]}>
                keep all currently connected pages
              </span>{' '}
              (see below).
              <Button
                customIcon={icon}
                onClick={handleOnReconnect}
                type="secondary"
              >
                Connect more pages
              </Button>
            </div>
          </div>
          {connectedList.length > 0 && (
            <span
              css={[
                paragraph.styles.preflight,
                text.styles.textXs,
                text.styles.fontSemibold,
              ]}
              className="grui mt-12 mb-8 block"
            >
              Already connected
            </span>
          )}
          <ButtonList
            css={styles.disabledButtonList}
            list={connectedList}
            size="small"
          />
        </>
      )}
    </AdminAccessDrawer>
  )
}

export default SelectFacebookPage
