import graphql, { getData, getResult } from 'api/graphql'

const basicPageFields = `
  id
  name
  subscribed
  mailbox_id
`

const PAGES_QUERY = `
  query FacebookPagesQuery {
    facebookPages {
      ${basicPageFields}
    }
  }
`

// TODO (jscheel): Thinking we should load the entire mailbox here, for the channels page.
const SUBSCRIBE_MUTATION = `
  mutation UpdateFacebookPageSubscriptions($subscriptions: [FacebookPageSubscriptionInput!]!) {
    updateFacebookPageSubscriptions(subscriptions: $subscriptions) {
      ${basicPageFields}
    }
  }
`

export const fetchFacebookPages = token => {
  return graphql(token, PAGES_QUERY)
    .then(getData)
    .then(getResult('facebookPages'))
}

export const updateFacebookPageSubscriptions = (token, subscriptions) => {
  return graphql(token, SUBSCRIBE_MUTATION, { subscriptions })
    .then(getData)
    .then(getResult('facebookPages'))
}
