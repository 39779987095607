import React, { useCallback } from 'react'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import {
  text,
  heading,
  paragraph,
} from '@groovehq/internal-design-system/lib/styles/elements'

import { AdminAccessDrawer } from 'subapps/settings/components/drawers/NoAccess'

import { useDrawer } from 'ducks/drawers/hooks'
import {
  DRAWER_TYPE_INTEGRATION_SLACK_CHANNEL_UPDATE,
  DRAWER_TYPE_INTEGRATION_SLACK_CONFIGURE,
} from 'ducks/drawers/types'

const Actions = ({ onExit }) => {
  return (
    <ModalBtns
      tertiaryBtnText="Close"
      tertiaryBtnType="button"
      onClickTertiaryBtn={onExit}
    />
  )
}

const SlackMapUsernamesDrawer = ({
  drawerResourceId: providerId,
  open,
  onClose,
  onExit,
  drawerId,
  queryId,
  previousDrawer,
  drawerPostIntegrationInstall,
  ...rest
}) => {
  const { openDrawer: openChannelDrawer } = useDrawer(
    DRAWER_TYPE_INTEGRATION_SLACK_CHANNEL_UPDATE
  )

  const { openDrawer: openConfigureDrawer } = useDrawer(
    DRAWER_TYPE_INTEGRATION_SLACK_CONFIGURE
  )

  const handleOpenChannel = useCallback(
    () => {
      openChannelDrawer('new')
    },
    [openChannelDrawer]
  )

  return (
    <AdminAccessDrawer
      {...rest}
      onClose={onExit}
      size="narrow"
      title="Slack Settings"
      open={open}
      footer={<Actions onExit={onExit} />}
    >
      <h3 css={heading.styles.h3} className="grui mt-12 mb-8">
        Slack is connected 🎉
      </h3>
      <p
        css={[paragraph.styles.preflight, text.styles.textSm]}
        className="grui mb-8"
      >
        Great news! Your Slack account is successfully connected and will start
        receiving notifications from Groove.
      </p>
      <div css={text.styles.fontMedium} className="grui mt-14 mb-8">
        What&#39;s next:
      </div>
      <Button
        type="primary"
        onClick={handleOpenChannel}
        className="grui mb-8 w-100"
      >
        Set up notifications for another Slack channel
      </Button>
      <Button
        type="tertiary"
        onClick={openConfigureDrawer}
        className="grui mb-8 w-100"
      >
        Go to Slack overview screen
      </Button>
    </AdminAccessDrawer>
  )
}

export default SlackMapUsernamesDrawer
