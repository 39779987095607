import graphql from 'api/graphql'
import isEmail from 'validator/lib/isEmail'
import isInt from 'validator/lib/isInt'
import isIn from 'validator/lib/isIn'
import isURL from 'validator/lib/isURL'
import isFQDN from 'validator/lib/isFQDN'
import matches from 'validator/lib/matches'
import { memoize } from 'util/memoization'

export const isEmpty = value =>
  value === undefined || value === null || value === ''

export const join = rules => (value, data) =>
  rules.map(rule => rule(value, data)).filter(error => !!error)[0]

export const fullName = value =>
  isEmpty(value) || !matches(value, /^\D+\s+\D+$/)
    ? 'Must provide your first and last name'
    : null

export const email = value =>
  isEmpty(value) || !isEmail(value) ? 'Invalid email address' : null

export const emailOrEmpty = value => {
  if (isEmpty(value)) return null
  return !isEmail(value) ? 'Invalid email address' : null
}
export const url = value => (isEmpty(value) || !isURL(value)) && 'Invalid URL'

export const httpUrl = value =>
  (url(value) || !/^https?:\/\//i.test(value)) && 'Invalid URL'

export const required = value => isEmpty(value) && 'Required field'

export const minLength = min => value =>
  (isEmpty(value) || value.length < min) && `Must be at least ${min} characters`

export const maxLength = max => value =>
  (isEmpty(value) || value.length > max) &&
  `Must be no more than ${max} characters`

export const number = value => !isInt(value) && 'Must be a valid number'

export const validateAlphanumericDash = value =>
  (isEmpty(value) || /[^A-Za-z0-9-]/i.test(value)) &&
  'Only alphanumeric characters and dashes allowed'

export const validateSlug = validateAlphanumericDash

export const username = validateAlphanumericDash

export const validHexCode = value =>
  (isEmpty(value) || !/^#([a-f0-9]{6}|[a-f0-9]{3})$/i.test(value)) &&
  'Must be a valid HEX code'

export const validGaCode = value =>
  isEmpty(value) ? 'Must provide Google Analytics code' : null

export const validCustomDomain = value =>
  !isEmpty(value) && !isFQDN(value) && 'Invalid domain name'

export const validScriptTag = value =>
  !isEmpty(value) &&
  !/^<script\b[^>]*>([\s\S]*?)<\/script>$/i.test(value) &&
  'Missing opening or closing script tag'

export const oneOf = (values, message) => value => {
  return (
    !isIn(value || '', values) &&
    (message || `Must be one of: ${values.join(', ')}`)
  )
}

export const notOneOf = (values, message) => value => {
  return (
    isIn(value || '', values) &&
    (message || `Cannot be one of: ${values.join(', ')}`)
  )
}

export const match = field => (value, data) =>
  data && value !== data[field] && 'Must match'

export const validateAccountSubdomain = value => {
  const mutation = `
      mutation checkSubdomainAvailability(
        $input: AccountSubdomainAvailabilityInput!
      ){
        checkSubdomainAvailability(
          input: $input
        ) {
          valid,
          message,
          suggestion,
        }
      }
    `

  const variables = {
    input: { subdomain: value },
  }

  return graphql('', mutation, variables)
    .then(res => {
      const data = res.json.data
      const { valid, message, suggestion } = data.checkSubdomainAvailability
      if (valid) {
        return null
      }
      return {
        message,
        suggestion,
      }
    })
    .catch(err => {
      return err
    })
}

// eslint-disable-next-line consistent-return
export const validIpAddresses = value => {
  const re4 = /^(?=.*[^.]$)((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.?){4}(\/([0-9]|[1-2][0-9]|3[0-2]))?$/
  const re6 = /^s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:)))(%.+)?s*(\/([0-9]|[1-9][0-9]|1[0-1][0-9]|12[0-8]))?$/

  if (!isEmpty(value)) {
    const ips = value.split('\n')
    const invalid = ips.some(ip => {
      if (isEmpty(ip)) {
        return false
      }
      if (ip.includes('-')) {
        const [a, b] = ip.split('-')
        return !re4.test(a.trim()) || !re4.test(b.trim())
      }
      return !re4.test(ip.trim()) && !re6.test(ip.trim())
    })
    return invalid && 'Some IP addresses are invalid'
  }
}

export const passwordRules = memoize(value => {
  return {
    lowercase: /[a-z]/.test(value),
    uppercase: /[A-Z]/.test(value),
    number: /[\d]/.test(value),
    special: /[^a-zA-Z\d\s]/.test(value),
    length: value.length >= 8,
  }
})

export const password = memoize(
  value =>
    Object.values(passwordRules(value)).indexOf(false) > -1 &&
    'Invalid password'
)

export const createValidator = rules => (data = {}) => {
  const errors = {}
  Object.keys(rules).forEach(key => {
    const rule = join([].concat(rules[key]))
    const error = rule(data[key], data)
    if (error) {
      errors[key] = error
    }
  })
  return errors
}
