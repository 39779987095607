import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Drawer from '@groovehq/internal-design-system/lib/components/Drawer/UnmanagedDrawer'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import { selectSearchByQueryId } from 'ducks/searches/selectors'
import { doAddDrawerParam } from 'ducks/drawers/actions'
import { doMergeTags } from 'ducks/tags/actions'
import { TagDropdownWithDrawerCreate } from 'subapps/settings/components/TagDropdown'
import { styles } from './styles'

const TagMergeDrawer = ({
  drawerId,
  drawerResourceId: tagIdCsv = '',
  drawerMergeTagMode,
  drawerTagId,
  queryId,
  open,
  onClose,
  onExit,
}) => {
  const dispatch = useDispatch()
  const ids = tagIdCsv.split(',')
  const isIdUpdate = drawerMergeTagMode === 'ids'
  const { totalCount: searchTotal } = useSelector(state =>
    selectSearchByQueryId(state, queryId)
  )

  const totalToBeUpdated = isIdUpdate ? ids.length : searchTotal

  const handleSave = useCallback(
    () => {
      dispatch(
        doMergeTags({
          ids,
          mode: drawerMergeTagMode,
          queryId,
          parentTagId: drawerTagId,
        })
      )
      onClose()
    },
    [dispatch, drawerTagId, ids, drawerMergeTagMode, queryId, onClose]
  )

  const handleOnTagSelect = useCallback(
    tag => {
      dispatch(doAddDrawerParam(drawerId, 'drawerTagId', tag?.id))
    },
    [dispatch, drawerId]
  )

  const footer = (
    <ModalBtns
      saveBtnText="Merge"
      saveBtnDisabled={!drawerTagId}
      onSave={handleSave}
      tertiaryBtnText="Cancel"
      onClickTertiaryBtn={onClose}
    />
  )

  return (
    <Drawer title="Merge tags" open={open} footer={footer} onClose={onExit}>
      <div className="grui pt-10">
        <div>
          <div css={text.styles.textNormal} className="grui mb-10">
            {`You will merge ${totalToBeUpdated} selected tags into the following tag:`}
          </div>
          <div css={styles.dropdownContainer}>
            <TagDropdownWithDrawerCreate
              tagId={drawerTagId}
              onTagSelect={handleOnTagSelect}
              menuHeight={500}
            />
          </div>
        </div>
      </div>
    </Drawer>
  )
}

export default TagMergeDrawer
