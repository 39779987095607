import React, { useState, useCallback } from 'react'
import Modal from '@groovehq/internal-design-system/lib/components/Modal/Modal'
import AnimatedEllipsis from '@groovehq/internal-design-system/lib/components/AnimatedEllipsis/AnimatedEllipsis'
import debug from 'util/debug'
import { useSetupSubscription } from 'ducks/billing/hooks'
import { useAdminAccess } from '../NoAccess/hooks'
import { styles } from './styles'
import SetupPaymentMethodCard from '../../BillingCards/SetupPaymentMethodCard'

const FORM_ID = 'SETUP_PAYMENT_METHOD_ID'

const BillingSetupPaymentMethod = props => {
  const {
    onClose,
    onExit,
    drawerSaveSubscription,
    drawerConvertTrials,
    drawerPricingIds,
    drawerBillingCycle,
  } = props
  const { saveSubscription } = useSetupSubscription({
    convertTrials: drawerConvertTrials,
    pricingIds: drawerPricingIds,
    billingCycle: drawerBillingCycle,
  })

  const [isReady, setIsReady] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  useAdminAccess(onClose)

  const handleOnReady = useCallback(
    () => {
      setIsReady(true)
    },
    [setIsReady]
  )

  const handleOnSubmitting = useCallback(() => {
    setIsSubmitting(true)
  }, [])

  const handleOnSubmitted = useCallback(
    async () => {
      if (drawerSaveSubscription) {
        try {
          await saveSubscription()
        } catch (error) {
          debug(error)
        }
      }
      setIsSubmitting(false)
      onClose()
    },
    [onClose, saveSubscription, drawerSaveSubscription]
  )
  const handleOnError = useCallback(() => {
    setIsSubmitting(false)
  }, [])

  return (
    <Modal
      onClose={onExit}
      portal="#drawer-root"
      dataTestId="drawer-billing-setup-card"
      className="grui pb-14"
      open
      backdropTransparency="light"
    >
      <Modal.Title>Payment method</Modal.Title>
      <Modal.Description css={styles.description}>
        Quickly and securely setup your payment method.
      </Modal.Description>

      <SetupPaymentMethodCard
        className="grui mt-8 mb-8 w-100"
        formId={FORM_ID}
        onReady={handleOnReady}
        showFormButtons={false}
        onSubmitting={handleOnSubmitting}
        onSubmitted={handleOnSubmitted}
        onError={handleOnError}
        saveSubscription={drawerSaveSubscription}
        convertTrials={drawerConvertTrials}
        pricingIds={drawerPricingIds}
        billingCycle={drawerBillingCycle}
      />
      <Modal.Button
        type="info"
        disabled={!isReady || isSubmitting}
        htmlType="submit"
        form={FORM_ID}
      >
        {isSubmitting && (
          <span>
            Saving<AnimatedEllipsis />
          </span>
        )}
        {!isSubmitting && 'Save'}
      </Modal.Button>
      <Modal.Button
        type="tertiary"
        onClick={onClose}
        className="grui mt-5"
        disabled={!isReady || isSubmitting}
      >
        Cancel
      </Modal.Button>
    </Modal>
  )
}

export default BillingSetupPaymentMethod
