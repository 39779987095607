import { connect } from 'react-redux'
import { selectCurrentTicketIndexInList } from 'selectors/ticket_list'
import { selectCurrentTicketSearchResultTicketCount } from 'selectors/search/base'
import { doOpenNextTicket, doOpenPreviousTicket } from 'actions/autoRedirect'
import PagingButtonsView from 'components/ConversationHeader/Buttons/PagingButtons'

const select = state => ({
  currentConversationIndex: selectCurrentTicketIndexInList(state),
  totalConversations: selectCurrentTicketSearchResultTicketCount(state),
})

const perform = {
  openNextConversation: doOpenNextTicket,
  openPreviousConversation: doOpenPreviousTicket,
}

export default connect(select, perform)(PagingButtonsView)
