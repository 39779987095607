import { css } from '@emotion/react'

const header = css`
  h4 {
    margin-top: 56px !important;
  }
`

export const styles = {
  header,
}
