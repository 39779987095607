// List of language tags according to RFC 5646.
// See <http://tools.ietf.org/html/rfc5646> for info on how to parse
// these language tags. Some duplicates have been removed.
// Source: https://gist.github.com/msikma/8912e62ed866778ff8cd

// NOTE (jscheel): We used this full list in KB and NewWidget settings, even though
// we never got around to getting the rest of the languages translated. Now we
// have users that have specified certain languages that don't actually exist, but
// are set in their translation keys. We eventually need to migrate this data to
// only recognize the supported langauges, but we don't have time to do this now.
// Instead, I have added a "supported" key to filter supported languages by.
const languages = {
  af: { name: 'Afrikaans', supported: false },
  ar: { name: 'Arabic', supported: false },
  az: { name: 'Azeri (Latin)', supported: false },
  be: { name: 'Belarusian', supported: false },
  bg: { name: 'Bulgarian', supported: false },
  bn: { name: 'Bengali', supported: false },
  bs: { name: 'Bosnian (Bosnia and Herzegovina)', supported: false },
  ca: { name: 'Catalan', supported: false },
  cs: { name: 'Czech', supported: true },
  cy: { name: 'Welsh', supported: false },
  da: { name: 'Danish', supported: true },
  de: { name: 'German', supported: true },
  'de-AT': { name: 'German (Austria)', supported: false },
  'de-CH': { name: 'German (Switzerland)', supported: false },
  'de-DE': { name: 'German (Germany)', supported: false },
  el: { name: 'Greek', supported: true },
  en: { name: 'English', supported: true },
  'en-AU': { name: 'English (Australia)', supported: false },
  'en-CA': { name: 'English (Canada)', supported: false },
  'en-GB': { name: 'English (United Kingdom)', supported: false },
  'en-IE': { name: 'English (Ireland)', supported: false },
  'en-NZ': { name: 'English (New Zealand)', supported: false },
  'en-US': { name: 'English (United States)', supported: false },
  'en-ZA': { name: 'English (South Africa)', supported: false },
  eo: { name: 'Esperanto', supported: false },
  es: { name: 'Spanish', supported: true },
  'es-AR': { name: 'Spanish (Argentina)', supported: false },
  'es-CL': { name: 'Spanish (Chile)', supported: false },
  'es-CO': { name: 'Spanish (Colombia)', supported: false },
  'es-CR': { name: 'Spanish (Costa Rica)', supported: false },
  'es-EC': { name: 'Spanish (Ecuador)', supported: false },
  'es-ES': { name: 'Spanish (Spain)', supported: false },
  'es-MX': { name: 'Spanish (Mexico)', supported: false },
  'es-PA': { name: 'Spanish (Panama)', supported: false },
  'es-PE': { name: 'Spanish (Peru)', supported: false },
  'es-VE': { name: 'Spanish (Venezuela)', supported: false },
  et: { name: 'Estonian', supported: false },
  eu: { name: 'Basque', supported: false },
  fa: { name: 'Farsi', supported: false },
  fi: { name: 'Finnish', supported: true },
  fr: { name: 'French', supported: true },
  'fr-CA': { name: 'French (Canada)', supported: false },
  'fr-CH': { name: 'French (Switzerland)', supported: false },
  'fr-FR': { name: 'French (France)', supported: false },
  gl: { name: 'Galician', supported: false },
  he: { name: 'Hebrew', supported: false },
  hi: { name: 'Hindi', supported: false },
  'hi-IN': { name: 'Hindi (India)', supported: false },
  hr: { name: 'Croatian', supported: false },
  hu: { name: 'Hungarian', supported: true },
  id: { name: 'Indonesian', supported: true },
  is: { name: 'Icelandic', supported: false },
  it: { name: 'Italian', supported: true },
  'it-CH': { name: 'Italian (Switzerland)', supported: false },
  ja: { name: 'Japanese', supported: true },
  kn: { name: 'Kannada', supported: false },
  ko: { name: 'Korean', supported: true },
  lo: { name: 'Lao', supported: false },
  lt: { name: 'Lithuanian', supported: true },
  lv: { name: 'Latvian', supported: false },
  mk: { name: 'FYRO Macedonian', supported: false },
  mn: { name: 'Mongolian', supported: false },
  'mr-IN': { name: 'Marathi (India)', supported: false },
  ms: { name: 'Malay', supported: false },
  nb: { name: 'Norwegian (Bokmål)', supported: true },
  'nb-NO': { name: 'Norwegian (Bokmål) (Norway)', supported: false },
  ne: { name: 'Nepali', supported: false },
  nl: { name: 'Dutch', supported: true },
  or: { name: 'Oriya', supported: false },
  pa: { name: 'Punjabi (Eastern)', supported: false },
  pl: { name: 'Polish', supported: true },
  pt: { name: 'Portuguese', supported: false },
  'pt-BR': { name: 'Portuguese (Brazil)', supported: true },
  rm: { name: 'Romansh', supported: false },
  ro: { name: 'Romanian', supported: true },
  ru: { name: 'Russian', supported: true },
  sk: { name: 'Slovak', supported: false },
  sl: { name: 'Slovenian', supported: true },
  sq: { name: 'Albanian', supported: false },
  sr: { name: 'Serbian', supported: false },
  sv: { name: 'Swedish', supported: true },
  sw: { name: 'Swahili', supported: false },
  ta: { name: 'Tamil', supported: false },
  th: { name: 'Thai', supported: false },
  tl: { name: 'Tagalog', supported: false },
  tr: { name: 'Turkish', supported: true },
  tt: { name: 'Tatar', supported: false },
  uk: { name: 'Ukrainian', supported: false },
  ur: { name: 'Urdu', supported: false },
  uz: { name: 'Uzbek (Latin)', supported: false },
  vi: { name: 'Vietnamese', supported: false },
  wo: { name: 'Wolof', supported: false },
  'zh-CN': { name: 'Chinese (S)', supported: true },
  'zh-HK': { name: 'Chinese (Hong Kong)', supported: false },
  'zh-TW': { name: 'Chinese (T)', supported: false },
  'zh-YUE': { name: 'Cantonese', supported: false },
}

export default languages
