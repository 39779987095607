import React, { PureComponent, Fragment } from 'react'
import KeyboardHandler from 'components/Lists/KeyboardHandler'
import KeyboardNavigator from 'components/KeyboardNavigator'
import { getLength } from 'util/arrays'
import { propFunc, isFunction } from 'util/functions'

export default function withKeyboardNavigation(WrappedComponent) {
  class WithKeyboardNavigation extends PureComponent {
    getFocusedIndex = () => {
      const { items = [], focusedItem } = this.props
      return items.indexOf(focusedItem)
    }

    createHandleFunction = onFunc => {
      return propFunc(this.indexToItem, onFunc)
    }

    indexToItem = (onFunc, index, evt) => {
      const { items = [] } = this.props
      if (isFunction(onFunc)) {
        onFunc(items[index], false, evt)
      }
    }

    handleSelect = index => {
      const { onItemSelect, items } = this.props
      onItemSelect(items[index])
    }

    handleFocus = index => {
      const { onItemFocus, items } = this.props
      onItemFocus(items[index])
    }

    render() {
      const { items } = this.props
      const {
        pageSize = 8,
        onEnter,
        onTab,
        onUp,
        onDown,
        onPageDown,
        onPageUp,
        onLeft,
        onEsc,
        onShiftEnter,
        forwardedRef,
        ...rest
      } = this.props
      return (
        <Fragment>
          <KeyboardNavigator.Focus />
          <KeyboardHandler
            focusedIndex={this.getFocusedIndex()}
            count={getLength(items)}
            pageSize={pageSize}
            onEnter={this.createHandleFunction(onEnter)}
            onShiftEnter={this.createHandleFunction(onShiftEnter)}
            onTab={this.createHandleFunction(onTab)}
            onUp={this.createHandleFunction(onUp)}
            onDown={this.createHandleFunction(onDown)}
            onPageDown={this.createHandleFunction(onPageDown)}
            onPageUp={this.createHandleFunction(onPageUp)}
            onLeft={this.createHandleFunction(onLeft)}
            onEscape={this.createHandleFunction(onEsc)}
          />
          <WrappedComponent {...rest} ref={forwardedRef} />
        </Fragment>
      )
    }
  }

  return React.forwardRef((props, ref) => {
    return <WithKeyboardNavigation {...props} forwardedRef={ref} />
  })
}
