import config from 'config'
import Cookies from 'js-cookie'
import { createSelector } from 'reselect'
import { selectCurrentPage } from 'selectors/location'
import {
  isAuthenticatedSelector,
  selectAccountUsageOnboarding,
} from 'selectors/app/base'
import { selectByName } from 'ducks/flags/selectors'
import { selectIsBillingAccountActive } from 'ducks/billing/selectors/selectIsBillingAccountActive'
import { selectIsTrial } from 'ducks/billing/selectors/selectIsTrial'
import { selectIsFreemium } from 'ducks/billing/selectors/selectIsFreemium'
import { selectCurrentUserIsAdminOrOwner } from 'ducks/currentUser/selectors'
import {
  FEATURE_INBOX_MAX_INTEGRATIONS,
  FEATURE_INBOX_MAX_RULES,
} from 'ducks/billing/featureTypes'
import { selectLimitForFeature } from 'ducks/billing/selectors/features/selectLimitForFeature'
import { selectAllMailboxes } from 'selectors/mailboxes/selectAllMailboxes'
import * as storage from 'util/storage'
import * as v1Pages from '../v1/pages'
import * as v2Pages from '../v2/pages'
import * as v3Pages from '../v3/pages'
import * as v4Pages from '../v4/pages'
import * as v5Pages from '../v5/pages'
import * as v6Pages from '../v6/pages'
import * as shopifyPages from '../shopify/pages'
import { shortOnboardingWorkflowData } from '../data'

const NON_BOOTSTRAPPABLE_PAGES = new Set([
  v2Pages.V2_SIGNUP_PAGE,
  v3Pages.V3_SIGNUP_PAGE,
  v3Pages.V3_EMAIL_PAGE,
  v4Pages.V4_EMAIL_PAGE,
  v5Pages.V5_SIGNUP_PAGE,
  v5Pages.V5_EMAIL_PAGE,
  v6Pages.V6_SIGNUP_PAGE,
  v6Pages.V6_EMAIL_PAGE,
  shopifyPages.SHOPIFY_EMAIL_PAGE,
])

const BOOTSTRAPPABLE_PAGES = new Set(
  [
    ...Object.values(v2Pages),
    ...Object.values(v3Pages),
    ...Object.values(v4Pages),
    ...Object.values(v5Pages),
    ...Object.values(v6Pages),
    ...Object.values(shopifyPages),
  ].filter(page => !NON_BOOTSTRAPPABLE_PAGES.has(page))
)

export function selectOnboarding(state) {
  return state.onboarding || {}
}

export const selectIsBootstrapped = createSelector(
  selectOnboarding,
  onboarding => !!onboarding.rootReducer?.bootstrapped
)

export const selectShouldBootstrap = createSelector(
  selectIsBootstrapped,
  isAuthenticatedSelector,
  selectCurrentPage,
  (isBootstrapped, isAuthenticated, currentPage) => {
    return (
      !isBootstrapped &&
      isAuthenticated &&
      BOOTSTRAPPABLE_PAGES.has(currentPage)
    )
  }
)

export const selectIsOnOnboardingPage = state =>
  state.page.subapp === 'onboarding'

export const selectIsOnSignupPage = state =>
  state.page.subapp === 'onboarding' &&
  state.page.componentName === 'SignupPage'
export const selectIsOnCustomerInformationPage = state =>
  state.page.subapp === 'onboarding' &&
  state.page.componentName === 'CustomerInformationPage'
export const selectIsOnChannelOverviewPage = state =>
  state.page.subapp === 'onboarding' &&
  state.page.componentName === 'ChannelOverviewPage'
export const selectIsOnAddTeamMembersPage = state =>
  state.page.subapp === 'onboarding' &&
  state.page.componentName === 'AddTeamMembersPage'
export const selectIsOnAddChannelPage = state =>
  state.page.subapp === 'onboarding' &&
  state.page.componentName === 'AddChannelPage'
export const selectIsOnSetupEmailPage = state =>
  state.page.subapp === 'onboarding' &&
  state.page.componentName === 'SetupEmailPage'

export function selectInputWithFocus(state) {
  return selectOnboarding(state).inputWithFocus
}

export const selectIsOnCompletedPage = state =>
  state.page.subapp === 'onboarding' &&
  state.page.componentName === 'CompletedPage'

export const selectIsOnAcceptInvitePage = state =>
  state.page.subapp === 'onboarding' &&
  state.page.componentName === 'AcceptInvitePage'

export const selectVersion = createSelector(selectCurrentPage, page => {
  if (Object.values(v1Pages).includes(page)) return 1
  if (Object.values(v2Pages).includes(page)) return 2
  if (Object.values(v6Pages).includes(page)) return 6
  return null
})

const selectCurrentV1Step = createSelector(selectCurrentPage, page => {
  switch (page) {
    case v1Pages.SIGNUP_PAGE:
      return 1
    case v1Pages.CUSTOMER_INFORMATION_PAGE:
      return 2
    case v1Pages.CHANNEL_OVERVIEW_PAGE:
    case v1Pages.ADD_CHANNEL_PAGE:
    case v1Pages.SETUP_EMAIL_PAGE:
    case v1Pages.FACEBOOK_PAGE_SELECTION_PAGE:
      return 3
    case v1Pages.ADD_TEAM_MEMBERS_PAGE:
      return 4
    default:
      return 5
  }
})

const selectCurrentV2Step = createSelector(selectCurrentPage, page => {
  switch (page) {
    case v2Pages.V2_SIGNUP_PAGE:
      return 1
    case v2Pages.V2_COMPANY_PAGE:
      return 2
    case v2Pages.V2_OWNER_PAGE:
      return 3
    case v2Pages.V2_PASSWORD_PAGE:
    case v2Pages.V2_VERIFICATION_PAGE:
      return 4
    default:
      return 5
  }
})

export const selectCurrentStep = createSelector(
  selectVersion,
  selectCurrentV1Step,
  selectCurrentV2Step,
  (version, currentV1Step, currentV2Step) => {
    if (version === 1) return currentV1Step
    if (version === 2) return currentV2Step
    return null
  }
)

export const selectIsOnV2SignupPage = createSelector(
  selectCurrentPage,
  page => {
    return page === v2Pages.V2_SIGNUP_PAGE
  }
)

export const selectIsOnV2CompanyPage = createSelector(
  selectCurrentPage,
  page => {
    return page === v2Pages.V2_COMPANY_PAGE
  }
)

export const selectIsOnV2OwnerPage = createSelector(selectCurrentPage, page => {
  return page === v2Pages.V2_OWNER_PAGE
})

export const selectIsOnV2PasswordPage = createSelector(
  selectCurrentPage,
  page => {
    return page === v2Pages.V2_PASSWORD_PAGE
  }
)

export const selectIsOnV2VerificationPage = createSelector(
  selectCurrentPage,
  page => {
    return page === v2Pages.V2_VERIFICATION_PAGE
  }
)

export const selectIsOnV2CompletionPage = createSelector(
  selectCurrentPage,
  page => {
    return page === v2Pages.V2_COMPLETION_PAGE
  }
)

export const selectIsOnV3SignupPage = createSelector(
  selectCurrentPage,
  page => {
    return page === v3Pages.V3_SIGNUP_PAGE
  }
)

export const selectIsOnV3EmailPage = createSelector(selectCurrentPage, page => {
  return page === v3Pages.V3_EMAIL_PAGE
})

export const selectIsOnV3CompanyPage = createSelector(
  selectCurrentPage,
  page => {
    return page === v3Pages.V3_COMPANY_PAGE
  }
)

export const selectIsOnV3VerificationPage = createSelector(
  selectCurrentPage,
  page => {
    return page === v3Pages.V3_VERIFICATION_PAGE
  }
)

export const selectIsOnV4EmailPage = createSelector(selectCurrentPage, page => {
  return page === v4Pages.V4_EMAIL_PAGE
})

export const selectIsOnV4UserPage = createSelector(selectCurrentPage, page => {
  return page === v4Pages.V4_USER_PAGE
})

export const selectIsOnV4CompanyPage = createSelector(
  selectCurrentPage,
  page => {
    return page === v4Pages.V4_COMPANY_PAGE
  }
)

export const selectIsOnV4VerificationPage = createSelector(
  selectCurrentPage,
  page => {
    return page === v4Pages.V4_VERIFICATION_PAGE
  }
)

export const selectIsOnV5SignupPage = createSelector(
  selectCurrentPage,
  page => {
    return page === v5Pages.V5_SIGNUP_PAGE
  }
)

export const selectIsOnV5EmailPage = createSelector(selectCurrentPage, page => {
  return page === v5Pages.V5_EMAIL_PAGE
})

export const selectIsOnV5UserPage = createSelector(selectCurrentPage, page => {
  return page === v5Pages.V5_USER_PAGE
})

export const selectIsOnV5CompanyPage = createSelector(
  selectCurrentPage,
  page => {
    return page === v5Pages.V5_COMPANY_PAGE
  }
)

export const selectIsOnV5VerificationPage = createSelector(
  selectCurrentPage,
  page => {
    return page === v5Pages.V5_VERIFICATION_PAGE
  }
)

export const selectIsOnV6SignupPage = createSelector(
  selectCurrentPage,
  page => {
    return page === v6Pages.V6_SIGNUP_PAGE
  }
)

export const selectIsOnV6EmailPage = createSelector(selectCurrentPage, page => {
  return page === v6Pages.V6_EMAIL_PAGE
})

export const selectIsOnV6CompanyPage = createSelector(
  selectCurrentPage,
  page => {
    return page === v6Pages.V6_COMPANY_PAGE
  }
)

export const selectIsOnV6CompanyPage2 = createSelector(
  selectCurrentPage,
  page => {
    return page === v6Pages.V6_COMPANY_2_PAGE
  }
)

export const selectIsOnV6VerificationPage = createSelector(
  selectCurrentPage,
  page => {
    return page === v6Pages.V6_VERIFICATION_PAGE
  }
)

export const selectOnboardingLayoutVariant = () => {
  const onboardingLayoutVariantValue =
    storage.get('onboardingLayoutVariant') || 3

  return parseInt(
    Cookies.get('onboardingLayoutVariant', {
      domain: `.${config.appDomain}`,
    }) || onboardingLayoutVariantValue,
    10
  )
}

export const selectIsOnShopifyEmailPage = createSelector(
  selectCurrentPage,
  page => {
    return page === shopifyPages.SHOPIFY_EMAIL_PAGE
  }
)

export const selectIsOnShopifyUserPage = createSelector(
  selectCurrentPage,
  page => {
    return page === shopifyPages.SHOPIFY_USER_PAGE
  }
)

export const selectIsOnShopifyCompanyPage = createSelector(
  selectCurrentPage,
  page => {
    return page === shopifyPages.SHOPIFY_COMPANY_PAGE
  }
)

export const selectIsOnShopifyTrialPage = createSelector(
  selectCurrentPage,
  page => {
    return page === shopifyPages.SHOPIFY_TRIAL_PAGE
  }
)

export const selectFeatureBasedOnboardingWorkflowData = createSelector(
  () => shortOnboardingWorkflowData,
  state => selectLimitForFeature(state, FEATURE_INBOX_MAX_RULES),
  state => selectLimitForFeature(state, FEATURE_INBOX_MAX_INTEGRATIONS),
  (workflow, maxRules, maxIntegrations) => {
    return Object.keys(workflow).reduce((result, key) => {
      if (key === 'rule' && maxRules === 0) return result
      if (key === 'integration' && maxIntegrations === 0) return result

      // eslint-disable-next-line no-param-reassign
      result[key] = workflow[key]
      return result
    }, {})
  }
)

export const selectOnboardingWorkflowCompletedAndSkippedList = createSelector(
  selectAccountUsageOnboarding,
  selectByName,
  selectFeatureBasedOnboardingWorkflowData,
  selectAllMailboxes,
  (accountUsageOnboarding, flags, onboardingWorkflowData, allMailboxes) => {
    return Object.keys(onboardingWorkflowData).filter(key => {
      if (
        key === 'mailbox' &&
        allMailboxes.some(m => ['active', 'confirmed'].includes(m.state))
      ) {
        // in case usage_onboarding.mailboxes_created isn't reliable
        return true
      }
      return (
        !!accountUsageOnboarding[onboardingWorkflowData[key].usageKey] ||
        !!flags[onboardingWorkflowData[key].flagName]
      )
    })
  }
)

export const selectCanOnboardAccount = createSelector(
  selectIsBillingAccountActive,
  selectCurrentUserIsAdminOrOwner,
  selectIsTrial,
  state => selectIsFreemium(state),
  (isAccountActive, isManager, isTrial, isFreemium) => {
    return isManager && isAccountActive && (isTrial || isFreemium)
  }
)
