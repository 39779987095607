import { all } from 'util/arrays'
import { curry } from 'util/functions'
import { constructSearchQueryObject, constructSearchQueryString } from './index'
import isQueryStringReady from './isQueryStringReady'

const KEYS_THAT_MUST_BE_IDS_TO_PIN = [
  'assignee',
  'assigned_group',
  'draft',
  'folder',
  'label',
  'mailbox',
  'mentions',
]

const KEYS_TO_IGNORE = { keywords: true }

function isID(key, value) {
  if (
    (key === 'assignee' || key === 'assigned_group') &&
    value === 'unassigned'
  )
    return true
  return !!value.match(/^\d+$/)
}

function checkValuesAreIDs(search, key) {
  const values = search[key]
  if (!values || values.length === 0) return true
  const areIDs = curry(isID)(key)
  return all(areIDs, values)
}

export default function isSearchPinnable(search) {
  const queryString = constructSearchQueryString(search)
  if (!isQueryStringReady(queryString)) return false
  const queryObject = constructSearchQueryObject(search)
  // if the query object contains any key that can't be pinned return false before further checks
  if (Object.keys(queryObject).some(key => KEYS_TO_IGNORE[key])) return false
  const valuesAreIDsForKeys = curry(checkValuesAreIDs)(queryObject)
  return all(valuesAreIDsForKeys, KEYS_THAT_MUST_BE_IDS_TO_PIN)
}
