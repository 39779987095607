export const USERS_RANGES = {
  everyone: 'all-agents',
  certainMembers: 'selected-agents',
  me: 'me',
  // For AddAgents in Channels
  certainTeams: 'selected-teams',
}

export const users = []

export const userRanges = [
  {
    key: USERS_RANGES.everyone,
    title: 'Everyone',
  },
  {
    key: USERS_RANGES.certainMembers,
    title: `Specific ${app.t('agents')}`,
  },
  {
    key: USERS_RANGES.certainTeams,
    title: `Specific ${app.t('teams')}`,
  },
  {
    key: USERS_RANGES.me,
    title: 'Only me',
  },
]
