import { css } from '@emotion/react'

const appBarList = theme => css`
  & > div:not(.close) {
    margin-top: ${theme.spacing['7']};
  }

  & > div:not(.close) ~ div:not(.close) {
    margin-top: ${theme.spacing['4']};
  }

  & > .close {
    margin-top: ${theme.spacing['0']};
  }
`

export const styles = {
  appBarList,
}
