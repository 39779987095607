import { refreshOnNextPage } from 'util/refreshMiddleware'
import app from '../app'

function watchForUpdate() {
  if (!window.applicationCache) return
  window.applicationCache.addEventListener(
    'updateready',
    e => {
      console.log('[cache] update is ready')
      refreshOnNextPage()
    },
    false
  )
}

module.exports = {
  watchForUpdate,
}
