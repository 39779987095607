import logo from 'assets/integrations/trello/icon-256.png'
import actionsScreenShot from 'assets/integrations/trello/screenshot-actions.jpg'
import linkScreenShot from 'assets/integrations/trello/screenshot-link.jpg'
import sidebarScreenShot from 'assets/integrations/trello/screenshot-sidebar.jpg'

import IntegrationsModal from '../../shared/Modal'
import TrelloSettingsForm from './TrelloSettingsForm'
import TrelloInstallDescription from './TrelloInstallDescription'

export default function IntegrationsTrelloModal(props) {
  return (
    <IntegrationsModal
      {...props}
      InstallDescription={TrelloInstallDescription}
      logo={logo}
      provider="trello"
      providerStylized="Trello"
      tagLine="Create and link Trello cards right from your conversations, and get updated on any changes."
      screenShots={{
        'A screen shot of the Trello actions in Groove': actionsScreenShot,
        'A screen shot of a linking Trello cards in Groove': linkScreenShot,
        'A screen shot of Trello cards in Groove': sidebarScreenShot,
      }}
      SettingsForm={TrelloSettingsForm}
    />
  )
}
