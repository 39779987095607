import React from 'react'
import {
  text,
  paragraph,
} from '@groovehq/internal-design-system/lib/styles/elements'
import useFetchEntityById from 'ducks/entities/hooks/useFetchEntityById'
import { doFetchSpammerV1 } from 'ducks/spammers'
import { FETCH_SPAMMERS } from 'ducks/spammers/types'

const curriedDoFetchSpammerV1 = ({ id }) => doFetchSpammerV1(id)

const SpammerDeleteContent = ({ id }) => {
  const { entity: spammer } = useFetchEntityById(
    id,
    'spammer',
    curriedDoFetchSpammerV1,
    FETCH_SPAMMERS
  )

  if (!spammer) {
    return <span css={text.styles.fontMedium}>Loading...</span>
  }

  return (
    <>
      <p css={[paragraph.styles.preflight, text.styles.textNormal]}>
        You are about to remove the address “<span css={text.styles.fontMedium}>
          {spammer.email}
        </span>” from your blacklist.
      </p>
    </>
  )
}

export default SpammerDeleteContent
