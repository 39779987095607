import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util'

const header = css`
  h4 {
    margin-top: 44px !important;
  }
`

const fullWidth = css`
  max-width: 100%;
`

const colorLabel = css`
  & + * {
    width: 100%;
    max-width: ${rem(320)};
  }
`

const colorPickerBtn = css`
  width: 100%;
`

const popoverContainer = css`
  left: 0 !important;
  right: unset !important;
`

const btnIcon = theme => css`
  svg {
    width: ${rem(24)};
    height: ${rem(24)};
    background: ${theme.color.monochrome.white};
    border-radius: 50%;
    vertical-align: middle;
  }
`

const messageCard = css`
  padding-right: ${rem(30)};
`

const portField = css`
  width: 120px;
  min-width: 120px;
`

export const styles = {
  header,
  fullWidth,
  colorLabel,
  colorPickerBtn,
  popoverContainer,
  btnIcon,
  messageCard,
  portField,
}
