const start = name => performance.mark(`${name}-start`)

const stop = name => {
  performance.mark(`${name}-end`)
  performance.measure(`${name}`, `${name}-start`, `${name}-end`)
}

export default {
  start,
  stop,
}
