import React, { useMemo } from 'react'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import Modal from '@groovehq/internal-design-system/lib/components/Modal/Modal'
import outlook from '@groovehq/internal-design-system/lib/assets/icons/logos/outlook.png'
import gmail from '@groovehq/internal-design-system/lib/assets/icons/logos/gmail.png'
import goDaddy from '@groovehq/internal-design-system/lib/assets/icons/logos/go-daddy.png'
import googleGroups from '@groovehq/internal-design-system/lib/assets/icons/logos/google-groups.png'
import zoho from '@groovehq/internal-design-system/lib/assets/icons/logos/zoho.png'
import cpanel from 'assets/logos/cpanel.png'
import { pluralize } from 'util/strings'

import { styles } from './styles'

const links = [
  {
    Icon: <img src={gmail} alt="Gmail logo" />,
    name: 'Gmail Alias',
    url:
      'https://help.groovehq.com/help/forward-email-from-a-google-alias-account',
    provider: ['google', 'googleAlias', 'gmail'],
  },
  {
    Icon: <img src={googleGroups} alt="Google group logo" />,
    name: 'Google Group',
    url:
      'https://help.groovehq.com/help/how-to-forward-email-from-a-google-group',
    provider: ['google', 'googleGroup', 'gmail'],
  },
  {
    Icon: <img src={outlook} alt="Outlook logo" />,
    name: 'Outlook & Microsoft 365',
    url: 'https://help.groovehq.com/help/how-to-forward-email-from-outlook',
    provider: ['outlook', 'office365'],
  },
  {
    Icon: <img src={goDaddy} alt="Go Daddy logo" />,
    name: 'Go Daddy',
    url: 'https://help.groovehq.com/help/how-to-forward-email-from-godaddy',
  },
  {
    Icon: <img src={zoho} alt="Zoho mail logo" />,
    name: 'Zoho Mail',
    url: 'https://help.groovehq.com/help/how-to-forward-email-from-zoho',
  },
  {
    Icon: <img src={cpanel} alt="CPanel logo" />,
    name: 'CPanel',
    url: 'https://help.groovehq.com/help/how-to-forward-email-from-cpanel',
  },
]

const ForwardingGuides = ({ provider }) => {
  const data = useMemo(
    () => {
      let result = []
      if (provider) {
        result = links.filter(
          link =>
            link.provider === provider || link.provider?.includes(provider)
        )
      }
      return result.length ? result : links
    },
    [provider]
  )
  return (
    <Modal.AttachedContainer
      title={
        <>
          Step-by-step <br />
          {`forwarding ${pluralize(data.length, 'guide')}`}
        </>
      }
      css={styles.forwardingWrapper}
    >
      <div>
        {data.map(link => (
          <Button
            type="tertiary"
            customIcon={link.Icon}
            as="a"
            href={link.url}
            target="_blank"
            rel="noopener noreferrer"
            key={link.name}
            css={styles.forwardingGuidesBtn}
          >
            {link.name}
          </Button>
        ))}
      </div>
    </Modal.AttachedContainer>
  )
}

export default ForwardingGuides
