/**
 * Incomplete query strings are:
 *  - falsey values
 *  - empty string
 *  - ending in :
 *  - ending in a space
 *  - having an unterminated quoted value ie an odd number of " or '
 */
export default function isQueryStringComplete(queryString) {
  return !!(
    queryString &&
    queryString !== '' &&
    !queryString.match(/(:|\s)$/) &&
    !queryString.match(/:("|')[^"']*$/)
  )
}
