import React from 'react'

import { throttle } from 'util/functions'

export default function withResizeChange(WrappedComponent) {
  return class extends React.Component {
    state = {
      height: window.innerHeight,
      width: window.innerWidth,
    }

    componentWillMount() {
      window.addEventListener('resize', this.onResizeThrottled)
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.onResizeThrottled)
    }

    onResize = () => {
      this.setState({
        height: window.innerHeight,
        width: window.innerWidth,
      })
    }

    onResizeThrottled = throttle(this.onResize, 20)

    render() {
      return (
        <WrappedComponent
          {...this.props}
          height={this.state.height}
          width={this.state.width}
        />
      )
    }
  }
}
