import config from 'config'
import FaviconDot from 'favorite-icon-dot'
import themeVars from 'ui_theme/site/globals/site.variables'

let favicon = null
let faviconTimer = null

export function initFavico() {
  if (config.isTest) return
  favicon = new FaviconDot({
    backgroundColor: themeVars.newBerry,
    strokeColor: themeVars.newBerry,
    radius: 5,
    faviconSrc: document.querySelector('link[rel*="icon"][sizes="32x32"]').href,
    positionX: 'right',
    positionY: 'top',
  })
}

export function getFavico() {
  return favicon
}

export function updateCount(count) {
  if (!favicon || config.isTest) return
  if (faviconTimer) {
    clearTimeout(faviconTimer)
  }
  faviconTimer = setTimeout(() => {
    if (count > 0) {
      favicon.show()
    } else {
      favicon.hide()
    }
  }, 100)
}

export function normalizeCount(count) {
  if (!count) return 0
  if (count < 0) return 0
  if (count > 99) return '99+'
  return count
}
