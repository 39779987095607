import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  heading,
  text,
} from '@groovehq/internal-design-system/lib/styles/elements'
import { selectAgent } from 'selectors/agents/base'
import { styles } from './style'

const TicketDeleted = ({ actorId, actorType }) => {
  const agent = useSelector(state => selectAgent(state, actorId))

  const deletedErrorMsg = useMemo(
    () => {
      let errorMsg = 'This conversation has been deleted'
      if (actorType === 'Agent' && agent?.fullName) {
        errorMsg += ` by ${agent.fullName}.`
      } else if (actorType === 'Rule') {
        errorMsg += ' by a rule.'
      } else {
        errorMsg += '.'
      }
      return errorMsg
    },
    [actorType, agent?.fullName]
  )

  return (
    <div className="grui flex-grow text-center">
      <h1 css={[heading.styles.h1, styles.error]}>404</h1>
      <h2 css={heading.styles.h2} className="grui mt-2">
        Conversation deleted
      </h2>
      <div css={text.styles.textNormal} className="grui mt-5">
        {deletedErrorMsg}
      </div>
    </div>
  )
}

export default TicketDeleted
