import React from 'react'
import ReactDOM from 'react-dom'

import getDisplayName from 'util/hoc/getDisplayName'

// Allow a user to append an element to the body
export default function inBody(WrappedChild) {
  class InBody extends React.Component {
    render() {
      return ReactDOM.createPortal(
        <WrappedChild {...this.props} />,
        document.body
      )
    }
  }

  InBody.displayName = `InBody(${getDisplayName(WrappedChild)})`

  return InBody
}
