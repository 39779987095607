import { useMemo } from 'react'
import { getType } from 'util/globalId'

// globalId.js
const GLOBAL_ID_PREFIX_TO_LABEL = {
  Channel: 'Email',
  Widget: 'Live Chat',
}

const useColumns = ({ showType } = {}) => {
  const columns = useMemo(
    () => {
      const formatChannelName = ({ row: { original } }) => {
        const { id, name } = original

        return (
          <label htmlFor={id}>
            <span>{name}</span>
          </label>
        )
      }

      const formatType = ({ value }) => {
        return <span>{GLOBAL_ID_PREFIX_TO_LABEL[getType(value)]}</span>
      }

      return [
        {
          Header: 'Name',
          accessor: 'name',
          Cell: formatChannelName,
        },
        showType && {
          Header: 'Type',
          accessor: 'id',
          Cell: formatType,
        },
        {
          Header: 'Email',
          accessor: 'email',
        },
      ].filter(Boolean)
    },
    [showType]
  )

  return columns
}

export default useColumns
