import { CREATE_INVITATION_REQUEST } from 'constants/action_types'
import { CHANNEL_OVERVIEW_PAGE, ADD_CHANNEL_PAGE } from '../pages'

import { SWITCH_INPUT_MODE, INVITATION_ERROR } from './types'

const defaultAddTeamMembersState = {
  inputMode: 'single',
  emailAddresses: [
    { value: '', isValid: false },
    { value: '', isValid: false },
    { value: '', isValid: false },
  ],
  backPageType: CHANNEL_OVERVIEW_PAGE,
}

export default function customerInformationReducer(
  state,
  { type, payload: { errors } = {} }
) {
  switch (type) {
    case SWITCH_INPUT_MODE:
      return {
        ...state,
        inputMode: state.inputMode === 'single' ? 'multi' : 'single',
        invitationError: null,
      }
    case CREATE_INVITATION_REQUEST: {
      return {
        ...state,
        invitationError: null,
      }
    }
    case INVITATION_ERROR: {
      return {
        ...state,
        invitationErrors: errors,
      }
    }
    case ADD_CHANNEL_PAGE:
      return {
        ...state,
        backPageType: ADD_CHANNEL_PAGE,
      }
    case CHANNEL_OVERVIEW_PAGE:
      return {
        ...state,
        backPageType: CHANNEL_OVERVIEW_PAGE,
      }
    default:
      return state || defaultAddTeamMembersState
  }
}
