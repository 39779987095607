export function detectScrollbarType() {
  const el = document.createElement('div')
  el.style.width = '100px'
  el.style.height = '100px'
  el.style.overflow = 'scroll'
  el.style.position = 'absolute'
  el.style.top = '-9999px'
  el.style.left = '-9999px'
  el.style.visibility = 'hidden'
  document.body.appendChild(el)
  const type =
    el.offsetWidth - el.clientWidth > 0
      ? 'normal-scrollbars'
      : 'overlay-scrollbars'
  document.body.removeChild(el)
  document.documentElement.classList.add(type)
}
