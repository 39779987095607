import Bugsnag from '@bugsnag/js'
import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from '@groovehq/internal-design-system/lib/components/Modal/Modal'
import AnimatedEllipsis from '@groovehq/internal-design-system/lib/components/AnimatedEllipsis/AnimatedEllipsis'
import ProgressDots from '@groovehq/internal-design-system/lib/components/ProgressDots/ProgressDots'
import storage from 'util/storage'
import {
  useChannel,
  useRebuildLeftNavMenu,
  useRedirectToChannel,
} from 'ducks/channels/hooks'
import { doSendVerification } from 'ducks/mailboxes/actions'
import { doBuildInboxMenuFromMailboxes } from 'ducks/folders/operations/collections'
import {
  doOpenSearchPageWithMailboxAndFolder,
  doSaveChannelDraft,
} from 'ducks/channels/actions'
import {
  DRAWER_TYPE_CHANNELS_FORWRADING_FAILED,
  DRAWER_TYPE_CHANNELS_MEMBERS_EDIT_MODAL,
} from 'ducks/drawers/types'
import { DRAWER_ID_FOLDERS_CHANNELS_MEMBERS_EDIT } from 'ducks/drawers/ids'
import { useDrawer } from 'ducks/drawers/hooks'
import { selectHasMailboxReceivedForwardingConfirmationCode } from 'selectors/tickets'
import { selectIsInInbox } from 'selectors/location'
import { selectAgentCount } from 'selectors/agents/base'
import { selectChannelCreationStepCount } from 'ducks/channels/selectors'
import { doFetchTicketSearch } from 'actions/search/doFetchTicketSearch'
import { NEWEST } from 'constants/defaults'
import { buildDrawerQueryParam } from 'ducks/drawers/util'
import animatedMailbox from 'assets/mailbox.gif'
import { useAdminAccess } from '../../NoAccess/hooks'
import { CHANNEL_TYPES } from '../Channels.data'

const TEST_CONNECTION_STATUS_PENDING = 'PENDING'
const TEST_CONNECTION_STATUS_VERIFYING = 'VERIFYING'
const TEST_CONNECTION_STATUS_VERIFIED = 'VERIFIED'
const TEST_CONNECTION_STATUS_ERROR = 'ERROR'

function isConfirmed(state) {
  return ['active', 'confirmed'].includes(state)
}
const ForwardingTest = ({
  drawerResourceId: channelId,
  onClose,
  onExit,
  drawerChannelType: channelType = 'forwarding',
  // If hasConfirmationCode is true: testing whether received a conformation code email
  // otherwise testing connection
  drawerChannelHasConfirmationCode: hasConfirmationCode,
  drawerChannelTypeMigrating: isMigrating,
}) => {
  const dispatch = useDispatch()
  const timers = useRef({
    firstStage: null,
    timeoutStage: null,
    loadChannel: null,
    failed: null,
    success: null,
  })
  useAdminAccess(onClose)
  const { channel, isLoading, isMissing, loadChannel } = useChannel(channelId, {
    useCachedIfAvailable: false,
  })
  const { state, folders, id } = channel || {}
  const isInInbox = useSelector(selectIsInInbox)

  const { rebuildExitAndRedirect } = useRedirectToChannel({
    channelId,
    onExit,
  })

  const { rebuildMenuAndExit } = useRebuildLeftNavMenu({ channelId, onExit })

  const {
    drawerId: forwardingFailDrawerId,
    openDrawer: openForwadingFailModal,
  } = useDrawer({
    id: null,
    type: DRAWER_TYPE_CHANNELS_FORWRADING_FAILED,
    closeIgnoresStack: false,
  })
  const { openDrawer: openEditMembers } = useDrawer({
    id: DRAWER_ID_FOLDERS_CHANNELS_MEMBERS_EDIT,
    type: DRAWER_TYPE_CHANNELS_MEMBERS_EDIT_MODAL,
    closeIgnoresStack: false,
  })

  const [heading, setHeading] = useState(null)
  const [testConnectionStatus, setTestConnectionStatus] = useState(
    TEST_CONNECTION_STATUS_PENDING
  )
  const hasReceivedConfirmationCode = useSelector(storeState =>
    selectHasMailboxReceivedForwardingConfirmationCode(storeState, id)
  )
  const activeAgentCount = useSelector(selectAgentCount)
  const creationStepCount = useSelector(
    storeState =>
      isMigrating
        ? 0
        : selectChannelCreationStepCount(storeState, CHANNEL_TYPES[channelType])
  )
  const showReceivedConfirmationCodeMail =
    hasConfirmationCode &&
    hasReceivedConfirmationCode &&
    testConnectionStatus !== TEST_CONNECTION_STATUS_VERIFIED

  const shouldShowTeamMembersEdit = activeAgentCount > 1 && !isMigrating

  const handleOnExit = useCallback(
    () => {
      // we do not rebuild left nav as soon as channel is created anymore
      // if the user clicks the Exit button on top right, rebuild nav
      if (isInInbox) {
        rebuildExitAndRedirect()
        return
      }
      rebuildMenuAndExit()
    },
    [isInInbox, rebuildMenuAndExit, rebuildExitAndRedirect]
  )

  const onTestConnectionError = useCallback(
    () => {
      setTestConnectionStatus(TEST_CONNECTION_STATUS_ERROR)
      timers.current.failed = setTimeout(() => {
        const confirmationCodeParam = hasConfirmationCode
          ? buildDrawerQueryParam(
              forwardingFailDrawerId,
              'drawerChannelHasConfirmationCode',
              1
            )
          : {}
        openForwadingFailModal(channelId, {
          query: confirmationCodeParam,
        })
      }, 500)
    },
    [
      channelId,
      openForwadingFailModal,
      forwardingFailDrawerId,
      hasConfirmationCode,
    ]
  )

  const onVerificationSend = useCallback(() => {
    setTestConnectionStatus(TEST_CONNECTION_STATUS_VERIFYING)
  }, [])

  const onVerificationError = useCallback(
    () => {
      onTestConnectionError()
    },
    [onTestConnectionError]
  )

  const handleRebuildExitAndRedirect = useCallback(
    () => {
      dispatch(doBuildInboxMenuFromMailboxes())
      onExit()
      dispatch(
        doOpenSearchPageWithMailboxAndFolder(
          channelId,
          folders ? folders[0].id : null
        )
      )
    },
    [dispatch, onExit, channelId, folders]
  )

  const onVerificationSuccess = useCallback(
    () => {
      // The member edit is in the next step, so we need to update the draft for it
      dispatch(doSaveChannelDraft(channelId, channel || {}))

      clearTimeout(timers.current.timeoutStage)
      timers.current.timeoutStage = null
      setTestConnectionStatus(TEST_CONNECTION_STATUS_VERIFIED)
      timers.current.success = setTimeout(() => {
        if (shouldShowTeamMembersEdit) {
          openEditMembers(channelId)
        } else {
          handleRebuildExitAndRedirect()
        }
      }, 1000)
    },
    [
      channelId,
      openEditMembers,
      dispatch,
      shouldShowTeamMembersEdit,
      channel,
      handleRebuildExitAndRedirect,
    ]
  )

  useEffect(
    () => {
      if (isMissing) onClose()
    },
    [isMissing, onClose]
  )

  useEffect(
    () => {
      if (
        isConfirmed(state) &&
        testConnectionStatus === TEST_CONNECTION_STATUS_VERIFYING
      ) {
        onVerificationSuccess()
      }
    },
    [onVerificationSuccess, state, testConnectionStatus]
  )

  useEffect(
    () => {
      if (
        isConfirmed(state) ||
        testConnectionStatus !== TEST_CONNECTION_STATUS_VERIFYING ||
        // We intentionally only load on false value because
        // the built in effect inside useChannel will load
        // the channel will the value is null
        isLoading === false ||
        isMissing ||
        timers.current.loadChannel
      )
        return

      timers.current.loadChannel = setTimeout(() => {
        timers.current.loadChannel = null
        loadChannel()
      }, 5000)
    },
    [testConnectionStatus, state, isLoading, isMissing, loadChannel]
  )

  useEffect(
    () => {
      if (testConnectionStatus === TEST_CONNECTION_STATUS_VERIFYING) {
        setHeading(
          hasConfirmationCode
            ? 'Waiting for the confirmation email'
            : `We’ve sent a test email`
        )

        timers.current.firstStage = setTimeout(() => {
          setHeading('Hang tight')
        }, 20 * 1000)

        timers.current.timeoutStage = setTimeout(() => {
          onTestConnectionError()
        }, 60 * 1000)
      }
    },
    [testConnectionStatus, onTestConnectionError, hasConfirmationCode]
  )

  useEffect(() => {
    return () => {
      clearTimeout(timers.current.firstStage)
      if (timers.current.timeoutStage) {
        clearTimeout(timers.current.timeoutStage)
      }
      clearTimeout(timers.current.loadChannel)
      clearTimeout(timers.current.failed)
      clearTimeout(timers.current.success)
      timers.current = {
        firstStage: null,
        timeoutStage: null,
        loadChannel: null,
        failed: null,
        success: null,
      }
    }
  }, [])

  useEffect(
    () => {
      if (
        testConnectionStatus !== TEST_CONNECTION_STATUS_PENDING ||
        hasConfirmationCode
      )
        return

      onVerificationSend()
      dispatch(doSendVerification(channelId)).catch(error => {
        // We don't want to show the verification error if testing whether has received confirmation code
        if (hasConfirmationCode) return
        onVerificationError()
        // eslint-disable-next-line no-console
        console.error('Unable to send the verification email', { error })
        Bugsnag.notify(error, event => {
          // eslint-disable-next-line no-param-reassign
          event.severity = 'error'

          event.addMetadata('metaData', {
            channelId,
          })
        })
      })
    },
    [
      testConnectionStatus,
      channelId,
      dispatch,
      onVerificationSend,
      onVerificationSuccess,
      onVerificationError,
      hasConfirmationCode,
    ]
  )

  useEffect(
    () => {
      if (hasReceivedConfirmationCode || !hasConfirmationCode) return
      onVerificationSend()
      // Fetch the ticket manually: if refresh the page, the received email will be cleared
      dispatch(
        doFetchTicketSearch(
          'inbox:id',
          { mailbox: [id] },
          { mailbox: id },
          NEWEST
        )
      )
    },
    [
      hasReceivedConfirmationCode,
      id,
      dispatch,
      hasConfirmationCode,
      onVerificationSend,
    ]
  )

  useEffect(
    () => {
      const { skipTesting: skipTestingForFullstackTest } =
        storage.get('testForwardingVerificationOverride') || {}
      if (skipTestingForFullstackTest) {
        onVerificationSuccess()
      }
    },
    [onVerificationSuccess]
  )

  const animatedHeading = useMemo(
    () => (
      <>
        {heading}
        <AnimatedEllipsis />
      </>
    ),
    [heading]
  )

  const title = useMemo(
    () => {
      if (showReceivedConfirmationCodeMail) return 'You’ve got mail!'
      switch (testConnectionStatus) {
        case TEST_CONNECTION_STATUS_PENDING:
        case TEST_CONNECTION_STATUS_VERIFYING:
          return animatedHeading
        case TEST_CONNECTION_STATUS_VERIFIED:
          return 'Email received!'
        case TEST_CONNECTION_STATUS_ERROR:
        default:
          return 'No email received!'
      }
    },
    [testConnectionStatus, animatedHeading, showReceivedConfirmationCodeMail]
  )

  const defaultDescription = hasConfirmationCode
    ? `We’re waiting to receive the confirmation email from your email provider.`
    : `We’ve sent a test email to your account to see if it arrives in Groove as it should!`

  return (
    <Modal
      portal="#drawer-root"
      open
      className="grui pb-16"
      onClose={handleOnExit}
      backdropTransparency="light"
    >
      <ProgressDots
        count={creationStepCount}
        now={3}
        className="grui mt-4 mb-13"
      />
      <Modal.Title>{title}</Modal.Title>
      <Modal.Description>
        {showReceivedConfirmationCodeMail
          ? 'Follow the instructions provided by your email host to confirm forwarding and finish setup.'
          : defaultDescription}
      </Modal.Description>
      <div className="grui mt-18 mb-14">
        <img
          src={animatedMailbox}
          alt="Animated mailbox"
          width="240"
          height="240"
        />
      </div>
      {showReceivedConfirmationCodeMail && (
        <Modal.Button
          type="info"
          onClick={handleRebuildExitAndRedirect}
          className="grui mb-8"
        >
          Open mailbox
        </Modal.Button>
      )}
      <Modal.Button type="link" onClick={onTestConnectionError}>
        {showReceivedConfirmationCodeMail ? 'cancel' : 'End the test'}
      </Modal.Button>
    </Modal>
  )
}

export default ForwardingTest
