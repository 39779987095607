import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import Link from 'redux-first-router-link'

import { useLink } from 'ducks/location/hooks'

import Carousel from 'components/Carousel'
import Sidebar from 'shared/components/ui/Sidebar'
import { Header, Label } from 'shared/components/ui/Typography'
import { Notification } from 'shared/ui'
import themeVars from 'ui_theme/site/globals/site.variables'
import { Button } from 'util/ui'
import { usePlanLimitationForDrawer } from 'ducks/integrations/hooks/usePlanLimitationForDrawer'

import { SETTINGS_INTEGRATIONS_PROVIDER_PAGE } from '../../../../types'
import AuthorizeModal from '../AuthorizeModal'
import ToUpgradeButton from '../ToUpgradeButton'

export default styled(InstallModal)`
  .ModalHeader {
    margin-bottom: ${props => props.theme.spacing['12']};
  }
  .ProviderLogo {
    padding: 16px;
    background: white;
    border: 1px solid ${props => props.theme.color.monochrome.medium};
    float: left;
    margin-right: ${props => props.theme.spacing['11']};
    margin-bottom: ${props => props.theme.spacing['11']};
    img {
      height: 64px;
      width: 64px;
      border-radius: ${themeVars.absoluteBorderRadius};
    }
  }
  .ModalContent {
    padding: ${themeVars.massive} ${props => props.theme.spacing['12']};

    h2 {
      margin-top: 0;
    }
  }
  .InstallScreenShot {
    width: 100%;
  }
`

function InstallModal(props) {
  const {
    animateModal,
    className,
    error,
    InstallDescription,
    installPath = null,
    installWithSettings,
    logo,
    onClose,
    open,
    premium = false,
    provider,
    oauth_provider: oauthProvider = provider,
    providerStylized,
    screenShots,
    tagLine,
    showPlanLimitation,
  } = props
  usePlanLimitationForDrawer(onClose)

  const installWithSettingsLinkAction = useLink(
    {
      type: SETTINGS_INTEGRATIONS_PROVIDER_PAGE,
      payload: { provider, tab: 'auth' },
    },
    {
      preserveQuery: true,
    }
  )

  const btns = useMemo(
    () => {
      if (!showPlanLimitation || !premium) {
        if (!installWithSettings) {
          return (
            <AuthorizeModal
              fluid
              message={`Taking you to ${providerStylized}`}
              path={installPath || `/auth/${oauthProvider}`}
              params={{ return_to: window.location.href }}
              text="Install App"
              feature={premium && 'premium_integrations'}
            />
          )
        }
        if (installWithSettings) {
          return (
            <Button fluid primary as={Link} to={installWithSettingsLinkAction}>
              Install App
            </Button>
          )
        }
        return (
          <Button basic fluid onClick={onClose}>
            Cancel
          </Button>
        )
      }
      return null
    },
    [
      showPlanLimitation,
      installWithSettings,
      premium,
      installPath,
      onClose,
      installWithSettingsLinkAction,
      providerStylized,
      oauthProvider,
    ]
  )

  return (
    <Sidebar
      animateInitiallyOpen={animateModal}
      className={className}
      headerChildren="Install a new App"
      footerChildren={
        <React.Fragment>
          {btns}
          {showPlanLimitation && premium && <ToUpgradeButton />}
        </React.Fragment>
      }
      narrow
      onClose={onClose}
      open={open}
    >
      <div className="ModalContent">
        {error && (
          <Notification
            appear={false}
            fluid
            header="Failed to Authenticate"
            message={`We were unable to authenticate you with ${providerStylized}.`}
            type="negative"
          />
        )}
        <div className="ModalHeader">
          <div className="ProviderLogo">
            <img alt={`${providerStylized} logo`} src={logo} />
          </div>
          <Header as="h2" size="small">
            {providerStylized}
            {showPlanLimitation &&
              premium && <span className="grui ml-3">⚡</span>}
          </Header>
          <p>{tagLine}</p>
        </div>

        <Carousel width={392} height={244}>
          {Object.keys(screenShots).map(screenShotAlt => {
            return (
              <Carousel.Item key={screenShotAlt}>
                <img
                  className="InstallScreenShot"
                  alt={screenShotAlt}
                  src={screenShots[screenShotAlt]}
                />
              </Carousel.Item>
            )
          })}
        </Carousel>
        <Label size="medium" as="h4">
          How it works
        </Label>
        <InstallDescription {...props} />
      </div>
    </Sidebar>
  )
}
