// Default filters applied to articles fetch
// See doFetchCategories() action on supported filters
export const defaultFetchFilters = {
  currentPage: 1,
  perPage: 20,
  totalPages: 1,
  totalCount: 0,
  selectedState: 'all',
  keyword: '',
  sortByOrder: 'position asc', // recently updated
}
