import React, { useMemo } from 'react'
import Menu from '@groovehq/internal-design-system/lib/components/Menu/Menu'
import Tag from 'assets/icons/groove/v2/tag'
import Tags from 'components/App/DesktopView/Layout/Leftnav/Tags'
import { useSelector } from 'react-redux'
import { selectLabels } from 'selectors/labels'
import { selectTicketSearchTotalCountsByQueryId } from 'selectors/search'
import { sortByKeyTypeDesc } from 'util/arrays'
import { styles } from './styles'

const TagsMenuItem = props => {
  const {
    isTagsSectionOpen,
    toggleTagsSection,
    doOpenSearchPage,
    doStartSearchTimer,
    buildMailboxFolderPath,
    buildMailboxQueryString,
  } = props
  const labelsRaw = useSelector(selectLabels)
  const ticketCounts = useSelector(selectTicketSearchTotalCountsByQueryId)

  const labels = useMemo(
    () => {
      return labelsRaw
        .map(label => {
          const ticketCount =
            ticketCounts[
              buildMailboxQueryString({
                state: 'opened',
                label: label.id,
              })
            ]
          return { ...label, ticketCount }
        })
        .filter(label => label.ticketCount > 0)
        .sort(sortByKeyTypeDesc('ticketCount', 'number'))
        .map(label => {
          return {
            id: label.id,
            name: label.name,
            ticketCount: label.ticketCount,
          }
        })
    },
    [ticketCounts, buildMailboxQueryString, labelsRaw]
  )

  if (!labels || labels.length <= 0) return null

  return (
    <Menu.Item
      icon={<Tag css={styles.tagIcon} />}
      defaultOpen={isTagsSectionOpen}
      isSubMenuItem
      hasArrow
      onClick={toggleTagsSection}
      value="tag-section"
      subMenu={
        <Tags
          doOpenSearchPage={doOpenSearchPage}
          doStartSearchTimer={doStartSearchTimer}
          buildMailboxFolderPath={buildMailboxFolderPath}
          buildMailboxQueryString={buildMailboxQueryString}
          isSubMenuItem
          labels={labels}
        />
      }
    >
      Tags
    </Menu.Item>
  )
}

export default TagsMenuItem
