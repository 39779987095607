import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util'

const content = theme => css`
  width: ${rem(400)};

  .channel-details-field {
    max-width: 100%;
  }

  [role='presentation'] {
    width: 100%;

    button:focus {
      border-color: ${theme.color.primary.info};
    }
  }
`

const tableStickyHeader = css`
  & > * {
    max-height: 380px;

    thead tr {
      z-index: 1;
      position: sticky;
      box-shadow: 0 -8px 0 2px #fff;
      top: 8px;
    }
  }
`

export const styles = {
  content,
  tableStickyHeader,
}
