import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import Drawer from '@groovehq/internal-design-system/lib/components/Drawer/UnmanagedDrawer'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import { SETTINGS_BILLING_PAGE } from 'subapps/settings/types'
import StartContent from 'components/StartContent'
import { FEATURE_KB_MAX_KBS } from 'ducks/billing/featureTypes'
import { pluralize } from 'util/strings'
import { useFeature } from 'ducks/billing/hooks'
import { CREATE_PAGE } from 'subapps/kb/pages'

import { styles } from './styles'

const KBCreateMore = ({ onExit }) => {
  const dispatch = useDispatch()
  const { featureLimit, canUseFeature } = useFeature(FEATURE_KB_MAX_KBS)
  const kbLimitToWord = ['zero', 'one'][featureLimit] || featureLimit

  const onClick = useCallback(
    () => {
      dispatch({
        type: SETTINGS_BILLING_PAGE,
        payload: {
          tab: 'kb-plans',
        },
      })
    },
    [dispatch]
  )

  const footer = useMemo(
    () => (
      <ModalBtns
        saveBtnText="See plans"
        type="info"
        css={styles.btn}
        onSave={onClick}
      />
    ),
    [onClick]
  )

  useEffect(
    () => {
      if (canUseFeature) {
        onExit()
        dispatch({
          type: CREATE_PAGE,
        })
      }
    },
    [canUseFeature, onExit, dispatch]
  )

  return (
    <Drawer
      onClose={onExit}
      title="Create a Knowledge Base"
      open
      footer={footer}
    >
      <StartContent
        css={styles.content}
        className="grui pt-20"
        title="Need multiple Knowledge Bases?"
        subtitle={`Your account only allows you to create ${kbLimitToWord} ${pluralize(
          featureLimit,
          'Knowledge Base'
        )}. Upgrade now to create more.`}
      />
    </Drawer>
  )
}

export default KBCreateMore
