import Switch from '@groovehq/internal-design-system/lib/components/Switch/Switch'
import React, { useCallback } from 'react'
import { useController } from 'react-hook-form'
import { styles } from './styles'

export default function StateSwitch({ control }) {
  const {
    field: { onChange, value, ...switchProps },
  } = useController({
    name: 'state',
    control,
    defaultValue: 'ACTIVE',
  })

  const handleOnChange = useCallback(
    e => {
      onChange(e.target.checked ? 'ACTIVE' : 'INACTIVE')
    },
    [onChange]
  )

  return (
    <Switch
      id="folder-state"
      onChange={handleOnChange}
      value={value}
      defaultChecked={value === 'ACTIVE'}
      {...switchProps}
    >
      <span css={styles.stateSwitchText}>
        {app.t('Folder')} is {value.toLowerCase()}
      </span>
    </Switch>
  )
}
