import React from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone-all'
import { string, arrayOf, oneOf } from 'prop-types'
import {
  text,
  paragraph,
} from '@groovehq/internal-design-system/lib/styles/elements'
import { selectSearchByQueryId } from 'ducks/searches/selectors'
import useFetchEntitiesByIds from 'ducks/entities/hooks/useFetchEntitiesByIds'
import { doFetchExportsByIds } from 'ducks/dataExports/operations'
import { FETCH_DATA_EXPORTS } from 'ducks/dataExports/types'
import { selectAgentsByIdIncludingArchived } from 'selectors/agents/base'

const ExportCancelOrDeleteContent = ({ ids, queryId, deleteMode }) => {
  const { requestState, entities } = useFetchEntitiesByIds(
    ids,
    'dataExport',
    doFetchExportsByIds,
    FETCH_DATA_EXPORTS
  )
  const entitiesArr = Object.values(entities)
  const agents = useSelector(selectAgentsByIdIncludingArchived)

  const search = useSelector(state => selectSearchByQueryId(state, queryId))
  const { totalCount: searchTotal } = search

  const isIdDelete = deleteMode === 'ids'

  if (entitiesArr.length === 0 && requestState.loading) {
    return <span css={text.styles.fontMedium}>Loading...</span>
  }

  if (entitiesArr.length === 0) {
    return <span css={text.styles.fontMedium}>No results...</span>
  }

  if (isIdDelete && ids.length === 1) {
    const entity = entitiesArr[0]
    return (
      <>
        <p css={[paragraph.styles.preflight, text.styles.textNormal]}>
          You are about to {entity.state === 'completed' ? 'delete' : 'cancel'}{' '}
          the export requested by{' '}
          {agents[entity.requestedById]?.name || 'Unknown'} on{' '}
          {moment(entity.createdAt).format('DD MMM YYYY [at] HH:mm')}.
        </p>
      </>
    )
  }
  const totalToBeRemoved = isIdDelete ? ids.length : searchTotal
  const additional = totalToBeRemoved - entitiesArr.length

  return (
    <div css={text.styles.textNormal}>
      You are about to delete:
      {entitiesArr.map(row => (
        <p key={row.id} css={paragraph.styles.preflight}>
          <span css={text.styles.fontMedium}>
            Export requested by {agents[row.requestedById]?.name || 'Unknown'}{' '}
            {moment(row.createdAt).format('DD MMM YYYY [at] HH:mm')}.
          </span>
        </p>
      ))}
      {additional > 0 && <p>and {additional} more items.</p>}
    </div>
  )
}

ExportCancelOrDeleteContent.propTypes = {
  deleteMode: oneOf(['ids', 'query']).isRequired,
  ids: arrayOf(string).isRequired,
  queryId: string,
}

ExportCancelOrDeleteContent.defaultProps = {
  queryId: null,
}

export default ExportCancelOrDeleteContent
