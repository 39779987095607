import {
  isMessage,
  isNote,
  isForward,
  isAgentResponse,
  isSnooze,
  isUnsnooze,
} from 'util/changesets'
import { isEmpty, reverseFindIndex } from 'util/arrays'

export const groupActionsByMessage = actions => {
  const messageActions = actions.filter(
    action => isMessage(action) && !isNote(action)
  )
  const messageActionIndexes = messageActions.map(action =>
    actions.indexOf(action)
  )
  const actionsGroupedByMessage = messageActionIndexes.map(
    (messageActionIndex, index) => {
      const nextActionIndex = messageActionIndexes[index + 1]

      return actions.slice(messageActionIndex, nextActionIndex)
    }
  )

  return actionsGroupedByMessage
}

export const getLatestCustomerReply = actions => {
  if (!actions || isEmpty(actions)) return undefined

  const latestReplyIndex = reverseFindIndex(actions, r => {
    return isMessage(r) && !isForward(r) && !isAgentResponse(r.change)
  })

  if (latestReplyIndex === -1) return undefined

  return actions[latestReplyIndex]
}

const isSnoozeRelated = action =>
  action && (isSnooze(action) || isUnsnooze(action))

const isAutomaticFromCustomer = action => {
  return (
    action.actor &&
    action.actor.type !== 'Rule' &&
    action.actor.type !== 'Integration' &&
    action.actor.type !== 'Agent'
  )
}

const isFirstChangeset = changeset => changeset.index === 1

const isFollowingASnoozeAction = (actions, index) => {
  const possibleSnoozePositions = [index - 1, index - 2]
  if (actions.length <= index) return false
  return possibleSnoozePositions
    .map(idx => actions[idx])
    .reduce(
      (isThereAnySnooze, possibleSnooze) =>
        isSnoozeRelated(possibleSnooze) || isThereAnySnooze,
      false
    )
}

const isFollowingADeleteAction = (actions, index) => {
  if (!actions[index - 1]) return false
  return actions[index - 1].change_type === 'Deleted'
}

export const changeStateShouldBeShown = (changeset, actionIndex) => {
  if (isFirstChangeset(changeset)) return false
  if (!changeset.actions) return false
  if (isFollowingASnoozeAction(changeset.actions, actionIndex)) return false
  if (isFollowingADeleteAction(changeset.actions, actionIndex)) return false
  if (isAutomaticFromCustomer(changeset.actions[actionIndex])) return false
  return true
}
