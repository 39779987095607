import React, { useCallback } from 'react'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import Drawer from '@groovehq/internal-design-system/lib/components/Drawer/UnmanagedDrawer'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import Header from '@groovehq/internal-design-system/lib/components/Header/Header'
import { DELETE_TAGS, MERGE_TAGS } from 'ducks/batchJobs/jobTypes'
import { useDrawer } from 'ducks/drawers/hooks'
import { DRAWER_TYPE_TAGS_UPDATE } from 'ducks/drawers/types'
import BatchJobsAppBarList from 'subapps/settings/components/BatchJobsAppBar/list'
import TagDataTable from 'subapps/settings/components/TagDataTable'
import { styles } from './styles'

const JOB_TYPES_TAGS = [DELETE_TAGS, MERGE_TAGS]

const TagOverview = ({ open, onClose, onExit }) => {
  const { openDrawer: openTagCreateDrawer } = useDrawer({
    type: DRAWER_TYPE_TAGS_UPDATE,
  })

  const onCreateTagClick = useCallback(
    () => {
      openTagCreateDrawer('new')
    },
    [openTagCreateDrawer]
  )

  return (
    <Drawer
      title="Tags"
      open={open}
      onClose={onExit}
      size="wideXL"
      footer={
        <ModalBtns tertiaryBtnText="Close" onClickTertiaryBtn={onClose} />
      }
    >
      <Header compact>
        <div className="grui justify-end">
          <Button size="small" onClick={onCreateTagClick}>
            Create tag
          </Button>
        </div>
      </Header>
      <BatchJobsAppBarList
        jobTypes={JOB_TYPES_TAGS}
        css={styles.appBarList}
        roundBorder
      />
      <div className="grui flex-grow pt-7">
        <TagDataTable compact />
      </div>
    </Drawer>
  )
}

export default TagOverview
