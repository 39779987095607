import { Checkbox } from 'util/ui'

import { SettingRow, Tabs } from '../../shared/SettingsModal'

export default function SidebarTab({ active, formFields, onChange }) {
  return (
    <Tabs.Content active={active}>
      <p>
        Choose which fields you want to display in the sidebar of all your
        linked Trello cards.
      </p>
      <SettingRow>
        <Checkbox
          checked={formFields['showSidebarField.list']}
          name="showSidebarField.list"
          onChange={onChange}
        />{' '}
        <span>List</span>
      </SettingRow>
      <SettingRow>
        <Checkbox
          checked={formFields['showSidebarField.board']}
          name="showSidebarField.board"
          onChange={onChange}
        />{' '}
        <span>Board</span>
      </SettingRow>
      <SettingRow>
        <Checkbox
          checked={formFields['showSidebarField.status']}
          name="showSidebarField.status"
          onChange={onChange}
        />{' '}
        <span>Status (open/closed)</span>
      </SettingRow>
      <SettingRow>
        <Checkbox
          checked={formFields['showSidebarField.members']}
          name="showSidebarField.members"
          onChange={onChange}
        />{' '}
        <span>Members</span>
      </SettingRow>
      <SettingRow>
        <Checkbox
          checked={formFields['showSidebarField.labels']}
          name="showSidebarField.labels"
          onChange={onChange}
        />{' '}
        <span>Labels</span>
      </SettingRow>
      <SettingRow>
        <Checkbox
          checked={formFields['showSidebarField.created']}
          name="showSidebarField.created"
          onChange={onChange}
        />{' '}
        <span>Created at</span>
      </SettingRow>
      <SettingRow>
        <Checkbox
          checked={formFields['showSidebarField.lastUpdated']}
          name="showSidebarField.lastUpdated"
          onChange={onChange}
        />{' '}
        <span>Last Updated</span>
      </SettingRow>
      <SettingRow>
        <Checkbox
          checked={formFields['showSidebarField.dueDate']}
          name="showSidebarField.dueDate"
          onChange={onChange}
        />{' '}
        <span>Due Date</span>
      </SettingRow>
    </Tabs.Content>
  )
}
