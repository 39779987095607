import React from 'react'
import { Accordion } from 'util/ui'

export default class Section extends React.PureComponent {
  onTitleClick() {
    this.parentOnTitleClick(this.sectionName)
  }

  render() {
    const { title, onTitleClick, active, children, name } = this.props
    return (
      <React.Fragment>
        <Accordion.Title
          active={active}
          content={title}
          onClick={this.onTitleClick}
          parentOnTitleClick={onTitleClick}
          sectionName={name}
        />
        <Accordion.Content active={active}>{children}</Accordion.Content>
      </React.Fragment>
    )
  }
}
