import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useImperativeHandle,
  useRef,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { string, bool, oneOfType, number, oneOf, func } from 'prop-types'
import { useFormContext, useController } from 'react-hook-form'
import Editor from '@groovehq/internal-design-system/lib/components/Editor/Editor'
import Field from '@groovehq/internal-design-system/lib/components/Field/Field'
import FieldWithVariables from '@groovehq/internal-design-system/lib/components/FieldWithVariables/FieldWithVariables'
import { styles as fieldStyles } from '@groovehq/internal-design-system/lib/components/Field/Field.styles'
import { rem } from '@groovehq/internal-design-system/lib/util'

import { selectCurrentAgentsById } from 'selectors/agents/base'
import { doSaveCannedReplyTemplate } from 'ducks/cannedReplies/operations'
import {
  doMessageTemplateAddPendingAttachment,
  doMessageTemplateRemovePendingAttachment,
} from 'ducks/messageTemplates/operations'
import {
  selectCurrentAttachmentsById,
  selectPendingAttachmentsById,
  selectPendingMessageTemplateBodyById,
  selectPendingMessageTemplateSubjectById,
  selectPendingMessageTemplateAttachmentIdsById,
} from 'ducks/messageTemplates/selectors'
import { EDITOR_MAX_INLINE_IMAGE_SIZE, EDITOR_MAX_UPLOAD_SIZE } from 'util/file'
import { buildId } from 'util/globalId'

import { fullWidth, hidden, copy } from './styles'

const MessageTemplateEditor = React.forwardRef(
  (
    {
      id,
      type,
      entityType,
      open,
      mode,
      queryId,
      minHeight,
      maxHeight,
      height,
      showPluginVariablesPlaceholder,
      pluginVariablesPlaceholderTypes,
      subjectVariableTypes,
      showAddAttachment,
      showPluginImageUpload,
      showPluginSourceEditing,
      formInputSubjectName,
      formInputBodyName,
      formInputSubjectPlaceholder,
      formInputSubjectLabel,
      formInputSubjectTooltip,
      formInputBodyLabel,
      formInputBodySubtitle,
      formInputBodyPlaceholder,
      isFormInputSubjectHidden,
      isFormInputSubjectWithVariablesHidden,
      isFormInputBodyHidden,
      isAiGenerated,
      isAiError,
      onAiDone,
      onAiCancel,
      onAiRetry,
      showAiSpinner,
      formInputAttachmentIdsName,
      formInputPendingUploadsName,
      doFetchTemplate,
      shouldResetFieldOnReady,
      disabled,
      onMessageChange,
      autoFocus,
    },
    forwardedRef
  ) => {
    const dispatch = useDispatch()
    // const isUpdate = !!id && id !== 'new'
    const [attachmentErrors, saveAttachmentErrors] = useState([])
    const bodyRef = useRef()

    useEffect(
      () => {
        // need to check drawer open state before fetch dispatches
        // otherwise the dispatch methods in useEffect below will run immediately
        // after this Drawer component is instantiated via `useDrawer` (before openDrawer is even called)
        // Which is unnecessary double dispatch triggering
        if (!open) return

        if (mode === 'unmanaged') {
          // save the response from the API in 'pending' store, not 'current' (because it'll immediately be used in this edit screen)
          dispatch(
            doFetchTemplate(buildId(type, id), { targetStore: 'pending' })
          )
        }
      },
      [dispatch, doFetchTemplate, id, mode, open, type]
    )

    const templateSubject = useSelector(state =>
      selectPendingMessageTemplateSubjectById(state, entityType, id)
    )

    const templateBody = useSelector(state =>
      selectPendingMessageTemplateBodyById(state, entityType, id)
    )

    const templateAttachmentIds = useSelector(state =>
      selectPendingMessageTemplateAttachmentIdsById(state, entityType, id)
    )

    const {
      register,
      formState: { errors },
      control,
      getValues,
      setValue,
      resetField,
    } = useFormContext()

    // We're using useController here for controlled components
    // https://react-hook-form.com/v6/api/#Controller
    // https://react-hook-form.com/get-started#IntegratingControlledInputs
    const {
      field: { onChange: onBodyChange, onBlur: onBodyBlur, value: bodyValue },
    } = useController({ name: formInputBodyName, control })

    const {
      field: { onChange: onSubjectChange, value: subjectValue },
    } = useController({ name: formInputSubjectName, control })

    // not actually hooked up to any control
    // we use a controller for saving/removing attachments so that react-hook-forms can set isDirty.
    // This way, if all was done was upload/remove an attachment, it will update isDirty automagically and allow us to save
    // it also stores the array of attachments
    const {
      field: { onChange: onAttachmentsChange },
    } = useController({ name: formInputAttachmentIdsName, control })

    const onEditorDataChange = useCallback(
      (event, editor) => {
        if (editor && editor.getData) {
          const data = editor.getData()
          onBodyChange(data)
          if (onMessageChange) {
            onMessageChange(data)
          }
        }
      },
      [onBodyChange, onMessageChange]
    )

    const handleEditorReady = useCallback(
      editor => {
        if (autoFocus) {
          editor.focus()
          if (bodyRef.current?.scrollIntoView) {
            bodyRef.current.scrollIntoView()
          }
        }
        if (!shouldResetFieldOnReady || !editor || !editor.getData()) return
        resetField(formInputBodyName, {
          defaultValue: editor.getData(),
        })
      },
      [resetField, formInputBodyName, shouldResetFieldOnReady, autoFocus]
    )

    const agentsById = useSelector(selectCurrentAgentsById)
    const currentAttachmentsById = useSelector(selectCurrentAttachmentsById)
    const pendingAttachmentsById = useSelector(selectPendingAttachmentsById)

    const attachments = useMemo(
      () => {
        if (!templateAttachmentIds || !Array.isArray(templateAttachmentIds))
          return []

        const pendingAttachments = templateAttachmentIds
          .map(attachmentId => {
            const attachment =
              pendingAttachmentsById[attachmentId] ||
              currentAttachmentsById[attachmentId]
            if (!attachment) return null
            return {
              id: attachment.id,
              name: attachment.attachment_file_name || attachment.file_name,
              size: attachment.attachment_file_size || attachment.file_size,
              added: attachment.created_at,
              url: attachment.token_url || attachment.url,
              uploader: {
                firstName:
                  agentsById[attachment.creator || attachment.creator_id]?.name,
              },
            }
          })
          .filter(a => !!a)

        return [...pendingAttachments, ...attachmentErrors]
      },
      [
        templateAttachmentIds,
        agentsById,
        currentAttachmentsById,
        pendingAttachmentsById,
        attachmentErrors,
      ]
    )

    const onUploadFile = useCallback(
      (attachmentId, editorFile) => {
        // add new pending upload to formState
        const pendingUploads = [
          ...(getValues(formInputPendingUploadsName) || []),
          { attachmentId, editorFile },
        ]

        setValue(formInputPendingUploadsName, pendingUploads)
        dispatch(
          doMessageTemplateAddPendingAttachment({
            id,
            entityType,
            attachmentId,
            editorFile,
          })
        )
      },
      [
        dispatch,
        id,
        entityType,
        setValue,
        getValues,
        formInputPendingUploadsName,
      ]
    )

    const onSetAttachments = useCallback(
      getEditorAttachments => {
        const editorAttachments = getEditorAttachments(attachments)
        const errorAttachments = editorAttachments.filter(a => !!a.error)
        saveAttachmentErrors(errorAttachments)
        // also update the attachments in the form state
        onAttachmentsChange(editorAttachments.map(a => a.id))
      },
      [attachments, onAttachmentsChange]
    )

    const onAttachmentRemove = useCallback(
      attachmentId => {
        dispatch(
          doMessageTemplateRemovePendingAttachment({
            id,
            entityType,
            attachmentId,
          })
        )

        // remove pending upload from form state and update state with new value
        if (getValues(formInputPendingUploadsName)) {
          const pendingUploads = getValues(formInputPendingUploadsName).filter(
            upload => upload.attachmentId !== attachmentId
          )
          setValue(formInputPendingUploadsName, pendingUploads)
        }

        // also update the attachments in the form state
        onAttachmentsChange(
          attachments.filter(att => att.id !== attachmentId).map(a => a.id)
        )

        saveAttachmentErrors(
          attachmentErrors.filter(ae => ae.id !== attachmentId)
        )
      },
      [
        dispatch,
        id,
        attachmentErrors,
        entityType,
        setValue,
        getValues,
        formInputPendingUploadsName,
        onAttachmentsChange,
        attachments,
      ]
    )

    useEffect(
      () => {
        if (templateSubject) {
          setValue(formInputSubjectName, templateSubject)
        }
      },
      [formInputSubjectName, templateSubject, setValue]
    )

    useEffect(
      () => {
        if (templateBody) {
          setValue(formInputBodyName, templateBody)
        }
      },
      [formInputBodyName, templateBody, setValue]
    )

    const saveTemplate = useCallback(
      async () => {
        const {
          id: templateId,
          name,
          subject,
          body,
          category,
          attachments: templateAttachments,
          formPendingUploads: uploadAttachments,
        } = getValues()

        const payload = {
          id: templateId,
          name,
          subject,
          body,
          category,
          attachments: templateAttachments,
        }

        // no generic way to save template,
        // guess we can use an if statement for now
        if (type === 'CannedReply') {
          return dispatch(
            doSaveCannedReplyTemplate(id, payload, {
              uploadAttachments,
              queryId,
            })
          )
        }
        // FIXME: when API endpoints are available to save rule message templates
        // if (type === 'Rule' && isUpdate)
        // if (type === 'Rule' && !isUpdate)

        const msg = `FIXME MessageTemplateEditor: ${type} saveTemplate method not implemented`
        throw new Error(msg)
      },
      [dispatch, getValues, id, queryId, type]
    )

    useImperativeHandle(forwardedRef, () => ({ saveTemplate }), [saveTemplate])

    return (
      <>
        <div css={isFormInputSubjectHidden && hidden}>
          {formInputSubjectLabel && (
            <div css={fieldStyles.labelBox}>{formInputSubjectLabel}</div>
          )}
          {formInputBodySubtitle && (
            <div css={copy}>{formInputBodySubtitle}</div>
          )}
          <Field
            {...register(formInputSubjectName)}
            name={formInputSubjectName}
            placeholder={formInputSubjectPlaceholder}
            tooltipTitle={formInputSubjectTooltip}
            tooltipPosition="right"
            css={fullWidth}
            disabled={showAiSpinner}
            validateStatus={
              errors?.[formInputSubjectName] ? 'error' : undefined
            }
            help={errors?.[formInputSubjectName]?.message}
          />
        </div>
        {!isFormInputSubjectWithVariablesHidden && (
          <>
            <FieldWithVariables
              value={subjectValue}
              onChange={onSubjectChange}
              className="grui mt-8"
              fieldContainerClassName="grui mw-100"
              placeholder={formInputSubjectPlaceholder}
              tooltipTitle={formInputSubjectTooltip}
              tooltipPosition="right"
              css={fullWidth}
              validateStatus={
                errors?.[formInputSubjectName] ? 'error' : undefined
              }
              help={errors?.[formInputSubjectName]?.message}
              label={formInputSubjectLabel}
              variableList={subjectVariableTypes}
            />
          </>
        )}
        <div
          className="grui mt-8 message-template-body"
          css={isFormInputBodyHidden && hidden}
          ref={bodyRef}
        >
          {formInputBodyLabel && (
            <div css={fieldStyles.labelBox}>{formInputBodyLabel}</div>
          )}
          <Editor
            showPluginImageUpload={showPluginImageUpload}
            showPluginVariablesPlaceholder={showPluginVariablesPlaceholder}
            pluginVariablesPlaceholderTypes={pluginVariablesPlaceholderTypes}
            showPluginSourceEditing={showPluginSourceEditing}
            validateStatus={errors?.[formInputBodyName] ? 'error' : undefined}
            help={errors?.[formInputBodyName]?.message}
            onReady={handleEditorReady}
            onBlur={onBodyBlur}
            onEditorDataChange={onEditorDataChange}
            editorData={bodyValue}
            showAddAttachment={showAddAttachment}
            attachments={attachments}
            onUploadFile={onUploadFile}
            onAttachmentRemove={onAttachmentRemove}
            setAttachments={onSetAttachments}
            maxUploadSize={EDITOR_MAX_UPLOAD_SIZE}
            maxInlineImageSize={EDITOR_MAX_INLINE_IMAGE_SIZE}
            minHeight={minHeight}
            maxHeight={maxHeight}
            height={height}
            placeholder={formInputBodyPlaceholder}
            showAiGenerated={isAiGenerated}
            showAiSpinner={showAiSpinner}
            showAiError={isAiError}
            onAiDone={onAiDone}
            onAiCancel={onAiCancel}
            onAiRetry={onAiRetry}
            disabled={disabled}
          />
        </div>
      </>
    )
  }
)

MessageTemplateEditor.propTypes = {
  minHeight: oneOfType([string, number]),
  maxHeight: oneOfType([string, number]),
  type: string.isRequired,
  entityType: string.isRequired,
  mode: oneOf(['unmanaged,', 'managed']),
  id: string,
  formInputSubjectName: string,
  formInputBodyName: string,
  formInputBodySubtitle: string,
  formInputAttachmentIdsName: string,
  formInputNameLabel: string,
  formInputNamePlaceholder: string,
  formInputSubjectLabel: string,
  formInputSubjectTooltip: string,
  isFormInputSubjectHidden: bool,
  isFormInputSubjectWithVariablesHidden: bool,
  isFormInputBodyHidden: bool,
  shouldResetFieldOnReady: bool,
  autoFocus: bool,
  onMessageChange: func,
}

MessageTemplateEditor.defaultProps = {
  minHeight: rem(100),
  maxHeight: rem(210),
  hasLoadedVariables: false,
  id: undefined,
  mode: 'managed',
  formInputSubjectName: 'subject',
  formInputBodyName: 'body',
  formInputAttachmentIdsName: 'attachments',
  formInputBodyLabel: 'Body',
  formInputBodySubtitle: undefined,
  formInputBodyPlaceholder: undefined,
  formInputSubjectLabel: 'Subject',
  formInputSubjectPlaceholder: 'Enter subject...',
  formInputSubjectTooltip: undefined,
  isFormInputSubjectHidden: false,
  isFormInputSubjectWithVariablesHidden: false,
  isFormInputBodyHidden: false,
  formInputPendingUploadsName: 'formPendingUploads',
  shouldResetFieldOnReady: false,
  autoFocus: false,
  onMessageChange: undefined,
}

export default MessageTemplateEditor
