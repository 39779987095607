import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util'

const wrapper = theme => css`
  grid-auto-flow: row;
  grid-template-columns: 70% auto;
  background-color: ${theme.color.monochrome.white};
  min-height: ${rem(48)};
  width: 100%;
  border-radius: ${rem(6)};
  padding: ${rem(8)} ${rem(12)} ${rem(8)} ${rem(16)};
  display: flex;
  align-items: center;
  border: 1px solid ${theme.color.monochrome.medium};
  box-shadow: 0 ${rem(2)} ${rem(2)} 0
    rgba(${theme.color.monochrome.black_rgba}, 0.05);
  transition: border-color 0.2s ease-in-out;
`

const clickableWrapper = theme => css`
  cursor: pointer;

  &:hover {
    border-color: ${theme.color.primary.brand};
  }
`

const rightSide = css`
  min-height: ${rem(70)};
`

const bottomContent = theme => css`
  font-size: ${theme.fontSize.small};
  color: ${theme.color.monochrome.dark};
`

const bottomNote = theme => css`
  color: ${theme.color.primary.warningText};
`

const note = theme => css`
  margin-left: ${rem(3)};
  color: ${theme.color.monochrome.black};
  background-color: ${theme.color.secondary.warning};
`

const customer = theme => css`
  font-size: ${theme.fontSize.large};
  font-weight: ${theme.fontWeight.medium};
`

const arrow = css`
  top: 1px;
  width: ${rem(20)};
  height: ${rem(20)};
  position: relative;
  transform: rotate(180deg);
`

const date = theme => css`
  font-size: ${theme.fontSize.xsmall};
  color: ${theme.color.monochrome.mediumDark};
`

const messageCount = theme => css`
  min-width: ${rem(23)};
  font-size: ${theme.fontSize.tiny};
  font-weight: ${theme.fontWeight.medium};
  color: ${theme.color.monochrome.mediumDark};
  background-color: ${theme.color.monochrome.light};
  border-radius: 100%;
`

const ticketNumber = theme => css`
  color: ${theme.color.monochrome.mediumDark};
`

const deleteButton = theme => css`
  top: ${rem(-12)};
  right: ${rem(-12)};
  width: ${rem(24)};
  height: ${rem(24)};
  position: absolute;
  background-color: ${theme.color.monochrome.white};
  border: 1px solid ${theme.color.monochrome.light};
  border-radius: ${rem(5)};

  svg {
    opacity: 0.2;
    color: ${theme.color.info['900']};
  }
`

export const styles = {
  wrapper,
  clickableWrapper,
  rightSide,
  bottomContent,
  bottomNote,
  note,
  customer,
  arrow,
  date,
  messageCount,
  ticketNumber,
  deleteButton,
}
