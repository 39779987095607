import { percentile } from 'util/arrays'

function createFpsCapturer() {
  let sum = 0
  let count = 0
  let stop = false
  let previousTime
  let t
  const results = []
  const tick = () => {
    if (stop) return
    t = performance.now()
    const elapsed = t - previousTime
    previousTime = t
    if (count < 120) {
      sum += elapsed
      count += 1
      requestAnimationFrame(tick)
    } else {
      const average = sum / count
      const fps = Math.round(1000 / average)
      results.push(fps)
      sum = elapsed
      count = 1
      requestAnimationFrame(tick)
    }
  }
  return {
    getResults: () => {
      return results
    },
    getAverage: () => {
      return Math.round(results.reduce((a, b) => a + b, 0) / results.length)
    },
    getMedian: () => {
      return percentile(50, results)
    },
    getPercentile: p => {
      return percentile(100 - p, results)
    },
    stop: () => {
      stop = true
    },
    start: () => {
      t = performance.now()
      previousTime = t
      requestAnimationFrame(tick)
    },
  }
}
export default createFpsCapturer
