import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'

const dropdownBtn = css`
  max-width: ${rem(326)};
  height: 3rem;
`

const checkboxList = css`
  margin-top: ${rem(16)};
`

const note = css`
  margin: 0;
  font-style: italic;
`

export const styles = {
  dropdownBtn,
  checkboxList,
  note,
}
