import { SETTINGS_BILLING_PAGE } from 'subapps/settings/types'
import { selectCurrentPage, selectCurrentPayload } from 'selectors/location'
import { redirect } from 'redux-first-router'
import { isPageAllowedForExpiredBilling } from 'ducks/billing/util'

export default function doRedirectToBilling(options = {}) {
  return (dispatch, getState) => {
    const {
      withRedirect = false,
      ignoreAllowed = false,
      query = {},
      payload = {},
    } = options
    const state = getState()
    const currentPageType = selectCurrentPage(state)
    const currentPayload = selectCurrentPayload(state)
    if (
      !isPageAllowedForExpiredBilling({
        type: currentPageType,
        payload: currentPayload,
      }) ||
      ignoreAllowed
    ) {
      const action = {
        type: SETTINGS_BILLING_PAGE,
        meta: {
          query,
        },
        payload,
      }
      return dispatch(withRedirect ? redirect(action) : action)
    }
    return null
  }
}
