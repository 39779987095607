import { selectCurrentPage } from 'selectors/location'
import { createSelector } from 'reselect'
import {
  SETTINGS_BILLING_PAGE,
  SETTINGS_CHANNELS_PAGE,
  SETTINGS_INBOX_MANAGEMENT_PAGE,
} from 'subapps/settings/types'

export const selectIsBillingPage = createSelector(
  selectCurrentPage,
  currentPage => {
    return currentPage === SETTINGS_BILLING_PAGE
  }
)

export const selectIsChannelsPage = createSelector(
  selectCurrentPage,
  currentPage => {
    return currentPage === SETTINGS_CHANNELS_PAGE
  }
)

export const selectIsInboxManagementPage = createSelector(
  selectCurrentPage,
  currentPage => {
    return currentPage === SETTINGS_INBOX_MANAGEMENT_PAGE
  }
)
