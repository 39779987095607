import React, { useMemo, useCallback, useEffect } from 'react'
import { useController, useFormState } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { doFetchTranslations } from 'ducks/translations/operations'
import {
  selectDefaultTranslations,
  selectUserTranslations,
} from 'ducks/translations/selectors'
import { deepCopy } from 'util/objects'
import { propFunc } from 'util/functions'
import { getRawId } from 'util/globalId'
import {
  button,
  text,
} from '@groovehq/internal-design-system/lib/styles/elements'

import dot from 'dot-object'
import Accordion from '@groovehq/internal-design-system/lib/components/Accordion/Accordion'
import Field from '@groovehq/internal-design-system/lib/components/Field/Field'
import Dropdown from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import DropdownMenu from 'subapps/settings/components/DropdownMenu'
import { styles as fieldStyles } from '@groovehq/internal-design-system/lib/components/Field/Field.styles'

import { styles } from './styles'
import { emailInputsData, formInputsData, languagesData } from './data'

const CustomizeWording = ({ control, register, setValue, settingId }) => {
  const {
    field: { value: localeId, onChange: onChangeLocale },
  } = useController({
    name: 'locale',
    control,
  })

  const { errors } = useFormState({ control })

  const dispatch = useDispatch()
  const defaultTranslationsFromStore = useSelector(selectDefaultTranslations)
  const defaultTranslationsFlat = useMemo(
    () => {
      if (!defaultTranslationsFromStore) {
        return {}
      }
      return dot.dot({
        translations: deepCopy(defaultTranslationsFromStore),
      })
    },
    [defaultTranslationsFromStore]
  )

  const selectedLanguage = useMemo(
    () => {
      return languagesData.find(l => l.id === localeId)?.name
    },
    [localeId]
  )

  useEffect(
    () => {
      const id = getRawId(settingId) || 'new'

      dispatch(
        doFetchTranslations('customer_rating_setting', id, localeId, true)
      )
    },
    [dispatch, settingId, localeId]
  )

  const userTranslations = useSelector(selectUserTranslations)

  useEffect(
    () => {
      const newValues = dot.dot(deepCopy(userTranslations))
      Object.keys(newValues).forEach(key => {
        setValue(`translations.${key}`, newValues[key], {
          shouldDirty: false,
          shouldValidate: true,
        })
      })
    },
    [userTranslations, setValue]
  )

  const handleChangeLang = useCallback(
    value => {
      onChangeLocale(value)
    },
    [onChangeLocale]
  )

  // Reset value to be placeholder
  const handleReset = useCallback(
    name => {
      const defaultValue = defaultTranslationsFlat[name]
      setValue(name, defaultValue, {
        shouldDirty: true,
        shouldValidate: true,
      })
    },
    [setValue, defaultTranslationsFlat]
  )

  const creatResetBtn = useCallback(
    name => (
      <button
        type="button"
        className="grui"
        css={[button.styles.preflight, styles.resetBtn]}
        onClick={propFunc(handleReset, name)}
      >
        Reset
      </button>
    ),
    [handleReset]
  )

  const emailRatingsTitle = (
    <div css={text.styles.textXs}>
      Rating wording displayed in email
      <div css={text.styles.fontNormal} className="grui mt-3">
        Change the wording displayed at the footer of your email asking for the
        rating.{' '}
        <a
          className="grui underline"
          css={button.styles.link}
          href="https://help.groovehq.com/help/satisfaction-rating-wording-customization#email"
          target="_blank"
          rel="noopener noreferrer"
        >
          View example
        </a>.
      </div>
    </div>
  )

  const formRatingsTitle = (
    <div css={text.styles.textXs}>
      Web capture form wording
      <div css={text.styles.fontNormal} className="grui mt-3">
        When users click on a rating, they are directed to a page for additional
        comments.{` `}
        <a
          className="grui underline"
          css={button.styles.link}
          href="https://help.groovehq.com/help/satisfaction-rating-wording-customization#ratings-page"
          target="_blank"
          rel="noopener noreferrer"
        >
          View example
        </a>.
      </div>
    </div>
  )

  return (
    <>
      <div className="grui mb-12">
        <div css={fieldStyles.labelBox} className="grui mb-7 block">
          Wording
          <div className="grui mt-2" css={text.styles.fontNormal}>
            Easily configure the wording that is shown in the email and the web
            capture form to match your locale and company tone of voice.
          </div>
        </div>
        <Dropdown
          overlay={<DropdownMenu data={languagesData} />}
          onSelect={handleChangeLang}
          selectedKey={localeId}
        >
          <Dropdown.Button css={styles.dropdownBtn}>
            {selectedLanguage}
          </Dropdown.Button>
        </Dropdown>
      </div>
      <Accordion css={styles.accordionPanel}>
        <Accordion.Panel panelKey="1" title={emailRatingsTitle}>
          <div css={styles.fields} className="grui px-12 pb-12">
            {/* Loop through names and create field */}
            {Object.keys(emailInputsData).map(name => {
              return (
                <Field
                  tagContent={creatResetBtn(name)}
                  tagPosition="right"
                  autoComplete="off"
                  key={name}
                  placeholder={defaultTranslationsFlat[name]}
                  {...emailInputsData[name].props}
                  {...register(name)}
                  validateStatus={dot.pick(name, errors) ? 'error' : undefined}
                  help={dot.pick(name, errors)?.message}
                />
              )
            })}
          </div>
        </Accordion.Panel>
        <Accordion.Panel
          panelKey="2"
          title={formRatingsTitle}
          className="grui mt-12"
        >
          <div css={styles.fields} className="grui px-12 pb-12">
            {/* Loop through names and create field */}
            {Object.keys(formInputsData).map(name => {
              return (
                <Field
                  tagContent={creatResetBtn(name)}
                  tagPosition="right"
                  autoComplete="off"
                  key={name}
                  placeholder={defaultTranslationsFlat[name]}
                  {...formInputsData[name].props}
                  {...register(name)}
                />
              )
            })}
          </div>
        </Accordion.Panel>
      </Accordion>
    </>
  )
}

export default CustomizeWording
