import React from 'react'
import cn from 'classnames'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import { heading } from '@groovehq/internal-design-system/lib/styles/elements'
import useOpenContact from 'util/hooks/useOpenContact'
import { styles } from './styles'

const ContactCard = ({ className }) => {
  const openContact = useOpenContact({ left: true })
  return (
    <div className={cn('grui mt-auto pt-12', className)}>
      <div css={styles.contactCard} className={'grui pt-7 pb-10 px-7'}>
        <div className={cn('grui mr-auto')}>
          <h5 css={heading.styles.h5} className="grui mb-5">
            Need a hand?
          </h5>
          Discuss your support goals with our team, and we’ll help you find the{` `}
          perfect plan.
        </div>
        <Button
          css={styles.contactBtn}
          onClick={openContact}
          type="info"
          size="small"
          className="grui mt-10"
        >
          Contact support
        </Button>
      </div>
    </div>
  )
}

export default ContactCard
