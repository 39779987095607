export default function startIntersectionObserver() {
  const callbacks = {}
  let uniqueId = 0

  const runCallback = change => {
    const callback = callbacks[change.target.id]
    if (callback) callback(change)
  }

  const observer = new IntersectionObserver(
    changes => {
      changes.forEach(runCallback)
    },
    {
      // fire events on each quarter change
      threshold: [0, 0.25, 0.5, 0.75, 1],
      // account for height of top nav
      rootMargin: '-60px',
    }
  )

  const proxy = {
    // our observe takes node *and* callback which will be passed
    // IntersectionChange events that match given node
    observe: (node, callback) => {
      // eslint-disable-next-line no-param-reassign
      if (!node.id) node.id = `id_${(uniqueId += 1)}`
      observer.observe(node)
      callbacks[node.id] = callback
    },

    unobserve: node => {
      observer.unobserve(node)
      delete callbacks[node.id]
    },
  }

  return proxy
}

export const highestRatioIntersectingEntry = (observerEntries = []) => {
  return (observerEntries || []).reduce((highest, current) => {
    if (
      current.isIntersecting &&
      (!highest || current.intersectionRatio > highest.intersectionRatio)
    ) {
      return current
    }
    return highest
  }, null)
}
