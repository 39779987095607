const order = [
  'inbox:',
  'folder:',
  'group:',
  'assignee:',
  'is:assigned',
  'is:unassigned',
  'draft:',
  'is:starred',
  'starred:',
  'is:unread',
  'is:open',
  'is:closed',
  'is:spam',
  'is:snoozed',
  'tag:',
  'deleted:',
]

// lookup table, for performance
const orderLookup = {}
order.forEach((key, index) => {
  orderLookup[key] = index
})

// normalize the query part to use the orderLookup table
function normalizePart(part) {
  const first3 = part.substr(0, 3)
  const startsWithIs = first3 === 'is:'
  if (startsWithIs) return part

  const firstPart = part.split(':')[0]
  return `${firstPart}:`
}

// this function normalizes the query string coming from realtime
// it DOES NOT support quoted strings, only ids
export function normalizeSearchQueryId(queryId) {
  // when there is nothing to re-sort, short circuit
  if (!queryId.match(/\s/)) return queryId

  const parts = queryId.split(' ')
  const sorted = parts.sort((a, b) => {
    const orderA = orderLookup[normalizePart(a)]
    const orderB = orderLookup[normalizePart(b)]
    return orderA > orderB ? 1 : -1
  })

  return sorted.join(' ')
}
