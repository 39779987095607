import { selectMailboxesByName } from 'selectors/mailboxes/selectMailboxesByName'
import { selectMailboxesByEmail } from 'selectors/mailboxes/selectMailboxesByEmail'
import { selectMailbox } from 'selectors/mailboxes/selectMailbox'
import { selectFolders } from 'selectors/folders/selectFolders'
import { foldersByIdSelector } from 'selectors/folders/foldersByIdSelector'

// This file gets used by the performance simulator

export const getMailboxById = mailboxId => {
  const state = app.store.getState()
  return selectMailbox(state, mailboxId)
}

export const getMailboxByName = name => {
  const state = app.store.getState()
  return selectMailboxesByName(state)[name]
}

export const getMailboxByEmail = email => {
  const state = app.store.getState()
  return selectMailboxesByEmail(state)[email]
}

export const getMailboxFolderById = folderId => {
  const state = app.store.getState()
  return foldersByIdSelector(state)[folderId]
}

export const getMailboxFolderByName = name => {
  const state = app.store.getState()
  return selectFolders(state).find(x => x.name === name)
}
