import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import { selectFuturePhase } from 'ducks/billing/selectors/selectFuturePhase'
import { doFetchBillingData } from 'ducks/billing/operations'
import { strftime } from 'util/date'
import { usePricingChangeInfo } from 'ducks/billing/hooks'
import { AdminAccessDrawer } from '../../NoAccess'
import { styles } from '../styles'
import ContactCard from '../ContactCard'
import Features from '../Features'

const BillingDownGradePlanConfirmation = ({
  open,
  onExit,
  onClose,
  drawerResourceId: newPricingIdsPipeDelimited,
  drawerBillingCycle,
}) => {
  const dispatch = useDispatch()
  const newPricingIds = useMemo(() => newPricingIdsPipeDelimited.split('|'), [
    newPricingIdsPipeDelimited,
  ])
  const { isLoading, isLimitsExceeded, nextBillDate } = usePricingChangeInfo(
    newPricingIds,
    drawerBillingCycle
  )

  const { startDate } = useSelector(selectFuturePhase) || {}
  const scheduledDate = startDate || nextBillDate || new Date().getTime() / 1000

  const handleClose = useCallback(
    () => {
      onClose({
        ignoreStack: true,
      })
    },
    [onClose]
  )

  const footer = (
    <ModalBtns
      css={styles.btns}
      tertiaryBtnText="Close"
      onClickTertiaryBtn={handleClose}
    />
  )

  useEffect(
    () => {
      dispatch(doFetchBillingData())
    },
    [dispatch]
  )

  return (
    <AdminAccessDrawer
      title="Downgrade plan"
      open={open}
      footer={footer}
      onClose={onExit}
      contentStyles={styles.wrapper}
      isLoading={isLoading}
    >
      <div
        className="grui pt-12"
        css={[text.styles.textNormal, styles.container]}
      >
        Your downgrade has been scheduled for{' '}
        {strftime('%A, %Do %B ‘%y', scheduledDate * 1000)}
        {isLimitsExceeded && (
          <div>
            <div className="grui mt-12">
              You are currently utilizing features which exceeds the limits of
              your downgraded subscription. If these excess features are not
              removed prior to the scheduled downgrade,{' '}
              <strong className="grui tilt-highlight">
                your subscription will automatically
              </strong>
              <strong className="grui tilt-highlight">
                be upgraded back to your current plan.
              </strong>
            </div>
            <div className="grui mt-11">
              <strong css={text.styles.textSm}>
                Features outside of new plan limit:
              </strong>
              <Features
                newPricingIds={newPricingIds}
                newBillingCycle={drawerBillingCycle}
              />
            </div>
          </div>
        )}
      </div>
      {!isLimitsExceeded && <ContactCard />}
    </AdminAccessDrawer>
  )
}

export default BillingDownGradePlanConfirmation
