import { cacheWithInvalidation } from 'util/memoization'
import memoize from 'fast-memoize'

import { createQueue, getQueue, deleteQueue } from './queue'

export const emptyFunc = () => {}

export function throttle(func, wait, options = {}) {
  // Based on underscore.js' `_.throttle`:
  //
  // Returns a function, that, when invoked, will only be triggered at most once
  // during a given window of time. Normally, the throttled function will run
  // as much as it can, without ever going more than once per `wait` duration;
  // but if you'd like to disable the execution on the leading edge, pass
  // `{leading: false}`. To disable execution on the trailing edge, ditto.
  let context
  let args
  let result
  let timeout = null
  let previous = 0
  const later = function later() {
    previous = options.leading === false ? 0 : Date.now()
    timeout = null
    result = func.apply(context, args)
    if (!timeout) {
      context = null
      args = null
    }
  }
  return function throttled(...innerArgs) {
    const now = Date.now()
    if (!previous && options.leading === false) previous = now
    const remaining = wait - (now - previous)
    context = this
    args = innerArgs
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout)
        timeout = null
      }
      previous = now
      result = func.apply(context, args)
      if (!timeout) {
        context = null
        args = null
      }
    } else if (!timeout && options.trailing !== false) {
      timeout = setTimeout(later, remaining)
    }
    return result
  }
}

// Lifted from underscore. Delays a function for the given number of milliseconds, and then calls
// it with the arguments supplied.
export function delay(func, wait, ...args) {
  return setTimeout(() => {
    return func(...args)
  }, wait)
}

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing. Taken from David Walsh, who
// borrowed from Underscore.js.
// If you want to cancel it, just call myDebouncedFn.cancel()
export function debounce(func, wait, immediate) {
  let timeout
  let result

  const later = (context, args) => {
    timeout = null
    if (args) func.apply(context, args)
  }

  const debounced = (...args) => {
    if (timeout) clearTimeout(timeout)
    if (immediate) {
      const callNow = !timeout
      timeout = setTimeout(later, wait)
      if (callNow) result = func.apply(this, args)
    } else {
      timeout = delay(later, wait, this, args)
    }

    return result
  }

  debounced.cancel = () => {
    clearTimeout(timeout)
    timeout = null
  }

  return debounced
}

export function debounceWithArgument(func, wait) {
  const timeoutMap = new Map()

  return (...args) => {
    const context = this
    const key = args[0] // Consider the first argument for debouncing.

    if (timeoutMap.has(key)) {
      clearTimeout(timeoutMap.get(key))
    }

    const timeout = setTimeout(() => {
      func.apply(context, args)
      timeoutMap.delete(key) // Cleanup after execution
    }, wait)

    timeoutMap.set(key, timeout)
  }
}

export const debounceAsync = (fn, wait, options = {}) => {
  if (!Number.isFinite(wait)) {
    throw new TypeError('Expected `wait` to be a finite number')
  }

  let leadingValue
  let timeout
  let resolveList = []

  return function debounceAsyncInner(...arguments_) {
    return new Promise(resolve => {
      const shouldCallNow = options.before && !timeout

      clearTimeout(timeout)

      timeout = setTimeout(() => {
        timeout = null

        const result = options.before
          ? leadingValue
          : fn.apply(this, arguments_)

        // eslint-disable-next-line no-restricted-syntax, no-param-reassign
        for (resolve of resolveList) {
          resolve(result)
        }

        resolveList = []
      }, wait)

      if (shouldCallNow) {
        leadingValue = fn.apply(this, arguments_)
        resolve(leadingValue)
      } else {
        resolveList.push(resolve)
      }
    })
  }
}

export function rAF(func, global = window) {
  return global.requestAnimationFrame(func)
}

// sauce https://github.com/msn0/dead-simple-curry/blob/master/index.js
export function curry(fn) {
  return function curried(...args) {
    return args.length >= fn.length
      ? fn.call(this, ...args)
      : (...rest) => curried.call(this, ...args, ...rest)
  }
}

export const runOnNextTick = fun => setTimeout(fun, 1)

let id = 0
function memoizedId(x) {
  id += 1
  if (!x.__memoizedId) x.__memoizedId = id // eslint-disable-line no-underscore-dangle, no-param-reassign
  return x.__memoizedId // eslint-disable-line no-underscore-dangle
}

// Documentation can be found in `docs/COMPONENT_BEST_PRACTICE.md`
export const propFunc = memoize(
  (...injectedArguments) => {
    const providedFunction = injectedArguments.shift()
    if (typeof providedFunction !== 'function') {
      return providedFunction
    }
    return (...callerArgs) => {
      return providedFunction(...injectedArguments.concat(callerArgs))
    }
  },
  {
    // because we use a specific strategy from fast-memoize, it's causing issues
    // with our wrapper. And that's why we cannot use it. Instead, we inject the
    // cacheWithInvalidation into this direct call to fast-memoize
    cache: cacheWithInvalidation,
    strategy: memoize.strategies.variadic,
    serializer: args => {
      const argumentsWithFuncIds = Array.from(args).map(x => {
        if (typeof x === 'function') return memoizedId(x)
        return x
      })
      return JSON.stringify(argumentsWithFuncIds)
    },
  }
)

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

export function focusRef(node) {
  if (node) node.focus()
}

export function refocus(event) {
  if (event.target && event.target.focus) event.target.focus()
}

export function isFunction(object) {
  return typeof object === 'function'
}

export function isNotFunction(object) {
  return !isFunction(object)
}

/**
 * Special case of function composition where both functions are given the
 * same argument and called separately one after another
 *
 * Essentially,
 *
 *   const composed = fanout(f, g)
 *
 * is equivalent to:
 *
 *   const composed = () => {
 *     f()
 *     g()
 *   }
 */
export function fanout(f = () => {}, g = () => {}, x = undefined) {
  return [f(x), g(x)]
}

function printNotImplemented(name) {
  // eslint-disable-next-line no-console
  console.log(`${name} is not implemented`)
}

export function notImplemented(name) {
  // eslint-disable-next-line no-console
  return propFunc(printNotImplemented, name)
}

export function asyncForEach(array, callback, { concurrency = 1 } = {}) {
  return new Promise(resolve => {
    if (array.length === 0) resolve()
    let queue = null
    const queueId = createQueue(concurrency, Infinity, {
      onEmpty: () => {
        // The queue could be empty, but there might still be
        // requests running. We need to wait for the queue and
        // executing requests (pending) to be empty before we
        // return
        if (queue.getQueueLength() === 0 && queue.getPendingLength() === 0) {
          deleteQueue(queueId)
          resolve()
        }
      },
    })
    queue = getQueue(queueId)
    array.forEach((item, index) => {
      queue.add(() => callback(item, index, array))
    })
  })
}

export const isPromise = v =>
  typeof v === 'object' && typeof v?.then === 'function'
