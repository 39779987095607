import config from 'config'
import { last, getLength } from 'util/arrays'
import { getValueByPath } from 'util/objects'
import debug from 'util/debug'

const tracker = (...args) => {
  try {
    if (getLength(args) < 1) return false
    if (config.env !== 'production') return false
    if (!window.ga) return false

    const googlaAnalyticsTracker = last(window.ga.getAll())
    const trackerProps = getValueByPath('a.data.values', googlaAnalyticsTracker)
    let trackerName
    try {
      trackerName = trackerProps[':name']
    } catch (e) {
      // pass
    }
    if (!trackerName) return false

    const [command, ...rest] = args
    const params = [`${trackerName}.${command}`, ...rest]

    return window.ga(...params)
  } catch (e) {
    debug(e)
    return false
  }
}

const track = (type, subtype, payload) => {
  return tracker('send', 'event', type, subtype, payload)
}

export default { track }
