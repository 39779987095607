import graphql, { getData, getResult } from 'api/graphql'

export const fetchOAuthAuthenticationUrl = (token, type, params = {}) => {
  const paramString = Object.keys(params).reduce((acc, key) => {
    let value = params[key]
    if (typeof value === 'string' || value instanceof String) {
      value = `"${value.replace(/\\([\s\S])|(")/g, '\\$1$2')}"`
    }
    return `${acc}, ${key}: ${value}`
  }, '')

  const query = `
    query OAuthAuthenticationUrl {
      oAuthAuthenticationUrl(type: ${type}, ${paramString}) {
        url
      }
    }
  `
  return graphql(token, query)
    .then(getData)
    .then(getResult('oAuthAuthenticationUrl'))
}
