import { connect } from 'react-redux'

import { doOpenMoreTicketOptionsMenu } from 'actions/menus'
import {
  selectIsMoreTicketOptionsMenuOpen,
  selectIsChangeInboxMenuOpen,
} from 'selectors/menus'
import MoreButtonView from 'components/ConversationHeader/Buttons/MoreButton'
import MoreDropdown from '../MoreDropdown'

const select = state => ({
  isChangeMenuOpen: selectIsChangeInboxMenuOpen(state),
  isMoreOptionsOpen: selectIsMoreTicketOptionsMenuOpen(state),
  Dropdown: MoreDropdown,
})

const perform = {
  openMoreDropdown: doOpenMoreTicketOptionsMenu,
}

export default connect(select, perform)(MoreButtonView)
