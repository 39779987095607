import React from 'react'
import Link from 'redux-first-router-link'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import {
  heading,
  paragraph,
  text,
} from '@groovehq/internal-design-system/lib/styles/elements'
import { SETTINGS_CHAT_MANAGEMENT_PAGE } from 'subapps/settings/types'
import { AdminAccessDrawer } from '../../drawers/NoAccess'

const DisabledDeletion = ({ open, onClose, drawerResourceId }) => {
  const footer = (
    <ModalBtns tertiaryBtnText="Cancel" onClickTertiaryBtn={onClose} />
  )

  return (
    <AdminAccessDrawer
      title="Delete widget"
      open={open}
      onClose={onClose}
      footer={footer}
    >
      <div className="grui mt-10">
        <h4 css={heading.styles.h4}>Deletion not possible</h4>
        <p
          css={[paragraph.styles.preflight, text.styles.textNormal]}
          className="grui mt-5"
        >
          In order to delete your widget, you will need to delete the linked
          Live Chat channel associated to your widget.
        </p>
      </div>
      <Button
        type="secondary"
        className="grui mt-12 w-100"
        as={Link}
        to={{
          type: SETTINGS_CHAT_MANAGEMENT_PAGE,
          payload: {
            id: drawerResourceId,
            tab: 'advanced',
          },
        }}
      >
        Go to live chat settings
      </Button>
    </AdminAccessDrawer>
  )
}

export default DisabledDeletion
