/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { useSelector } from 'react-redux'
import { bool } from 'prop-types'
import TableWithCheckbox from '@groovehq/internal-design-system/lib/components/TableWithCheckbox/TableWithCheckbox'
import { selectAgentsSortedByName } from 'selectors/agents/base'

import useColumns from './useColumn'
import { styles } from './styles'

// otherProps are for TableWithCheckbox
const AgentsSelectionTable = ({
  hasEmail,
  onSelectedRowIdsChange,
  ...otherProps
}) => {
  const agents = useSelector(selectAgentsSortedByName)
  const columns = useColumns(hasEmail)
  return (
    <TableWithCheckbox
      onSelectedRowIdsChange={onSelectedRowIdsChange}
      columns={columns}
      data={agents}
      css={styles.table}
      gapToCheckbox={16}
      {...otherProps}
    />
  )
}

AgentsSelectionTable.propTypes = {
  /**
   * Whether show the users's emails besides their names
   */
  hasEmail: bool,
}

AgentsSelectionTable.defaultProps = {
  hasEmail: false,
}

export default AgentsSelectionTable
