import config from 'config'
import storage from 'util/storage'

export const ERR_POPUP_BLOCKED = 'ERR_POPUP_BLOCKED'
export const ERR_USER_CLOSED_POPUP = 'ERR_USER_CLOSED_POPUP'
export const ERR_USER_REJECTED = 'ERR_USER_REJECTED'
export const ERR_UNKNOWN = 'ERR_UNKNOWN'

export default class OAuthWindow {
  oauthWindow
  failed = false
  _timer
  _resolve
  _reject
  _options

  /**
   * Create an oauth flow.
   * @param {Object} options - The options hash.
   * @param {number} [options.width=600] - Width of the oauth popup.
   * @param {number} [options.height=400] - Height of the oauth popup.
   * @param {string} [options.title=Authenticate] - Title of the oauth popup.
   * @param {string} [options.url=/oauth.html] - Url of the loading page, must be on same domain as frontend.
   */
  constructor(options) {
    const test = storage.get('testOAuthWindowOverride') || {}
    this._options = {
      width: 600,
      height: 400,
      title: 'Authenticate',
      url: '/oauth.html?begin',
      test,
      ...options,
    }
  }

  /**
   * Begin the oauth flow.
   * @param {startCallback} callback - Code to execute during the start of the oauth flow. Receives the OAuthWindow object as a argument.
   * @returns {Promise} Promise object representing the completion or failure of the oauth flow.
   */
  start(callback) {
    const popupOptions = {
      width: this._options.width,
      height: this._options.height,
      top:
        window.screenTop + (window.outerHeight / 2 - this._options.height / 2),
      left:
        window.screenLeft + (window.outerWidth / 2 - this._options.width / 2),
      toolbar: false,
      scrollbars: true,
      status: false,
      resizable: true,
      location: false,
      menuBar: false,
    }
    const optionsString = Object.keys(popupOptions)
      .map(key => `${key}=${popupOptions[key]}`)
      .join(',')

    this._listen()

    this.oauthWindow = window.open(
      this._options.url,
      this._options.title,
      optionsString
    )

    const { skipStartCallback, autoSucceed, autoFail } = this._options.test

    if (skipStartCallback || autoSucceed || autoFail) {
      // eslint-disable-next-line no-console
      console.warn('Using oauth test paramaters:', this._options.test)
    }

    return new Promise((resolve, reject) => {
      this._resolve = resolve
      this._reject = reject
      this._checkOAuthWindowState()
      if (!this.failed) {
        this._timer = setInterval(this._checkOAuthWindowState, 500)
        if (callback && !skipStartCallback) callback(this)
        if (autoSucceed) this._succeed(autoSucceed)
        if (autoFail) this._fail(autoFail)
      }
    })
  }

  /** Cancel the oauth flow. */
  cancel() {
    this._cleanup()
  }

  /**
   * Redirect the oauth flow.
   * @param {string} url - The oauth url to redirect the oauth window to.
   */
  redirect(url) {
    if (this.failed) return
    // TODO (jscheel): Consider using location.replace instead to prevent back button.
    this.oauthWindow.location = url
    this._listen() // listens again, to make sure redirecting doesn't break postMessage
  }

  _checkOAuthWindowState = () => {
    if (!this.oauthWindow) {
      this._fail(ERR_POPUP_BLOCKED)
      return false
    } else if (this.oauthWindow.closed) {
      this._fail(ERR_USER_CLOSED_POPUP)
      return false
    }
    return true
  }

  _handleMessage = event => {
    if (!event.origin) {
      return
    }
    if (
      event.origin !== config.api_url &&
      new URL(event.origin).hostname
        .split('.')
        .slice(-2)
        .join('.') !== config.appDomain
    ) {
      return
    }
    if (event.data === 'success') {
      this._succeed()
    } else if (event.data === 'error') {
      this._fail(ERR_USER_REJECTED)
    } else if (typeof event.data === 'object') {
      // do nothing, this is a spurious event, all of our events are stringified right now
    } else {
      try {
        const data = JSON.parse(event.data)
        if (data.error) {
          if (data.error.message) {
            this._fail(data.error.message)
          } else {
            this._fail(ERR_USER_REJECTED)
          }
          return
        }
        this._succeed(data)
      } catch (error) {
        this._fail(ERR_UNKNOWN)
      }
    }
  }

  _listen() {
    window.removeEventListener('message', this._handleMessage, false)
    window.addEventListener('message', this._handleMessage, false)
  }

  _cleanup() {
    window.removeEventListener('message', this._handleMessage, false)
    clearInterval(this._timer)
    const { disableCloseWindow = false } = this._options.test
    if (this.oauthWindow && !disableCloseWindow) {
      this.oauthWindow.close()
    }
  }

  _succeed(data) {
    this._cleanup()
    this._resolve(data)
  }

  _fail(err) {
    this.failed = true
    this._cleanup()
    this._reject(err)
  }
}

/**
 * @callback startCallback
 * @param {OAuthWindow} oauthWindow  The currently-executing OAuthWindow object.
 */
