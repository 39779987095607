import React from 'react'
import { IconButton } from 'shared/ui'

const TriggerIcon = () => (
  <IconButton
    name="options"
    svg
    size="small"
    iconSize={18}
    tooltip={'More [.]'}
  />
)

export default TriggerIcon
