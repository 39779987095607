import { INTEGRATIONS_UPDATE_FORM_VALUE } from 'constants/action_types'
import {
  INTEGRATION_METHOD_CREATE_NEW,
  INTEGRATION_METHOD_ADD_TO_EXISITING,
} from 'constants/integrations'
import {
  doFetchGithubRepoDetails,
  doFetchGithubRepoIssues,
  doFetchTrelloBoardDetails,
  doFetchTrelloCardsForCurrentBoard,
} from 'actions/userIntegrations'

const integrationMiddleware = store => next => action => {
  const method = store.getState().integrations.selectedMethod
  if (
    action.type === INTEGRATIONS_UPDATE_FORM_VALUE &&
    action.item.key === 'repo'
  ) {
    if (method === INTEGRATION_METHOD_CREATE_NEW) {
      store.dispatch(doFetchGithubRepoDetails(action.item.value))
    }
    if (method === INTEGRATION_METHOD_ADD_TO_EXISITING) {
      store.dispatch(doFetchGithubRepoIssues(action.item.value))
    }
  }
  if (
    action.type === INTEGRATIONS_UPDATE_FORM_VALUE &&
    action.item.key === 'trelloBoard'
  ) {
    if (method === INTEGRATION_METHOD_CREATE_NEW) {
      store.dispatch(doFetchTrelloBoardDetails(action.item.value))
    }
    if (method === INTEGRATION_METHOD_ADD_TO_EXISITING) {
      store.dispatch(doFetchTrelloCardsForCurrentBoard(action.item.value))
    }
  }
  return next(action)
}

export default integrationMiddleware
