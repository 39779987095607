import {
  LONGEST_UNANSWERED,
  NEWEST_BY_CLOSED,
  NEWEST_BY_DELETED,
  NEWEST_BY_SPAM,
} from 'constants/defaults'
import { CLOSED, SPAM } from 'constants/ticket_state_types'
import { hasDeletedCondition } from './hasDeletedCondition'
import { hasTicketStateTypeCondition } from './hasTicketStateTypeCondition'

export const SORT_ORDERS_FOR_FOLDER = new Map([
  [
    NEWEST_BY_CLOSED,
    fol => hasTicketStateTypeCondition(fol?.conditions, CLOSED),
  ],
  [NEWEST_BY_SPAM, fol => hasTicketStateTypeCondition(fol?.conditions, SPAM)],
  [NEWEST_BY_DELETED, fol => hasDeletedCondition(fol?.conditions)],
  [
    LONGEST_UNANSWERED,
    fol => {
      const conditions = fol?.conditions
      return (
        !hasDeletedCondition(conditions) &&
        !hasTicketStateTypeCondition(conditions, CLOSED) &&
        !hasTicketStateTypeCondition(conditions, SPAM)
      )
    },
  ],
])
