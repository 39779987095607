import React, { useCallback, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import {
  heading,
  text,
} from '@groovehq/internal-design-system/lib/styles/elements'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import { AdminAccessDrawer } from 'subapps/settings/components/drawers/NoAccess'
import { doConfirmShopifyActiveSubscription } from 'ducks/billing/operations'
import useIsMounted from 'util/hooks/useIsMounted'
import { useSetupSubscription } from 'ducks/billing/hooks'

export default function BillingConfirmDrawer({
  drawerId,
  drawerResourceId: teamId,
  previousDrawer,
  open,
  onClose,
  onExit,
  ...rest
}) {
  const isMounted = useIsMounted()
  const dispatch = useDispatch()
  const [state, setState] = useState({
    status: 'confirming',
    running: false,
  })
  const { status, running } = state

  const checkBilling = useCallback(
    async () => {
      if (!isMounted()) return
      try {
        await dispatch(doConfirmShopifyActiveSubscription())
      } catch {
        setState(prevState => ({
          ...prevState,
          status: 'failed',
          running: false,
        }))
        return
      }

      if (!isMounted()) return
      // Successful conversion of shopify
      setState(prevState => ({
        ...prevState,
        status: 'success',
        running: false,
      }))
    },
    [dispatch, isMounted]
  )

  const onCompleted = useCallback(
    () => {
      onClose({ ignoreStack: true })
    },
    [onClose]
  )

  const { saveSubscription } = useSetupSubscription({ convertTrials: true })

  useEffect(
    () => {
      if (status === 'confirming' && !running) {
        setState(prevState => ({
          ...prevState,
          running: true,
        }))
        checkBilling(1)
      }
    },
    [status, running, checkBilling]
  )

  let cancelButtonText = null
  let saveButtonText = null
  let saveFunction = onCompleted
  let header = null
  let description = null
  if (status === 'confirming') {
    cancelButtonText = 'Cancel'
  } else if (status === 'success') {
    saveButtonText = 'Close'
    header = 'Success!'
    description =
      'Your subscription to Groove has successfully been changed in Shopify.'
  } else if (status === 'failed') {
    saveButtonText = 'Try again'
    cancelButtonText = 'Cancel'
    saveFunction = saveSubscription
    header = 'An issue occured!'
    description =
      'Unfortunately the subscription change within Shopify was unsuccessful.'
  }

  return (
    <AdminAccessDrawer
      {...rest}
      open={open}
      onClose={onExit}
      title="Billing confirmation"
      isLoading={running}
      footer={
        <ModalBtns
          onSave={saveFunction}
          saveBtnText={saveButtonText}
          saveBtnHtmlType="button"
          tertiaryBtnText={cancelButtonText}
          onClickTertiaryBtn={onClose}
        />
      }
    >
      <div className="grui mt-10">
        {header && <h1 css={heading.styles.h3}>{header}</h1>}
        {description && (
          <div
            className="grui mt-4"
            css={[text.styles.textNormal, text.styles.textDark]}
          >
            {description}
          </div>
        )}
      </div>
    </AdminAccessDrawer>
  )
}
