export default {
  google: {},
  outlook: {},
  office365: {},
  imap: {},
  // This is to indicate that facebook and instagram are valid options, but there are
  // no settings for them (aka the flow ends here)
  facebook: {},
  instagram: {},
  forwarding: {},
}
