import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { string, arrayOf } from 'prop-types'
import {
  text,
  paragraph,
} from '@groovehq/internal-design-system/lib/styles/elements'
import { selectCurrentRulesById } from 'ducks/rules/selectors'
import { selectRawAgentsById } from 'selectors/agents/base'
import { strftime } from 'util/date'

const RuleDeleteContent = ({ ids }) => {
  const entitiesById = useSelector(selectCurrentRulesById)

  const agentsById = useSelector(selectRawAgentsById)

  const entitiesForIds = useMemo(
    () => {
      return ids.map(id => entitiesById[id]).filter(e => !!e)
    },
    [ids, entitiesById]
  )

  // There will always be atleast 1 id. If there isnt then it means we're currently
  // loading the entities after a page refresh
  if (entitiesForIds.length === 0) {
    return <span css={text.styles.fontMedium}>Loading...</span>
  }

  // We display a slightly differnt message for single deletes
  const countUsed = entitiesForIds[0].run_count
  const { name: agentName } = agentsById[entitiesForIds[0].creator] || {
    name: `an unknown ${app.t('agent')}`,
  }
  return (
    <>
      <p css={[paragraph.styles.preflight, text.styles.textNormal]}>
        You are about to delete “
        <span css={text.styles.fontMedium}>{entitiesForIds[0].name}</span>”
        which was created by{' '}
        <span css={text.styles.fontMedium}>{agentName}</span>.
      </p>
      <p css={[text.styles.textNormal]}>
        The rule has {countUsed === 0 && <>never been run.</>}
        {countUsed > 0 && (
          <>
            run{' '}
            <span css={text.styles.fontMedium}>
              {countUsed} time{countUsed > 1 ? 's' : ''}, last on the{' '}
              {strftime('%Do of %B %Y', entitiesForIds[0].last_run_at)}
            </span>.
          </>
        )}
      </p>
      <p css={paragraph.styles.preflight} className="grui mt-14">
        Are you sure you want to proceed with this action?
      </p>
    </>
  )
}

RuleDeleteContent.propTypes = {
  ids: arrayOf(string).isRequired,
}

RuleDeleteContent.defaultProps = {}

export default RuleDeleteContent
