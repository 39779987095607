import React, { useCallback, useState } from 'react'
import ForwardingFailConfirmation from './Confirmation'
import ForwardingFailinformation from './Information'

const ForwardingFail = props => {
  const { drawerChannelHasConfirmationCode: hasConfirmationCode } = props
  const [wantsRetry, setWantsRetry] = useState(hasConfirmationCode)
  const handleRetry = useCallback(
    () => {
      setWantsRetry(true)
    },
    [setWantsRetry]
  )
  if (wantsRetry) {
    return <ForwardingFailinformation {...props} />
  }
  return <ForwardingFailConfirmation onRetry={handleRetry} {...props} />
}
export default ForwardingFail
