// ordinal stolen from moment.js
function absFloor(number) {
  if (number < 0) {
    // -0 -> 0
    return Math.ceil(number) || 0
  }
  return Math.floor(number)
}

export function toInt(argumentForCoercion) {
  const coercedNumber = +argumentForCoercion
  let value = 0

  if (coercedNumber !== 0 && isFinite(coercedNumber)) {
    value = absFloor(coercedNumber)
  }

  return value
}

export function ordinal(number) {
  if (toInt((number % 100) / 10) === 1) return `${number}th`
  const b = number % 10
  if (b === 1) return `${number}st`
  if (b === 2) return `${number}nd`
  if (b === 3) return `${number}rd`
  return `${number}th`
}
