import { connect } from 'react-redux'
import {
  selectCurrentSearchQueryString,
  selectCurrentSortOrder,
} from 'selectors/app'
import { selectSortOptions } from 'selectors/search/base'
import SortMenuView from 'components/ConversationList/Header/TitleBar/SortMenu'

const select = state => ({
  sortOrder: selectCurrentSortOrder(state),
  queryId: selectCurrentSearchQueryString(state),
  options: selectSortOptions(state),
})

export default connect(select)(SortMenuView)
