import { css } from '@emotion/react'

const icon = css`
  height: 128px;
  width: 128px;
`

const success = theme => css`
  color: ${theme.color.primary.positive};
`

const error = theme => css`
  color: ${theme.color.primary.negative};
`

export const styles = {
  icon,
  success,
  error,
}
