import React from 'react'
import { useFormContext } from 'react-hook-form'
import Switch from '@groovehq/internal-design-system/lib/components/Switch/Switch'
import {
  list,
  paragraph,
  text,
} from '@groovehq/internal-design-system/lib/styles/elements'
import * as styles from './styles'

const paragraphStyles = [
  paragraph.styles.preflight,
  text.styles.textMediumDark,
  text.styles.italic,
]

export default function UserAdvancedContent() {
  const { register } = useFormContext()

  return (
    <>
      <ul css={[list.styles.preflight, styles.list]} className="grui mt-8">
        <li>
          <Switch
            id="chat_allow_round_robin"
            {...register('chat_allow_round_robin')}
          >
            Include in Round Robin
          </Switch>
          <p css={paragraphStyles}>
            Disabling this completely removes the {app.t('agent')} from Round
            Robin assignment for all chat channels, regardless of their presence
            state.
          </p>
        </li>
        <li>
          <Switch id="visible_in_widget" {...register('visible_in_widget')}>
            Display {app.t('agent')} avatar in widget heading
          </Switch>
          <p css={paragraphStyles}>
            Disabling this hides the {app.t('agent_possessive')} avatar in the
            headers of all your widgets.
          </p>
        </li>
        <li>
          <Switch
            id="include_in_analytics"
            {...register('include_in_analytics')}
          >
            Include in analytics
          </Switch>
          <p css={paragraphStyles}>
            Disabling this will prevent the {app.t('agent')} from being tracked
            in any metrics.
          </p>
        </li>
      </ul>
    </>
  )
}
