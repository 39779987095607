import { css } from '@emotion/react'

const dropdownBtn = css`
  min-width: 100%;
`

const fullWith = css`
  width: 100%;
  max-width: unset;
`

/* 
  Cindy is working on a improvement on the dropdown component which
  will allow us to remove this hack. The TLDR is that our dropdown 
  component will have a header and a stickyheader and each will be
  seperated by a divider with padding etc. The create button will be
  in the header, and the search will be in the sticky header. Right
  now both are inside of the header
*/
const hackPadding = css`
  margin-bottom: 12px;
`

export const styles = {
  dropdownBtn,
  fullWith,
  hackPadding,
}
