import React, { useCallback, useState } from 'react'
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import { isFunction } from 'util/functions'
import { useSelector } from 'react-redux'
import { selectAccountUrl } from 'selectors/app'
import { toQueryString } from 'util/params'
import { compact } from 'util/objects'
import MessageCard from '@groovehq/internal-design-system/lib/components/MessageCard/MessageCard'

const FORM_ID = 'SETUP_CARD_FORM_ID'

const CheckoutForm = ({
  formId: inputFormId,
  showFormButtons = true,
  onCancel,
  actionText,
  onReady,
  onSubmitting,
  onError,
  onSubmitted,
  saveSubscription,
  convertTrials,
  pricingIds,
  billingCycle,
}) => {
  const formId = inputFormId || FORM_ID
  const stripe = useStripe()
  const elements = useElements()
  const accountUrl = useSelector(selectAccountUrl)

  const [errorMessage, setErrorMessage] = useState(null)

  const handleOnSubmit = useCallback(
    async event => {
      // We don't want to let default form submission happen here,
      // which would refresh the page.
      event.preventDefault()
      setErrorMessage(null)

      if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return null
      }
      if (isFunction(onSubmitting)) onSubmitting()

      const returnParams = compact({
        'drawer-1': 'billing-setup-payment-method-feedback',
        'drawerSaveSubscription-1': saveSubscription,
        'drawerConvertTrials-1': convertTrials,
        'drawerPricingIds-1': pricingIds,
        'drawerBillingCycle-1': billingCycle,
      })
      const confirmResponse = await stripe.confirmSetup({
        // `Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: `${accountUrl}settings/billing/summary?${toQueryString(
            returnParams
          )}`,
        },
        redirect: 'if_required',
      })
      const { error } = confirmResponse

      if (error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        if (isFunction(onError)) onError(error)
        setErrorMessage(error.message)
      } else if (isFunction(onSubmitted)) {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
        onSubmitted(confirmResponse.setupIntent.id)
      }
      return true
    },
    [
      setErrorMessage,
      elements,
      stripe,
      onSubmitted,
      onSubmitting,
      onError,
      accountUrl,
      saveSubscription,
      convertTrials,
      pricingIds,
      billingCycle,
    ]
  )

  return (
    <form onSubmit={handleOnSubmit} id={formId}>
      {errorMessage && (
        <MessageCard className="grui mb-10" type="negative">
          {errorMessage}
        </MessageCard>
      )}
      <PaymentElement onReady={onReady} />
      {showFormButtons && (
        <div className="grui mt-10">
          <Button
            type="tertiary"
            size="small"
            htmlType="button"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            type="info"
            size="small"
            htmlType="submit"
            form={formId}
            className="grui ml-4"
            disabled={!stripe}
          >
            {actionText || 'Save card'}
          </Button>
        </div>
      )}
    </form>
  )
}

export default CheckoutForm
