import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util'
import { FormControl } from '@groovehq/internal-design-system/lib/components/Field/Field'

const base = theme => css`
  padding: ${rem(10)} 0;
  border-bottom: 1px dashed ${theme.color.monochrome.medium};
  position: relative;

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    border-bottom-color: transparent;
    padding-bottom: ${rem(0)};
  }

  & .operator {
    position: absolute;
    top: 100%;
    left: 0;
    transform: translateY(-50%);
    color: ${theme.color.monochrome.mediumDark};
    background-color: ${theme.color.monochrome.superLight};
    font-size: ${theme.fontSize.tiny};
    font-weight: ${theme.fontWeight.medium};
    line-height: 1;
    padding-right: ${rem(10)};
    padding-left: ${rem(4)};
  }

  &:last-of-type .operator {
    display: none;
  }
`

const dropdownContainer = css`
  & > * {
    width: 100%;
  }
`

const dropdownBtn = css`
  min-width: unset;
  width: 100%;
  & > * {
    padding-right: ${rem(10)};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

const dropdowns = css`
  display: flex;
  align-items: flex-start;
  margin-left: -${rem(4)};
  margin-right: -${rem(4)};
  width: 100%;

  & > * {
    flex: 0 0 calc((100% - ${rem(36)}) / 3);
    max-width: calc((100% - ${rem(36)}) / 3);
    padding-left: ${rem(4)};
    padding-right: ${rem(4)};

    &:last-child {
      flex-basis: auto;
    }
  }
`

const field = theme => css`
  & > ${FormControl} input {
    font-size: ${theme.fontSize.base};
    padding-top: ${rem(6)};
    padding-bottom: ${rem(6)};
  }
`

const editor = css`
  .ck-editor__editable_inline {
    min-height: ${rem(100)};
  }

  .ck.ck-toolbar {
    .ck-toolbar__items {
      padding-top: 1px;
      padding-bottom: 1px;
    }
  }
`

export const styles = {
  base,
  dropdownContainer,
  dropdownBtn,
  dropdowns,
  field,
  editor,
}
