import React from 'react'
import { useLink } from 'ducks/location/hooks'

import { Tabs } from '../../shared/SettingsModal'

import { SETTINGS_INTEGRATIONS_PROVIDER_PAGE } from '../../../../types'

import ActionsTab from './ActionsTab'
import SidebarTab from './SidebarTab'

const toActionsTab = {
  type: SETTINGS_INTEGRATIONS_PROVIDER_PAGE,
  payload: { provider: 'trello', tab: 'actions' },
}
const toSidebarTab = {
  type: SETTINGS_INTEGRATIONS_PROVIDER_PAGE,
  payload: { provider: 'trello', tab: 'sidebar' },
}

export default function TrelloSettingsForm({ formFields, tab, onChange }) {
  const toActionsTabAction = useLink(toActionsTab, { preserveQuery: true })
  const toSidebarTabAction = useLink(toSidebarTab, { preserveQuery: true })
  return (
    <Tabs>
      <Tabs.Header>
        <React.Fragment>
          <Tabs.Link active={!tab || tab === 'actions'} to={toActionsTabAction}>
            Actions
          </Tabs.Link>
          <Tabs.Link active={tab === 'sidebar'} to={toSidebarTabAction}>
            Sidebar
          </Tabs.Link>
        </React.Fragment>
      </Tabs.Header>
      <ActionsTab
        active={!tab || tab === 'actions'}
        formFields={formFields}
        onChange={onChange}
      />
      <SidebarTab
        active={tab === 'sidebar'}
        formFields={formFields}
        onChange={onChange}
      />
    </Tabs>
  )
}
