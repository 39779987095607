import { css } from '@emotion/react'

const wrapper = theme => css`
  transition: all 150ms ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: ${theme.color.secondary.brand};
  }
`

const date = theme => css`
  font-size: ${theme.fontSize.xsmall};
`

const messagesCount = theme => css`
  color: ${theme.color.monochrome.mediumDark};
`

const author = theme => css`
  font-weight: ${theme.fontWeight.semibold};
`

const bodyText = theme => css`
  color: ${theme.color.monochrome.mediumDark};
`

const stillSending = theme => css`
  font-size: ${theme.fontSize.xsmall};
  color: ${theme.color.primary.warningText};
`

export const styles = {
  wrapper,
  date,
  messagesCount,
  author,
  bodyText,
  stillSending,
}
