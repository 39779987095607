import React, { useCallback, useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AdminAccessDrawer } from 'subapps/settings/components/drawers/NoAccess'
import { DRAWER_ID_FOLDERS_CHANNELS_AUTHENTICATE_SUCCESS } from 'ducks/drawers/ids'
import {
  DRAWER_TYPE_CHANNELS_AUTHENTICATE_SUCCESS,
  DRAWER_TYPE_CHANNELS_SELECT_FACEBOOKPAGE,
} from 'ducks/drawers/types'
import { useDrawer } from 'ducks/drawers/hooks'
import { buildDrawerQueryParam } from 'ducks/drawers/util'
import {
  selectPendingChannelById,
  selectCurrentChannelById,
} from 'ducks/channels/selectors'
import {
  useChannel,
  useEstablishChannelConnection,
  useChannelDetailsConfig,
} from 'ducks/channels/hooks'
import { doFetchFacebookPages } from 'ducks/facebook/actions'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import { styles as modalBtnsStyles } from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns.styles'
import HeaderWithImage from '@groovehq/internal-design-system/lib/components/HeaderWithImage/HeaderWithImage'
import { oAuthErrorToErrorCode } from 'subapps/settings/components/drawers/Channels/ChannelDetails/oAuthError'
import {
  button,
  paragraph,
  text,
} from '@groovehq/internal-design-system/lib/styles/elements'
import MessageCard from '@groovehq/internal-design-system/lib/components/MessageCard/MessageCard'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import {
  isBridgeChannelType,
  isEmailOAuthChannelType,
} from 'ducks/channels/channelTypes'
import { doFetchChatChannels } from 'ducks/channels/actions'
import { doBuildInboxMenuFromMailboxes } from 'ducks/folders/operations/collections'
import { googleButton as GoogleButton } from 'assets/icons/groove/v2'
import { styles } from './styles'
import { CHANNEL_TYPES, drawerConfig } from '../Channels.data'
import useSourceWording from './useSourceWording'

const { DEFAULT_DRAWER_TITLE } = drawerConfig

const drawerSuccessConfig = {
  id: DRAWER_ID_FOLDERS_CHANNELS_AUTHENTICATE_SUCCESS,
  type: DRAWER_TYPE_CHANNELS_AUTHENTICATE_SUCCESS,
  closeIgnoresStack: true,
}

const drawerFacebookPageSelection = {
  type: DRAWER_TYPE_CHANNELS_SELECT_FACEBOOKPAGE,
  closeIgnoresStack: true,
}

const AuthenticateChannel = ({
  onClose,
  onExit,
  drawerResourceId: channelId,
  drawerChannelType: channelType,
  drawerRetryAuth: retryAuth,
  drawerSource: source = 'settings',
  drawerAction: action = 'install',
  previousDrawer,
}) => {
  const dispatch = useDispatch()
  const pendingChannel = useSelector(state =>
    selectPendingChannelById(state, channelId)
  )
  const currentChannel = useSelector(state =>
    selectCurrentChannelById(state, channelId)
  )

  useChannel(channelId, { useCachedIfAvailable: true })
  const [createdChannel, setCreatedChannel] = useState(null)
  const [nextDrawerConfig, setNextDrawerConfig] = useState(drawerSuccessConfig)
  const [errorCode, setErrorCode] = useState(null)
  const { oAuthErrors } = useChannelDetailsConfig(channelType)
  const channel = pendingChannel || currentChannel
  const { bridge: { externalName } = {} } = channel || {}
  const { email: channelEmail } = channel || {}
  const channelTypeConfig = CHANNEL_TYPES[channelType] || {}
  const { name } = channelTypeConfig
  const channelTitle = channelEmail || name
  const {
    drawerTitle: sourceDrawerTitle,
    intro: sourceIntro,
    btnText: sourceBtnText,
  } = useSourceWording(source, channelTypeConfig)

  const { drawerId: nextDrawerId, openDrawer: openNextDrawer } = useDrawer(
    nextDrawerConfig
  )
  const isGoogleChannelType = channelType === 'google'

  const oauthErrorMessage = useMemo(
    () => {
      if (!errorCode) return null

      const message = oAuthErrors[errorCode] || oAuthErrors.DEFAULT
      if (typeof message === 'string') {
        return message.replaceAll('{externalName}', externalName)
      }
      return message
    },
    [errorCode, oAuthErrors, externalName]
  )

  const handleError = useCallback(
    async error => {
      setErrorCode(oAuthErrorToErrorCode(error, channelType))
      // eslint-disable-next-line no-console
      console.error(error)

      setCreatedChannel(false)
      if (isBridgeChannelType(channelType)) {
        dispatch(doFetchChatChannels({ skipLoaded: false }))
      }
    },
    [dispatch, channelType]
  )

  const handleSuccess = useCallback(
    async connectedChannel => {
      if (isEmailOAuthChannelType(channelType)) {
        setNextDrawerConfig(drawerSuccessConfig)
        setCreatedChannel(connectedChannel)
        dispatch(doBuildInboxMenuFromMailboxes())
      } else if (isBridgeChannelType(channelType)) {
        dispatch(doFetchChatChannels({ skipLoaded: false }))
        const { facebookPages } = await dispatch(
          doFetchFacebookPages({ skipLoaded: false })
        )
        if (action === 'reconnect' || facebookPages.length === 1) {
          onExit()
        } else {
          setNextDrawerConfig(drawerFacebookPageSelection)
          setCreatedChannel(connectedChannel)
        }
      }
    },
    [dispatch, onExit, channelType, action]
  )

  const { connectChannel } = useEstablishChannelConnection({
    channelId,
    channelType,
    source,
    action,
  })

  const handleConnectChannel = useCallback(
    async () => {
      setCreatedChannel(null)
      setErrorCode(null)
      try {
        const connectedChannel = await connectChannel()
        await handleSuccess(connectedChannel)
      } catch (error) {
        await handleError(error)
      }
    },
    [connectChannel, handleError, handleSuccess]
  )

  useEffect(
    () => {
      if (!createdChannel) return
      const query = {
        ...buildDrawerQueryParam(
          nextDrawerId,
          'drawerChannelType',
          channelType
        ),
      }
      if (source) {
        Object.assign(
          query,
          buildDrawerQueryParam(nextDrawerId, 'drawerSource', source)
        )
      }
      openNextDrawer(createdChannel?.id, {
        clearStack:
          nextDrawerConfig !== drawerFacebookPageSelection && !!createdChannel,
        query,
      })
    },
    // We only want to open the net drawer when the createdChannel changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [openNextDrawer, createdChannel]
  )

  useEffect(
    () => {
      if (retryAuth === 1) {
        handleConnectChannel()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [retryAuth]
  )

  const handleBack = useCallback(
    () => {
      onClose({ ignoreStack: false })
    },
    [onClose]
  )

  const footer = (
    <>
      {oauthErrorMessage && (
        <MessageCard
          type="negative"
          className="grui mx-12 my-10"
          css={styles.messageCard}
          isIconHidden
        >
          <p css={[paragraph.styles.preflight, text.styles.fontMedium]}>
            Authentication error
          </p>
          <p css={paragraph.styles.preflight} className="grui mt-4">
            {oauthErrorMessage}
          </p>
        </MessageCard>
      )}
      {isGoogleChannelType && (
        <div css={modalBtnsStyles.base} className="grui items-center">
          <button
            css={[button.styles.preflight, styles.googleButton]}
            onClick={handleConnectChannel}
          >
            {/* Should follow the branding guidelines from Google: https://developers.google.com/identity/branding-guidelines */}
            <GoogleButton />
          </button>
          {previousDrawer && (
            <Button
              type="tertiary"
              onClick={handleBack}
              css={styles.googleButton}
            >
              Back
            </Button>
          )}
        </div>
      )}

      {!isGoogleChannelType && (
        <ModalBtns
          saveBtnText={sourceBtnText || `Authenticate ${name}`}
          onSave={handleConnectChannel}
          tertiaryBtnText={previousDrawer && 'Back'}
          onClickTertiaryBtn={handleBack}
        />
      )}
    </>
  )

  return (
    <AdminAccessDrawer
      title={sourceDrawerTitle || DEFAULT_DRAWER_TITLE}
      onClose={onExit}
      footer={footer}
    >
      <HeaderWithImage
        className="grui mt-14 mb-12"
        intro={
          sourceIntro ||
          `To start receiving your ${channelTitle} conversations in Groove, you will need to authenticate your ${name} account.`
        }
      />
    </AdminAccessDrawer>
  )
}

export default AuthenticateChannel
