export function stopEvent(e) {
  if (!e) return false
  e.preventDefault()
  e.stopPropagation()
  return true
}

export const stopEventAndRun = fn => e => {
  stopEvent(e)
  fn()
}

export function stopPropagation(e) {
  if (!e) return false
  e.stopPropagation()
  return true
}

export const isCmdEnter = e => (e.ctrlKey || e.metaKey) && e.keyCode === 13
