import React, { useState, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import { heading } from '@groovehq/internal-design-system/lib/styles/elements'
import Table from '@groovehq/internal-design-system/lib/components/Table/Table'
import { AdminAccessDrawer } from 'subapps/settings/components/drawers/NoAccess'
import { SETTINGS_WIDGET_MANAGEMENT_PAGE } from 'subapps/settings/types'
import {
  selectWidgetSettingsById,
  selectWidgetsWithoutChat,
} from 'ducks/widgets/selectors'
import { doUpdateSettings } from 'ducks/widgets/operations'
import debug from 'util/debug'
import useColumns from './useColumns'

const SelectWidget = ({ onClose, onExit, previousDrawer }) => {
  const dispatch = useDispatch()
  const widgetsWithoutChat = useSelector(selectWidgetsWithoutChat)
  const [selectedWidgetId, setSelectedWidgetId] = useState(null)
  const widgetSettings = useSelector(
    state => selectWidgetSettingsById(state, selectedWidgetId) || {}
  )
  const chatEnabled = widgetSettings['chat.enabled']
  const columns = useColumns(selectedWidgetId)
  const [saveBtnDisabled, setSaveBtnDisabled] = useState(true)

  const handleBack = useCallback(
    () => {
      onClose({ ignoreStack: false })
    },
    [onClose]
  )

  const handleEnableChatForWidget = useCallback(
    async () => {
      setSaveBtnDisabled(true)
      try {
        if (chatEnabled) {
          // MERGE commit won't work if the value in the settings is the same as the value we want to merge and publish
          // So need to set the value to be false first
          await dispatch(
            doUpdateSettings({ 'chat.enabled': false }, selectedWidgetId)
          )
        }
        await dispatch(
          doUpdateSettings({ 'chat.enabled': true }, selectedWidgetId, {
            commitType: 'MERGE',
            shouldShowToasts: true,
            toastsConfig: {
              success: {
                content: 'Live Chat created',
              },
              failed: {
                content: 'Live Chat creation failed',
              },
            },
          })
        )
        dispatch({
          type: SETTINGS_WIDGET_MANAGEMENT_PAGE,
          payload: {
            id: selectedWidgetId,
            tab: 'chat',
          },
        })
      } catch (err) {
        debug(err)
      }
    },
    [dispatch, selectedWidgetId, chatEnabled]
  )

  useEffect(
    () => {
      if (selectedWidgetId === null && widgetsWithoutChat.length) {
        setSaveBtnDisabled(false)
        setSelectedWidgetId(widgetsWithoutChat[0].id)
      }
    },
    [selectedWidgetId, widgetsWithoutChat]
  )

  const footer = (
    <ModalBtns
      tertiaryBtnText={previousDrawer ? 'Back' : 'Close'}
      onClickTertiaryBtn={handleBack}
      saveBtnText="Continue"
      saveBtnDisabled={saveBtnDisabled}
      onSave={handleEnableChatForWidget}
    />
  )

  return (
    <AdminAccessDrawer title="Add a channel" footer={footer} onClose={onExit}>
      <h4 className="grui mt-14 mb-10" css={heading.styles.h4}>
        Which widget do you want to add it to?
      </h4>
      <Table
        data={widgetsWithoutChat}
        columns={columns}
        onEdit={setSelectedWidgetId}
      />
    </AdminAccessDrawer>
  )
}

export default SelectWidget
