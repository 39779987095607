import Portal from 'semantic-ui-react/dist/es/addons/Portal'
import Modal from 'semantic-ui-react/dist/es/modules/Modal'
import Popup from 'semantic-ui-react/dist/es/modules/Popup'

const suiPortalRoot = document.getElementById('sui-portal-root')
const klasses = [Portal, Modal, Popup]
klasses.forEach(klass => {
  /* eslint-disable no-param-reassign */
  klass.defaultProps = {
    ...klass.defaultProps,
    mountNode: suiPortalRoot,
  }
  /* eslint-enable no-param-reassign */
})

/*
 *
 * After uncommenting from ./src/kb.js - uncomment out the component you want to import below.
 * This way - we only import what we need to the bundle, as well as the component.
 *
 * Broken down by component type (Addons, Behaviors, Collections, Elements, Modules, Views)
 *
*/
// Addons
export {
  default as Responsive,
} from 'semantic-ui-react/dist/es/addons/Responsive'
export { default as Confirm } from 'semantic-ui-react/dist/es/addons/Confirm'
export { default as Portal } from 'semantic-ui-react/dist/es/addons/Portal'
export { default as Radio } from 'semantic-ui-react/dist/es/addons/Radio'
export { default as Ref } from 'semantic-ui-react/dist/es/addons/Ref'
export { default as Select } from 'semantic-ui-react/dist/es/addons/Select'
export { default as TextArea } from 'semantic-ui-react/dist/es/addons/TextArea'
// export { default as TransitionablePortal } from 'semantic-ui-react/dist/es/addons/TransitionablePortal'

// Behaviors
export {
  default as Visibility,
} from 'semantic-ui-react/dist/es/behaviors/Visibility'

// Collections
// export { default as Breadcrumb } from 'semantic-ui-react/dist/es/collections/Breadcrumb'
// export { default as BreadcrumbDivider } from 'semantic-ui-react/dist/es/collections/Breadcrumb/BreadcrumbDivider'
// export { default as BreadcrumbSection } from 'semantic-ui-react/dist/es/collections/Breadcrumb/BreadcrumbSection'

export { default as Form } from 'semantic-ui-react/dist/es/collections/Form'
export {
  default as FormButton,
} from 'semantic-ui-react/dist/es/collections/Form/FormButton'
export {
  default as FormCheckbox,
} from 'semantic-ui-react/dist/es/collections/Form/FormCheckbox'
export {
  default as FormDropdown,
} from 'semantic-ui-react/dist/es/collections/Form/FormDropdown'
export {
  default as FormField,
} from 'semantic-ui-react/dist/es/collections/Form/FormField'
export {
  default as FormGroup,
} from 'semantic-ui-react/dist/es/collections/Form/FormGroup'
export {
  default as FormInput,
} from 'semantic-ui-react/dist/es/collections/Form/FormInput'
export {
  default as FormRadio,
} from 'semantic-ui-react/dist/es/collections/Form/FormRadio'
export {
  default as FormSelect,
} from 'semantic-ui-react/dist/es/collections/Form/FormSelect'
export {
  default as FormTextArea,
} from 'semantic-ui-react/dist/es/collections/Form/FormTextArea'

export { default as Grid } from 'semantic-ui-react/dist/es/collections/Grid'
export {
  default as GridColumn,
} from 'semantic-ui-react/dist/es/collections/Grid/GridColumn'
export {
  default as GridRow,
} from 'semantic-ui-react/dist/es/collections/Grid/GridRow'

export { default as Menu } from 'semantic-ui-react/dist/es/collections/Menu'
export {
  default as MenuHeader,
} from 'semantic-ui-react/dist/es/collections/Menu/MenuHeader'
export {
  default as MenuItem,
} from 'semantic-ui-react/dist/es/collections/Menu/MenuItem'
export {
  default as MenuMenu,
} from 'semantic-ui-react/dist/es/collections/Menu/MenuMenu'

export {
  default as Message,
} from 'semantic-ui-react/dist/es/collections/Message'
// export { default as MessageContent } from 'semantic-ui-react/dist/es/collections/Message/MessageContent'
// export { default as MessageHeader } from 'semantic-ui-react/dist/es/collections/Message/MessageHeader'
// export { default as MessageItem } from 'semantic-ui-react/dist/es/collections/Message/MessageItem'
// export { default as MessageList } from 'semantic-ui-react/dist/es/collections/Message/MessageList'

export { default as Table } from 'semantic-ui-react/dist/es/collections/Table'
export {
  default as TableBody,
} from 'semantic-ui-react/dist/es/collections/Table/TableBody'
export {
  default as TableCell,
} from 'semantic-ui-react/dist/es/collections/Table/TableCell'
export {
  default as TableFooter,
} from 'semantic-ui-react/dist/es/collections/Table/TableFooter'
export {
  default as TableHeader,
} from 'semantic-ui-react/dist/es/collections/Table/TableHeader'
export {
  default as TableHeaderCell,
} from 'semantic-ui-react/dist/es/collections/Table/TableHeaderCell'
export {
  default as TableRow,
} from 'semantic-ui-react/dist/es/collections/Table/TableRow'

// Elements
export {
  default as Button,
} from 'semantic-ui-react/dist/es/elements/Button/Button'
export {
  default as ButtonContent,
} from 'semantic-ui-react/dist/es/elements/Button/ButtonContent'
export {
  default as ButtonGroup,
} from 'semantic-ui-react/dist/es/elements/Button/ButtonGroup'
export {
  default as ButtonOr,
} from 'semantic-ui-react/dist/es/elements/Button/ButtonOr'

export {
  default as Container,
} from 'semantic-ui-react/dist/es/elements/Container'

export { default as Divider } from 'semantic-ui-react/dist/es/elements/Divider'

// export { default as Flag } from 'semantic-ui-react/dist/es/elements/Flag'

export { default as Header } from 'semantic-ui-react/dist/es/elements/Header'
export {
  default as HeaderContent,
} from 'semantic-ui-react/dist/es/elements/Header/HeaderContent'
export {
  default as HeaderSubheader,
} from 'semantic-ui-react/dist/es/elements/Header/HeaderSubheader'

export { default as Icon } from 'semantic-ui-react/dist/es/elements/Icon'
// export { default as IconGroup } from 'semantic-ui-react/dist/es/elements/Icon/IconGroup'

export { default as Image } from 'semantic-ui-react/dist/es/elements/Image'
// export { default as ImageGroup } from 'semantic-ui-react/dist/es/elements/Image/ImageGroup'

export { default as Input } from 'semantic-ui-react/dist/es/elements/Input'

export { default as Label } from 'semantic-ui-react/dist/es/elements/Label'
// export { default as LabelDetail } from 'semantic-ui-react/dist/es/elements/Label/LabelDetail'
// export { default as LabelGroup } from 'semantic-ui-react/dist/es/elements/Label/LabelGroup'

export { default as List } from 'semantic-ui-react/dist/es/elements/List'
// export { default as ListContent } from 'semantic-ui-react/dist/es/elements/List/ListContent'
// export { default as ListDescription } from 'semantic-ui-react/dist/es/elements/List/ListDescription'
// export { default as ListHeader } from 'semantic-ui-react/dist/es/elements/List/ListHeader'
// export { default as ListIcon } from 'semantic-ui-react/dist/es/elements/List/ListIcon'
// export { default as ListItem } from 'semantic-ui-react/dist/es/elements/List/ListItem'
// export { default as ListList } from 'semantic-ui-react/dist/es/elements/List/ListList'

export { default as Loader } from 'semantic-ui-react/dist/es/elements/Loader'

export { default as Rail } from 'semantic-ui-react/dist/es/elements/Rail'

export { default as Reveal } from 'semantic-ui-react/dist/es/elements/Reveal'
export {
  default as RevealContent,
} from 'semantic-ui-react/dist/es/elements/Reveal/RevealContent'

export { default as Segment } from 'semantic-ui-react/dist/es/elements/Segment'
export {
  default as SegmentGroup,
} from 'semantic-ui-react/dist/es/elements/Segment/SegmentGroup'

// export { default as Step } from 'semantic-ui-react/dist/es/elements/Step'
// export { default as StepContent } from 'semantic-ui-react/dist/es/elements/Step/StepContent'
// export { default as StepDescription } from 'semantic-ui-react/dist/es/elements/Step/StepDescription'
// export { default as StepGroup } from 'semantic-ui-react/dist/es/elements/Step/StepGroup'
// export { default as StepTitle } from 'semantic-ui-react/dist/es/elements/Step/StepTitle'

// Modules
export {
  default as Accordion,
} from 'semantic-ui-react/dist/es/modules/Accordion/Accordion'
export {
  default as AccordionAccordion,
} from 'semantic-ui-react/dist/es/modules/Accordion/AccordionAccordion'
export {
  default as AccordionContent,
} from 'semantic-ui-react/dist/es/modules/Accordion/AccordionContent'
export {
  default as AccordionTitle,
} from 'semantic-ui-react/dist/es/modules/Accordion/AccordionTitle'

export { default as Checkbox } from 'semantic-ui-react/dist/es/modules/Checkbox'

export { default as Dimmer } from 'semantic-ui-react/dist/es/modules/Dimmer'
export {
  default as DimmerDimmable,
} from 'semantic-ui-react/dist/es/modules/Dimmer/DimmerDimmable'

export { default as Dropdown } from 'semantic-ui-react/dist/es/modules/Dropdown'
export {
  default as DropdownDivider,
} from 'semantic-ui-react/dist/es/modules/Dropdown/DropdownDivider'
export {
  default as DropdownHeader,
} from 'semantic-ui-react/dist/es/modules/Dropdown/DropdownHeader'
export {
  default as DropdownItem,
} from 'semantic-ui-react/dist/es/modules/Dropdown/DropdownItem'
export {
  default as DropdownMenu,
} from 'semantic-ui-react/dist/es/modules/Dropdown/DropdownMenu'
// export { default as DropdownSearchInput } from 'semantic-ui-react/dist/es/modules/Dropdown/DropdownSearchInput'

// export { default as Embed } from 'semantic-ui-react/dist/es/modules/Embed'

export { default as Modal } from 'semantic-ui-react/dist/es/modules/Modal'
export {
  default as ModalActions,
} from 'semantic-ui-react/dist/es/modules/Modal/ModalActions'
export {
  default as ModalContent,
} from 'semantic-ui-react/dist/es/modules/Modal/ModalContent'
export {
  default as ModalDescription,
} from 'semantic-ui-react/dist/es/modules/Modal/ModalDescription'
export {
  default as ModalHeader,
} from 'semantic-ui-react/dist/es/modules/Modal/ModalHeader'

// NOTE (jscheel): We have to override the default semantic popup, because it
// does not handle scrolling and positioning for mountNodes properly.
export { default as Popup } from './Popup'
export {
  default as PopupContent,
} from 'semantic-ui-react/dist/es/modules/Popup/PopupContent'
// export { default as PopupHeader } from 'semantic-ui-react/dist/es/modules/Popup/PopupHeader'

export { default as Progress } from 'semantic-ui-react/dist/es/modules/Progress'

// export { default as Rating } from 'semantic-ui-react/dist/es/modules/Rating'
// export { default as RatingIcon } from 'semantic-ui-react/dist/es/modules/Rating/RatingIcon'

// export { default as Search } from 'semantic-ui-react/dist/es/modules/Search'
// export { default as SearchCategory } from 'semantic-ui-react/dist/es/modules/Search/SearchCategory'
// export { default as SearchResult } from 'semantic-ui-react/dist/es/modules/Search/SearchResult'
// export { default as SearchResults } from 'semantic-ui-react/dist/es/modules/Search/SearchResults'

/* export { default as Sidebar } from 'semantic-ui-react/dist/es/modules/Sidebar'
export {
  default as SidebarPushable,
} from 'semantic-ui-react/dist/es/modules/Sidebar/SidebarPushable'
export {
  default as SidebarPusher,
} from 'semantic-ui-react/dist/es/modules/Sidebar/SidebarPusher'
*/

export { default as Sticky } from 'semantic-ui-react/dist/es/modules/Sticky'

export { default as Tab } from 'semantic-ui-react/dist/es/modules/Tab'
export {
  default as TabPane,
} from 'semantic-ui-react/dist/es/modules/Tab/TabPane'

export {
  default as Transition,
} from 'semantic-ui-react/dist/es/modules/Transition'
// export { default as TransitionGroup } from 'semantic-ui-react/dist/es/modules/Transition/TransitionGroup'

// Views
// export { default as Advertisement } from 'semantic-ui-react/dist/es/views/Advertisement'

export { default as Card } from 'semantic-ui-react/dist/es/views/Card/Card'
export {
  default as CardContent,
} from 'semantic-ui-react/dist/es/views/Card/CardContent'
// export { default as CardDescription } from 'semantic-ui-react/dist/es/views/Card/CardDescription'
export {
  default as CardGroup,
} from 'semantic-ui-react/dist/es/views/Card/CardGroup'
export {
  default as CardHeader,
} from 'semantic-ui-react/dist/es/views/Card/CardHeader'
export {
  default as CardMeta,
} from 'semantic-ui-react/dist/es/views/Card/CardMeta'

// export { default as Comment } from 'semantic-ui-react/dist/es/views/Comment'
// export { default as CommentAction } from 'semantic-ui-react/dist/es/views/Comment/CommentAction'
// export { default as CommentActions } from 'semantic-ui-react/dist/es/views/Comment/CommentActions'
// export { default as CommentAuthor } from 'semantic-ui-react/dist/es/views/Comment/CommentAuthor'
// export { default as CommentAvatar } from 'semantic-ui-react/dist/es/views/Comment/CommentAvatar'
// export { default as CommentContent } from 'semantic-ui-react/dist/es/views/Comment/CommentContent'
// export { default as CommentGroup } from 'semantic-ui-react/dist/es/views/Comment/CommentGroup'
// export { default as CommentMetadata } from 'semantic-ui-react/dist/es/views/Comment/CommentMetadata'
// export { default as CommentText } from 'semantic-ui-react/dist/es/views/Comment/CommentText'

export { default as Feed } from 'semantic-ui-react/dist/es/views/Feed'
export {
  default as FeedContent,
} from 'semantic-ui-react/dist/es/views/Feed/FeedContent'
export {
  default as FeedDate,
} from 'semantic-ui-react/dist/es/views/Feed/FeedDate'
export {
  default as FeedEvent,
} from 'semantic-ui-react/dist/es/views/Feed/FeedEvent'
// export { default as FeedExtra } from 'semantic-ui-react/dist/es/views/Feed/FeedExtra'
// export { default as FeedLabel } from 'semantic-ui-react/dist/es/views/Feed/FeedLabel'
export {
  default as FeedLike,
} from 'semantic-ui-react/dist/es/views/Feed/FeedLike'
export {
  default as FeedMeta,
} from 'semantic-ui-react/dist/es/views/Feed/FeedMeta'
export {
  default as FeedSummary,
} from 'semantic-ui-react/dist/es/views/Feed/FeedSummary'
export {
  default as FeedUser,
} from 'semantic-ui-react/dist/es/views/Feed/FeedUser'

export { default as Item } from 'semantic-ui-react/dist/es/views/Item'
export {
  default as ItemContent,
} from 'semantic-ui-react/dist/es/views/Item/ItemContent'
export {
  default as ItemDescription,
} from 'semantic-ui-react/dist/es/views/Item/ItemDescription'
export {
  default as ItemExtra,
} from 'semantic-ui-react/dist/es/views/Item/ItemExtra'
export {
  default as ItemGroup,
} from 'semantic-ui-react/dist/es/views/Item/ItemGroup'
export {
  default as ItemHeader,
} from 'semantic-ui-react/dist/es/views/Item/ItemHeader'
export {
  default as ItemImage,
} from 'semantic-ui-react/dist/es/views/Item/ItemImage'
export {
  default as ItemMeta,
} from 'semantic-ui-react/dist/es/views/Item/ItemMeta'
export {
  default as Pagination,
} from 'semantic-ui-react/dist/es/addons/Pagination'

// export { default as Statistic } from 'semantic-ui-react/dist/es/views/Statistic'
// export { default as StatisticGroup } from 'semantic-ui-react/dist/es/views/Statistic/StatisticGroup'
// export { default as StatisticLabel } from 'semantic-ui-react/dist/es/views/Statistic/StatisticLabel'
// export { default as StatisticValue } from 'semantic-ui-react/dist/es/views/Statistic/StatisticValue'
