/* eslint-disable no-param-reassign */
import { createActionTypeReducer } from 'util/reducers'

import {
  ACCEPT_INVITATION_REQUEST,
  ACCEPT_INVITATION_SUCCESS,
  ACCEPT_INVITATION_FAILURE,
  GET_INVITATION_FAILURE,
  GET_INVITATION_SUCCESS,
  GET_INVITATION_REQUEST,
} from 'constants/action_types'
import { ACCEPT_INVITE_PAGE } from '../pages'

const defaultState = {
  isAccepting: false,
  hasError: false,
  acceptError: null,
  invitationFound: false,
  isLoading: true,
}

export default createActionTypeReducer(
  {
    [ACCEPT_INVITE_PAGE]: (
      draftState,
      { payload: { invitationToken } = {} }
    ) => {
      draftState.invitationToken = invitationToken
      return draftState
    },
    [ACCEPT_INVITATION_REQUEST]: draftState => {
      draftState.isAccepting = true
      draftState.hasError = false
      draftState.acceptError = null

      return draftState
    },
    [ACCEPT_INVITATION_SUCCESS]: draftState => {
      draftState.isAccepting = false
      draftState.hasError = false

      return draftState
    },
    [ACCEPT_INVITATION_FAILURE]: (draftState, { payload: { error } = {} }) => {
      draftState.isAccepting = false
      draftState.hasError = true
      draftState.acceptError = error

      return draftState
    },
    [GET_INVITATION_REQUEST]: draftState => {
      draftState.isLoading = true

      return draftState
    },
    [GET_INVITATION_FAILURE]: draftState => {
      draftState.invitationFound = false
      draftState.isLoading = false

      return draftState
    },
    [GET_INVITATION_SUCCESS]: (
      draftState,
      { payload: { response, currentPageSubdomain } = {} }
    ) => {
      const { email, subdomain } = response || {}

      const hasResponse = !!email && !!subdomain
      const isForCurrentSubdomain =
        !currentPageSubdomain || currentPageSubdomain === subdomain

      draftState.invitationFound = hasResponse && isForCurrentSubdomain
      draftState.isLoading = false

      return draftState
    },
  },
  defaultState
)
