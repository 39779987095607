import { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

import { doUpdateSlackAppV0 } from 'ducks/integrations/slack/operations'
import { selectAgents } from 'selectors/agents/base'
import {
  selectMatchedProfilesByAgentGid,
  selectProfilesBySlackId,
} from 'ducks/integrations/slack/selectors'
import { convertGidToRaw } from 'util/scatterSwap'
import { useRhfDirtyHold } from 'util/dirtyHolds'

import DynamicPortal from 'components/DynamicPortal'
import AgentsTable from './AgentsTable'

export const FORM_ID = 'slack-configure-form'

const SlackConfigureForm = ({
  formId,
  actionsPortalRef,
  actionsComponent: ActionsComponent,
  actionsComponentAdditionalProps,
  onClose,
}) => {
  const agents = useSelector(selectAgents)
  const profilesBySlackId = useSelector(selectProfilesBySlackId)
  const matchedProfilesByAgentGid = useSelector(selectMatchedProfilesByAgentGid)

  const initialData = useMemo(
    () => {
      return agents.reduce((memo, agent) => {
        const agentGid = `ag_${agent.id}`
        const matchedProfile = matchedProfilesByAgentGid[agentGid]
        // eslint-disable-next-line no-param-reassign
        memo[agentGid] = matchedProfile?.slack_id || null
        return memo
      }, {})
    },
    [agents, matchedProfilesByAgentGid]
  )

  const { handleSubmit, control, reset } = useForm({
    mode: 'all',
    defaultValues: initialData,
    delayError: 300,
  })

  const { releaseHold } = useRhfDirtyHold(formId, control)
  const dispatch = useDispatch()

  const onSubmit = useCallback(
    async data => {
      const dataSlackIds = new Set(Object.values(data))
      const payload = Object.keys(data).reduce((memo, agentGid) => {
        const slackId = data[agentGid]
        // NOTE (jscheel): This includes all profile mappings, including ones
        // that do not yet have agents assigned to them.
        const profile = profilesBySlackId[slackId]
        if (!slackId) {
          const matchedProfile = matchedProfilesByAgentGid[agentGid]
          // NOTE (jscheel): We only want to delete if not re-assigned elsewhere
          if (matchedProfile && !dataSlackIds.has(matchedProfile.slack_id)) {
            // deleted
            memo.push({ id: matchedProfile.id, _destroy: true })
          }
        } else if (slackId && profile?.id && profile?.agent_gid !== agentGid) {
          // changed
          memo.push({
            id: profile.id,
            slack_id: slackId,
            agent_id: profile.agent_id,
          })
        } else if (slackId && !profile?.id) {
          // added
          memo.push({
            slack_id: slackId,
            agent_id: convertGidToRaw(agentGid),
            username: profile.username,
          })
        }
        return memo
      }, [])

      await dispatch(doUpdateSlackAppV0({ profiles: payload }))
      releaseHold()
      reset(data)
      onClose()
    },
    [
      profilesBySlackId,
      matchedProfilesByAgentGid,
      dispatch,
      releaseHold,
      reset,
      onClose,
    ]
  )

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)}>
      <AgentsTable control={control} />
      <DynamicPortal portalRef={actionsPortalRef}>
        <ActionsComponent
          {...actionsComponentAdditionalProps}
          control={control}
          formId={formId}
        />
      </DynamicPortal>
    </form>
  )
}

export default SlackConfigureForm
