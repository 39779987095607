import styled from '@emotion/styled'

import themeVars from 'ui_theme/site/globals/site.variables'

import CheckboxColumn from './CheckboxColumn'
import LabelColumn from './LabelColumn'

export default styled('div')`
  line-height: ${props => props.theme.spacing['11']};
  margin-bottom: ${props => props.theme.spacing['7']};

  ${CheckboxColumn} & {
    text-align: right;
  }

  ${LabelColumn} & {
    line-height: ${props => props.theme.spacing['11']};
  }

  > *,
  .sui & > .ui.checkbox {
    vertical-align: middle;
  }

  .sui & > .ui.checkbox {
    margin-right: ${props => props.theme.spacing['5']};
  }

  & .label {
    margin-bottom: ${themeVars.relativeSmall};
  }
`
