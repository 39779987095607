import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useDataTable } from 'ducks/tables/hooks'
import Table from '@groovehq/internal-design-system/lib/components/Table/Table'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import { doFetchCannedReplyCategoriesInMemory } from 'ducks/cannedReplies/operations'
import { SETTINGS_CANNED_REPLY_CATEGORY_TABLE_ID } from 'ducks/tables/ids'
import {
  DRAWER_TYPE_CANNED_REPLY_CATEGORIES_DELETE,
  DRAWER_TYPE_CANNED_REPLY_CATEGORIES_UPDATE,
} from 'ducks/drawers/types'
import { useDrawer } from 'ducks/drawers/hooks'
import { buildDrawerQueryParam } from 'ducks/drawers/util'
import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'
import useColumns from './useColumns'
import { styles } from './styles'

const CannedReplyCategoryDataTable = ({ onCannedReplyCategoryClick }) => {
  const {
    pagination: {
      currentDenormalizedEntities: cannedReplyCategories,
      gotoPage,
      changePageSize,
    },
    pagination,
    filtering: { changeFilterDelayed, changeFilter },
    sorting: { changeSort },
    queryId,
    sorting,
    isLoading,
    shouldLoad,
    isError,
    scroll,
  } = useDataTable(
    SETTINGS_CANNED_REPLY_CATEGORY_TABLE_ID,
    'cannedReplyCategory',
    doFetchCannedReplyCategoriesInMemory,
    {
      pagination: {
        defaultPageSize: 20,
        defaultCursor: 1,
        showSizeChanger: true,
        pageSizeOptions: [10, 20, 30],
        filterDelay: 1000,
        parseToIntKeys: ['cursor'],
      },
      scroll: {
        persistScrollPosition: true,
      },
    }
  )

  const currentUser = useSelector(selectCurrentUser)
  const columns = useColumns({ currentUser, onCannedReplyCategoryClick })

  const handleOnTableStateChange = useCallback(
    (newState, action = {}) => {
      const { type } = action || {}
      if (type === 'gotoPage') {
        const { pageIndex } = action
        gotoPage(pageIndex + 1)
      } else if (type === 'setPageSize') {
        const { pageSize } = action
        changePageSize(pageSize)
      } else if (type === 'toggleSortBy' || type === 'setSortBy') {
        const { id, desc } = newState.sortBy[0] || {}
        changeSort(id ? `${id}_${desc ? 'DESC' : 'ASC'}` : null)
      } else if (type === 'setGlobalFilter') {
        const { filterValue } = action
        // When setting filter, debounce for 1 second, but when removing a filter
        // trigger that immediately
        if (filterValue) {
          changeFilterDelayed('search', filterValue, { resetPage: true })
        } else {
          changeFilter('search', filterValue, { resetPage: true })
        }
      }
    },
    [changeSort, changeFilterDelayed, changeFilter, gotoPage, changePageSize]
  )

  const { drawerId: deleteDrawerId, openDrawer: openDeleteDrawer } = useDrawer(
    DRAWER_TYPE_CANNED_REPLY_CATEGORIES_DELETE
  )

  const { openDrawer: openEditDrawer } = useDrawer(
    DRAWER_TYPE_CANNED_REPLY_CATEGORIES_UPDATE
  )

  const handleCreate = useCallback(
    () => {
      openEditDrawer('new')
    },
    [openEditDrawer]
  )

  const handleDelete = useCallback(
    id => {
      openDeleteDrawer(id, {
        query: {
          ...buildDrawerQueryParam(deleteDrawerId, 'drawerDeleteMode', 'ids'),
        },
        additionalProps: {
          queryId,
        },
      })
    },
    [openDeleteDrawer, deleteDrawerId, queryId]
  )

  const handleEdit = useCallback(
    id => {
      openEditDrawer(id, {
        additionalProps: {
          queryId,
        },
      })
    },
    [openEditDrawer, queryId]
  )

  const emptyContent = (
    <Table.Empty
      title="There are no categories"
      subtitle={`You haven't created any categories for ${app.t(
        'canned_replies'
      )} on your account yet.`}
    >
      <Button onClick={handleCreate}>Create new category</Button>
    </Table.Empty>
  )

  return (
    <Table
      separate
      columns={columns}
      sorting={sorting}
      css={styles.table}
      data={cannedReplyCategories}
      showPagination
      pagination={pagination}
      onTableStateChange={handleOnTableStateChange}
      emptyContent={emptyContent}
      loading={shouldLoad || !!isLoading}
      emptyHintVisible={false}
      hasError={isError}
      updateData={handleEdit}
      deleteData={handleDelete}
      onEdit={handleEdit}
      dataType="canned replies"
      scroll={scroll}
    />
  )
}

CannedReplyCategoryDataTable.displayName = 'CannedReplyCategoryDataTable'

export default CannedReplyCategoryDataTable
