import React from 'react'
import {
  list,
  text,
} from '@groovehq/internal-design-system/lib/styles/elements'
import { useSelector } from 'react-redux'
import { selectUsage } from 'ducks/billing/selectors/usage/selectUsage'
import {
  USAGE_INBOX_API_ACCESS,
  USAGE_INBOX_BRANDING,
  USAGE_INBOX_CANNED_REPLIES,
  USAGE_INBOX_CANNED_REPLIES_TIER_1,
  USAGE_INBOX_CHANNELS,
  USAGE_INBOX_CUSTOM_FIELDS,
  USAGE_INBOX_CUSTOM_PROFILE,
  USAGE_INBOX_FOLDERS,
  USAGE_INBOX_INTEGRATIONS,
  USAGE_INBOX_MAILBOXES,
  USAGE_INBOX_RULES,
  USAGE_INBOX_RULES_TIER_1,
  USAGE_INBOX_RULES_TIER_2,
  USAGE_INBOX_SATISFACTIONS_RATINGS,
  USAGE_INBOX_TEAMS,
  USAGE_INBOX_USERS,
  USAGE_INBOX_WIDGETS,
  USAGE_KB_ARTICLES,
  USAGE_KB_BRANDING,
  USAGE_KB_CUSTOM_SCRIPTS,
  USAGE_KB_KBS,
  USAGE_KB_PRIVATE,
  USAGE_KB_SEO,
  USAGE_INBOX_VIEWERS,
  USAGE_INBOX_PREMIUM_INTEGRATIONS,
} from 'ducks/billing/usageTypes'
import {
  FEATURE_INBOX_API_ACCESS,
  FEATURE_INBOX_BRANDING,
  FEATURE_INBOX_CANNED_REPLIES_TIER_1,
  FEATURE_INBOX_CRM_FIELDS,
  FEATURE_INBOX_CUSTOM_PROFILE,
  FEATURE_INBOX_MAX_CANNED_REPLIES,
  FEATURE_INBOX_MAX_CHANNELS,
  FEATURE_INBOX_MAX_FOLDERS,
  FEATURE_INBOX_MAX_INTEGRATIONS,
  FEATURE_INBOX_MAX_MAILBOXES,
  FEATURE_INBOX_MAX_RULES,
  FEATURE_INBOX_MAX_USERS,
  FEATURE_INBOX_MAX_VIEWER_USERS,
  FEATURE_INBOX_MAX_WIDGETS,
  FEATURE_INBOX_PREMIUM_INTEGRATIONS,
  FEATURE_INBOX_RULES_TIER_1,
  FEATURE_INBOX_RULES_TIER_2,
  FEATURE_INBOX_SATISFACTIONS_RATINGS,
  FEATURE_INBOX_TEAMS,
  FEATURE_KB_BRANDING,
  FEATURE_KB_CUSTOM_SCRIPTS,
  FEATURE_KB_MAX_ARTICLES,
  FEATURE_KB_MAX_KBS,
  FEATURE_KB_PRIVATE,
  FEATURE_KB_SEO,
} from 'ducks/billing/featureTypes'
import { checkNumberLimit, checkBooleanLimit } from 'util/usageLimit'
import Feature from './Feature'
import { styles } from '../styles'

export default function Features({ className, ...rest }) {
  const usage = useSelector(selectUsage)

  return (
    <>
      <div css={[text.styles.textSm, text.styles.fontMedium]}>
        {app.t('Mailbox')}
      </div>
      <ul css={[list.styles.preflight, styles.features]} className={className}>
        {checkNumberLimit(
          usage[USAGE_INBOX_CHANNELS],
          rest[FEATURE_INBOX_MAX_CHANNELS]
        ) && (
          <Feature>
            Remove channels (Limit of {rest[FEATURE_INBOX_MAX_CHANNELS]},{' '}
            {usage[USAGE_INBOX_CHANNELS]} in use)
          </Feature>
        )}
        {checkNumberLimit(
          usage[USAGE_INBOX_MAILBOXES],
          rest[FEATURE_INBOX_MAX_MAILBOXES]
        ) && (
          <Feature>
            Remove {app.t('Mailboxes')} (Limit of{' '}
            {rest[FEATURE_INBOX_MAX_MAILBOXES]}, {usage[USAGE_INBOX_MAILBOXES]}{' '}
            in use)
          </Feature>
        )}
        {checkNumberLimit(
          usage[USAGE_INBOX_INTEGRATIONS],
          rest[FEATURE_INBOX_MAX_INTEGRATIONS]
        ) && (
          <Feature>
            Remove integrations (Limit of {rest[FEATURE_INBOX_MAX_INTEGRATIONS]},{' '}
            {usage[USAGE_INBOX_INTEGRATIONS]} in use)
          </Feature>
        )}
        {checkBooleanLimit(
          usage[USAGE_INBOX_PREMIUM_INTEGRATIONS],
          rest[FEATURE_INBOX_PREMIUM_INTEGRATIONS]
        ) && <Feature>Remove premium integrations</Feature>}
        {checkNumberLimit(
          usage[USAGE_INBOX_USERS],
          rest[FEATURE_INBOX_MAX_USERS]
        ) && (
          <Feature>
            Remove {app.t('agents')} (Limit of {rest[FEATURE_INBOX_MAX_USERS]},{' '}
            {usage[USAGE_INBOX_USERS]} in{` `}use)
          </Feature>
        )}
        {checkBooleanLimit(
          usage[USAGE_INBOX_TEAMS] > 0,
          rest[FEATURE_INBOX_TEAMS]
        ) && (
          <Feature>
            Remove {app.t('Teams')} ({usage[USAGE_INBOX_TEAMS]} {app.t('teams')}{' '}
            in use)
          </Feature>
        )}
        {checkNumberLimit(
          usage[USAGE_INBOX_CANNED_REPLIES],
          rest[FEATURE_INBOX_MAX_CANNED_REPLIES]
        ) && (
          <Feature>
            Remove {app.t('canned_replies')} (Limit of{' '}
            {rest[FEATURE_INBOX_MAX_CANNED_REPLIES]},{' '}
            {usage[USAGE_INBOX_CANNED_REPLIES]} in{` `}use)
          </Feature>
        )}
        {checkBooleanLimit(
          usage[USAGE_INBOX_CANNED_REPLIES_TIER_1],
          rest[FEATURE_INBOX_CANNED_REPLIES_TIER_1]
        ) && <Feature>Remove advanced {app.t('canned_replies')}</Feature>}
        {checkNumberLimit(
          usage[USAGE_INBOX_RULES],
          rest[FEATURE_INBOX_MAX_RULES]
        ) && (
          <Feature>
            Remove rules (Limit of {rest[FEATURE_INBOX_MAX_RULES]},{' '}
            {usage[USAGE_INBOX_RULES]} in use)
          </Feature>
        )}
        {checkBooleanLimit(
          usage[USAGE_INBOX_RULES_TIER_1] > 0,
          rest[FEATURE_INBOX_RULES_TIER_1]
        ) && <Feature>Remove advanced rules</Feature>}
        {checkBooleanLimit(
          usage[USAGE_INBOX_RULES_TIER_2] > 0,
          rest[FEATURE_INBOX_RULES_TIER_2]
        ) && <Feature>Remove time based rules</Feature>}
        {checkNumberLimit(
          usage[USAGE_INBOX_FOLDERS],
          rest[FEATURE_INBOX_MAX_FOLDERS]
        ) && (
          <Feature>
            Remove folders (Limit of {rest[FEATURE_INBOX_MAX_FOLDERS]},{' '}
            {usage.folders} in use)
          </Feature>
        )}
        {checkNumberLimit(
          usage[USAGE_INBOX_WIDGETS],
          rest[FEATURE_INBOX_MAX_WIDGETS]
        ) && (
          <Feature>
            Remove widgets (Limit of {rest[FEATURE_INBOX_MAX_WIDGETS]},{' '}
            {usage[USAGE_INBOX_WIDGETS]} in use)
          </Feature>
        )}
        {checkBooleanLimit(
          usage[USAGE_INBOX_CUSTOM_FIELDS],
          rest[FEATURE_INBOX_CRM_FIELDS]
        ) && <Feature>Remove Custom fields</Feature>}
        {checkBooleanLimit(
          usage[USAGE_INBOX_SATISFACTIONS_RATINGS],
          rest[FEATURE_INBOX_SATISFACTIONS_RATINGS]
        ) && <Feature>Disable Satisfaction ratings</Feature>}
        {checkBooleanLimit(
          usage[USAGE_INBOX_API_ACCESS],
          rest[FEATURE_INBOX_API_ACCESS]
        ) && <Feature>Disable API</Feature>}
        {checkBooleanLimit(
          usage[USAGE_INBOX_CUSTOM_PROFILE],
          rest[FEATURE_INBOX_CUSTOM_PROFILE]
        ) && <Feature>Disable Custom profiles</Feature>}
        {checkBooleanLimit(
          usage[USAGE_INBOX_BRANDING],
          rest[FEATURE_INBOX_BRANDING]
        ) && <Feature>Enable Groove branding for inbox</Feature>}
        {checkNumberLimit(usage[USAGE_KB_KBS], rest[FEATURE_KB_MAX_KBS]) && (
          <Feature>
            Remove Knowledge Bases (Limit of {rest[FEATURE_KB_MAX_KBS]},{' '}
            {usage.kbs} in{` `}use)
          </Feature>
        )}
        {checkNumberLimit(
          usage[USAGE_KB_ARTICLES],
          rest[FEATURE_KB_MAX_ARTICLES]
        ) && (
          <Feature>
            Remove articles (Limit of {rest[FEATURE_KB_MAX_ARTICLES]},{' '}
            {usage.articles} in{` `}use)
          </Feature>
        )}
        {checkBooleanLimit(
          usage[USAGE_KB_CUSTOM_SCRIPTS],
          rest[FEATURE_KB_CUSTOM_SCRIPTS]
        ) && <Feature>Disable Custom Scripts</Feature>}
        {checkBooleanLimit(usage[USAGE_KB_SEO], rest[FEATURE_KB_SEO]) && (
          <Feature>Disable SEO and Open Graph settings</Feature>
        )}
        {checkBooleanLimit(
          usage[USAGE_KB_PRIVATE],
          rest[FEATURE_KB_PRIVATE]
        ) && <Feature>Disable Access Control</Feature>}
        {checkBooleanLimit(
          usage[USAGE_KB_BRANDING],
          rest[FEATURE_KB_BRANDING]
        ) && <Feature>Enable Groove branding for kbs</Feature>}
        {checkNumberLimit(
          usage[USAGE_INBOX_VIEWERS],
          rest[FEATURE_INBOX_MAX_VIEWER_USERS]
        ) && (
          <Feature>
            Remove lite users (Limit of {rest[FEATURE_INBOX_MAX_VIEWER_USERS]},{' '}
            {usage[USAGE_INBOX_VIEWERS]} in{` `}use)
          </Feature>
        )}
      </ul>
    </>
  )
}
