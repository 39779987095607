import logo from 'assets/integrations/salesforce/icon-96.png'

import actionsScreenShot from 'assets/integrations/salesforce/screenshot-actions.jpg'
import linkScreenShot from 'assets/integrations/salesforce/screenshot-link.jpg'
import Carousel from 'components/Carousel'

import { Header } from 'shared/components/ui/Typography'

import {
  InstallLogo,
  InstallModalHead,
  InstallScreenShot,
  SectionLabel,
} from './styled'

export default function Description({ showPlanLimitation }) {
  return (
    <div>
      <InstallModalHead>
        <InstallLogo alt="HubSpot logo" src={logo} />
        <Header as="h2" size="small">
          Salesforce
          {showPlanLimitation && <span className="grui ml-3">⚡</span>}
        </Header>
        <p>
          Pull in Salesforce lead, contact, and account information right into
          your {app.t('Mailbox')} sidebar, and create a new lead for new email
          addresses.
        </p>
      </InstallModalHead>

      <Carousel width={392} height={244}>
        <Carousel.Item>
          <InstallScreenShot
            alt="A screen shot of the Salesforce actions in Groove"
            src={actionsScreenShot}
          />
        </Carousel.Item>
        <Carousel.Item>
          <InstallScreenShot
            alt="A screen shot of adding a Lead in Groove"
            src={linkScreenShot}
          />
        </Carousel.Item>
      </Carousel>

      <SectionLabel size="medium" as="h4">
        How it works
      </SectionLabel>

      <p>
        With the Salesforce integration you can pull in additional information
        from Salesforce straight into your {app.t('Mailbox')} sidebar. This
        provides you with additional context right there where it&apos;s most
        useful.
      </p>
      <p>
        Salesforce is searched for a matching email address, and will display
        the following information::
      </p>
      <ul>
        <li>Lead information</li>
        <li>Contact information</li>
        <li>Account information</li>
        <li>Recent tasks</li>
        <li>Recent opportunities</li>
      </ul>
      <p>
        If no contact is found you can create a new lead right from the sidebar.
      </p>
    </div>
  )
}
