import React, { useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Drawer from '@groovehq/internal-design-system/lib/components/Drawer/UnmanagedDrawer'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'

import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import { selectCurrentCannedReplyCategories } from 'ducks/cannedReplies/selectors'
import {
  doChangeCategoryForCannedReplies,
  doFetchCannedReplyCategoriesV1,
} from 'ducks/cannedReplies/operations'
import { DRAWER_TYPE_CANNED_REPLY_CATEGORIES_UPDATE } from 'ducks/drawers/types'
import { useDrawer } from 'ducks/drawers/hooks'
import { selectSearchByQueryId } from 'ducks/searches/selectors'

import CategorySelection from '../../CannedReplyCategorySelection'
import { styles } from './styles'

const CannedReplyChangeCategoryDrawer = ({
  drawerResourceId: cannedReplyIdCsv = '',
  drawerChangeCategoryMode,
  drawerSetCategoryId,
  queryId,
  open,
  onClose,
  onExit,
  onChange,
}) => {
  const dispatch = useDispatch()
  const categories = useSelector(selectCurrentCannedReplyCategories)
  const [categoryId, setCategoryId] = useState(null)
  const ids = cannedReplyIdCsv.split(',')
  const isIdUpdate = drawerChangeCategoryMode === 'ids'
  const { totalCount: searchTotal } = useSelector(state =>
    selectSearchByQueryId(state, queryId)
  )
  const totalToBeUpdated = isIdUpdate ? ids.length : searchTotal

  useEffect(
    () => {
      // need to check drawer open state before fetch dispatches
      // otherwise the dispatch methods in useEffect below will run immediately
      // after this Drawer component is instantiated via `useDrawer` (before openDrawer is even called)
      // Which is unnecessary double dispatch triggering
      if (!open) return

      dispatch(
        doFetchCannedReplyCategoriesV1({
          skipLoaded: true,
        })
      )
    },
    [dispatch, open]
  )

  useEffect(
    () => {
      // if this drawerSetCategoryId is defined by a previous drawer (+ Create Category in this case),
      // set as the category Id to be selected
      if (!drawerSetCategoryId) return

      setCategoryId(drawerSetCategoryId)
    },
    [drawerSetCategoryId]
  )

  const { openDrawer: openEditDrawer } = useDrawer(
    DRAWER_TYPE_CANNED_REPLY_CATEGORIES_UPDATE
  )

  const handleOnCreateCategory = useCallback(
    () => {
      openEditDrawer('new', {
        additionalProps: {
          queryId,
        },
      })
    },
    [openEditDrawer, queryId]
  )

  const handleSave = useCallback(
    () => {
      dispatch(
        doChangeCategoryForCannedReplies({
          ids,
          queryId,
          mode: drawerChangeCategoryMode,
          newCategoryId: categoryId,
        })
      )
      if (onChange) onChange()
      onClose()
    },
    [
      dispatch,
      ids,
      queryId,
      drawerChangeCategoryMode,
      onChange,
      categoryId,
      onClose,
    ]
  )

  const footer = (
    <ModalBtns
      saveBtnText="Move"
      saveBtnDisabled={!categoryId}
      onSave={handleSave}
      tertiaryBtnText="Cancel"
      onClickTertiaryBtn={onClose}
    />
  )

  return (
    <Drawer title="Move category" open={open} footer={footer} onClose={onExit}>
      <div className="grui pt-10">
        <div>
          <div css={text.styles.textNormal} className="grui mb-10">
            {`You will move ${totalToBeUpdated} selected ${app.t(
              'canned_replies_plural',
              { count: totalToBeUpdated }
            )} into the following category:`}
          </div>
          <div css={styles.dropdownContainer}>
            <CategorySelection
              categories={categories}
              categoryId={categoryId}
              onSelectCategory={setCategoryId}
              css={styles.dropdownBtn}
              showCreate
              onCreateCategory={handleOnCreateCategory}
            />
          </div>
        </div>
      </div>
    </Drawer>
  )
}

export default CannedReplyChangeCategoryDrawer
