// eslint-disable-next-line import/no-extraneous-dependencies
import _extends from 'babel-runtime/helpers/extends'
// Heads Up!
//
// Do not replace this with named exports.
// We need to export an object here for browser builds.
// Otherwise, we end up with every component on the window. :poop:
import * as semanticUIReact from './components'

import TableDivider from './Table/Divider'

semanticUIReact.Table.Divider = TableDivider

module.exports = _extends({}, semanticUIReact)
