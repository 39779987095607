import { css } from '@emotion/react'

const dropdownWrapper = css`
  div[role='presentation'] {
    width: 100%;
  }
`

const dropdownContainer = css`
  max-height: 300px;
  overflow: auto;
`

const triggerButton = css`
  min-width: 100%;
`

export const styles = {
  dropdownWrapper,
  dropdownContainer,
  triggerButton,
}
