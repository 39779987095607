import ActionAccessWarning from '../../shared/ActionAccessWarning'

export default function TrelloInstallDescription() {
  return (
    <React.Fragment>
      <p>
        Quickly create new Trello cards directly from a conversation, and attach
        the conversation to either a new or an existing card for frictionless
        logging. When a card is updated in Trello you can receive the actions
        right in your conversation history, and you can even have all these
        actions trigger a re-open on the linked conversations. No more back and
        forth between Groove and Trello, and no more manually having to keep
        track of your cards!
      </p>

      <p>With this integration you can:</p>
      <ul>
        <li>Create a new card from the {app.t('Mailbox')}</li>
        <li>Attach conversations to new or existing cards</li>
        <li>View Trello card actions in your conversation history</li>
        <li>
          Have conversations re-opened when a Trello card has been updated
        </li>
        <li>View all linked cards and their details in the sidebar</li>
      </ul>
      <ActionAccessWarning />
    </React.Fragment>
  )
}
