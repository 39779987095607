export function isDemoMailbox(mailbox) {
  return (
    mailbox.name === `Demo ${app.t('Mailbox')}` && mailbox.type === 'LEGACY'
  )
}

// Unconfigured forwarding mailboxes and demo mailboxes
// Should not be shown in the onboarding wizard. Those
// mailboxes will be removed in the completed page
export function isAllowedOnOverview(mailbox) {
  if (
    isDemoMailbox(mailbox) ||
    (mailbox.name.startsWith('groove-onboarding') &&
      mailbox.type === 'FORWARDING')
  ) {
    return false
  }
  return true
}
