import { pick } from 'util/objects'
import { initials as agentInitials } from 'util/agents'
import { firstInitial } from 'ducks/customers/utils'

export const isSameActor = (target, actor) => {
  // need to make sure we aren't comparing undefined in all checks
  if (target.id && actor.id && target.id === actor.id) return true
  if (target.email && actor.email && target.email === actor.email) return true

  return false
}

const PROVIDER_HUMAN_STRING = {
  github: 'GitHub',
  atlassian_oauth2: 'Jira Cloud',
  trello: 'Trello',
}
export const getActorCurried = (agentsById, customersById = {}) => (
  actor = {}
) => {
  if (!actor) return actor
  switch (actor.type) {
    case 'Agent': {
      const agent = agentsById[actor.id]
      return agent || actor // return actor in case agent not found.
    }
    case 'Customer':
      return customersById[actor.id] || actor
    case 'Collaborator':
      return customersById[actor.id] || actor
    case 'Rule':
      return { name: 'Rule', type: 'Rule' }
    case 'OauthCredential': {
      const name = PROVIDER_HUMAN_STRING[actor.provider] || 'An integration'
      return {
        label: name,
        name,
        type: 'Integration',
        provider: actor.provider,
      }
    }
    default:
      // desperate, last second buzzer shot
      if (!actor.id) return actor
      return agentsById[actor.id] || customersById[actor.id] || actor
  }
}

// Old getActor API.
export const getActor = (actor = {}, agentsById, customersById = {}) =>
  getActorCurried(agentsById, customersById)(actor)

export const avatarURL = actor =>
  actor && actor.avatar_url && !actor.avatar_url.includes('missing')
    ? actor.avatar_url
    : null

export const initials = actor => {
  if (!actor) return ''
  if (actor.type === 'Agent') return agentInitials(actor)
  if (actor.agentFullName) return agentInitials(actor) // its an assignment...
  return firstInitial(actor)
}

export const reduceActor = actor => {
  const newActor = pick(
    ['id', 'type', 'email', 'name', 'avatar_url', 'provider', 'twitter'],
    actor
  )
  if (actor.avatar_url !== undefined) {
    newActor.avatarUrl = avatarURL(actor)
  }
  if (actor.name !== undefined) {
    newActor.initials = initials(actor)
  }
  return newActor
}
