import { connect } from 'react-redux'
import { selectBackButtonRouteAction } from 'selectors/app'
import BackButtonView from 'components/ConversationHeader/Buttons/BackButton'

const select = state => {
  return {
    to: selectBackButtonRouteAction(state) || '/',
  }
}

export default connect(select)(BackButtonView)
