import React from 'react'
import { Checkbox } from 'util/ui'
import { SettingRow } from './styled'

const PropertySection = ({ prefix, formFields, properties, onChange }) => {
  if (!properties) return null

  const keys = Object.keys(properties)
  const sorted = keys
    .filter(key => properties[key])
    .map(key => properties[key])
    .sort((a, b) => {
      return a.label > b.label ? 1 : -1
    })

  return (
    <React.Fragment>
      {sorted.map(property => {
        const key = [prefix, property.name].join('.')
        return (
          <SettingRow key={key}>
            <Checkbox
              checked={formFields[key]}
              name={key}
              value={property.label}
              onChange={onChange}
            />{' '}
            <span>{property.label}</span>
          </SettingRow>
        )
      })}
    </React.Fragment>
  )
}

export default PropertySection
