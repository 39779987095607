import { _processQueryStringPart, _splitQueryString } from './index'

export default function getSearchQueryPartAtPosition(
  queryString,
  selectionStart
) {
  const parts = _splitQueryString(queryString)
  let currentPosition = -1
  const partIndex =
    parts &&
    parts.findIndex(part => {
      const startPosition = currentPosition + 1
      const endPosition = startPosition + part.length
      if (selectionStart >= startPosition && selectionStart <= endPosition)
        return true
      currentPosition = endPosition
      return false
    })

  const part = parts && parts[partIndex]
  const partObj = part && _processQueryStringPart(part)
  const operator = partObj && partObj.key
  const value = partObj && partObj.values[0]
  return { operator, value, part }
}
