/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import TableWithCheckbox from '@groovehq/internal-design-system/lib/components/TableWithCheckbox/TableWithCheckbox'
import { useSelector } from 'react-redux'
import { selectGroupsSortedByName } from 'selectors/app/groups'

import useColumns from './useColumn'

// otherProps are for TableWithCheckbox
const TeamsSelectionTable = ({ onSelectedRowIdsChange, ...otherProps }) => {
  const groups = useSelector(selectGroupsSortedByName)
  const columns = useColumns()
  return (
    <TableWithCheckbox
      columns={columns}
      data={groups}
      onSelectedRowIdsChange={onSelectedRowIdsChange}
      {...otherProps}
    />
  )
}

export default TeamsSelectionTable
