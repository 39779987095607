import Queue from 'promise-queue'
import uuid from './uuid'

const queues = {}

export function createQueue(maxConcurrent = 2, maxQueue = Infinity, options) {
  const queueId = uuid()
  queues[queueId] = new Queue(maxConcurrent, maxQueue, options)
  return queueId
}

export function deleteQueue(queueId) {
  if (queues[queueId]) delete queues[queueId]
}

export function getQueue(queueId) {
  return queues[queueId]
}

export function queueify(queueId, func) {
  return (...args) => {
    const queue = getQueue(queueId)
    return queue.add(() => func.apply(this, args))
  }
}
