import React, { useMemo, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useFormState } from 'react-hook-form'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import MessageCard from '@groovehq/internal-design-system/lib/components/MessageCard/MessageCard'
import {
  text,
  heading,
  paragraph,
} from '@groovehq/internal-design-system/lib/styles/elements'

import { AdminAccessDrawer } from 'subapps/settings/components/drawers/NoAccess'

import { useSlackApp } from 'ducks/integrations/slack/hooks'
import { useDrawer } from 'ducks/drawers/hooks'
import { buildDrawerQueryParam } from 'ducks/drawers/util'
import {
  DRAWER_TYPE_INTEGRATION_SLACK_CHANNEL_UPDATE,
  DRAWER_TYPE_INTEGRATION_SLACK_CONFIGURE,
} from 'ducks/drawers/types'
import { useConfirmHoldsCallback } from 'util/dirtyHolds'

import SlackConfigureForm from '../Configure/Form'

const FORM_ID = 'slack-map-usernames'

const Actions = ({ formId, control, onSkip }) => {
  const { isValid, isDirty, isSubmitting } = useFormState({ control })

  return (
    <ModalBtns
      saveBtnText="Next step"
      saveBtnHtmlType="submit"
      saveBtnDisabled={!isValid || !isDirty || isSubmitting}
      saveBtnForm={formId}
      tertiaryBtnText="Skip for now"
      onClickTertiaryBtn={onSkip}
    />
  )
}

const SlackMapUsernamesDrawer = ({
  drawerResourceId,
  open,
  onClose,
  onExit,
  drawerId,
  queryId,
  previousDrawer,
  drawerPostIntegrationInstall,
  ...rest
}) => {
  const postInstall = drawerPostIntegrationInstall === '1'
  const dispatch = useDispatch()
  const handleOnExit = useConfirmHoldsCallback(null, onExit, [dispatch, onExit])
  const footerRef = useRef(null)

  const {
    drawerId: channelDrawerId,
    openDrawer: openChannelDrawer,
  } = useDrawer(DRAWER_TYPE_INTEGRATION_SLACK_CHANNEL_UPDATE)

  const { openDrawer: openConfigureDrawer } = useDrawer(
    DRAWER_TYPE_INTEGRATION_SLACK_CONFIGURE
  )

  const {
    slackApp,
    profiles,
    isLoading,
    isLoaded,
    hasError,
    isMissing,
  } = useSlackApp({ preferCached: false })

  const handleNext = useConfirmHoldsCallback(
    FORM_ID,
    () => {
      if (slackApp?.channels?.length > 0) {
        openConfigureDrawer(drawerResourceId, {
          clearStack: true,
        })
      } else {
        openChannelDrawer('new', {
          clearStack: true,
          ...(postInstall && {
            query: {
              ...buildDrawerQueryParam(
                channelDrawerId,
                'drawerPostIntegrationInstall',
                '1'
              ),
            },
          }),
        })
      }
    },
    [
      openConfigureDrawer,
      openChannelDrawer,
      drawerResourceId,
      channelDrawerId,
      slackApp,
      postInstall,
    ]
  )

  const actionsComponentProps = useMemo(
    () => {
      return { formId: FORM_ID, onSkip: handleNext }
    },
    [handleNext]
  )

  return (
    <AdminAccessDrawer
      {...rest}
      onClose={handleOnExit}
      isLoading={isLoading}
      isError={hasError}
      isNoResultFound={isMissing}
      size="wide"
      title="Slack Settings"
      open={open}
      footer={<div ref={footerRef} />}
    >
      {isLoaded &&
        slackApp && (
          <>
            <h3 css={heading.styles.h3} className="grui mt-12 mb-8">
              Map {app.t('agents')} to Slack users
            </h3>
            <p
              css={[paragraph.styles.preflight, text.styles.textSm]}
              className="grui mb-8"
            >
              To ensure your {app.t('agents')} receive notifications in Slack,
              you need to first map {app.t('agents')} to their Slack usernames.
            </p>

            <MessageCard className="grui mb-12">
              <strong css={text.styles.fontMedium}>Heads up!</strong> You
              don&#39;t have to map all of your {app.t('agents')} right now. You
              can update these mappings at any time in your Slack settings.
            </MessageCard>

            <SlackConfigureForm
              formId={FORM_ID}
              profiles={profiles}
              actionsPortalRef={footerRef}
              actionsComponent={Actions}
              actionsComponentAdditionalProps={actionsComponentProps}
              onClose={handleNext}
              hasSearch
              searchPlaceholder={app.t('agents')}
            />
          </>
        )}
    </AdminAccessDrawer>
  )
}

export default SlackMapUsernamesDrawer
