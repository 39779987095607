import {
  selectCurrentRawActionsForTicket,
  selectCurrentRawChangesetsForTicket,
} from 'selectors/currentChangesets'
import {
  selectCurrentTicketSearchResultTicketCount,
  selectCurrentTicketSearchQueryObject,
  selectCurrentTicketSearchSortOrder,
} from 'selectors/search'
import { selectCurrentTicketAssignment } from 'selectors/tickets/assignment'
import { selectTicketById } from 'selectors/tickets/byId/selectTicketById'
import { selectCurrentTicketIsFollowing } from 'selectors/tickets/state'
import {
  selectCurrentTicketLabels,
  selectSelectedTicketsOrCurrentTicketLabels,
} from 'selectors/tickets/labels'
import { selectCurrentTicketMailboxId } from 'selectors/tickets/mailbox'
import { selectSelectedTicketIds } from 'selectors/ticket_list/base'
import { selectCommonStateForSelected } from 'selectors/ticket_list/main'
import { selectMailbox } from 'selectors/mailboxes/selectMailbox'
import { selectOtherMailbox } from 'selectors/mailboxes'

import { selectCurrentTicketId } from 'selectors/tickets/current/selectCurrentTicketId'

import { isDeleteHard } from 'actions/tickets/state'
import { getLatestCustomerReply } from 'util/actions'
import { fullParsedBodyText } from 'util/changesets'

export * as state from 'util/ticketState'

// This file gets used by the performance simulator

export const isTicketHot = ticketId => {
  const state = app.store.getState()
  return !!selectCurrentRawActionsForTicket(state, ticketId)
}

export const numberOfChangesets = ticketId => {
  const state = app.store.getState()
  const changesets = selectCurrentRawChangesetsForTicket(state, ticketId)
  return changesets ? changesets.length : null // not 0 incase changeset is cold
}

export const getTicket = ticketId => {
  return selectTicketById(app.store.getState(), ticketId)
}

export const currentTicketGetLatestCustomerReply = () => {
  const state = app.store.getState()
  const ticketId = selectCurrentTicketId(state)
  const actions = selectCurrentRawActionsForTicket(state, ticketId)
  const { change = {}, created_at: createdAt } =
    getLatestCustomerReply(actions) || {}

  const reply = fullParsedBodyText(change)

  return {
    createdAt,
    reply,
  }
}

export const currentSearchNumberOfTickets = () => {
  const state = app.store.getState()
  return selectCurrentTicketSearchResultTicketCount(state)
}

export const currentTicketAssignee = () => {
  const state = app.store.getState()
  return selectCurrentTicketAssignment(state)
}

export const isFollowingConversation = () => {
  const state = app.store.getState()
  return selectCurrentTicketIsFollowing(state)
}

export const isSearchHot = queryId => {
  const query = app.store.getState().search.byQueryId[queryId]
  if (!query) return false

  return !!query.loaded
}

export const currentSearchModifiers = () => {
  const state = app.store.getState()
  const queryObject = selectCurrentTicketSearchQueryObject(state)
  const sort = selectCurrentTicketSearchSortOrder(state)
  return {
    ...queryObject,
    sortOrder: sort,
  }
}

export const currentTicketMailbox = () => {
  const state = app.store.getState()
  const mailboxId = selectCurrentTicketMailboxId(state)
  return selectMailbox(state, mailboxId)
}

export const currentTicketOtherMailbox = () => {
  const state = app.store.getState()
  return selectOtherMailbox(state)
}

export const currentTicketLabels = () => {
  // works for bulk and single tickets
  const state = app.store.getState()
  return selectSelectedTicketsOrCurrentTicketLabels(state)
}

export const currentTicketLabels22 = () => {
  const state = app.store.getState()
  return selectCurrentTicketLabels(state)
}

export const getBulkSelectedTicketIds = () => {
  const state = app.store.getState()
  return selectSelectedTicketIds(state)
}

export const bulkSelectIsPermanentDelete = ticketIds => {
  const state = app.store.getState()
  return isDeleteHard(ticketIds, state)
}

export const currentBulkSelectTicketsState = () => {
  const state = app.store.getState()
  return selectCommonStateForSelected(state)
}
