import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  heading,
  text,
} from '@groovehq/internal-design-system/lib/styles/elements'
import { selectPendingIntegrationInstallStateProviderValueById } from 'ducks/integrations/selectors/settings'
import { doUninstallIntegrationByProvider } from 'ducks/integrations/operations/settings'
import { doDeleteIntegrationForProvider } from 'ducks/integrations/operations/doDeleteIntegrationForProvider'
import { useFetchIntegrationProvider } from 'ducks/integrations/hooks'
import EntityDeleteDrawer from 'subapps/settings/components/drawers/EntityDelete'

const ENTITY_DELETE_PROP_DEFAULTS = {
  single: {
    deleteBtnText: 'Remove',
    title: 'Remove',
    hardDeleteWord: 'REMOVE',
    hardDelete: true,
  },
  all: {
    deleteBtnText: 'Uninstall Application',
    title: 'Uninstall',
    hardDeleteWord: 'UNINSTALL',
    hardDelete: true,
    message:
      'After performing this action the app will be available under "Available apps" where it can be installed again.',
  },
}

const DELETE_MODE_SINGLE = 'single'
const DELETE_MODE_ALL = 'all'

const UninstallDrawer = props => {
  const {
    open,
    drawerResourceId: providerId,
    onClose,
    onExit,
    drawerIntegrationIdToRemove: integrationIdToRemove,
  } = props

  const dispatch = useDispatch()

  const [isUninstalling, setIsUninstalling] = useState(false)
  // Prevent fetching integration if we're uninstalling it,
  // so the isAlreadyInstalled from useFetchIntegrationProvider is correct:
  // doUninstallIntegrationByProvider will remove the provider from the pending store,
  // this hook is triggered again on rerender to fetch the integration and add the integration
  // to the store again before uninstalling successfully
  const { provider, isLoading, hasError } = useFetchIntegrationProvider(
    providerId,
    {
      shouldPreventFetching: isUninstalling,
    }
  )

  const integrationInstallStateProviderValue = useSelector(state =>
    selectPendingIntegrationInstallStateProviderValueById(
      state,
      integrationIdToRemove
    )
  )

  const { name, uninstallActionConfig = {} } = provider || {}

  const isNoResultFound =
    !provider ||
    (integrationIdToRemove && !integrationInstallStateProviderValue)

  const mode = integrationIdToRemove ? DELETE_MODE_SINGLE : DELETE_MODE_ALL

  const integrationDeleteDrawerProps = useMemo(
    () => {
      // use props set in store OR use ENTITY_DELETE_PROP_DEFAULTS if not set
      return {
        ...ENTITY_DELETE_PROP_DEFAULTS[mode],
        ...uninstallActionConfig.deleteDrawerProps?.[mode],
      }
    },
    [mode, uninstallActionConfig.deleteDrawerProps]
  )

  const handleOnRemove = useCallback(
    () => {
      setIsUninstalling(true)
      if (mode === DELETE_MODE_ALL) {
        dispatch(doUninstallIntegrationByProvider(providerId))
      } else {
        dispatch(
          doDeleteIntegrationForProvider(integrationIdToRemove, providerId)
        )
      }
    },
    [mode, integrationIdToRemove, dispatch, providerId]
  )

  const header = useMemo(
    () => {
      const headerContent = integrationDeleteDrawerProps?.header || null

      if (!headerContent && mode === DELETE_MODE_ALL)
        return `You are about to uninstall ${name}.`

      return headerContent
    },
    [mode, name, integrationDeleteDrawerProps]
  )

  const message = useMemo(
    () => {
      const content = integrationDeleteDrawerProps?.message || null

      if (
        !content &&
        mode === DELETE_MODE_SINGLE &&
        providerId === 'SHOPIFY_V2'
      ) {
        const storeName = integrationInstallStateProviderValue?.storeName || ''
        return `This will remove the Shopify store "${storeName}" from your account`
      }
      return content
    },
    [
      integrationDeleteDrawerProps,
      mode,
      providerId,
      integrationInstallStateProviderValue,
    ]
  )

  return (
    <EntityDeleteDrawer
      {...props}
      {...integrationDeleteDrawerProps}
      isNoResultFound={isNoResultFound}
      isLoading={isLoading}
      isError={hasError}
      onRemove={handleOnRemove}
      onClose={onExit}
      onCancel={onClose}
      tertiaryBtnText="Cancel"
      open={open}
      deleteBtnDisabled={isNoResultFound || isLoading || hasError}
    >
      {header && (
        <h4 className="grui mb-5" css={heading.styles.h4}>
          {header}
        </h4>
      )}
      {message && (
        <div css={[text.styles.textNormal, text.styles.textDark]}>
          {message}
        </div>
      )}
    </EntityDeleteDrawer>
  )
}

export default UninstallDrawer
