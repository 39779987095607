import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util'

const imageBox = css`
  width: ${rem(120)};
  margin: ${rem(16)} auto;
  & > img {
    width: 100%;
  }
`

const textArea = css`
  max-width: 100%;
`

const agentTypeDescription = css`
  display: block;
  margin: 4px 0px 10px 0;
`

const upgradeButton = css`
  margin-left: 4px;
`
const agentDescriptionLong = css`
  margin-top: 8px;
`

export const styles = {
  imageBox,
  textArea,
  agentTypeDescription,
  upgradeButton,
  agentDescriptionLong,
}
