import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util'

const topFields = theme => css`
  display: flex;
  margin-left: -${theme.spacing[7]};
  margin-right: -${theme.spacing[7]};

  & > * {
    flex-basis: 50%;
    max-width: 50% !important;
    padding-left: ${theme.spacing[7]};
    padding-right: ${theme.spacing[7]};
  }
`

const stateSwitch = css`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const stateSwitchText = css`
  display: inline-block;
  min-width: 106px;
`

const fullWidth = css`
  min-width: 100%;
`

const fields = css`
  display: flex;
  margin-left: -${rem(12)};
  margin-right: -${rem(12)};

  & > * {
    flex-basis: 50%;
    max-width: 50% !important;
    padding-left: ${rem(12)};
    padding-right: ${rem(12)};
  }
`

const dropdownContainer = css`
  & > * {
    width: 100%;
  }
`

const dropdownBtn = css`
  min-width: 100%;
`

const checkboxList = css`
  display: flex;
  flex-flow: row wrap;
  margin-top: 0;

  & > * {
    margin-top: ${rem(14)};
  }
`

const selection = css`
  & > *:first-of-type {
    width: 240px;
  }
`

export const styles = {
  fields,
  dropdownContainer,
  dropdownBtn,
  checkboxList,
  selection,
  topFields,
  fullWidth,
  stateSwitch,
  stateSwitchText,
}
