const elemCheckLimit = (elem, limit = document) => {
  if (typeof limit === 'string' || limit instanceof String) {
    return elem.matches(limit) || elem === document
  }

  return elem === limit
}

// NOTE (jscheel): Added ability to pass a limit selector or element to prevent
// this method from walking all the way up the DOM tree before erroring out.
// Source: https://gomakethings.com/climbing-up-and-down-the-dom-tree-with-vanilla-javascript/
export const getClosest = (elem, selector, limit) => {
  // eslint-disable-next-line no-param-reassign
  for (; elem && !elemCheckLimit(elem, limit); elem = elem.parentNode) {
    if (elem.matches(selector)) return elem
  }

  return null
}

// Get parent that has a width and height > 0
export const getClosestVisibleParent = (elem, limit) => {
  // eslint-disable-next-line no-param-reassign
  for (; elem && !elemCheckLimit(elem, limit); elem = elem.parentNode) {
    if (elem) {
      const { height, width, x, y } = elem.getBoundingClientRect()

      if (height !== 0 && width !== 0 && x !== 0 && y !== 0) return elem
    }
  }

  return null
}

export function getParents(node) {
  let current = node
  const parents = []
  while (current && current !== window.document) {
    parents.unshift(current)
    current = current.parentNode
  }

  return parents
}

// https://stackoverflow.com/a/47614491
export function insertAndExecute(el, html) {
  // eslint-disable-next-line no-param-reassign
  el.innerHTML = html
  Array.from(el.querySelectorAll('script')).forEach(oldScript => {
    const newScript = el.ownerDocument.createElement('script')
    Array.from(oldScript.attributes).forEach(attr =>
      newScript.setAttribute(attr.name, attr.value)
    )
    newScript.appendChild(el.ownerDocument.createTextNode(oldScript.innerHTML))
    oldScript.parentNode.replaceChild(newScript, oldScript)
  })
}
