import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { string, arrayOf } from 'prop-types'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import { selectCurrentTeamsById } from 'ducks/teams/selectors'

const TeamDeleteContent = ({ ids }) => {
  const entitiesById = useSelector(selectCurrentTeamsById)

  const entitiesForIds = useMemo(
    () => {
      return ids.map(id => entitiesById[id]).filter(e => !!e)
    },
    [ids, entitiesById]
  )

  // There will always be atleast 1 id. If there isnt then it means we're currently
  // loading the entities after a page refresh
  if (entitiesForIds.length === 0) {
    return <span css={text.styles.fontMedium}>Loading...</span>
  }

  const label = `the ${
    entitiesForIds.length === 1 ? app.t('team') : app.t('teams')
  }`

  return (
    <div css={text.styles.textNormal}>
      You are about to delete {label}
      {entitiesForIds.map((row, index) => (
        <div css={text.styles.fontMedium}>
          <>“</>
          {row.name}
          <>”</>
          {index < entitiesForIds.length - 1 && ', '}
        </div>
      ))}
    </div>
  )
}

TeamDeleteContent.propTypes = {
  ids: arrayOf(string).isRequired,
}

TeamDeleteContent.defaultProps = {}

export default TeamDeleteContent
