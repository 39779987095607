let boostrapCallbacks = []
let isBootstrapped = false
const wait = () => {
  if (isBootstrapped) return Promise.resolve()
  return new Promise(resolve => {
    boostrapCallbacks.push({ resolve })
  })
}
const done = () => {
  isBootstrapped = true
  boostrapCallbacks.map(promise => promise.resolve())
  boostrapCallbacks = []
}

const mod = { done, wait }

export class BootstrapLoader {
  constructor(storeKey, startAction, successAction, failAction, loader) {
    this.key = storeKey
    this.start = startAction
    this.success = successAction
    this.fail = failAction
    this.loader = loader
  }

  // Important !!!!
  // Its by design that dispatch is not passed into the "loader" function
  // of a bootstrap loader. This is to ensure that a Bootstrap loader cannot
  // dispatch actions. The whole purpose behind this class is to have an external
  // party dispatch the actions which allows for batching them together
  promise = async getState => {
    try {
      return this.success(await this.loader(getState), { getState })
    } catch (error) {
      return this.fail(error, { getState })
    }
  }
}

export default mod
