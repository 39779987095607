import React from 'react'
import {
  button,
  text,
} from '@groovehq/internal-design-system/lib/styles/elements'
import { styles } from './styles'

const Button = ({ children, className, onClick, isError }) => {
  return (
    <button
      type="button"
      css={[
        button.styles.preflight,
        styles.addButton,
        isError && styles.addButtonError,
      ]}
      className={className}
      onClick={onClick}
    >
      <span css={[text.styles.fontBold, styles.plusIcon]} className="grui mr-5">
        +
      </span>
      {children}
    </button>
  )
}

export default Button
