import React, { useState, useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { func, shape, number } from 'prop-types'
import Dropdown from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import { Trash } from '@groovehq/internal-design-system/lib/assets/icons'
import { selectGroupsSortedByName } from 'selectors/app/groups'
import { selectMailboxesIncludingInaccessible } from 'selectors/mailboxes/selectMailboxesIncludingInaccessible'
import MessageTemplateEditor from 'subapps/settings/components/MessageTemplateEditor'
import RuleNote from 'components/App/DesktopView/CommunicationForms/Reply/RuleNote'
import EmailReply from 'subapps/settings/components/RuleValue/EmailReply'
import { useWatch } from 'react-hook-form'
import { omit } from 'util/objects'
import { selectCurrentPaidActiveAgentsWithCurrentUserSort } from 'ducks/agents/selectors'

import RuleValue from '../RuleValue'
import DropdownMenu from '../DropdownMenu'
import { actionVariableTypes, subjectVariableTypes } from './data'
import { styles as conditionStyles } from '../Condition/Condition.styles'
import { buildPossibleValueOptions } from './util'

const selectValue = (all, key) => {
  const selected = all.find(item => item.value === key) || {
    name: '',
  }
  return selected.name
}

const Action = ({
  variables,
  onRemoveAction,
  onInput,
  onBlur,
  onActionChange,
  index,
  totalActions,
  name,
  control,
  itemKey,
  actionErrorMessage,
}) => {
  const action = useWatch({
    control,
    name,
  })

  const { parameters: allParameters = [], values = {} } = variables
  const [parameterKey, setParameterKey] = useState(action.type)
  const [initialParameterKey] = useState(parameterKey)

  const [valueKey, setValueKey] = useState(action.value)
  const [toAgentKey, setToAgentKey] = useState(action.to_agent)

  const agents = useSelector(selectCurrentPaidActiveAgentsWithCurrentUserSort)
  const groups = useSelector(selectGroupsSortedByName)
  const mailboxes = useSelector(selectMailboxesIncludingInaccessible)

  const { dataType, paramType, toAgentDataType } = values[parameterKey] || {}

  const possibleOptionsArray = buildPossibleValueOptions(
    values,
    parameterKey,
    agents,
    groups,
    mailboxes
  )

  const possibleToAgentOptionsArray = buildPossibleValueOptions(
    values,
    'send_email_to_agent',
    agents,
    groups,
    mailboxes
  )

  const [value, setValue] = useState(possibleOptionsArray)
  const [toAgentValue, setToAgentValue] = useState(possibleToAgentOptionsArray)

  const selectedParameter = useMemo(
    () => selectValue(allParameters, parameterKey),
    [parameterKey, allParameters]
  )

  const selectedValue = useMemo(
    () => {
      return Array.isArray(value) ? selectValue(value, valueKey) : null
    },
    [value, valueKey]
  )

  const selectedToAgentValue = useMemo(
    () => {
      return Array.isArray(toAgentValue)
        ? selectValue(toAgentValue, toAgentKey)
        : null
    },
    [toAgentValue, toAgentKey]
  )

  // Handle select parameter
  const handleSelectParameter = useCallback(
    key => {
      setParameterKey(key)
      const newValue = values[key]?.options || []
      let lastValue = newValue[0] ? newValue[0].value : ''
      let lastToAgent = null

      const newPossibleOptionsArray = buildPossibleValueOptions(
        values,
        key,
        agents,
        groups,
        mailboxes
      )
      setValue(newPossibleOptionsArray)
      if (Array.isArray(newPossibleOptionsArray)) {
        lastValue = newPossibleOptionsArray[0]?.value
      }
      setValueKey(lastValue)

      if (key === 'send_email_to_agent') {
        setToAgentValue(newPossibleOptionsArray)
        if (Array.isArray(newPossibleOptionsArray)) {
          lastToAgent = newPossibleOptionsArray[0]?.value
        }
        setToAgentKey(lastToAgent)
      }

      if (
        ['send_email_reply', 'add_note', 'forward_conversation'].includes(key)
      ) {
        lastToAgent = null
        setToAgentKey(null)
      }

      // Pass select values to action
      onActionChange(
        {
          ...action,
          type: key,
          value: lastValue,
          to_agent: lastToAgent,
          messageTemplate: null,
        },
        index
      )
    },
    [action, index, onActionChange, values, agents, groups, mailboxes]
  )

  // Handle select value
  const handleSelectValueKey = useCallback(
    key => {
      setValueKey(key)
      onActionChange(
        {
          ...action,
          value: key,
        },
        index
      )
    },
    [action, onActionChange, index]
  )

  const handleMessageTemplateBodyChange = useCallback(
    body => {
      onActionChange(
        {
          ...action,
          value: body,
          messageTemplate: {
            ...action.messageTemplate,
            body,
          },
        },
        index
      )
    },
    [action, onActionChange, index]
  )

  const handleMessageTemplateAttachmentsChange = useCallback(
    ({ pendingUploads, attachments }) => {
      onActionChange(
        {
          ...action,
          messageTemplate: {
            ...action.messageTemplate,
            ...(pendingUploads !== undefined ? { pendingUploads } : {}),
            ...(attachments !== undefined ? { attachments } : {}),
          },
        },
        index
      )
    },
    [action, onActionChange, index]
  )

  const handleSelectToAgentKey = useCallback(
    key => {
      setToAgentKey(key)
      onActionChange(
        {
          ...action,
          to_agent: key,
        },
        index
      )
    },
    [action, onActionChange, index]
  )

  // Handle select value
  const handleDelete = useCallback(
    () => {
      onRemoveAction(action, index)
    },
    [action, onRemoveAction, index]
  )

  const handleInput = useCallback(
    e => {
      const newValue = e.target.value
      setValueKey(newValue)
      onInput(
        {
          ...action,
          value: newValue,
        },
        index
      )
    },
    [setValueKey, onInput, action, index]
  )

  const handleBlur = useCallback(
    e => {
      const newValue = e.target.value
      onBlur(
        {
          ...action,
          value: newValue,
        },
        index
      )
    },
    [onBlur, index, action]
  )

  const toAgentValueItem = useMemo(
    () => {
      return (
        <div css={conditionStyles.dropdownContainer}>
          <Dropdown
            overlay={<DropdownMenu data={toAgentValue} />}
            onSelect={handleSelectToAgentKey}
            selectedKey={toAgentKey}
          >
            <Dropdown.Button size="small" css={conditionStyles.dropdownBtn}>
              {selectedToAgentValue || <>&nbsp;</>}
            </Dropdown.Button>
          </Dropdown>
        </div>
      )
    },
    [handleSelectToAgentKey, toAgentValue, selectedToAgentValue, toAgentKey]
  )

  const parameters = useMemo(
    () => {
      return allParameters
        .filter(p => !p.deprecated || p.value === initialParameterKey)
        .map(p => omit(['deprecated'], p))
    },
    [allParameters, initialParameterKey]
  )

  return (
    <div css={conditionStyles.base}>
      <div css={conditionStyles.dropdowns}>
        <div css={conditionStyles.dropdownContainer} title={selectedValue}>
          <Dropdown
            overlay={<DropdownMenu data={parameters} />}
            onSelect={handleSelectParameter}
            selectedKey={parameterKey}
          >
            <Dropdown.Button size="small" css={conditionStyles.dropdownBtn}>
              {selectedParameter || 'Select an action...'}
            </Dropdown.Button>
          </Dropdown>
        </div>

        {parameterKey &&
          parameterKey === 'send_email_to_agent' &&
          toAgentValueItem}

        {parameterKey && (
          <RuleValue
            value={value}
            onDropdownSelect={
              toAgentDataType ? handleSelectToAgentKey : handleSelectValueKey
            }
            selectedValue={selectedValue}
            onInputBlur={handleBlur}
            onInputChange={handleInput}
            valueKey={toAgentDataType ? toAgentKey : action.value}
            dataType={toAgentDataType || dataType}
            paramType={paramType}
            itemKey={itemKey}
            actionType={action.type}
            errorMessage={actionErrorMessage}
          />
        )}
        <div>
          {totalActions > 1 && (
            <Button
              type="tertiary"
              size="small"
              customIcon={<Trash />}
              css={text.styles.textMediumDark}
              onClick={handleDelete}
            />
          )}
        </div>
      </div>
      {/* Show editor on a new line */}
      {parameterKey &&
        dataType &&
        dataType === 'MESSAGE_TEMPLATE' && (
          <MessageTemplateEditor
            // eslint-disable-next-line camelcase
            type="Rule"
            id={action?.rule_reply_template}
            entityType="ruleActionMessageTemplate"
            open={open}
            mode="managed"
            showPluginImageUpload
            showPluginSourceEditing
            showAddAttachment
            showPluginVariablesPlaceholder
            pluginVariablesPlaceholderTypes={actionVariableTypes}
            subjectVariableTypes={subjectVariableTypes}
            isFormInputSubjectHidden
            isFormInputSubjectWithVariablesHidden={
              parameterKey !== 'send_email_to_requester'
            }
            formInputSubjectName={`actions.${index}.messageTemplate.subject`}
            formInputBodyName={`actions.${index}.messageTemplate.body`}
            formInputAttachmentIdsName={`actions.${index}.messageTemplate.attachments`}
            formInputPendingUploadsName={`actions.${index}.messageTemplate.pendingUploads`}
            formInputBodyLabel={null}
          />
        )}

      {parameterKey &&
        dataType &&
        dataType === 'NOTE' && (
          <RuleNote
            id={action?.rule_reply_template}
            value={valueKey}
            onChange={handleMessageTemplateBodyChange}
            onAttachmentsChange={handleMessageTemplateAttachmentsChange}
            pendingUploads={action.messageTemplate?.pendingUploads}
          />
        )}

      {parameterKey === 'forward_conversation' && (
        <div className="grui mt-6">
          <EmailReply
            onDropdownSelect={handleSelectToAgentKey}
            valueKey={toAgentKey}
            actionType={action.type}
          />
        </div>
      )}

      <div className="operator">AND</div>
    </div>
  )
}

Action.propTypes = {
  variables: shape({}),
  onRemoveAction: func,
  onInput: func,
  onBlur: func,
  onActionChange: func,
  totalActions: number,
}

Action.defaultProps = {
  variables: {},
  onRemoveAction() {},
  action: {},
  onInput() {},
  onBlur() {},
  onActionChange() {},
  onEditorData() {},
  totalActions: 0,
}

export default React.memo(Action)
