import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { doUpdateMergeTicket } from 'ducks/merging/operations'

import SearchableList from './SearchableList'

const SelectTickets = ({
  searchResults,
  clearSearch,
  isDelayed,
  onSearch,
  onSelect,
  queryString,
  ticket,
  ...rest
}) => {
  const dispatch = useDispatch()

  const handleSelect = useCallback(
    conversationOrTicket => {
      onSelect()
      dispatch(
        doUpdateMergeTicket({
          id: conversationOrTicket.number || conversationOrTicket.id,
          title: conversationOrTicket.titleLabel || conversationOrTicket.title,
        })
      )
    },
    [dispatch, onSelect]
  )

  return (
    <div data-test-id="ticket-merge-selector">
      {isDelayed && (
        <div className="grui my-12 text-center">
          {`Please wait until ${app.t('ticket')} is sent...`}
        </div>
      )}
      {!isDelayed && (
        <SearchableList
          searchResults={searchResults}
          onSelect={handleSelect}
          placeholder="Search..."
          searchTerm={queryString}
          {...rest}
        />
      )}
    </div>
  )
}

export default SelectTickets
