// used on input onFocus events. moves cursor to end of text
export const moveCursorToEnd = e => {
  const val = e.target.value
  e.target.value = ''
  e.target.value = val
}

export function formData(form) {
  return Object.fromEntries(new FormData(form))
}
