import styled from '@emotion/styled'
import { css } from '@emotion/react'
import Link from 'redux-first-router-link'
import { Button } from 'shared/ui'
import { Header } from 'shared/components/ui/Typography'
import themeVars from 'ui_theme/site/globals/site.variables'

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`

const StyledNoConvos = styled(Header)`
  text-align: center;
  margin-left: ${props => props.theme.spacing['10']};
  margin-right: ${props => props.theme.spacing['10']};
`

const buttonStyle = () => theme => css`
  &&& {
    margin-top: ${theme.spacing['7']};
  }
`

export default function EmptyStateDesktop({ nextSearch }) {
  return (
    <Container>
      <StyledNoConvos size="medium" color="dust" as="div">
        No conversations
      </StyledNoConvos>
      {nextSearch && (
        <Button
          as={Link}
          to={nextSearch.href}
          color={themeVars.groovyColorName}
          size="small"
          css={buttonStyle}
        >
          Jump to {nextSearch.label} ({nextSearch.count})
        </Button>
      )}
    </Container>
  )
}
