import React, { useCallback } from 'react'
import cn from 'classnames'
import {
  text,
  paragraph,
} from '@groovehq/internal-design-system/lib/styles/elements'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import { useDrawer } from 'ducks/drawers/hooks'
import { DRAWER_TYPE_INTEGRATION_UNINSTALL } from 'ducks/drawers/types'

const CommonAdvancedConfigure = ({
  providerId,
  className,
  integrationName,
}) => {
  const { openDrawer: openUninstallDrawer } = useDrawer(
    DRAWER_TYPE_INTEGRATION_UNINSTALL
  )

  const onUninstallCLick = useCallback(
    () => {
      openUninstallDrawer(providerId)
    },
    [openUninstallDrawer, providerId]
  )

  return (
    <div className={cn('grui mt-12', className)}>
      <div css={text.styles.fontMedium}>Remove Integration</div>
      <p css={paragraph.styles.preflight} className="grui mt-5 mb-10">
        This will remove the {integrationName} integration from your Groove
        account.
      </p>
      <Button type="warning" size="small" onClick={onUninstallCLick}>
        Uninstall Integration
      </Button>
    </div>
  )
}

export default CommonAdvancedConfigure
