import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useWatch } from 'react-hook-form'
import Dropdown from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import { emptyArr, flatten, areArraysEqual } from 'util/arrays'
import MenuWithSearch, {
  useShowHeaderShadow,
  useSearchItems,
  styles as menuWithSearchStyles,
} from '../MenuWithSearch'
import TriggerValue from './TriggerValue'
import AutomationItem from '../AutomationItem'
import { styles as automationItemStyles } from '../AutomationItem/styles'

const selectValue = (all, key) => {
  const items = flatten(Object.values(all))
  const selected = items.find(item => item.value === key) || {
    name: '',
  }
  return selected.name
}

const Trigger = ({
  control,
  name: fieldName,
  variables,
  parameterSectionTitles,
  onRemoveTrigger,
  onTriggerChange,
  index,
  placeholder,
}) => {
  const { parameters = {} } = variables
  const trigger = useWatch({
    control,
    name: fieldName,
  })

  const parameterKey = trigger?.type
  const triggerInput = trigger?.settings?.trigger_input || emptyArr
  const searchRef = useRef()
  const [parameterMenuVisible, setParameterMenuVisibleChange] = useState(
    !!trigger && !parameterKey
  )

  const [filteredParameters, setFilteredParameters] = useState({})
  const [search, setSearch] = useState('')
  const [selectedIds, setSelectedIds] = useState(triggerInput)
  const { handleScroll, shouldShowHeaderShadow } = useShowHeaderShadow(
    parameterMenuVisible
  )
  useSearchItems({
    data: parameters,
    search,
    onSearchResultChange: setFilteredParameters,
  })
  const selectedParameter = useMemo(
    () => selectValue(parameters, parameterKey),
    [parameterKey, parameters]
  )

  const handleChangeSearch = useCallback(({ target }) => {
    setSearch(target.value)
  }, [])

  // Handle select parameter
  const handleSelectParameter = useCallback(
    key => {
      setParameterMenuVisibleChange(false)
      if (key === parameterKey) return
      onTriggerChange(
        {
          ...trigger,
          type: key,
          settings: {
            trigger_input: [],
          },
        },
        index
      )
    },
    [index, trigger, onTriggerChange, parameterKey]
  )

  const handleSelectTriggerInput = useCallback(
    newSelectedIds => {
      const newTriggerInput = newSelectedIds.filter(v => !!v)
      if (
        !trigger?.settings?.trigger_input ||
        !areArraysEqual(trigger?.settings?.trigger_input, newTriggerInput)
      ) {
        onTriggerChange(
          {
            ...trigger,
            settings: {
              trigger_input: newTriggerInput,
            },
          },
          index
        )
      }
    },
    [trigger, onTriggerChange, index]
  )

  const handleDelete = useCallback(
    () => {
      onRemoveTrigger(trigger, index)
    },
    [trigger, onRemoveTrigger, index]
  )

  const handleParametersMenuVisibleChange = useCallback(
    visible => {
      if (!visible && search) {
        setSearch('')
      }
      setParameterMenuVisibleChange(visible)
    },
    [search]
  )

  const handleOpenParameterMenu = useCallback(
    () => {
      if (!parameterMenuVisible) {
        setParameterMenuVisibleChange(true)
      }
    },
    [parameterMenuVisible]
  )

  const header = useMemo(
    () => (
      <MenuWithSearch.Search
        placeholder="Search triggers..."
        value={search}
        onChange={handleChangeSearch}
        ref={searchRef}
        shouldFocus={parameterMenuVisible}
      />
    ),
    [search, handleChangeSearch, parameterMenuVisible]
  )

  const parametersMenu = useMemo(
    () => (
      <MenuWithSearch
        focusElementRef={searchRef}
        search={search}
        className="grui pt-3 pb-8"
        onScroll={handleScroll}
      >
        {Object.keys(filteredParameters)
          ?.filter(key => filteredParameters[key]?.length)
          .map(key => {
            const { title, icon } = parameterSectionTitles[key] || {}
            return (
              <div key={key}>
                <MenuWithSearch.Header icon={icon}>
                  {title}
                </MenuWithSearch.Header>
                {filteredParameters[key]?.map(
                  ({ value, name, tooltip, feature, product }) => (
                    <MenuWithSearch.Item
                      key={value}
                      itemKey={value}
                      tooltip={tooltip}
                      onSelect={handleSelectParameter}
                      selectedKey={parameterKey}
                      visible={parameterMenuVisible}
                      feature={feature}
                      product={product}
                      checkProductFeatureLimit
                      type="trigger"
                    >
                      {name}
                    </MenuWithSearch.Item>
                  )
                )}
              </div>
            )
          })}
      </MenuWithSearch>
    ),
    [
      filteredParameters,
      parameterSectionTitles,
      handleSelectParameter,
      parameterKey,
      parameterMenuVisible,
      search,
      handleScroll,
    ]
  )

  const inputType = useMemo(
    () => {
      let it = null
      Object.keys(parameters).forEach(key => {
        const item = parameters[key].find(
          paramter => paramter.value === parameterKey
        )
        if (item) {
          it = item.inputType
        }
      })
      return it
    },
    [parameters, parameterKey]
  )

  const triggerIcon = useMemo(
    () => {
      const triggerSectionKey = Object.keys(variables.parameters).find(
        sectionKey => {
          return variables.parameters[sectionKey].some(
            item => item.value === parameterKey
          )
        }
      )
      return parameterSectionTitles[triggerSectionKey]?.icon
    },
    [parameterKey, variables, parameterSectionTitles]
  )

  return (
    <div>
      <AutomationItem
        icon={triggerIcon}
        onDelete={handleDelete}
        shouldWrapInnerItems={false}
        onClick={handleOpenParameterMenu}
      >
        <Dropdown
          header={header}
          overlay={parametersMenu}
          css={[
            menuWithSearchStyles.dropdownContainer,
            shouldShowHeaderShadow && menuWithSearchStyles.showHeaderShadow,
          ]}
          onVisibleChange={handleParametersMenuVisibleChange}
          visible={parameterMenuVisible}
          autoHeight
          // Don't let dropdown's arrow event handlers handle the keyboard events
          // MenuWithSearch will do it
          isNavByArrowsDisabled
        >
          <span>{selectedParameter || placeholder}</span>
        </Dropdown>
        {inputType && (
          <div className="grui ml-7">
            <TriggerValue
              // Handle ids change, but the ids aren't set in the form yet.
              onSelectedIdsChange={setSelectedIds}
              selectedIds={selectedIds}
              value={triggerInput}
              setValue={handleSelectTriggerInput}
              inputType={inputType}
            />
          </div>
        )}
      </AutomationItem>
      <div css={[automationItemStyles.operator]}>OR</div>
    </div>
  )
}
export default Trigger
