import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'
import MessageCard from '@groovehq/internal-design-system/lib/components/MessageCard/MessageCard'
import AnimatedEllipsis from '@groovehq/internal-design-system/lib/components/AnimatedEllipsis/AnimatedEllipsis'
import Modal from '@groovehq/internal-design-system/lib/components/Modal/Modal'
import Radio from '@groovehq/internal-design-system/lib/components/Radio/Radio'
import { Question } from '@groovehq/internal-design-system/lib/assets/icons'
import { doGmailImport } from 'ducks/mailboxes/actions'
import { selectIsInInbox, selectIsInSettings } from 'selectors/location'
import { useRedirectToChannel } from 'ducks/channels/hooks'
import { doClearChannelDraft } from 'ducks/channels/actions'
import {
  paragraph,
  text,
  divider,
  button,
} from '@groovehq/internal-design-system/lib/styles/elements'
import ProgressDots from '@groovehq/internal-design-system/lib/components/ProgressDots/ProgressDots'
import { add } from 'util/date'
import { useFeature } from 'ducks/billing/hooks'
import { FEATURE_INBOX_MAX_SEARCH_HISTORY_DAYS } from 'ducks/billing/featureTypes'
import { snow } from 'util/ui/confetti'
import { selectCurrentMailboxId } from 'selectors/mailboxes/selectCurrentMailboxId'
import { selectIsTestAccount } from 'selectors/account'
import { selectChannelCreationStepCount } from 'ducks/channels/selectors'
import debug from 'util/debug'
import useOpenContact from 'util/hooks/useOpenContact'
import { styles } from './styles'
import ImportPeriodDropdown from './ImportPeriodDropdown'
import { CHANNEL_TYPES } from '../Channels.data'

const FORM_SCHEMA = yup.object().shape({
  import_labels: yup.boolean(),
  close_tickets: yup.boolean(),
  stop_importing_at: yup.number().nullable(),
})

const DEFAULT_CLOSE_TICKETS = true
const DEFAULT_IMPORT_LABELS = true

const formEmptyState = {
  import_labels: DEFAULT_IMPORT_LABELS,
  close_tickets: DEFAULT_CLOSE_TICKETS,
  stop_importing_at: null,
}

const formatTimePeriod = days => {
  if (days < 30) {
    // Output in days
    return `${days} day${days > 1 ? 's' : ''}`
  }
  // Convert days to months and output
  const months = Math.round(days / 30) // You can change rounding method as needed
  return `${months} month${months > 1 ? 's' : ''}`
}

const MailImport = ({
  onClose,
  onExit,
  drawerResourceId: channelId,
  drawerId,
  previousDrawer,
  drawerChannelType: channelType,
}) => {
  const [isSaving, setIsSaving] = useState(null)
  const [errors, setErrors] = useState(null)

  const dispatch = useDispatch()
  const isInInbox = useSelector(selectIsInInbox)
  const isInSettings = useSelector(selectIsInSettings)
  const currentMailboxId = useSelector(selectCurrentMailboxId)
  const isTestAccount = useSelector(selectIsTestAccount)
  const creationStepCount = useSelector(state =>
    selectChannelCreationStepCount(state, CHANNEL_TYPES[channelType])
  )

  const { rebuildExitAndRedirect, exitAndRedirect } = useRedirectToChannel({
    channelId, // channel id after mailbox is created
    onExit,
  })

  const useFormMethods = useForm({
    mode: 'all',
    resolver: yupResolver(FORM_SCHEMA),
    defaultValues: formEmptyState,
  })

  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    formState: { isValid },
  } = useFormMethods

  const period = watch('stop_importing_at')

  const openContact = useOpenContact({ left: true })

  const handleOnExit = useCallback(
    () => {
      reset(formEmptyState)
      dispatch(doClearChannelDraft(channelId))
      onExit()
    },
    [onExit, reset, channelId, dispatch]
  )

  const onSubmit = useCallback(
    async data => {
      try {
        setIsSaving(true)
        setErrors(null)
        const time = new Date(Date.now())
        const stopImportingAt = data.stop_importing_at
        if (!isTestAccount) {
          await dispatch(
            doGmailImport(channelId, {
              ...data,
              pages_to_import: null,
              stop_importing_at: stopImportingAt
                ? add(time, -stopImportingAt, 'day').getTime()
                : null,
            })
          )
        }
        dispatch(doClearChannelDraft(channelId))
        if (isInInbox || isInSettings) {
          rebuildExitAndRedirect()

          if (currentMailboxId === channelId) {
            snow({ duration: 1 })
          }
        } else {
          exitAndRedirect()
        }
        setIsSaving(false)
      } catch (error) {
        setIsSaving(false)
        setErrors(error)
        debug('Unable to start import', { error })
      }
    },
    [
      dispatch,
      channelId,
      isInInbox,
      isInSettings,
      rebuildExitAndRedirect,
      exitAndRedirect,
      currentMailboxId,
      isTestAccount,
    ]
  )

  const handleBack = useCallback(
    () => {
      onClose({ ignoreStack: false })
    },
    [onClose]
  )

  const { featureLimit } = useFeature(FEATURE_INBOX_MAX_SEARCH_HISTORY_DAYS)

  const formId = `${drawerId}-form`

  return (
    <Modal
      title="Import existing emails"
      onClose={handleOnExit}
      data-test-id="channels-mail-import"
      portal="#drawer-root"
      open
    >
      {previousDrawer && (
        <ProgressDots
          count={creationStepCount}
          now={creationStepCount}
          className="grui mt-4 mb-13"
        />
      )}
      <Modal.Title>Import</Modal.Title>
      {!previousDrawer && (
        <Modal.Description>
          Don{"'"}t worry, existing conversations won{"'"}t be changed. Only
          missing conversations will be imported.
        </Modal.Description>
      )}
      <div className="grui pt-10 pb-6">
        <form id={formId} onSubmit={handleSubmit(onSubmit)}>
          <div css={[text.styles.textNormal, styles.container]}>
            <p className="grui mb-4">How much history should be imported?</p>
            <div
              css={divider.styles.primary}
              role="separator"
              className="grui mb-8"
            />
            <ImportPeriodDropdown control={control} name="stop_importing_at" />
          </div>
          {period !== null && (
            <>
              <div
                css={[paragraph.styles.preflight, text.styles.textXs]}
                className="grui mt-10"
              >
                <div className="grui mb-4">
                  <b>Import options</b>
                </div>
                <div className="grui mb-4 ml-4">
                  <b className="grui align-middle pr-4">
                    State:
                    <Tooltip
                      title="The state of the conversation inside groove after the import"
                      position="top"
                    >
                      <Question className="grui align-middle" tabIndex={-1} />
                    </Tooltip>
                  </b>
                  <Radio
                    {...register('close_tickets')}
                    id="conversationClosedOption"
                    className="grui pr-4"
                    name="close_tickets"
                    value
                    defaultChecked={DEFAULT_CLOSE_TICKETS}
                  >
                    Closed
                  </Radio>
                  <Radio
                    {...register('close_tickets')}
                    id="conversationOpenedOption"
                    name="close_tickets"
                    className="grui mt-3"
                    value={false}
                    defaultChecked={!DEFAULT_CLOSE_TICKETS}
                  >
                    Open
                  </Radio>
                </div>
                <div className="grui mb-4 ml-4">
                  <b className="grui align-middle pr-6">
                    Tags:
                    <Tooltip
                      title="Import your google labels as tags inside groove"
                      position="top"
                    >
                      <Question className="grui align-middle" tabIndex={-1} />
                    </Tooltip>
                  </b>
                  <Radio
                    {...register('import_labels')}
                    id="conversationLabelNoImport"
                    name="import_labels"
                    value
                    defaultChecked={DEFAULT_IMPORT_LABELS}
                    className="grui pr-4"
                  >
                    Yes
                  </Radio>
                  <Radio
                    {...register('import_labels')}
                    id="conversationLabelImport"
                    className="grui mt-3"
                    name="import_labels"
                    value={false}
                    defaultChecked={!DEFAULT_IMPORT_LABELS}
                  >
                    No
                  </Radio>
                </div>
                <div
                  css={divider.styles.primary}
                  role="separator"
                  className="grui mt-8 mb-8"
                />
              </div>
              {featureLimit !== 'unlimited' && (
                <p className="grui mt-8">
                  <MessageCard
                    css={styles.container}
                    type="warning"
                    className="grui mb-12"
                    visible
                  >
                    Your actual history will be limited to{' '}
                    {formatTimePeriod(featureLimit)} until you upgrade your
                    plan.
                  </MessageCard>
                </p>
              )}
              <p className="grui mt-8">
                <MessageCard
                  css={styles.container}
                  type="info"
                  className="grui mb-12"
                  visible
                >
                  <p>
                    The import will run in the background, and you will be
                    notified upon completion.
                  </p>
                  <p>
                    The maximum import limit is 100,000 emails. For larger
                    imports,&nbsp;
                    <button
                      className="grui"
                      type="button"
                      css={button.styles.link}
                      onClick={openContact}
                    >
                      please contact our support team.
                    </button>
                  </p>
                </MessageCard>
                <MessageCard
                  type="negative"
                  className="grui mb-12"
                  visible={!!errors}
                >
                  An error occurred importing your conversations. Please try
                  again.
                </MessageCard>
              </p>
            </>
          )}
          <p className="grui mt-10" />
        </form>
        {previousDrawer === 'channels-members-edit' && (
          <Modal.Button
            type="tertiary"
            onClick={handleBack}
            className="grui mt-8"
          >
            Back
          </Modal.Button>
        )}
        <Modal.Button
          type="info"
          disabled={isSaving || !isValid}
          onClick={handleSubmit(onSubmit)}
          className="grui mt-8"
          data-test-id="finish-and-redirect-inbox"
        >
          <>
            {isSaving ? (
              <span>
                Saving<AnimatedEllipsis />
              </span>
            ) : (
              <span>
                {period !== null
                  ? `Start import & take me to my ${app.t('mailbox')}`
                  : `Take me to my ${app.t('mailbox')}`}
              </span>
            )}
          </>
        </Modal.Button>
      </div>
    </Modal>
  )
}

export default MailImport
