import React from 'react'
import { useSelector } from 'react-redux'
import {
  button,
  text,
} from '@groovehq/internal-design-system/lib/styles/elements'
import { selectAdminAgents } from 'selectors/agents/base'

const AdminList = ({ className }) => {
  const admins = useSelector(selectAdminAgents)
  return (
    <div css={[text.styles.textXs, text.styles.textDark]} className={className}>
      {admins.map(admin => (
        <div key={admin.id}>
          <strong>{admin.name}</strong>
          {' - '}
          <a
            css={button.styles.link}
            href={`mailto:${admin.email}?subject=${app.t(
              'Mailbox'
            )}%20access%20on%20Groove`}
          >
            {admin.email}
          </a>
          <br />
        </div>
      ))}
    </div>
  )
}

export default AdminList
