import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useDataTable } from 'ducks/tables/hooks'
import Table from '@groovehq/internal-design-system/lib/components/Table/Table'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import {
  SETTINGS_RULE_TABLE_ID,
  SETTINGS_RULE_TABLE_ID_INACTIVE,
} from 'ducks/tables/ids'
import { doFetchRulesInMemory, doUpdateSingleRule } from 'ducks/rules/actions'
import { useDrawer } from 'ducks/drawers/hooks'
import { buildDrawerQueryParam } from 'ducks/drawers/util'
import {
  DRAWER_TYPE_RULES_DELETE,
  DRAWER_TYPE_RULES_UPDATE,
} from 'ducks/drawers/types'
import { useFeature } from 'ducks/billing/hooks'
import { FEATURE_INBOX_MAX_RULES } from 'ducks/billing/featureTypes'
import useColumns from './useColumns'
import { styles } from './styles'
import PlanLimitationCard from '../PlanLimitationCard'
import useAddFeatureUsageIndicator from './useAddFeatureUsageIndicator'

const RuleDataTable = ({ active, compact }) => {
  const dispatch = useDispatch()
  const disableReorder = active === false
  const { canUseFeature, featureLimit: rulesLimit } = useFeature(
    FEATURE_INBOX_MAX_RULES
  )
  const showPlanLimitationForRules = !canUseFeature
  const {
    pagination: {
      currentDenormalizedEntities: rules,
      gotoPage,
      changePageSize,
    },
    pagination,
    filtering: { changeFilter },
    sorting: { changeSort },
    query: { name },
    sorting,
    isLoading,
    shouldLoad,
    isError,
    queryId,
  } = useDataTable(
    disableReorder ? SETTINGS_RULE_TABLE_ID_INACTIVE : SETTINGS_RULE_TABLE_ID,
    'rule',
    doFetchRulesInMemory,
    {
      pagination: {
        defaultPageSize: 9999,
        defaultCursor: 1,
        showSizeChanger: false,
        filterDelay: 1000,
        parseToIntKeys: ['cursor'],
        defaultOrderBy: disableReorder ? 'name_ASC' : null,
      },
      filtering: {
        baseFilters: {
          active,
        },
      },
    }
  )

  const columns = useColumns({ compact, disableReorder })
  const rulesWithFeatureUsageIndicator = useAddFeatureUsageIndicator(rules)

  const handleOnTableStateChange = useCallback(
    (newState, action = {}) => {
      const { type } = action || {}
      if (type === 'gotoPage') {
        const { pageIndex } = action
        gotoPage(pageIndex + 1)
      } else if (type === 'setPageSize') {
        const { pageSize } = action
        changePageSize(pageSize)
      } else if (type === 'toggleSortBy' || type === 'setSortBy') {
        const { id, desc } = newState.sortBy[0] || {}
        changeSort(id ? `${id}_${desc ? 'DESC' : 'ASC'}` : null)
      } else if (type === 'setGlobalFilter') {
        const { filterValue } = action
        // When setting filter, debounce for 1 second, but when removing a filter
        // trigger that immediately
        changeFilter('name', filterValue, { resetPage: true, persist: true })
      }
    },
    [changeSort, changeFilter, gotoPage, changePageSize]
  )

  // We intentially take the initial value and never change it because we dont actually want
  // the default value to ever change
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultGlobalFilter = useMemo(() => name || null)

  const { drawerId: deleteDrawerId, openDrawer: openDeleteDrawer } = useDrawer(
    DRAWER_TYPE_RULES_DELETE
  )

  const { openDrawer: openCreateEditDrawer } = useDrawer(
    DRAWER_TYPE_RULES_UPDATE
  )

  const handleCreate = useCallback(
    () => {
      openCreateEditDrawer('new')
    },
    [openCreateEditDrawer]
  )

  const handleDelete = useCallback(
    id => {
      openDeleteDrawer(id, {
        query: {
          ...buildDrawerQueryParam(deleteDrawerId, 'drawerDeleteMode', 'ids'),
        },
      })
    },
    [openDeleteDrawer, deleteDrawerId]
  )

  const handleEdit = useCallback(
    id => {
      openCreateEditDrawer(id)
    },
    [openCreateEditDrawer]
  )

  const emptyContent = useMemo(
    () => {
      if (active !== undefined) {
        return <Table.Empty title="There are no rules" />
      }
      return (
        <Table.Empty
          title="There are no rules"
          subtitle="You haven’t created any rules on your account yet."
        >
          <Button onClick={handleCreate}>Create new rule</Button>
        </Table.Empty>
      )
    },
    [active, handleCreate]
  )

  const handleOnReorder = useCallback(
    (e, item) => {
      const { orderedIds, newIndex } = e
      dispatch(
        doUpdateSingleRule(
          { ...item, position: newIndex },
          {
            updatePosition: true,
            orderedIds,
            queryId,
          }
        )
      )
    },
    [dispatch, queryId]
  )

  return (
    <>
      {showPlanLimitationForRules && (
        <PlanLimitationCard
          className="grui mb-9 mt-3"
          title="Ready to create more Rules?"
          trackingFeatureType={FEATURE_INBOX_MAX_RULES}
        >
          You’ve reached your <strong>limit of {rulesLimit} Rules</strong> on
          your account.
          <br />
          To add more, please upgrade your account.
        </PlanLimitationCard>
      )}
      <Table
        separate
        columns={columns}
        data={rulesWithFeatureUsageIndicator}
        loading={shouldLoad || !!isLoading}
        css={[styles.table, compact && styles.compactTable]}
        draggable
        disableReorder={disableReorder}
        hasSearch
        searchPlaceholder="rules"
        updateData={handleEdit}
        deleteData={handleDelete}
        onEdit={handleEdit}
        sorting={sorting}
        showPagination
        pagination={pagination}
        dataType="rules"
        emptyContent={emptyContent}
        hasError={isError}
        emptyHintVisible={false}
        onTableStateChange={handleOnTableStateChange}
        defaultGlobalFilter={defaultGlobalFilter}
        onReorder={handleOnReorder}
      />
    </>
  )
}

RuleDataTable.displayName = 'RuleDataTable'

export default RuleDataTable
