import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'

const container = theme => css`
  && {
    color: ${theme.color.monochrome.black};
    max-width: ${rem(379)};
    padding: ${rem(44)} ${rem(22)} ${rem(28)};
    margin-top: 32vh;
  }
`

const imgBox = theme => css`
  border-radius: 50%;
  background-color: ${theme.color.monochrome.white};
  box-shadow: 0 2px 2px 0 rgba(${theme.color.monochrome.black_rgba}, 0.15);
  padding: ${rem(4)};
  position: absolute;
  top: 0;
  transform: translateY(-50%);
`

const imgWrapper = theme => css`
  width: ${rem(70)};
  height: ${rem(70)};
  background-color: ${theme.color.info['50']};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    display: block;
    width: ${rem(36)};
  }
`

const heading = theme => css`
  font-size: ${theme.fontSize.x5large};
`

const btn = theme => css`
  min-width: ${rem(282)};
  font-family: ${theme.font.secondary};
`

export const styles = {
  container,
  imgBox,
  imgWrapper,
  heading,
  btn,
}
