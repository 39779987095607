/* eslint-disable no-multi-assign */ // ok in reducers
import * as types from 'subapps/kb/actions'
import { defaultFetchFilters } from 'subapps/kb/constants/articles'
import { emptyArr } from 'util/arrays'
import { omit, emptyObj } from 'util/objects'
import { hash } from 'util/scatterSwap'
import { produce } from 'immer'

const defaultState = {
  byId: {},
  counts: {},
  isLoading: false,
  isLoadingCounts: null,
  isSaving: false,
  byContext: {
    ARTICLE_LIST: {
      ...defaultFetchFilters,
    },
    CATEGORY_LIST: {
      ...defaultFetchFilters,
      sortByOrder: 'position asc',
      selectedState: 'allWithDeleted',
    },
    CATEGORY_ARTICLE_LIST: {
      ...defaultFetchFilters,
      stale: true,
    },
    RELATED_ARTICLES: {
      ...defaultFetchFilters,
      selectedState: 'published',
      sortByOrder: 'title asc',
    },
  },
  stale: true,
}
const reducers = {}

const updateArticle = (state, newArticle) => {
  const existingArticle = state.byId[newArticle.id] || emptyArr

  return {
    ...state.byId,
    ...{ [newArticle.id]: { ...existingArticle, ...newArticle } },
  }
}

// Switched context, loading new KB so reset the state
reducers[types.SWITCH_KNOWLEDGE_BASE] = state => {
  return {
    ...state,
    ...defaultState,
  }
}

reducers[types.FETCH_ARTICLES_REQUEST] = (state, action) => {
  const { context, selectedState } = action

  return {
    ...state,
    byContext: {
      ...state.byContext,
      [context]: {
        ...state.byContext[context],
        isLoading: true,
        selectedState,
      },
    },
  }
}

reducers[types.FETCH_ARTICLES_SUCCESS] = (state, action) => {
  const {
    context,
    meta,
    data,
    keyword,
    selectedCategoryId,
    selectedCategoryTitle,
    selectedAuthorId,
    selectedAuthorName,
    perPage,
    sortByOrder,
  } = action

  const newById = {}
  const newIds = []

  data.forEach(article => {
    newById[article.id] = { ...state.byId[article.id], ...article }
    newIds.push(article.id)
  })

  const newContext = { ...(state.byContext[context] || defaultFetchFilters) }
  if (context === 'CATEGORY_ARTICLE_LIST') {
    newContext.stale = false
  }

  return {
    ...state,
    stale: false,
    byId: { ...state.byId, ...newById },
    byContext: {
      ...state.byContext,
      [context]: {
        ...newContext,
        ids: newIds,
        perPage,
        currentPage: meta.currentPage,
        totalPages: meta.totalPages,
        totalCount: meta.totalCount,
        keyword,
        selectedCategoryId,
        selectedCategoryTitle,
        selectedAuthorId,
        selectedAuthorName,
        sortByOrder,
        isLoading: false,
      },
    },
  }
}

reducers[types.FETCH_ARTICLES_FAILURE] = (state, action) => {
  const { context } = action

  return {
    ...state,
    byContext: {
      ...state.byContext,
      [context]: {
        ...state.byContext[context],
        isLoading: false,
      },
    },
  }
}

reducers[types.FETCH_ARTICLES_COUNTS_REQUEST] = state => {
  return {
    ...state,
    isLoadingCounts: true,
  }
}

reducers[types.FETCH_ARTICLES_COUNTS_SUCCESS] = (state, action) => {
  return {
    ...state,
    isLoadingCounts: false,
    counts: action.data,
  }
}

reducers[types.FETCH_ARTICLES_VIEWS_SUCCESS] = (state, action) => {
  const { data } = action
  const newViews = {}

  data.forEach(view => {
    newViews[view['article.id']] = view.result
  })

  return {
    ...state,
    views: { ...state.views, ...newViews },
  }
}

reducers[types.FETCH_ARTICLE_REQUEST] = state => {
  return {
    ...state,
    isLoading: true,
  }
}

reducers[types.FETCH_ARTICLE_SUCCESS] = (state, action) => {
  const { article } = action.data
  return {
    ...state,
    byId: {
      ...state.byId,
      [article.id]: article,
    },
    isLoading: false,
    isSaving: false,
  }
}

reducers[types.FETCH_ARTICLE_FAILURE] = state => {
  return {
    ...state,
    isLoading: false,
  }
}

reducers[types.SET_SELECTED_ARTICLES] = (state, action) => {
  const { context, data } = action
  const { selected, articleId } = data

  const selectedArticleIds = [...state.byContext[context].selectedArticleIds]
  if (selected) {
    selectedArticleIds.push(articleId)
  } else {
    selectedArticleIds.splice(selectedArticleIds.indexOf(articleId), 1)
  }

  return {
    ...state,
    byContext: {
      ...state.byContext,
      [context]: {
        ...state.byContext[context],
        selectedArticleIds,
      },
    },
  }
}

reducers[types.SET_SELECTED_ALL_ARTICLES] = (state, action) => {
  const { context, data } = action

  return {
    ...state,
    byContext: {
      ...state.byContext,
      [context]: {
        ...state.byContext[context],
        selectedArticleIds: data.selected ? state.byContext[context].ids : [],
      },
    },
  }
}

reducers[types.CREATE_ARTICLE_REQUEST] = state => {
  return {
    ...state,
    isSaving: true,
  }
}

reducers[types.CREATE_ARTICLE_FAILURE] = (state, action) => {
  const { err } = action.data
  return {
    ...state,
    isSaving: false,
    error: err,
  }
}

reducers[types.CREATE_ARTICLE_SUCCESS] = (state, action) => {
  const { createdArticle, createdArticleId } = action.data
  const context = 'ARTICLE_LIST'

  return {
    ...state,
    byContext: {
      ...state.byContext,
      [context]: {
        ...state.byContext[context],
        ids: [createdArticleId, ...state.byContext[context].ids],
      },
      CATEGORY_ARTICLE_LIST: {
        ...defaultState.byContext.CATEGORY_ARTICLE_LIST,
      },
    },
    byId: {
      ...state.byId,
      [createdArticleId]: createdArticle,
    },
    isSaving: false,
    stale: true,
  }
}

reducers[types.UPDATE_ARTICLE_REQUEST] = state => {
  return {
    ...state,
    isSaving: true,
  }
}

reducers[types.UPDATE_ARTICLE_FAILURE] = (state, action) => {
  const { err } = action.data
  return {
    ...state,
    isSaving: false,
    error: err,
  }
}

reducers[types.UPDATE_ARTICLE_SUCCESS] = (state, action) => {
  const { updatedArticle } = action.data
  return {
    ...state,
    byId: updateArticle(state, updatedArticle),
    isSaving: false,
  }
}

reducers[types.REMOVE_CATEGORY_ARTICLE] = (state, action) => {
  const { articleId } = action.data
  const articleWithoutCategory = {
    ...state.byId[articleId],
    categoryId: null,
    category: null,
  }

  return {
    ...state,
    byId: updateArticle(state, articleWithoutCategory),
  }
}

const getCurrentPageArticleIds = (articleIds, categoryList) => {
  return articleIds.slice(
    categoryList.perPage * (categoryList.currentPage - 1),
    categoryList.perPage * categoryList.currentPage
  )
}

reducers[types.UPDATE_CATEGORY_SUCCESS] = produce((draftState, action) => {
  const { updatedCategory } = action.data
  const context = 'CATEGORY_LIST'
  const categoryList = draftState.byContext[context]

  if (categoryList.selectedCategoryId === updatedCategory.id) {
    // Show correct ids when articles length is changed: adding new articles
    let ids = updatedCategory.articleIds
    if (categoryList.ids.length !== updatedCategory.articleIds.length) {
      ids = getCurrentPageArticleIds(updatedCategory.articleIds, categoryList)
      if (ids.length === 0 && categoryList.currentPage !== 1) {
        categoryList.currentPage -= 1
        ids = getCurrentPageArticleIds(updatedCategory.articleIds, categoryList)
      }
    }
    categoryList.ids = ids
    categoryList.totalCount = updatedCategory.articleIds?.length
    categoryList.totalPages = Math.ceil(
      categoryList.totalCount / categoryList.perPage
    )
  }

  updatedCategory.articleIds.forEach(articleId => {
    if (draftState.byId[articleId]) {
      draftState.byId[articleId].categoryId = updatedCategory.id
      draftState.byId[articleId].category = updatedCategory
    }
  })

  return draftState
}, emptyObj)

reducers[types.PUBLISH_CATEGORY_SUCCESS] = produce((draftState, action) => {
  const { publishedCategory } = action.data

  publishedCategory.articleIds.forEach(articleId => {
    if (draftState.byId[articleId]) {
      draftState.byId[articleId].categoryId = publishedCategory.id
      draftState.byId[articleId].category = publishedCategory
    }
  })

  return draftState
}, emptyObj)

reducers[types.UNPUBLISH_CATEGORY_SUCCESS] = produce((draftState, action) => {
  const { unpublishedCategory } = action.data

  unpublishedCategory.articleIds.forEach(articleId => {
    if (draftState.byId[articleId]) {
      draftState.byId[articleId].categoryId = unpublishedCategory.id
      draftState.byId[articleId].category = unpublishedCategory
    }
  })

  return draftState
}, emptyObj)

reducers[types.DELETE_CATEGORY_SUCCESS] = produce((draftState, action) => {
  const { categoryId } = action.data

  Object.keys(draftState.byId).forEach(articleId => {
    if (draftState.byId[articleId]) {
      if (draftState.byId[articleId].categoryId === categoryId) {
        draftState.byId[articleId].categoryId = null
        draftState.byId[articleId].category = null
      }
    }
  })

  return draftState
}, emptyObj)

reducers[types.REPOSITION_ARTICLES_REQUEST] = (state, action) => {
  const { context, data } = action

  return {
    ...state,
    byContext: {
      ...state.byContext,
      [context]: {
        ...state.byContext[context],
        ids: data.articleIds,
      },
    },
  }
}

reducers[types.TRASH_ARTICLE_REQUEST] = state => {
  return {
    ...state,
    isSaving: true,
  }
}

reducers[types.TRASH_ARTICLE_SUCCESS] = (state, action) => {
  const { trashedArticle } = action.data
  const existingArticle = state.byId[trashedArticle.id]
  const articleListContext = 'ARTICLE_LIST'
  const relatedArticlesContext = 'RELATED_ARTICLES'

  const counts = state.counts
  let newCountsByState = {}

  if (counts && counts.state) {
    newCountsByState = {
      ...counts,
      ...{
        state: {
          ...counts.state,
          ...{
            [existingArticle.state]: counts.state[existingArticle.state] - 1,
            deleted: (counts.state.deleted || 0) + 1,
          },
        },
      },
    }
  }

  return {
    ...state,
    byContext: {
      ...state.byContext,
      [articleListContext]: {
        ...state.byContext[articleListContext],
        ids: state.byContext[articleListContext].ids.filter(
          id => id !== trashedArticle.id
        ),
        totalCount: state.byContext[articleListContext].totalCount - 1 || 0,
      },
      [relatedArticlesContext]: {
        ...state.byContext[relatedArticlesContext],
        ids: state.byContext[relatedArticlesContext].ids.filter(
          id => id !== trashedArticle.id
        ),
      },
      CATEGORY_ARTICLE_LIST: {
        // Set default state to avoid stale data and avoid accessing undefined article when add articles to category
        ...defaultState.byContext.CATEGORY_ARTICLE_LIST,
      },
    },
    byId: omit([trashedArticle.id], state.byId),
    counts: newCountsByState,
    article: null,
    error: null,
    isSaving: false,
    stale: true,
  }
}

reducers[types.TRASH_ARTICLE_FAILURE] = (state, action) => {
  const { err } = action.data
  return {
    ...state,
    isSaving: false,
    error: err,
  }
}

reducers[types.DELETE_ARTICLE_STARTED] = state => {
  return {
    ...state,
    isSaving: true,
  }
}

reducers[types.DELETE_ARTICLE_SUCCESS] = (state, action) => {
  const { kbArticleDelete } = action.payload
  const { deletedArticleId } = kbArticleDelete

  const articleId = hash(deletedArticleId)
  const articleListContext = 'ARTICLE_LIST'
  const relatedArticlesContext = 'RELATED_ARTICLES'

  const counts = state.counts
  let newCountsByState = {}

  if (counts && counts.state.deleted) {
    newCountsByState = {
      ...counts,
      ...{
        state: {
          ...counts.state,
          ...{
            deleted: counts.state.deleted - 1,
          },
        },
      },
    }
  }

  let newByIdState = {}
  if (state.byId) {
    newByIdState = { ...omit([articleId], state.byId) }

    Object.keys(newByIdState).forEach(key => {
      if (newByIdState[key].relatedIds.includes(articleId)) {
        newByIdState[key].relatedIds = [
          ...newByIdState[key].relatedIds.filter(
            relatedId => relatedId !== articleId
          ),
        ]

        newByIdState[key].relatedArticles = [
          ...newByIdState[key].relatedArticles.filter(
            relatedArticle => relatedArticle.id !== articleId
          ),
        ]
      }
    })
  }

  return {
    ...state,
    byId: newByIdState,
    byContext: {
      ...state.byContext,
      [articleListContext]: {
        ...state.byContext[articleListContext],
        ids: state.byContext[articleListContext].ids.filter(
          id => id !== articleId
        ),
        totalCount: state.byContext[articleListContext].totalCount - 1 || 0,
      },
      [relatedArticlesContext]: {
        ...state.byContext[relatedArticlesContext],
        ids: state.byContext[relatedArticlesContext].ids.filter(
          id => id !== articleId
        ),
      },
      CATEGORY_ARTICLE_LIST: {
        ...defaultState.byContext.CATEGORY_ARTICLE_LIST,
      },
    },
    counts: newCountsByState,
    isSaving: false,
    stale: true,
  }
}

reducers[types.DELETE_ARTICLE_FAILURE] = state => {
  return {
    ...state,
    isSaving: false,
  }
}

reducers[types.RESTORE_ARTICLE_REQUEST] = state => {
  return {
    ...state,
    isSaving: true,
  }
}

reducers[types.RESTORE_ARTICLE_SUCCESS] = (state, action) => {
  const { restoredArticle } = action.data
  const context = 'ARTICLE_LIST'

  const counts = state.counts
  let newCountsByState = {}

  if (counts && counts.state) {
    newCountsByState = {
      ...counts,
      ...{
        state: {
          ...counts.state,
          ...{
            [restoredArticle.state]:
              (counts.state[restoredArticle.state] || 0) + 1,
            deleted: counts.state.deleted - 1,
          },
        },
      },
    }
  }

  return {
    ...state,
    byContext: {
      ...state.byContext,
      [context]: {
        ...state.byContext[context],
        ids: state.byContext[context].ids.filter(
          id => id !== restoredArticle.id
        ),
        totalCount: state.byContext[context].totalCount - 1 || 0,
      },
      CATEGORY_ARTICLE_LIST: {
        ...defaultState.byContext.CATEGORY_ARTICLE_LIST,
      },
    },
    byId: omit([restoredArticle.id], state.byId),
    counts: newCountsByState,
    isSaving: false,
    stale: true,
  }
}

reducers[types.RESTORE_ARTICLE_FAILURE] = (state, action) => {
  const { err } = action.data
  return {
    ...state,
    isSaving: false,
    error: err,
  }
}

reducers[types.PUBLISH_ARTICLE_REQUEST] = state => {
  return {
    ...state,
    isSaving: true,
  }
}

reducers[types.PUBLISH_ARTICLE_SUCCESS] = (state, action) => {
  const { publishedArticle } = action.data

  return {
    ...state,
    byId: updateArticle(state, publishedArticle),
    isSaving: false,
    stale: true,
  }
}

reducers[types.PUBLISH_ARTICLE_FAILURE] = (state, action) => {
  const { err } = action.data
  return {
    ...state,
    isSaving: false,
    error: err,
  }
}

reducers[types.UNPUBLISH_ARTICLE_REQUEST] = state => {
  return {
    ...state,
    isSaving: true,
  }
}

reducers[types.UNPUBLISH_ARTICLE_SUCCESS] = (state, action) => {
  const { unpublishedArticle } = action.data

  return {
    ...state,
    byId: updateArticle(state, unpublishedArticle),
    isSaving: false,
    stale: true,
  }
}

reducers[types.UNPUBLISH_ARTICLE_FAILURE] = (state, action) => {
  const { err } = action.data
  return {
    ...state,
    isSaving: false,
    error: err,
  }
}

reducers[types.REVERT_ARTICLE_REQUEST] = state => {
  return {
    ...state,
    isSaving: true,
  }
}

reducers[types.REVERT_ARTICLE_SUCCESS] = (state, action) => {
  const { revertedArticle } = action.data

  return {
    ...state,
    byId: updateArticle(state, revertedArticle),
    isSaving: false,
    stale: true,
  }
}

reducers[types.REVERT_ARTICLE_FAILURE] = (state, action) => {
  const { err } = action.data
  return {
    ...state,
    isSaving: false,
    error: err,
  }
}

reducers[types.ARTICLE_SAVE_CLOSE] = state => {
  return {
    ...state,
    error: null,
    isSaving: false,
  }
}

reducers[types.ARTICLE_CANCEL_CLOSE] = state => {
  return {
    ...state,
    error: null,
    isSaving: false,
  }
}

export default function reducer(state = defaultState, action, shared) {
  const handler = reducers[action.type]
  if (handler) return handler(state, action, shared)
  return state
}
