import React, { useEffect } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import Field from '@groovehq/internal-design-system/lib/components/Field/Field'
import ColorPicker from '@groovehq/internal-design-system/lib/components/ColorPicker/ColorPicker'

import { styles as fieldStyles } from '@groovehq/internal-design-system/lib/components/Field/Field.styles'
import { styles } from './styles'

export default function TagCreateEditContent() {
  const {
    register,
    control,
    setFocus,
    formState: { errors },
  } = useFormContext()

  useEffect(
    () => {
      setFocus('name')
    },
    [setFocus]
  )

  return (
    <div className="grui pt-10">
      <Field
        {...register('name', { required: true })}
        css={styles.fullWidth}
        label="Name"
        placeholder="Enter tag name..."
        validateStatus={errors?.name ? 'error' : undefined}
        help={errors?.name?.message}
      />

      <div
        css={[fieldStyles.labelBox, styles.colorLabel]}
        className="grui mt-12"
      >
        Color
      </div>
      <Controller
        control={control}
        name="color"
        // eslint-disable-next-line react/jsx-no-bind
        render={({ field: { onChange, onBlur, value } }) => (
          <ColorPicker
            onColorChange={onChange}
            onBlur={onBlur}
            pickerBtnStyles={styles.dropdownBtn}
            css={[styles.popoverContainer, styles.fullWidth]}
            defaultColor={value}
          />
        )}
      />
    </div>
  )
}
