import graphql from 'api/graphql'
import { NOT_FOUND } from 'redux-first-router'
import storage from 'util/storage'
import { reverseHashInt } from 'util/scatterSwap'
import { oauthTokenSelector } from 'selectors/app'
import { doGraphqlRequest } from 'ducks/requests/operations'
import { ARTICLES_PAGE, LANDING_PAGE, PRO_UPGRADE_PAGE } from 'subapps/kb/pages'
import {
  selectCurrentKnowledgeBaseId,
  selectCurrentKnowledgeBase,
  selectById,
} from 'subapps/kb/selectors/knowledge_bases'
import * as types from 'subapps/kb/actions'
import { isEmpty } from 'util/objects'
import { selectFeatureBasedOnboardingWorkflowData } from 'subapps/onboarding/selectors'
import { doTryFetchAccountUsageOnboardingForOnboarding } from 'ducks/accountPreferences/operations'
import { capture } from 'ducks/tracking/actions'

const knowledgeBaseFields = `
  createdAt
  customDomain
  customDomainExpiresAt
  customDomainLetsencrypt
  customDomainStatus
  customDomainValid
  customScriptsEnabled
  enabled
  gaAnonymized
  gaCode
  gaVersion
  id
  ipAddresses
  ipAddressesEnabled
  language
  logoAltText
  metaDescription
  metaRobots
  newWidgetId
  ogDescription
  ogImageUrl
  ogTitle
  pageTitle
  password
  passwordEnabled
  previewToken
  primary
  publishedThemeId
  ratingsType
  redirectLegacy
  siteUrl
  subdomainUrl
  subdomain
  themeId
  title
  updatedAt
`

export const doSetKnowledgeBaseAuthCookie = (doLogin, kb) => (_, getState) => {
  const setCookie = (url, token, _doLogin) => {
    return new Promise(resolve => {
      const iframe = document.createElement('iframe')
      iframe.addEventListener('load', () => resolve())
      iframe.style.cssText = 'display:none;width:0px;height:0px;border:none;'
      if (_doLogin === true) {
        iframe.src = `${url}/sessions/login/?access_token=${token}`
      } else {
        iframe.src = `${url}/sessions/logout`
      }
      window.document.body.appendChild(iframe)
    })
  }

  const state = getState()
  const token = oauthTokenSelector(state)
  const kbs = kb ? [kb] : selectById(state)
  const promises = []

  Object.keys(kbs).forEach(id => {
    // If KB uses custom domain, the siteUrl would be different
    // from the subdomainUrl, so we'd need to auth both
    // in order for logged in features to work.
    promises.push(setCookie(kbs[id].subdomainUrl, token, doLogin))
    if (kbs[id].subdomainUrl !== kbs[id].siteUrl) {
      promises.push(setCookie(kbs[id].siteUrl, token, doLogin))
    }
  })

  return Promise.all(promises)
}

export function doClearKnowledgeBaseAuthCookie() {
  return doSetKnowledgeBaseAuthCookie()
}

export function doSwitchKnowledgeBase(knowledgeBaseId, page = ARTICLES_PAGE) {
  return (dispatch, getState) => {
    const state = getState()
    const currentKnowledgeBaseId = selectCurrentKnowledgeBaseId(state)

    if (knowledgeBaseId !== currentKnowledgeBaseId) {
      dispatch({
        type: types.SWITCH_KNOWLEDGE_BASE,
      })
    }
    dispatch({
      type: page,
      payload: { knowledgeBaseId },
    })
    storage.set('lastAccessedKnowledgeBaseId', knowledgeBaseId)
  }
}

export function doFetchKnowledgeBases() {
  return (dispatch, getState) => {
    const state = getState()
    const token = oauthTokenSelector(state)

    dispatch({
      type: types.FETCH_KNOWLEDGE_BASES_REQUEST,
    })

    const query = `
      query KnowledgeBasesQuery {
        knowledgeBases {
          ${knowledgeBaseFields}
        }
      }
    `

    return graphql(token, query)
      .then(res => {
        const data = res.json.data

        dispatch({
          type: types.FETCH_KNOWLEDGE_BASES_SUCCESS,
          data: data.knowledgeBases,
        })

        // set auth cookie
        dispatch(doSetKnowledgeBaseAuthCookie(true))
      })
      .catch(() => {
        dispatch({
          type: types.FETCH_KNOWLEDGE_BASES_FAILURE,
        })
      })
  }
}

/*
 * Fetches knowledge base
 */
export function doFetchKnowledgeBase(knowledgeBaseId, reload = false) {
  return (dispatch, getState) => {
    const state = getState()
    const token = oauthTokenSelector(state)

    if (!reload) {
      dispatch({
        type: types.FETCH_KNOWLEDGE_BASE_REQUEST,
      })
    }

    const query = `
      query KnowledgeBaseQuery($id: ID!) {
        knowledgeBase(id: $id) {
          ${knowledgeBaseFields}
        }
      }
    `

    const variables = {
      id: knowledgeBaseId,
    }

    return graphql(token, query, variables)
      .then(res => {
        const data = res.json.data
        const knowledgeBase = data.knowledgeBase
        dispatch({
          type: types.FETCH_KNOWLEDGE_BASE_SUCCESS,
          data: { knowledgeBase },
        })
      })
      .catch(err => {
        dispatch({
          type: types.FETCH_KNOWLEDGE_BASE_FAILURE,
        })
        if (err.errors[0].status === 404) {
          dispatch({ type: NOT_FOUND })
        }
      })
  }
}

/*
 * Creates knowledge base
 */
export function doCreateKnowledgeBase(knowledgeBase) {
  return (dispatch, getState) => {
    const state = getState()
    const token = oauthTokenSelector(state)

    dispatch({
      type: types.CREATE_KNOWLEDGE_BASE_REQUEST,
    })

    const mutation = `
      mutation CreateKnowledgeBase(
        $input: KnowledgeBaseInput!
      ){
        createKnowledgeBase(
          input: $input
        ){
          ${knowledgeBaseFields}
        }
      }
    `

    const variables = {
      input: {
        subdomain: knowledgeBase.subdomain,
        title: knowledgeBase.title,
        themeId: knowledgeBase.themeId,
      },
    }

    return graphql(token, mutation, variables)
      .then(res => {
        const data = res.json.data
        const createdKnowledgeBase = data.createKnowledgeBase
        capture('kb create knowledgebase')
        dispatch({
          type: types.CREATE_KNOWLEDGE_BASE_SUCCESS,
          data: { createdKnowledgeBase },
        })
        const onboardingWorkflowData = selectFeatureBasedOnboardingWorkflowData(
          getState()
        )
        dispatch(
          doTryFetchAccountUsageOnboardingForOnboarding(
            onboardingWorkflowData.kb?.usageKey
          )
        )
      })
      .catch(err => {
        dispatch({
          type: types.CREATE_KNOWLEDGE_BASE_FAILURE,
          data: { err },
        })
      })
  }
}

export function doUpdateCurrentKnowledgeBase(fields, _opts) {
  return (dispatch, getState) => {
    const state = getState()
    const knowledgeBase = { ...selectCurrentKnowledgeBase(state), ...fields }
    return dispatch(
      doUpdateKnowledgeBase(knowledgeBase, knowledgeBase.id, _opts)
    )
  }
}

/*
 * Updates knowledge base
 *
 * opts:
 *   redirectLegacy - The Boolean whether to enable redirection for primary KB.
 */
export function doUpdateKnowledgeBase(knowledgeBase, knowledgeBaseId, _opts) {
  return dispatch => {
    const opts = _opts || {}
    // If previousPrimaryKnowledgeBaseId exists, it means we're changing primary kb now
    // we will use it to update that kb's primary state to be false
    const { previousPrimaryKnowledgeBaseId } = opts

    const mutation = `
      mutation UpdateKnowledgeBase(
        $id: ID!,
        $input: KnowledgeBaseInput!
      ){
        updateKnowledgeBase(
          id: $id,
          input: $input
        ){
          ${knowledgeBaseFields}
        }
      }
    `

    const metaRobots = `${knowledgeBase.metaIndex ? 'INDEX' : 'NOINDEX'},FOLLOW`
    const newWidgetId = knowledgeBase.newWidgetId
      ? reverseHashInt(knowledgeBase.newWidgetId)
      : null
    const ipAddresses = knowledgeBase.ipAddresses
      .split('\n')
      .map(ip => ip.trim())
      .filter(ip => (isEmpty(ip) ? null : ip))

    const variables = {
      id: knowledgeBaseId,
      input: {
        customDomain: knowledgeBase.customDomain,
        customDomainLetsencrypt: knowledgeBase.customDomainLetsencrypt === true,
        enabled: knowledgeBase.enabled === true,
        gaAnonymized: knowledgeBase.gaAnonymized,
        gaCode: knowledgeBase.gaCode,
        gaVersion: knowledgeBase.gaVersion,
        ipAddresses,
        ipAddressesEnabled: knowledgeBase.ipAddressesEnabled === true,
        language: knowledgeBase.language,
        logoAltText: knowledgeBase.logoAltText,
        metaDescription: knowledgeBase.metaDescription,
        metaRobots,
        newWidgetId,
        ogDescription: knowledgeBase.ogDescription,
        ogImageUrl: knowledgeBase.ogImageUrl,
        ogTitle: knowledgeBase.ogTitle,
        pageTitle: knowledgeBase.pageTitle,
        password: knowledgeBase.password,
        passwordEnabled: knowledgeBase.passwordEnabled === true,
        primary: opts.primary === true,
        subdomain: knowledgeBase.subdomain,
        themeId: knowledgeBase.themeId,
        title: knowledgeBase.title,
      },
    }

    // redirectLegacy shall only be sent if its set on the opts,
    // since this attribute is shared across KBs (global for an account)
    // and does not reflect a per KB redirection status.
    // Fixes an issue when an update to one KB,
    // would disable redirection for another. - @tair
    if (opts.redirectLegacy !== undefined) {
      variables.input.redirectLegacy = opts.redirectLegacy === true
    }

    capture('kb update knowledgebase')

    return dispatch(
      doGraphqlRequest(types.UPDATE_KNOWLEDGE_BASE, mutation, variables, {
        onBeforeSuccessAction: data => {
          if (knowledgeBase.subdomain !== data.updateKnowledgeBase.subdomain) {
            doSetKnowledgeBaseAuthCookie(true, data.updateKnowledgeBase)
          }
        },
        transformResponse: res =>
          previousPrimaryKnowledgeBaseId
            ? { ...res, previousPrimaryKnowledgeBaseId }
            : res,
        moduleOptions: {
          toasts: {
            enabled: true,
            started: {
              enabled: false,
            },
            success: {
              enabled: true,
              content: 'Knowledge Base changes saved',
            },
            failed: {
              content: 'Knowledge Base changes failed',
              onClickAction: () =>
                dispatch(
                  doUpdateKnowledgeBase(knowledgeBase, knowledgeBaseId, _opts)
                ),
            },
          },
        },
      })
    )
  }
}

export function doGoToKnowledgeBaseLandingPage() {
  return { type: LANDING_PAGE }
}

export function doGoToKnowledgeBaseUpgradePage() {
  return { type: PRO_UPGRADE_PAGE }
}

export function doDeleteKnowledgeBase() {
  return (dispatch, getState) => {
    const state = getState()
    const token = oauthTokenSelector(state)
    const knowledgeBaseId = selectCurrentKnowledgeBaseId(state)

    dispatch({
      type: types.DELETE_KNOWLEDGE_BASE_REQUEST,
      data: { knowledgeBaseId },
    })

    const mutation = `
      mutation deleteKnowledgeBase($id: ID!) {
        deleteKnowledgeBase(id: $id)
      }
    `
    const variables = {
      id: knowledgeBaseId,
    }

    return graphql(token, mutation, variables)
      .then(() => {
        capture('kb delete knowledgebase')
        dispatch({
          type: types.DELETE_KNOWLEDGE_BASE_SUCCESS,
          data: { knowledgeBaseId },
        })
        dispatch(doGoToKnowledgeBaseLandingPage())
      })
      .catch(err => {
        dispatch({
          type: types.DELETE_KNOWLEDGE_BASE_FAILURE,
          data: { err },
        })
      })
  }
}
