import { useMemo } from 'react'
import Radio from '@groovehq/internal-design-system/lib/components/Radio/Radio'
import { propFunc } from 'util/functions'

const useColumns = () => {
  const columns = useMemo(() => {
    const createRadio = ({ value, onEdit, row: { index } }) => {
      return (
        <Radio
          id={value}
          name="widget-for-chat"
          value={value}
          defaultChecked={index === 0}
          onChange={propFunc(onEdit, value)}
        />
      )
    }
    return [
      {
        Header: '',
        accessor: 'id',
        Cell: createRadio,
        disableSortBy: true,
      },
      {
        Header: 'Widgets',
        accessor: 'name',
        width: '100%',
      },
    ]
  }, [])

  return columns
}

export default useColumns
