import config from 'config'
import { getValueByPath } from 'util/objects'
import { smartSort, sortByKey } from './sorting'

export * from './sorting'

export const emptyArr = config.isDevelopment ? Object.freeze([]) : []

export const diff = (a, b) => {
  return a.filter(i => {
    return b.indexOf(i) < 0
  })
}

// Mutates input array and removes the objet if it exists
export const without = (array, object, { clearAll = false } = {}) => {
  let index = array.indexOf(object)
  while (index > -1) {
    array.splice(index, 1)
    if (!clearAll) break
    index = array.indexOf(object)
  }
  return array
}

// Mutates input array and adds object to end of array if it doesn't already exist
export const withPush = (array, object, { allowDuplicate = false } = {}) => {
  const index = array.indexOf(object)
  if (index === -1 || allowDuplicate) {
    array.push(object)
  }
  return array
}

// Mutates input array and adds object to start of array if it doesn't already exist
export const withUnshift = (array, object, { allowDuplicate = false } = {}) => {
  const index = array.indexOf(object)
  if (index === -1 || allowDuplicate) {
    array.unshift(object)
  }
  return array
}

// Mutates the array and adds it if its not present or removes it when it is
export const withToggle = (array, object) => {
  const index = array.indexOf(object)
  if (index === -1) {
    array.push(object)
  } else {
    array.splice(index, 1)
  }
  return array
}

export const difference = (array = [], withoutArray = []) => {
  return array.filter(object => withoutArray.indexOf(object) < 0)
}

export const areArraysEqual = (a = [], b = []) => {
  return difference(a, b).length === 0 && a.length === b.length
}

export const uniq = array => {
  return array.filter((v, i, a) => a.indexOf(v) === i)
}

export const uniqBy = (arr, predicate, last = false) => {
  const cb = typeof predicate === 'function' ? predicate : o => o[predicate]

  return Array.from(
    arr
      .reduce((map, item) => {
        const key = item === null || item === undefined ? item : cb(item)
        // eslint-disable-next-line no-unused-expressions
        if (!map.has(key) || last) map.set(key, item)
        return map
      }, new Map())
      .values()
  )
}

// Create array of unique objects by property
export const uniqByProp = (objectArr, propName = 'id') => {
  const encounteredProps = new Set()
  const newArr = objectArr.filter(entry => {
    if (encounteredProps.has(entry[propName])) return false
    encounteredProps.add(entry[propName])
    return true
  })
  return newArr
}

export const isEmpty = arrayish => {
  return (Array.isArray(arrayish) && arrayish.length === 0) || !arrayish
}

const shallowProperty = key => {
  return obj => {
    return obj == null ? undefined : obj[key]
  }
}

export const getLength = shallowProperty('length')

export const reverseForEach = (array, callback) => {
  const length = getLength(array)
  for (let i = length - 1; i >= 0; i -= 1) {
    const item = array[i]
    if (callback(item) === false) break
  }
}

// Produce an array that contains every item shared between all the
// passed-in arrays.
// Gimped version of underscore.js's intersection()
// Needs Array.includes()
export function intersection(array) {
  const result = []
  const argsLength = arguments.length
  for (let i = 0, length = getLength(array); i < length; i += 1) {
    const item = array[i]
    if (result.includes(item)) continue // eslint-disable-line
    let j
    for (j = 1; j < argsLength; j += 1) {
      if (!arguments[j].includes(item)) break // eslint-disable-line
    }
    if (j === argsLength) result.push(item)
  }
  return result
}

// Reverse search an array for given item using a predicate function
// Based on https://tc39.github.io/ecma262/#sec-array.prototype.findIndex
// (but we don't pollute the Array prototype, because we are not savages)
export const reverseFindIndex = (arr, predicate) => {
  const len = arr.length

  if (typeof predicate !== 'function') {
    throw new TypeError('predicate must be a function')
  }

  let k = len - 1
  while (k >= 0) {
    const kValue = arr[k]
    if (predicate.call(undefined, kValue, k, arr)) {
      return k
    }
    k -= 1
  }

  return -1
}

export const last = (array, emptyElement) => {
  if (!array) return undefined
  const lastElement = array[getLength(array) - 1]
  return lastElement === undefined ? emptyElement : lastElement
}

export const compact = array => array.filter(x => x != null)

// Deprecated use smartsort function
export const sortByKeyType = (key, type) => {
  return (a, b) => {
    const aVal = getValueByPath(key, a)
    const bVal = getValueByPath(key, b)
    if (type === 'number') {
      return aVal - bVal
    }
    return `${aVal}`.localeCompare(bVal)
  }
}

// Deprecated use smartsort function
export const sortByKeyTypeDesc = (key, type) => {
  return (a, b) => {
    const aVal = getValueByPath(key, a)
    const bVal = getValueByPath(key, b)
    if (type === 'number') {
      return bVal - aVal
    }
    return `${bVal}`.localeCompare(aVal)
  }
}

// Deprecated use sortByKey (is case insensative by default)
export const sortByKeyInsensitive = (array, key) => {
  return array.sort((a, b) => {
    return smartSort(a[key], b[key])
  })
}

export const sortByName = (array, options) => sortByKey(array, 'name', options)

/**
 * Gimped version of Ramda's R.all()
 *
 * Returns `true` if all elements of the list match the predicate, `false` if
 * there are any that don't.
 *
 * @func
 * @sig (a -> Boolean) -> [a] -> Boolean
 * @param {Function} fn The predicate function.
 * @param {Array} list The array to consider.
 * @return {Boolean} `true` if the predicate is satisfied by every element, `false`
 *         otherwise.
 * @example
 *
 *      let equals3 = R.equals(3);
 *      all(equals3)([3, 3, 3, 3]); //=> true
 *      all(equals3)([3, 3, 1, 3]); //=> false
 */
export const all = (fn, list) => {
  let idx = 0
  while (idx < list.length) {
    if (!fn(list[idx], idx)) {
      return false
    }
    idx += 1
  }
  return true
}

/**
 * Gimped version of Ramda's R.any()
 *
 * Returns `true` if at least one of elements of the list match the predicate,
 * `false` otherwise.
 *
 * @func
 * @sig (a -> Boolean) -> [a] -> Boolean
 * @param {Function} fn The predicate function.
 * @param {Array} list The array to consider.
 * @return {Boolean} `true` if the predicate is satisfied by at least one element, `false`
 *         otherwise.
 * @example
 *
 *      let lessThan0 = R.flip(R.lt)(0);
 *      let lessThan2 = R.flip(R.lt)(2);
 *      R.any(lessThan0)([1, 2]); //=> false
 *      R.any(lessThan2)([1, 2]); //=> true
 */
export const any = (fn, list) => {
  if (!list) return false
  let idx = 0
  while (idx < list.length) {
    if (fn(list[idx])) {
      return true
    }
    idx += 1
  }
  return false
}

/**
 * Pure `Array#reverse`
 */
export const reverse = array => array.slice().reverse()

/**
 * Given an array and a predicate function, returns array of two arrays:
 * first array contains elements that satisfy predicate function,
 * second array contains elements that don't satisfy predicate function
 */
export const partition = (array, predicate) => {
  return array.reduce(
    (result, element, index, allItems) => {
      result[predicate(element, index, allItems) ? 0 : 1].push(element)
      return result
    },
    [[], []]
  )
}

// Sauce: https://stackoverflow.com/questions/10457264/how-to-find-first-element-of-array-matching-a-boolean-condition-in-javascript
export function findFirst(arr = [], predicateFn, ctx) {
  if (!arr) return undefined
  let result = null
  arr.some((el, i) => {
    if (predicateFn.call(ctx, el, i, arr)) {
      result = el
      return true
    }
    return false
  })
  return result
}

export function times(n, func) {
  const result = []
  for (let i = 0; i < n; i += 1) {
    result.push(func(i))
  }
  return result
}

// https://stackoverflow.com/questions/17500312/is-there-some-way-i-can-join-the-contents-of-two-javascript-arrays-much-like-i/17500836
export function fullOuterJoin(xs, ys, primary, foreign, sel) {
  const ix = xs.reduce(
    (
      iix,
      row // loop through m items
    ) => iix.set(row[primary], row), // populate index for primary table
    new Map()
  ) // create an index for primary table

  return ys.map((
    row // loop through n items
  ) =>
    sel(
      ix.get(row[foreign]), // get corresponding row from primary
      row
    )
  ) // select only the columns you need
}

// https://stackoverflow.com/questions/14446511/what-is-the-most-efficient-method-to-groupBy-on-a-javascript-array-of-objects
export function groupByToMap(list, keyGetter) {
  const map = new Map()
  list.forEach(item => {
    const key = keyGetter(item)
    const collection = map.get(key)
    if (!collection) {
      map.set(key, [item])
    } else {
      collection.push(item)
    }
  })
  return map
}

export function groupBy(list, keyGetter) {
  if (!list) return null
  return list.reduce((result, item) => {
    const key = keyGetter(item)
    // eslint-disable-next-line no-param-reassign
    result[key] = result[key] || []
    result[key].push(item)
    return result
  }, {})
}

export function flatMap(array = [], fn) {
  return [].concat(...array.map(fn))
}

export function itemsDifferByKey(arrA, arrB, ...keys) {
  if (!arrA) return true
  if (!arrB) return true
  if (arrA.length !== arrB.length) return true
  let idx = 0
  while (idx < arrA.length) {
    const objA = arrA[idx]
    const objB = arrB[idx]
    if (!objA) return true
    if (!objB) return true
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i]
      if (objA[key] !== objB[key]) return true
    }

    idx += 1
  }
  return false
}

export function wrapInArray(objOrArray) {
  return Array.isArray(objOrArray) ? objOrArray : [objOrArray]
}

export function toObject(array, keyGenerator = ({ id }) => id) {
  return array.reduce((reduction, value) => {
    const key = keyGenerator(value)
    // eslint-disable-next-line no-param-reassign
    reduction[key] = value
    return reduction
  }, {})
}

export function chunk(myArray, chunkSize) {
  const results = []

  while (myArray.length) {
    results.push(myArray.splice(0, chunkSize))
  }

  return results
}

export function flatten(array) {
  return array.reduce(
    (flat, toFlatten) =>
      flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten),
    []
  )
}

// lifted from https://github.com/d4rkr00t/percentile/blob/master/lib/index.js
export function percentile(p, list, fn) {
  // eslint-disable-next-line no-param-reassign
  p = Number(p)

  // eslint-disable-next-line no-param-reassign
  list = list.slice().sort((a, b) => {
    if (fn) {
      // eslint-disable-next-line no-param-reassign
      a = fn(a)
      // eslint-disable-next-line no-param-reassign
      b = fn(b)
    }

    // eslint-disable-next-line no-param-reassign
    a = Number.isNaN(a) ? Number.NEGATIVE_INFINITY : a
    // eslint-disable-next-line no-param-reassign
    b = Number.isNaN(b) ? Number.NEGATIVE_INFINITY : b

    if (a > b) return 1
    if (a < b) return -1

    return 0
  })

  if (p === 0) return list[0]

  const kIndex = Math.ceil(list.length * (p / 100)) - 1

  return list[kIndex]
}

// Javascript equivilant to the ruby product function
// https://www.geeksforgeeks.org/ruby-array-product-function/#:~:text=Array%23product()%20%3A%20product(),of%20elements%20from%20all%20arrays.&text=Return%3A%20an%20array%20of%20all%20combinations%20of%20elements%20from%20all%20arrays.
// Method which returns an array of all combinations of elements from both arrays
// Example
// array1 = ["foo", "bar"]
// array2 = [null, "channel"]
// return = [[null,"foo"],["channel","foo"],[null,"bar"],["channel","bar"]]
export function product(array1, array2) {
  return array1.reduce((a, v) => [...a, ...array2.map(x => [v, x])], [])
}

export function firstNonUndefined(values) {
  return values.find(val => val !== undefined)
}

/* intersperse: Return an array with the separator interspersed between
 * each element of the input array.
 * https://stackoverflow.com/questions/23618744/rendering-comma-separated-list-of-links
 * > _([1,2,3]).intersperse(0)
 * [1,0,2,0,3]
 */
export function intersperse(arr, sep) {
  if (arr.length === 0) {
    return []
  }

  return arr.slice(1).reduce(
    (xs, x) => {
      return xs.concat([sep, x])
    },
    [arr[0]]
  )
}
