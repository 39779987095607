import { css } from '@emotion/react'

const controls = theme => css`
  & > button {
    width: 100%;

    + button {
      margin-top: ${theme.spacing[5]};
    }
  }
`

export const styles = {
  controls,
}
