import React from 'react'
import cn from 'classnames'
import ChannelOption from './ChannelOption'
import { styles } from '../styles'

const ChannelOptions = ({ channelList, onSelect, className, children }) => {
  return (
    <div
      css={styles.channelOptions}
      className={cn('grui flex flex-wrap justify-center', className)}
    >
      {channelList.map(channel => (
        <ChannelOption
          key={`${channel.provider}-${channel.type}`}
          type={channel.type}
          title={channel.title}
          icon={channel.iconBig || channel.icon}
          provider={channel.provider}
          onSelect={onSelect}
          recommended={channel.recommended}
        >
          {channel.description}
        </ChannelOption>
      ))}
      {children}
    </div>
  )
}

export default ChannelOptions
