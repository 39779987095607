import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { string, arrayOf } from 'prop-types'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import { selectCurrentFoldersById } from 'ducks/folders/selectors/folders'

const FolderDeleteContent = ({ ids }) => {
  const entitiesById = useSelector(selectCurrentFoldersById)

  const entitiesForIds = useMemo(
    () => {
      return ids.map(id => entitiesById[id]).filter(e => !!e)
    },
    [ids, entitiesById]
  )

  // There will always be atleast 1 id. If there isnt then it means we're currently
  // loading the entities after a page refresh
  if (entitiesForIds.length === 0) {
    return <span css={text.styles.fontMedium}>Loading...</span>
  }

  let label = `the ${app.t('folder')} `
  if (entitiesForIds.length > 1) label = `${app.t('folders')} `

  return (
    <div css={text.styles.textNormal}>
      You are about to delete {label}
      {entitiesForIds.map((row, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <span key={index} css={text.styles.fontMedium}>
          <>“</>
          {row.name}
          <>”</>
          {index < entitiesForIds.length - 1 && ', '}
        </span>
      ))}. This action is permanent and cannot be undone.
    </div>
  )
}

FolderDeleteContent.propTypes = {
  ids: arrayOf(string).isRequired,
}

FolderDeleteContent.defaultProps = {}

export default FolderDeleteContent
