import { css } from '@emotion/react'

const header = css`
  h4 {
    margin-top: 44px !important;
  }
`
const fullWidth = css`
  width: 100%;
`

export const styles = {
  header,
  fullWidth,
}
