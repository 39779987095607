export const fieldTypes = [
  {
    key: 'select',
    title: 'Dropdown',
  },
  {
    key: 'input',
    title: 'Text',
  },
  {
    key: 'textarea',
    title: 'Textarea',
  },
]
