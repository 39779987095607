import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util'
import { FormControl } from '@groovehq/internal-design-system/lib/components/Field/Field'

const topFields = css`
  display: flex;
  & > *:first-of-type {
    label {
      margin-bottom: ${rem(5)};
    }
  }
`

const label = theme => css`
  font-size: ${rem(19)};
  color: ${theme.color.info['900']};
  font-weight: ${theme.fontWeight.medium};
`

const stateSwitch = css`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: auto;
`

const stateSwitchText = css`
  display: inline-block;
  min-width: 94px;
`

const fullWidth = css`
  min-width: 100%;
`

const dropdownContainer = css`
  & > * {
    width: 100%;
  }
`

const dropdownBtn = css`
  min-width: 100%;
`

const checkboxList = css`
  display: flex;
  flex-flow: row wrap;
  margin-left: -${rem(12)};
  margin-right: -${rem(12)};
  margin-top: 0;

  & > * {
    padding-left: ${rem(12)};
    padding-right: ${rem(12)};
    margin-top: ${rem(14)};
  }
`

const selection = css`
  & > *:first-of-type {
    width: 240px;
  }
`

const scheduleContainer = theme => css`
  .form-control {
    max-width: 80px;
  }
  input[type='number'] {
    font-weight: ${theme.fontWeight.medium};
  }

  [role='presentation'] {
    max-width: 45%;
  }

  .schedule-dropdown-text,
  .automation-item-icon {
    color: ${theme.color.brand['800']};
  }

  .ui.dropdown .input {
    max-width: ${rem(120)};
  }

  .ui.dropdown .menu {
    margin-top: ${rem(4)};
    margin-bottom: ${rem(4)};
  }

  .section-title-icon-lg {
    padding: 7px;
  }

  .automation-item-icon.section-title-icon-lg {
    padding: 5px;
  }
`

const field = theme => css`
  & > ${FormControl} input {
    font-size: ${theme.fontSize.base};
    padding-top: ${rem(5)};
    padding-bottom: ${rem(5)};
  }
`

const dateField = theme => css`
  & .ui.input input {
    font-size: ${theme.fontSize.base};
    padding-top: ${rem(5)};
    padding-bottom: ${rem(5)};
    padding-left: ${rem(13)};
  }
`

const arrowIcon = theme => css`
  transform: rotate(90deg);
  margin-left: auto;
  color: ${theme.color.brand['800']};
`

export const styles = {
  field,
  dateField,
  topFields,
  label,
  dropdownContainer,
  dropdownBtn,
  checkboxList,
  selection,
  fullWidth,
  stateSwitch,
  stateSwitchText,
  scheduleContainer,
  arrowIcon,
}
