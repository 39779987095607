import {
  V6_SIGNUP_PAGE,
  V6_EMAIL_PAGE,
  V6_COMPANY_PAGE,
  V6_COMPANY_2_PAGE,
  V6_VERIFICATION_PAGE,
} from './pages'

// NOTE: Simple configuration object for onboarding routes to load

const V6_ROUTES_CONFIG = {
  [V6_SIGNUP_PAGE]: {
    route: {
      path: '/signup',
    },
    handler: () => ({
      componentName: 'V6SignupPage',
      subapp: 'onboarding',
    }),
  },
  [V6_EMAIL_PAGE]: {
    route: {
      path: '/signup/email',
    },
    handler: () => ({
      componentName: 'V6EmailPage',
      subapp: 'onboarding',
    }),
  },
  [V6_COMPANY_PAGE]: {
    route: {
      path: '/signup/organization',
    },
    handler: () => ({
      componentName: 'V6CompanyPage',
      subapp: 'onboarding',
    }),
  },
  [V6_COMPANY_2_PAGE]: {
    route: {
      path: '/signup/details',
    },
    handler: () => ({
      componentName: 'V6CompanyPage2',
      subapp: 'onboarding',
    }),
  },
  [V6_VERIFICATION_PAGE]: {
    route: {
      path: '/signup/verification',
    },
    handler: () => ({
      componentName: 'V6VerificationPage',
      subapp: 'onboarding',
    }),
  },
}

export default V6_ROUTES_CONFIG
