import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'

const resetBtn = css`
  height: 100%;
  padding: 0 16px !important;
  cursor: pointer;
`

const fields = () => css`
  max-width: ${rem(486)};
  padding-top: ${rem(17)};

  & > * {
    max-width: 100% !important;
    margin-bottom: ${rem(21)};
  }

  & > *:last-of-type {
    margin-bottom: 0;
  }
`

const accordionPanel = css`
  & > [role='button'] {
    padding: ${rem(14)} ${rem(19)} ${rem(16)} ${rem(14)};
    align-items: flex-start;
  }
`

const dropdownBtn = css`
  min-width: ${rem(321)};
`

export const styles = {
  resetBtn,
  fields,
  dropdownBtn,
  accordionPanel,
}
