import * as types from 'subapps/kb/actions'
import * as devicePreviewSizes from 'subapps/kb/constants/device_preview_sizes'
import storage from 'util/storage'

const defaultState = {
  isLoading: false,
  devicePreviewSize: devicePreviewSizes.DESKTOP,
  hidePreviewSizeWarning: storage.get('hidePreviewSizeWarning'),
}

const reducers = {}

reducers[types.FETCH_SETTINGS_REQUEST] = state => {
  return {
    ...state,
    isLoading: true,
  }
}

reducers[types.FETCH_SETTINGS_SUCCESS] = (state, action) => {
  const { data } = action

  return {
    ...state,
    settings: data,
    isLoading: false,
  }
}

reducers[types.FETCH_SETTINGS_FAILURE] = (state, action) => {
  const { err } = action

  return {
    ...state,
    error: err,
    isLoading: false,
  }
}

reducers[types.UPDATE_SETTINGS_REQUEST] = state => {
  return {
    ...state,
    isLoading: true,
  }
}

reducers[types.UPDATE_SETTINGS_SUCCESS] = (state, action) => {
  const { data } = action

  return {
    ...state,
    settings: data,
    isLoading: false,
  }
}

reducers[types.UPDATE_SETTINGS_FAILURE] = (state, action) => {
  const { err } = action

  return {
    ...state,
    error: err,
    isLoading: false,
  }
}

reducers[types.PUBLISH_SETTINGS_REQUEST] = state => {
  return {
    ...state,
    isLoading: true,
  }
}

reducers[types.PUBLISH_SETTINGS_SUCCESS] = (state, action) => {
  const { data } = action

  return {
    ...state,
    settings: data,
    isLoading: false,
  }
}

reducers[types.PUBLISH_SETTINGS_FAILURE] = (state, action) => {
  const { err } = action

  return {
    ...state,
    error: err,
    isLoading: false,
  }
}

reducers[types.REVERT_SETTINGS_REQUEST] = state => {
  return {
    ...state,
    isLoading: true,
  }
}

reducers[types.REVERT_SETTINGS_SUCCESS] = (state, action) => {
  const { data } = action

  return {
    ...state,
    settings: data,
    isLoading: false,
  }
}

reducers[types.REVERT_SETTINGS_FAILURE] = (state, action) => {
  const { err } = action

  return {
    ...state,
    error: err,
    isLoading: false,
  }
}

reducers[types.RESET_SETTINGS_REQUEST] = state => {
  return {
    ...state,
    isLoading: true,
  }
}

reducers[types.RESET_SETTINGS_SUCCESS] = (state, action) => {
  const { data } = action

  return {
    ...state,
    settings: data,
    isLoading: false,
  }
}

reducers[types.RESET_SETTINGS_FAILURE] = (state, action) => {
  const { err } = action

  return {
    ...state,
    error: err,
    isLoading: false,
  }
}

reducers[types.UPDATE_THEME_DEVICE_PREVIEW_SIZE] = (state, action) => {
  const { size } = action.data

  return {
    ...state,
    devicePreviewSize: size,
  }
}

reducers[types.UPDATE_THEME_HIDE_PREVIEW_SIZE_WARNING] = (state, action) => {
  const { hide } = action.data

  storage.set('hidePreviewSizeWarning', hide)
  return {
    ...state,
    hidePreviewSizeWarning: hide,
  }
}

export default function reducer(state = defaultState, action) {
  const handler = reducers[action.type]
  if (handler) return handler(state, action)
  return state
}
