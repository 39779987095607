import React from 'react'
import { css } from '@emotion/react'
import { useSelector } from 'react-redux'

import { Loader, SUI } from 'shared/ui'
import Scroller from 'components/Scroller'
import InvalidSearchMessage from 'components/App/Pages/SearchPage/InvalidSearchMessage'
import SearchErrorMessage from 'components/App/Pages/SearchPage/SearchErrorMessage'
import DemoInboxCard from 'components/TrialSetup/DemoInboxCard'
import OnboardingWorkflowCard from 'components/TrialSetup/OnboardingWorkflowCard'
import TicketList from 'subapps/ticketing/components/TicketList'
import TicketListHeader from 'subapps/ticketing/components/TicketList/Header'
import { selectShowDemoInboxCard } from 'selectors/trial_setup'
import { selectHasCurrentTicketSearchLoaded } from 'selectors/search/base'
import { selectCurrentUserIsAdminOrOwner } from 'ducks/currentUser/selectors'
import { selectShowOnboardingWorkflowInConversationList } from 'subapps/onboarding/selectors/selectShowOnboardingWorkflowInConversationList'
import { selectIsRefreshingSearches } from 'selectors/app'

import styles from './styles.less'
import LimitSearchHistoryCard from '../LimitSearchHistoryCard'

// Wraps the ticket list container and adds mouseEnter/Leave events
export default function WrappedTicketList({
  hasSearchErrored,
  isQueryStringValid,
  queryString,
}) {
  const showDemoInboxCard = useSelector(selectShowDemoInboxCard)
  const showOnboardingWorkflow = useSelector(
    selectShowOnboardingWorkflowInConversationList
  )
  const isManager = useSelector(selectCurrentUserIsAdminOrOwner)

  const isCurrentSearchLoaded = useSelector(selectHasCurrentTicketSearchLoaded)
  const isSearchError = queryString && !isQueryStringValid && !hasSearchErrored
  const isRefreshingSearches = useSelector(selectIsRefreshingSearches)

  const showAllScreenLoader =
    !isCurrentSearchLoaded && !isSearchError && !isRefreshingSearches

  if (showAllScreenLoader) {
    return (
      <div className={`${styles.leftCell} grui justify-center`}>
        <Loader />
      </div>
    )
  }

  return (
    <div className={styles.leftCell}>
      <SUI
        css={css`
          /* Auto-grow SUI wrapper to fill leftCell */
          &,
          & > * {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            min-height: 0px;
          }
        `}
      >
        <TicketListHeader isSidebarOpen />
        {isManager && (
          <>
            <LimitSearchHistoryCard className="grui mx-10 my-5 pt-10" />
            {showOnboardingWorkflow && <OnboardingWorkflowCard mini />}
          </>
        )}

        {queryString && hasSearchErrored && <SearchErrorMessage />}
        {isSearchError && (
          <Scroller className="desktopNormalScrollbarDisableRightBorder">
            <InvalidSearchMessage queryString={queryString} />
          </Scroller>
        )}
        {(!queryString || (!hasSearchErrored && isQueryStringValid)) && (
          <TicketList />
        )}
      </SUI>
      {!showOnboardingWorkflow && showDemoInboxCard && <DemoInboxCard mini />}
    </div>
  )
}
