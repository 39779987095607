import React from 'react'
import TeammatesDropdown from '../TeammatesDropdown'
import TagsDropdown from '../TagsDropdown'
import RatingsDropdown from '../RatingsDropdown'
import MailboxesDropdown from '../MailboxesDropdown'

const TriggerValue = ({
  inputType,
  onSelectedIdsChange,
  selectedIds,
  value,
  setValue,
}) => {
  if (inputType === 'teammate') {
    return (
      <TeammatesDropdown
        onSelectedIdsChange={onSelectedIdsChange}
        selectedIds={selectedIds}
        value={value}
        setValue={setValue}
      />
    )
  }

  if (inputType === 'tag') {
    return (
      <TagsDropdown
        fieldType="names"
        onSelectedIdsChange={onSelectedIdsChange}
        selectedIds={selectedIds}
        value={value}
        setValue={setValue}
      />
    )
  }

  if (inputType === 'rating') {
    return (
      <RatingsDropdown
        onSelectedIdsChange={onSelectedIdsChange}
        selectedIds={selectedIds}
        value={value}
        setValue={setValue}
      />
    )
  }

  if (inputType === 'mailbox') {
    return (
      <MailboxesDropdown
        onSelectedIdsChange={onSelectedIdsChange}
        selectedIds={selectedIds}
        value={value}
        setValue={setValue}
      />
    )
  }

  return null
}

export default TriggerValue
