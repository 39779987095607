import logo from 'assets/integrations/hubspot/icon-96.png'

import actionsScreenShot from 'assets/integrations/hubspot/screenshot-actions.jpg'
import linkScreenShot from 'assets/integrations/hubspot/screenshot-link.jpg'
import sidebarScreenShot from 'assets/integrations/hubspot/screenshot-sidebar.jpg'
import Carousel from 'components/Carousel'

import { Header } from 'shared/components/ui/Typography'

import {
  InstallLogo,
  InstallModalHead,
  InstallScreenShot,
  SectionLabel,
} from './styled'

export default function Description({ showPlanLimitation }) {
  return (
    <div>
      <InstallModalHead>
        <InstallLogo alt="HubSpot logo" src={logo} />
        <Header as="h2" size="small">
          HubSpot
          {showPlanLimitation && <span className="grui ml-3">⚡</span>}
        </Header>
        <p>
          Pull in HubSpot contact and company information right into your{' '}
          {app.t('Mailbox')}
          sidebar, and create a new contact for new email addresses.
        </p>
      </InstallModalHead>

      <Carousel width={392} height={244}>
        <Carousel.Item>
          <InstallScreenShot
            alt="A screen shot of the Trello actions in Groove"
            src={actionsScreenShot}
          />
        </Carousel.Item>
        <Carousel.Item>
          <InstallScreenShot
            alt="A screen shot of a linking Trello cards in Groove"
            src={linkScreenShot}
          />
        </Carousel.Item>
        <Carousel.Item>
          <InstallScreenShot
            alt="A screen shot of Trello cards in Groove"
            src={sidebarScreenShot}
          />
        </Carousel.Item>
      </Carousel>

      <SectionLabel size="medium" as="h4">
        How it works
      </SectionLabel>

      <p>
        With the HubSpot integration you can pull in additional information from
        HubSpot straight into your {app.t('Mailbox')} sidebar. This provides you
        with additional context right there where it&apos;s most useful.
      </p>
      <p>
        HubSpot is searched for a matching email address, and will display the
        following information:
      </p>
      <ul>
        <li>Contact information</li>
        <li>Contact list memberships</li>
        <li>Company information</li>
        <li>Recent deals</li>
      </ul>
      <p>
        If no contact is found you can create a new contact right from the
        sidebar.
      </p>
    </div>
  )
}
