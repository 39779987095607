import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util'

const reconnectButtonListItem = theme => css`
  border: dashed 2px ${theme.color.monochrome.medium};
  margin-top: 8px;
  border-radius: 4px;
  padding: ${rem(11)} ${rem(12)} ${rem(12)} ${rem(16)};
  display: flex;
  flex-direction: row;

  > svg {
    margin-right: 1rem;
    min-width: 25px;
    height: auto;
    align-self: flex-start;
    color: ${theme.color.monochrome.mediumDark};
  }

  > div {
    color: ${theme.color.monochrome.mediumDark};
    letter-spacing: 0;
  }

  > div > span {
    margin-bottom: 8px;
  }

  button {
    margin-top: 16px;
    width: 100%;
  }
`

const disabledButtonList = css`
  li > button {
    border-radius: 4px;
    cursor: not-allowed;
  }

  li > button > span {
    overflow: hidden;
    white-space: normal;
  }

  li > button > img {
    width: 25px;
    height: auto;
  }
`

const messageCard = css`
  padding-right: ${rem(30)};
`

const img = css`
  border-radius: 50%;
`

const buttonList = css`
  li > button {
    border-radius: 4px;
  }

  li > button > span {
    overflow: hidden;
    white-space: normal;
  }

  li > button > img {
    width: 25px;
    height: auto;
  }
`

export const styles = {
  reconnectButtonListItem,
  disabledButtonList,
  messageCard,
  img,
  buttonList,
}
