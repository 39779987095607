import moment from 'moment'
import { getType } from './type'
import { ordinal } from './ordinal'

export function isValidTime(time) {
  // Regular expression to match time in HH:MM format
  const pattern = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/
  return pattern.test(time)
}

export function toDate(value) {
  let date

  switch (getType(value)) {
    case 'date':
      return value
    case 'string':
      date = new Date(Date.parse(value))
      if (isNaN(date)) {
        date = new Date()
        if (isValidTime(value)) {
          const parts = value.split(':')
          date.setHours(parseInt(parts[0], 10))
          date.setMinutes(parseInt(parts[1], 10))
        }
      }
      return date
    case 'number':
      date = new Date(value)
      return isNaN(date) ? new Date() : date
    default:
      return new Date()
  }
}

export function toTimestamp(value) {
  const date = toDate(value)

  if (!date) return null

  return date.getTime()
}

export const DATE_UNITS = {
  milliseconds: 1,
  seconds: 1000,
  minutes: 60 * 1000,
  hours: 60 * 60 * 1000,
  days: 24 * 60 * 60 * 1000,
  weeks: 7 * 24 * 60 * 60 * 1000,
}

export const DATE_UNITS_SECONDS = {
  milliseconds: DATE_UNITS.milliseconds / DATE_UNITS.seconds,
  seconds: DATE_UNITS.seconds / DATE_UNITS.seconds,
  minutes: DATE_UNITS.minutes / DATE_UNITS.seconds,
  hours: DATE_UNITS.hours / DATE_UNITS.seconds,
  days: DATE_UNITS.days / DATE_UNITS.seconds,
  weeks: DATE_UNITS.weeks / DATE_UNITS.seconds,
}

export const diff = (unit, date1, date2) => {
  if (!DATE_UNITS[unit]) return NaN

  return Math.floor((toDate(date2) - toDate(date1)) / DATE_UNITS[unit])
}

function toDateTime(msecs) {
  const t = new Date(1970, 0, 1) // Epoch
  t.setTime(msecs)
  return t
}

export function timeAgo(time, now = new Date()) {
  const secondsDiff = diff('seconds', time, now)
  const dayDiff = diff('days', time, now)
  const weeksDiff = diff('weeks', time, now)

  if (isNaN(dayDiff) || dayDiff < 0) return ''

  return (
    (dayDiff === 0 &&
      ((secondsDiff < 60 && 'just now') ||
        (secondsDiff < 120 && '1 minute ago') ||
        (secondsDiff < 3600 && `${Math.floor(secondsDiff / 60)} minutes ago`) ||
        (secondsDiff < 7200 && '1 hour ago') ||
        (secondsDiff < 86400 &&
          `${Math.floor(secondsDiff / 3600)} hours ago`))) ||
    (dayDiff === 1 && 'yesterday') ||
    (dayDiff < 7 && `${dayDiff} days ago`) ||
    (dayDiff < 14 && '1 week ago') ||
    (dayDiff < 60 && `${Math.ceil(dayDiff / 7)} weeks ago`) ||
    (weeksDiff < 52 && `about ${Math.round(dayDiff / 30)} months ago`) ||
    (weeksDiff === 52 && '1 year ago') ||
    `${Math.ceil(weeksDiff / 52)} years ago`
  )
}

export function timeAgoAbbr(time, now = new Date()) {
  const secondsDiff = diff('seconds', time, now)
  const dayDiff = diff('days', time, now)
  const weeksDiff = diff('weeks', time, now)

  if (isNaN(dayDiff) || dayDiff < 0) return ''

  return (
    (dayDiff === 0 &&
      ((secondsDiff < 60 && '<1min') ||
        (secondsDiff < 120 && '1 min') ||
        (secondsDiff < 3600 && `${Math.floor(secondsDiff / 60)} min`) ||
        (secondsDiff < 7200 && '1 Hr') ||
        (secondsDiff < 86400 && `${Math.floor(secondsDiff / 3600)} Hrs`))) ||
    (dayDiff === 1 && '1 Day') ||
    (dayDiff < 7 && `${dayDiff} Days`) ||
    (dayDiff < 14 && '1 Week') ||
    (dayDiff < 60 && `${Math.ceil(dayDiff / 7)} Weeks`) ||
    (weeksDiff < 52 && `${Math.round(dayDiff / 30)} Months`) ||
    (weeksDiff === 52 && '1 Year') ||
    `${Math.ceil(weeksDiff / 52)} Years`
  )
}

export const midnight = now => {
  const then = new Date(now)
  then.setHours(24, 0, 0, 0)
  return then
}

export const daysNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

export const formatToDayName = timestamp => {
  return daysNames[toDateTime(timestamp).getDay()]
}

export const abbrMonthNames = 'Jan Feb Mar Apr May Jun Jul Aug Sep Oct Nov Dec'.split(
  /\s+/
)

const monthNames = 'January February March April May June July August September October November December'.split(
  /\s+/
)

// very limited strftime stub,
// only supports small subset of "real" strftime directives
export const strftime = (() => {
  function pad(value, length, padChar) {
    let valueStr = String(value)
    const padStr = String(padChar)
    while (valueStr.length < length) valueStr = padStr + valueStr
    return valueStr
  }

  function hours12(hour) {
    if (hour === 0) return 12
    if (hour > 12) return hour - 12
    return hour
  }

  return function strftimeFormat(format, timeStr) {
    const time = toDate(timeStr)
    const hours = time.getHours()
    const meridianIndicator = hours >= 12 ? 'pm' : 'am'

    return format
      .replace(/%HO/g, pad(hours, 2, '0'))
      .replace(/%H/g, pad(hours, 2, ' '))
      .replace(/%l/g, pad(hours12(hours), 2, ' '))
      .replace(/%M/g, pad(time.getMinutes(), 2, '0'))
      .replace(/%p/g, meridianIndicator.toUpperCase())
      .replace(/%P/g, meridianIndicator)
      .replace(/%b/g, abbrMonthNames[time.getMonth()])
      .replace(/%B/g, monthNames[time.getMonth()])
      .replace(/%d/g, pad(time.getDate(), 2, '0'))
      .replace(/%m/g, pad(time.getMonth() + 1, 2, '0'))
      .replace(/%y/g, pad(time.getFullYear() % 100, 2, '0'))
      .replace(/%Y/g, pad(time.getFullYear()))
      .replace(/%Do/g, () => ordinal(time.getDate()))
      .replace(/%D/g, () => strftime('%m/%d/%y', time))
      .replace(/%A/g, formatToDayName(timeStr))
  }
})()

export const formatTime = timestamp => strftime('%l:%M %P', timestamp)
export const formatTimeWithToday = timestamp =>
  strftime('Today, %l:%M %P', timestamp)

// http://ejohn.org/blog/javascript-pretty-date/
export function timeUntilInWords(now, time) {
  const dayDiff = diff('days', now, time)

  if (isNaN(dayDiff) || dayDiff < 0) return ''

  const date = toDate(time)
  const beforeMidnight = date < midnight(now)
  const formattedTime = formatTime(date)
  const unpaddedTime = formattedTime.trim()

  if (dayDiff < 1 && beforeMidnight) return `today at ${unpaddedTime}`
  if (dayDiff < 1 && !beforeMidnight) return `tomorrow at ${unpaddedTime}`
  if (dayDiff === 1) return `tomorrow at ${unpaddedTime}`
  return `in ${dayDiff} days at ${unpaddedTime}`
}

export function shiftedDate(time, offset) {
  const date = toDate(time)
  const existingOffset = date.getTimezoneOffset() * 60 * 1000
  const utcTimestamp = date.getTime() + existingOffset
  return new Date(utcTimestamp + (offset || 0) * 60 * 60 * 1000)
}

export const isToday = date => strftime('%D') === strftime('%D', date)
export const isThisYear = date => strftime('%Y') === strftime('%Y', date)
export const isYesterday = date => {
  const now = new Date()
  const yesterday = getOffsetTime(now, -1, 'days')
  return strftime('%D', yesterday) === strftime('%D', date)
}
export const isTomorrow = date => {
  const now = new Date()
  const tomorrow = getOffsetTime(now, 1, 'days')
  return strftime('%D', tomorrow) === strftime('%D', date)
}
export const isWeekend = date => {
  return date.getDay() === 0 || date.getDay() === 6
}
export const isWithinWeek = date => {
  const now = new Date()
  const end = getOffsetTime(now, 6, 'days')
  return new Date(date) < end
}

export const isFromDifferentYear = date => {
  const currentYear = Number(strftime('%Y'))
  const year = Number(strftime('%Y', date))
  return year !== currentYear
}

export const timeInHuman = (
  date,
  { yearFormat = '%Y', showToday = false, hideTodayTime = false } = {}
) => {
  const today = isToday(date)
  if (today && showToday && hideTodayTime) return 'Today'
  if (today && showToday && !hideTodayTime) return formatTimeWithToday(date)
  if (today && !showToday) return formatTime(date)
  if (isFromDifferentYear(date)) return strftime(`%b %Do ${yearFormat}`, date)
  return strftime('%b %Do', date)
}

export const timeInHumanExpanded = (date, includeYear = true) => {
  if (isToday(date)) {
    return { prefix: 'at', day: 'Today', dateTime: formatTime(date) }
  }
  if (includeYear) {
    return { prefix: 'on', dateTime: strftime('%b %Do %Y', date) }
  }
  return { prefix: 'on', dateTime: strftime('%b %Do', date) }
}

export const snoozeTimeInHuman = date => {
  if (isToday(date)) return { day: 'Today', time: strftime('%l:%M%p', date) }
  if (isTomorrow(date)) {
    return { day: 'Tomorrow', time: strftime('Tomorrow, %l:%M%p', date) }
  }
  if (isFromDifferentYear(date))
    return {
      day: strftime('%b %Do %Y', date),
      time: strftime('%l:%M%p', date),
    }
  return {
    day: strftime('%b %Do', date),
    time: strftime('%l:%M%p', date),
  }
}

const millisecondsUntilMidnight = now => midnight(now) - now

// Returns the number of milliseconds of the given offset e.g. (4, 'days')
const offsetMs = (num, unit) => {
  switch (unit) {
    case 'hour':
    case 'hours':
      return num * DATE_UNITS.hours
    case 'day':
    case 'days':
      return num * DATE_UNITS.days
    case 'week':
    case 'weeks':
      return num * DATE_UNITS.weeks
    default:
      return num
  }
}

export function getOffsetTime(now, numUnits, unit) {
  return toDateTime(now.getTime() + offsetMs(numUnits, unit))
}

export const getOffsetTimeFromMidnight = (now, numUnits, unit) =>
  toDateTime(
    now.getTime() + millisecondsUntilMidnight(now) + offsetMs(numUnits, unit)
  )

export const unixTimestampToMs = timestampStr => Number(timestampStr) * 1000

export const formatDateSeparator = date => {
  if (isToday(date)) return 'Today'
  if (isYesterday(date)) return 'Yesterday'
  if (isThisYear(date)) return strftime('%b %Do', date)
  return strftime('%b %Do, %Y', date)
}
global.formatDateSeparator = formatDateSeparator

export const toServerString = date =>
  [date.toISOString().split('.')[0], 'Z'].join('')

export function toTimePeriod(unit, every = 1) {
  if (every === 1) return unit === 'day' ? 'daily' : `${unit}ly`
  return `every ${every} ${unit}s`
}

const formatMomentObject = date => {
  const isDifferentYear = moment().year() !== date.year()
  const format = `Do MMM${isDifferentYear ? ', YYYY' : ''}`
  return date.format(format)
}

export const getTimestampDisplayString = stamp =>
  formatMomentObject(moment(stamp))

export const getUnixTimestampDisplayString = stamp =>
  formatMomentObject(moment.unix(stamp))

export function relativeToAbsolute(timeframe, today = moment()) {
  if (timeframe === 'all_time') {
    return {
      start: moment.tz('2017-12-01T00:00:00', today.tz()),
      end: moment(today)
        .subtract(1, 'day')
        .endOf('day'),
    }
  }
  const [thisOrPrevious, count, amount] = timeframe.split('_')
  const previous = thisOrPrevious === 'previous'
  const end = previous
    ? moment(today)
        .subtract(1, amount)
        .endOf(amount)
    : moment(today).endOf(amount)
  const start = moment(end)
    .subtract(count - 1, amount)
    .startOf(amount)
  return { start, end }
}
export function relativeToAbsoluteDate(timeframe, today = moment()) {
  const { start, end } = relativeToAbsolute(timeframe, today)
  return {
    start: start.format('YYYY-MM-DD'),
    end: end.format('YYYY-MM-DD'),
  }
}

export function isFirstOfMonth(day) {
  return day.getDate() === 1
}

export function isLastOfMonth(day) {
  const test = new Date(day.getTime())
  test.setDate(test.getDate() + 1)
  return test.getDate() === 1
}

export function advanceMonth(existingMonth, by) {
  const newMonth = new Date(existingMonth)
  const day = newMonth.getDate()
  newMonth.setMonth(existingMonth.getMonth() + by)
  // August 31st + 1 month => September 30th (not October 1st)
  // check if the new date has the same day as the original date
  if (newMonth.getDate() !== day) {
    // set the new date to the last day of the previous month
    newMonth.setDate(0)
  }
  return newMonth
}

export function parseUserTime(value) {
  if (!value || value === '') return null
  const matches = value.match(/([^: apm]+):?([^: apm]+)? ?(am|pm)?/i)
  if (!matches) return '00:00'
  const hours = parseInt(matches[1], 10)
  const minutes = parseInt(matches[2], 10)
  const aNHours = isNaN(hours) ? 0 : hours
  const aNMinutes = isNaN(minutes) ? 0 : minutes
  const constrainedHours = aNHours > 23 ? 23 : aNHours
  const constrainedMinutes = aNMinutes > 59 ? 59 : aNMinutes
  const constrainedMinutesByHours = aNHours > 23 ? 59 : constrainedMinutes
  const ampm = matches[3]
  const isPM = ampm && ampm.toLowerCase() === 'pm' && constrainedHours < 12
  const hours24 = isPM ? constrainedHours + 12 : constrainedHours
  return `${`0${hours24}`.slice(-2)}:${`0${constrainedMinutesByHours}`.slice(
    -2
  )}`
}

export function to12HourTime(value) {
  if (!value || value === '') return null
  const matches = value.trim().match(/^(\d{1,2}):(\d{2})$/)
  if (!matches) return '00:00 AM'
  const hours = parseInt(matches[1], 10)
  const hours12 = hours >= 13 ? hours - 12 : hours
  const minutes = parseInt(matches[2], 10)
  const isPM = hours >= 12
  return `${hours12}:${`0${minutes}`.slice(-2)} ${isPM ? 'PM' : 'AM'}`
}

// dayOfWeek: Monday - Sunday : 1 - 7 (using moment's)
export const adjustNextDayOfWeek = nextDayOfTheWeek => timestamp => {
  const date = moment(timestamp)
  const currDayOfTheWeek = date.isoWeekday()

  if (nextDayOfTheWeek > currDayOfTheWeek) {
    return moment(date)
      .isoWeekday(nextDayOfTheWeek)
      .toDate()
      .getTime()
  }
  return moment(date)
    .add(1, 'weeks')
    .isoWeekday(nextDayOfTheWeek)
    .toDate()
    .getTime()
}

export const mergeDateAndTime = (date, time) => {
  if (!date || (!date && !time)) {
    return null
  }

  const value = new Date(date)
  if (time) {
    const matches = time.split(':')
    if (matches && matches.length === 2) {
      const hours = parseInt(matches[0], 10)
      const minutes = parseInt(matches[1], 10)
      value.setHours(hours)
      value.setMinutes(minutes)
      value.setSeconds(0)
      value.setMilliseconds(0)
    } else {
      return null
    }
  }
  return value
}

export const minuteIntervalsInHours = (
  interval,
  options = { timeFormat: '24hour', outputFormat: 'string' }
) => {
  return Array.from(Array(24 * (60 / interval)).keys()).map(i => {
    const asHours = i / (60 / interval)
    const hour24 = Math.floor(asHours)
    const minutes = (asHours % 1) * 60
    const paddedMinutes = `0${minutes}`.slice(-2)
    const value = `${`0${hour24}`.slice(-2)}:${paddedMinutes}`

    let key

    if (options.timeFormat === '12hour') {
      let hour12 = hour24
      let period = 'AM'

      if (hour24 === 0) {
        // midnight
        hour12 = 12
      } else if (hour24 === 12) {
        // noon
        period = 'PM'
      } else if (hour24 > 12) {
        // PM
        hour12 = hour24 - 12
        period = 'PM'
      }

      key = `${`0${hour12}`.slice(-2)}:${paddedMinutes} ${period}`
    } else {
      key = value
    }

    if (options.outputFormat === 'dropdown') {
      return { name: key, value }
    }
    return key
  })
}

// e.g. if interval = 30: 00:00, 00:30...23:00, 23:30
export const minuteIntervalsIn24Hours = interval => {
  return minuteIntervalsInHours(interval, {
    timeFormat: '24hour',
    outputFormat: 'string',
  })
}

export const startOf = (date, unit) => {
  const newDate = new Date(date)
  switch (unit) {
    case 'second':
      newDate.setMilliseconds(0)
      break
    case 'minute':
      newDate.setSeconds(0, 0)
      break
    case 'hour':
      newDate.setMinutes(0, 0, 0)
      break
    case 'day':
      newDate.setHours(0, 0, 0, 0)
      break
    case 'month':
      newDate.setDate(1)
      newDate.setHours(0, 0, 0, 0)
      break
    default:
      // build new formats if not here
      throw new Error('Not Implemented')
  }

  return newDate
}

export const add = (date, amount, unit) => {
  const newDate = new Date(date)

  switch (unit) {
    case 'second':
      newDate.setTime(date.getTime() + amount * DATE_UNITS.seconds)
      break
    case 'minute':
      newDate.setTime(date.getTime() + amount * DATE_UNITS.minutes)
      break
    case 'hour':
      newDate.setTime(date.getTime() + amount * DATE_UNITS.hours)
      break
    case 'day':
      newDate.setTime(date.getTime() + amount * DATE_UNITS.days)
      break
    case 'month':
      newDate.setMonth(date.getMonth() + amount)
      if (newDate.getDate() !== date.getDate()) {
        newDate.setDate(0)
      }
      break
    default:
      // build new formats if not here
      throw new Error('Not Implmented')
  }

  return newDate
}

export const subtract = (date, amount, unit) => {
  return add(date, Math.abs(amount) * -1, unit)
}

export const dateToNearestHalfHour = date => {
  const newDate = startOf(date, 'hour') // from 0 to 15 minutes

  if (date.getMinutes() >= 15 && date.getMinutes() < 45) {
    return add(newDate, 30, 'minute')
  } else if (date.getMinutes() >= 45 && date.getMinutes() < 60) {
    return add(newDate, 1, 'hour')
  }

  return newDate
}

export const toNearestHalfHour = epochTimestamp => {
  const date = toDate(epochTimestamp)
  return dateToNearestHalfHour(date).getTime()
}

export const getPreviousMonthFirstDayDate = () => {
  const date = new Date()
  const year = date.getFullYear()
  const month = date.getMonth() - 1
  return new Date(year, month, 1)
}

export const getCurrentMonthFirstDayDate = () => {
  const date = new Date()
  const year = date.getFullYear()
  const month = date.getMonth()
  return new Date(year, month, 1)
}
