import { useEffect } from 'react'

const useSearchItems = ({ data, search, onSearchResultChange }) => {
  useEffect(
    () => {
      if (search?.trim()) {
        const filterdData = Object.keys(data).reduce((accumulator, key) => {
          // eslint-disable-next-line no-param-reassign
          accumulator[key] = data[key].filter(item =>
            item.name.toLowerCase().includes(search?.trim()?.toLowerCase())
          )
          return accumulator
        }, {})
        onSearchResultChange(filterdData)
      } else {
        onSearchResultChange(data)
      }
    },
    [data, search, onSearchResultChange]
  )
}

export default useSearchItems
