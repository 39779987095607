import React, { useCallback } from 'react'
import config from 'config'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import MessageCard from '@groovehq/internal-design-system/lib/components/MessageCard/MessageCard'
import {
  text,
  paragraph,
} from '@groovehq/internal-design-system/lib/styles/elements'
import { useDrawer } from 'ducks/drawers/hooks'
import { DRAWER_TYPE_SHOPIFY_STORE_ADD } from 'ducks/drawers/types'
import storage from 'util/storage'
import ShopifyStoresDataTable from '../../StoresDataTable'

const ShopifyConfigureGeneral = ({ providerId, internalAction }) => {
  const { openDrawer: openAddStoreDrawer } = useDrawer(
    DRAWER_TYPE_SHOPIFY_STORE_ADD
  )

  const onAddStore = useCallback(
    () => {
      openAddStoreDrawer(providerId)
    },
    [openAddStoreDrawer, providerId]
  )

  const useLegacyInstallMode = !!storage.get('showShopifyV2LegacyInstallMode')

  return (
    <>
      {internalAction === 'installed' && (
        <MessageCard type="info" closeable>
          Syncing customers is in progress.
        </MessageCard>
      )}
      <p
        className="grui mt-10 mb-12"
        css={[
          paragraph.styles.preflight,
          text.styles.textNormal,
          text.styles.textDark,
        ]}
      >
        Configure which of your Shopify stores you would like to display and
        manage within your Groove account.
      </p>
      <ShopifyStoresDataTable providerId={providerId} />
      <div className="grui mt-11">
        {!useLegacyInstallMode && (
          <Button
            as="a"
            href={config.shopify.appStoreGrooveAppUrl}
            type="secondary"
            size="small"
          >
            Add a store
          </Button>
        )}
        {useLegacyInstallMode && (
          <Button onClick={onAddStore} type="secondary" size="small">
            Add a store
          </Button>
        )}
      </div>
    </>
  )
}

export default ShopifyConfigureGeneral
