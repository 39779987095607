import React from 'react'
import {
  heading,
  text,
} from '@groovehq/internal-design-system/lib/styles/elements'
import { styles } from './style'

const TicketNotFound = () => {
  return (
    <div className="grui flex-grow text-center">
      <h1 css={[heading.styles.h1, styles.error]}>404</h1>
      <h2 css={heading.styles.h2} className="grui mt-2">
        No Conversation found
      </h2>
      <div css={text.styles.textNormal} className="grui mt-5">
        This conversation does not exist.
      </div>
    </div>
  )
}

export default TicketNotFound
