import { connect } from 'react-redux'
import ConversationListHeader from 'components/ConversationList/Header'
import TitleBar from './TitleBar'
import FilterBar from './FilterBar'

const select = () => {
  return {
    TitleBarComponent: TitleBar,
    FilterBarComponent: FilterBar,
  }
}
export default connect(select)(ConversationListHeader)
