import originalUseLocalStorageState, {
  createLocalStorageStateHook as originalCreateLocalStorageStateHook,
} from 'use-local-storage-state'

export function useLocalStorageState(key, defaultValue) {
  return originalUseLocalStorageState(`groove-${key}`, defaultValue)
}

export function createLocalStorageStateHook(key, defaultValue) {
  originalCreateLocalStorageStateHook(`groove-${key}`, defaultValue)
}
