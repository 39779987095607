import { hasHolds } from 'util/dirtyHolds'
import {
  SETTINGS_PAGE,
  SETTINGS_BILLING_PAGE,
  SETTINGS_CRM_PAGE,
  SETTINGS_INTEGRATIONS_PAGE,
  SETTINGS_INTEGRATIONS_PROVIDER_PAGE,
  SETTINGS_INTEGRATIONS_ADD_NEW_PAGE,
  SETTINGS_INTEGRATIONS_EDIT_PAGE,
  SETTINGS_WIDGETS_PAGE,
  SETTINGS_WIDGET_MANAGEMENT_PAGE,
  SETTINGS_CHAT_MANAGEMENT_PAGE,
  SETTINGS_CHAT_BOT_SKILL_PAGE,
  SETTINGS_WIDGET_CREATE_NEW_PAGE,
  SETTINGS_WIDGET_CREATE_CUSTOMIZE_PAGE,
  SETTINGS_WIDGET_CREATE_INSTALL_PAGE,
  SETTINGS_CANNEDREPLIES_PAGE,
  SETTINGS_FOLDERS_PAGE,
  SETTINGS_RULES_PAGE,
  SETTINGS_TEAMMATES_PAGE,
  SETTINGS_TAGS_PAGE,
  SETTINGS_INBOXES_PAGE,
  SETTINGS_CHANNELS_PAGE,
  SETTINGS_TEAMS_PAGE,
  SETTINGS_INBOX_MANAGEMENT_PAGE,
  SETTINGS_DEVELOPER_PAGE,
  SETTINGS_EXPORTS_PAGE,
  SETTINGS_COMPANY_PREFERENCES_PAGE,
  SETTINGS_BLACKLIST_PAGE,
  SETTINGS_PROFILE_PAGE,
  SETTINGS_AUTHENTICATION_PAGE,
  SETTINGS_PREFERENCES_PAGE,
  SETTINGS_NOTIFICATIONS_PAGE,
  SETTINGS_WORKFLOWS_PAGE,
  SETTINGS_REPORTING_PAGE,
  SETTINGS_CUSTOM_PROFILE_PAGE,
  SETTINGS_SATISFACTION_RATINGS_PAGE,
  SETTINGS_REDIRECT_INTEGRATION_PAGE,
  SETTINGS_BUSINESS_HOURS_PAGE,
  SETTINGS_IMPORT_PAGE,
} from './types'
// This file is required by core, so to perform
// appropriate bundling, it should not require any
// underlying subapp code

// routes get injecter into rudy
export const routes = {}

// pages get injected into constants
export const pages = {}

// handlers get injected into page reducers
export const handlers = {}

function confirmLeave() {
  // NOTE (jscheel): Not bothering with returning the actual hold message here,
  // as our custom displayConfirmLeave will handle it.
  return hasHolds()
}

// convenience function to allow defining of
// all 3 required pieces in one go
function register(name, config) {
  pages[name] = name
  routes[name] = config.route
  if (!config.route.confirmLeave) {
    routes[name].confirmLeave = confirmLeave
  }
  handlers[name] = config.handler
}

register(SETTINGS_PAGE, {
  route: {
    path: '/settings',
  },
  handler: () => ({
    componentName: 'HomePage',
    subapp: 'settings',
  }),
})

register(SETTINGS_BILLING_PAGE, {
  route: {
    path: '/settings/billing/:tab?',
  },
  handler: () => ({
    componentName: 'BillingPage',
    subapp: 'settings',
  }),
})

register(SETTINGS_WIDGETS_PAGE, {
  route: {
    path: '/settings/integrations/widgets',
  },
  handler: () => ({
    componentName: 'WidgetsPage',
    subapp: 'settings',
  }),
})

register(SETTINGS_WIDGET_CREATE_NEW_PAGE, {
  route: {
    path: '/settings/integrations/widgets/new',
  },
  handler: () => ({
    componentName: 'WidgetsCreatePage',
    subapp: 'settings',
  }),
})

register(SETTINGS_WIDGET_CREATE_CUSTOMIZE_PAGE, {
  route: {
    path: '/settings/integrations/widgets/new/customize',
  },
  handler: () => ({
    componentName: 'WidgetsCreatePage',
    subapp: 'settings',
  }),
})

register(SETTINGS_WIDGET_CREATE_INSTALL_PAGE, {
  route: {
    path: '/settings/integrations/widgets/install',
  },
  handler: () => ({
    componentName: 'WidgetsCreatePage',
    subapp: 'settings',
  }),
})

register(SETTINGS_WIDGET_MANAGEMENT_PAGE, {
  route: {
    path: '/settings/integrations/widgets/:id/:tab?',
  },
  handler: () => ({
    componentName: 'WidgetManagementPage',
    subapp: 'settings',
  }),
})

register(SETTINGS_CHAT_MANAGEMENT_PAGE, {
  route: {
    path: '/settings/chat/:id/:tab?',
  },
  handler: () => ({
    componentName: 'WidgetsChatPage',
    subapp: 'settings',
  }),
})

register(SETTINGS_CHAT_BOT_SKILL_PAGE, {
  route: {
    path: '/settings/chat/:id/bot/:tab/:subtab?',
  },
  handler: () => ({
    componentName: 'BotAutomationSkillPage',
    subapp: 'settings',
  }),
})

register(SETTINGS_INTEGRATIONS_ADD_NEW_PAGE, {
  route: {
    path: '/settings/integrations/p/:provider/new',
  },
  handler: () => ({
    componentName: 'IntegrationsAddNewPage',
    subapp: 'settings',
  }),
})

register(SETTINGS_INTEGRATIONS_EDIT_PAGE, {
  route: {
    path: '/settings/integrations/p/:provider/edit/:id',
  },
  handler: () => ({
    componentName: 'IntegrationsEditPage',
    subapp: 'settings',
  }),
})
register(SETTINGS_INTEGRATIONS_PROVIDER_PAGE, {
  route: {
    path: '/settings/integrations/p/:provider/:tab?/:id?',
  },
  handler: () => ({
    componentName: 'IntegrationsProviderPage',
    subapp: 'settings',
  }),
})

register(SETTINGS_REPORTING_PAGE, {
  route: {
    path: '/settings/reporting/:tab?',
  },
  handler: () => ({
    componentName: 'ReportingPage',
    subapp: 'settings',
  }),
})

register(SETTINGS_BUSINESS_HOURS_PAGE, {
  route: {
    path: '/settings/business-hours',
  },
  handler: () => ({
    componentName: 'BusinessHoursPage',
    subapp: 'settings',
  }),
})

register(SETTINGS_CRM_PAGE, {
  route: {
    path: '/settings/crm/:tab?/:id?/:modal?',
  },
  handler: () => ({
    componentName: 'CrmPage',
    subapp: 'settings',
  }),
})

register(SETTINGS_CANNEDREPLIES_PAGE, {
  route: {
    path: '/settings/email-templates/:tab?',
  },
  handler: () => ({
    componentName: 'CannedRepliesPage',
    subapp: 'settings',
  }),
})

register(SETTINGS_FOLDERS_PAGE, {
  route: {
    path: '/settings/folders/:tab?',
  },
  handler: () => ({
    componentName: 'FoldersPage',
    subapp: 'settings',
  }),
})

register(SETTINGS_RULES_PAGE, {
  route: {
    path: '/settings/rules/:tab?/:subtab?',
  },
  handler: () => ({
    componentName: 'RulesPage',
    subapp: 'settings',
  }),
})

register(SETTINGS_TAGS_PAGE, {
  route: {
    path: '/settings/tags/:tab?',
  },
  handler: () => ({
    componentName: 'TagsPage',
    subapp: 'settings',
  }),
})

register(SETTINGS_TEAMMATES_PAGE, {
  route: {
    path: '/settings/teammates/:tab?',
  },
  handler: () => ({
    componentName: 'TeammatesPage',
    subapp: 'settings',
  }),
})

register(SETTINGS_TEAMS_PAGE, {
  route: {
    path: '/settings/teams',
  },
  handler: () => ({
    componentName: 'TeamsPage',
    subapp: 'settings',
  }),
})

register(SETTINGS_INBOXES_PAGE, {
  route: {
    path: '/settings/inboxes/:tab?',
  },
  handler: () => ({
    componentName: 'InboxesPage',
    subapp: 'settings',
  }),
})

register(SETTINGS_CHANNELS_PAGE, {
  route: {
    path: '/settings/channels/:tab?',
  },
  handler: () => ({
    componentName: 'ChannelsPage',
    subapp: 'settings',
  }),
})

register(SETTINGS_INTEGRATIONS_PAGE, {
  route: {
    path: '/settings/integrations/:tab?',
  },
  handler: () => ({
    componentName: 'IntegrationsPage',
    subapp: 'settings',
  }),
})

register(SETTINGS_INBOX_MANAGEMENT_PAGE, {
  route: {
    path: '/settings/inbox/:id/:tab',
  },
  handler: () => ({
    componentName: 'InboxManagementPage',
    subapp: 'settings',
  }),
})

register(SETTINGS_DEVELOPER_PAGE, {
  route: {
    path: '/settings/developer/:tab?',
  },
  handler: () => ({
    componentName: 'DeveloperPage',
    subapp: 'settings',
  }),
})

register(SETTINGS_IMPORT_PAGE, {
  route: {
    path: '/settings/import/:tab?',
  },
  handler: () => ({
    componentName: 'ImportPage',
    subapp: 'settings',
  }),
})

register(SETTINGS_EXPORTS_PAGE, {
  route: {
    path: '/settings/exports',
  },
  handler: () => ({
    componentName: 'ExportsPage',
    subapp: 'settings',
  }),
})

register(SETTINGS_COMPANY_PREFERENCES_PAGE, {
  route: {
    path: '/settings/company-preferences',
  },
  handler: () => ({
    componentName: 'CompanyPreferencesPage',
    subapp: 'settings',
  }),
})

register(SETTINGS_BLACKLIST_PAGE, {
  route: {
    path: '/settings/blacklist',
  },
  handler: () => ({
    componentName: 'BlacklistPage',
    subapp: 'settings',
  }),
})

register(SETTINGS_PROFILE_PAGE, {
  route: {
    path: '/settings/profile/:tab?',
  },
  handler: () => ({
    componentName: 'ProfilePage',
    subapp: 'settings',
  }),
})

register(SETTINGS_AUTHENTICATION_PAGE, {
  route: {
    path: '/settings/authentication',
  },
  handler: () => ({
    componentName: 'AuthenticationPage',
    subapp: 'settings',
  }),
})

register(SETTINGS_PREFERENCES_PAGE, {
  route: {
    path: '/settings/preferences',
  },
  handler: () => ({
    componentName: 'PersonalPreferencesPage',
    subapp: 'settings',
  }),
})

register(SETTINGS_NOTIFICATIONS_PAGE, {
  route: {
    path: '/settings/notifications/:tab?',
  },
  handler: () => ({
    componentName: 'NotificationsPage',
    subapp: 'settings',
  }),
})

register(SETTINGS_WORKFLOWS_PAGE, {
  route: {
    path: '/settings/workflows',
  },
  handler: () => ({
    componentName: 'PersonalWorkflowsPage',
    subapp: 'settings',
  }),
})

register(SETTINGS_CUSTOM_PROFILE_PAGE, {
  route: {
    path: '/settings/custom-profile',
  },
  handler: () => ({
    componentName: 'CustomProfilePage',
    subapp: 'settings',
  }),
})

register(SETTINGS_SATISFACTION_RATINGS_PAGE, {
  route: {
    path: '/settings/satisfaction-ratings',
  },
  handler: () => ({
    componentName: 'CustomerRatingSettingsPage',
    subapp: 'settings',
  }),
})

register(SETTINGS_REDIRECT_INTEGRATION_PAGE, {
  route: {
    path: '/settings/redirect/:integrationId',
  },
  handler: () => ({
    componentName: 'RedirectIntegrationPage',
    subapp: 'settings',
  }),
})
