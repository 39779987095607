import { equalsCaseInsensitive } from 'util/strings'

export const hasDeletedCondition = conditions => {
  return (conditions || []).some(
    c =>
      equalsCaseInsensitive(c.param, 'deleted') &&
      equalsCaseInsensitive(c.operator, 'eq') &&
      equalsCaseInsensitive(c.value, 'true')
  )
}
