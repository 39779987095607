import { diff } from 'util/date'
import { SNOOZED_INDEFINITELY } from 'util/snooze'

export function getStateLabel(
  state,
  snoozedUntil = null,
  deletedAt = null,
  { mapUnreadToOpen = true } = {}
) {
  const remaining = snoozedUntil ? diff('seconds', new Date(), snoozedUntil) : 0
  if (deletedAt) return 'deleted'
  if (snoozedUntil === SNOOZED_INDEFINITELY) return 'snoozed'
  if (remaining > 0) return 'snoozed'
  if (state === 'opened') return 'open'
  if (state === 'unread' && mapUnreadToOpen) return 'open'
  if (state === 'follow_up') return 'open'
  if (state === 'pending') return 'open'
  return state
}

export function invertTicketState(stateName) {
  if (stateName === 'deleted') return 'opened'
  return stateName === 'open' ? 'closed' : 'opened'
}

// Eg: Return "Close" when state is "open"
// Return Re-open when ticket state is "closed"
export function getInvertStateActionLabel(
  state,
  snoozedUntil = null,
  deletedAt = null,
  { mapUnreadToOpen = true } = {}
) {
  const stateLabel = getStateLabel(state, snoozedUntil, deletedAt, {
    mapUnreadToOpen,
  })

  if (
    stateLabel === 'deleted' ||
    stateLabel === 'snoozed' ||
    stateLabel === 'closed'
  )
    return 'Re-open'
  return 'Close'
}

export const isOpen = ticket =>
  ticket && (ticket.state === 'opened' || ticket.state === 'unread')

export const isClosed = ticket => ticket && ticket.state === 'closed'

export const isSpam = ticket => ticket && ticket.state === 'spam'

export const isUnread = ticket => ticket && ticket.state === 'unread'

export const isStarred = ticket => ticket && ticket.priority === 'urgent'

export const isDeleted = ticket => ticket && !!ticket.deleted_at

export const isSnoozed = ticket =>
  ticket && !!ticket.snoozedUntil && !isDeleted(ticket)

export function isCloseable(stateName) {
  return ['open', 'snoozed'].includes(stateName)
}

export const inverseStateName = stateName => {
  if (stateName === 'deleted') return 'opened'
  return isCloseable(stateName) ? 'closed' : 'opened'
}
