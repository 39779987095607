import React, { useCallback } from 'react'
import { useFieldArray } from 'react-hook-form'
import { ruleTriggers, ruleTriggersParemetersSectionTitles } from './data'
import Trigger from '../../Trigger'
import AutomationItem from '../../AutomationItem'

const RuleTriggers = ({ control, trigger, setValue }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'triggers',
    // default keyName is 'id', which overrides and removes our ids
    // before submit, to prevent that we set it to 'key' and
    // let useFieldArray handles it by itself
    keyName: 'key',
  })
  const onAdd = useCallback(
    () => {
      // Don't add key value for new entity, useFieldArray will add it
      // so the form won't be dirty if only the key is different
      const newEntity = {
        type: null,
        settings: {},
      }
      append(newEntity)
    },
    [append]
  )

  const onRemove = useCallback(
    (_, index) => {
      remove(index)
    },
    [remove]
  )

  const onChange = useCallback(
    (newEntity, index) => {
      setValue(`triggers.${index}`, newEntity, {
        shouldDirty: true,
        shouldValidate: true,
      })
      trigger(`triggers.${index}`)
      trigger('conditions')
    },
    [setValue, trigger]
  )

  return (
    <div>
      {fields.length === 0 && (
        <AutomationItem.Button onClick={onAdd}>
          Add a trigger
        </AutomationItem.Button>
      )}
      {fields.map((item, index) => {
        const { id, key } = item
        return (
          <Trigger
            control={control}
            name={`triggers.${index}`}
            key={`trigger-${id}-${key}`}
            variables={ruleTriggers}
            parameterSectionTitles={ruleTriggersParemetersSectionTitles}
            index={index}
            onRemoveTrigger={onRemove}
            onTriggerChange={onChange}
            onInput={onChange}
            placeholder="Select a trigger..."
          />
        )
      })}
      {fields.length > 0 && (
        <AutomationItem.Button onClick={onAdd}>
          Add another trigger
        </AutomationItem.Button>
      )}
    </div>
  )
}

export default React.memo(RuleTriggers)
