import React, { useCallback } from 'react'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import { useDispatch, useSelector } from 'react-redux'
import EntityDelete from 'subapps/settings/components/drawers/EntityDelete'
import {
  selectWidgetById,
  selectWidgetSettingsById,
} from 'ducks/widgets/selectors'
import { doUpdateSettings, doDeleteWidget } from 'ducks/widgets/operations'
import { isBridgeChannelType } from 'ducks/channels/channelTypes'

const LiveChatConformDelete = ({
  open,
  drawerResourceId,
  onClose,
  onExit,
  onRemove,
}) => {
  const dispatch = useDispatch()
  const widget =
    useSelector(state => selectWidgetById(state, drawerResourceId)) || {}
  const channelName = widget.name
  const widgetSettings = useSelector(state =>
    selectWidgetSettingsById(state, drawerResourceId)
  )
  const isChatDisabledInSettings = !widgetSettings['chat.enabled']

  const handleRemove = useCallback(
    async () => {
      let response = null
      if (isBridgeChannelType(widget?.kind)) {
        response = await dispatch(doDeleteWidget(drawerResourceId))
      } else {
        if (isChatDisabledInSettings) {
          // MERGE commit won't work if the value in the settings is the same as the value we want to merge and publish
          // So need to set the value to be true first
          await dispatch(
            doUpdateSettings({ 'chat.enabled': true }, drawerResourceId)
          )
        }
        response = await dispatch(
          doUpdateSettings({ 'chat.enabled': false }, drawerResourceId, {
            commitType: 'MERGE',
            shouldShowToasts: true,
            toastsConfig: {
              success: {
                content: 'Live Chat deleted',
              },
              failed: {
                content: 'Live Chat delete failed',
              },
            },
          })
        )
      }

      if (onRemove) onRemove()
      return response
    },
    [
      dispatch,
      drawerResourceId,
      onRemove,
      isChatDisabledInSettings,
      widget?.kind,
    ]
  )

  if (!widget) return null

  return (
    <EntityDelete
      title="Delete Channel"
      onRemove={handleRemove}
      onClose={onExit}
      onExit={onExit}
      onCancel={onClose}
      deleteBtn
      tertiaryBtnText="Cancel"
      open={open}
      hardDelete
    >
      <div css={text.styles.textNormal} className="grui mb-11">
        {`You are about to delete your channel “${channelName}” and all associated conversations.`}
      </div>
      This action is permanent and cannot be undone.
    </EntityDelete>
  )
}

export default LiveChatConformDelete
