import config from 'config'
import useFrillWidget from './useFrillWidget'

const { frillWidgets } = config

const useFrillNotification = () => {
  const { onNotify, loaded } = useFrillWidget(frillWidgets.roadmap.key, {
    identifyUser: false,
  })

  return { onNotify, loaded }
}

export default useFrillNotification
