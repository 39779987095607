import React from 'react'
import { useSelector } from 'react-redux'
import { string, arrayOf, oneOf } from 'prop-types'
import {
  text,
  paragraph,
} from '@groovehq/internal-design-system/lib/styles/elements'
import { selectSearchByQueryId } from 'ducks/searches/selectors'
import useFetchEntitiesByIds from 'ducks/entities/hooks/useFetchEntitiesByIds'
import { doFetchTagsV2ByIds } from 'ducks/tags/actions'
import { FETCH_TAGS_BY_IDS_NAMES } from 'ducks/tags/actionTypes'
import { selectRawAgentsById } from 'selectors/agents/base'
import { pluralize } from 'util/strings'
import { strftime } from 'util/date'

const TagDeleteContent = ({ ids, queryId, deleteMode }) => {
  const { requestState, entities } = useFetchEntitiesByIds(
    ids,
    'tag',
    doFetchTagsV2ByIds,
    FETCH_TAGS_BY_IDS_NAMES
  )
  const entitiesArr = Object.values(entities)

  const search = useSelector(state => selectSearchByQueryId(state, queryId))
  const { totalCount: searchTotal } = search
  const agentsById = useSelector(selectRawAgentsById)

  const isIdDelete = deleteMode === 'ids'

  if (entitiesArr.length === 0 && requestState.loading) {
    return <span css={text.styles.fontMedium}>Loading...</span>
  }

  // TODO (jscheel): This condition is triggering when closing the drawer... why?
  if (entitiesArr.length === 0) {
    return <span css={text.styles.fontMedium}>No results...</span>
  }

  if (isIdDelete && ids.length === 1) {
    const tag = entitiesArr[0]
    const countUsed = tag.conversationCount
    const { name: agentName } = agentsById[tag.creator] || {
      name: `an unknown ${app.t('agent')}`,
    }
    return (
      <>
        <p css={[paragraph.styles.preflight, text.styles.textNormal]}>
          You are about to delete the tag “<span css={text.styles.fontMedium}>
            {tag.name}
          </span>”.
        </p>
        <p css={[text.styles.textNormal]}>
          This tag was created by{' '}
          <span css={text.styles.fontMedium}>{agentName}</span> and has{' '}
          {countUsed === 0 && <>never been used.</>}
          {countUsed > 0 && (
            <>
              been applied to{' '}
              <span css={text.styles.fontMedium}>{countUsed}</span>{' '}
              {pluralize(tag.conversationCount, 'conversation', 's')}
              , last on the{' '}
              <span css={text.styles.fontMedium}>
                {strftime('%Do of %B %Y', tag.lastUsedAt)}
              </span>.
            </>
          )}
        </p>
        <p css={paragraph.styles.preflight} className="grui mt-14 mb-8">
          Are you sure you want to proceed with this action?
        </p>
      </>
    )
  }
  const totalToBeRemoved = isIdDelete ? ids.length : searchTotal
  const additional = totalToBeRemoved - entitiesArr.length

  return (
    <div css={text.styles.textNormal}>
      You are about to delete:
      {entitiesArr.map(row => (
        <p key={row.id} css={paragraph.styles.preflight}>
          <span css={text.styles.fontMedium}>
            <>“</>
            {row.name}
            <>”</>
          </span>
        </p>
      ))}
      {additional > 0 && <p>and {additional} more items.</p>}
    </div>
  )
}

TagDeleteContent.propTypes = {
  deleteMode: oneOf(['ids', 'query']).isRequired,
  ids: arrayOf(string).isRequired,
  queryId: string,
}

TagDeleteContent.defaultProps = {
  queryId: null,
}

export default TagDeleteContent
