import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectMergingSearchTerm } from 'ducks/merging/selectors/base'
import { selectMergeableForCurrentCustomer } from 'ducks/merging/selectors/search'
import {
  doUpdateSearchTerm,
  doLoadMergeSearchByQueryString,
  doFetchMergeSearchByQueryString,
  doFetchMergeableTickets,
} from 'ducks/merging/operations'
import { debounce } from 'util/functions'
import { fixedEncodeURIComponent } from 'util/params'

import MenuWithSearch from 'subapps/settings/components/MenuWithSearch'
import List from './List'

import { styles } from '../styles'

const SearchableList = ({
  onSearchBoxChange,
  placeholder,
  currentMergeSearch,
  ...rest
}) => {
  const dispatch = useDispatch()
  const searchTerm = useSelector(selectMergingSearchTerm)
  const mergeable = useSelector(selectMergeableForCurrentCustomer)

  const handleSearch = useCallback(
    () => {
      dispatch(doLoadMergeSearchByQueryString())
    },
    [dispatch]
  )

  const debouncedSearch = useMemo(
    () => {
      return debounce(handleSearch, 500)
    },
    [handleSearch]
  )

  const onSearch = useCallback(
    async event => {
      const value = event.target.value

      if (value && value.length > 0) {
        const encodedQuery = `${fixedEncodeURIComponent(value)}`
        await dispatch(doUpdateSearchTerm(encodedQuery))
        return debouncedSearch(encodedQuery)
      }

      return dispatch(doUpdateSearchTerm(event.target.value))
    },
    [dispatch, debouncedSearch]
  )

  const onLoadMoreSearch = useCallback(
    () => {
      dispatch(
        doFetchMergeSearchByQueryString(currentMergeSearch.currentPage + 1)
      )
    },
    [dispatch, currentMergeSearch]
  )

  const onLoadMoreMergeableTickets = useCallback(
    () => {
      dispatch(doFetchMergeableTickets(mergeable.currentPage + 1))
    },
    [dispatch, mergeable]
  )

  const hasMoreSearch = useMemo(
    () => currentMergeSearch.currentPage < currentMergeSearch.totalPages,
    [currentMergeSearch]
  )

  const hasMoreMergeableTickets = useMemo(
    () => mergeable.currentPage < mergeable.totalPages,
    [mergeable]
  )

  useEffect(
    () => {
      return () => {
        dispatch(doUpdateSearchTerm(''))
      }
    },
    [dispatch]
  )

  return (
    <>
      <div className="grui flex justify-center mt-6">
        <MenuWithSearch.Search
          value={searchTerm}
          onChange={onSearch}
          placeholder={placeholder}
          shouldFocus
        />
      </div>

      <div className="grui mt-7" css={styles.divider} />

      <List
        {...rest}
        searchTerm={searchTerm}
        hasMoreSearch={hasMoreSearch}
        hasMoreMergeableTickets={hasMoreMergeableTickets}
        onLoadMoreSearch={onLoadMoreSearch}
        onLoadMoreMergeableTickets={onLoadMoreMergeableTickets}
        currentMergeSearch={currentMergeSearch}
      />
    </>
  )
}

export default SearchableList
