import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util'

const dropdownContainer = css`
  flex: 0 0 calc((100% - ${rem(36)}) / 2);
  max-width: initial;
`

const row = css`
  display: flex;
  align-items: center;
  padding-bottom: ${rem(4)};

  & > div[role='presentation'] {
    width: 100%;
    max-width: 50%;
  }
`

const text = css`
  margin-right: ${rem(4)};
  white-space: nowrap;
`

const textWithPadding = css`
  padding-left: ${rem(8)};
`

const textAfterIcon = css`
  margin-left: ${rem(4)};
`

export const styles = {
  dropdownContainer,
  row,
  text,
  textWithPadding,
  textAfterIcon,
}
