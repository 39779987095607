import { Flex, Label } from 'shared/ui'
import { Checkbox } from 'util/ui'

import ActionAccessWarning from '../../shared/ActionAccessWarning'
import {
  CheckboxColumn,
  LabelColumn,
  SettingRow,
  Tabs,
} from '../../shared/SettingsModal'

export default function ActionsTab({ active, formFields, onChange }) {
  return (
    <Tabs.Content active={active}>
      <p>
        Set actions to <strong>Re-open</strong> again if {app.t('a_ticket')} has
        been closed and/or <strong>Show</strong> actions in the conversation
        view.
      </p>
      <ActionAccessWarning />

      <Flex>
        <LabelColumn>
          <Label as="h4">&nbsp;</Label>
          <SettingRow>Card has been archived</SettingRow>
          <SettingRow>List has been archived</SettingRow>
          <SettingRow>Board has been closed</SettingRow>

          <SettingRow>Card has been unarchived</SettingRow>
          <SettingRow>List has been unarchived</SettingRow>
          <SettingRow>Board has been re-opened</SettingRow>

          <SettingRow>Card list has has been changed</SettingRow>
          <SettingRow>Card board has been changed</SettingRow>
          <SettingRow>Due date has been changed</SettingRow>
          <SettingRow>Members has been changed</SettingRow>
          <SettingRow>Labels has been changed</SettingRow>
          <SettingRow>Title has been changed</SettingRow>
          <SettingRow>Description has been changed</SettingRow>
          <SettingRow>Comment has been added</SettingRow>
        </LabelColumn>
        <CheckboxColumn>
          <Label as="h4">Re-open</Label>
          <SettingRow>
            <Checkbox
              checked={formFields['reopenOn.cardArchived']}
              name="reopenOn.cardArchived"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['reopenOn.listArchived']}
              name="reopenOn.listArchived"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['reopenOn.boardArchived']}
              name="reopenOn.boardArchived"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['reopenOn.cardUnarchived']}
              name="reopenOn.cardUnarchived"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['reopenOn.listUnarchived']}
              name="reopenOn.listUnarchived"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['reopenOn.boardUnarchived']}
              name="reopenOn.boardUnarchived"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['reopenOn.listChanged']}
              name="reopenOn.listChanged"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['reopenOn.boardChanged']}
              name="reopenOn.boardChanged"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['reopenOn.dueDateChanged']}
              name="reopenOn.dueDateChanged"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['reopenOn.membersChanged']}
              name="reopenOn.membersChanged"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['reopenOn.labelsChanged']}
              name="reopenOn.labelsChanged"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['reopenOn.titleChanged']}
              name="reopenOn.titleChanged"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['reopenOn.descriptionChanged']}
              name="reopenOn.descriptionChanged"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['reopenOn.commentAdded']}
              name="reopenOn.commentAdded"
              onChange={onChange}
            />
          </SettingRow>
        </CheckboxColumn>
        <CheckboxColumn>
          <Label as="h4">Show</Label>
          <SettingRow>
            <Checkbox
              checked={formFields['showAction.cardArchived']}
              name="showAction.cardArchived"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['showAction.listArchived']}
              name="showAction.listArchived"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['showAction.boardArchived']}
              name="showAction.boardArchived"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['showAction.cardUnarchived']}
              name="showAction.cardUnarchived"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['showAction.listUnarchived']}
              name="showAction.listUnarchived"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['showAction.boardUnarchived']}
              name="showAction.boardUnarchived"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['showAction.listChanged']}
              name="showAction.listChanged"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['showAction.boardChanged']}
              name="showAction.boardChanged"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['showAction.dueDateChanged']}
              name="showAction.dueDateChanged"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['showAction.membersChanged']}
              name="showAction.membersChanged"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['showAction.labelsChanged']}
              name="showAction.labelsChanged"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['showAction.titleChanged']}
              name="showAction.titleChanged"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['showAction.descriptionChanged']}
              name="showAction.descriptionChanged"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['showAction.commentAdded']}
              name="showAction.commentAdded"
              onChange={onChange}
            />
          </SettingRow>
        </CheckboxColumn>
      </Flex>
    </Tabs.Content>
  )
}
