import { doUpdateAppData } from 'actions/app'
import { oauthTokenSelector } from 'selectors/app'
import { basicFields as accountFields } from 'api/account'
import grooveAPI from 'api/groove'
import { signupFields } from 'ducks/currentUser/api'
import { doGraphqlRequest } from 'ducks/requests/operations'
import { BOOTSTRAP_ONBOARDING, ONBOARDING_COMPLETE_SUCCESS } from './types'

export const doBootstrapOnboarding = ({ disableRedirect = false } = {}) => {
  return dispatch => {
    return dispatch(
      doGraphqlRequest(
        BOOTSTRAP_ONBOARDING,
        `
          query BootstrapOnboardingQuery {
            user {
              ${signupFields}
              first_name
            }
            account {
              ${accountFields}
            }
          }
        `
      )
    ).then(data => {
      if (!disableRedirect && data.account?.is_onboarding_completed) {
        window.location = '/'
      }
      return dispatch(doUpdateAppData(data, { preventRedirect: true }))
    })
  }
}

export const doSetOnboardingCompleteFlag = () => {
  return (dispatch, getState) => {
    const state = getState()
    const token = oauthTokenSelector(state)
    return grooveAPI
      .post(token, 'v1/account/complete_onboarding', {})
      .then(() => {
        dispatch({
          type: ONBOARDING_COMPLETE_SUCCESS,
        })
      })
  }
}
