import Switch from '@groovehq/internal-design-system/lib/components/Switch/Switch'
import { useController } from 'react-hook-form'
import { styles } from './styles'

export default function StateSwitch({ control, className }) {
  const {
    field: { value, ...switchProps },
  } = useController({
    name: 'active',
    control,
    defaultValue: true,
  })

  return (
    <Switch
      id="rule-state"
      type="secondary"
      value={value.toString()}
      defaultChecked={value}
      className={className}
      {...switchProps}
    >
      <span css={styles.stateSwitchText}>
        Rule is {value ? 'active' : 'inactive'}
      </span>
    </Switch>
  )
}
