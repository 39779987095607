import { css } from '@emotion/react'

const featureUnAvailableItem = css`
  &.disabled {
    display: block;
    pointer-events: initial;
    cursor: initial;

    &&&:hover {
      background-color: transparent;
    }
  }
`

export const styles = {
  featureUnAvailableItem,
}
