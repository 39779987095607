import React from 'react'

import KeyboardHandler from 'components/Lists/KeyboardHandler'

import { getLength } from 'util/arrays'
import { getDisplayName } from 'util/hoc'

// Props Proxy HOC for scrolling
// Sauce https://medium.com/@franleplant/react-higher-order-components-in-depth-cf9032ee6c3e
function withListController(WrappedList) {
  class WithListController extends React.Component {
    state = {
      focusedIndex: null,
      mouseX: null,
      mouseY: null,
    }

    componentDidMount() {
      this.focusFirstItem()
    }

    componentWillReceiveProps(newProps, oldProps) {
      if (oldProps.isSearching && !newProps.isSearching) {
        this.focusFirstItem()
      }
    }

    checkMouseMoved = evt => {
      const x = evt.clientX
      const y = evt.clientY
      const mouseMoved = x !== this.state.mouseX && y !== this.state.mouseY
      if (mouseMoved) {
        this.setState({ mouseX: x, mouseY: y })
        return true
      }

      return false
    }

    focusFirstItem = () => {
      const { items } = this.props
      if (items) this.handleFocus(0)
    }

    handleFocus = (focusedIndex, evt = null, focusedViaMouse = false) => {
      if (focusedViaMouse) {
        // Dont focus this item if the mouse didnt move (i.e. a scroll event
        // caused the onMouseOver to fire)
        if (!this.checkMouseMoved(evt)) return
      }
      this.setState({ focusedIndex, focusedViaMouse })
    }

    handleUp = (focusedIndex, evt = null, focusedViaMouse = false) => {
      this.handleFocus(focusedIndex, evt, focusedViaMouse)
      const { onUp } = this.props
      if (onUp) onUp(focusedIndex)
    }

    handleDown = (focusedIndex, evt = null, focusedViaMouse = false) => {
      this.handleFocus(focusedIndex, evt, focusedViaMouse)
      const { onDown } = this.props
      if (onDown) onDown(focusedIndex)
    }

    handleSelect = (index, evt) => {
      if (isNaN(index)) return false
      const { delayedTicketIds, items, onSelect, onClose } = this.props

      if (!onSelect) return false
      const id = items[index].id
      const sendingDelayed = delayedTicketIds.includes(id)
      if (sendingDelayed) return false
      if (evt) {
        // handle click events. (KB handler already strips this)
        evt.preventDefault()
        evt.stopPropagation()
      }
      const result = onSelect(items[index].id)
      if (onClose) onClose()
      return result
    }

    render() {
      const props = this.props
      const { onClose, items, ignoreTabEnter } = props
      const { focusedIndex } = this.state

      return (
        <React.Fragment>
          <KeyboardHandler
            focusedIndex={focusedIndex}
            count={getLength(items)}
            onEnter={!ignoreTabEnter && this.handleSelect}
            onTab={!ignoreTabEnter && this.handleSelect}
            onUp={this.handleUp}
            onDown={this.handleDown}
            onEsc={onClose}
          />
          <WrappedList
            focusedIndex={focusedIndex}
            onItemClick={this.handleSelect}
            {...props}
          />
        </React.Fragment>
      )
    }
  }

  WithListController.displayName = `WithListController(${getDisplayName(
    WrappedList
  )})`

  return WithListController
}

export default withListController
