import { selectCurrentUser2faEnforced } from 'ducks/currentUser/selectors/base'
import { redirect } from 'redux-first-router'
import { selectCurrentPage, selectCurrentPayload } from 'selectors/location'
import { to2faPage } from './redirect'

// If 2fa is enforced and the user hasn't enabled it, redirect to 2fa page
const doRedirectTo2faPageIfNeccesary = () => {
  return (dispatch, getState) => {
    const state = getState()
    if (!selectCurrentUser2faEnforced(state)) {
      return null
    }
    const currentPage = selectCurrentPage(state)
    const tab = selectCurrentPayload(state).tab
    const is2faSettingsPage =
      currentPage === to2faPage.type && tab === to2faPage.payload.tab
    if (is2faSettingsPage) return null
    return dispatch(redirect(to2faPage))
  }
}

export default doRedirectTo2faPageIfNeccesary
