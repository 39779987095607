// getType is stolen from jQuery
const class2type = {}
const toString = Object.prototype.toString
// Populate the class2type map

'Boolean Number String Function Array Date RegExp Object'
  .split(' ')
  .forEach(name => {
    class2type[`[object ${name}]`] = name.toLowerCase()
  })

const getType = function(obj) {
  return obj == null ? String(obj) : class2type[toString.call(obj)] || 'object'
}

const isNumeric = function(n) {
  return !isNaN(parseFloat(n)) && isFinite(n)
}

module.exports = {
  getType,
  isNumeric,
}
