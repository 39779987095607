import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { string, bool } from 'prop-types'
import { doDeleteChannel } from 'ducks/channels/actions'
import EntityDeleteDrawer from '../../EntityDelete'
import DeleteChannelContent from './Content'

const DeleteChannelDrawer = props => {
  const {
    title,
    deleteBtnText,
    open,
    drawerResourceId: channelId,
    onClose,
    onExit,
    onRemove,
  } = props

  const dispatch = useDispatch()

  const handleOnRemove = useCallback(
    () => {
      dispatch(
        doDeleteChannel(channelId, {
          moduleOptions: {
            toasts: {
              enabled: true,
              started: {
                enabled: true,
                content: `${app.t('Mailbox')} deleted`,
              },
              success: {
                enabled: false,
              },
              failed: {
                content: `${app.t('Mailbox')} deletion failed`,
                onClickAction: () => {
                  dispatch(doDeleteChannel(channelId))
                },
              },
            },
            entities: {
              targetOperation: 'remove',
            },
          },
        })
      )
      if (onRemove) onRemove()
    },
    [dispatch, channelId, onRemove]
  )

  return (
    <EntityDeleteDrawer
      {...props}
      hardDelete
      onRemove={handleOnRemove}
      onClose={onClose}
      tertiaryBtnText="Cancel"
      onCancel={onExit}
      title={title}
      deleteBtnText={deleteBtnText}
      open={open}
    >
      <DeleteChannelContent channelId={channelId} />
    </EntityDeleteDrawer>
  )
}

DeleteChannelDrawer.propTypes = {
  title: string,
  deleteBtnText: string,
  open: bool.isRequired,
  drawerResourceId: string,
}

DeleteChannelDrawer.defaultProps = {
  title: `Delete ${app.t('mailbox')}`,
  deleteBtnText: 'Delete',
  drawerResourceId: '',
  queryId: null,
}

export default DeleteChannelDrawer
