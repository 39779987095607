import { connect } from 'react-redux'
import { doStopEditingTicketTitle } from 'actions/app'
import { emptyObj, pick } from 'util/objects'

import {
  doCloseTicket,
  doMarkTicketAsSpam,
  doOpenTicket,
  doStarTicket,
  doUnstarTicket,
  doToggleTicketState,
  doSnoozeTicket,
  doUnsnoozeAndUnread,
  doDeleteCurrentTicket,
  doRestoreTicket,
  doOpenTicketMergeDrawer,
} from 'actions/tickets'
import {
  doOpenChangeInboxMenu,
  doOpenMoreTicketOptionsMenu,
} from 'actions/menus'

import {
  selectIsEditingTicketTitle,
  selectIsRightSidebarCollapsed,
  selectGroup,
} from 'selectors/app'
import { selectAgent } from 'selectors/agents/base'
import { selectMergingIsMerging } from 'ducks/merging/selectors/base'
import { selectPrefersClassicView } from 'ducks/currentUser/selectors/preferences/selectPrefersClassicView'
import {
  selectCurrentTicketAssignment,
  selectCurrentTicketAssignedGroupId,
  selectCurrentTicketIsStarred,
  selectCurrentTicketIsSnoozed,
} from 'selectors/tickets'
import { selectCurrentTicketUndecorated } from 'selectors/tickets/current/selectCurrentTicketUndecorated'
import { selectIsTagsAssignOpen } from 'selectors/modals_base'
import { doShowTagsAssignModal, doHideTagsAssignModal } from 'actions/modals'
import { doOpenNextTicket, doOpenPreviousTicket } from 'actions/autoRedirect'
import ConversationHeader from 'components/ConversationHeader'
import StateButton from 'components/ConversationHeader/Buttons/StateButton'
import TagsButton from 'components/ConversationHeader/Buttons/TagsButton'
import SnoozeButton from 'components/ConversationHeader/Buttons/SnoozeButton'
import StarButton from 'components/ConversationHeader/Buttons/StarButton'
import { selectCurrentUserIsAdminOrOwnerOrAgent } from 'ducks/currentUser/selectors'

import AssignmentButton from './Buttons/AssignmentButton'
import MoreButton from './Buttons/MoreButton'
import PagingButtons from './Buttons/PagingButtons'
import BackButton from './Buttons/BackButton'

const select = state => {
  const ticket = selectCurrentTicketUndecorated(state) || emptyObj
  const assignment = selectCurrentTicketAssignment(state)
  const assignedGroupId = selectCurrentTicketAssignedGroupId(state)
  const agent = selectAgent(state, ticket.assigneeId)
  const group =
    ticket.assignedGroupId && selectGroup(state, ticket.assignedGroupId)
  let assignmentAgentName = agent ? agent.label : null
  if (group) assignmentAgentName = group.name
  const isAssigned = (!!assignment && !!agent) || (!!assignedGroupId && group)
  const isAdminOrOwnerOrAgent = selectCurrentUserIsAdminOrOwnerOrAgent(state)
  return {
    conversationId: ticket.id,
    isAssigned,
    assignmentAgentName,
    assignedGroupId,
    isEditingTitle: selectIsEditingTicketTitle(state),
    isRightSidebarCollapsed: selectIsRightSidebarCollapsed(state),
    isStarred: selectCurrentTicketIsStarred(state),
    is3ColumnView: selectPrefersClassicView(state),
    isMergeOpen: selectMergingIsMerging(state),
    isSnoozed: selectCurrentTicketIsSnoozed(state),
    isTagsAssignModalOpen: selectIsTagsAssignOpen(state),
    ...pick(
      ['stateLabel', 'isCloseable', 'isDeleted', 'isSpam', 'snoozedUntil'],
      ticket
    ),
    StateButton,
    TagsButton,
    SnoozeButton,
    StarButton,
    AssignmentButton,
    BackButton,
    MoreButton,
    PagingButtons,
    disableMerge: !isAdminOrOwnerOrAgent,
    disableStar: !isAdminOrOwnerOrAgent,
    disableTags: !isAdminOrOwnerOrAgent,
    disableSpam: !isAdminOrOwnerOrAgent,
    disableDelete: !isAdminOrOwnerOrAgent,
    disableMore: !isAdminOrOwnerOrAgent,
    disableSnooze: !isAdminOrOwnerOrAgent,
    disableState: !isAdminOrOwnerOrAgent,
    disableAssignment: !isAdminOrOwnerOrAgent,
    disableHardDelete: !isAdminOrOwnerOrAgent,
    disableRestore: !isAdminOrOwnerOrAgent,
  }
}

const perform = {
  onStopEditingTitle: doStopEditingTicketTitle,
  onToggleState: doToggleTicketState,
  onClose: doCloseTicket,
  onSpam: doMarkTicketAsSpam,
  onOpen: doOpenTicket,
  onStar: doStarTicket,
  onUnstarred: doUnstarTicket,
  onOpenChangeInboxMenu: doOpenChangeInboxMenu,
  onOpenConversationOptionsMenu: doOpenMoreTicketOptionsMenu,
  onSnooze: (ticketId, snoozeOption) => doSnoozeTicket(snoozeOption, ticketId),
  onOpenMerge: doOpenTicketMergeDrawer,
  onUnsnooze: doUnsnoozeAndUnread,
  onSoftDelete: () => doDeleteCurrentTicket('SOFT'),
  onHardDelete: () => doDeleteCurrentTicket('HARD'),
  onRestoreDeleted: doRestoreTicket,
  onRestoreSpam: doOpenTicket,
  onOpenNextConversation: doOpenNextTicket,
  onOpenPreviousConversation: doOpenPreviousTicket,
  onShowTagsAssignModal: doShowTagsAssignModal,
  onHideTagsAssignModal: doHideTagsAssignModal,
}

export default connect(select, perform)(ConversationHeader)
