import { createSelector } from 'reselect'

const selectBase = state => state.onboarding.facebook

export const selectFacebookPages = state => selectBase(state).pages

export const selectFacebookPagesAsFormFields = createSelector(
  selectFacebookPages,
  pages => {
    return pages.reduce((obj, page) => {
      obj[page.id] = page.subscribed
      return obj
    }, {})
  }
)

export const selectIsFetchingFacebookPages = state => selectBase(state).loading
