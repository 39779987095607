import config from 'config'

let installDisabled = false

export const disableInstall = () => {
  installDisabled = true
}

export function install(text) {
  if (config.isAlpha) return
  if (config.isTest) return
  if (installDisabled) return
  window.onbeforeunload = () => {
    return text
  }
}

export function uninstall() {
  window.onbeforeunload = null
}

export default {
  install,
  uninstall,
}
