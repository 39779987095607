import React from 'react'
import cn from 'classnames'
import { useSelector } from 'react-redux'
import Link from 'redux-first-router-link'
import { motion, AnimatePresence } from 'framer-motion'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import {
  heading,
  divider,
} from '@groovehq/internal-design-system/lib/styles/elements'
import {
  selectCurrentCommittedTicketSearchQueryString,
  selectIsTypedSearch,
} from 'selectors/search'
import useOpenContact from 'util/hooks/useOpenContact'
import { useFeature } from 'ducks/billing/hooks'
import { FEATURE_INBOX_MAX_SEARCH_HISTORY_DAYS } from 'ducks/billing/featureTypes'
import { normalizeDays } from 'subapps/settings/components/BillingPlans/PlanCards/data'
import { TO_SETTINGS_BILLING_PAGE } from 'ducks/billing/constants'
import { styles } from './styles'

const variants = {
  hidden: { height: 0 },
  show: {
    height: 'auto',
    transition: {
      duration: 0.3,
    },
  },
}

const LimitSearchHistoryCard = ({ className }) => {
  const isTypedSearch = useSelector(selectIsTypedSearch)
  const committedSearchQueryString = useSelector(
    selectCurrentCommittedTicketSearchQueryString
  )
  const isSearchingBySearchForm =
    isTypedSearch && committedSearchQueryString !== ''
  const { featureLimit: maxSearchHistory } = useFeature(
    FEATURE_INBOX_MAX_SEARCH_HISTORY_DAYS
  )
  const showPlanLimitationForSearch = maxSearchHistory !== 'unlimited'
  const openContact = useOpenContact()

  if (!showPlanLimitationForSearch) return null

  return (
    <AnimatePresence>
      {isSearchingBySearchForm && (
        <motion.div
          variants={variants}
          initial="hidden"
          animate="show"
          exit="hidden"
        >
          <div
            css={styles.container}
            className={cn(
              'grui px-10 pb-10 pt-5 flex flex-wrap items-end',
              className
            )}
          >
            <div className="grui mr-auto" css={styles.main}>
              <h5 css={heading.styles.h5} className="grui mb-4">
                Ready to upgrade?
              </h5>
              Your account is limited to {normalizeDays(maxSearchHistory)} of
              search history. Upgrade now to access all mails!
            </div>
            <footer css={styles.footer} className="grui flex mt-11">
              <Button
                type="info"
                css={styles.button}
                as={Link}
                to={TO_SETTINGS_BILLING_PAGE}
              >
                Upgrade now
              </Button>
              <Button
                css={styles.button}
                className="chat-to-sales-btn"
                onClick={openContact}
                type="tertiary"
              >
                Chat to sales
              </Button>
            </footer>
          </div>
          <div role="separator" css={divider.styles.base} />
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default LimitSearchHistoryCard
