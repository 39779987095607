import { createSelector } from 'reselect'

const base = state => state.kb.themes || {}

export const selectIds = state => base(state).ids || []
export const selectById = state => base(state).byId || {}

export const selectThemes = createSelector(selectById, selectIds, (byId, ids) =>
  ids.map(id => byId[`${id}`])
)

export const selectDefaultTheme = createSelector(selectThemes, themes =>
  themes.find(theme => theme.name === 'Barcelona')
)
