import { uniq, any } from 'util/arrays'
import { constructEncodedSearchQueryString } from 'util/search'
import { downcase } from 'util/strings'
import { convertHslToHex } from 'util/colors'
import { getRandomNumber } from 'util/numbers'

export const getId = label => label.id

export const merge = (labels, addLabels = [], removeLabels = []) =>
  uniq(
    (labels || [])
      .concat(addLabels)
      .filter(label => !removeLabels.includes(label))
  )

// Labels with an ID that matches their name, don't have ID and are just using
// their name as a temporary label.
export const includesTransientLabel = labels =>
  any(x => x.id === x.name, labels)

const pathFor = (mailboxName, label) => {
  if (!label) return ''
  const searchStr = constructEncodedSearchQueryString({
    inbox: mailboxName ? downcase(mailboxName) : null,
    state: ['open'],
    label: [label.name ? downcase(label.name) : null],
  })
  return `/search/${searchStr}`
}

export const addPath = mailboxName => label => {
  return {
    ...label,
    path: pathFor(mailboxName, label),
  }
}

// https://stackoverflow.com/questions/5850590/random-color-generator-with-hue-saturation-and-more-controls
export function generateRandomLabelColor() {
  const h = getRandomNumber(1, 360)
  const s = getRandomNumber(0, 100)
  // Putting lightness between 20 and 80 so that we get non white and black colors
  const l = getRandomNumber(20, 80)
  return convertHslToHex(`hsl(${h},${s}%,${l}%)`)
}
