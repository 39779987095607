import * as types from 'subapps/kb/actions'
import { defaultFetchFilters } from 'subapps/kb/constants/categories'
import { emptyObj, omit } from 'util/objects'
import { without } from 'util/arrays'

const defaultState = {
  byId: {},
  byTitle: [],
  ids: [],
  error: null,
  isLoading: false,
  isLoaded: false,
  isSaving: false,
  stale: true,
  ...defaultFetchFilters,
}
const reducers = {}

const updateCategory = (state, newCategory) => {
  const existingCategory = state.byId[newCategory.id] || emptyObj

  return {
    ...state.byId,
    ...{ [newCategory.id]: { ...existingCategory, ...newCategory } },
  }
}

// Switched context, loading new KB so reset the state
reducers[types.SWITCH_KNOWLEDGE_BASE] = state => {
  return {
    ...state,
    ...defaultState,
  }
}

reducers[types.FETCH_CATEGORIES_REQUEST] = (state, action) => {
  const { selectedState } = action

  return {
    ...state,
    isLoading: true,
    selectedState,
  }
}

reducers[types.FETCH_CATEGORIES_SUCCESS] = (state, action) => {
  const { meta, data, keyword, sortByOrder, perPage } = action

  const newById = {}
  const newByTitle = []
  const newIds = []

  data.forEach(category => {
    newById[category.id] = category
    newByTitle.push({ id: category.id, title: category.title })
    newIds.push(category.id)
  })

  return {
    ...state,
    byId: { ...state.byId, ...newById },
    byTitle: [...newByTitle],
    ids: newIds,
    perPage,
    currentPage: meta.currentPage,
    totalPages: meta.totalPages,
    totalCount: meta.totalCount,
    keyword,
    sortByOrder,
    isLoading: false,
    isLoaded: true,
    stale: false,
  }
}

reducers[types.FETCH_CATEGORIES_FAILURE] = state => {
  return {
    ...state,
    isLoading: false,
  }
}

reducers[types.FETCH_CATEGORIES_COUNTS_SUCCESS] = (state, action) => {
  return {
    ...state,
    counts: action.data,
  }
}

reducers[types.FETCH_CATEGORIES_TITLES_REQUEST] = state => {
  return {
    ...state,
    isLoading: true,
  }
}

reducers[types.FETCH_CATEGORIES_TITLES_SUCCESS] = (state, action) => {
  const { data } = action

  return {
    ...state,
    byTitle: data,
    isLoading: false,
  }
}

reducers[types.FETCH_CATEGORIES_TITLES_FAILURE] = state => {
  return {
    ...state,
    isLoading: false,
  }
}

reducers[types.FETCH_CATEGORY_REQUEST] = state => {
  return {
    ...state,
    isLoading: true,
  }
}

reducers[types.FETCH_CATEGORY_SUCCESS] = (state, action) => {
  const { category } = action.data

  return {
    ...state,
    isLoading: false,
    byId: {
      ...state.byId,
      [category.id]: category,
    },
    isSaving: false,
  }
}

reducers[types.FETCH_CATEGORY_FAILURE] = state => {
  return {
    ...state,
    isLoading: false,
  }
}

reducers[types.REPOSITION_ARTICLES_REQUEST] = (state, action) => {
  const { categoryId, articleIds: reorderedIds } = action.data

  const newArticleIds = [...state.byId[categoryId].articleIds]
  const idx = newArticleIds.indexOf(reorderedIds.first)
  newArticleIds.splice(idx, reorderedIds.length, ...reorderedIds)

  return {
    ...state,
    byId: updateCategory(state, { id: categoryId, newArticleIds }),
  }
}

reducers[types.CREATE_CATEGORY_REQUEST] = state => {
  return {
    ...state,
    isSaving: true,
  }
}

reducers[types.CREATE_CATEGORY_FAILURE] = (state, action) => {
  const { err } = action.data
  return {
    ...state,
    isSaving: false,
    error: err,
  }
}

reducers[types.CREATE_CATEGORY_SUCCESS] = (state, action) => {
  const { createdCategory, createdCategoryId } = action.data

  return {
    ...state,
    ids: [createdCategoryId, ...state.ids],
    byId: {
      ...state.byId,
      [createdCategoryId]: createdCategory,
    },
    byTitle: [
      ...state.byTitle,
      { id: createdCategoryId, title: createdCategory.title },
    ],
    isSaving: false,
    stale: true,
  }
}

reducers[types.UPDATE_CATEGORY_REQUEST] = state => {
  return {
    ...state,
    isSaving: true,
  }
}

reducers[types.UPDATE_CATEGORY_FAILURE] = (state, action) => {
  const { err } = action.data
  return {
    ...state,
    isSaving: false,
    error: err,
  }
}

reducers[types.UPDATE_CATEGORY_SUCCESS] = (state, action) => {
  const { updatedCategory } = action.data

  return {
    ...state,
    byId: updateCategory(state, updatedCategory),
    isSaving: false,
  }
}

reducers[types.UPDATE_CATEGORY_ARTICLE_IDS_SUCCESS] = (state, action) => {
  const { categoryId, articleIds } = action.payload
  return {
    ...state,
    byId: updateCategory(state, {
      id: categoryId,
      articleIds,
    }),
  }
}

reducers[types.DELETE_CATEGORY_REQUEST] = state => {
  return {
    ...state,
    isSaving: true,
  }
}

reducers[types.DELETE_CATEGORY_SUCCESS] = (state, action) => {
  const { categoryId } = action.data
  const existingCategory = state.byId[categoryId]

  const counts = state.counts
  let newCountsByState = {}

  if (counts && counts.state) {
    newCountsByState = {
      ...counts,
      ...{
        state: {
          ...counts.state,
          ...{
            [existingCategory.state]: counts.state[existingCategory.state] - 1,
          },
        },
      },
    }
  }

  return {
    ...state,
    byId: omit([categoryId], state.byId),
    ids: state.ids.filter(id => id !== categoryId),
    counts: newCountsByState,
    category: null,
    error: null,
    isSaving: false,
    totalCount: state.totalCount - 1 || 0,
  }
}

reducers[types.DELETE_CATEGORY_FAILURE] = (state, action) => {
  const { err } = action.data
  return {
    ...state,
    isSaving: false,
    error: err,
  }
}

reducers[types.PUBLISH_CATEGORY_REQUEST] = state => {
  return {
    ...state,
    isSaving: true,
  }
}

reducers[types.PUBLISH_CATEGORY_SUCCESS] = (state, action) => {
  const { publishedCategory } = action.data
  return {
    ...state,
    byId: updateCategory(state, publishedCategory),
    isSaving: false,
    stale: true,
  }
}

reducers[types.PUBLISH_CATEGORY_FAILURE] = (state, action) => {
  const { err } = action.data
  return {
    ...state,
    isSaving: false,
    error: err,
  }
}

reducers[types.UNPUBLISH_CATEGORY_REQUEST] = state => {
  return {
    ...state,
    isSaving: true,
  }
}

reducers[types.UNPUBLISH_CATEGORY_SUCCESS] = (state, action) => {
  const { unpublishedCategory } = action.data
  return {
    ...state,
    byId: updateCategory(state, unpublishedCategory),
    isSaving: false,
    stale: true,
  }
}

reducers[types.UNPUBLISH_CATEGORY_FAILURE] = (state, action) => {
  const { err } = action.data
  return {
    ...state,
    isSaving: false,
    error: err,
  }
}

reducers[types.REVERT_CATEGORY_REQUEST] = state => {
  return {
    ...state,
    isSaving: true,
  }
}

reducers[types.REVERT_CATEGORY_SUCCESS] = (state, action) => {
  const { revertedCategory } = action.data
  return {
    ...state,
    byId: updateCategory(state, revertedCategory),
    isSaving: false,
    stale: true,
  }
}

reducers[types.REVERT_CATEGORY_FAILURE] = (state, action) => {
  const { err } = action.data
  return {
    ...state,
    isSaving: false,
    error: err,
  }
}

reducers[types.REPOSITION_CATEGORIES_REQUEST] = (state, action) => {
  const { sortedIds } = action.data

  return {
    ...state,
    ids: sortedIds,
  }
}

reducers[types.REMOVE_CATEGORY_ARTICLE] = (state, action) => {
  const { categoryId, articleId } = action.data
  const currentArticleIds = [...state.byId[categoryId].articleIds]

  without(currentArticleIds, articleId)

  return {
    ...state,
    byId: updateCategory(state, {
      id: categoryId,
      articleIds: currentArticleIds,
      articlesCount: currentArticleIds.length,
    }),
  }
}

reducers[types.CATEGORY_SAVE_CLOSE] = state => {
  return {
    ...state,
    error: null,
    isSaving: false,
  }
}

reducers[types.CATEGORY_CANCEL_CLOSE] = state => {
  return {
    ...state,
    error: null,
    isSaving: false,
  }
}

export default function reducer(state = defaultState, action) {
  const handler = reducers[action.type]
  if (handler) return handler(state, action)
  return state
}
