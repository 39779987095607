import React, { useCallback } from 'react'
import Modal from '@groovehq/internal-design-system/lib/components/Modal/Modal'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import useOpenContact from 'util/hooks/useOpenContact'
import lock from 'assets/lock.png'

import { styles } from './styles'

const TeammatesRemovalLock = ({ onExit, open }) => {
  const openContact = useOpenContact()

  const handleSupport = useCallback(
    () => {
      openContact()
      onExit()
    },
    [onExit, openContact]
  )

  return (
    <Modal
      portal="#overlays"
      open={open}
      className="grui pb-14"
      css={styles.container}
      onClose={onExit}
    >
      <div css={styles.imgBox}>
        <div css={styles.imgWrapper}>
          <img src={lock} alt="lock" />
        </div>
      </div>
      <div className="grui text-center">
        <Modal.Title css={styles.heading}>Unusual account activity</Modal.Title>
        <Modal.Description>
          We’ve noticed unusual activity and disabled this feature as a security
          risk.
        </Modal.Description>
        <Modal.Description className="grui my-11">
          Please contact our support team to assist.
        </Modal.Description>
        <Button
          type="info"
          size="xBig"
          onClick={handleSupport}
          css={styles.btn}
        >
          Contact support
        </Button>
      </div>
    </Modal>
  )
}

export default TeammatesRemovalLock
