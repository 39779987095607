import { DATE_UNITS, timeAgoAbbr, toTimestamp } from './date'

export const DURATION_LEVEL_NEGATIVE = 'negative'
export const DURATION_LEVEL_NORMAL = 'normal'
export const DURATION_LEVEL_POSITIVE = 'positive'
export const DURATION_LEVEL_NONE = 'none'

export const lastUnansweredUserMessageLabel = lastUnansweredUserMessageAt => {
  let unansweredSinceLabel = ''

  if (lastUnansweredUserMessageAt) {
    const unansweredSince = timeAgoAbbr(lastUnansweredUserMessageAt)

    if (unansweredSince) unansweredSinceLabel = `Waiting ${unansweredSince}`
  }

  return unansweredSinceLabel
}

export const lastUnansweredUserMessageDurationLevel = lastUnansweredUserMessageAt => {
  let unansweredSinceDuration = 0

  if (lastUnansweredUserMessageAt) {
    unansweredSinceDuration = Math.max(
      Date.now() - toTimestamp(lastUnansweredUserMessageAt),
      0
    )
  }

  if (unansweredSinceDuration > DATE_UNITS.days * 1) {
    return DURATION_LEVEL_NEGATIVE
  }

  if (unansweredSinceDuration > DATE_UNITS.hours * 4) {
    return DURATION_LEVEL_NORMAL
  }

  if (unansweredSinceDuration > 0) {
    return DURATION_LEVEL_POSITIVE
  }

  return DURATION_LEVEL_NONE
}
