import { flatten } from 'util/arrays'
import { reverseHashInt } from 'util/scatterSwap'

export const buildPossibleValueOptions = (
  values,
  parameterKey,
  agents,
  groups,
  mailboxes
) => {
  let possibleOptionsArray

  const {
    valueType,
    unassigned,
    anyone,
    currentUser,
    options: possibleOptions,
  } =
    values[parameterKey] || {}

  if (valueType === 'Group') {
    possibleOptionsArray = []
    if (unassigned) {
      possibleOptionsArray.push({ name: 'Unassigned', value: '0' })
    }
    groups.forEach(m => {
      possibleOptionsArray.push({
        name: m.name,
        value: reverseHashInt(m.id).toString(),
      })
    })
  } else if (valueType === 'Mailbox') {
    possibleOptionsArray = []
    mailboxes.forEach(m => {
      possibleOptionsArray.push({
        name: m.name,
        value: reverseHashInt(m.id).toString(),
      })
    })
  } else if (valueType === 'Agent') {
    possibleOptionsArray = []
    if (anyone) {
      possibleOptionsArray.push({ name: 'Anyone', value: '0' })
    }
    if (currentUser) {
      possibleOptionsArray.push({ name: 'Current User', value: '-1' })
    }
    if (unassigned) {
      possibleOptionsArray.push({ name: 'Unassigned', value: '0' })
    }
    agents.forEach(m => {
      possibleOptionsArray.push({
        name: m.name,
        value: reverseHashInt(m.id).toString(),
      })
    })
  } else {
    possibleOptionsArray = possibleOptions
  }

  return possibleOptionsArray
}

export const getActionKey = (type, value) => {
  return [type, value].filter(item => !!item).join('-')
}

export const selectParameter = (all, key, valueKey) => {
  const items = flatten(Object.values(all))
  const selected = items.find(item => {
    return item.type === key && (item.value ? item.value === valueKey : true)
  })
  return selected?.name
}

export const selectValue = (all, key) =>
  all.find(item => item.value === key)?.name
