import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import config from 'config'
import Drawer from '@groovehq/internal-design-system/lib/components/Drawer/UnmanagedDrawer'
import CannedReplyCategoryDataTable from 'subapps/settings/components/CannedReplyCategoryDataTable'
import CannedReplyDataTable from 'subapps/settings/components/CannedReplyDataTable'
import Tabbed from 'subapps/settings/components/drawers/Tabbed'
import { Play } from '@groovehq/internal-design-system/lib/assets/icons'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import { selectCurrentUserIsAdminOrOwner } from 'ducks/currentUser/selectors'
import { useDrawer } from 'ducks/drawers/hooks'
import {
  DRAWER_TYPE_CANNED_REPLIES_UPDATE,
  DRAWER_TYPE_CANNED_REPLY_CATEGORIES_UPDATE,
} from 'ducks/drawers/types'
import BatchJobsAppBarList from 'subapps/settings/components/BatchJobsAppBar/list'
import {
  DELETE_MESSAGE_TEMPLATES,
  UPDATE_MESSAGE_TEMPLATES,
} from 'ducks/batchJobs/jobTypes'
import { SETTINGS_CANNED_REPLY_TABLE_ID } from 'ducks/tables/ids'
import { useFeature } from 'ducks/billing/hooks'
import { FEATURE_INBOX_MAX_CANNED_REPLIES } from 'ducks/billing/featureTypes'
import CanndReplyEmpty from '../CanndReplyEmpty'
import { styles } from './styles'

const JOB_TYPES_TAGS = [DELETE_MESSAGE_TEMPLATES, UPDATE_MESSAGE_TEMPLATES]

const tabsData = [
  {
    key: 'replies',
    tabName: app.t('Canned_replies'),
    component: CannedReplyDataTable,
  },
  {
    key: 'categories',
    tabName: 'Categories',
    component: CannedReplyCategoryDataTable,
  },
]

const CannedReplyOverview = ({
  drawerTabId = tabsData[0].key,
  open,
  onClose,
  onExit,
  updateDrawerParameter,
}) => {
  const isManager = useSelector(selectCurrentUserIsAdminOrOwner)
  const { canUseFeature } = useFeature(FEATURE_INBOX_MAX_CANNED_REPLIES)
  const showPlanLimitationForCannedReplies = !canUseFeature
  const isCategory = drawerTabId === 'categories'
  const handleTabKeyChange = useCallback(
    (key, options) => {
      if (key === drawerTabId) return
      updateDrawerParameter('drawerTabId', key, options)
    },
    [drawerTabId, updateDrawerParameter]
  )

  const handleOnCannedReplyCategoryClick = useCallback(
    categoryId => {
      handleTabKeyChange('replies', {
        query: {
          [`${SETTINGS_CANNED_REPLY_TABLE_ID}-categoryId`]: categoryId,
        },
      })
    },
    [handleTabKeyChange]
  )

  const { openDrawer: openCreateDrawer } = useDrawer({
    type: isCategory
      ? DRAWER_TYPE_CANNED_REPLY_CATEGORIES_UPDATE
      : DRAWER_TYPE_CANNED_REPLIES_UPDATE,
  })

  const handleOnCreate = useCallback(
    () => {
      openCreateDrawer('new')
    },
    [openCreateDrawer]
  )

  const HeaderButtons = useCallback(
    () => {
      return (
        <>
          {config.isDevelopment && (
            <Button
              size="small"
              type="tertiary"
              customIcon={<Play css={text.styles.textMediumDark} />}
            >
              Watch a tutorial
            </Button>
          )}
          <Button
            size="small"
            onClick={handleOnCreate}
            disabled={showPlanLimitationForCannedReplies && !isCategory}
          >
            {isCategory
              ? 'Create category'
              : `Create ${app.t('canned_reply_with_article')}`}
          </Button>
        </>
      )
    },
    [handleOnCreate, isCategory, showPlanLimitationForCannedReplies]
  )

  const showHeaderButtons = !isCategory || isManager

  return (
    <Drawer
      title={app.t('Canned_replies')}
      open={open}
      onClose={onExit}
      size="wideXL"
      footer={
        <ModalBtns tertiaryBtnText="Close" onClickTertiaryBtn={onClose} />
      }
    >
      <BatchJobsAppBarList
        jobTypes={JOB_TYPES_TAGS}
        css={styles.appBarList}
        roundBorder
      />
      <Tabbed
        tabsData={tabsData}
        activeTabKey={drawerTabId}
        onTabKeyChange={handleTabKeyChange}
        HeaderButtons={showHeaderButtons && HeaderButtons}
        compact
        onCannedReplyCategoryClick={
          drawerTabId === 'categories' && handleOnCannedReplyCategoryClick
        }
      />
    </Drawer>
  )
}

const CannedReplyOverviewWithEmptyState = props => {
  const { currentUsage: totalCannedReplies } = useFeature(
    FEATURE_INBOX_MAX_CANNED_REPLIES
  )

  if (totalCannedReplies === 0) return <CanndReplyEmpty {...props} />

  return <CannedReplyOverview {...props} />
}

export default CannedReplyOverviewWithEmptyState
