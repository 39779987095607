import { css } from '@emotion/react'

const container = () => css`
  width: 100%;
`

const dangerCard = theme => css`
  background: ${theme.color.primary.negative};
  color: ${theme.color.monochrome.white};
`

export const styles = {
  container,
  dangerCard,
}
