import { css } from '@emotion/react'

import Table from 'semantic-ui-react/dist/es/collections/Table'

const dividerBottomCss = theme => css`
  &&&&& {
    td {
      background: ${theme.color.monochrome.white};
      outline: 1px solid ${theme.color.monochrome.white};
      border: 0;
      height: 16px;
      padding: 0;
      overflow: visible;

      &:before,
      &:after {
        display: block;
        content: '';
        position: relative;
        left: -1px;
        width: calc(100% + 2px);
        background: rgba(0, 0, 50, 0);
        border-bottom: 1px solid ${theme.color.monochrome.medium};
        border-left: 1px solid ${theme.color.monochrome.medium};
        border-right: 1px solid ${theme.color.monochrome.medium};
        height: 6px;
        border-radius: 0 0 4px 4px;
        top: -7px;
        box-shadow: 0px 5px 0px ${theme.color.monochrome.white};
      }

      &:after {
        background: ${theme.color.monochrome.light};
        border-top: 1px solid ${theme.color.monochrome.medium};
        border-bottom: 0;
        border-radius: 4px 4px 0 0;
        bottom: -7px;
        box-shadow: 0px -5px 0px ${theme.color.monochrome.white};
        top: auto;
      }
    }
    &:nth-child(2n) td:before {
      background: white;
    }
  }

  tbody:last-child &&&&& td:after {
    content: none;
  }
`

export default function TableDividerBottom({ colSpan }) {
  return (
    <>
      <Table.Row css={dividerBottomCss} className="TableDividerBottom">
        <Table.Cell colSpan={colSpan} />
      </Table.Row>
    </>
  )
}
