import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { string, bool, oneOf } from 'prop-types'
import { doDeleteThirdPartyAccessTokens } from 'ducks/accessTokens/actions'
import { emptyArr } from 'util/arrays'
import EntityDeleteDrawer from '../EntityDelete'
import ThirdPartyAccessTokenContent from './Content'

const ThirdPartyAccessTokenDeleteDrawer = props => {
  const {
    queryId,
    deleteBtnText,
    open,
    drawerResourceId,
    drawerDeleteMode,
    onExit,
    onClose,
    onRemove,
  } = props
  const dispatch = useDispatch()

  const ids = useMemo(
    () => {
      return drawerResourceId ? drawerResourceId.split(',') : emptyArr
    },
    [drawerResourceId]
  )

  const handleOnRemove = useCallback(
    () => {
      const response = dispatch(
        doDeleteThirdPartyAccessTokens(ids, {
          queryId,
          mode: drawerDeleteMode,
          convertScatterToRaw: true,
        })
      )
      if (onRemove) onRemove()
      return response
    },
    [dispatch, ids, queryId, drawerDeleteMode, onRemove]
  )

  const title =
    drawerDeleteMode === 'query' || ids.length > 1
      ? 'Revoke 3rd Party Tokens'
      : 'Revoke 3rd Party Token'

  return (
    <EntityDeleteDrawer
      {...props}
      onRemove={handleOnRemove}
      onClose={onExit}
      tertiaryBtnText="Cancel"
      onCancel={onClose}
      title={title}
      deleteBtnText={deleteBtnText}
      open={open}
    >
      <ThirdPartyAccessTokenContent
        ids={ids}
        queryId={queryId}
        deleteMode={drawerDeleteMode}
      />
    </EntityDeleteDrawer>
  )
}

ThirdPartyAccessTokenDeleteDrawer.propTypes = {
  deleteBtnText: string,
  open: bool.isRequired,
  drawerDeleteMode: oneOf(['ids', 'query']),
  drawerResourceId: string,
  queryId: string,
}

ThirdPartyAccessTokenDeleteDrawer.defaultProps = {
  deleteBtnText: 'Revoke',
  drawerDeleteMode: 'ids',
  drawerResourceId: '',
  queryId: null,
}

export default ThirdPartyAccessTokenDeleteDrawer
