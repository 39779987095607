import isQueryStringComplete from './isQueryStringComplete'
import isSearchValid from './isSearchValid'

/**
 * Ready query strings cannot:
 *  - be invalid
 *  - be falsey values
 *  - be empty strings
 *  - end in :
 *  - have an unterminated quoted value ie an odd number of " or '
 *
 * They are basically complete query strings but they can end in a space (which
 * which is easy to strip out) and must be valid.
 */
export default function isQueryStringReady(queryString) {
  const isComplete = isQueryStringComplete(queryString)
  const isValid = isSearchValid(queryString)
  return !!(
    (isComplete || (queryString && queryString.match(/\s$/))) &&
    isValid
  )
}
