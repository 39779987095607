import { css } from '@emotion/react'

const dropdownContainer = css`
  & > * {
    width: 100%;
  }
`

const dropdownBtn = css`
  min-width: 100%;
`

export const styles = {
  dropdownContainer,
  dropdownBtn,
}
