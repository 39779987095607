import { connect } from 'react-redux'

import { selectCurrentTicketListType } from 'selectors/app'
import {
  selectCurrentSearchIsFolderSearch,
  selectCurrentTicketSearchQueryTitle,
} from 'selectors/search'
import { SEARCH } from 'constants/conversation_list_types'

import FilterBarView from 'components/ConversationList/Header/FilterBar'

const select = state => {
  const searchIsFolderSearch = selectCurrentSearchIsFolderSearch(state)
  const titleType = selectCurrentTicketListType(state)
  const title = selectCurrentTicketSearchQueryTitle(state)

  const isRightType = !searchIsFolderSearch && titleType === SEARCH
  const isDrafts = title && title.match('Drafts')

  return {
    shouldShowOptions: isRightType || !!isDrafts,
  }
}

export default connect(select)(FilterBarView)
