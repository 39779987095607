import { oauthTokenSelector } from 'selectors/app/base'
import {
  doAddMailboxBasedOauthChannel,
  doAddGmailChannel as doAddGmailChannelBase,
} from 'ducks/channels/actions'
import OAuthWindow from 'util/oauth_window'
import debug from 'util/debug'
import {
  CHANNEL_OVERVIEW_PAGE,
  FACEBOOK_PAGE_SELECTION_PAGE,
  SETUP_EMAIL_PAGE,
} from 'subapps/onboarding/pages'
import { fetchOAuthAuthenticationUrl } from 'subapps/onboarding/api/channels'
import { selectIsFacebookConnected } from './selectors'
import { CONNECT_CHANNEL_SUCCESS, CONNECT_CHANNEL_FAILURE } from './types'

function doAddGmailChannel() {
  return dispatch => {
    dispatch(doAddGmailChannelBase()).then(() => {
      dispatch({
        type: CHANNEL_OVERVIEW_PAGE,
      })
    })
  }
}

function doAddFacebookChannel() {
  return (dispatch, getState) => {
    const state = getState()
    // TODO (jscheel): We need to make sure this is bootstrapped on reload
    const isConnected = selectIsFacebookConnected(state)
    if (isConnected) {
      dispatch({
        type: FACEBOOK_PAGE_SELECTION_PAGE,
      })
      return Promise.resolve()
    }
    const token = oauthTokenSelector(state)
    const returnTo = `${window.location.origin}/oauth.html`
    const oAuthFlow = new OAuthWindow({ width: 700, height: 700 })
    return oAuthFlow
      .start(() => {
        fetchOAuthAuthenticationUrl(token, 'FACEBOOK', { returnTo }).then(
          res => {
            oAuthFlow.redirect(res.url)
          }
        )
      })
      .then(() => {
        dispatch({
          type: FACEBOOK_PAGE_SELECTION_PAGE,
        })
      })
  }
}

function doAddTwitterChannel() {
  return (dispatch, getState) => {
    // TODO (jscheel): Should we skip if they want to add another page? Or do we
    // just take them to the page selection if they have already connected their
    // account to groove? Do we create a new mailbox for each twitter account?

    // NOTE (jscheel): We don't actually have to create the twitter mailbox first,
    // but mailbox creation can be a tiny bit slow, so better to let it look like
    // the time spent is actually twitter's oauth page loading.
    return doAddMailboxBasedOauthChannel(
      'TWITTER',
      {},
      {
        width: 750,
        height: 680,
      }
    )(dispatch, getState).then(() => {
      dispatch({
        type: CHANNEL_OVERVIEW_PAGE,
      })
    })
  }
}

export function doAddChannel(type) {
  return (dispatch, getState) => {
    switch (type) {
      case 'mail':
        return dispatch({ type: SETUP_EMAIL_PAGE })
      case 'google':
        return doAddGmailChannel()(dispatch, getState)
          .then(() =>
            dispatch({ type: CONNECT_CHANNEL_SUCCESS, payload: { type } })
          )
          .catch(err => {
            debug(err)
            dispatch({ type: CONNECT_CHANNEL_FAILURE, payload: { error: err } })
          })
      case 'facebook':
        return doAddFacebookChannel()(dispatch, getState)
          .then(() =>
            dispatch({ type: CONNECT_CHANNEL_SUCCESS, payload: { type } })
          )
          .catch(err => {
            debug(err)
            dispatch({ type: CONNECT_CHANNEL_FAILURE, payload: { error: err } })
          })
      case 'twitter':
        return doAddTwitterChannel()(dispatch, getState)
          .then(() =>
            dispatch({ type: CONNECT_CHANNEL_SUCCESS, payload: { type } })
          )
          .catch(err => {
            debug(err)
            dispatch({ type: CONNECT_CHANNEL_FAILURE, payload: { error: err } })
          })
      default:
        return false
    }
  }
}
