import React from 'react'
import ReactDOM from 'react-dom'

export default function asOverlay(WrappedChild) {
  const overlaysRoot = document.getElementById('overlays')
  class AsOverlay extends React.Component {
    componentDidMount() {
      overlaysRoot.appendChild(this.el)
    }

    componentWillUnmount() {
      overlaysRoot.removeChild(this.el)
    }

    el = document.createElement('div')

    render() {
      return ReactDOM.createPortal(
        <WrappedChild {...this.props} ref={this.props.forwardRef} />,
        this.el
      )
    }
  }

  function AsOverlayFunctionWrapper(props, ref) {
    return <AsOverlay {...props} forwardRef={ref} />
  }

  return React.forwardRef(AsOverlayFunctionWrapper)
}
