export const inputsData = {
  webhookUrl: {
    name: 'url',
    label: 'URL',
    placeholder: 'Enter URL...',
  },
}

export const webhookDropdown = [
  {
    key: 'ticket_started',
    title: 'ticket_started',
  },
  {
    key: 'ticket_opened',
    title: 'ticket_opened',
  },
  {
    key: 'ticket_state_changed',
    title: 'ticket_state_changed',
  },
  {
    key: 'ticket_closed',
    title: 'ticket_closed',
  },
  {
    key: 'ticket_assigned',
    title: 'ticket_assigned',
  },
  {
    key: 'ticket_priority_changed',
    title: 'ticket_priority_changed',
  },
  {
    key: 'ticket_deleted',
    title: 'ticket_deleted',
  },
  {
    key: 'ticket_moved',
    title: 'ticket_moved',
  },
  {
    key: 'ticket_tagged',
    title: 'ticket_tagged',
  },
  {
    key: 'ticket_untagged',
    title: 'ticket_untagged',
  },
  {
    key: 'ticket_merged',
    title: 'ticket_merged',
  },
  {
    key: 'customer_replied',
    title: 'customer_replied',
  },
  {
    key: 'note_added',
    title: 'note_added',
  },
  {
    key: 'note_changed',
    title: 'note_changed',
  },
  {
    key: 'agent_replied',
    title: 'agent_replied',
  },
  {
    key: 'customer_added',
    title: 'customer_added',
  },
  {
    key: 'agent_invited',
    title: 'agent_invited',
  },
  {
    key: 'agent_archived',
    title: 'agent_archived',
  },
  {
    key: 'message_read',
    title: 'message_read',
  },
  {
    key: 'message_rated',
    title: 'message_rated',
  },
]
