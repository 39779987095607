import React, { useMemo } from 'react'
import { useFormContext, useController } from 'react-hook-form'
import Dropdown from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import DropdownMenu from 'subapps/settings/components/DropdownMenu'
import { useFeature } from 'ducks/billing/hooks'
import { FEATURE_INBOX_MAX_VIEWER_USERS } from 'ducks/billing/featureTypes'
import { AGENT_DROPDOWN_MENU, AGENT_DESCRIPTIONS } from './constants'
import { styles } from './styles'

const { Button } = Dropdown

const selectTitle = (all, key) => {
  const selected = all.find(item => item.key === key) || {
    title: '',
  }
  return selected.title
}

export const DropdownView = () => {
  const { control } = useFormContext()
  const { currentUsage, featureLimit } = useFeature(
    FEATURE_INBOX_MAX_VIEWER_USERS
  )
  const {
    field: {
      onChange: handleSelectEvent,
      ref: roleRef,
      value: selectedAgentRole,
    },
  } = useController({
    name: 'role',
    control,
  })

  const agentDropdownMenu = useMemo(
    () => {
      return AGENT_DROPDOWN_MENU.map(item => {
        if (item.key === 'viewer') {
          return {
            ...item,
            title:
              currentUsage < featureLimit
                ? `${item.title} (${featureLimit - currentUsage} remaining)`
                : item.title,
          }
        }
        return item
      })
    },
    [currentUsage, featureLimit]
  )

  const selectedAgentRoleTitle = useMemo(
    () => selectTitle(agentDropdownMenu, selectedAgentRole),
    [agentDropdownMenu, selectedAgentRole]
  )

  return (
    <div>
      <Dropdown
        overlay={<DropdownMenu data={agentDropdownMenu} />}
        onSelect={handleSelectEvent}
        selectedKey={selectedAgentRole}
      >
        <Button ref={roleRef}>
          {selectedAgentRoleTitle || 'Please select'}
        </Button>
      </Dropdown>
      <div css={styles.agentDescriptionLong}>
        {AGENT_DESCRIPTIONS[selectedAgentRole]?.long}
      </div>
    </div>
  )
}
