import React, { useCallback } from 'react'
import {
  text,
  paragraph,
} from '@groovehq/internal-design-system/lib/styles/elements'
import MessageCard from '@groovehq/internal-design-system/lib/components/MessageCard/MessageCard'
import { useDispatch, useSelector } from 'react-redux'
import EntityDelete from 'subapps/settings/components/drawers/EntityDelete'
import { doDeleteWidget } from 'ducks/widgets/operations'
import { selectWidgetById } from 'ducks/widgets/selectors/widgets'
import { styles } from './styles'
import DisabledDeletion from './DisabledDeletion'

const WidgetDelete = ({
  open,
  drawerResourceId,
  onClose,
  onExit,
  onRemove,
  children,
  title,
}) => {
  const dispatch = useDispatch()
  const widget = useSelector(state => selectWidgetById(state, drawerResourceId))

  const handleRemove = useCallback(
    async () => {
      const response = await dispatch(doDeleteWidget(drawerResourceId))
      if (onRemove) onRemove()
      return response
    },
    [dispatch, drawerResourceId, onRemove]
  )

  if (!widget) return null

  const alert = (
    <div className="grui mt-auto">
      <MessageCard type="warning" className="grui mt-12" closeable>
        <div css={text.styles.fontMedium}>Heads up!</div>
        <p css={paragraph.styles.preflight} className="grui py-5">
          While the widget will no longer display on your site, you will still
          need to remove the script that was placed on your website.
        </p>
      </MessageCard>
    </div>
  )

  return (
    <EntityDelete
      title={title || 'Delete widget'}
      onRemove={handleRemove}
      onClose={onClose}
      onExit={onExit}
      onCancel={onClose}
      deleteBtn
      tertiaryBtnText="Cancel"
      open={open}
      hardDelete
      extraContent={alert}
      contentStyles={styles.content}
    >
      {children || (
        <div css={text.styles.textNormal} className="grui mb-11">
          {`You are about to delete your widget “${widget.name ||
            ''}” and will no longer display on
      your site.`}
        </div>
      )}
      This action is permanent and cannot be undone.
    </EntityDelete>
  )
}

WidgetDelete.DisabledDeletion = DisabledDeletion

export default WidgetDelete
