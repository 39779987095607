import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'

const fullWidth = css`
  max-width: 100%;
`
const emailBody = theme => css`
  border: 1px solid ${theme.color.monochrome.medium};
  border-radius: 6px;
`

const content = css`
  width: ${rem(400)};

  .channel-details-field {
    max-width: 100%;
  }
`

export const styles = {
  fullWidth,
  emailBody,
  content,
}
