import { css } from '@emotion/react'

import { rem } from '@groovehq/internal-design-system/lib/util'

const dropdownBox = css`
  margin-left: -${rem(16)};
`

const dropdownBtn = css`
  min-width: ${rem(200)};
`

const checkbox = css`
  label > span {
    margin-right: ${rem(16)};
  }
`

const table = theme => css`
  overflow: hidden;
  tbody tr:hover {
    td:last-of-type > * {
      opacity: 1;
      visibility: visible;
      color: ${theme.color.monochrome.mediumDark};
    }
  }
`

const compactTable = theme => css`
  tbody tr {
    .row-controls {
      position: relative;

      .controls {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 10px;
      }
    }

    &:hover {
      .row-controls {
        .controls {
          opacity: 1;
          visibility: visible;
          color: ${theme.color.monochrome.mediumDark};
        }

        /* Only hide components if controls actually exist */
        .components:not(.controls-disabled) {
          display: none;
        }
      }
    }
  }
`

const hiddenFolderNameCell = theme => css`
  color: ${theme.color.monochrome.mediumDark};
`

export const styles = {
  dropdownBox,
  dropdownBtn,
  checkbox,
  table,
  hiddenFolderNameCell,
  compactTable,
}
