import { createSelector } from 'reselect'
import { selectOnboardingWorkflowCompletedAndSkippedList } from './index'
import { selectShowOnboardingWorkflow } from './selectShowOnboardingWorkflow'

export const selectShowOnboardingWorkflowInConversationList = createSelector(
  selectShowOnboardingWorkflow,
  selectOnboardingWorkflowCompletedAndSkippedList,
  (showOnboardingWorkflow, onboardingWorkflowCompletedAndSkippedList) =>
    showOnboardingWorkflow &&
    !onboardingWorkflowCompletedAndSkippedList.includes('mailbox')
)
