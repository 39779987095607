import { useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { redirect } from 'redux-first-router'
import { useDrawer } from 'ducks/drawers/hooks'
import { buildDrawerQueryParam, getCurrentDrawerId } from 'ducks/drawers/util'
import { selectQueryParams } from 'selectors/location'
import { SETTINGS_CHANNELS_PAGE } from 'subapps/settings/types'
import { useFeature } from 'ducks/billing/hooks'
import { FEATURE_INBOX_MAX_MAILBOXES } from 'ducks/billing/featureTypes'
import { selectChannelCreationStepCount } from 'ducks/channels/selectors'
import { useAdminAccess } from '../../NoAccess/hooks'
import { CHANNEL_TYPES } from '../Channels.data'

const useEmailChannelSelector = ({
  onClose,
  onExit,
  drawerSource: source = 'settings',
  options,
}) => {
  const dispatch = useDispatch()
  const [selectedChannelOption, setSelectedChannelOption] = useState(null)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const { canUseFeature } = useFeature(FEATURE_INBOX_MAX_MAILBOXES)
  const showPlanLimitationForMailboxes = !canUseFeature
  const queryParams = useSelector(selectQueryParams)
  // get the current drawer id from the url string in browser address bar
  const currentDrawerId = getCurrentDrawerId(queryParams)
  const creationStepCount = useSelector(state =>
    selectChannelCreationStepCount(state, CHANNEL_TYPES.google)
  )
  const { drawerId: nextDrawerId, openDrawer: openNextDrawer } = useDrawer(
    options[
      selectedChannelOption?.shouldUseTypeForNextDrawer
        ? selectedChannelOption?.type
        : selectedChannelOption?.provider
    ]?.drawer
  )

  useAdminAccess(onClose)

  useEffect(
    () => {
      if (
        isDrawerOpen &&
        selectedChannelOption?.type &&
        currentDrawerId !== nextDrawerId
      ) {
        // prevent drawer from being triggered to open again if the drawer is closed
        // i.e. closed being, the previous state's drawer is missing/changed from address bar query
        setSelectedChannelOption(null)
        setIsDrawerOpen(false)
      } else if (
        selectedChannelOption?.type &&
        currentDrawerId !== nextDrawerId
      ) {
        // now useDrawer is initialised with value from currentDrawer
        // invoke openDrawer
        openNextDrawer('new', {
          query: {
            ...buildDrawerQueryParam(
              nextDrawerId,
              'drawerChannelType',
              selectedChannelOption.type
            ),
            ...buildDrawerQueryParam(
              nextDrawerId,
              'drawerChannelProvider',
              selectedChannelOption.provider
            ),
          },
          additionalProps: {
            drawerSource: source,
          },
        })
        setIsDrawerOpen(true)
      }
    },
    [
      currentDrawerId,
      nextDrawerId,
      selectedChannelOption?.type,
      selectedChannelOption?.provider,
      openNextDrawer,
      isDrawerOpen,
      source,
    ]
  )

  const handleSelect = useCallback(
    (type, { provider, shouldUseTypeForNextDrawer }) => {
      setSelectedChannelOption({
        type,
        provider,
        shouldUseTypeForNextDrawer,
      })
    },
    []
  )

  const handleBack = useCallback(
    () => {
      onClose({ ignoreStack: false })
    },
    [onClose]
  )

  useEffect(
    () => {
      if (showPlanLimitationForMailboxes) {
        onExit()
        dispatch(
          redirect({
            type: SETTINGS_CHANNELS_PAGE,
          })
        )
      }
    },
    [onExit, showPlanLimitationForMailboxes, dispatch]
  )

  return {
    handleSelect,
    handleBack,
    creationStepCount,
  }
}

export default useEmailChannelSelector
