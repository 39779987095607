function start() {
  const app = require('../app')

  const cache = window.applicationCache

  // grr https://developer.mozilla.org/en-US/docs/Web/HTML/Using_the_application_cache
  if (!cache) return false
  if (!cache.addEventListener) return false

  const cacheStatusValues = []
  cacheStatusValues[0] = 'uncached'
  cacheStatusValues[1] = 'idle'
  cacheStatusValues[2] = 'checking'
  cacheStatusValues[3] = 'downloading'
  cacheStatusValues[4] = 'updateready'
  cacheStatusValues[5] = 'obsolete'

  cache.addEventListener('cached', logEvent, false)
  cache.addEventListener('checking', logEvent, false)
  cache.addEventListener('downloading', logEvent, false)
  cache.addEventListener('error', logEvent, false)
  cache.addEventListener('noupdate', logEvent, false)
  cache.addEventListener('obsolete', logEvent, false)
  cache.addEventListener('progress', logEvent, false)
  cache.addEventListener('updateready', logEvent, false)

  function logEvent(e) {
    const online = navigator.onLine ? 'yes' : 'no'
    const status = cacheStatusValues[cache.status]
    const type = e.type
    let message = `online: ${online}`
    message += `, event: ${type}`
    message += `, status: ${status}`
    if (type === 'error' && navigator.onLine) {
      message += ' (prolly a syntax error in manifest)'
    }
    app.log(message)
  }

  window.applicationCache.addEventListener(
    'updateready',
    () => {
      window.applicationCache.swapCache()
      app.log('swap cache has been called')
    },
    false
  )

  return true
}

module.exports = {
  start,
}
