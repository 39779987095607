import React, { useCallback } from 'react'
import { useFieldArray } from 'react-hook-form'
import { ruleConditions, ruleConditionsParemetersSectionTitles } from './data'

import ConditionWithSearch from '../../Condition/ConditionWithSearch'
import AutomationItem from '../../AutomationItem'
import MatchTypeSection from './MatchTypeSection'

export default React.memo(
  ({
    control,
    errors,
    trigger,
    setValue,
    conditionMatchTypeName,
    register,
  }) => {
    const { fields, append, remove } = useFieldArray({
      control,
      name: 'conditions',
      // default keyName is 'id', which overrides and removes our ids
      // before submit, to prevent that we set it to 'key' and
      // let useFieldArray handles it by itself
      keyName: 'key',
    })

    const onAddCondition = useCallback(
      () => {
        // Don't add key value for new entity, useFieldArray will add it
        // so the form won't be dirty if only the key is different
        const newCondition = {
          operator: null,
          param: null,
          value: '',
        }
        append(newCondition)
      },
      [append]
    )

    const onRemoveCondition = useCallback(
      (_, index) => {
        remove(index)
      },
      [remove]
    )

    const onConditionChange = useCallback(
      (newCondition, index, shouldValidate = true) => {
        setValue(`conditions.${index}`, newCondition, {
          shouldDirty: true,
          shouldValidate,
        })
        trigger(`conditions.${index}`)
      },
      [setValue, trigger]
    )

    const onConditionBlur = useCallback(
      index => {
        // Only validate input value on blur
        trigger(`conditions.${index}`)
      },
      [trigger]
    )

    return (
      <div>
        {fields.length === 0 ? (
          <AutomationItem.Button
            onClick={onAddCondition}
            isError={!!errors?.message}
          >
            Add a condition
          </AutomationItem.Button>
        ) : (
          <MatchTypeSection register={register} />
        )}

        {fields.map((item, index) => {
          return (
            <ConditionWithSearch
              control={control}
              name={`conditions.${index}`}
              key={item.key}
              itemKey={item.key}
              variables={ruleConditions}
              index={index}
              onRemoveCondition={onRemoveCondition}
              conditionErrorMessage={errors && errors[index]?.message}
              onConditionChange={onConditionChange}
              onBlur={onConditionBlur}
              onInput={onConditionChange}
              decodeIds
              placeholder="Select a condition..."
              conditionMatchTypeName={conditionMatchTypeName}
              tooltipForAdvancedFeature="This condition is part of our Advanced Rules"
              parameterSectionTitles={ruleConditionsParemetersSectionTitles}
              className="grui mt-6"
            />
          )
        })}
        {fields.length > 0 && (
          <AutomationItem.Button onClick={onAddCondition}>
            Add another condition
          </AutomationItem.Button>
        )}
      </div>
    )
  }
)
