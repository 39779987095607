import { Flex, Label } from 'shared/ui'
import { Checkbox } from 'util/ui'

import ActionAccessWarning from '../../shared/ActionAccessWarning'
import {
  CheckboxColumn,
  LabelColumn,
  SettingRow,
  Tabs,
} from '../../shared/SettingsModal'

export default function ActionsTab({ active, formFields, onChange }) {
  return (
    <Tabs.Content active={active}>
      <p>
        Set actions to <strong>Re-open</strong> again if {app.t('a_ticket')} has
        been closed and/or <strong>Show</strong> actions in the conversation
        view.
      </p>
      <ActionAccessWarning />
      <Flex>
        <LabelColumn>
          <Label as="h4">&nbsp;</Label>
          <SettingRow>Issue has been closed</SettingRow>
          <SettingRow>Issue has been re-opened</SettingRow>
          <SettingRow>Issue has been deleted</SettingRow>
          <SettingRow>Issue has been edited</SettingRow>
          <SettingRow>Assignees have been changed</SettingRow>
          <SettingRow>Labels have been changed</SettingRow>
          <SettingRow>Issue has been transferred</SettingRow>
          <SettingRow>Milestone has been added</SettingRow>
          <SettingRow>Milestone has been removed</SettingRow>
          <SettingRow>Issue locked</SettingRow>
          <SettingRow>Issue unlocked</SettingRow>
          <SettingRow>Issue pinned</SettingRow>
          <SettingRow>Issue unpinned</SettingRow>
          <SettingRow>Comment has been added</SettingRow>
        </LabelColumn>
        <CheckboxColumn>
          <Label as="h4">Re-open</Label>
          <SettingRow>
            <Checkbox
              checked={formFields['reopenOn.issueClosed']}
              name="reopenOn.issueClosed"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['reopenOn.issueReopened']}
              name="reopenOn.issueReopened"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['reopenOn.issueDeleted']}
              name="reopenOn.issueDeleted"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['reopenOn.issueEdited']}
              name="reopenOn.issueEdited"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['reopenOn.assigneesChanged']}
              name="reopenOn.assigneesChanged"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['reopenOn.labelsChanged']}
              name="reopenOn.labelsChanged"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['reopenOn.issueTransferred']}
              name="reopenOn.issueTransferred"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['reopenOn.issueMilestoned']}
              name="reopenOn.issueMilestoned"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['reopenOn.issueDemilestoned']}
              name="reopenOn.issueDemilestoned"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['reopenOn.issueLocked']}
              name="reopenOn.issueLocked"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['reopenOn.issueUnlocked']}
              name="reopenOn.issueUnlocked"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['reopenOn.issuePinned']}
              name="reopenOn.issuePinned"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['reopenOn.issueUnpinned']}
              name="reopenOn.issueUnpinned"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['reopenOn.issueCommentAdded']}
              name="reopenOn.issueCommentAdded"
              onChange={onChange}
            />
          </SettingRow>
        </CheckboxColumn>
        <CheckboxColumn>
          <Label as="h4">Show</Label>
          <SettingRow>
            <Checkbox
              checked={formFields['showAction.issueClosed']}
              name="showAction.issueClosed"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['showAction.issueReopened']}
              name="showAction.issueReopened"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['showAction.issueDeleted']}
              name="showAction.issueDeleted"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['showAction.issueEdited']}
              name="showAction.issueEdited"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['showAction.assigneesChanged']}
              name="showAction.assigneesChanged"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['showAction.labelsChanged']}
              name="showAction.labelsChanged"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['showAction.issueTransferred']}
              name="showAction.issueTransferred"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['showAction.issueMilestoned']}
              name="showAction.issueMilestoned"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['showAction.issueDemilestoned']}
              name="showAction.issueDemilestoned"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['showAction.issueLocked']}
              name="showAction.issueLocked"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['showAction.issueUnlocked']}
              name="showAction.issueUnlocked"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['showAction.issuePinned']}
              name="showAction.issuePinned"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['showAction.issueUnpinned']}
              name="showAction.issueUnpinned"
              onChange={onChange}
            />
          </SettingRow>
          <SettingRow>
            <Checkbox
              checked={formFields['showAction.issueCommentAdded']}
              name="showAction.issueCommentAdded"
              onChange={onChange}
            />
          </SettingRow>
        </CheckboxColumn>
      </Flex>
    </Tabs.Content>
  )
}
