import { all, any, uniq } from 'util/arrays'
import { toDate, diff as dateDiff } from 'util/date'
import { toInt } from 'util/ordinal'
import { eq, getOperatorFn } from 'util/operators'
import { constructSearchQueryId, searchDiff } from 'util/search'
import { _selectById as selectMailboxesById } from 'selectors/mailboxes/_selectById'
import { foldersByIdSelector as selectFoldersById } from 'selectors/folders/foldersByIdSelector'
import {
  isOpen,
  isClosed,
  isSpam,
  isStarred,
  isSnoozed,
} from 'util/ticketState'
import {
  NEWEST_BY_CLOSED,
  NEWEST_BY_DELETED,
  NEWEST_BY_SPAM,
} from 'constants/defaults'
import { CLOSED, SPAM } from 'constants/ticket_state_types'
import { hasTicketStateTypeCondition } from './hasTicketStateTypeCondition'
import { hasDeletedCondition } from './hasDeletedCondition'

// eslint-disable-next-line func-names
export const getFolderCount = function(preferences, folder, mailboxId) {
  if (mailboxId) return folder.counts[mailboxId] || 0
  return folder.counts.total || 0
}

const equalsTrue = m => eq(m, true)

export const isMe = id => id === '4517239960' // 1, hashed

// Loose equality match
const isTrue = str => str == 'true' // eslint-disable-line eqeqeq

export const priorityMap = {
  low: 1000,
  medium: 2000,
  high: 3000,
  urgent: 4000,
}

const statusMap = {
  unread: 1000,
  opened: 2000,
  follow_up: 3000,
  pending: 4000,
  closed: 5000,
  spam: 6000,
}

const hoursSinceOperands = (then, value, gateCondition = true) => ({
  prop: dateDiff('hours', toDate(then), new Date()),
  value: toInt(value),
  gateCondition,
})

const hoursSinceStateOperands = (ticket, value, state = null) =>
  hoursSinceOperands(
    ticket.stateChangedAt,
    value,
    state ? ticket.state === state : true
  )

const secondsUntilOperands = (until, value, gateCondition = true) => ({
  prop: until ? dateDiff('seconds', new Date(), toDate(until)) : undefined,
  value: toInt(value),
  gateCondition,
})

const getOperands = ({ param, value }, ticket) => {
  const { state = '' } = ticket
  switch (param) {
    case 'starred':
      return { prop: ticket[param], value: isTrue(value) }
    case 'priority':
      return {
        prop: priorityMap[ticket[param].toLowerCase()],
        value: priorityMap[value.toLowerCase()],
      }
    case 'status': {
      const conditionStatusInt = statusMap[value.toLowerCase()]
      let ticketStatusInt = statusMap[state.toLowerCase()]
      // If we're checking if a ticket is/is not closed, then make
      // sure we override the ticket status to 5001 when its snoozed
      // to ensure that it doesnt show in "closed" folders
      if (conditionStatusInt === 5000 && isSnoozed(ticket)) {
        ticketStatusInt = 5001
      }
      return {
        prop: ticketStatusInt,
        value: conditionStatusInt,
      }
    }
    case 'deleted':
      return { prop: Boolean(ticket.deleted_at), value: isTrue(value) }
    case 'channel':
      return { prop: ticket.type, value }
    case 'snooze_until': {
      const indefinitely = 999999999

      // When folder filter is snoozed indefinitely and ticket
      // is snoozed indefinitely
      if (
        value === 'indefinitely' &&
        ticket.snoozedUntil === 'SNOOZED_INDEFINITELY'
      )
        return { prop: indefinitely, value: indefinitely }

      // when folder condition is snoozed indefinitely and ticket
      // is snoozedUntil
      if (value === 'indefinitely')
        return secondsUntilOperands(ticket.snoozedUntil, indefinitely)

      // when folder condition is snoozed unil X and ticket is
      // snoozed indefinitely
      if (ticket.snoozedUntil === 'SNOOZED_INDEFINITELY') {
        return { prop: indefinitely, value }
      }

      // When neither folder condition or ticket is snoozed indefinitely
      return secondsUntilOperands(ticket.snoozedUntil, value)
    }
    case 'snooze_state': {
      // value of null means "Anyone", so if we have anyone,
      // we return a preformatted pair that will work in operation
      if (!!ticket.snoozedById && value === null) {
        return { prop: 1, value: 1 }
      } else if (ticket.snoozedById === null && value === null) {
        return { prop: 0, value: 1 }
      }
      return { prop: ticket.snoozedById, value }
    }
    case 'assigned_agent':
      return {
        prop: ticket.assignee ? ticket.assignee.id : null,
        value,
      }
    case 'assigned_group':
      return { prop: ticket.assigned_group_id, value }
    case 'interaction_count':
      return { prop: ticket.interactionCount, value }
    case 'hours_since_updated':
      return hoursSinceOperands(ticket.updated_at, value)
    case 'hours_since_status_changed':
      return hoursSinceStateOperands(ticket, value)
    case 'hours_since_open':
      return hoursSinceStateOperands(ticket, value, 'open')
    case 'hours_since_pending':
      return hoursSinceStateOperands(ticket, value, 'pending')
    case 'hours_since_closed':
      return hoursSinceStateOperands(ticket, value, 'closed')
    case 'hours_since_assigned':
      return hoursSinceOperands(ticket.assignedAt, value)
    case 'hours_since_last_unanswered_user_message':
      return hoursSinceOperands(ticket.lastUnansweredUserMessageAt, value)
    case 'labels':
      return { prop: ticket.labels.map(label => label.name), value }
    default:
      return { prop: ticket[param], value }
  }
}

// Determines if the given ticket matches the given filter condition
// Returns true/false
export const matchTicket = (condition, ticket) => {
  const opFn = getOperatorFn(condition.operator)
  const { prop, value, gateCondition = true } = getOperands(condition, ticket)
  return opFn(prop, value) && gateCondition
}

// Determines if the given ticket matches any/all (matchType) of the the given
// filter conditions
export const matchFilter = (conditions, matchType = 'any', ticket) => {
  const matchFn = matchType === 'all' ? all : any
  const matches = conditions.map(condition => matchTicket(condition, ticket))
  return matchFn(equalsTrue, matches)
}

const makeDiff = (stillMatches, folderId, mailboxId = null) => {
  const diff = {
    ungrouped: {
      [folderId]: stillMatches ? 0 : -1,
    },
  }
  if (mailboxId) {
    diff[mailboxId] = {}
    diff[mailboxId][folderId] = -1
  }
  return diff
}

export function negativeSearchDiff(search) {
  return {
    searches: {
      [constructSearchQueryId(search)]: -1,
    },
  }
}

export const folderSearchDiff = (
  ticket,
  folder = {},
  search = null,
  mailboxId = null
) => {
  // deleting is a special snowflake. We can short circuit our logic as we know
  // this ticket will no longer apply to any filters
  if (ticket.deleted_at) return negativeSearchDiff(search)

  // compute if this ticket still applies to given folder's conditions

  const matchesFilter = matchFilter(folder.conditions, folder.matchType, ticket)

  if (matchesFilter && mailboxId && ticket.mailboxId !== mailboxId) {
    let diff
    if (mailboxId) {
      diff = negativeSearchDiff(search)
    }
    return diff
  }

  if (matchesFilter) return null // no filter diff to apply

  return negativeSearchDiff(search)
}

// Creates a 'partial' diff, which is a simplified diff of the current folder
// (and optionally the mailbox too). It assumes that the ticket already applies
// to the current folder/mailbox, and will only generate a 'negative' diff if
// the given folder conditions *no longer* match the ticket.
//
//  E.g.
//   {"ungrouped":{"2914437040":-1},"0927513294":{"2914437040":-1}}
//
export const partialDiff = (
  ticket,
  folder = {},
  search = null,
  mailboxId = null
) => {
  const searchIsFolderSearch = folder && search && !!search.folder
  if (searchIsFolderSearch)
    return folderSearchDiff(ticket, folder, search, mailboxId)

  if (constructSearchQueryId(search)) return searchDiff(ticket, search)
  if (!folder) return null // e.g. we have no currentFolderId.

  // Ensure the ticket is cached in given folder's mailboxTickets. If not,
  // there's need to compute the diff - the ticket might have already been
  // removed from the mailbox, or the folder might not yet be fully loaded
  // (e.g. if you landed directly on the ticket page)
  const mailboxTickets = (folder.mailboxTickets || {})[mailboxId] || []
  if (!mailboxTickets.find(id => id === ticket.id)) return null

  // deleting is a special snowflake. We can short circuit our logic as we know
  // this ticket will no longer apply to any filters
  if (ticket.deleted_at) return makeDiff(false, folder.id, mailboxId)

  // compute if this ticket still applies to given folder's conditions
  const matchesFilter = matchFilter(folder.conditions, folder.matchType, ticket)

  if (matchesFilter && mailboxId && ticket.mailboxId !== mailboxId) {
    let diff
    if (mailboxId) {
      diff[mailboxId] = {}
      diff[mailboxId][folder.id] = -1
    }
    return diff
  }

  if (matchesFilter) return null // no filter diff to apply

  // At this point the filter no longer applies, so we create a 'partial' diff
  // on the current folder [and mailbox]
  return makeDiff(matchesFilter, folder.id, mailboxId)
}

/*
    components = []
    states = [nil, 'is:open'] if ticket.opened?
    states = [nil, 'is:closed'] if ticket.closed?
    states = [nil, 'is:snoozed'] if ticket.snoozed?
    states = [nil, 'is:open', 'is:unread'] if ticket.unread?
    states = [nil, 'is:spam'] if ticket.spam?

    priority = ticket.priority? ? [nil, 'is:starred'] : [nil]

    ticket.ticket_drafts.each do |ticket_draft|
      drafts = ["draft:#{ScatterSwap.hash(ticket_draft.agent_id)}"]
    end

    if (ticket.assignee_id)
      assignees = [nil, "is:assigned", "assignee:#{ScatterSwap.hash(ticket.assignee_id)}"]
    else
      assignees = [nil, "assignee:unassigned"]
    end
    if (ticket.assignee_group_id)
      groups = [nil, "is:assigned", "group:#{ScatterSwap.hash(ticket.assignee_group_id)}"]
    else
      groups = [nil, "group:unassigned"]
    end
    if (!ticket.assignee_id && !ticket.assignee_group_id)
      unassigned = [nil, 'is:unassigned']
    else
      unassigned = [nil]
    end
  */

const generateSearchesForTicket = ticket => {
  const searches = {}
  if (!ticket) return searches
  const { mailboxId } = ticket
  const isTicketOpen = isOpen(ticket)
  if (isTicketOpen) {
    searches['is:open'] = 1
    searches[`inbox:${mailboxId} is:open`] = 1
  }
  if (isClosed(ticket)) {
    searches['is:closed'] = 1
    searches[`inbox:${mailboxId} is:closed`] = 1
  }
  if (isSpam(ticket)) {
    searches['is:spam'] = 1
    searches[`inbox:${mailboxId} is:spam`] = 1
  }
  if (isStarred(ticket)) {
    searches['is:starred'] = 1
    searches[`inbox:${mailboxId} is:starred`] = 1
  }
  if (ticket.assignee) {
    const assigneeId = ticket.assignee.id
    searches[`is:assigned`] = 1
    searches[`assignee:${assigneeId}`] = 1
    searches[`inbox:${mailboxId} is:assigned`] = 1
    searches[`inbox:${mailboxId} assignee:${assigneeId}`] = 1
    if (isTicketOpen) {
      searches[`is:assigned is:open`] = 1
      searches[`assignee:${assigneeId} is:open`] = 1
      searches[`inbox:${mailboxId} is:assigned is:open`] = 1
      searches[`inbox:${mailboxId} assignee:${assigneeId} is:open`] = 1
    }
  }
  if (ticket.assigned_group_id) {
    const groupId = ticket.assigned_group_id
    searches[`is:assigned`] = 1
    searches[`group:${groupId}`] = 1
    searches[`inbox:${mailboxId} is:assigned`] = 1
    searches[`inbox:${mailboxId} group:${groupId}`] = 1
    if (isTicketOpen) {
      searches[`is:assigned is:open`] = 1
      searches[`group:${groupId} is:open`] = 1
      searches[`inbox:${mailboxId} is:assigned is:open`] = 1
      searches[`inbox:${mailboxId} group:${groupId} is:open`] = 1
    }
  }
  if (!ticket.assignee && !ticket.assigned_group_id) {
    searches[`is:unassigned`] = 1
    searches[`inbox:${mailboxId} is:unassigned`] = 1
    if (isTicketOpen) {
      searches[`is:unassigned is:open`] = 1
      searches[`inbox:${mailboxId} is:unassigned is:open`] = 1
    }
  }
  if (ticket.labels && ticket.labels.length > 0) {
    ticket.labels.forEach(label => {
      searches[`tag:${label.id}`] = 1
      searches[`inbox:${mailboxId} tag:${label.id}`] = 1
      if (isTicketOpen) {
        searches[`tag:${label.id} is:open`] = 1
        searches[`inbox:${mailboxId} tag:${label.id} is:open`] = 1
      }
    })
  }

  return searches
}

const calculateBasicDiff = (ticket, oldTicket) => {
  const newSearches = generateSearchesForTicket(ticket)
  const oldSearches = generateSearchesForTicket(oldTicket)
  const newKeys = Object.keys(newSearches)
  const oldKeys = Object.keys(oldSearches)
  const allKeys = uniq(newKeys.concat(oldKeys))
  const diff = {}
  allKeys.forEach(key => {
    const valueWas = oldSearches[key]
    const valueIs = newSearches[key]
    if (valueWas && !valueIs) diff[key] = -1
    if (!valueWas && valueIs) diff[key] = 1
  })
  return diff
}

// Creates a smarter version of the 'partial' diff, which attempts to add the ticketin a few places that can be figured out easily
export const smartPartialDiff = (state, ticket, oldTicket = {}) => {
  const result = {
    searches: calculateBasicDiff(ticket, oldTicket),
  }

  const mailboxId = ticket.mailboxId
  const oldMailboxId = oldTicket.mailboxId
  const mailboxesById = selectMailboxesById(state)
  const mailbox = mailboxesById[mailboxId]

  if (!mailbox) return result
  if (!mailbox.folders) return result

  const foldersById = selectFoldersById(state)

  mailbox.folders.forEach(id => {
    const folder = foldersById[id]
    const matchesNew = matchFilter(folder.conditions, folder.matchType, ticket)
    const matchesOld = matchFilter(
      folder.conditions,
      folder.matchType,
      oldTicket
    )

    if (matchesNew && !matchesOld) {
      result.searches[`folder:${folder.id}`] = 1
      result.searches[`inbox:${mailbox.id} folder:${folder.id}`] = 1
    }
    if (!matchesNew && matchesOld) {
      result.searches[`folder:${folder.id}`] = -1
      result.searches[`inbox:${mailbox.id} folder:${folder.id}`] = -1
    }
  })

  if (mailboxId !== oldMailboxId) {
    const oldMailbox = mailboxesById[oldMailboxId]
    if (oldMailbox) {
      oldMailbox.folders.forEach(id => {
        result.searches[`inbox:${oldMailboxId} folder:${id}`] = -1
      })
    }
  }

  return result
}

export const checkDiffs = (predicted, actual) => {
  // its entirely possible we dont have a previous folder so we cant (and dont
  // need to) compute an optimistic diff
  if (!predicted) return true

  let matches = true
  Object.keys(predicted).forEach(mailboxId => {
    Object.keys(predicted[mailboxId]).forEach(folderId => {
      const predictedMailbox = predicted[mailboxId]
      const predictedDiff = predictedMailbox ? predictedMailbox[folderId] : null
      const actualMailbox = actual[mailboxId]
      const actualDiff = actualMailbox ? actualMailbox[folderId] : null
      if (predictedDiff !== actualDiff) {
        matches = false
      }
    })
  })
  return matches
}

// auto select this option when navigating to to folder that matches this condition
export const DEFAULT_SORT_ORDER_FOR_FOLDER = new Map([
  [
    NEWEST_BY_CLOSED,
    fol => hasTicketStateTypeCondition(fol?.conditions, CLOSED),
  ],
  [NEWEST_BY_SPAM, fol => hasTicketStateTypeCondition(fol?.conditions, SPAM)],
  [NEWEST_BY_DELETED, fol => hasDeletedCondition(fol?.conditions)],
])
