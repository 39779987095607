import { css } from '@emotion/react'

const error = theme => css`
  margin-top: 220px;
  font-size: 144px;
  font-weight: ${theme.fontWeight.bold};
  color: ${theme.color.monochrome.medium};
  line-height: ${theme.lineHeight.leadingNone};
`

export const styles = {
  error,
}
