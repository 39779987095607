import { isEmpty, diff, sortByKey, uniqByProp } from 'util/arrays'
import { deepCopy } from 'util/objects'

export const labelSelection = ({
  common = [],
  uncommon = [],
  added = [],
  removed = [],
  top = [],
  created = [],
} = {}) => {
  return {
    common,
    uncommon,
    added,
    removed,
    top,
    created,
  }
}

export const commonLabelsFromSelection = selection => {
  const commonNames = selection.common.map(x => x.name)
  const removedNames = selection.removed.map(x => x.name)
  return diff(commonNames, removedNames).map((name, index) => {
    return selection.common.find(x => x.name === name)
  })
}

export const uncommonLabelsFromSelection = selection => {
  const uncommonNames = selection.uncommon.map(x => x.id)
  const removedNames = selection.removed.map(x => x.id)
  return diff(uncommonNames, removedNames).map((id, index) => {
    return selection.uncommon.find(x => x.id === id)
  })
}

export const inAdded = (label, selection) =>
  selection.added.map(x => x.name).includes(label.name)

export const inCommon = (label, selection) =>
  selection.common.map(x => x.name).includes(label.name)

export const inUncommon = (label, selection) =>
  selection.uncommon.map(x => x.name).includes(label.name)

const inTop = (label, selection) =>
  selection.top.map(x => x.name).includes(label.name)

const notInAddedOrCommon = (label, selection) =>
  !selection.added.map(x => x.name).includes(label.name) &&
  !selection.common.map(x => x.name).includes(label.name)

const inRemoved = (label, selection) =>
  selection.removed.map(x => x.name).includes(label.name)

export const commonTopLabelsFromSelection = selection =>
  selection.added.filter(
    label => !(inCommon(label, selection) || inUncommon(label, selection))
  )

export const unusedTopLabelsFromSelection = selection =>
  selection.top.filter(
    label =>
      notInAddedOrCommon(label, selection) && !inUncommon(label, selection)
  )

const curriedEnqueue = label => list => list.concat([label])
const curriedDequeue = label => list => list.filter(l => l.name !== label.name)
const curriedCheck = (label, selection) => fn => fn(label, selection)

export const updateSelection = ({
  selectedLabel,
  labelSelection: currentSelection,
  dontRemove,
}) => {
  const label = selectedLabel
  const selection = deepCopy(currentSelection)
  const { added, removed } = selection
  // Helpers to improve readability
  const is = curriedCheck(label, selection)
  const enqueue = curriedEnqueue(label)
  const dequeue = curriedDequeue(label)

  // "inTop" is a bit ambiguous here. It implies we are clicking an item in the
  // Most Used (i.e top) section, but it could actually mean we are clicking a
  // common/uncommon item that is also a (hidden) top item.
  const isUnusedTopItem = is(inTop) && !is(inCommon) && !is(inUncommon)
  if (isUnusedTopItem) {
    if (is(notInAddedOrCommon)) return { added: enqueue(added), removed }
    if (is(inAdded)) return { added: dequeue(added), removed }
  }

  if (is(inRemoved)) return { added: dequeue(added), removed: dequeue(removed) }

  if (is(inUncommon)) {
    if (is(inAdded) && !dontRemove)
      return { added: dequeue(added), removed: enqueue(removed) }
    return { added: enqueue(added), removed }
  }

  if (is(inCommon)) {
    if (is(inRemoved)) return { added, removed: dequeue(removed) }
    if (!dontRemove) return { added, removed: enqueue(removed) }
  }

  if (is(inAdded)) return { added: dequeue(added), removed }

  // otherwise we just add the label
  return { added: enqueue(added), removed }
}

// Saves the created prop from getting blown away
const withCreated = (updateFn, props) => {
  const {
    labelSelection: { created },
  } = props
  if (!created || isEmpty(created)) return updateFn(props)
  return {
    ...updateFn(props),
    created,
  }
}

export const updateLabelSelection = props => withCreated(updateSelection, props)

function mergeLabelLists(labels = [], added = []) {
  return sortByKey(uniqByProp(labels.concat(added), 'id'), 'name').filter(
    e => e
  )
}

export function getCurrentLabelsWithPendingEdits(
  labels = [],
  addedLabels = [],
  removedLabelIds = []
) {
  return mergeLabelLists(labels, addedLabels).filter(
    l => !removedLabelIds.includes(l.id)
  )
}

// This checked property is added by a selector
export const isChecked = label => label.status === 'checked'
