import { CHANNEL_TYPES } from 'subapps/settings/components/drawers/Channels/Channels.data'
import { GraphQlValidationError } from 'util/global-exceptions'
import { isObject } from 'util/objects'
import { isBridgeChannelType } from 'ducks/channels/channelTypes'

export const oAuthErrorToErrorCode = (error, channelType) => {
  // Kevin R (2021-12-08)
  // Good god, how can we have gotten error handling so wrong.
  // We need to refactor our graphql and oauth dialogs to return proper
  // error objects so that we can use a simple "instanceof" check to
  // determine what type of error has occurred.
  if (isObject(error)) {
    if (error instanceof GraphQlValidationError) {
      if (isBridgeChannelType(channelType)) {
        const { name = [] } = error.fields
        if (name.some(msg => msg === 'has already been taken')) {
          return 'ERR_VALIDATION_NAME_TAKEN'
        }
        return 'ERR_CREATE_WIDGET'
      }
      return 'ERR_CREATE_MAILBOX'
    } else if (error.errors) {
      const message = error.errors[0].message

      if (message.includes('CreateMailbox')) {
        return 'ERR_CREATE_MAILBOX'
      } else if (message.includes('CreateWidget')) {
        return 'ERR_CREATE_WIDGET'
      } else if (message.includes('OAuthAuthenticationUrl')) {
        return 'ERR_FETCH_OAUTH_URL'
      }
    } else if (
      error.message.includes('give') &&
      error.message.includes('permission') &&
      error.message.includes('page')
    ) {
      return 'MISSING_SPECIFIC_RESOURCE'
    } else if (
      [
        'ERR_AUTH_IMAP',
        'ERR_IMAP_CREDENTIALS',
        'ERR_IMAP_APP_PASSWORD',
      ].includes(error.message)
    ) {
      return error.message
    }
    // The following error can be returned from the gmail oauth process
    // Refresh token missing. Please remove Groove from your authorized applications at: https://security.google.com/settings/security/permissions and then link your mailbox again.
  } else if (
    Object.keys(CHANNEL_TYPES).includes(channelType) &&
    error.includes('already') &&
    error.includes('linked') &&
    error.includes('mailbox')
  ) {
    return 'ALREADY_LINKED'
  } else if (
    Object.keys(CHANNEL_TYPES).includes(channelType) &&
    error.includes('remove') &&
    error.includes('authorized') &&
    error.includes('applications')
  ) {
    return 'ALREADY_AUTHORIZED'
  } else if (
    Object.keys(CHANNEL_TYPES).includes(channelType) &&
    error.includes('scopes') &&
    error.includes('required') &&
    error.includes('not granted')
  ) {
    return 'MISSING_SCOPES'
  } else if (
    Object.keys(CHANNEL_TYPES).includes(channelType) &&
    error.includes('resources') &&
    error.includes('not granted')
  ) {
    return 'MISSING_RESOURCES'
  } else if (
    error === 'ERR_USER_CLOSED_POPUP' ||
    error === 'ERR_USER_REJECTED'
  ) {
    return error
  }

  return null
}
