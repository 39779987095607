import logo from 'assets/integrations/jira-server/icon-256.png'
import actionsScreenShot from 'assets/integrations/jira-cloud/screenshot-actions.jpg'
import linkScreenShot from 'assets/integrations/jira-cloud/screenshot-link.jpg'
import sidebarScreenShot from 'assets/integrations/jira-cloud/screenshot-sidebar.jpg'

import IntegrationsModal from '../../shared/Modal'
import JiraServerSettingsForm from './JiraServerSettingsForm'
import JiraServerInstallDescription from './JiraServerInstallDescription'

export default function IntegrationsJiraServerModal(props) {
  return (
    <IntegrationsModal
      {...props}
      InstallDescription={JiraServerInstallDescription}
      installWithSettings
      logo={logo}
      oauth_provider="jira_server"
      premium
      provider="jira_server"
      providerStylized="Jira Server"
      tagLine="Create and link Jira Server issues right from your conversations, and get updated on any changes."
      screenShots={{
        'A screen shot of the Jira Server actions in Groove': actionsScreenShot,
        'A screen shot of a linking Jira issues in Groove': linkScreenShot,
        'A screen shot of Jira Server issues in Groove': sidebarScreenShot,
      }}
      SettingsForm={JiraServerSettingsForm}
    />
  )
}
