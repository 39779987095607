import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'

const btn = theme => css`
  &&&& {
    font-family: ${theme.font.secondary};
    border-radius: ${rem(999)} !important;
    width: 100%;
  }
`

export const styles = {
  btn,
}
