import React, { useCallback } from 'react'
import cn from 'classnames'
import { useController } from 'react-hook-form'
import Field from '@groovehq/internal-design-system/lib/components/Field/Field'
import Checkbox from '@groovehq/internal-design-system/lib/components/Checkbox/Checkbox'
import Dropdown from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import { button } from '@groovehq/internal-design-system/lib/styles/elements'
import { styles as fieldStyles } from '@groovehq/internal-design-system/lib/components/Field/Field.styles'
import DropdownMenu from 'subapps/settings/components/DropdownMenu'
import { runOnNextTick } from 'util/functions'
import {
  EMAIL_SERVER_DOMAIN,
  EMAIL_SERVER_DOMAIN_PORT,
  EMAIL_SERVER_LOGIN_EMAIL,
  EMAIL_SERVER_AUTH_SSL,
  EMAIL_SERVER_LOGIN_PASSWORD,
  EMAIL_SERVER_IS_UPDATE_PASSWORD,
} from 'ducks/mailboxes/constants'
import { ports } from 'subapps/settings/pages/InboxManagementPage/SMTP/CustomSMTP/CustomSMTP.data'
import { getImapServerFieldName, getSmtpServerFieldName } from './config'
import { styles } from '../styles'

const isChangePasswordFieldName = getImapServerFieldName(
  EMAIL_SERVER_IS_UPDATE_PASSWORD
)

const ServerDetails = ({
  errors = {},
  register,
  setFocus,
  className,
  control,
  imapServerId,
  setValue,
  watch,
}) => {
  const isChangingPassword = !imapServerId || watch(isChangePasswordFieldName)

  // SMTP domain port dropdown
  const {
    field: { onChange: onSelectPort, value: selectedPort },
  } = useController({
    name: getSmtpServerFieldName(EMAIL_SERVER_DOMAIN_PORT),
    control,
  })

  const handleChangePassword = useCallback(
    () => {
      setValue(getImapServerFieldName(EMAIL_SERVER_IS_UPDATE_PASSWORD), true)
      runOnNextTick(() => {
        setFocus(getImapServerFieldName(EMAIL_SERVER_LOGIN_PASSWORD))
      })
    },
    [setFocus, setValue]
  )

  return (
    <div className={className}>
      <div className="grui flex">
        <Field
          label="IMAP Domain"
          placeholder="imap.email.com"
          {...register(getImapServerFieldName(EMAIL_SERVER_DOMAIN))}
          validateStatus={errors[EMAIL_SERVER_DOMAIN] ? 'error' : undefined}
          help={errors[EMAIL_SERVER_DOMAIN]?.message}
          className="grui flex-1"
        />
        <Field
          label="IMAP Port"
          placeholder="993"
          htmlType="number"
          {...register(getImapServerFieldName(EMAIL_SERVER_DOMAIN_PORT))}
          validateStatus={
            errors[EMAIL_SERVER_DOMAIN_PORT] ? 'error' : undefined
          }
          help={errors[EMAIL_SERVER_DOMAIN_PORT]?.message}
          className="grui ml-5"
          css={styles.portField}
        />
      </div>
      <div className="grui flex mt-12">
        <Field
          label="SMTP Domain"
          placeholder="smtp.email.com"
          {...register(getSmtpServerFieldName(EMAIL_SERVER_DOMAIN))}
          validateStatus={errors[EMAIL_SERVER_DOMAIN] ? 'error' : undefined}
          help={errors[EMAIL_SERVER_DOMAIN]?.message}
          className="grui flex-1"
        />
        <div className="grui ml-5">
          <div css={fieldStyles.labelBox}>SMTP Port</div>
          <Dropdown
            overlay={<DropdownMenu data={ports} />}
            onSelect={onSelectPort}
            selectedKey={selectedPort}
          >
            <Dropdown.Button css={styles.portField}>
              {selectedPort || 'Select port'}
            </Dropdown.Button>
          </Dropdown>
        </div>
      </div>
      <Field
        placeholder="Username"
        label="Login"
        htmlType="text"
        {...register(getImapServerFieldName(EMAIL_SERVER_LOGIN_EMAIL))}
        className="grui mt-10 channel-details-field"
        validateStatus={errors[EMAIL_SERVER_LOGIN_EMAIL] ? 'error' : undefined}
        help={errors[EMAIL_SERVER_LOGIN_EMAIL]?.message}
      />
      <div className="grui mt-5">
        {!isChangingPassword && (
          <button
            css={[button.styles.link]}
            type="button"
            className="grui mt-4"
            onClick={handleChangePassword}
          >
            Change password
          </button>
        )}
        <Field
          placeholder="Password"
          htmlType="password"
          {...register(getImapServerFieldName(EMAIL_SERVER_LOGIN_PASSWORD))}
          className={cn('grui channel-details-field', {
            hidden: !isChangingPassword,
          })}
          validateStatus={
            errors[EMAIL_SERVER_LOGIN_PASSWORD] ? 'error' : undefined
          }
          help={errors[EMAIL_SERVER_LOGIN_PASSWORD]?.message}
          autoComplete="off"
        />
        <Checkbox
          className="grui mt-10 flex"
          {...register(getImapServerFieldName(EMAIL_SERVER_AUTH_SSL))}
          id={getImapServerFieldName(EMAIL_SERVER_AUTH_SSL)}
        >
          Connect using SSL
        </Checkbox>
      </div>
    </div>
  )
}

export default ServerDetails
