import {
  V4_EMAIL_PAGE,
  V4_USER_PAGE,
  V4_COMPANY_PAGE,
  V4_VERIFICATION_PAGE,
} from './pages'

// NOTE (jscheel): Simple configuration object for onboarding routes to load

const V4_ROUTES_CONFIG = {
  [V4_EMAIL_PAGE]: {
    route: {
      path: '/signup/f4',
    },
    handler: () => ({
      componentName: 'V4EmailPage',
      subapp: 'onboarding',
    }),
  },
  [V4_USER_PAGE]: {
    route: {
      path: '/signup/f4/me',
    },
    handler: () => ({
      componentName: 'V4UserPage',
      subapp: 'onboarding',
    }),
  },
  [V4_COMPANY_PAGE]: {
    route: {
      path: '/signup/f4/organization',
    },
    handler: () => ({
      componentName: 'V4CompanyPage',
      subapp: 'onboarding',
    }),
  },
  [V4_VERIFICATION_PAGE]: {
    route: {
      path: '/signup/f4/verification',
    },
    handler: () => ({
      componentName: 'V4VerificationPage',
      subapp: 'onboarding',
    }),
  },
}

export default V4_ROUTES_CONFIG
