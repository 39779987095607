import { useMemo } from 'react'
import Table from '@groovehq/internal-design-system/lib/components/Table/Table'
import { selectChannelsBySlackId } from 'ducks/integrations/slack/selectors'
import { useSelector } from 'react-redux'

const { RowControls } = Table

const createRowControls = ({ value, onEdit, deleteData }) => {
  return <RowControls value={value} onEdit={onEdit} deleteData={deleteData} />
}

const useColumns = () => {
  const channelsBySlackId = useSelector(selectChannelsBySlackId)

  const columns = useMemo(
    () => {
      return [
        {
          Header: 'Channels',
          accessor: channel => {
            return channelsBySlackId[channel.slack_id]?.name || 'Unknown'
          },
          id: 'name',
        },
        {
          Header: '',
          accessor: 'gid',
          Cell: createRowControls,
          disableSortBy: true,
          id: 'controls',
        },
      ]
    },
    [channelsBySlackId]
  )

  return columns
}

export default useColumns
