import { css } from '@emotion/react'

const container = css`
  max-width: 400px;
`

const dropdown = css`
  width: 400px;
`

export const styles = {
  container,
  dropdown,
}
