import React from 'react'
import Link from 'redux-first-router-link'
import cn from 'classnames'
import { useSelector } from 'react-redux'
import DropdownMenuItem from '@groovehq/internal-design-system/lib/components/Dropdown/DropdownMenuItem'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'
import { text as textStyles } from '@groovehq/internal-design-system/lib/styles/elements'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import { useFeature } from 'ducks/billing/hooks'
import { SETTINGS_BILLING_PAGE } from 'subapps/settings/types'
import { selectPendingDowngradePlans } from 'ducks/billing/selectors/selectPendingDowngradePlans'
import { styles as menuWithSearchStyles } from 'subapps/settings/components/MenuWithSearch/styles'
import { camelize } from 'util/strings'
import { styles } from './styles'

const TO_SETTINGS_BILLING_PAGE = {
  type: SETTINGS_BILLING_PAGE,
  payload: {
    tab: 'plans',
  },
}

const Item = ({
  name,
  title,
  text,
  product,
  feature,
  checkProductFeatureLimit = false,
  disabled = false,
  itemKey,
  tooltipForAdvancedFeature = 'This is part of our Advanced features',
}) => {
  const { isFeatureEnabled } = useFeature(feature)
  const featureAvailable =
    feature && checkProductFeatureLimit ? isFeatureEnabled : true
  const disableItem = disabled || !featureAvailable
  const { [product]: pendingDowngradeInPlan } = useSelector(
    selectPendingDowngradePlans
  )
  const featureAvailableInPendingPlan =
    checkProductFeatureLimit && feature && pendingDowngradeInPlan
      ? pendingDowngradeInPlan?.features[camelize(feature)]
      : true

  const upgradeNotice = (
    <div
      css={[
        textStyles.styles.textXs,
        textStyles.styles.fontMedium,
        textStyles.styles.textWhite,
      ]}
    >
      This feature is not
      <br />
      available on your current plan
      <br />
      <Button
        as={Link}
        to={TO_SETTINGS_BILLING_PAGE}
        css={menuWithSearchStyles.upgradeBtn}
        type="info"
        size="small"
        className="grui px-12 py-3 mt-6"
      >
        See Plans
      </Button>
    </div>
  )

  const content = name || title || text || itemKey

  return (
    <DropdownMenuItem
      itemKey={itemKey}
      disabled={disableItem}
      className={cn({ 'grui w-auto pr-0': !featureAvailable })}
      css={!featureAvailable && styles.featureUnAvailableItem}
    >
      {featureAvailable && featureAvailableInPendingPlan ? (
        content
      ) : (
        <Tooltip
          title={!featureAvailable ? upgradeNotice : tooltipForAdvancedFeature}
          strategy="fixed"
          portal="#overlays"
          interactiveTooltip
          css={menuWithSearchStyles.limitedTooltip}
          className={cn(
            'grui',
            !featureAvailableInPendingPlan ? 'py-8' : 'pt-6 pb-11 px-11'
          )}
          maxWidth={featureAvailable ? 160 : undefined}
          offset={2}
        >
          <span>
            {content}
            <span
              className="grui px-3 align-middle"
              css={textStyles.styles.textNormal}
            >
              ⚡
            </span>
          </span>
        </Tooltip>
      )}
    </DropdownMenuItem>
  )
}

export default Item
