import {
  SHOPIFY_EMAIL_PAGE,
  SHOPIFY_USER_PAGE,
  SHOPIFY_COMPANY_PAGE,
  SHOPIFY_TRIAL_PAGE,
} from './pages'

const SHOPIFY_ROUTES_CONFIG = {
  [SHOPIFY_EMAIL_PAGE]: {
    route: {
      path: '/signup/shopify',
    },
    handler: () => ({
      componentName: 'ShopifyEmailPage',
      subapp: 'onboarding',
    }),
  },
  [SHOPIFY_USER_PAGE]: {
    route: {
      path: '/signup/shopify/me',
    },
    handler: () => ({
      componentName: 'ShopifyUserPage',
      subapp: 'onboarding',
    }),
  },
  [SHOPIFY_COMPANY_PAGE]: {
    route: {
      path: '/signup/shopify/organization',
    },
    handler: () => ({
      componentName: 'ShopifyCompanyPage',
      subapp: 'onboarding',
    }),
  },
  [SHOPIFY_TRIAL_PAGE]: {
    route: {
      path: '/signup/shopify/trial',
    },
    handler: () => ({
      componentName: 'ShopifyTrialPage',
      subapp: 'onboarding',
    }),
  },
}

export default SHOPIFY_ROUTES_CONFIG
