// This will let you nest reducers and have them work on the same piece of state.

// Note: @msas I'm super against using this pattern as it couples reducer that
// otherwise wouldn't be coupled. At the very least they need to be good citizens
// and only update the part of the state branch that is there responsibility. For
// Example they can never return an entirely new state object, they must always
// merge in the state of reducers to the right.
export default function createComposedReducer(...reducers) {
  if (reducers.length === 0) {
    return arg => arg
  }

  if (reducers.length === 1) {
    return reducers[0]
  }

  return (state, action) => {
    return reducers.reduce((progress, reducer) => {
      // If state is undefined ie we are in the initial state setup we need to use
      // that and not the result so that each reducer has an oppotunity to set up
      // its initial state and if these are all objects they are merged together.
      if (typeof state === 'undefined') {
        const currentState = reducer(undefined, action)
        if (typeof progress === 'object' && typeof currentState === 'object') {
          return {
            ...progress,
            ...currentState,
          }
        }
        return currentState
      }
      return reducer(progress, action)
    }, state)
  }
}
