import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util'

const header = css`
  h4 {
    margin-top: 44px !important;
  }
`

const fullWidth = css`
  max-width: 100%;
`

const colorLabel = css`
  & + * {
    width: 100%;
  }
`

const colorPickerBtn = css`
  width: 100%;
`

const popoverContainer = css`
  left: 0 !important;
  right: unset !important;
`

const btnIcon = theme => css`
  svg {
    width: ${rem(24)};
    height: ${rem(24)};
    background: ${theme.color.monochrome.white};
    border-radius: 50%;
    vertical-align: middle;
  }
`

const btn = css`
  width: ${rem(24)};
  height: ${rem(24)};
  svg {
    vertical-align: unset;
  }
`

const messageCard = css`
  box-shadow: unset;
`

const content = theme => css`
  width: 400px;
  font-size: ${theme.fontSize.base};

  .channel-details-field {
    max-width: 100%;
  }
`

const oauthBtn = css`
  & > span {
    display: inline-flex;
    align-items: center;
  }
`

const description = css`
  max-width: 500px;
`

export const styles = {
  header,
  fullWidth,
  colorLabel,
  colorPickerBtn,
  popoverContainer,
  btnIcon,
  btn,
  messageCard,
  content,
  oauthBtn,
  description,
}
