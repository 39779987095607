import React, { useCallback, useMemo } from 'react'
import Drawer from '@groovehq/internal-design-system/lib/components/Drawer/UnmanagedDrawer'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import Tabbed from 'subapps/settings/components/drawers/Tabbed'
import { useFetchIntegrationProvider } from 'ducks/integrations/hooks'
import General from './General'
import AdvancedConfigure from '../../../AdvancedConfigure'

const ShopifyConfigureDrawer = ({
  drawerResourceId: providerId,
  drawerInternalAction,
  open,
  onClose,
  onExit,
  drawerId,
  drawer,
  updateDrawerParameter,
  ...rest
}) => {
  const { isLoading, hasError, isMissing } = useFetchIntegrationProvider(
    providerId
  )

  const tabsData = useMemo(
    () => [
      {
        key: 'general',
        tabName: 'General',
        component: props => (
          <General {...props} internalAction={drawerInternalAction} />
        ),
      },
      {
        key: 'advanced',
        tabName: 'Advanced',
        component: AdvancedConfigure,
      },
    ],
    [drawerInternalAction]
  )

  const drawerTabId = rest.drawerTabId || tabsData[0].key

  const handleTabKeyChange = useCallback(
    key => {
      if (key === drawerTabId) return
      updateDrawerParameter('drawerTabId', key)
    },
    [drawerTabId, updateDrawerParameter]
  )

  return (
    <Drawer
      {...rest}
      onClose={onExit}
      isLoading={isLoading}
      isError={hasError}
      isNoResultFound={isMissing}
      size="wide"
      title="Configure Shopify"
      open={open}
      footer={
        <ModalBtns tertiaryBtnText="Cancel" onClickTertiaryBtn={onClose} />
      }
    >
      <Tabbed
        tabsData={tabsData}
        activeTabKey={drawerTabId}
        onTabKeyChange={handleTabKeyChange}
        providerId={providerId}
      />
    </Drawer>
  )
}

export default ShopifyConfigureDrawer
