import React, { useMemo } from 'react'
import { func, shape, arrayOf, oneOf } from 'prop-types'
import { useSelector } from 'react-redux'
import TableWithCheckbox from '@groovehq/internal-design-system/lib/components/TableWithCheckbox/TableWithCheckbox'
import { selectMailboxesIncludingInaccessible } from 'selectors/mailboxes/selectMailboxesIncludingInaccessible'
import { selectWidgetsWithChat } from 'ducks/widgets/selectors/widgets'
import { buildId, isGid } from 'util/globalId'
import useColumns from './useColumns'
import { EMAIL_TYPE, LIVE_CHAT_WIDGET_TYPE } from './types'

// because of the potential conflicts of id returned from onSelectedRowIdsChange
// onSelectedRowIdsChange will now only return GlobalIds i.e. ch_123456
const ChannelSelectionTable = ({
  onSelectedRowIdsChange,
  defaultSelectedRowIds,
  displayChannelTypes,
  ...rest
}) => {
  const columns = useColumns({ showType: displayChannelTypes?.length > 1 })
  const mailboxes = useSelector(selectMailboxesIncludingInaccessible)
  const widgetsWithChat = useSelector(selectWidgetsWithChat)

  const data = useMemo(
    () => {
      let mailboxesData = []
      let widgetsData = []

      if (displayChannelTypes.includes(EMAIL_TYPE)) {
        // mailboxes
        mailboxesData = mailboxes.map(mailbox => {
          let id = mailbox?.gid || mailbox.id
          if (!isGid(id)) {
            id = buildId('Channel', id)
          }

          return {
            ...mailbox,
            id,
          }
        })
      }

      if (displayChannelTypes.includes(LIVE_CHAT_WIDGET_TYPE)) {
        // live chat widget
        widgetsData = widgetsWithChat.map(widget => {
          let id = widget?.gid || widget.id
          if (!isGid(id)) {
            id = buildId('Widget', id)
          }

          return {
            ...widget,
            id,
          }
        })
      }

      return mailboxesData.concat(widgetsData)
    },
    [mailboxes, widgetsWithChat, displayChannelTypes]
  )

  return (
    <TableWithCheckbox
      columns={columns}
      onSelectedRowIdsChange={onSelectedRowIdsChange}
      data={data}
      defaultSelectedRowIds={defaultSelectedRowIds}
      columnWithCheckbox="name"
      {...rest}
    />
  )
}

ChannelSelectionTable.propTypes = {
  onSelectedRowIdsChange: func,
  defaultSelectedRowIds: shape({}),
  displayChannelTypes: arrayOf(oneOf([EMAIL_TYPE, LIVE_CHAT_WIDGET_TYPE])),
}

ChannelSelectionTable.defaultProps = {
  onSelectedRowIdsChange() {},
  defaultSelectedRowIds: undefined,
  displayChannelTypes: [EMAIL_TYPE],
}

export default ChannelSelectionTable
