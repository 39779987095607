import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util'

const base = theme => css`
  min-width: ${rem(200)};
  & :last-of-type button {
    color: ${theme.color.primary.negative};
  }
`

const icon = theme => css`
  width: ${rem(24)};
  height: ${rem(24)};
  color: ${theme.color.monochrome.mediumDark};
  vertical-align: middle;
`

export const styles = {
  base,
  icon,
}
