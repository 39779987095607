/* eslint-disable no-param-reassign */
import React, { useLayoutEffect, useRef } from 'react'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import Agent from './Agent'
import { getOverflowPixels } from './util'

const Agents = ({ list, operator = 'or' }) => {
  const containerRef = useRef()

  useLayoutEffect(() => {
    const containerElement = containerRef.current
    if (!containerElement) return

    // Show ellipsis and remove the previous operator if overflow
    const childNodes = Array.from(containerRef.current.children)
    let lastShownElementIndex = 0
    childNodes.forEach((child, index) => {
      if (child.previousElementSibling?.hidden || lastShownElementIndex > 0) {
        child.hidden = true
        return
      }
      const overflowPixels = getOverflowPixels(child, containerElement)
      if (overflowPixels <= 0) return
      if (child.matches('[data-teammates-dropdown-agent]')) {
        lastShownElementIndex = index
        const childWidth = child.clientWidth - overflowPixels
        if (childWidth === 0) {
          child.hidden = true
          if (child.previousElementSibling) {
            // Replace the previous operator with ellipsis if the agent item is hidden
            child.previousElementSibling.removeAttribute('class')
            child.previousElementSibling.textContent = '...'
          }
        } else if (childWidth <= 30) {
          // 30px is for avatar + ellipsis
          child.previousElementSibling.hidden = true
          child.textContent = '...'
        }
        // Remove flex-shrink for truncate class to work
        child.classList.remove('flex-shrink-0')
      } else {
        child.hidden = true
        child.previousElementSibling.innerHTML += '...'
        // Remove flex-shrink for truncate class to work
        child.previousElementSibling.classList.remove('flex-shrink-0')
      }
    })
  }, [])

  if (list.length === 0) return null

  return (
    <div className="grui flex" ref={containerRef}>
      {list.map(({ id, username, avatarUrl, fullName }, index) => {
        return (
          <React.Fragment key={id}>
            {index > 0 && (
              <span css={text.styles.fontNormal} className="grui mx-4">
                {operator}
              </span>
            )}
            <Agent
              id={id}
              index={index}
              username={username}
              avatarUrl={avatarUrl}
              fullName={fullName}
              className="grui flex-shrink-0 truncate"
            />
          </React.Fragment>
        )
      })}
    </div>
  )
}

export default Agents
