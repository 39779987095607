import { Checkbox } from 'util/ui'

import { SettingRow, Tabs } from '../../shared/SettingsModal'

export default function SidebarTab({ active, formFields, onChange }) {
  return (
    <Tabs.Content active={active}>
      <p>
        Choose which fields you want to display in the sidebar of all your
        linked GitHub issues.
      </p>
      <SettingRow>
        <Checkbox
          checked={formFields['showSidebarField.repository']}
          name="showSidebarField.repository"
          onChange={onChange}
        />{' '}
        <span>Repository</span>
      </SettingRow>
      <SettingRow>
        <Checkbox
          checked={formFields['showSidebarField.state']}
          name="showSidebarField.state"
          onChange={onChange}
        />{' '}
        <span>State (open/closed)</span>
      </SettingRow>
      <SettingRow>
        <Checkbox
          checked={formFields['showSidebarField.assignees']}
          name="showSidebarField.assignees"
          onChange={onChange}
        />{' '}
        <span>Assignees</span>
      </SettingRow>
      <SettingRow>
        <Checkbox
          checked={formFields['showSidebarField.labels']}
          name="showSidebarField.labels"
          onChange={onChange}
        />{' '}
        <span>Labels</span>
      </SettingRow>
      <SettingRow>
        <Checkbox
          checked={formFields['showSidebarField.created']}
          name="showSidebarField.created"
          onChange={onChange}
        />{' '}
        <span>Created at</span>
      </SettingRow>
      <SettingRow>
        <Checkbox
          checked={formFields['showSidebarField.lastUpdated']}
          name="showSidebarField.lastUpdated"
          onChange={onChange}
        />{' '}
        <span>Last Updated</span>
      </SettingRow>
      <SettingRow>
        <Checkbox
          checked={formFields['showSidebarField.milestone']}
          name="showSidebarField.milestone"
          onChange={onChange}
        />{' '}
        <span>Milestone</span>
      </SettingRow>
      <SettingRow>
        <Checkbox
          checked={formFields['showSidebarField.locked']}
          name="showSidebarField.locked"
          onChange={onChange}
        />{' '}
        <span>Locked</span>
      </SettingRow>
    </Tabs.Content>
  )
}
