import React from 'react'

import { useLink } from 'ducks/location/hooks'

import { SETTINGS_INTEGRATIONS_PROVIDER_PAGE } from '../../../../types'

import { Tabs } from '../../shared/SettingsModal'
import ActionsTab from './ActionsTab'
import SidebarTab from './SidebarTab'
import InstallsTab from './InstallsTab'

const toActionsTab = {
  type: SETTINGS_INTEGRATIONS_PROVIDER_PAGE,
  payload: { provider: 'github', tab: null },
}
const toSidebarTab = {
  type: SETTINGS_INTEGRATIONS_PROVIDER_PAGE,
  payload: { provider: 'github', tab: 'sidebar' },
}
const toInstallsTab = {
  type: SETTINGS_INTEGRATIONS_PROVIDER_PAGE,
  payload: { provider: 'github', tab: 'installs' },
}

export default function GitHubSettingsForm({
  formFields,
  installPath,
  tab,
  onChange,
}) {
  const toActionsTabAction = useLink(toActionsTab, { preserveQuery: true })
  const toSidebarTabAction = useLink(toSidebarTab, { preserveQuery: true })
  const toInstallsTabAction = useLink(toInstallsTab, { preserveQuery: true })
  return (
    <Tabs>
      <Tabs.Header>
        <Tabs.Link active={!tab} to={toActionsTabAction}>
          Actions
        </Tabs.Link>
        <Tabs.Link active={tab === 'sidebar'} to={toSidebarTabAction}>
          Sidebar
        </Tabs.Link>
        <Tabs.Link active={tab === 'installs'} to={toInstallsTabAction}>
          Installs
        </Tabs.Link>
      </Tabs.Header>
      <ActionsTab active={!tab} formFields={formFields} onChange={onChange} />
      <SidebarTab
        active={tab === 'sidebar'}
        formFields={formFields}
        onChange={onChange}
      />
      <InstallsTab active={tab === 'installs'} installPath={installPath} />
    </Tabs>
  )
}
