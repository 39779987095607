import { useMemo } from 'react'
import { Controller, useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'

import { selectProfilesBySlackId } from 'ducks/integrations/slack/selectors'
import { selectCurrentUnarchivedAgentsById } from 'ducks/agents/selectors'
import { convertGidToScatterId } from 'util/scatterSwap'

import SearchableSelect from 'subapps/settings/components/SearchableSelect'

import { styles } from '../../styles'

const useColumns = ({ control }) => {
  const profilesBySlackId = useSelector(selectProfilesBySlackId)
  const unarchivedAgentsById = useSelector(selectCurrentUnarchivedAgentsById)
  const formValues = useWatch({ control })

  const columns = useMemo(
    () => {
      const formatUserSelector = ({
        row: {
          original: { gid: agentGid },
        },
      }) => {
        const data = []
        const unavailableSlackIds = Object.keys(formValues).reduce(
          (memo, agentGidFormKey) => {
            const slackId = formValues[agentGidFormKey]
            if (slackId && agentGidFormKey !== agentGid) {
              // eslint-disable-next-line no-param-reassign
              memo[slackId] = true
            }
            return memo
          },
          {}
        )
        Object.values(profilesBySlackId).forEach(profile => {
          const unavailable = !!unavailableSlackIds[profile.slack_id]
          const archived =
            profile.agent_gid &&
            !unarchivedAgentsById[convertGidToScatterId(profile.agent_gid)]

          if (unavailable || archived) return

          data.push({
            key: profile.slack_id,
            name: `@${profile.username}`,
          })
        })
        const defaultValue = formValues[agentGid]

        return (
          <Controller
            control={control}
            name={agentGid}
            defaultValue={defaultValue}
            // eslint-disable-next-line react/jsx-no-bind
            render={({ field: { onChange, onBlur, value } }) => {
              if (value) {
                return (
                  <div className="grui flex justify-between items-center">
                    @{profilesBySlackId[value].username}
                    <Button
                      type="tertiary"
                      size="small"
                      css={styles.unsetButton}
                      // eslint-disable-next-line react/jsx-no-bind
                      onClick={() => {
                        onChange(null)
                      }}
                    >
                      Unset
                    </Button>
                  </div>
                )
              }
              return (
                <SearchableSelect
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  data={data}
                  control={control}
                  searchPlaceholder="Search Slack usernames..."
                  placeholder="Select Slack username..."
                  size="small"
                  overlayMinWidth="274px"
                />
              )
            }}
          />
        )
      }

      return [
        {
          Header: app.t('Agent'),
          accessor: 'fullName',
        },
        {
          Header: 'Slack Username',
          accessor: 'gid',
          Cell: formatUserSelector,
          disableSortBy: true,
        },
      ]
    },
    [profilesBySlackId, control, formValues, unarchivedAgentsById]
  )

  return columns
}

export default useColumns
