import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectIsLoadingCustomerRatingSettings,
  selectIsLoadingCustomerRatingSetting,
  selectCustomerRatingSettingById,
} from 'ducks/customerRatingSettings/selectors'
import { selectCustomerRatingSettings } from 'ducks/customerRatingSettings/selectors/selectCustomerRatingSettings'
import { useController, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useRhfDirtyHold, useConfirmHoldsCallback } from 'util/dirtyHolds'
import { doUpsertCustomerRatingSetting } from 'ducks/customerRatingSettings/operations'
import { styles as mailboxStyles } from '@groovehq/internal-design-system/lib/components/Field/Field.styles'

import * as yup from 'yup'
import Field from '@groovehq/internal-design-system/lib/components/Field/Field'
import Drawer from '@groovehq/internal-design-system/lib/components/Drawer/UnmanagedDrawer'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import { styles } from './styles'
import CustomizeWording from './CustomizeWording'

import {
  defaultValues as translationDefaultValues,
  languagesData,
  emailSchema,
  formSchema,
} from './CustomizeWording/data'
import MailboxSelection from '../../MailboxSelection'

const schema = {
  locale: yup.string().oneOf(languagesData.map(l => l.id)),
  name: yup.string().required('Internal name is required'),
  mailboxIds: yup.array().of(yup.string()),
  channelVisibility: yup.string(),
  translations: yup.object({
    email: yup.object(emailSchema),
    form: yup.object(formSchema),
  }),
}

const FORM_SCHEMA = yup.object().shape(schema)

const CustomeRatingSettingEdit = ({
  drawerId,
  drawerResourceId: customerRatingSettingId,
  open,
  onClose,
  onExit,
  rest,
}) => {
  const dispatch = useDispatch()
  const customerRatingSetting = useSelector(state =>
    selectCustomerRatingSettingById(state, customerRatingSettingId)
  )
  const isLoading = useSelector(selectIsLoadingCustomerRatingSettings)
  const isSaving = useSelector(selectIsLoadingCustomerRatingSetting)
  const isUpdate = customerRatingSettingId !== 'new'

  const mailboxIds = useMemo(
    () => {
      return customerRatingSetting.mailboxIds || []
    },
    [customerRatingSetting]
  )

  const allCustomerRatingSettings = useSelector(selectCustomerRatingSettings)

  const mailboxIdsFromCustomerRatingSettings = useMemo(
    () => {
      return allCustomerRatingSettings
        .filter(s => s.id !== customerRatingSettingId)
        .map(s => {
          if (s.mailboxIds.length === 0) {
            return 'all'
          }
          return s.mailboxIds
        })
        .flat()
    },
    [allCustomerRatingSettings, customerRatingSettingId]
  )

  const channelVisibility = useMemo(
    () => {
      if (mailboxIdsFromCustomerRatingSettings.length > 0) return 'selected'

      return customerRatingSetting.mailboxIds?.length ? 'selected' : 'all'
    },
    [customerRatingSetting, mailboxIdsFromCustomerRatingSettings]
  )

  const {
    handleSubmit,
    register,
    control,
    reset,
    setValue,
    formState: { errors, isValid, isDirty },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(FORM_SCHEMA),
    defaultValues: {
      locale: 'en',
      mailboxIds,
      channelVisibility,
      ...translationDefaultValues,
    },
  })

  const {
    field: { onChange: onMailboxIdsChange, value: mailboxIdsValue },
  } = useController({
    name: 'mailboxIds',
    control,
    defaultValue: mailboxIds,
  })

  const {
    field: {
      onChange: onChannelVisibilityChange,
      value: channelVisibilityValue,
      ref: channelVisibilityRef,
      onBlur: onChannelVisibilityBlur,
    },
  } = useController({
    name: 'channelVisibility',
    control,
    defaultValue: channelVisibility,
  })

  const { releaseHold } = useRhfDirtyHold(drawerId, control)

  useEffect(
    () => {
      return () => releaseHold()
    },
    [releaseHold]
  )

  useEffect(
    () => {
      if (!open) return

      if (isUpdate && open) {
        reset({
          id: customerRatingSetting.id,
          locale: customerRatingSetting.locale,
          name: customerRatingSetting.name,
          mailboxIds,
          channelVisibility,
        })
      }
    },
    [
      isUpdate,
      open,
      reset,
      customerRatingSetting,
      mailboxIds,
      channelVisibility,
    ]
  )

  const onSubmit = useCallback(
    async data => {
      releaseHold()

      await dispatch(
        doUpsertCustomerRatingSetting({
          locale: data.locale,
          name: data.name,
          settingId: data.id,
          translations: data.translations,
          mailboxIds: data.mailboxIds,
          channelVisibility: data.channelVisibility,
        })
      )

      onClose()
    },
    [dispatch, releaseHold, onClose]
  )

  const handleOnClose = useCallback(onClose, [onClose])
  const handleOnExit = useConfirmHoldsCallback(null, onExit, [onExit])

  const DrawerForm = useCallback(
    props => {
      return <form onSubmit={handleSubmit(onSubmit)} {...props} />
    },
    [handleSubmit, onSubmit]
  )

  return (
    <Drawer
      {...rest}
      open={open}
      onClose={handleOnExit}
      title={
        isUpdate ? 'Edit satisfaction ratings' : 'Set up satisfaction ratings'
      }
      isLoading={isLoading}
      size="wide"
      footer={
        <ModalBtns
          saveBtnDisabled={isLoading || isSaving || !isValid || !isDirty}
          saveBtnText={
            isUpdate
              ? 'Update satisfaction ratings'
              : 'Enable satisfaction ratings'
          }
          saveBtnHtmlType="submit"
          tertiaryBtnText="Cancel"
          onClickTertiaryBtn={handleOnClose}
        />
      }
      container={DrawerForm}
    >
      <div className="grui mt-10 mb-12">
        <Field
          {...register('name')}
          label="Internal name"
          placeholder="Enter internal name..."
          name="name"
          validateStatus={errors?.name ? 'error' : undefined}
          help={errors?.name?.message}
        />
      </div>

      <CustomizeWording
        control={control}
        register={register}
        setValue={setValue}
        settingId={customerRatingSetting.id}
      />

      <div css={mailboxStyles.labelBox} className="grui mt-12">
        Select which {app.t('mailboxes')} to include the{' '}
        {app.t('customer_rating')} in
      </div>

      {mailboxIdsFromCustomerRatingSettings.length > 0 && (
        <p css={styles.note}>
          {`Note: ${app.t(
            'Mailboxes'
          )} that already have a satisfaction rating set up are hidden.`}
        </p>
      )}

      <MailboxSelection
        dropdownBtnStyles={styles.dropdownBtn}
        checkboxListStyles={styles.checkboxList}
        selectedIds={mailboxIdsValue}
        usedMailboxIds={mailboxIdsFromCustomerRatingSettings}
        type={channelVisibilityValue}
        onMailboxListChange={onMailboxIdsChange}
        onMailboxVisibilityTypeChange={onChannelVisibilityChange}
        mailboxVisibilityTypeRef={channelVisibilityRef}
        onMailboxVisibilityTypeBlur={onChannelVisibilityBlur}
        dropdownBtnSize="small"
        showDropdown={mailboxIdsFromCustomerRatingSettings.length === 0}
      />
    </Drawer>
  )
}

export default CustomeRatingSettingEdit
