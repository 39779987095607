import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useConfirmHoldsCallback } from 'util/dirtyHolds'
import {
  selectSubscriptionForId,
  selectChargeForId,
} from 'ducks/integrations/recharge/selectors'
import { useRechargeSubscriptions } from 'ducks/integrations/recharge/hooks/subscriptions'
import { useRechargeCharges } from 'ducks/integrations/recharge/hooks/charges'
import { pluralize } from 'util/strings'
import { capture } from 'ducks/tracking/actions'

import moment from 'moment'
import SanitizedHTML from 'shared/components/ui/SanitizedHTML'
import Drawer from '@groovehq/internal-design-system/lib/components/Drawer/UnmanagedDrawer'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import doSkipNextOrder from 'ducks/integrations/recharge/operations/doSkipNextOrder'
import doFetchCharges from 'ducks/integrations/recharge/operations/doFetchCharges'

import { styles } from './styles'

const RechargeSkipNextOrder = ({
  drawerResourceId,
  drawerIntegrationId,
  open,
  onClose,
  onExit,
  drawerId,
  queryId,
  ...rest
}) => {
  const dispatch = useDispatch()
  const [customerId, subscriptionId, chargeId] = drawerResourceId.split('-')

  const subscription = useSelector(state =>
    selectSubscriptionForId(state, { customerId, subscriptionId })
  )
  const charge = useSelector(state =>
    selectChargeForId(state, { subscriptionId, chargeId })
  )

  const { isLoading: isSubscriptionsLoading } = useRechargeSubscriptions(
    drawerIntegrationId,
    customerId
  )
  const { isLoading: isChargesLoading } = useRechargeCharges(subscription?.id)

  const isLoading = !!isSubscriptionsLoading || !!isChargesLoading

  const isUpdating = useMemo(
    () => {
      return charge?.isUpdating
    },
    [charge]
  )

  const handleOnClose = useConfirmHoldsCallback(drawerId, onClose, [onClose])

  const onSave = useCallback(
    async () => {
      await dispatch(
        doSkipNextOrder({
          customerId,
          subscriptionId,
          chargeId,
          integrationId: drawerIntegrationId,
        })
      )

      await dispatch(
        doFetchCharges({ integrationId: drawerIntegrationId, subscriptionId })
      )

      capture('Recharge Order Skipped')

      onExit()
    },
    [
      dispatch,
      onExit,
      customerId,
      subscriptionId,
      chargeId,
      drawerIntegrationId,
    ]
  )

  const descriptionText = useMemo(
    () => {
      if (!charge || !subscription) {
        return null
      }

      const nextScheduledAtDate = moment(charge.scheduled_at).format(
        'MMM DD, YYYY'
      )

      const itemsText = pluralize(charge.line_items.length, 'item')
      const noun = charge.line_items.length > 1 ? 'are' : 'is'

      return `${
        charge.line_items.length
      } ${itemsText} ${noun} in this delivery on <b>${nextScheduledAtDate}</b> and will be skipped.`
    },
    [charge, subscription]
  )

  return (
    <Drawer
      {...rest}
      onClose={onExit}
      isLoading={isLoading}
      size="narrow"
      title="Skip next order"
      open={open}
      footer={
        <ModalBtns
          saveBtnDisabled={isLoading || isUpdating}
          tertiaryBtnText="Cancel"
          onClickTertiaryBtn={handleOnClose}
          saveBtnText={
            isUpdating ? 'Skipping next order...' : 'Skip next order'
          }
          onSave={onSave}
          saveBtnHtmlType="submit"
        />
      }
    >
      <div className="grui pt-12">
        <SanitizedHTML html={descriptionText} />

        {charge && (
          <div className="grui mt-12">
            {charge.line_items.map(item => (
              <div key={item.id} css={styles.item}>
                <img
                  src={item.images.small}
                  alt={item.title}
                  css={styles.img}
                />

                <div css={styles.textWrapper}>
                  <span css={styles.title}>{item.title}</span>
                  <span css={styles.shippingText}>
                    Ships every {subscription.order_interval_frequency}{' '}
                    {pluralize(subscription.order_interval_frequency, 'day')}
                  </span>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </Drawer>
  )
}

export default RechargeSkipNextOrder
