import { useEffect, useMemo, useRef, useState } from 'react'
import { v4 as uuidV4 } from 'uuid'

import TagsDropdown from 'subapps/settings/components/TagsDropdown'
import { styles } from './styles'

const TYPE_NAME = 'labels_by_id'

const CannedReplyAddTags = ({ trigger, getValues, setValue }) => {
  const valueRef = useRef([])
  const [selectedIds, setSelectedIds] = useState([])
  const { automatic_actions: automaticActions } = getValues()

  const tagsField = useMemo(
    () => automaticActions.find(a => a.type === TYPE_NAME),
    [automaticActions]
  )
  const index = useMemo(
    () => {
      if (tagsField) {
        return automaticActions.indexOf(tagsField)
      }

      return automaticActions.length
    },
    [automaticActions, tagsField]
  )

  useEffect(
    () => {
      const value = tagsField?.value?.split(',')

      if (value) {
        valueRef.current = value
        setSelectedIds(value)
      }
    },
    [tagsField]
  )

  const handleSetValue = tags => {
    valueRef.current = tags

    setValue(
      `automatic_actions.${index}`,
      {
        operator: null,
        param: null,
        type: TYPE_NAME,
        value: tags.join(','),
        key: uuidV4(),
      },
      {
        shouldDirty: true,
        shouldValidate: true,
      }
    )
    trigger(`automatic_actions.${index}`)
  }

  const onSelectedIdsChange = tags => {
    if (tags.length) {
      setSelectedIds(tags)
      return
    }

    if (selectedIds.length) {
      setSelectedIds([])
    }
  }

  return (
    <div css={styles.container}>
      <TagsDropdown
        fieldType="ids"
        onSelectedIdsChange={onSelectedIdsChange}
        selectedIds={selectedIds}
        value={valueRef.current}
        setValue={handleSetValue}
      />
    </div>
  )
}

export default CannedReplyAddTags
