import { combineReducers } from 'core/redux'

import articles from './reducers/articles'
import availability from './reducers/availability'
import categories from './reducers/categories'
import knowledgeBases from './reducers/knowledge_bases'
import tags from './reducers/tags'
import settings from './reducers/settings'
import themeBackgrounds from './reducers/theme_backgrounds'
import themes from './reducers/themes'

export default combineReducers({
  articles,
  availability,
  categories,
  knowledgeBases,
  tags,
  settings,
  themeBackgrounds,
  themes,
})
