import { useMemo } from 'react'
import Table from '@groovehq/internal-design-system/lib/components/Table/Table'

const makeFormatUser = hasEmail => {
  return ({ row: { original } }) => {
    const { id, name = '' } = original

    const trimmedName = name.trim()
    const data = {
      email: hasEmail ? original.email : '',
      value: trimmedName,
      avatar: original.avatar_url,
      userName: original.username,
    }
    return (
      <label htmlFor={id}>
        <Table.CellWithAvatar data={data} oneLine />
      </label>
    )
  }
}

const useColumns = hasEmail => {
  const formatUser = useMemo(
    () => {
      return makeFormatUser(hasEmail)
    },
    [hasEmail]
  )

  const columns = [
    {
      Header: app.t('Agents'),
      accessor: 'name',
      Cell: formatUser,
      disableSortBy: true,
    },
  ]

  return columns
}

export default useColumns
