import debug from 'util/debug'
import { REDUX_ACTION_LOGGING_ADD_REALTIME_LOG } from 'constants/performance_sim/event_action_keys'
import { logRealtimeAction } from 'util/performance_sim/actionsLoggerMiddleware'

const addRealtimeLog = event => {
  logRealtimeAction(event.data.performanceSim.data)
}

const onMessageReceivedHandler = event => {
  if (
    event &&
    event.data &&
    event.data.performanceSim &&
    event.data.performanceSim.action
  ) {
    const action = event.data.performanceSim.action
    if (action === REDUX_ACTION_LOGGING_ADD_REALTIME_LOG) {
      addRealtimeLog(event)
    } else {
      debug(`performancesim: ${action} unsupported`)
    }
  }
}

export const registerListeners = () => {
  // not using custom event as 'cos not async
  // listen for messages from performance sim to start logging redux/realtime actions
  // https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/dispatchEvent#Notes
  window.addEventListener('message', onMessageReceivedHandler)
}
