const mockStorage = () => {
  let storage = {}

  return {
    setItem: (key, value) => {
      storage[key] = value || ''
    },
    getItem: key => {
      return key in storage ? storage[key] : null
    },
    removeItem: key => {
      delete storage[key]
    },
    get length() {
      return Object.keys(storage).length
    },
    key: i => {
      const keys = Object.keys(storage)
      return keys[i] || null
    },
    keys: () => {
      return Object.keys(storage)
    },
    clear: () => {
      storage = {}
    },
    cleanupAppStorage: () => {
      const host = window.location.host
      if (host.match('^beta.groovehq.com') || host.match('^app.groovehq.com')) {
        storage = {}
      }
    },
    dump: () => {
      return storage
    },
  }
}

let storageDB = global.localStorage

// TODO (team): Make switching to test mode explicit.
try {
  if (process && process.env && process.env.NODE_ENV === 'test') {
    storageDB = mockStorage()
  }
} catch (e) {
  /* empty */
}

// LocalStorage wrapper API.
module.exports = {
  namespace: 'groove',
  isAvailable() {
    if (this.available === undefined) {
      this.available = this.checkAvailability()
      return this.available
    }
    return this.available
  },
  checkAvailability(options = {}) {
    const mod = 'localStorageCheck'
    try {
      storageDB.setItem(mod, mod)
      storageDB.removeItem(mod)
      return true
    } catch (e) {
      if (this.isQuotaExceeded(e) && !options.isRetry) {
        this.removeDrafts()
        return this.checkAvailability({ isRetry: true })
      }
      // eslint-disable-next-line no-console
      console.error('localStorage not available') // debugging for cross-browser
      return false
    }
  },
  clear({ except = false } = {}) {
    if (!this.isAvailable()) return null
    const exceptValues =
      except &&
      except.reduce((reduction, key) => {
        // eslint-disable-next-line no-param-reassign
        reduction[key] = module.exports.get(key)
        return reduction
      }, {})
    storageDB.clear()
    if (exceptValues) {
      Object.keys(exceptValues).forEach(key => {
        if (exceptValues[key] !== null && exceptValues[key] !== undefined) {
          module.exports.set(key, exceptValues[key])
        }
      })
    }
    return null
  },
  buildKey(key, options = {}) {
    if (options.ignoreNamespace) return key
    return `${this.namespace}-${key}`
  },
  get(key, options = {}) {
    if (!this.isAvailable()) return null
    const item = storageDB.getItem(this.buildKey(key, options))
    try {
      return item ? JSON.parse(item) : null
    } catch (e) {
      return item
    }
  },
  set(key, value, options = {}) {
    if (!this.isAvailable()) return null
    const stringified = JSON.stringify(value)
    try {
      storageDB.setItem(
        this.buildKey(key, options),
        options.asJson === false ? value : stringified
      )
    } catch (e) {
      if (this.isQuotaExceeded(e) && !options.isRetry) {
        this.removeDrafts()
        return this.set(key, value, { isRetry: true })
      }
      // eslint-disable-next-line no-console
      console.error(e)
    }
    return null
  },
  remove(key, options = {}) {
    if (!this.isAvailable()) return null
    storageDB.removeItem(this.buildKey(key, options))
    return null
  },
  cleanupAppStorage(options) {
    const host = window.location.host
    if (host.match('^beta.groovehq.com') || host.match('^app.groovehq.com')) {
      this.clear(options)
    }
  },
  isQuotaExceeded(e) {
    let quotaExceeded = false
    if (e) {
      if (e.code) {
        switch (e.code) {
          // Chrome
          case 22:
            quotaExceeded = true
            break
          case 1014:
            // Firefox
            if (e.name === 'NS_ERROR_DOM_QUOTA_REACHED') {
              quotaExceeded = true
            }
            break
          default:
            break
        }
      } else if (e.number === -2147024882) {
        // Internet Explorer 8
        quotaExceeded = true
      }
    }
    return quotaExceeded
  },
  // Kevin Rademan 2020-03-18
  // This is a dirty hack!
  // Long term drafts drafts should be allocated a storage limit and should ensure
  // that it never crosses that limit
  removeDrafts() {
    try {
      storageDB.removeItem(`${this.namespace}-ticketDrafts`)
      storageDB.removeItem(`${this.namespace}-ticketNoteDrafts`)
    } catch (e) {
      // Just supress any removal errors
    }
  },
  dump() {
    if (!this.isAvailable()) return null
    return Object.keys(localStorage).reduce((obj, key) => {
      // eslint-disable-next-line no-param-reassign
      obj[key] = this.get(key, { ignoreNamespace: true })
      return obj
    }, {})
  },
}
