export const summarizeCount = count => {
  let summary = count

  if (count >= 100 && count < 1000) {
    summary = '99+'
  } else if (count >= 1000) {
    summary = '1k+'
  }

  return summary
}
