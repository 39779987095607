import { button } from '@groovehq/internal-design-system/lib/styles/elements'
import {
  FEATURE_INBOX_MAX_CHANNELS,
  FEATURE_INBOX_MAX_MAILBOXES,
} from 'ducks/billing/featureTypes'
import {
  DRAWER_ID_FOLDERS_CHANNELS_EMAIL_FORWARD,
  DRAWER_ID_FOLDERS_CHANNELS_MEMBERS_EDIT,
} from 'ducks/drawers/ids'
import {
  DRAWER_TYPE_CHANNELS_EMAIL_FORWARD,
  DRAWER_TYPE_CHANNELS_MEMBERS_EDIT,
} from 'ducks/drawers/types'
import { INBOX } from 'ducks/billing/productTypes'

const getCommonMailboxChannelConfig = (oAuthErrors = {}) => {
  return {
    drawer: {
      id: DRAWER_ID_FOLDERS_CHANNELS_MEMBERS_EDIT,
      type: DRAWER_TYPE_CHANNELS_MEMBERS_EDIT,
      closeIgnoresStack: true,
    },
    drawerTitle: 'Overview',
    oAuthErrors: {
      ERR_CREATE_MAILBOX: `There was an error creating the ${app.t(
        'mailbox'
      )} in your account.`,
      ERR_CREATE_WIDGET:
        'There was an error creating the chat widget in your account.',
      ERR_USER_CLOSED_POPUP:
        'Oh no! Looks like you closed the authentication pop up. Please try again.',
      ALREADY_LINKED: `The address is already linked to a different ${app.t(
        'mailbox'
      )} in your Groove account.`,
      ERR_FETCH_OAUTH_URL:
        'An unknown error occurred. This could be due to network connectivity or an error from the authentication service.',
      DEFAULT:
        'An unknown error occurred. This could be due to network connectivity or an error from the authentication service.',
      ...oAuthErrors,
    },
    oAuthSuccess: 'Email address connected successfully.',
    product: INBOX,
    feature: FEATURE_INBOX_MAX_MAILBOXES,
  }
}

// NOTE: use useChannelDetailsConfig hook instead of direct import of this file
export default {
  google: getCommonMailboxChannelConfig({
    ALREADY_AUTHORIZED: (
      <>
        The Groove app has already been authorized on Gmail. Please remove
        it&nbsp;
        <a
          css={button.styles.link}
          target="_blank"
          rel="noopener noreferrer"
          href="https://security.google.com/settings/security/permissions"
        >
          here
        </a>&nbsp; before continuing.
      </>
    ),
  }),
  outlook: getCommonMailboxChannelConfig({
    ALREADY_AUTHORIZED: (
      <>
        The Groove app has already been authorized on Outlook. Please remove
        it&nbsp;
        <a
          css={button.styles.link}
          target="_blank"
          rel="noopener noreferrer"
          href="https://account.live.com/consent/Manage"
        >
          here
        </a>&nbsp; before continuing.
      </>
    ),
  }),
  office365: getCommonMailboxChannelConfig({
    ALREADY_AUTHORIZED: (
      <>
        The Groove app has already been authorized on Microsoft. Please remove
        it&nbsp;
        <a
          css={button.styles.link}
          target="_blank"
          rel="noopener noreferrer"
          href="https://account.live.com/consent/Manage"
        >
          here
        </a>&nbsp; before continuing.
      </>
    ),
  }),
  facebook: {
    drawer: {
      id: DRAWER_ID_FOLDERS_CHANNELS_MEMBERS_EDIT,
      type: DRAWER_TYPE_CHANNELS_MEMBERS_EDIT,
      closeIgnoresStack: true,
    },
    drawerTitle: `Add Messenger`,
    oAuthErrors: {
      ERR_CREATE_WIDGET:
        'There was an error creating the chat widget in your account.',
      ERR_VALIDATION_NAME_TAKEN: 'The selected channel name is already in use',
      MISSING_SCOPES:
        'Please allow all requested permissions to make sure this channel works properly',
      ERR_USER_CLOSED_POPUP:
        'Oh no! Looks like you closed the authentication pop up. Please try again.',
      ERR_USER_REJECTED:
        'Oh no! Looks like you canceled the authentication pop up. Please try again.',
      ERR_FETCH_OAUTH_URL:
        'An unknown error occurred. This could be due to network connectivity or an error from the authentication service.',
      MISSING_RESOURCES:
        'Please allow access to at least one page to make sure this channel works properly.',
      MISSING_SPECIFIC_RESOURCE:
        'Please also give permission for the page “{externalName}“.',
      DEFAULT:
        'An unknown error occurred. This could be due to network connectivity or an error from the authentication service.',
    },
    product: INBOX,
    feature: FEATURE_INBOX_MAX_CHANNELS,
  },
  instagram: {
    drawer: {
      id: DRAWER_ID_FOLDERS_CHANNELS_MEMBERS_EDIT,
      type: DRAWER_TYPE_CHANNELS_MEMBERS_EDIT,
      closeIgnoresStack: true,
    },
    drawerTitle: `Add Instagram`,
    oAuthErrors: {
      ERR_CREATE_WIDGET:
        'There was an error creating the chat widget in your account.',
      ERR_VALIDATION_NAME_TAKEN: 'The selected channel name is already in use',
      MISSING_SCOPES:
        'Please allow all requested permissions to make sure this channel works properly',
      ERR_USER_CLOSED_POPUP:
        'Oh no! Looks like you closed the authentication pop up. Please try again.',
      ERR_USER_REJECTED:
        'Oh no! Looks like you canceled the authentication pop up. Please try again.',
      ERR_FETCH_OAUTH_URL:
        'An unknown error occurred. This could be due to network connectivity or an error from the authentication service.',
      MISSING_RESOURCES:
        'Please allow access to at least one page to make sure this channel works properly.',
      MISSING_SPECIFIC_RESOURCE:
        'Please also give permission for the page “{externalName}“.',
      DEFAULT:
        'An unknown error occurred. This could be due to network connectivity or an error from the authentication service.',
    },
    product: INBOX,
    feature: FEATURE_INBOX_MAX_CHANNELS,
  },
  imap: getCommonMailboxChannelConfig({
    ERR_AUTH_IMAP:
      'An unknown error occurred. This could be due to network connectivity or an error from the authentication service. Please try again.',
    ERR_IMAP_CREDENTIALS:
      'There was an error connecting to your email server using the credentials provided. Please check your email server settings and try again.',
    ERR_IMAP_APP_PASSWORD: (
      <>
        There was an error connecting to your email server. Your regular&nbsp;
        password will fail. You will need to use a Google App password
        &nbsp;instead.&nbsp;
        <a
          css={button.styles.link}
          target="_blank"
          rel="noopener noreferrer"
          href="https://support.google.com/accounts/answer/185833?hl=en#zippy=%2Cwhy-you-may-need-an-app-password"
        >
          Where do I find my Google app password...
        </a>
      </>
    ),
  }),
  forwarding: {
    drawer: {
      id: DRAWER_ID_FOLDERS_CHANNELS_EMAIL_FORWARD,
      type: DRAWER_TYPE_CHANNELS_EMAIL_FORWARD,
      closeIgnoresStack: true,
    },
    product: INBOX,
    feature: FEATURE_INBOX_MAX_MAILBOXES,
  },
}
