import React from 'react'
import { styles } from './styles'

const Header = ({ children, icon: Icon, className }) => {
  return (
    <div css={styles.header} className={className}>
      {Icon && <Icon />}
      {children}
    </div>
  )
}

export default Header
