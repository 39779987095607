/* eslint-disable no-underscore-dangle */
/** ***************************
 * !!!!!! IMPORTANT !!!!!!!
 *
 * The code below is purposefully not using imports and
 * variables to ensure that webpack code splitting will
 * correctly divide our subapps into chunks
 */

// Define your subapp in the subapp registry.
import {
  chatPageHandler,
  socialPageHandler,
} from 'subapps/chat/routes/chatPageHandler'

export const subappRegistry = {
  chat: {
    path: 'subapps/chat',
    loader: import('subapps/chat'),
  },
  kb: {
    path: 'subapps/kb',
    loader: import('subapps/kb'),
  },
  settings: {
    path: 'subapps/settings',
    loader: import('subapps/settings'),
  },
  onboarding: {
    path: 'subapps/onboarding',
    loader: import('subapps/onboarding'),
  },
  demo: {
    path: 'subapps/demo',
    loader: import('subapps/demo'),
  },
  crm: {
    path: 'subapps/crm',
    loader: import('subapps/crm'),
  },
  maintenance: {
    path: 'subapps/maintenance',
    loader: import('subapps/maintenance'),
  },
}

// Register the base path for the subapp. Its important to add the * postfix
// in order for dynamically loaded routes to work correctly when you refresh
// the page. The routes will automatically be registed by the main application
export const routes = {
  'demo/INDEX_PAGE': {
    path: '/demo/:subRoute*',
  },
  'kb/LANDING_PAGE': {
    path: '/kb/:subRoute*',
  },
  'settings/SETTINGS_WIDGET_MANAGEMENT_PAGE': {
    path: '/settings/integrations/widgets/:subRoute*',
  },
  'crm/INDEX_PAGE': {
    path: '/crm/:subRoute*',
  },
  'chat/CHAT_PAGE': {
    path: '/chats/:subRoute*',
  },
  'chat/SOCIAL_PAGE': {
    path: '/social/:subRoute*',
  },
  'maintenance/MAINTENANCE_PAGE': {
    path: '/maintenance/:subRoute*',
  },
}

// Define the page Reducer for your subapp apps entry point. The entry point
// page reducers will automatically be loaded by the parent application
export const pageReducers = {
  'demo/INDEX_PAGE': () => ({
    componentName: 'IndexPage',
    subapp: 'demo',
  }),
  'kb/LANDING_PAGE': () => ({
    componentName: 'LandingPage',
    subapp: 'kb',
  }),
  'settings/SETTINGS_WIDGET_MANAGEMENT_PAGE': () => ({
    componentName: 'WidgetManagementPage',
    subapp: 'settings',
  }),
  'crm/INDEX_PAGE': () => ({
    componentName: 'IndexPage',
    subapp: 'crm',
  }),
  'chat/CHAT_PAGE': chatPageHandler,
  'chat/SOCIAL_PAGE': socialPageHandler,
  'maintenance/MAINTENANCE_PAGE': () => ({
    componentName: '',
    subapp: 'maintenance',
  }),
}
