import { useCallback } from 'react'
import { useController } from 'react-hook-form'
import Radio from '@groovehq/internal-design-system/lib/components/Radio/Radio'

const MailboxSpecificityToggle = ({ name, control }) => {
  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
  })

  const handleChange = useCallback(
    e => {
      onChange(e.target.value === '1')
    },
    [onChange]
  )

  return (
    <>
      <Radio
        id="allMailboxesOption"
        name={name}
        value="1"
        checked={value}
        onChange={handleChange}
        className="grui mb-4"
      >
        All {app.t('mailboxes')}
      </Radio>
      <br />
      <Radio
        id="specificMailboxesOption"
        name={name}
        value="0"
        checked={!value}
        onChange={handleChange}
      >
        Specific {app.t('mailboxes')}
      </Radio>
    </>
  )
}

export default MailboxSpecificityToggle
