import React, { useMemo } from 'react'
import Avatar from '@groovehq/internal-design-system/lib/components/Avatar/Avatar'
import { styles } from './styles'

const CURRENT_USER_ID = '-1'
const ASSIGNED_ID = '0'

const Agent = ({ avatarUrl, username, fullName, id, className }) => {
  const isCustomText = useMemo(
    () => {
      if ([CURRENT_USER_ID, ASSIGNED_ID].includes(id)) {
        return true
      }

      return false
    },
    [id]
  )

  const customText = useMemo(
    () => {
      if (id === CURRENT_USER_ID) return 'Current user'
      if (id === ASSIGNED_ID) return `Assigned ${app.t('agent')}`
      return ''
    },
    [id]
  )

  return (
    <div className={className} data-teammates-dropdown-agent>
      {isCustomText ? (
        customText
      ) : (
        <>
          <Avatar
            avatar={avatarUrl}
            userName={fullName}
            size="small"
            css={styles.avatar}
            className="grui mr-3"
          />
          @{username}
        </>
      )}
    </div>
  )
}

export default Agent
