import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useDataTable } from 'ducks/tables/hooks'
import Table from '@groovehq/internal-design-system/lib/components/Table/Table'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import {
  SETTINGS_FOLDER_TABLE_ID,
  SETTINGS_FOLDER_TABLE_ID_INACTIVE,
} from 'ducks/tables/ids'
import {
  doFetchFoldersInMemory,
  doUpdateSingleFolder,
} from 'ducks/folders/operations/folders'
import { useDrawer } from 'ducks/drawers/hooks'
import { buildDrawerQueryParam } from 'ducks/drawers/util'
import {
  DRAWER_TYPE_FOLDERS_DELETE,
  DRAWER_TYPE_FOLDERS_UPDATE,
} from 'ducks/drawers/types'
import { doFetchMailboxesAndBuildInboxMenu } from 'ducks/mailboxes/actions'
import PlanLimitationCard from 'subapps/settings/components/PlanLimitationCard'
import { useFeature } from 'ducks/billing/hooks'
import { FEATURE_INBOX_MAX_FOLDERS } from 'ducks/billing/featureTypes'
import { selectFetchAppBootstrapRequest } from 'ducks/bootstrap/selectors/selectFetchAppBootstrapRequest'
import Loading from 'core/subapps/Loading'
import useColumns from './useColumns'
import { styles } from './styles'

const FolderDataTable = ({ state, compact }) => {
  const dispatch = useDispatch()
  const disableReorder = state === 'INACTIVE'
  const { canUseFeature, featureLimit: folderLimit } = useFeature(
    FEATURE_INBOX_MAX_FOLDERS
  )
  const featureStatusLoaded = useSelector(selectFetchAppBootstrapRequest)
    ?.loaded
  const showPlanLimitation = !canUseFeature

  const {
    pagination: {
      currentDenormalizedEntities: folders,
      gotoPage,
      changePageSize,
    },
    pagination,
    filtering: { changeFilter },
    sorting: { changeSort },
    query: { name },
    sorting,
    isLoading,
    shouldLoad,
    isError,
    queryId,
  } = useDataTable(
    disableReorder
      ? SETTINGS_FOLDER_TABLE_ID_INACTIVE
      : SETTINGS_FOLDER_TABLE_ID,
    'folder',
    doFetchFoldersInMemory,
    {
      pagination: {
        defaultPageSize: 9999,
        defaultCursor: 1,
        showSizeChanger: false,
        filterDelay: 1000,
        parseToIntKeys: ['cursor'],
        defaultOrderBy: disableReorder ? 'name_ASC' : null,
      },
      filtering: {
        baseFilters: {
          state,
        },
      },
    }
  )

  const columns = useColumns({ compact, disableReorder })

  const handleOnTableStateChange = useCallback(
    (newState, action = {}) => {
      const { type } = action || {}
      if (type === 'gotoPage') {
        const { pageIndex } = action
        gotoPage(pageIndex + 1)
      } else if (type === 'setPageSize') {
        const { pageSize } = action
        changePageSize(pageSize)
      } else if (type === 'toggleSortBy' || type === 'setSortBy') {
        const { id, desc } = newState.sortBy[0] || {}
        changeSort(id ? `${id}_${desc ? 'DESC' : 'ASC'}` : null)
      } else if (type === 'setGlobalFilter') {
        const { filterValue } = action
        // When setting filter, debounce for 1 second, but when removing a filter
        // trigger that immediately
        changeFilter('name', filterValue, { resetPage: true, persist: true })
      }
    },
    [changeSort, changeFilter, gotoPage, changePageSize]
  )

  // We intentially take the initial value and never change it because we dont actually want
  // the default value to ever change
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultGlobalFilter = useMemo(() => name || null)

  const { drawerId: deleteDrawerId, openDrawer: openDeleteDrawer } = useDrawer(
    DRAWER_TYPE_FOLDERS_DELETE
  )

  const { openDrawer: openCreateEditDrawer } = useDrawer(
    DRAWER_TYPE_FOLDERS_UPDATE
  )

  const handleCreate = useCallback(
    () => {
      openCreateEditDrawer('new')
    },
    [openCreateEditDrawer]
  )

  const handleDelete = useCallback(
    id => {
      openDeleteDrawer(id, {
        query: {
          ...buildDrawerQueryParam(deleteDrawerId, 'drawerDeleteMode', 'ids'),
        },
      })
    },
    [openDeleteDrawer, deleteDrawerId]
  )

  const handleEdit = useCallback(
    id => {
      openCreateEditDrawer(id)
    },
    [openCreateEditDrawer]
  )

  const emptyContent = useMemo(
    () => {
      if (state) {
        return <Table.Empty title={`There are no ${app.t('folders')}`} />
      }
      return (
        <Table.Empty
          title={`There are no ${app.t('folders')}`}
          subtitle={`You haven't created any ${app.t(
            'folders'
          )} on your account yet.`}
        >
          <Button onClick={handleCreate}>Create new {app.t('folder')}</Button>
        </Table.Empty>
      )
    },
    [state, handleCreate]
  )

  const handleOnReorder = useCallback(
    (e, item) => {
      const { orderedIds, newIndex } = e
      dispatch(
        doUpdateSingleFolder(
          { ...item, position: newIndex },
          {
            updatePosition: true,
            orderedIds,
            queryId,
          }
        )
      )
    },
    [dispatch, queryId]
  )

  useEffect(() => {
    return () => {
      dispatch(doFetchMailboxesAndBuildInboxMenu())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!featureStatusLoaded) {
    return <Loading />
  }

  return (
    <>
      {showPlanLimitation && (
        <PlanLimitationCard
          title={`Need to create more ${app.t('Folders')}?`}
          className="grui mb-9 mt-3"
          trackingFeatureType={FEATURE_INBOX_MAX_FOLDERS}
        >
          You’ve reached your limit of {folderLimit} {app.t('Folders')} on your
          account.
          <br />
          To add more, please upgrade your account.
        </PlanLimitationCard>
      )}
      <Table
        separate
        columns={columns}
        data={folders}
        loading={shouldLoad || !!isLoading}
        css={[styles.table, compact && styles.compactTable]}
        draggable
        disableReorder={disableReorder}
        hasSearch
        searchPlaceholder={app.t('folders')}
        updateData={handleEdit}
        deleteData={handleDelete}
        onEdit={handleEdit}
        sorting={sorting}
        showPagination
        pagination={pagination}
        dataType="folders"
        emptyContent={emptyContent}
        hasError={isError}
        emptyHintVisible={false}
        onTableStateChange={handleOnTableStateChange}
        defaultGlobalFilter={defaultGlobalFilter}
        onReorder={handleOnReorder}
      />
    </>
  )
}

FolderDataTable.displayName = 'FolderDataTable'

export default FolderDataTable
