import React from 'react'
import { AdminAccessDrawer } from 'subapps/settings/components/drawers/NoAccess'
import { UsersDataTableWithMenu } from 'subapps/settings/components/UsersDataTable'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'

const UsersOverview = ({ open, onClose, onExit }) => {
  return (
    <AdminAccessDrawer
      title={app.t('Agents')}
      open={open}
      onClose={onExit}
      size="wideXL"
      footer={
        <ModalBtns tertiaryBtnText="Close" onClickTertiaryBtn={onClose} />
      }
    >
      <UsersDataTableWithMenu />
    </AdminAccessDrawer>
  )
}

export default UsersOverview
