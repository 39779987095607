import React, { useCallback, useMemo } from 'react'
import { useController } from 'react-hook-form'
import Dropdown from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import DropdownMenu from 'subapps/settings/components/DropdownMenu'
import config from 'config'
import { styles } from './styles'

const { Button } = Dropdown

function ImportPeriodDropdown({ control, name }) {
  const IMPORT_PERIODS = useMemo(() => {
    let periods = [
      { name: 'No history', value: null },
      { name: '1 Day', value: 1 },
      { name: '1 Month', value: 31 },
      { name: '3 Months', value: 90 },
      { name: '6 Months', value: 183 },
      { name: '9 Months', value: 274 },
      { name: '1 Year', value: 365 },
    ]
    if (!config.isDevelopment) {
      periods = periods.filter(p => p.value !== 1)
    }
    return periods
  }, [])

  const {
    field: { onChange, value, onBlur, ref },
  } = useController({
    name,
    control,
  })

  const handleSelectPeriod = useCallback(
    period => {
      onChange(period)
    },
    [onChange]
  )

  const selectedName = useMemo(
    () => {
      return (
        IMPORT_PERIODS.find(p => p.value === value)?.name ||
        IMPORT_PERIODS[0].name
      )
    },
    [value, IMPORT_PERIODS]
  )

  return (
    <Dropdown
      overlay={<DropdownMenu data={IMPORT_PERIODS} />}
      onSelect={handleSelectPeriod}
      selectedKey={value}
      hasMinWidth
    >
      <Button css={styles.dropdown} ref={ref} onBlur={onBlur}>
        {selectedName || ''}
      </Button>
    </Dropdown>
  )
}

export default ImportPeriodDropdown
