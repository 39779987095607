import * as types from 'subapps/kb/actions'

const defaultState = {
  articleTags: [],
}

const reducers = {}

reducers[types.FETCH_ARTICLE_TAGS_SUCCESS] = (state, action) => {
  return {
    ...state,
    articleTags: action.data,
  }
}

reducers[types.ADD_ARTICLE_TAG] = (state, action) => {
  return {
    ...state,
    articleTags: [
      {
        id: action.data,
        name: action.data,
        new: true, // not persisted, so track it down
      },
      ...state.articleTags,
    ],
  }
}

export default function reducer(state = defaultState, action) {
  const handler = reducers[action.type]
  if (handler) return handler(state, action)
  return state
}
