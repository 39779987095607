import moment from 'moment'
import dinero from 'dinero.js'

export const clamp = (min, max, n) => Math.min(Math.max(min, n), max)

export const generateId = () => Math.floor(Math.random() * (1000000 - 0))

export const numberToSize = (number, digits = 1) => {
  const si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ]

  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/

  let i
  for (i = si.length - 1; i > 0; i -= 1) {
    if (number >= si[i].value) {
      break
    }
  }
  return (number / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol
}

export function formatNumber(t) {
  return numberToSize(Math.round(t * 10) / 10, 1)
}

export function formatNumberExact(number) {
  const rounded = Math.round(number * 100) / 100
  const parts = rounded.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}

export function formatNumberWithSeparators(number) {
  return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function formatDurationAsTime(t) {
  return moment.duration(t).format('hh:mm:ss')
}

export function formatDuration(t) {
  return moment
    .duration(t)
    .format(
      'y [years], M [months], d [days], h [hours], m [minutes], s [seconds]',
      {
        largest: 1,
        trim: false,
      }
    )
}

const toCents = (amount, currency) => {
  const one = dinero({ amount: 1, currency })
  return Math.trunc(amount * 10 ** one.getPrecision())
}

export const getFormattedPrice = (amount, currency) => {
  const price = Number.isInteger(amount) ? amount : toCents(amount)
  return dinero({ amount: price, currency }).toFormat()
}

export function getRandomNumber(min, max, round = true) {
  const randomNumber = min + Math.random() * (max - min)
  if (!round) return randomNumber
  return Math.round(randomNumber)
}

export const castToNumber = numberOrUndefined => {
  return numberOrUndefined === undefined ? 0 : numberOrUndefined * 1
}

export const percentageIncrease = (originalVal, newVal) => {
  if (
    [undefined, null].includes(originalVal) ||
    [undefined, null].includes(newVal)
  ) {
    return 0
  }

  const difference = newVal - originalVal
  return difference / originalVal * 100
}

export const percentageDecrease = (originalVal, newVal) => {
  if (
    [undefined, null].includes(originalVal) ||
    [undefined, null].includes(newVal)
  ) {
    return 0
  }

  const difference = originalVal - newVal
  return difference / originalVal * 100
}
