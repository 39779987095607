import React, { useCallback, useMemo, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useFormState } from 'react-hook-form'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'

import { AdminAccessDrawer } from 'subapps/settings/components/drawers/NoAccess'

import { useSlackApp } from 'ducks/integrations/slack/hooks'
import { useDrawer } from 'ducks/drawers/hooks'
import { DRAWER_TYPE_INTEGRATION_UNINSTALL } from 'ducks/drawers/types'
import { useConfirmHoldsCallback } from 'util/dirtyHolds'

import Form, { FORM_ID } from './Form'
import ChannelsTable from './ChannelsTable'

const Actions = ({ formId, control, onUninstall }) => {
  const { isValid, isDirty, isSubmitting } = useFormState({ control })

  return (
    <ModalBtns
      saveBtnText="Save changes"
      saveBtnHtmlType="submit"
      saveBtnDisabled={!isValid || !isDirty || isSubmitting}
      saveBtnForm={formId}
      warningBtnText="Uninstall app"
      onClickWarningBtn={onUninstall}
      warningButtonType="warningOutline"
    />
  )
}

const SlackConfigureDrawer = ({
  drawerResourceId: providerId,
  open,
  onClose,
  onExit,
  drawerId,
  queryId,
  previousDrawer,
  drawerPreferCachedSlackApp,
  ...rest
}) => {
  const dispatch = useDispatch()
  const handleOnClose = useConfirmHoldsCallback(FORM_ID, onClose, [onClose])
  const handleOnExit = useConfirmHoldsCallback(null, onExit, [dispatch, onExit])
  const footerRef = useRef(null)

  const { openDrawer: openUninstallDrawer } = useDrawer(
    DRAWER_TYPE_INTEGRATION_UNINSTALL
  )

  const handleUninstall = useCallback(
    () => {
      openUninstallDrawer(providerId)
    },
    [openUninstallDrawer, providerId]
  )

  const actionsComponentProps = useMemo(
    () => {
      return { formId: FORM_ID, onUninstall: handleUninstall }
    },
    [handleUninstall]
  )

  const preferCached = drawerPreferCachedSlackApp === '1'
  const {
    slackApp,
    profiles,
    isLoading,
    isLoaded,
    hasError,
    isMissing,
  } = useSlackApp({ preferCached })

  return (
    <AdminAccessDrawer
      {...rest}
      onClose={handleOnExit}
      isLoading={isLoading}
      isError={hasError}
      isNoResultFound={isMissing}
      size="wide"
      title="Slack Settings"
      open={open}
      footer={<div ref={footerRef} />}
    >
      {isLoaded && (
        <>
          <div css={text.styles.fontMedium} className="grui mt-14 mb-3">
            Channels receiving notifications from Groove
          </div>
          <ChannelsTable channels={slackApp?.channels} />
          <div css={text.styles.fontMedium} className="grui mt-14 mb-3">
            Map {app.t('agents')} to Slack users
          </div>
          <Form
            formId={FORM_ID}
            profiles={profiles}
            actionsPortalRef={footerRef}
            actionsComponent={Actions}
            actionsComponentAdditionalProps={actionsComponentProps}
            onClose={handleOnClose}
          />
        </>
      )}
    </AdminAccessDrawer>
  )
}

export default SlackConfigureDrawer
