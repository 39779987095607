import { useMemo } from 'react'
import CommonConfigureDrawer from 'subapps/settings/components/drawers/integrations/common/Configure'
import GeneralConfigure from 'subapps/settings/components/drawers/integrations/providers/recharge/Configure/General'
import AdvancedConfigure from 'subapps/settings/components/drawers/integrations/common/Configure/Advanced'

const RechargeConfigure = props => {
  const tabsData = useMemo(
    () => {
      return [
        {
          key: 'general',
          tabName: 'General',
          component: p => (
            <GeneralConfigure
              {...p}
              internalAction={props.drawerInternalAction}
            />
          ),
        },
        {
          key: 'advanced',
          tabName: 'Advanced',
          component: p => (
            <AdvancedConfigure {...p} integrationName="Recharge" />
          ),
        },
      ]
    },
    [props.drawerInternalAction]
  )
  const drawerTabId = props.drawerTabId || tabsData[0].key

  return (
    <CommonConfigureDrawer
      {...props}
      drawerTabId={drawerTabId}
      tabsData={tabsData}
      title="Configure Recharge"
    />
  )
}

export default RechargeConfigure
