import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { string, bool } from 'prop-types'
import { doUpdateSlackAppV0 } from 'ducks/integrations/slack/operations'
import { emptyArr } from 'util/arrays'
import { convertGidToRaw } from 'util/scatterSwap'
import EntityDeleteDrawer from '../../../../EntityDelete'
import ChannelDeleteContent from './Content'

const ChannelDeleteDrawer = props => {
  const { deleteBtnText, open, drawerResourceId, onClose, onRemove } = props
  const dispatch = useDispatch()

  const ids = useMemo(
    () => {
      return drawerResourceId ? drawerResourceId.split(',') : emptyArr
    },
    [drawerResourceId]
  )

  const handleOnRemove = useCallback(
    () => {
      const payload = {
        channels: ids.map(gid => {
          return { id: convertGidToRaw(gid), _destroy: true }
        }),
      }
      const response = dispatch(doUpdateSlackAppV0(payload))
      if (onRemove) onRemove()
      return response
    },
    [dispatch, ids, onRemove]
  )

  const title = ids.length > 1 ? 'Delete channels' : 'Delete channel'

  return (
    <EntityDeleteDrawer
      {...props}
      onRemove={handleOnRemove}
      onClose={onClose}
      tertiaryBtnText="Cancel"
      onCancel={onClose}
      title={title}
      deleteBtnText={deleteBtnText}
      open={open}
    >
      <ChannelDeleteContent ids={ids} />
    </EntityDeleteDrawer>
  )
}

ChannelDeleteDrawer.propTypes = {
  deleteBtnText: string,
  open: bool.isRequired,
  drawerResourceId: string,
}

ChannelDeleteDrawer.defaultProps = {
  deleteBtnText: 'Delete',
  drawerResourceId: '',
}

export default ChannelDeleteDrawer
