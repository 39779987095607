import logo from 'assets/integrations/github/icon-256.png'
import actionsScreenShot from 'assets/integrations/github/screenshot-actions.jpg'
import linkScreenShot from 'assets/integrations/github/screenshot-link.jpg'
import sidebarScreenShot from 'assets/integrations/github/screenshot-sidebar.jpg'

import IntegrationsModal from '../../shared/Modal'
import GitHubSettingsForm from './GitHubSettingsForm'
import GitHubInstallDescription from './GitHubInstallDescription'

export default function IntegrationsGitHubModal(props) {
  return (
    <IntegrationsModal
      {...props}
      InstallDescription={GitHubInstallDescription}
      installPath={'/auth/github/install'}
      logo={logo}
      provider="github"
      providerStylized="GitHub"
      tagLine="Create and link GitHub issues right from your conversations, and get updated on any changes."
      screenShots={{
        'A screen shot of the GitHub actions in Groove': actionsScreenShot,
        'A screen shot of a linking GitHub issues in Groove': linkScreenShot,
        'A screen shot of GitHub issues in Groove': sidebarScreenShot,
      }}
      SettingsForm={GitHubSettingsForm}
    />
  )
}
