import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { redirect } from 'redux-first-router'
import { capture, stopRecording } from 'ducks/tracking/actions'
import Field from '@groovehq/internal-design-system/lib/components/Field/Field'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import Drawer from '@groovehq/internal-design-system/lib/components/Drawer/UnmanagedDrawer'
import AnimatedEllipsis from '@groovehq/internal-design-system/lib/components/AnimatedEllipsis/AnimatedEllipsis'
import { useDispatch, useSelector } from 'react-redux'
import { FormProvider, useForm, useController } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAi } from 'ducks/ai/hooks'
import * as yup from 'yup'
import { styles as fieldStyles } from '@groovehq/internal-design-system/lib/components/Field/Field.styles'
import {
  selectIsLoadingCannedReplyCategories,
  selectHasErrorCannedReplyCategories,
  selectIsLoadingCannedReplyVariables,
  selectHasLoadedCannedReplyVariables,
  selectHasErrorCannedReplyVariables,
  selectIsLoadingCannedReplyTemplate,
  selectHasLoadedCannedReplyTemplate,
  selectHasErrorCannedReplyTemplate,
  selectCurrentCannedReplyCategories,
  selectCurrentCannedReplyVariables,
  selectPendingCannedReplyById,
  selectCurrentCannedReplyCategoryById,
} from 'ducks/cannedReplies/selectors'

import {
  INSTANT_REPLY_TYPE,
  TEXT_REFINER_SERVICE,
  ACTION_RPC_METHOD_MAP,
  INSTANT_REPLY_CAST_ACTION,
} from 'ducks/ai/types'

import {
  doFetchCannedReplyCategoriesV1,
  doFetchCannedReplyVariables,
  doFetchCannedReplyByIdV0Settings,
  doSaveCannedReplyTemplate,
  doSaveCannedReplyTemplateDraft,
  doClearCannedReplyTemplateDraft,
} from 'ducks/cannedReplies/operations'
import { isEmpty, omit } from 'util/objects'
import { buildId } from 'util/globalId'
import { useRhfDirtyHold, useConfirmHoldsCallback } from 'util/dirtyHolds'
import { DRAWER_TYPE_CANNED_REPLY_CATEGORIES_UPDATE } from 'ducks/drawers/types'
import { useDrawer } from 'ducks/drawers/hooks'
import { SETTINGS_CANNED_REPLY_TABLE_ID } from 'ducks/tables/ids'
import { selectQueryParamsQueryId } from 'selectors/location'
import MessageTemplateEditor from 'subapps/settings/components/MessageTemplateEditor'
import CannedReplyAutomaticActions from 'subapps/settings/components/CannedReplyAutomaticActions'
import CannedReplyAddTags from 'subapps/settings/components/CannedReplyAddTags'
import { useFeature } from 'ducks/billing/hooks'
import {
  FEATURE_INBOX_CANNED_REPLIES_TIER_1,
  FEATURE_INBOX_MAX_CANNED_REPLIES,
} from 'ducks/billing/featureTypes'
import { SETTINGS_CANNEDREPLIES_PAGE } from 'subapps/settings/types'
import { DrawerContext } from 'subapps/settings/components/drawers/contexts'
import CategorySelection from '../../CannedReplyCategorySelection'
import {
  editorWrapper,
  dropdownContainer,
  topFields,
  fullWidth,
  copy,
  checkboxList,
  selection,
} from './styles'
import MailboxSelection from '../../MailboxSelection'
import PlanLimitationForCannedReply from './PlanLimitationForCannedReply'

// keys should preferably match the object being stored in the entity store
// that way for updates, you can just spread the entity in reset() and not have to set each property individually
const FORM_SCHEMA = yup.object().shape({
  id: yup.string().nullable(),
  name: yup.string().required('Template name is required'),
  subject: yup.string().nullable(),
  body: yup.string().required('Reply body is required'),
  category: yup.string().nullable(), // it's actually the id that is stored here
  mailboxIds: yup.array().of(yup.string()),
  channelVisibility: yup.string(),
  // 1. prepend form schema that are not part of the entity with 'form'
  // 2. don't forget to remove it from object being sent onsubmit
  // 3. used to store the selected category dropdown
  formCategory: yup.object().shape({
    id: yup.string().nullable(),
    name: yup.string().nullable(),
  }),
  attachments: yup.array().of(yup.string()),
  formPendingUploads: yup.array(yup.object()),
})

const noCategorySelectOption = { id: null, name: 'Select category' }

const generateDefaultCategoryValues = category => {
  return {
    category: category?.id,
    formCategory: category?.id
      ? {
          id: category?.id,
          name: category?.name,
        }
      : noCategorySelectOption,
  }
}

const generateDefaultValues = (replyFields, category) => {
  return {
    // defaultValue has to match yup form schema
    id: replyFields?.id || null,
    name: replyFields?.name || '',
    subject: replyFields?.subject || '',
    body: replyFields?.body || '',
    attachments: replyFields?.attachments || [],
    formPendingUploads: replyFields?.formPendingUploads || [],
    automatic_actions: replyFields?.automatic_actions || [],
    mailboxIds: replyFields?.mailbox_ids || [],
    channelVisibility: replyFields?.mailbox_ids?.length ? 'selected' : 'all',
    ...generateDefaultCategoryValues(category),
  }
}

const formEmptyState = generateDefaultValues()

function getMaxHeight(numberOfAttachments = 0) {
  // 477 represents the height of everything that is not the editor
  let spacedHeight = window.innerHeight - 656

  if (numberOfAttachments > 0) {
    // The attachment header and margin
    spacedHeight -= 20
    // Each attachment line item
    spacedHeight -= numberOfAttachments * 48
  }
  return spacedHeight < 100 ? 100 : spacedHeight
}

const errorsData = {
  title: {
    key: 'name',
    message: 'This name has already been taken in this category',
  },
}

export default function CannedReplyCreateEditDrawer({
  drawerResourceId: cannedReplyId,
  drawerSetCategoryId,
  open,
  onClose,
  onExit,
  queryId,
  drawerId,
  aiProps,
  ...rest
}) {
  const {
    requestAndWait,
    abortResult,
    hidePrompt,
    clearState,
    cancelRequest,
    requestState: {
      response: AiResponse,
      loading: AiLoading,
      loaded: AiLoaded,
      showPrompt: AiShowPrompt,
      error: AiError,
    },
  } = useAi(INSTANT_REPLY_TYPE)
  const dispatch = useDispatch()
  const isAiUpdate = cannedReplyId === 'newAi'
  const [isSaving, setIsSaving] = useState(null)
  const [closing, setClosing] = useState(false)
  const [editorDisabled, setEditorDisabled] = useState(false)

  const isUpdate = !!cannedReplyId && cannedReplyId !== 'new' && !isAiUpdate

  // not needed for creates but cannot have conditional hooks
  // call anyway, it'll just return null
  const cannedReplyToUpdate = useSelector(state =>
    selectPendingCannedReplyById(state, cannedReplyId)
  )
  const { canUseFeature: canUseFeatureCannedReplies } = useFeature(
    FEATURE_INBOX_MAX_CANNED_REPLIES
  )
  const showPlanLimitation = !canUseFeatureCannedReplies
  const { canUseFeature: canUseFeatureCannedReplyActions } = useFeature(
    FEATURE_INBOX_CANNED_REPLIES_TIER_1
  )
  const showPlanLimiationForActions = !canUseFeatureCannedReplyActions

  const cannedReplyDraftDirtyFieldsExist = !isEmpty(
    cannedReplyToUpdate?.dirtyFields || {}
  )

  const generateAIPayload = useCallback(
    async () => {
      if (closing) return

      await requestAndWait({
        service: TEXT_REFINER_SERVICE,
        method: ACTION_RPC_METHOD_MAP[INSTANT_REPLY_CAST_ACTION],
        params: { text: aiProps.messageBody },
      })
    },
    [aiProps, requestAndWait, closing]
  )

  useEffect(
    () => {
      // need to check drawer open state before fetch dispatches
      // otherwise the dispatch methods in useEffect below will run immediately
      // after this Drawer component is instantiated via `useDrawer` (before openDrawer is even called)
      // Which is unnecessary double dispatch triggering

      // Won't refetch the canned reply if dirtyFields exists, which means
      // we go to create a new category from this component
      if (!open || !!cannedReplyDraftDirtyFieldsExist) return

      dispatch(
        doFetchCannedReplyCategoriesV1({
          skipLoaded: false,
        })
      )

      dispatch(doFetchCannedReplyVariables())

      if (isUpdate) {
        // save the response from the API in 'pending' store, not 'current' (because it'll immediately be used in this edit screen)
        dispatch(
          doFetchCannedReplyByIdV0Settings(
            buildId('CannedReply', cannedReplyId),
            { targetStore: 'pending' }
          )
        )
      }

      if (isAiUpdate && AiResponse === null && AiError === false) {
        generateAIPayload()
      }
    },
    [
      open,
      cannedReplyDraftDirtyFieldsExist,
      dispatch,
      cannedReplyId,
      isUpdate,
      isAiUpdate,
      generateAIPayload,
      AiError,
      AiResponse,
    ]
  )

  const categories = useSelector(selectCurrentCannedReplyCategories)
  const variables = useSelector(selectCurrentCannedReplyVariables)

  const [maxHeight, setMaxHeight] = useState(
    getMaxHeight(cannedReplyToUpdate?.attachments?.length)
  )

  // not needed for creates but cannot have conditional hooks
  // call anyway, it'll just return null
  // update: can also be used to load a category created by a previous drawer: drawerSetCategoryId
  const cannedReplyToUpdateCategory = useSelector(state =>
    selectCurrentCannedReplyCategoryById(
      state,
      drawerSetCategoryId || cannedReplyToUpdate?.category
    )
  )

  const originalCategory = useSelector(state =>
    selectCurrentCannedReplyCategoryById(state, cannedReplyToUpdate?.category)
  )

  const isLoadingCategories = useSelector(selectIsLoadingCannedReplyCategories)
  const hasErrorCategories = useSelector(selectHasErrorCannedReplyCategories)
  const isLoadingVariables = useSelector(selectIsLoadingCannedReplyVariables)
  const hasLoadedVariables = useSelector(selectHasLoadedCannedReplyVariables)
  const hasErrorVariables = useSelector(selectHasErrorCannedReplyVariables)
  const isLoadingCannedReplyForUpdate = useSelector(
    selectIsLoadingCannedReplyTemplate
  )
  const hasLoadedCannedReplyForUpdate = useSelector(
    selectHasLoadedCannedReplyTemplate
  )
  const hasErrorCannedReplyForUpdate = useSelector(
    selectHasErrorCannedReplyTemplate
  )
  const isLoadingCannedReply = isUpdate ? isLoadingCannedReplyForUpdate : false
  const hasLoadedCannedReply = isUpdate ? hasLoadedCannedReplyForUpdate : true
  const hasErrorCannedReply = isUpdate ? hasErrorCannedReplyForUpdate : false

  const isLoading = useMemo(
    () => {
      if (isAiUpdate) {
        return false
      }

      return isLoadingCategories || isLoadingVariables || isLoadingCannedReply
    },
    [isAiUpdate, isLoadingCategories, isLoadingVariables, isLoadingCannedReply]
  )

  const hasError =
    hasErrorCategories || hasErrorVariables || hasErrorCannedReply

  const noCannedReplyFoundById = isUpdate
    ? hasLoadedCannedReply && !cannedReplyToUpdate
    : false

  const canLoadCannedReplyForUpdate = !isLoading && !noCannedReplyFoundById

  // If access this drawer without through CannedReplyDataTable, need to fetch canned replies after saving
  const shouldFetchCannedReplies = !useSelector(state =>
    selectQueryParamsQueryId(state, {
      targetId: SETTINGS_CANNED_REPLY_TABLE_ID,
    })
  )

  const useFormMethods = useForm({
    mode: 'all',
    resolver: yupResolver(FORM_SCHEMA),
    defaultValues: formEmptyState,
  })

  const {
    register,
    handleSubmit,
    reset,
    formState,
    control,
    setValue,
    getValues,
    trigger,
    setError,
  } = useFormMethods

  const { errors, isValid, isDirty, dirtyFields } = formState

  // We're using useController here for controlled components
  // https://react-hook-form.com/v6/api/#Controller
  // https://react-hook-form.com/get-started#IntegratingControlledInputs
  /* const {
    field: { onChange: onBodyChange, onBlur: onBodyBlur, value: bodyValue },
  } = useController({ name: 'body', control }) */

  const {
    field: {
      onChange: onCategoryChange,
      // not necessary considering this is an optional field
      onBlur: onCategoryBlur,
      // what's being returned in value here is one of:
      // 1. defaultValue from useForm
      // 2. category object returned from loading form fields from a template (see reset() further down)
      // 3. similar to above, value returned from a previous drawer e.g. "+ Create New Category"
      // 4. value returned from onCategoryChange (when the dropdown value is changed)
      // All of which need to match the yup form schema FORM_SCHEMA
      value: { id: selectedCategoryId },
      ref: categoryRef,
    },
  } = useController({
    name: 'formCategory',
    control,
  })

  const {
    field: { onChange: onMailboxIdsChange, value: mailboxIdsValue },
  } = useController({ name: 'mailboxIds', control })

  const {
    field: {
      onChange: onChannelVisibilityChange,
      value: channelVisibilityValue,
      ref: channelVisibilityRef,
      onBlur: onChannelVisibilityBlur,
    },
  } = useController({ name: 'channelVisibility', control })

  const onCategorySelect = useCallback(
    (_, category) => {
      if (category) {
        // store the 'selected value in dropdown'
        onCategoryChange({
          id: category.id,
          name: category.name,
        })
        // also set the value of category id in category field (what actually gets saved to entity store)
        setValue('category', category?.id)
      }
    },
    [onCategoryChange, setValue]
  )

  useEffect(
    () => {
      if (!open) return
      if (isUpdate && canLoadCannedReplyForUpdate) {
        // load fields with template values
        reset({
          ...generateDefaultValues(cannedReplyToUpdate, originalCategory),
        })
      }
    },
    // We intentionally exclude dependency props here to ensure that this
    // reset only triggers for the initial load
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [canLoadCannedReplyForUpdate, isUpdate, open, drawerSetCategoryId]
  )

  useEffect(
    () => {
      if (!open) return

      if (isAiUpdate && AiLoaded) {
        setEditorDisabled(true)
        reset({
          ...generateDefaultValues(),
          body: AiResponse.body,
          subject: AiResponse.title,
        })
      }
    },
    // We intentionally exclude dependency props here to ensure that this
    // reset only triggers for the initial load
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [open, isAiUpdate, AiResponse, AiLoaded]
  )

  useEffect(
    () => {
      if (drawerSetCategoryId && cannedReplyToUpdateCategory) {
        reset(
          {
            ...generateDefaultValues(cannedReplyToUpdate),
            ...cannedReplyToUpdate?.dirtyFields,
            ...generateDefaultCategoryValues(cannedReplyToUpdateCategory),
          },
          {
            keepDefaultValues: true,
          }
        )
      } else {
        // Handle reset after category edit, but didn't create a new category
        reset(
          {
            ...generateDefaultValues(
              cannedReplyToUpdate,
              cannedReplyToUpdateCategory
            ),
            ...cannedReplyToUpdate?.dirtyFields,
          },
          {
            keepDefaultValues: true,
          }
        )
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [drawerSetCategoryId, cannedReplyToUpdateCategory]
  )

  const { releaseHold, holdKey } = useRhfDirtyHold(
    drawerId,
    control,
    `Are you sure? You have unsaved ${app.t(
      'canned_reply'
    )} changes that will be lost.`
  )

  const beforeCloseDrawer = useCallback(
    () => {
      dispatch(doClearCannedReplyTemplateDraft(cannedReplyId))
      reset(formEmptyState)
      setClosing(() => true)
      if (isAiUpdate) {
        stopRecording()
        cancelRequest()
      }
    },
    [dispatch, cannedReplyId, reset, isAiUpdate, cancelRequest]
  )

  const handleOnClose = useConfirmHoldsCallback(
    holdKey,
    () => {
      setClosing(() => true)
      beforeCloseDrawer()
      onClose()
    },
    [beforeCloseDrawer, onClose]
  )

  const handleOnExit = useConfirmHoldsCallback(
    null,
    () => {
      setClosing(() => true)
      beforeCloseDrawer()
      onExit()
    },
    [beforeCloseDrawer, onExit]
  )

  const handleOnAiDone = useCallback(
    () => {
      capture('ai instant_reply done')
      setEditorDisabled(false)
      hidePrompt()
    },
    [hidePrompt]
  )

  const handleOnAiRetry = useCallback(
    async () => {
      capture('ai instant_reply retried', {
        body: AiResponse.body,
        title: AiResponse.title,
      })
      clearState()
    },
    [clearState, AiResponse]
  )

  const handleOnAiCancel = useCallback(
    () => {
      capture('ai instant_reply canceled', {
        body: AiResponse.body,
        title: AiResponse.title,
      })
      setEditorDisabled(false)
      abortResult()
      hidePrompt()
    },
    [abortResult, hidePrompt, AiResponse]
  )

  const handleError = useCallback(
    errorsObj => {
      Object.keys(errorsObj).forEach(e => {
        if (!errorsData[e]) {
          return
        }

        setError(
          errorsData[e].key,
          {
            type: 'custom',
            message: errorsData[e].message,
          },
          {
            shouldFocus: true,
          }
        )
      })
    },
    [setError]
  )

  const onSubmit = useCallback(
    async data => {
      // remove 'form' prefixed fields otherwise it'll get stored in entity store and maybe sent to api
      const sanitizedData = omit(['formCategory', 'formPendingUploads'], data)
      const { formPendingUploads: uploadAttachments } = data

      setIsSaving(true)

      try {
        await dispatch(
          doSaveCannedReplyTemplate(cannedReplyId, sanitizedData, {
            uploadAttachments,
            queryId,
            shouldFetchCannedReplies,
          })
        )

        setIsSaving(false)
        releaseHold()
        handleOnClose()
      } catch (error) {
        setIsSaving(false)
        handleError(error)
      }
    },
    [
      dispatch,
      handleOnClose,
      cannedReplyId,
      queryId,
      releaseHold,
      shouldFetchCannedReplies,
      handleError,
    ]
  )

  const { openDrawer: openEditDrawer } = useDrawer(
    DRAWER_TYPE_CANNED_REPLY_CATEGORIES_UPDATE
  )

  const onSaveDraft = useCallback(
    () => {
      const formValues = getValues()
      const dirtyFieldListWithTrueValues = Object.keys(dirtyFields).filter(
        key => dirtyFields[key] === true
      )

      dispatch(
        doSaveCannedReplyTemplateDraft(cannedReplyId, {
          dirtyFields: dirtyFieldListWithTrueValues.reduce(
            (accumulator, currentField) => {
              return {
                ...accumulator,
                [currentField]: formValues[currentField],
              }
            },
            {}
          ),
        })
      )
      releaseHold()
    },
    [releaseHold, cannedReplyId, dirtyFields, dispatch, getValues]
  )

  const handleOnCreateCategory = useCallback(
    () => {
      onSaveDraft()
      openEditDrawer('new', {
        additionalProps: {
          queryId,
        },
      })
    },
    [openEditDrawer, queryId, onSaveDraft]
  )

  const DrawerForm = useCallback(
    props => <form onSubmit={handleSubmit(onSubmit)} {...props} />,
    [handleSubmit, onSubmit]
  )

  const handleOnResize = useCallback(
    () => {
      setMaxHeight(getMaxHeight(cannedReplyToUpdate?.attachments?.length))
    },
    [cannedReplyToUpdate]
  )

  const drawerContextValue = useMemo(() => ({ onSaveDraft }), [onSaveDraft])

  useEffect(
    () => {
      window.addEventListener('resize', handleOnResize)

      return () => window.removeEventListener('resize', handleOnResize)
    },
    [handleOnResize]
  )

  useEffect(
    () => {
      handleOnResize()
    },
    [cannedReplyToUpdate?.attachments?.length, handleOnResize]
  )

  useEffect(
    () => {
      if (showPlanLimitation && !isUpdate) {
        dispatch(
          redirect({
            type: SETTINGS_CANNEDREPLIES_PAGE,
          })
        )
      }
    },
    [showPlanLimitation, dispatch, isUpdate]
  )

  const actions = (
    <>
      <div className="grui mt-12">
        <div css={fieldStyles.labelBox}>Add tags to conversation</div>
        <div css={copy}>
          {`These tags will be added to the conversation when sending this  ${app.t(
            'Canned_Reply'
          )}.`}
        </div>
        <CannedReplyAddTags
          trigger={trigger}
          getValues={getValues}
          setValue={setValue}
        />
      </div>

      <div className="grui mt-12 mb-12">
        <div css={fieldStyles.labelBox}>Add an action</div>
        <div css={copy}>
          {`These actions will be run when sending this ${app.t(
            'Canned_Reply'
          )}.`}
        </div>
        <CannedReplyAutomaticActions
          control={control}
          trigger={trigger}
          setValue={setValue}
        />
      </div>
      <div className="grui mb-16 mt-8">
        <div css={fieldStyles.labelBox} className="grui mb-8">
          Select which {app.t('mailboxes')} to include the{' '}
          {app.t('canned_reply')} in
        </div>

        <MailboxSelection
          selectedIds={mailboxIdsValue}
          type={channelVisibilityValue}
          onMailboxListChange={onMailboxIdsChange}
          onMailboxVisibilityTypeChange={onChannelVisibilityChange}
          css={selection}
          checkboxListStyles={checkboxList}
          mailboxVisibilityTypeRef={channelVisibilityRef}
          onMailboxVisibilityTypeBlur={onChannelVisibilityBlur}
          dropdownBtnSize="small"
        />
      </div>
    </>
  )

  return (
    <DrawerContext.Provider value={drawerContextValue}>
      <FormProvider {...useFormMethods}>
        <Drawer
          {...rest}
          open={open}
          onClose={handleOnExit}
          size="wide"
          title={
            isUpdate
              ? `Edit ${app.t('canned_reply')}`
              : `New ${app.t('canned_reply')}`
          }
          isLoading={isLoading}
          isError={hasError}
          isNoResultFound={noCannedReplyFoundById}
          footer={
            <ModalBtns
              saveBtnDisabled={
                isLoading || hasError || !isValid || !isDirty || isSaving
              }
              saveBtnText={
                <>
                  {isSaving && (
                    <span>
                      Saving<AnimatedEllipsis />
                    </span>
                  )}
                  {!isSaving && isUpdate && 'Save'}
                  {!isSaving && !isUpdate && 'Create'}
                </>
              }
              // type="submit" will automatically trigger the form submit event when clicked
              saveBtnHtmlType="submit"
              tertiaryBtnText="Cancel"
              onClickTertiaryBtn={handleOnClose}
            />
          }
          container={DrawerForm}
        >
          <div className="grui pt-10" css={editorWrapper}>
            <div css={topFields} className="grui mb-12">
              <Field
                {...register('name')}
                label="Name*"
                placeholder={`Enter ${app.t('canned_reply')} name...`}
                name="name"
                validateStatus={errors?.name ? 'error' : undefined}
                help={errors?.name?.message}
              />
              <div css={dropdownContainer}>
                <div css={fieldStyles.labelBox}>Category</div>
                <CategorySelection
                  categories={categories}
                  categoryId={selectedCategoryId}
                  onSelectCategory={onCategorySelect}
                  css={fullWidth}
                  showCreate
                  onCreateCategory={handleOnCreateCategory}
                  buttonRef={categoryRef}
                  onButtonBlur={onCategoryBlur}
                />
              </div>
            </div>

            <MessageTemplateEditor
              id={cannedReplyId}
              type="CannedReply"
              entityType="cannedReply"
              disabled={editorDisabled}
              open={open}
              mode="managed"
              showPluginImageUpload
              showPluginSourceEditing
              showAddAttachment
              showPluginVariablesPlaceholder
              pluginVariablesPlaceholderTypes={
                hasLoadedVariables ? variables : undefined
              }
              formInputSubjectPlaceholder="Enter email subject..."
              queryId={queryId}
              minHeight={'122px'}
              maxHeight={`${maxHeight + 100}px`}
              shouldResetFieldOnReady
              formInputBodyLabel="Response text"
              formInputSubjectLabel="Email subject"
              formInputBodySubtitle={`This subject line is only used when creating a new conversation with this ${app.t(
                'Canned_Reply'
              )}.`}
              isFormInputSubjectWithVariablesHidden
              isAiGenerated={AiShowPrompt && isAiUpdate}
              isAiError={AiError && isAiUpdate}
              showAiSpinner={AiLoading && isAiUpdate}
              onAiDone={handleOnAiDone}
              onAiRetry={handleOnAiRetry}
              onAiCancel={handleOnAiCancel}
            />
            {showPlanLimiationForActions ? (
              <PlanLimitationForCannedReply className="grui mt-12" />
            ) : (
              actions
            )}
          </div>
        </Drawer>
      </FormProvider>
    </DrawerContext.Provider>
  )
}
