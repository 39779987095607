import graphql from 'api/graphql'
import grooveAPI from 'api/groove'
import { doAppGraphqlRequest } from 'ducks/requests/operations'
import { isBlank } from 'util/strings'
import { logError } from 'util/debug'
import { NOT_FOUND, redirect } from 'redux-first-router'
import editor from 'shared/editor/utils'

import * as types from 'subapps/kb/actions'
import * as pages from 'subapps/kb/pages'

import { oauthTokenSelector } from 'selectors/app'
import { selectCurrentKnowledgeBaseId } from 'subapps/kb/selectors/knowledge_bases'
import {
  selectActiveArticle,
  selectCurrentArticlesState,
  selectById,
  selectArticlesStale,
} from 'subapps/kb/selectors/articles'
import { doFetchBillingData } from 'ducks/billing/operations'
import { FEATURE_KB_MAX_ARTICLES } from 'ducks/billing/featureTypes'
import { selectFeatureUsage } from 'ducks/billing/selectors/features'
import { capture } from 'ducks/tracking/actions'
import { buildId } from 'util/globalId'
import { doFetchCategories } from '../categories/doFetchCategories'
import { doLoadCategoriesTitles } from '../categories/doLoadCategoriesTitles'
import { doFetchTags } from '../tags'
import { doFetchArticlesCounts } from './doFetchArticlesCounts'

const articleFields = `
  authorId
  author {
    avatar_url
    name
  }
  categoryId
  category {
    id
    title
    slug
    state
  }
  createdAt
  description
  featured
  id
  knowledgeBaseId
  metaDescription
  metaRobots
  ogDescription
  ogImageUrl
  ogTitle
  pageTitle
  ratings {
    count
    value
  }
  relatedIds
  relatedArticles {
    id
    title
  }
  slug
  state
  tags
  title
  updatedAt
`

export function doLoadArticles(context) {
  return (dispatch, getState) => {
    dispatch(doLoadCategoriesTitles())
    dispatch(
      doFetchArticles({
        selectedState: selectCurrentArticlesState(getState()),
        // In order not to confuse user with limited result set,
        // we reset keyword search every time they load articles
        keyword: '',
        context,
      })
    )
  }
}

export function doLoadArticleEditor() {
  return (dispatch, getState) => {
    const { id, preventReload } = getState().location.payload
    // HACK (jscheel): This is terrible.
    if (preventReload) {
      return
    }
    dispatch(doFetchArticle(id))
    dispatch(doLoadCategoriesTitles())
    dispatch(doFetchTags('article'))
  }
}

export function doLoadArticleCreator() {
  return dispatch => {
    dispatch(doLoadCategoriesTitles())
    dispatch(doFetchTags('article'))
  }
}

/*
 * Fetch articles
 *
 * opts:
 *   keyword - The String keyword to search by, default: ''
 *   selectedState - The String state to filter by
 *   selectedCategoryId - The String category id to filter by
 *   selectedCategoryTitle - The String category title
 *   selectedAuthorId - The String author id to filter by
 *   selectedAuthorName - The String author name
 *   sortByOrder - The String sort by field and order: [asc, desc]
 *   currentPage - The Integer page number to fetch
 *   perPage - The Integer number of results per page
 *   context - Name of context for search results
 */

export function doFetchArticles(_opts) {
  return (dispatch, getState) => {
    const opts = _opts || {}
    const state = getState()
    const token = oauthTokenSelector(state)
    const knowledgeBaseId = selectCurrentKnowledgeBaseId(state)
    const context = opts.context || 'ARTICLE_LIST'
    const stale = selectArticlesStale(state)
    const {
      ids,
      keyword,
      selectedState,
      selectedCategoryId,
      selectedCategoryTitle,
      selectedAuthorId,
      selectedAuthorName,
      sortByOrder,
      currentPage,
      perPage,
      stale: contextStale,
    } = state.kb.articles.byContext[context]

    // these options may come as nulls to reset the filters
    const newKeyword = opts.keyword !== undefined ? opts.keyword : keyword
    const newSelectedState =
      opts.selectedState !== undefined ? opts.selectedState : selectedState
    const newSelectedCategoryId =
      opts.selectedCategoryId !== undefined
        ? opts.selectedCategoryId
        : selectedCategoryId

    const newSelectedCategoryTitle =
      opts.selectedCategoryTitle !== undefined
        ? opts.selectedCategoryTitle
        : selectedCategoryTitle

    const newSelectedAuthorId =
      opts.selectedAuthorId !== undefined
        ? opts.selectedAuthorId
        : selectedAuthorId

    const newSelectedAuthorName =
      opts.selectedAuthorName !== undefined
        ? opts.selectedAuthorName
        : selectedAuthorName

    const newSortByOrder = opts.sortByOrder || sortByOrder
    const newPerPage = opts.perPage || perPage

    const filtersChanged =
      newSortByOrder !== sortByOrder ||
      newKeyword !== keyword ||
      newSelectedState !== selectedState ||
      newSelectedCategoryId !== selectedCategoryId ||
      newSelectedAuthorId !== selectedAuthorId ||
      newPerPage !== perPage

    const pageChanged = opts.currentPage && currentPage !== opts.currentPage

    // To avoid extra requests, do not re-fetch if filters or
    // page are unchanged and we have fetched some records before
    if (
      !stale &&
      !contextStale &&
      !filtersChanged &&
      !pageChanged &&
      ids.length > 0
    ) {
      return
    }

    // If any of the filters changed, reset back to first page
    const newCurrentPage = filtersChanged ? 1 : opts.currentPage || currentPage

    dispatch({
      type: types.FETCH_ARTICLES_REQUEST,
      context,
      selectedState: newSelectedState,
    })

    const categoryIdQuery = `categoryId: "${newSelectedCategoryId}"`
    const authorIdQuery = `authorId: "${newSelectedAuthorId}"`

    let states = [newSelectedState]

    if (newSelectedState === 'all') {
      states = ['published', 'wip', 'draft']
    } else if (newSelectedState === 'allWithDeleted') {
      states = ['published', 'wip', 'draft', 'deleted']
    } else if (newSelectedState === 'published') {
      states = ['published', 'wip']
    }

    const query = `
      query ArticlesQuery($knowledgeBaseId: ID!, $state: [String]) {
        articles(
          knowledgeBaseId: $knowledgeBaseId
          state: $state
          keyword: "${isBlank(newKeyword) ? '*' : newKeyword}"
          page: ${newCurrentPage}
          perPage: ${newPerPage}
          order: "${newSortByOrder}"
          ${newSelectedCategoryId ? categoryIdQuery : ''}
          ${newSelectedAuthorId ? authorIdQuery : ''}
        ){
          data {
            ${articleFields}
          }
          meta {
            totalCount
            currentPage
            totalPages
          }
        }
      }
    `

    const variables = {
      knowledgeBaseId,
      state: states,
    }

    graphql(token, query, variables)
      .then(res => {
        const data = res.json.data
        const articleIds = data.articles.data.map(a => a.id)

        dispatch({
          type: types.FETCH_ARTICLES_SUCCESS,
          data: data.articles.data,
          meta: data.articles.meta,
          keyword: newKeyword,
          selectedCategoryId: newSelectedCategoryId,
          selectedCategoryTitle: newSelectedCategoryTitle,
          selectedAuthorId: newSelectedAuthorId,
          selectedAuthorName: newSelectedAuthorName,
          sortByOrder: newSortByOrder,
          perPage: newPerPage,
          context,
        })

        if (context === 'ARTICLE_LIST') {
          dispatch(
            doFetchArticlesCounts({
              keyword: newKeyword,
              categoryId: newSelectedCategoryId,
              authorId: newSelectedAuthorId,
            })
          )
          dispatch(doFetchArticlesViews(articleIds))
        }
      })
      .catch(() => {
        dispatch({
          type: types.FETCH_ARTICLES_FAILURE,
          context,
        })
      })
  }
}

export function doFetchArticlesViews(articleIds) {
  return (dispatch, getState) => {
    const state = getState()
    const token = oauthTokenSelector(state)

    dispatch({
      type: types.FETCH_ARTICLES_VIEWS_REQUEST,
    })

    const filters = [
      {
        property_name: 'article.id',
        operator: 'in',
        property_value: articleIds,
      },
    ]

    return grooveAPI
      .get(token, 'v2/reports/count', {
        event_collection: 'kb.article.view',
        group_by: 'article.id',
        timeframe: 'this_30_days',
        filters: JSON.stringify(filters),
      })
      .then(res => {
        dispatch({
          type: types.FETCH_ARTICLES_VIEWS_SUCCESS,
          data: res.json.result,
        })
      })
      .catch(() => {
        dispatch({
          type: types.FETCH_ARTICLES_VIEWS_FAILURE,
        })
      })
  }
}

export function doFetchArticle(articleId) {
  return (dispatch, getState) => {
    const state = getState()
    const token = oauthTokenSelector(state)
    const knowledgeBaseId = selectCurrentKnowledgeBaseId(state)

    dispatch({
      type: types.FETCH_ARTICLE_REQUEST,
    })

    const query = `
      query ArticleQuery($knowledgeBaseId: ID!, $id: ID!) {
        article(knowledgeBaseId: $knowledgeBaseId, id: $id){
          ${articleFields}
          body
        }
      }
    `

    const variables = {
      knowledgeBaseId,
      id: articleId,
    }

    return graphql(token, query, variables)
      .then(res => {
        const article = res.json.data.article
        // do not allow editing trashed articles
        if (article.state === 'deleted') {
          dispatch({
            type: pages.ARTICLES_PAGE,
            payload: { knowledgeBaseId },
          })

          return
        }
        dispatch({
          type: types.FETCH_ARTICLE_SUCCESS,
          data: { article },
        })
      })
      .catch(err => {
        dispatch({
          type: types.FETCH_ARTICLE_FAILURE,
        })
        if (err && err.errors && err.errors[0].status === 404) {
          dispatch({ type: NOT_FOUND })
        } else {
          logError(err)
        }
      })
  }
}

export function doSelectAllArticles(context, selected) {
  return {
    type: types.SET_SELECTED_ALL_ARTICLES,
    context,
    data: { selected },
  }
}

export function doSelectArticle(context, articleId, selected) {
  return {
    type: types.SET_SELECTED_ARTICLES,
    context,
    data: { articleId, selected },
  }
}

export function doCreateArticle(article) {
  return (dispatch, getState) => {
    const state = getState()
    const token = oauthTokenSelector(state)
    const knowledgeBaseId = selectCurrentKnowledgeBaseId(state)

    dispatch({
      type: types.CREATE_ARTICLE_REQUEST,
      data: article,
    })

    const mutation = `
      mutation CreateArticle($knowledgeBaseId: ID!, $input: ArticleInput!) {
        createArticle(knowledgeBaseId: $knowledgeBaseId, input: $input) {
          ${articleFields}
          body
        }
      }
    `

    const variables = {
      knowledgeBaseId,
      input: {
        body: article.body,
        authorId: article.authorId,
        categoryId: article.categoryId,
        description: article.description,
        featured: article.featured,
        metaDescription: article.metaDescription,
        metaRobots: article.metaRobots,
        ogDescription: article.ogDescription,
        ogImageUrl: article.ogImageUrl,
        ogTitle: article.ogTitle,
        pageTitle: article.pageTitle,
        tags: article.tags,
        title: article.title,
      },
    }

    return graphql(token, mutation, variables)
      .then(res => {
        capture('kb create article')
        const data = res.json.data
        const createdArticle = data.createArticle
        const createdArticleId = createdArticle.id
        dispatch({
          type: types.CREATE_ARTICLE_SUCCESS,
          data: { createdArticle, createdArticleId },
        })
        dispatch(doFetchBillingData())
        dispatch(
          redirect({
            type: pages.ARTICLE_EDIT_PAGE,
            payload: {
              id: createdArticleId,
              knowledgeBaseId: article.knowledgeBaseId,
              preventReload: true,
            },
          })
        )
      })
      .catch(err => {
        dispatch({
          type: types.CREATE_ARTICLE_FAILURE,
          data: { err },
        })
      })
  }
}

export function doUpdateArticle(article, articleId) {
  if (articleId === 'new') {
    return doCreateArticle(article)
  }
  return (dispatch, getState) => {
    const state = getState()
    const token = oauthTokenSelector(state)
    const knowledgeBaseId = selectCurrentKnowledgeBaseId(state)
    const existingArticle = selectById(state)[articleId]

    dispatch({
      type: types.UPDATE_ARTICLE_REQUEST,
      data: { article, articleId },
    })

    const mutation = `
      mutation UpdateArticle(
        $knowledgeBaseId: ID!,
        $id: ID!,
        $input: ArticleInput!
      ){
        updateArticle(
          knowledgeBaseId: $knowledgeBaseId,
          id: $id,
          input: $input
        ) {
          ${articleFields}
          body
        }
      }
    `

    // TODO (jscheel): We really should not have this computed property here.
    // Eventually, we either need to move this logic to GQL, or we need to
    // change the data model so that index and follow are stored separately and
    // the final robots tag value is computed at render time in the backend.
    // Currently we also have to decorate the article in selectors to break
    // this value back apart, which is terrible.
    const metaRobots = `${article.metaIndex ? 'INDEX' : 'NOINDEX'},FOLLOW`

    const variables = {
      knowledgeBaseId,
      id: articleId,
      input: {
        body: article.body,
        authorId: article.authorId,
        categoryId: article.categoryId,
        description: article.description,
        featured: article.featured,
        metaDescription: article.metaDescription,
        metaRobots,
        ogDescription: article.ogDescription,
        ogImageUrl: article.ogImageUrl,
        ogTitle: article.ogTitle,
        pageTitle: article.pageTitle,
        slug: article.slug,
        tags: article.tags,
        title: article.title,
        relatedIds: article.relatedIds,
      },
    }

    return graphql(token, mutation, variables)
      .then(res => {
        capture('kb update article')
        const data = res.json.data
        const updatedArticle = data.updateArticle

        dispatch({
          type: types.UPDATE_ARTICLE_SUCCESS,
          data: { updatedArticle },
        })

        // If article category has changed, refetch all categories
        // to see an updated list with article counts
        if (existingArticle.categoryId !== updatedArticle.categoryId) {
          dispatch(doFetchCategories({ refetch: true }))
        }
      })
      .catch(err => {
        dispatch({
          type: types.UPDATE_ARTICLE_FAILURE,
          data: { err },
        })
      })
  }
}

export function doUpdateCurrentArticle(fields) {
  return (dispatch, getState) => {
    const state = getState()
    const article = { ...selectActiveArticle(state), ...fields }
    return dispatch(doUpdateArticle(article, article.id))
  }
}

export function doPublishArticle(articleId) {
  return (dispatch, getState) => {
    const state = getState()
    const token = oauthTokenSelector(state)
    const knowledgeBaseId = selectCurrentKnowledgeBaseId(state)
    const {
      featureLimit: articlesLimit,
      currentUsage: totalArticles,
    } = selectFeatureUsage(state, FEATURE_KB_MAX_ARTICLES)

    if (totalArticles >= articlesLimit) {
      return dispatch({
        type: pages.PRO_UPGRADE_PAGE,
      })
    }

    dispatch({
      type: types.PUBLISH_ARTICLE_REQUEST,
      data: { articleId },
    })
    const mutation = `
      mutation publishArticle($knowledgeBaseId: ID!, $id: ID!) {
        publishArticle(knowledgeBaseId: $knowledgeBaseId, id: $id) {
          ${articleFields}
          body
        }
      }
    `
    const variables = {
      knowledgeBaseId,
      id: articleId,
    }

    return graphql(token, mutation, variables)
      .then(res => {
        capture('kb publish article')
        const data = res.json.data
        const publishedArticle = data.publishArticle
        dispatch({
          type: types.PUBLISH_ARTICLE_SUCCESS,
          data: { publishedArticle },
        })
      })
      .catch(err => {
        dispatch({
          type: types.PUBLISH_ARTICLE_FAILURE,
          data: { err },
        })
      })
  }
}

export function doUnpublishArticle(articleId) {
  return (dispatch, getState) => {
    const state = getState()
    const token = oauthTokenSelector(state)
    const knowledgeBaseId = selectCurrentKnowledgeBaseId(state)

    dispatch({
      type: types.UNPUBLISH_ARTICLE_REQUEST,
      data: { articleId },
    })

    const mutation = `
      mutation unpublishArticle($knowledgeBaseId: ID!, $id: ID!) {
        unpublishArticle(knowledgeBaseId: $knowledgeBaseId, id: $id) {
          ${articleFields}
          body
        }
      }
    `
    const variables = {
      knowledgeBaseId,
      id: articleId,
    }

    return graphql(token, mutation, variables)
      .then(res => {
        capture('kb unpublish article')
        const data = res.json.data
        const unpublishedArticle = data.unpublishArticle
        dispatch({
          type: types.UNPUBLISH_ARTICLE_SUCCESS,
          data: { unpublishedArticle },
        })
      })
      .catch(err => {
        dispatch({
          type: types.UNPUBLISH_ARTICLE_FAILURE,
          data: { err },
        })
      })
  }
}

export function doRevertArticle(articleId) {
  return (dispatch, getState) => {
    const state = getState()
    const token = oauthTokenSelector(state)
    const knowledgeBaseId = selectCurrentKnowledgeBaseId(state)

    dispatch({
      type: types.REVERT_ARTICLE_REQUEST,
      data: { articleId },
    })

    const mutation = `
      mutation revertArticle($knowledgeBaseId: ID!, $id: ID!) {
        revertArticle(knowledgeBaseId: $knowledgeBaseId, id: $id) {
          ${articleFields}
          body
        }
      }
    `
    const variables = {
      knowledgeBaseId,
      id: articleId,
    }

    return graphql(token, mutation, variables)
      .then(res => {
        capture('kb revert article')
        const data = res.json.data
        const revertedArticle = data.revertArticle
        dispatch({
          type: types.REVERT_ARTICLE_SUCCESS,
          data: { revertedArticle },
        })
        // revert the content of the tiny mce editor
        const tinyEditor = editor.getEditor()
        if (tinyEditor) {
          tinyEditor.setContent(revertedArticle.body, {
            focus: true,
            format: 'raw',
          })
        }
      })
      .catch(err => {
        dispatch({
          type: types.REVERT_ARTICLE_FAILURE,
          data: { err },
        })
      })
  }
}

export function doTrashArticle(articleId) {
  return (dispatch, getState) => {
    const state = getState()
    const token = oauthTokenSelector(state)
    const knowledgeBaseId = selectCurrentKnowledgeBaseId(state)

    dispatch({
      type: types.TRASH_ARTICLE_REQUEST,
      data: { articleId },
    })
    const mutation = `
      mutation deleteArticle($knowledgeBaseId: ID!, $id: ID!) {
        deleteArticle(knowledgeBaseId: $knowledgeBaseId, id: $id) {
          ${articleFields}
        }
      }
    `
    const variables = {
      knowledgeBaseId,
      id: articleId,
    }

    return graphql(token, mutation, variables)
      .then(res => {
        capture('kb delete article')
        const data = res.json.data
        const trashedArticle = data.deleteArticle
        dispatch({
          type: types.TRASH_ARTICLE_SUCCESS,
          data: { trashedArticle },
        })
        dispatch(doFetchBillingData())
      })
      .catch(err => {
        dispatch({
          type: types.TRASH_ARTICLE_FAILURE,
          data: { err },
        })
      })
  }
}

export function doDeleteArticle(articleId) {
  const articleGid = buildId('Article', articleId)

  const query = `
    mutation KbArticleDelete($articleId: ID!) {
      kbArticleDelete(input: {
        articleId: $articleId
      }) {
        deletedArticleId
        errors {
          message
          path
        }
      }
    }
  `

  return doAppGraphqlRequest(
    types.DELETE_ARTICLE,
    query,
    { articleId: articleGid },
    {
      moduleOptions: {
        toasts: {
          enabled: true,
          started: {
            enabled: false,
          },
          success: {
            enabled: true,
            content: 'Article deleted',
          },
          failed: {
            enabled: true,
            content: 'Article delete failed',
          },
        },
      },
    }
  )
}

export function doRestoreArticle(articleId) {
  return (dispatch, getState) => {
    const state = getState()
    const token = oauthTokenSelector(state)
    const knowledgeBaseId = selectCurrentKnowledgeBaseId(state)

    dispatch({
      type: types.RESTORE_ARTICLE_REQUEST,
      data: { articleId },
    })
    const mutation = `
      mutation restoreArticle($knowledgeBaseId: ID!, $id: ID!) {
        restoreArticle(knowledgeBaseId: $knowledgeBaseId, id: $id) {
          ${articleFields}
        }
      }
    `
    const variables = {
      knowledgeBaseId,
      id: articleId,
    }

    return graphql(token, mutation, variables)
      .then(res => {
        capture('kb restore article')
        const data = res.json.data
        const restoredArticle = data.restoreArticle
        dispatch({
          type: types.RESTORE_ARTICLE_SUCCESS,
          data: { restoredArticle },
        })
        dispatch(doFetchBillingData())
      })
      .catch(err => {
        dispatch({
          type: types.RESTORE_ARTICLE_FAILURE,
          data: { err },
        })
      })
  }
}

export function doCancel() {
  return { type: types.ARTICLE_CANCEL_CLOSE }
}

export function doCancelAndClose() {
  return (dispatch, getState) => {
    const state = getState()
    const knowledgeBaseId = selectCurrentKnowledgeBaseId(state)

    dispatch(doCancel())
    dispatch({ type: pages.ARTICLES_PAGE, payload: { knowledgeBaseId } })
  }
}

export function doSaveAndClose() {
  return (dispatch, getState) => {
    const state = getState()
    const knowledgeBaseId = selectCurrentKnowledgeBaseId(state)

    dispatch({ type: types.ARTICLE_SAVE_CLOSE })
    dispatch({
      type: pages.ARTICLES_PAGE,
      payload: { knowledgeBaseId },
    })
  }
}
