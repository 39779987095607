import React, { useCallback, useMemo } from 'react'
import {
  text,
  paragraph,
} from '@groovehq/internal-design-system/lib/styles/elements'
import { useDispatch, useSelector } from 'react-redux'
import debug from 'util/debug'
import EntityDelete from 'subapps/settings/components/drawers/EntityDelete'
import { selectWidgetSettingsById } from 'ducks/widgets/selectors/settings'
import { doUpdateSettings } from 'ducks/widgets/operations'
import {
  generateDefaultValueForFormFields,
  WIDGET_CONTACT_FORM_FIELDS,
} from './util'

const toastsConfig = {
  success: {
    content: 'Question deleted',
  },
  failed: {
    content: 'Question delete failed',
  },
}

const CustomFieldDelete = ({
  open,
  drawerResourceId: formFieldId,
  drawerWidgetId: widgetId,
  onClose,
  onExit,
  resetField,
}) => {
  const dispatch = useDispatch()
  const settings = useSelector(state =>
    selectWidgetSettingsById(state, widgetId)
  )
  const formField = useMemo(
    () =>
      settings?.[WIDGET_CONTACT_FORM_FIELDS]?.find(
        item => item.id === formFieldId
      ),
    [settings, formFieldId]
  )
  const isLoading = !formField

  const handleClose = useCallback(
    e => {
      if (e?.isAfterDelete) {
        onExit()
      } else {
        onClose()
      }
    },
    [onExit, onClose]
  )

  const handleRemove = useCallback(
    () => {
      return new Promise(async resolve => {
        try {
          const customFieldsToUpdate = settings[
            WIDGET_CONTACT_FORM_FIELDS
          ].filter(field => field.id !== formFieldId)

          const settingsData = {
            ...settings,
            [WIDGET_CONTACT_FORM_FIELDS]: customFieldsToUpdate,
          }
          await dispatch(
            doUpdateSettings(settingsData, widgetId, {
              shouldShowToasts: true,
              toastsConfig,
            })
          )
          resolve()
          if (resetField) {
            resetField(WIDGET_CONTACT_FORM_FIELDS, {
              defaultValue: generateDefaultValueForFormFields(
                customFieldsToUpdate
              ),
            })
          }
        } catch (error) {
          debug(error)
        }
      })
    },
    [dispatch, widgetId, settings, resetField, formFieldId]
  )

  if (!settings) return null

  return (
    <EntityDelete
      title="Delete question"
      onRemove={handleRemove}
      onClose={handleClose}
      onExit={onExit}
      onCancel={onClose}
      deleteBtn
      tertiaryBtnText="Cancel"
      open={open}
      isLoading={isLoading}
      confirmationHidden
    >
      <div css={text.styles.textNormal} className="grui pt-5">
        <p css={[paragraph.styles.preflight, text.styles.fontMedium]}>
          {`Are you sure you want to permanently delete the question “${
            formField?.name
          }”?`}
        </p>
        <br />
        <p css={paragraph.styles.preflight}>
          This will remove the question from your contact form and cannot be
          undone.
        </p>
      </div>
    </EntityDelete>
  )
}

export default CustomFieldDelete
