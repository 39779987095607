import { css } from '@emotion/react'

export const header = theme => css`
  && > h4 {
    margin-top: ${theme.spacing[14]};
  }
`

export const list = theme => css`
  li + li {
    margin-top: ${theme.spacing[12]};
  }
  p {
    margin-left: ${theme.spacing[15]};
    margin-top: ${theme.spacing[5]};
  }
`
