import React from 'react'
import Link from 'redux-first-router-link'
import { SETTINGS_BILLING_PAGE } from 'subapps/settings/types'
import { Button } from 'util/ui'

import { styles } from './styles'

const TO_SETTINGS_BILLING_PLANS_PAGE = {
  type: SETTINGS_BILLING_PAGE,
  payload: {
    tab: 'plans',
  },
}

const UpgradeButton = () => {
  return (
    <Button
      fluid
      primary
      as={Link}
      to={TO_SETTINGS_BILLING_PLANS_PAGE}
      css={styles.btn}
    >
      Upgrade to install Premium integration
    </Button>
  )
}

export default UpgradeButton
