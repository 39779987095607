export const wait = ms => new Promise(r => setTimeout(r, ms))

// Retries Promise N times with incremental backoff
export const retryOperation = (operation, delay, times) =>
  new Promise((resolve, reject) => {
    return operation()
      .then(resolve)
      .catch(reason => {
        if (times - 1 > 0) {
          return wait(delay)
            .then(retryOperation.bind(null, operation, delay, times - 1))
            .then(resolve)
            .catch(reject)
        }
        return reject(reason)
      })
  })

export const promisify = (fn, ...args) => {
  if (!fn) {
    return Promise.resolve(fn)
  }
  if (fn instanceof Promise) {
    return fn
  }
  return new Promise((resolve, reject) => {
    try {
      if (typeof fn === 'function') {
        resolve(fn(...args))
      } else {
        resolve(fn)
      }
    } catch (e) {
      reject(e)
    }
  })
}
