import { reverseHashInt } from 'util/scatterSwap'
import { flatten } from 'util/arrays'
import {
  ASSIGNED_AGENT,
  ASSIGNED_GROUP,
} from '../drawers/FolderCreateEdit/data'

export const buildPossibleValueOptions = (
  values,
  parameterKey,
  agents,
  groups,
  mailboxes,
  { decodeIds }
) => {
  let possibleOptionsArray
  const possibleOptions = values[parameterKey]
  const { valueType, unassigned, anyone, currentUser } = possibleOptions || {}

  if (valueType === 'Group') {
    possibleOptionsArray = []
    if (unassigned) {
      possibleOptionsArray.push({ name: 'Unassigned', value: null })
    }
    groups.forEach(m => {
      let id = m.id
      if (decodeIds) {
        id = reverseHashInt(m.id).toString()
      }
      possibleOptionsArray.push({ name: m.name, value: id })
    })
  } else if (valueType === 'Mailbox') {
    possibleOptionsArray = []
    mailboxes.forEach(m => {
      let id = m.id
      if (decodeIds) {
        id = reverseHashInt(m.id).toString()
      }
      possibleOptionsArray.push({ name: m.name, value: id })
    })
  } else if (valueType === 'Agent') {
    possibleOptionsArray = []
    if (anyone) {
      possibleOptionsArray.push({ name: 'Anyone', value: null })
    }
    if (currentUser) {
      possibleOptionsArray.push({ name: 'Current User', value: '-1' })
    }
    if (unassigned) {
      possibleOptionsArray.push({ name: 'Unassigned', value: null })
    }
    agents.forEach(m => {
      let id = m.id
      if (decodeIds) {
        id = reverseHashInt(m.id).toString()
      }
      possibleOptionsArray.push({ name: m.name, value: id })
    })
  } else {
    possibleOptionsArray = possibleOptions
  }

  return possibleOptionsArray
}

export const selectValue = (all, key) => {
  const selected = all.find(item => item.value === key) || {
    name: '',
  }
  return selected.name
}

export const getConditionKey = (param, operator, value) => {
  return [param, operator, value].filter(item => !!item).join('-')
}

export const isConflicting = conditions => {
  if (conditions.length === 1) return []
  const conflictableTypes = [[ASSIGNED_AGENT, ASSIGNED_GROUP]]
  let isConflict = []
  conflictableTypes.forEach(conflictableTypeset => {
    const conflictableConditions = conditions.filter(condition =>
      conflictableTypeset.includes(condition.param)
    )
    if (conflictableConditions.length > 1) isConflict = conflictableTypeset
  })
  return isConflict
}

export const selectParameter = (all, param, operator, value) => {
  const items = flatten(Object.values(all))
  let match = null

  // eslint-disable-next-line no-restricted-syntax
  for (const item of items) {
    const isParamEqual = item.param === param
    // eslint-disable-next-line no-continue
    if (!isParamEqual) continue

    const isOperatorEqual = item.operator ? item.operator === operator : true
    const isValueEqual = item.value ? item.value === value : true

    if (isParamEqual && isOperatorEqual && isValueEqual)
      return item.selectedName || item.name
    if (isParamEqual && isOperatorEqual && !match) match = item
    if (isParamEqual && !match) match = item
  }

  return match?.selectedName || match?.name
}

// Allow wrap fields
export const isWrapAllowed = type =>
  ['TEXT', 'TEXTAREA', 'NUMBER', 'EMAIL', 'NOTE', 'REPLY_AGENT'].includes(type)
