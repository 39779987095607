import { FOCUS_INPUT, BLUR_INPUT } from './types'

const defaultSharedFormState = {
  inputWithFocus: null,
}

export default function sharedFormReducer(
  state,
  { type, payload: { inputType } = {} }
) {
  switch (type) {
    case FOCUS_INPUT:
      return {
        ...state,
        inputWithFocus: inputType,
      }
    case BLUR_INPUT:
      return {
        ...state,
        inputWithFocus: null,
      }
    default:
      return state || defaultSharedFormState
  }
}
