import React from 'react'
import { Accordion } from 'util/ui'

export default class Managed extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      currentSection: props.initialSection,
    }
  }

  onTitleClick = section => {
    let newSection = section
    if (this.state.currentSection === section) newSection = null
    this.setState({ currentSection: newSection })
  }

  render() {
    const { children, ...rest } = this.props
    const { currentSection } = this.state
    const elements = React.Children.toArray(children)
    const components = elements.map(el => {
      const active = el.props.name === currentSection
      return React.cloneElement(el, { active, onTitleClick: this.onTitleClick })
    })
    return <Accordion {...rest}>{components}</Accordion>
  }
}
