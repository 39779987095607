import React, { useMemo } from 'react'
import { string, func } from 'prop-types'
import Dropdown from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import { button } from '@groovehq/internal-design-system/lib/styles/elements'
import { OptionsHorizontal } from '@groovehq/internal-design-system/lib/assets/icons'
import { useFeature } from 'ducks/billing/hooks'
import { FEATURE_INBOX_MAX_VIEWER_USERS } from 'ducks/billing/featureTypes'
import DropdownMenu from './DropdownMenu'

import { styles } from './styles'

const UsersDropdown = ({
  onClickFooterBtn,
  className,
  onSelect,
  currentUserRole,
  rowItemRole,
  currentAndRowMatch,
  status2fa,
  currentScope = 'active',
}) => {
  const { isFeatureEnabled: canUseViewers } = useFeature(
    FEATURE_INBOX_MAX_VIEWER_USERS
  )

  const footerBtns = useMemo(
    () => {
      if (
        currentScope === 'active' &&
        !currentAndRowMatch &&
        (currentUserRole === 'owner' ||
          (currentUserRole === 'admin' && rowItemRole !== 'owner'))
      ) {
        return (
          <button
            type="button"
            css={button.styles.link}
            onClick={onClickFooterBtn}
          >
            {`Archive ${app.t('agent')}`}
          </button>
        )
      }
      return null
    },
    [
      currentAndRowMatch,
      currentUserRole,
      rowItemRole,
      onClickFooterBtn,
      currentScope,
    ]
  )

  const dropdownList = useMemo(
    () => {
      const items = []

      if (
        currentScope === 'active' &&
        ['admin', 'owner'].includes(currentUserRole)
      ) {
        items.push({
          key: 'resetPassword',
          title: 'Reset password',
        })
      }

      if (
        currentScope === 'active' &&
        ['admin', 'owner'].includes(currentUserRole) &&
        status2fa === 'locked'
      ) {
        items.push({
          key: 'resetLoginAttempts',
          title: 'Reset 2FA attempts',
        })
      }

      if (currentScope === 'active') {
        items.push({
          key: 'advanced',
          title: 'Advanced',
        })
      }

      if (
        !currentAndRowMatch &&
        currentScope === 'active' &&
        ['admin', 'owner'].includes(currentUserRole) &&
        rowItemRole === 'admin'
      ) {
        items.push({
          key: 'beAgent',
          title: 'Remove admin access',
        })
      }

      if (
        !currentAndRowMatch &&
        currentScope === 'active' &&
        currentUserRole === 'owner'
      ) {
        items.push({
          key: 'beOwner',
          title: 'Change to owner',
        })
      }

      if (
        !currentAndRowMatch &&
        currentScope === 'active' &&
        ['admin', 'owner'].includes(currentUserRole) &&
        ['agent', 'viewer'].includes(rowItemRole)
      ) {
        items.push({
          key: 'beAdmin',
          title: 'Change to admin',
        })
      }

      if (
        !currentAndRowMatch &&
        currentScope === 'active' &&
        ['admin', 'owner'].includes(currentUserRole) &&
        rowItemRole === 'viewer'
      ) {
        items.push({
          key: 'beAgent',
          title: 'Change to agent',
        })
      }

      if (
        canUseViewers &&
        !currentAndRowMatch &&
        currentScope === 'active' &&
        ['admin', 'owner'].includes(currentUserRole) &&
        ['agent', 'admin'].includes(rowItemRole)
      ) {
        items.push({
          key: 'beViewer',
          title: 'Change to lite user',
        })
      }

      if (currentScope === 'invited') {
        items.push({
          key: 'resendInvitation',
          title: 'Resend invitation email',
        })
        items.push({
          key: 'revokeInvitation',
          title: 'Revoke invitation',
        })
      }

      if (currentScope === 'archived') {
        items.push({
          key: 'unarchiveUser',
          title: `Unarchive ${app.t('agent')}`,
        })
      }

      return items
    },
    [
      currentScope,
      currentAndRowMatch,
      rowItemRole,
      currentUserRole,
      canUseViewers,
      status2fa,
    ]
  )

  return (
    <Dropdown
      css={styles.base}
      overlay={<DropdownMenu data={dropdownList} />}
      footer={footerBtns}
      onSelect={onSelect}
      className={className}
      strategy="fixed"
    >
      <button type="button" css={button.styles.link}>
        <OptionsHorizontal css={styles.icon} />
      </button>
    </Dropdown>
  )
}

UsersDropdown.propTypes = {
  onClickFooterBtn: func,
  className: string,
  onSelect: func,
}

UsersDropdown.defaultProps = {
  onClickFooterBtn: undefined,
  className: undefined,
  onSelect() {},
}

export default UsersDropdown
