import * as types from 'subapps/kb/actions'
import { emptyArr } from 'util/arrays'

const defaultState = {
  isLoading: false,
  file: {
    keyword: null, // keyword-based search is unsupported
    // pagination is unsupported, add for consistency
    currentPage: 0,
    perPage: 26,
  },
  unsplash: {
    keyword: null,
    currentPage: 0, // only unsplash backend supports pagination
    perPage: 26, // max limit is 30,
    totalPages: 1,
  },
  currentBackgroundUrl: null,
  currentBackgroundId: null,
}

const reducers = {}

reducers[types.FETCH_THEME_BACKGROUNDS_REQUEST] = state => {
  return {
    ...state,
    isLoading: true,
  }
}

reducers[types.FETCH_THEME_BACKGROUNDS_SUCCESS] = (state, action) => {
  const { backend, data, meta, keyword, keywordChanged } = action

  const backgrounds = keywordChanged
    ? data
    : [...(state[backend].backgrounds || emptyArr), ...data]

  return {
    ...state,
    [backend]: {
      ...state[backend],
      backgrounds,
      keyword,
      currentPage: meta.currentPage,
      totalPages: meta.totalPages,
    },
    isLoading: false,
  }
}

reducers[types.FETCH_THEME_BACKGROUNDS_FAILURE] = (state, action) => {
  const { err } = action

  return {
    ...state,
    error: err,
    isLoading: false,
  }
}

reducers[types.FETCH_THEME_BACKGROUND_REQUEST] = state => {
  return {
    ...state,
    isLoading: true,
  }
}

reducers[types.FETCH_THEME_BACKGROUND_SUCCESS] = (state, action) => {
  const { url, backgroundId } = action

  return {
    ...state,
    isLoading: false,
    currentBackgroundUrl: url,
    currentBackgroundId: backgroundId,
  }
}

reducers[types.FETCH_THEME_BACKGROUND_FAILURE] = (state, action) => {
  const { err } = action

  return {
    ...state,
    error: err,
    isLoading: false,
  }
}

export default function reducer(state = defaultState, action) {
  const handler = reducers[action.type]
  if (handler) return handler(state, action)
  return state
}
