import { css } from '@emotion/react'

const avatar = theme => css`
  width: ${theme.spacing[10]};
  height: ${theme.spacing[10]};
`

const dropdownBtn = theme => css`
  padding-left: ${theme.spacing[5]};

  &&& > * {
    padding-right: ${theme.spacing[8]};
    svg {
      margin-right: -${theme.spacing[3]};
    }
  }
`

export const styles = {
  avatar,
  dropdownBtn,
}
