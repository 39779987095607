import React, { useCallback, useState, useMemo, useEffect } from 'react'
import cn from 'classnames'
import Dropdown from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import Field from '@groovehq/internal-design-system/lib/components/Field/Field'
import FieldWithVariables from '@groovehq/internal-design-system/lib/components/FieldWithVariables/FieldWithVariables'
import NumberField from '@groovehq/internal-design-system/lib/components/NumberField/NumberField'
import Textarea from '@groovehq/internal-design-system/lib/components/Textarea/Textarea'
import { TagDropdownWithDrawerCreate } from 'subapps/settings/components/TagDropdown'
import TagsDropdown from 'subapps/settings/components/TagsDropdown'
import DateTimeInput from 'shared/components/ui/DateTimeInput'
import { SUI } from 'shared/ui'
import { dateToNearestHalfHour, strftime, toDate } from 'util/date'
import { areArraysEqual } from 'util/arrays'
import DropdownMenu from '../DropdownMenu'
import EmailReply from './EmailReply'
import TeammatesDropdown from '../TeammatesDropdown'
import StatusesDropdown from '../StatusesDropdown'
import MailboxesDropdown from '../MailboxesDropdown'
import TicketSourcesDropdown from '../TicketSourcesDropdown'

import { styles } from './styles'

export default function RuleValue({
  className,
  label,
  value: inputValue,
  onDropdownSelect,
  selectedValue,
  onInputBlur,
  onInputChange,
  errorMessage,
  valueKey,
  dataType = 'AUTO',
  paramType,
  itemKey,
  actionType,
  dataOperator,
  hasMinWidth,
  allowSelectAssigned,
  allowSelectCurrentUser,
  isAutomationItemFocused,
  subjectVariableTypes,
}) {
  const [isInputFocused, setIsInputFocused] = useState(false)
  const [selectedValues, setRawSelectedValues] = useState(
    valueKey?.split(',') || []
  )
  // Don't show error:
  // when the field is focused
  // if the value is empty and the item container is focused
  const showErrorIfExists = isInputFocused
    ? false
    : !valueKey && !isAutomationItemFocused

  const setSelectedValues = useCallback(newSelectedValues => {
    setRawSelectedValues(previousSelectedValues => {
      if (!areArraysEqual(previousSelectedValues, newSelectedValues)) {
        return newSelectedValues
      }
      return previousSelectedValues
    })
  }, [])

  useEffect(
    () => {
      setSelectedValues(valueKey?.split(',') || [])
    },
    [valueKey, setSelectedValues]
  )

  const onTagSelect = useCallback(
    tag => {
      onDropdownSelect(tag && tag[paramType])
    },
    [onDropdownSelect, paramType]
  )

  const onTagsSelect = useCallback(
    tagIds => {
      if (JSON.stringify(tagIds) === JSON.stringify(selectedValues)) {
        return
      }

      if (tagIds.length) {
        const ids = tagIds.filter(id => !!id)

        if (ids.length) {
          onDropdownSelect(ids.join(','))
        }

        return
      }

      if (selectedValues.length) {
        onDropdownSelect(null)
      }
    },
    [onDropdownSelect, selectedValues]
  )

  const handleFocus = useCallback(() => {
    setIsInputFocused(true)
  }, [])

  const handleBlur = useCallback(
    e => {
      setIsInputFocused(false)
      onInputBlur(e)
    },
    [onInputBlur]
  )

  const handleDateChange = useCallback(
    date => {
      const formattedDate = strftime('%Y-%m-%d', date)
      onDropdownSelect(formattedDate)
    },
    [onDropdownSelect]
  )

  const handleTimeChange = useCallback(
    date => {
      const formattedTime = strftime('%HO:%M', date)
      onDropdownSelect(formattedTime)
    },
    [onDropdownSelect]
  )

  const onMultiSelect = useCallback(
    inputIds => {
      const ids = inputIds.filter(id => !!id)
      onDropdownSelect(ids.length ? ids.join(',') : null)
    },
    [onDropdownSelect]
  )

  const handleClickField = useCallback(e => {
    e.stopPropagation()
  }, [])

  const value = useMemo(
    () => {
      if (Array.isArray(inputValue)) {
        return inputValue.filter(
          v => !(v.deprecated === true && !selectedValues.includes(v.value))
        )
      }
      return inputValue
    },
    [inputValue, selectedValues]
  )

  if (dataType === 'TAG') {
    return (
      <TagDropdownWithDrawerCreate
        tagId={paramType === 'id' && valueKey}
        tagName={paramType === 'name' && valueKey}
        onTagSelect={onTagSelect}
        size="small"
        hasMinWidth={hasMinWidth}
      />
    )
  }

  if (dataType === 'TAGS') {
    return (
      <TagsDropdown
        fieldType={paramType}
        onSelectedIdsChange={onTagsSelect}
        selectedIds={selectedValues}
        value={selectedValues}
        setValue={onTagsSelect}
      />
    )
  }

  if (dataType === 'TEAMMATES') {
    return (
      <TeammatesDropdown
        onSelectedIdsChange={onMultiSelect}
        selectedIds={selectedValues}
        value={selectedValues}
        setValue={onMultiSelect}
        operator={dataOperator}
        allowSelectAssigned={allowSelectAssigned}
        allowSelectCurrentUser={allowSelectCurrentUser}
      />
    )
  }

  if (dataType === 'STATUSES') {
    return (
      <StatusesDropdown
        onSelectedIdsChange={onMultiSelect}
        selectedIds={selectedValues}
        value={selectedValues}
        setValue={onMultiSelect}
      />
    )
  }

  if (dataType === 'MAILBOXES') {
    return (
      <MailboxesDropdown
        onSelectedIdsChange={onMultiSelect}
        selectedIds={selectedValues}
        value={selectedValues}
        setValue={onMultiSelect}
      />
    )
  }

  if (dataType === 'TICKETSOURCES') {
    return (
      <TicketSourcesDropdown
        onSelectedIdsChange={onMultiSelect}
        selectedIds={selectedValues}
        value={selectedValues}
        setValue={onMultiSelect}
      />
    )
  }

  if (dataType === 'DATE') {
    return (
      <SUI css={styles.dateField} onClick={handleClickField}>
        <DateTimeInput
          onChange={handleDateChange}
          setDefaultValue={handleDateChange}
          justDate
          value={toDate(valueKey)}
          key={`${itemKey}${dataType}`}
        />
      </SUI>
    )
  }

  if (dataType === 'TIME') {
    return (
      <SUI css={styles.dateField} onClick={handleClickField}>
        <DateTimeInput
          onChange={handleTimeChange}
          setDefaultValue={handleTimeChange}
          justTime
          value={dateToNearestHalfHour(toDate(valueKey))}
          key={`${itemKey}${dataType}`}
        />
      </SUI>
    )
  }

  if (['AUTO', 'LIST'].includes(dataType) && Array.isArray(value)) {
    return (
      <div css={styles.dropdownContainer}>
        <Dropdown
          overlay={<DropdownMenu data={value} />}
          onSelect={onDropdownSelect}
          selectedKey={valueKey}
          hasMinWidth={hasMinWidth}
        >
          <Dropdown.Button size="small" css={styles.dropdownBtn}>
            {selectedValue || 'Select...'}
          </Dropdown.Button>
        </Dropdown>
      </div>
    )
  }
  if (['AUTO', 'TEXT', 'EMAIL'].includes(dataType)) {
    return (
      <Field
        label={label}
        name={itemKey?.toString()}
        value={valueKey || ''}
        onChange={onInputChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        css={styles.field}
        validateStatus={errorMessage && showErrorIfExists ? 'error' : undefined}
        help={errorMessage && showErrorIfExists ? errorMessage : undefined}
        placeholder={dataType === 'EMAIL' ? 'Email address' : null}
        autoFocus
        className={cn('automation-full-field', className, {
          error: !!errorMessage,
        })}
      />
    )
  }

  if (dataType === 'SUBJECT') {
    return (
      <FieldWithVariables
        name={itemKey?.toString()}
        value={valueKey || ''}
        onChange={onInputChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        css={styles.field}
        validateStatus={errorMessage && showErrorIfExists ? 'error' : undefined}
        help={errorMessage && showErrorIfExists ? errorMessage : undefined}
        placeholder={null}
        autoFocus
        className={cn('grui mt-8 automation-full-field', {
          error: !!errorMessage,
        })}
        fieldContainerClassName="grui mw-100"
        variableList={subjectVariableTypes}
        label="Subject"
        tooltipPosition="right"
      />
    )
  }

  if (dataType === 'REPLY_AGENT') {
    return (
      <EmailReply
        onDropdownSelect={onDropdownSelect}
        valueKey={valueKey}
        actionType={actionType}
        className="automation-full-field"
      />
    )
  }

  if (dataType === 'NUMBER') {
    return (
      <NumberField
        name={itemKey?.toString()}
        css={styles.field}
        value={valueKey || ''}
        onChange={onInputChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        min={0}
        validateStatus={errorMessage && showErrorIfExists ? 'error' : undefined}
        help={errorMessage && showErrorIfExists ? errorMessage : undefined}
        autoFocus
        className={cn('automation-full-field', {
          error: !!errorMessage,
        })}
      />
    )
  }

  if (dataType === 'TEXTAREA') {
    return (
      <Textarea
        name={itemKey?.toString()}
        value={valueKey || ''}
        onChange={onInputChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        css={styles.field}
        validateStatus={errorMessage && showErrorIfExists ? 'error' : undefined}
        help={errorMessage && showErrorIfExists ? errorMessage : undefined}
        autoFocus
        autoSize
        className={cn('automation-full-field', {
          error: !!errorMessage,
        })}
      />
    )
  }

  return null
}
