import { useCallback, useEffect, useState } from 'react'

const useShowHeaderShadow = visible => {
  const [shouldShowHeaderShadow, setShouldHeaderShadow] = useState(false)
  const handleScroll = useCallback(scrollTop => {
    setShouldHeaderShadow(scrollTop > 40)
  }, [])

  useEffect(
    () => {
      if (!visible) {
        setShouldHeaderShadow(false)
      }
    },
    [visible]
  )

  return {
    shouldShowHeaderShadow,
    handleScroll,
  }
}

export default useShowHeaderShadow
