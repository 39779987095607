import React from 'react'
import { propFunc } from 'util/functions'
import { Button } from 'util/ui'
import Modal from 'shared/components/ui/Modal'

export default function IntegrationRemoveModal({
  name,
  id,
  trigger,
  triggerDisabled,
  onConfirm,
  buttonLabel = 'Disconnect',
}) {
  return (
    <Modal
      header={`Remove ${name} integration`}
      content="Are you sure you want to do this?"
      onConfirm={propFunc(onConfirm, id)}
      dangerous
      trigger={
        trigger || (
          <Button negative disabled={triggerDisabled}>
            {buttonLabel}
          </Button>
        )
      }
    />
  )
}
