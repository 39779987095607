import * as types from 'subapps/kb/actions'
import { emptyObj, omit } from 'util/objects'

const defaultState = {
  bootstrapped: false,
  byId: {},
  ids: [],
  isKbsLoading: false,
  isLoading: false,
  isSaving: false,
  error: null,
  isLoaded: false,
}

const reducers = {}

reducers[types.BOOTSTRAP] = state => {
  return {
    ...state,
    bootstrapped: true,
  }
}

reducers[types.FETCH_KNOWLEDGE_BASES_REQUEST] = state => {
  return {
    ...state,
    isKbsLoading: true,
    isLoaded: false,
  }
}

reducers[types.FETCH_KNOWLEDGE_BASES_SUCCESS] = (state, action) => {
  const { data } = action

  const newById = {}
  const newIds = []

  data.forEach(kb => {
    newById[kb.id] = kb
    newIds.push(kb.id)
  })

  return {
    ...state,
    byId: newById,
    ids: newIds,
    isKbsLoading: false,
    isLoaded: true,
  }
}

reducers[types.FETCH_KNOWLEDGE_BASES_FAILURE] = state => {
  return {
    ...state,
    isKbsLoading: false,
    isLoaded: false,
  }
}

reducers[types.FETCH_KNOWLEDGE_BASE_REQUEST] = state => {
  return {
    ...state,
    isLoading: true,
  }
}

reducers[types.FETCH_KNOWLEDGE_BASE_SUCCESS] = (state, action) => {
  const { knowledgeBase } = action.data
  const existingKb = state.byId[knowledgeBase.id] || emptyObj

  return {
    ...state,
    byId: {
      ...state.byId,
      ...{
        [knowledgeBase.id]: {
          ...existingKb,
          ...knowledgeBase,
        },
      },
    },
    isLoading: false,
  }
}

reducers[types.FETCH_KNOWLEDGE_BASE_FAILURE] = state => {
  return {
    ...state,
    isLoading: false,
  }
}

reducers[types.CREATE_KNOWLEDGE_BASE_REQUEST] = state => {
  return {
    ...state,
    isSaving: true,
    error: false,
  }
}

reducers[types.CREATE_KNOWLEDGE_BASE_SUCCESS] = (state, action) => {
  const { createdKnowledgeBase } = action.data

  return {
    ...state,
    ids: [createdKnowledgeBase.id, ...state.ids],
    byId: {
      ...state.byId,
      [createdKnowledgeBase.id]: createdKnowledgeBase,
    },
    isSaving: false,
    error: null,
    lastCreatedKbId: createdKnowledgeBase.id,
  }
}

reducers[types.CREATE_KNOWLEDGE_BASE_FAILURE] = (state, action) => {
  const { err } = action.data

  return {
    ...state,
    isSaving: false,
    error: err,
  }
}

reducers[types.UPDATE_KNOWLEDGE_BASE_SUCCESS] = (state, action) => {
  const { updateKnowledgeBase, previousPrimaryKnowledgeBaseId } = action.payload
  const existingKb = state.byId[updateKnowledgeBase.id] || emptyObj
  const byId = {
    ...state.byId,
    [updateKnowledgeBase.id]: {
      ...existingKb,
      ...updateKnowledgeBase,
    },
  }

  if (
    state.byId[previousPrimaryKnowledgeBaseId] &&
    previousPrimaryKnowledgeBaseId !== updateKnowledgeBase.id
  ) {
    byId[previousPrimaryKnowledgeBaseId] = {
      ...state.byId[previousPrimaryKnowledgeBaseId],
      primary: false,
    }
  }

  return {
    ...state,
    byId,
    isSaving: false,
  }
}

reducers[types.REVERT_KNOWLEDGE_BASE_THEME] = (state, action) => {
  const { knowledgeBaseId } = action.data
  const existingKb = state.byId[knowledgeBaseId] || emptyObj

  return {
    ...state,
    byId: {
      ...state.byId,
      [knowledgeBaseId]: {
        ...existingKb,
        themeId: existingKb.publishedThemeId,
      },
    },
  }
}

reducers[types.DELETE_KNOWLEDGE_BASE_SUCCESS] = (state, action) => {
  const { knowledgeBaseId } = action.data

  return {
    ...state,
    byId: omit([knowledgeBaseId], state.byId),
    ids: state.ids.filter(id => id !== knowledgeBaseId),
  }
}

reducers[types.DELETE_KNOWLEDGE_BASE_FAILURE] = (state, action) => {
  const { err } = action.data

  return {
    ...state,
    error: err,
  }
}

export default function reducer(state = defaultState, action) {
  const handler = reducers[action.type]
  if (handler) return handler(state, action)
  return state
}
