import React, { PureComponent } from 'react'
import Scroller from 'components/Scroller'
import { isFunction } from 'util/functions'

export default function asList(
  WrappedComponent,
  { trackingMode = 'object', isItemEqual } = {}
) {
  class AsList extends PureComponent {
    constructor(props) {
      super(props)
      if (isFunction(isItemEqual)) {
        // If the user has passed in a custom item compare function,
        // override the instance on this class with the passed int value
        this.isItemEqual = isItemEqual.bind(this)
      }
      this.itemRefs = new WeakMap()
      this.state = {
        mouseX: null,
        mouseY: null,
        focusedItem: null,
        isFocused: false,
      }
      this.updateAutoFocusFirstStateIfRequired(props, true)
      if (props.items && props.items.length > 0) {
        props.items.forEach(i => this.itemRefs.set(i, React.createRef()))
      }
    }

    componentDidMount() {
      this.triggerAutoFocusIfRequired()
    }

    componentWillReceiveProps(nextProps) {
      const { items } = this.props
      const { items: nextItems } = nextProps
      if (items !== nextItems) {
        items.forEach(i => this.itemRefs.delete(i))
        nextItems.forEach(i => this.itemRefs.set(i, React.createRef()))
        this.setState({
          mouseX: null,
          mouseY: null,
          focusedItem: null,
          isFocused: false,
        })
      }
      this.updateAutoFocusFirstStateIfRequired(nextProps)
    }

    componentDidUpdate() {
      this.triggerAutoFocusIfRequired()
    }

    // eslint-disable-next-line react/sort-comp
    updateAutoFocusFirstStateIfRequired = (props, mutateState = false) => {
      const { autoFocusFirst, items } = props
      const { focusedItem } = this.state
      if (autoFocusFirst && !focusedItem && items && items.length > 0) {
        this.requireFocusTrigger = true
        if (mutateState) {
          this.state = {
            ...this.state,
            focusedItem: items[0],
          }
        } else {
          this.setState({
            focusedItem: items[0],
          })
        }
      }
    }

    checkMouseMoved = evt => {
      const { lastClientX, lastClientY } = this.lastMousePosition || {}
      const { clientX, clientY } = evt || {}
      const mouseMoved = clientX !== lastClientX && clientY !== lastClientY
      if (mouseMoved) {
        this.lastMousePosition = { lastClientX: clientX, lastClientY: clientY }
        return true
      }
      return false
    }

    triggerAutoFocusIfRequired = () => {
      const { onItemFocus } = this.props
      const { focusedItem } = this.state
      if (this.requireFocusTrigger) {
        this.requireFocusTrigger = false
        this.scrollToFocusedListItem(focusedItem)
        if (isFunction(onItemFocus)) {
          onItemFocus(focusedItem, false, null)
        }
      }
    }

    focusFirstItem = () => {
      const { items } = this.props
      if (items && items.length > 0) this.focusItem(items[0])
    }

    focusItem = (item, focusedViaMouse = false, evt = null) => {
      const focusedItem = this.getFocusedItem()

      if (item && !this.isItemEqual(item, focusedItem)) {
        this.checkAndFocus({ focusedItem: item }, focusedViaMouse, evt)
      }
    }

    checkAndFocus = (newState, focusedViaMouse = false, evt) => {
      const { onItemFocus } = this.props
      if (focusedViaMouse) {
        // Dont focus this item if the mouse didnt move (i.e. a scroll event
        // caused the onMouseOver to fire)
        if (!this.checkMouseMoved(evt)) return
      } else {
        this.scrollToFocusedListItem(newState.focusedItem)
      }
      this.setState({ ...newState, focusedViaMouse })
      if (isFunction(onItemFocus)) {
        onItemFocus(newState.focusedItem, focusedViaMouse, evt)
      }
    }

    getFocusedItem = () => {
      const { items = [] } = this.props
      const { focusedItem } = this.state
      return items.find(i => this.isItemEqual(i, focusedItem))
    }

    isItemEqual = (a, b) => {
      if (a && b) {
        if (trackingMode === 'id') {
          return a.id === b.id
        }
        return a === b
      }
      return false
    }

    getStyle = () => {
      const { scrollerHeight } = this.props
      if (scrollerHeight) {
        return {
          maxHeight: scrollerHeight,
        }
      }
      return {}
    }

    scrollToFocusedListItem(focusedItem) {
      const focusedItemRef = this.getItemRef(focusedItem)
      if (focusedItemRef && focusedItemRef.current) {
        const el = focusedItemRef.current
        const { scrollerAPI } = this

        if (scrollerAPI) {
          return scrollerAPI.scrollElementIntoViewIfNeeded(el)
        }
      }

      return false
    }

    getItemRef = item => {
      return this.itemRefs.get(item)
    }

    handleScrollInit = ({ scrollerAPI }) => {
      this.scrollerAPI = scrollerAPI
    }

    handleOnMouseMove = () => {
      const { isFocused } = this.state
      if (!isFocused) this.setState({ isFocused: true }, this.handleFocus)
    }

    handleOnMouseOut = () => {
      const { isFocused } = this.state
      if (isFocused) this.setState({ isFocused: false }, this.handleBlur)
    }

    handleFocus = () => {
      const { onFocus } = this.props
      if (isFunction(onFocus)) onFocus()
    }

    handleBlur = () => {
      const { onBlur } = this.props
      if (isFunction(onBlur)) onBlur()
    }

    render() {
      const {
        scrollerClassName,
        scrollerHeight,
        scrollerProps,
        forwardedRef,
        ...rest
      } = this.props

      return (
        <Scroller
          {...scrollerProps}
          className={scrollerClassName}
          style={this.getStyle()}
          onScrollInit={this.handleScrollInit}
          onMouseMove={this.handleOnMouseMove}
          onMouseOut={this.handleOnMouseOut}
          ref={forwardedRef}
        >
          <WrappedComponent
            {...rest}
            focusedItem={this.getFocusedItem()}
            focusedItemIcon="keyboard-return"
            getItemRef={this.getItemRef}
            onItemFocus={this.focusItem}
          />
        </Scroller>
      )
    }
  }

  return React.forwardRef((props, ref) => {
    return <AsList {...props} forwardedRef={ref} />
  })
}
