import { css } from '@emotion/react'

const container = theme => css`
  background-color: ${theme.color.info['50']};
  border-radius: ${theme.spacing[7]};
  color: ${theme.color.info['900']};
  font-size: ${theme.fontSize.x2large};

  strong {
    font-weight: ${theme.fontWeight.semibold};
  }
`

const btn = theme => css`
  border-radius: 9999px;
  font-weight: ${theme.fontWeight.semibold};
  padding-left: ${theme.spacing[12]};
  padding-right: ${theme.spacing[12]};

  &&& + * {
    color: inherit;
    border-color: currentColor;
  }
`

const imgWrapper = theme => css`
  img {
    border-radius: ${theme.spacing[7]};
  }
`

const list = theme => css`
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.spacing[2]} ${theme.spacing[5]};

  svg {
    width: ${theme.spacing[12]};
    height: ${theme.spacing[12]};
    flex-shrink: 0;
  }
`

const main = css`
  max-width: 326px;
`

const buttonGroup = ({ gap }) => css`
  gap: ${gap}px;
`

const medium = theme => css`
  ${container(theme)};
  gap: ${theme.spacing[11]} ${theme.spacing[8]};
  padding: ${theme.spacing[7]} ${theme.spacing[10]} ${theme.spacing[10]}
    ${theme.spacing[12]};
`

const small = theme => css`
  ${container(theme)};
  font-size: ${theme.fontSize.base};
  gap: ${theme.spacing[5]};

  && {
    padding: ${theme.spacing[7]} ${theme.spacing[11]};
    align-items: center;
  }

  & > *:first-of-type {
    margin-bottom: ${theme.spacing[3]};
  }

  h5 {
    font-size: ${theme.fontSize.large};
  }

  button,
  a {
    padding-left: ${theme.spacing[10]};
    padding-right: ${theme.spacing[10]};
  }
`

const sizes = {
  small,
  medium,
}

export const styles = {
  container,
  btn,
  imgWrapper,
  list,
  main,
  buttonGroup,
  sizes,
}
