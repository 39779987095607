import { css } from '@emotion/react'
import { styles as planLimitationCardStyles } from 'subapps/settings/components/PlanLimitationCard/styles'

const container = theme => css`
  background-color: ${theme.color.info['50']};
  border-radius: ${theme.spacing[7]};
  color: ${theme.color.info['900']};
  // Support updating styles on resizing the panel container
  container: limit-search-card-container / inline-size;
  font-size: ${theme.fontSize.base};
`

const main = theme => css`
  @container limit-search-card-container (min-width: 400px) {
    & {
      font-size: ${theme.fontSize.x2large};
    }
  }
`

const footer = theme => css`
  gap: ${theme.spacing[8]} ${theme.spacing[5]};

  @supports not (container-type: inline-size) {
    justify-content: center;
    flex-wrap: wrap;
  }
`

const button = theme => css`
  ${planLimitationCardStyles.btn(theme)};

  @container limit-search-card-container (max-width: 314px) {
    & {
      padding-left: ${theme.spacing[10]};
      padding-right: ${theme.spacing[10]};
      font-weight: ${theme.fontWeight.medium};
      font-size: ${theme.fontSize.large};
    }
  }

  @container limit-search-card-container (max-width: 280px) {
    & {
      padding-left: ${theme.spacing[7]};
      padding-right: ${theme.spacing[7]};
    }
  }

  @container limit-search-card-container (max-width: 230px) {
    & {
      padding-left: ${theme.spacing[12]};
      padding-right: ${theme.spacing[12]};
    }
    &.chat-to-sales-btn {
      display: none;
    }
  }
`

export const styles = {
  container,
  main,
  footer,
  button,
}
