import React, { useCallback } from 'react'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import {
  text,
  paragraph,
} from '@groovehq/internal-design-system/lib/styles/elements'
import { useDrawer } from 'ducks/drawers/hooks'
import {
  DRAWER_TYPE_INTEGRATION_RECHARGE_INSTALL,
  DRAWER_TYPE_INTEGRATION_RECHARGE_STORE_UPDATE,
} from 'ducks/drawers/types'
import MessageCard from '@groovehq/internal-design-system/lib/components/MessageCard/MessageCard'
import StoresDataTable from 'subapps/settings/components/drawers/integrations/common/StoresDataTable'
import useColumns from './useColumns'

const RechargeConfigureGeneral = ({ providerId, internalAction }) => {
  const columns = useColumns()

  const { openDrawer: openAddStoreDrawer } = useDrawer(
    DRAWER_TYPE_INTEGRATION_RECHARGE_INSTALL
  )

  const { openDrawer: openEditStore } = useDrawer(
    DRAWER_TYPE_INTEGRATION_RECHARGE_STORE_UPDATE
  )

  const onAddStore = useCallback(
    () => {
      openAddStoreDrawer(providerId)
    },
    [openAddStoreDrawer, providerId]
  )

  const onEditStore = useCallback(
    id => {
      openEditStore(id)
    },
    [openEditStore]
  )

  return (
    <>
      {internalAction === 'installed' && (
        <MessageCard type="info" closeable>
          Syncing customers is in progress.
        </MessageCard>
      )}
      <p
        className="grui mt-10 mb-12"
        css={[
          paragraph.styles.preflight,
          text.styles.textNormal,
          text.styles.textDark,
        ]}
      >
        Configure which of your stores you would like to display and manage
        within your Groove account.
      </p>
      <StoresDataTable
        providerId={providerId}
        columns={columns}
        onEditStore={onEditStore}
      />
      <div className="grui mt-11">
        <Button onClick={onAddStore} type="secondary" size="small">
          Add a store
        </Button>
      </div>
    </>
  )
}

export default RechargeConfigureGeneral
