import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'

// UserSelection component uses these styles too.
const dropdownContainer = css`
  & > * {
    width: 100%;
  }
`

const dropdownBtn = css`
  min-width: 100%;
`

const mailboxUsedLabel = theme => css`
  margin-top: ${rem(8)};
  color: ${theme.color.warning['500']};
`

export const styles = {
  dropdownContainer,
  dropdownBtn,
  mailboxUsedLabel,
}
