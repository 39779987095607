import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'

const dropdownContainer = theme => css`
  width: ${rem(340)};
  font-size: ${theme.fontSize.large};
  color: ${theme.color.info['900']};
  padding-bottom: 0;

  header {
    [role='separator'] {
      margin-bottom: 0;
    }
  }
`

const container = css`
  max-height: 40vh;

  *:focus {
    outline: none;
  }
`

const item = theme => css`
  display: flex;
  justify-content: space-between;
  min-height: ${rem(30)};
  padding: ${rem(4)} ${rem(16)};
  cursor: pointer;
  &.selected {
    color: ${theme.color.dropdownMenu.activeText};
  }
  &.active:not(.selected) {
    background-color: ${theme.color.dropdownMenu.itemBgHover};
  }
  &.active .enter-icon {
    opacity: 1;
    color: ${theme.color.dropdown.icon};
  }
`

const disabledItem = theme => css`
  color: rgba(${theme.color.monochrome.black_rgba}, 0.5);
  cursor: default;
`

const header = theme => css`
  padding: ${rem(8)} ${rem(10)} 0;
  margin-bottom: ${rem(-2)};
  display: flex;
  align-items: center;
  font-weight: ${theme.fontWeight.medium};
  svg {
    height: ${rem(34)};
    width: ${rem(34)};
    margin-right: ${rem(0)};
  }
`

const tooltip = theme => css`
  max-width: ${rem(177)};
  z-index: 2000;
  font-weight: ${theme.fontWeight.medium};
  line-height: ${theme.lineHeight.leadingSnug};
  background-color: ${theme.color.info['900']};
`

const tooltipIcon = theme => css`
  width: ${rem(16)};
  height: ${rem(16)};
  background-color: ${theme.color.monochrome.medium};
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: ${theme.fontWeight.medium};
  font-style: italic;
  font-size: ${theme.fontSize.small};
  margin-left: ${rem(6)};
  color: ${theme.color.info['900']};
`

const search = css`
  &&& input {
    color: inherit;
    font-size: inherit;
    &::placeholder {
      color: inherit;
      opacity: 0.5;
    }
  }
`

const showHeaderShadow = theme => css`
  header {
    [role='separator'] {
      opacity: 1;
      box-shadow: 0 2px 4px 0 rgba(${theme.color.monochrome.black_rgba}, 0.1);
    }
  }
`

const upgradeBtn = css`
  border-radius: 9999px;
`

const limitedTooltip = theme => css`
  background-color: ${theme.color.info['900']};
  border-radius: ${rem(6)};
  font-weight: ${theme.fontWeight.medium};
`

const noMinWidth = css`
  min-width: 0;
`

export const styles = {
  dropdownContainer,
  container,
  item,
  disabledItem,
  header,
  tooltip,
  tooltipIcon,
  search,
  showHeaderShadow,
  upgradeBtn,
  limitedTooltip,
  noMinWidth,
}
