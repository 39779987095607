import { createSelector } from 'reselect'
import { selectCurrentPath } from 'selectors/location'

const base = state => state.page || {}

export const selectIsOnKbLandingPage = createSelector(
  base,
  page => page.subapp === 'kb' && page.componentName === 'LandingPage'
)

export const selectIsOnKbCreatePage = createSelector(
  base,
  page => page.subapp === 'kb' && page.componentName === 'CreatePage'
)

export const selectIsOnKbUpgradePage = createSelector(
  base,
  page => page.subapp === 'kb' && page.componentName === 'UpgradePage'
)

export const selectIsOnKbUpgradeProPage = createSelector(
  base,
  page => page.subapp === 'kb' && page.componentName === 'ProUpgradePage'
)

export const selectIsOnKbDashboardPage = createSelector(
  base,
  page => page.subapp === 'kb' && page.componentName === 'DashboardPage'
)

export const selectIsOnKbThemePage = createSelector(
  base,
  page => page.subapp === 'kb' && page.componentName === 'ThemePage'
)

export const selectIsOnKbArticlesPage = createSelector(
  base,
  page => page.subapp === 'kb' && page.componentName === 'ArticlesPage'
)

export const selectIsOnKbCategoriesPage = createSelector(
  base,
  page => page.subapp === 'kb' && page.componentName === 'CategoriesPage'
)

export const selectIsOnKbSettingsPage = createSelector(
  base,
  page => page.subapp === 'kb' && page.componentName === 'SettingsPage'
)

export const selectIsOnKbArticleCreatePage = createSelector(
  selectCurrentPath,
  path => path.match(/\/kb\/[0-9]+\/articles\/create/)
)

export const selectIsOnKbArticleEditPage = createSelector(
  selectCurrentPath,
  path => path.match(/\/kb\/[0-9]+\/articles\/edit\/[0-9]+/)
)

export const selectIsOnKbAnyArticlesPage = createSelector(
  selectIsOnKbArticlesPage,
  selectIsOnKbArticleCreatePage,
  selectIsOnKbArticleEditPage,
  (list, create, edit) => !!(list || create || edit)
)

export const selectIsOnKbCategoryCreatePage = createSelector(
  selectCurrentPath,
  path => path.match(/\/kb\/[0-9]+\/categories\/create/)
)

export const selectIsOnKbCategoryEditPage = createSelector(
  selectCurrentPath,
  path => path.match(/\/kb\/[0-9]+\/categories\/edit\/[0-9]+/)
)

export const selectIsOnKbAnyCategoriesPage = createSelector(
  selectIsOnKbCategoriesPage,
  selectIsOnKbCategoryCreatePage,
  selectIsOnKbCategoryEditPage,
  (list, create, edit) => list || create || edit
)
