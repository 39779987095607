import { deepCopy } from 'util/objects'
import debug from 'util/debug'
import md5 from 'util/md5'

export const history = { entries: [] }
window.actionHistory = history

const IGNORED_ACTIONS = {
  'collisions/UPDATE_AGENT_TICKET_COLLISION_STATUS': true,
  TRIGGER_EMBEDDABLE_CARD_SUCCESS: true,
  FETCH_EMBEDDABLE_CARD_CONTENT_RECEIVED: true,
  FETCH_EMBEDDABLE_CARD_CONTENT_FETCHING: true,
  MARK_FETCHING_STATUS: true,
  UNDO_SEND_TIMER_TICK: true,
}

function shouldCaptureAction(action) {
  return !IGNORED_ACTIONS[action.type] && debug.enabled
}

const searchHashStorage = {}
function extractSearches(state) {
  const byQueryId = state.search.byQueryId
  const rows = []
  Object.keys(byQueryId).forEach(id => {
    const payload = byQueryId[id]
    // eslint-disable-next-line prefer-spread
    const ticketIds = [].concat.apply([], Object.values(payload.pages || []))
    const row = [
      id,
      ticketIds,
      payload.sortedIds,
      payload.totalCount,
      payload.totalPages,
    ]
    const hash = md5(JSON.stringify(row))

    if (hash !== searchHashStorage[id]) {
      rows.push(row)
      searchHashStorage[id] = hash
    }
  })
  return rows
}

const getCircularReplacer = () => {
  const seen = new WeakSet()
  return (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return null
      }
      seen.add(value)
    }
    return value
  }
}

export default ({ getState }) => next => action => {
  const returnedValue = next(action)
  if (shouldCaptureAction(action)) {
    const stateAfter = getState()
    const searches = extractSearches(stateAfter)
    const time = new Date().getTime()
    history.entries.push(
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Cyclic_object_value#Examples
      deepCopy(
        { time, action, searches },
        { stringify: { replacer: getCircularReplacer() } }
      )
    )
    history.entries = history.entries.slice(-200)
  }
  return returnedValue
}
