import { useCallback, useMemo, useState } from 'react'
import { css } from '@emotion/react'
import Dropdown from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import { styles as DropdownStyles } from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown.styles'
import Search from '@groovehq/internal-design-system/lib/components/Search/Search'
import { levenshteinDistance } from 'util/strings'

const { Menu, Button } = Dropdown

const searchStyle = css`
  min-width: 100%;
`

const SearchableSelect = ({
  data,
  value,
  onChange,
  onBlur,
  size,
  placeholder = 'Select item...',
  searchPlaceholder = 'Search...',
  overlayMinWidth,
}) => {
  const [filteredData, setFilteredData] = useState(data)
  const handleChangeSearch = useCallback(
    ({ target }) => {
      const term = target.value.toLowerCase()
      if (term) {
        const levenCache = {}
        setFilteredData(
          data
            .filter(({ name: itemName, searchValue }) => {
              const s = searchValue || itemName
              if (levenCache[s] === undefined) {
                const lenSum = s.length + term.length
                const distance = levenshteinDistance(s, term)
                const ratio = (lenSum - distance) / lenSum
                const exact = s.indexOf(term) > -1
                levenCache[s] = {
                  distance,
                  ratio,
                  exact,
                }
              }
              return levenCache[s].exact || levenCache[s].ratio > 0.8
            })
            .sort((a, b) => {
              const cA = levenCache[a.name]
              const cB = levenCache[b.name]
              return (cA.exact ? 0 : cA.distance) - (cB.exact ? 0 : cB.distance)
            })
        )
      } else {
        setFilteredData(data)
      }
    },
    [setFilteredData, data]
  )

  const handleSelect = useCallback(
    (_, item) => {
      if (onChange) {
        onChange(item.key)
      }
    },
    [onChange]
  )

  const handleVisibilityChange = useCallback(
    visible => {
      setFilteredData(data)
      if (!visible && onBlur) {
        onBlur()
      }
    },
    [setFilteredData, data, onBlur]
  )

  const overlay = useMemo(
    () => {
      return (
        <div style={{ minWidth: overlayMinWidth || 'auto' }}>
          <div css={[DropdownStyles.item]}>
            <Search
              name="search"
              placeholder={searchPlaceholder}
              onChange={handleChangeSearch}
              css={searchStyle}
            />
          </div>
          <Menu>
            {filteredData.map(item => (
              <Menu.Item key={item.key} itemKey={item.key} item={item}>
                {item.name}
              </Menu.Item>
            ))}
          </Menu>
        </div>
      )
    },
    [filteredData, handleChangeSearch, searchPlaceholder, overlayMinWidth]
  )

  return (
    <Dropdown
      overlay={overlay}
      onSelect={handleSelect}
      onVisibleChange={handleVisibilityChange}
      strategy="fixed"
      hasMinWidth
    >
      <Button size={size}>
        {value
          ? data.find(item => item.key === value)?.name || 'Unknown'
          : placeholder}
      </Button>
    </Dropdown>
  )
}

export default SearchableSelect
