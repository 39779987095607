import {
  LOAD_ACCOUNT_REQUEST,
  LOAD_ACCOUNT_SUCCESS,
  LOAD_ACCOUNT_FAILURE,
} from './types'

const defaultCompletedPageState = {}

export default function completedReducer(
  state,
  { type, payload: { account: { subdomain } = {}, error } = {} }
) {
  switch (type) {
    case LOAD_ACCOUNT_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        subdomain: null,
        isLoaded: false,
      }
    case LOAD_ACCOUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        subdomain,
      }
    case LOAD_ACCOUNT_FAILURE:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        hasError: true,
        error,
      }
    default:
      return state || defaultCompletedPageState
  }
}
