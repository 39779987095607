import React, { useCallback, useEffect } from 'react'
import Drawer from '@groovehq/internal-design-system/lib/components/Drawer/UnmanagedDrawer'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import Field from '@groovehq/internal-design-system/lib/components/Field/Field'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {
  selectPendingCannedReplyCategoryById,
  selectIsLoadingCannedReplyCategories,
  selectHasLoadedReplyCategories,
  selectHasErrorCannedReplyCategories,
} from 'ducks/cannedReplies/selectors'
import {
  doFetchCannedReplyCategoriesV1,
  doSaveCannedReplyCategory,
  doCreateCannedReplyDraft,
} from 'ducks/cannedReplies/operations'
import { buildDrawerQueryParam } from 'ducks/drawers/util'
import { useRhfDirtyHold, useConfirmHoldsCallback } from 'util/dirtyHolds'

// keys should preferably match the object being stored in the entity store
// that way for updates, you can just spread the entity in reset() and not have to set each property individually
const FORM_SCHEMA = yup.object().shape({
  id: yup.string().nullable(),
  name: yup.string().required('Category name is required'),
})

export default function CannedReplyCategoryCreateEditDrawer({
  drawerId,
  drawerResourceId: cannedReplyCategoryId,
  previousDrawer,
  open,
  onClose,
  onExit,
  queryId,
  ...rest
}) {
  const dispatch = useDispatch()
  const isUpdate = !!cannedReplyCategoryId && cannedReplyCategoryId !== 'new'

  useEffect(
    () => {
      // need to check drawer open state before fetch dispatches
      // otherwise the dispatch methods in useEffect below will run immediately
      // after this Drawer component is instantiated via `useDrawer` (before openDrawer is even called)
      // Which is unnecessary double dispatch triggering
      if (!open) return

      dispatch(
        doFetchCannedReplyCategoriesV1({
          skipLoaded: false,
        })
      ).then(() => {
        if (isUpdate) {
          dispatch(doCreateCannedReplyDraft(cannedReplyCategoryId))
        }
      })
    },
    [dispatch, isUpdate, cannedReplyCategoryId, open]
  )

  const isLoading = useSelector(selectIsLoadingCannedReplyCategories)
  const hasLoaded = useSelector(selectHasLoadedReplyCategories)
  const hasError = useSelector(selectHasErrorCannedReplyCategories)
  // not needed for creates but cannot have conditional hooks
  // call anyway, it'll just return null
  const cannedReplyCategoryToUpdate = useSelector(state =>
    selectPendingCannedReplyCategoryById(state, cannedReplyCategoryId)
  )

  const isNoResultFound = isUpdate
    ? hasLoaded && !cannedReplyCategoryToUpdate
    : false

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isValid, isDirty },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(FORM_SCHEMA),
    defaultValues: {
      // defaultValue has to match yup form schema
      id: null,
      name: '',
    },
  })

  const { releaseHold } = useRhfDirtyHold(drawerId, control)

  useEffect(
    () => {
      return () => releaseHold()
    },
    [releaseHold]
  )

  useEffect(
    () => {
      if (isUpdate && open) {
        // load fields with template values
        reset({
          // just a spread here cos the needed keys should match the form schema
          ...cannedReplyCategoryToUpdate,
        })
        // When the drawer gets closed, clear the form
      } else if (!open) {
        reset()
      }
    },
    [cannedReplyCategoryToUpdate, isUpdate, reset, open]
  )

  const onSubmit = useCallback(
    async data => {
      const saveResponsePromise = dispatch(
        doSaveCannedReplyCategory(cannedReplyCategoryId, data, { queryId })
      )

      let closeParams = {}

      if (previousDrawer?.drawerId) {
        // This is sent out if this drawer is part of a multi-step process e.g. creating a category while creating a canned reply
        // Because doSaveCannedReplyCategory is returned after success from API,
        // Send the newly created category id so that the 'calling' drawer can automatically select it
        // This will be cleared by the last drawer to be closed in the stack

        // because this is needed for a previous drawer, we need to wait for response from API before closing drawer
        const { id: createdCannedReplyCategoryId } = await saveResponsePromise

        if (createdCannedReplyCategoryId) {
          closeParams = {
            ...closeParams,
            ...{
              query: {
                ...buildDrawerQueryParam(
                  previousDrawer.drawerId,
                  'drawerSetCategoryId',
                  createdCannedReplyCategoryId
                ),
              },
            },
          }
        }
      }
      releaseHold()
      onClose(closeParams)
    },
    [
      dispatch,
      onClose,
      queryId,
      cannedReplyCategoryId,
      previousDrawer?.drawerId,
      releaseHold,
    ]
  )

  const handleOnClose = useCallback(onClose, [onClose])
  const handleOnExit = useConfirmHoldsCallback(null, onExit, [onExit])

  const DrawerForm = useCallback(
    props => {
      return <form onSubmit={handleSubmit(onSubmit)} {...props} />
    },
    [handleSubmit, onSubmit]
  )

  return (
    <Drawer
      {...rest}
      open={open}
      onClose={handleOnExit}
      title={isUpdate ? 'Edit category' : 'New category'}
      isLoading={isLoading}
      isError={hasError}
      isNoResultFound={isNoResultFound}
      footer={
        <ModalBtns
          saveBtnDisabled={isLoading || hasError || !isValid || !isDirty}
          saveBtnText={isUpdate ? 'Save' : 'Create'}
          // type="submit" will automatically trigger the form submit event when clicked
          saveBtnHtmlType="submit"
          tertiaryBtnText="Cancel"
          onClickTertiaryBtn={handleOnClose}
        />
      }
      container={DrawerForm}
    >
      <div className="grui pt-10">
        <Field
          {...register('name')}
          label="Name"
          placeholder="Enter category name..."
          name="name"
          validateStatus={errors?.name ? 'error' : undefined}
          help={errors?.name?.message}
        />
      </div>
    </Drawer>
  )
}
