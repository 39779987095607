import React from 'react'
import { xCircleFill as CircleFill } from 'assets/icons/groove/v2'

const Feature = ({ children }) => {
  return (
    <li className="grui flex">
      <CircleFill className="grui mr-5 mt-2" />
      {children}
    </li>
  )
}

export default Feature
