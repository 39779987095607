import { oauthTokenSelector } from 'selectors/app/base'
import {
  fetchFacebookPages,
  updateFacebookPageSubscriptions,
} from 'subapps/onboarding/api/facebook'
import { CHANNEL_OVERVIEW_PAGE } from 'subapps/onboarding/pages'
import {
  FETCH_FACEBOOK_PAGES_REQUEST,
  FETCH_FACEBOOK_PAGES_SUCCESS,
  FETCH_FACEBOOK_PAGES_FAILURE,
  SUBSCRIBE_TO_FACEBOOK_PAGES_REQUEST,
  SUBSCRIBE_TO_FACEBOOK_PAGES_SUCCESS,
  SUBSCRIBE_TO_FACEBOOK_PAGES_FAILURE,
} from './types'

// NOTE (jscheel): This just assumes that it receives the input from a withFormFields
// form for now. We can abstract that later if we really care about it.
export function doUpdateFacebookPageSubscriptions(idsFromForm) {
  return (dispatch, getState) => {
    const state = getState()
    const token = oauthTokenSelector(state)
    const subscriptions = Object.keys(idsFromForm)
      .map(id => {
        const subscribed = idsFromForm[id]
        return {
          id,
          subscribed,
          destroyMailboxOnUnsubscribe: true,
        }
      })
      .filter(Boolean)
    dispatch({ type: SUBSCRIBE_TO_FACEBOOK_PAGES_REQUEST })
    return updateFacebookPageSubscriptions(token, subscriptions)
      .then(pages => {
        dispatch({
          type: SUBSCRIBE_TO_FACEBOOK_PAGES_SUCCESS,
          payload: { pages },
        })
        dispatch({
          type: CHANNEL_OVERVIEW_PAGE,
        })
      })
      .catch(err => {
        dispatch({
          type: SUBSCRIBE_TO_FACEBOOK_PAGES_FAILURE,
          payload: { error: err },
        })
      })
  }
}

export function doFetchFacebookPages() {
  return (dispatch, getState) => {
    const state = getState()
    const token = oauthTokenSelector(state)
    dispatch({ type: FETCH_FACEBOOK_PAGES_REQUEST })
    return fetchFacebookPages(token)
      .then(pages => {
        dispatch({ type: FETCH_FACEBOOK_PAGES_SUCCESS, payload: { pages } })
      })
      .catch(err => {
        dispatch({
          type: FETCH_FACEBOOK_PAGES_FAILURE,
          payload: { error: err },
        })
      })
  }
}
