import React, { useMemo } from 'react'
import { list } from '@groovehq/internal-design-system/lib/styles/elements'
import { useSelector } from 'react-redux'
import {
  FEATURE_INBOX_API_ACCESS,
  FEATURE_INBOX_BRANDING,
  FEATURE_INBOX_CANNED_REPLIES_TIER_1,
  FEATURE_INBOX_CRM_FIELDS,
  FEATURE_INBOX_CUSTOM_PROFILE,
  FEATURE_INBOX_MAX_CANNED_REPLIES,
  FEATURE_INBOX_MAX_FOLDERS,
  FEATURE_INBOX_MAX_INTEGRATIONS,
  FEATURE_INBOX_MAX_RULES,
  FEATURE_INBOX_MAX_USERS,
  FEATURE_INBOX_MAX_VIEWER_USERS,
  FEATURE_INBOX_MAX_WIDGETS,
  FEATURE_INBOX_PREMIUM_INTEGRATIONS,
  FEATURE_INBOX_RULES_TIER_1,
  FEATURE_INBOX_RULES_TIER_2,
  FEATURE_INBOX_SATISFACTIONS_RATINGS,
  FEATURE_INBOX_TEAMS,
  FEATURE_KB_BRANDING,
  FEATURE_KB_CUSTOM_SCRIPTS,
  FEATURE_KB_MAX_KBS,
  FEATURE_KB_PRIVATE,
  FEATURE_KB_SEO,
  FEATURE_ROUND_ROBIN,
} from 'ducks/billing/featureTypes'
import { checkNumberRemoved, checkBooleanRemoved } from 'util/usageLimit'
import { selectPlans } from 'ducks/billing/selectors/selectPlans'
import { selectAvailableFeaturesByPricingIds } from 'ducks/billing/selectors/selectAvailableFeaturesByPricingIds'
import Feature from './Feature'
import { styles } from '../styles'

export default function Removed({ className, ...rest }) {
  const plans = useSelector(selectPlans)
  const currentPricingIds = useMemo(() => plans.map(p => p.pricing.id), [plans])
  const currentFeatures = useSelector(state =>
    selectAvailableFeaturesByPricingIds(state, currentPricingIds)
  )

  return (
    <>
      <ul css={[list.styles.preflight, styles.features]} className={className}>
        {checkNumberRemoved(
          currentFeatures[FEATURE_INBOX_MAX_INTEGRATIONS],
          rest[FEATURE_INBOX_MAX_INTEGRATIONS]
        ) && <Feature>Integrations</Feature>}
        {checkBooleanRemoved(
          currentFeatures[FEATURE_INBOX_PREMIUM_INTEGRATIONS],
          rest[FEATURE_INBOX_PREMIUM_INTEGRATIONS]
        ) && <Feature>Premium integrations</Feature>}
        {checkNumberRemoved(
          currentFeatures[FEATURE_INBOX_MAX_USERS],
          rest[FEATURE_INBOX_MAX_USERS]
        ) && <Feature>No user limit</Feature>}
        {checkBooleanRemoved(
          currentFeatures[FEATURE_INBOX_TEAMS] > 0,
          rest[FEATURE_INBOX_TEAMS]
        ) && <Feature>{app.t('Teams')}</Feature>}
        {checkNumberRemoved(
          currentFeatures[FEATURE_INBOX_MAX_CANNED_REPLIES],
          rest[FEATURE_INBOX_MAX_CANNED_REPLIES]
        ) && <Feature>{app.t('Canned_replies')}</Feature>}
        {checkBooleanRemoved(
          currentFeatures[FEATURE_INBOX_CANNED_REPLIES_TIER_1],
          rest[FEATURE_INBOX_CANNED_REPLIES_TIER_1]
        ) && <Feature>Advanced {app.t('canned_replies')}</Feature>}
        {checkNumberRemoved(
          currentFeatures[FEATURE_INBOX_MAX_RULES],
          rest[FEATURE_INBOX_MAX_RULES]
        ) && <Feature>Rules</Feature>}
        {checkBooleanRemoved(
          currentFeatures[FEATURE_INBOX_RULES_TIER_1] > 0,
          rest[FEATURE_INBOX_RULES_TIER_1]
        ) && <Feature>Advanced rules</Feature>}
        {checkBooleanRemoved(
          currentFeatures[FEATURE_INBOX_RULES_TIER_2] > 0,
          rest[FEATURE_INBOX_RULES_TIER_2]
        ) && <Feature>Time based rules</Feature>}
        {checkNumberRemoved(
          currentFeatures[FEATURE_INBOX_MAX_FOLDERS],
          rest[FEATURE_INBOX_MAX_FOLDERS]
        ) && <Feature>Folders</Feature>}
        {checkNumberRemoved(
          currentFeatures[FEATURE_INBOX_MAX_WIDGETS],
          rest[FEATURE_INBOX_MAX_WIDGETS]
        ) && <Feature>Widgets</Feature>}
        {checkBooleanRemoved(
          currentFeatures[FEATURE_INBOX_CRM_FIELDS],
          rest[FEATURE_INBOX_CRM_FIELDS]
        ) && <Feature>Custom fields</Feature>}
        {checkBooleanRemoved(
          currentFeatures[FEATURE_INBOX_SATISFACTIONS_RATINGS],
          rest[FEATURE_INBOX_SATISFACTIONS_RATINGS]
        ) && <Feature>Satisfaction ratings</Feature>}
        {checkBooleanRemoved(
          currentFeatures[FEATURE_INBOX_API_ACCESS],
          rest[FEATURE_INBOX_API_ACCESS]
        ) && <Feature>API Access</Feature>}
        {checkBooleanRemoved(
          currentFeatures[FEATURE_INBOX_CUSTOM_PROFILE],
          rest[FEATURE_INBOX_CUSTOM_PROFILE]
        ) && <Feature>Custom profiles</Feature>}
        {checkBooleanRemoved(
          currentFeatures[FEATURE_INBOX_BRANDING],
          rest[FEATURE_INBOX_BRANDING]
        ) && <Feature>Disable Groove branding for inbox</Feature>}
        {checkNumberRemoved(
          currentFeatures[FEATURE_KB_MAX_KBS],
          rest[FEATURE_KB_MAX_KBS]
        ) && <Feature>Knowledge Bases</Feature>}
        {checkBooleanRemoved(
          currentFeatures[FEATURE_KB_CUSTOM_SCRIPTS],
          rest[FEATURE_KB_CUSTOM_SCRIPTS]
        ) && <Feature>Knowledge Base Custom Scripts</Feature>}
        {checkBooleanRemoved(
          currentFeatures[FEATURE_KB_SEO],
          rest[FEATURE_KB_SEO]
        ) && <Feature>Knowledge Base SEO and Open Graph settings</Feature>}
        {checkBooleanRemoved(
          currentFeatures[FEATURE_KB_PRIVATE],
          rest[FEATURE_KB_PRIVATE]
        ) && <Feature>Knowledge Base Access Control</Feature>}
        {checkBooleanRemoved(
          currentFeatures[FEATURE_KB_BRANDING],
          rest[FEATURE_KB_BRANDING]
        ) && <Feature>Disable Groove Knowledge Base branding</Feature>}
        {checkNumberRemoved(
          currentFeatures[FEATURE_INBOX_MAX_VIEWER_USERS],
          rest[FEATURE_INBOX_MAX_VIEWER_USERS]
        ) && <Feature>Lite users</Feature>}
        {checkBooleanRemoved(
          currentFeatures[FEATURE_ROUND_ROBIN],
          rest[FEATURE_ROUND_ROBIN]
        ) && <Feature>Round Robin with Load balancing</Feature>}
      </ul>
    </>
  )
}
