import { produce } from 'immer'

// Given an initial state and an object of reducer handlers, keyed by action
// type, this function will produce a new state for the respective action handler.
export default function createActionTypeReducer(
  handlers,
  initialState = undefined
) {
  return produce((draftState, action, sharedState = {}) => {
    const handler = handlers[action.type] || handlers['*']
    if (handler) return handler(draftState, action, sharedState)
    return draftState
  }, initialState)
}
