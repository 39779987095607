import Dot from '@groovehq/internal-design-system/lib/components/Dot/Dot'

export default function TagDropdownIndicator({
  name,
  color,
  conversationCount,
}) {
  return (
    <div className="grui flex items-center overflow-hidden">
      {color && <Dot color={color} className="grui mr-5" />}
      <div className="grui truncate">
        {`${name}${
          conversationCount !== null ? ` (${conversationCount})` : ''
        }`}
      </div>
    </div>
  )
}
