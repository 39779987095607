import React, { useCallback, useMemo, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useFormState } from 'react-hook-form'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'

import { AdminAccessDrawer } from 'subapps/settings/components/drawers/NoAccess'

import { useDrawer } from 'ducks/drawers/hooks'
import {
  DRAWER_TYPE_INTEGRATION_SLACK_CONFIGURE,
  DRAWER_TYPE_INTEGRATION_SLACK_SETUP_SUCCESS,
} from 'ducks/drawers/types'
import { buildDrawerQueryParam } from 'ducks/drawers/util'
import { useSlackApp } from 'ducks/integrations/slack/hooks'
import { useConfirmHoldsCallback } from 'util/dirtyHolds'

import Form, { FORM_ID } from './Form'

const Actions = ({
  formId,
  control,
  previousDrawerId,
  onClose,
  postInstall,
}) => {
  const { isValid, isDirty, isSubmitting } = useFormState({ control })

  const handleBack = useCallback(
    () => {
      onClose({
        ignoreStack: false,
        ...(previousDrawerId && {
          query: {
            ...buildDrawerQueryParam(
              previousDrawerId,
              'drawerPreferCachedSlackApp',
              '1'
            ),
          },
        }),
      })
    },
    [onClose, previousDrawerId]
  )

  const SaveButton = useCallback(
    props => {
      return (
        <Tooltip
          title="Please select at least one mailbox and one notification type"
          disabled={isValid}
        >
          <div className="grui flex flex-col">
            <button {...props} />
          </div>
        </Tooltip>
      )
    },
    [isValid]
  )

  if (postInstall) {
    return (
      <ModalBtns
        saveBtnText="Next step"
        saveBtnHtmlType="submit"
        saveBtnDisabled={!isValid || !isDirty || isSubmitting}
        saveBtnForm={formId}
      />
    )
  }

  return (
    <ModalBtns
      saveBtnText="Save changes"
      saveBtnAs={SaveButton}
      saveBtnHtmlType="submit"
      saveBtnDisabled={!isValid || !isDirty || isSubmitting}
      saveBtnForm={formId}
      tertiaryBtnText="Back"
      onClickTertiaryBtn={handleBack}
    />
  )
}

const SlackChannelDrawer = ({
  drawerResourceId: channelGid,
  open,
  onClose,
  onExit,
  drawerId,
  queryId,
  previousDrawer,
  drawerPostIntegrationInstall,
  ...rest
}) => {
  const postInstall = drawerPostIntegrationInstall === '1'
  const dispatch = useDispatch()

  const { openDrawer: openConfigureDrawer } = useDrawer(
    DRAWER_TYPE_INTEGRATION_SLACK_CONFIGURE
  )
  const { openDrawer: openSetupSuccessDrawer } = useDrawer(
    DRAWER_TYPE_INTEGRATION_SLACK_SETUP_SUCCESS
  )

  const handleOnClose = useConfirmHoldsCallback(
    FORM_ID,
    (...params) => {
      onClose(...params)
      if (!previousDrawer) {
        openConfigureDrawer('SLACK', { clearStack: true })
      }
    },
    [onClose, previousDrawer]
  )
  const handleOnExit = useConfirmHoldsCallback(null, onExit, [dispatch, onExit])
  const footerRef = useRef(null)

  const actionsComponentProps = useMemo(
    () => {
      return {
        formId: FORM_ID,
        previousDrawerId: previousDrawer?.drawerId,
        onClose: handleOnClose,
        postInstall,
      }
    },
    [previousDrawer, handleOnClose, postInstall]
  )

  const { slackApp, channels, isLoading, isMissing, hasError } = useSlackApp({
    preferCached: true,
  })

  return (
    <AdminAccessDrawer
      {...rest}
      onClose={handleOnExit}
      isLoading={isLoading}
      isError={hasError}
      isNoResultFound={isMissing}
      size="wide"
      title="Slack Settings"
      open={open}
      footer={<div ref={footerRef} />}
    >
      {!isLoading &&
        !isMissing &&
        slackApp &&
        channels && (
          <Form
            slackApp={slackApp}
            formId={FORM_ID}
            channelGid={channelGid}
            actionsPortalRef={footerRef}
            actionsComponent={Actions}
            actionsComponentAdditionalProps={actionsComponentProps}
            onSubmit={postInstall ? openSetupSuccessDrawer : handleOnClose}
            postInstall={postInstall}
          />
        )}
    </AdminAccessDrawer>
  )
}

export default SlackChannelDrawer
