import React, { useCallback, useMemo } from 'react'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'

const useColumns = () => {
  const Controls = ({ value, onEdit }) => {
    const handleClickEdit = useCallback(
      () => {
        onEdit(value)
      },
      [onEdit, value]
    )

    return (
      <div className="grui flex justify-end">
        <Button size="small" type="tertiary" onClick={handleClickEdit}>
          Edit store
        </Button>
      </div>
    )
  }

  const columns = useMemo(() => {
    return [
      {
        Header: 'Store',
        accessor: 'storeName',
        disableSortBy: true,
        width: 190,
      },
      {
        Header: 'Domain',
        accessor: 'storeDomain',
        disableSortBy: true,
        width: 260,
      },
      { Header: '', accessor: 'id', Cell: Controls, disableSortBy: true },
    ]
  }, [])

  return columns
}

export default useColumns
