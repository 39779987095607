import React from 'react'
import Radio from '@groovehq/internal-design-system/lib/components/Radio/Radio'

const MatchTypeSection = React.memo(({ register }) => {
  return (
    <>
      <Radio
        id="allConditions"
        className="grui mb-8"
        value="all"
        {...register('match_type')}
      >
        Conversations that match ALL of the conditions set below
      </Radio>
      <br />
      <Radio
        id="specificConditions"
        value="any"
        className="grui mb-5"
        {...register('match_type')}
      >
        Conversations that match ANY of the conditions set below
      </Radio>
    </>
  )
})

export default MatchTypeSection
