import { createSelector } from 'reselect'
import { selectArticlesStateCounts } from './selectArticlesStateCounts'

export const selectNonDeletedArticlesCount = createSelector(
  selectArticlesStateCounts,
  stateCounts => {
    return Object.keys(stateCounts).reduce((accumulator, currentKey) => {
      if (currentKey === 'deleted') return accumulator
      const stateCount = stateCounts[currentKey] || 0
      return accumulator + stateCount
    }, 0)
  }
)
