import React from 'react'
import { SUI } from 'shared/ui'
import { compact, getLength, itemsDifferByKey } from 'util/arrays'
import { propFunc, fanout } from 'util/functions'
import GA from 'util/googleAnalytics'
import { TRACK_TYPE_FOLLOW_TICKET_TOP_BAR } from 'ducks/ticketing/operations/ticketFollow'

import WrappedDropdown from './WrappedDropdown'
import MergeDropdownOption from './MergeDropdownOption'

const trackGA = label => {
  return GA.track('Inbox - Email Top Bar', 'Clicked', label)
}

const mailboxOption = (mailboxes = [], onShowChangeInbox, doChangeMailbox) => {
  const count = getLength(mailboxes)
  const track = propFunc(trackGA, 'Change mailbox')

  if (count < 1) return undefined

  if (count === 1) {
    return {
      text: `Move to ${mailboxes[0].name}`,
      key: 'moveMailbox',
      value: mailboxes[0].id,
      onClick: () => {
        doChangeMailbox(mailboxes[0].id)
        track()
      },
    }
  }

  return {
    text: `Change ${app.t('Mailbox')}`,
    key: 'changeInbox',
    value: 'changeInbox',
    onClick: () => {
      onShowChangeInbox()
      track()
    },
  }
}

export const options = ({
  ticketId,
  doChangeMailbox,
  doMarkAsRead,
  doMarkAsUnread,
  isUnread,
  doToggleTicketAsSpam,
  isSpam,
  doDeleteTicket,
  doHardDeleteTicket,
  doRestoreTicket,
  isDeleted,
  mailboxes,
  onShowChangeInbox,
  isFollowing,
  doFollowTicket,
  doUnfollowTicket,
  doOpenForwardTicketPage,
  doPrintWholeTicket,
  doOpenTicketMergeDrawer,
}) => {
  const mark = {
    text: 'Mark as read',
    key: `markRead-${ticketId}`,
    value: 'markRead',
    onClick: propFunc(fanout, doMarkAsRead, propFunc(trackGA, 'Mark as read')),
  }

  const unMark = {
    text: 'Mark as unread',
    key: `markUnread-${ticketId}`,
    value: 'markUnread',
    onClick: propFunc(
      fanout,
      doMarkAsUnread,
      propFunc(trackGA, 'Mark as unread')
    ),
  }

  const spam = {
    text: 'Mark as spam',
    key: `markSpam-${ticketId}`,
    value: 'markSpam',
    id: 'ticket-header-spam-option',
    onClick: propFunc(
      fanout,
      doToggleTicketAsSpam,
      propFunc(trackGA, 'Mark as spam')
    ),
  }

  const unSpam = {
    text: 'Unmark as spam',
    key: `unmarkSpam-${ticketId}`,
    value: 'unmarkSpam',
    onClick: propFunc(
      fanout,
      doToggleTicketAsSpam,
      propFunc(trackGA, 'Unmark as spam')
    ),
  }

  const deleteTicket = {
    text: 'Move to Trash',
    key: `delete-${ticketId}`,
    value: 'delete',
    // Doesn't support passing class or className, use id as a selector for CSS
    id: 'ticket-header-delete-option',
    onClick: propFunc(
      fanout,
      doDeleteTicket,
      propFunc(trackGA, 'Delete ticket')
    ),
  }

  const hardDeleteTicket = {
    text: 'Delete forever',
    key: `hard-delete-${ticketId}`,
    value: 'hard-delete',
    onClick: propFunc(
      fanout,
      doHardDeleteTicket,
      propFunc(trackGA, 'Hard delete ticket')
    ),
  }

  const restoreTicket = {
    text: 'Restore',
    key: `restore-${ticketId}`,
    value: 'restore',
    onClick: propFunc(
      fanout,
      doRestoreTicket,
      propFunc(trackGA, 'Restore ticket')
    ),
  }

  const follow = {
    text: 'Follow Conversation',
    key: `follow-${ticketId}`,
    value: 'follow',
    onClick: propFunc(doFollowTicket, TRACK_TYPE_FOLLOW_TICKET_TOP_BAR),
  }

  const unfollow = {
    text: 'Unfollow Conversation',
    key: `unfollow-${ticketId}`,
    value: 'unfollow',
    onClick: propFunc(doUnfollowTicket, TRACK_TYPE_FOLLOW_TICKET_TOP_BAR),
  }

  const forward = {
    text: 'Forward',
    key: `forward-${ticketId}`,
    value: 'forward',
    onClick: propFunc(
      fanout,
      doOpenForwardTicketPage,
      propFunc(trackGA, 'Forward ticket')
    ),
  }

  const print = {
    text: 'Print',
    key: `print-${ticketId}`,
    value: 'print',
    onClick: propFunc(
      fanout,
      doPrintWholeTicket,
      propFunc(trackGA, 'Print whole ticket')
    ),
  }

  const merge = {
    text: 'Merge',
    key: `merge-${ticketId}`,
    value: 'merge',
    id: 'ticket-header-merge-option',
    onClick: propFunc(
      fanout,
      doOpenTicketMergeDrawer,
      propFunc(trackGA, 'Merge ticket')
    ),
    as: MergeDropdownOption,
  }

  return compact([
    isUnread ? mark : unMark,
    isSpam ? unSpam : spam,
    isDeleted && restoreTicket,
    isDeleted ? hardDeleteTicket : deleteTicket,
    mailboxOption(mailboxes, onShowChangeInbox, doChangeMailbox),
    isFollowing ? unfollow : follow,
    forward,
    print,
    merge,
  ])
}

class MainDropdown extends React.PureComponent {
  componentWillUpdate(nextProps) {
    this.recalculateOptions(nextProps)
  }

  getOptions() {
    return this._options || this.recalculateOptions(this.props)
  }

  doChangeMailbox = mailboxId => {
    this.props.doChangeMailbox(this.props.ticketId, mailboxId)
  }

  doHardDeleteTicket = () => {
    this.props.doDeleteTicket({ deleteMode: 'HARD' })
  }

  recalculateOptions(props) {
    const {
      isDeleted,
      isUnread,
      isSpam,
      mailboxes,
      doOpenChangeInboxMenu: onShowChangeInbox,
      doToggleTicketAsSpam,
      doMarkAsUnread,
      doMarkAsRead,
      doDeleteTicket,
      doRestoreTicket,
      ticketId,
      isFollowing,
      doFollowTicket,
      doUnfollowTicket,
      doOpenForwardTicketPage,
      doPrintWholeTicket,
      doOpenTicketMergeDrawer,
    } = props

    const newOptions = options({
      ticketId,
      doChangeMailbox: this.doChangeMailbox,
      doMarkAsRead: propFunc(doMarkAsRead, ticketId),
      doMarkAsUnread: propFunc(doMarkAsUnread, ticketId),
      isUnread,
      doToggleTicketAsSpam: propFunc(doToggleTicketAsSpam, ticketId),
      isSpam,
      doDeleteTicket,
      doHardDeleteTicket: this.doHardDeleteTicket,
      doRestoreTicket: propFunc(doRestoreTicket, ticketId),
      isDeleted,
      mailboxes,
      onShowChangeInbox,
      isFollowing,
      doFollowTicket,
      doUnfollowTicket,
      doOpenForwardTicketPage: propFunc(doOpenForwardTicketPage, ticketId),
      doPrintWholeTicket: propFunc(doPrintWholeTicket, ticketId),
      doOpenTicketMergeDrawer: propFunc(doOpenTicketMergeDrawer, ticketId),
    })
    if (itemsDifferByKey(this._options, newOptions, 'key')) {
      this._options = newOptions
    }

    return this._options
  }

  render() {
    const { className, open, onClose, onOpen, trigger } = this.props

    return (
      <SUI>
        <WrappedDropdown
          className={className}
          open={open}
          options={this.getOptions()}
          onClose={onClose}
          onOpen={onOpen}
          trigger={trigger}
        />
      </SUI>
    )
  }
}

export default MainDropdown
