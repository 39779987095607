export const FETCH_FACEBOOK_PAGES_REQUEST =
  'onboard/signup/FETCH_FACEBOOK_PAGES_REQUEST'
export const FETCH_FACEBOOK_PAGES_SUCCESS =
  'onboard/signup/FETCH_FACEBOOK_PAGES_SUCCESS'
export const FETCH_FACEBOOK_PAGES_FAILURE =
  'onboard/signup/FETCH_FACEBOOK_PAGES_FAILURE'
export const SUBSCRIBE_TO_FACEBOOK_PAGES_REQUEST =
  'onboard/signup/SUBSCRIBE_TO_FACEBOOK_PAGES_REQUEST'
export const SUBSCRIBE_TO_FACEBOOK_PAGES_SUCCESS =
  'onboard/signup/SUBSCRIBE_TO_FACEBOOK_PAGES_SUCCESS'
export const SUBSCRIBE_TO_FACEBOOK_PAGES_FAILURE =
  'onboard/signup/SUBSCRIBE_TO_FACEBOOK_PAGES_FAILURE'
