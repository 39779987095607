import emailTemplates from 'assets/email_templates.png'
import kb from 'assets/kb-eCommerce.png'
import rule from 'assets/rules.png'
import inbox from 'assets/inbox_empty.png'
import creditCard from 'assets/creditcards/credit-card-lg.png'

import { DRAWER_TYPE_CANNED_REPLIES_UPDATE } from 'ducks/drawers/types'
import {
  SETTINGS_BILLING_PAGE,
  SETTINGS_IMPORT_PAGE,
  SETTINGS_RULES_PAGE,
} from 'subapps/settings/types'
import { LANDING_PAGE } from 'subapps/kb/pages'

export const shortOnboardingWorkflowData = {
  mailbox: {
    name: `Create ${app.t('Mailbox_with_article')}`,
    img: inbox,
    imgWidth: 278,
    imgHeight: 218,
    title: 'Get your email flowing into Groove!',
    subtitle: `Let’s get your emails flowing in by connecting your first ${app.t(
      'mailbox'
    )} to your Groove account.`,
    note: 'Setup time of 2 minutes or less',
    skippable: false,
    usageKey: 'mailboxes_created',
    // usage_onboarding.mailboxes_created isn't reliable sometimes, so we also check for the presence of the flag
    flagName: 'complete_onboarding_mailbox',
  },
  canned_reply: {
    name: `Create ${app.t('Canned_Reply_with_article')}`,
    img: emailTemplates,
    imgWidth: 263,
    imgHeight: 207,
    title: `Respond 45% faster with ${app.t('Canned_Replies')}!`,
    subtitle: `Create a searchable library of answers to frequently asked questions, then insert them into replies in two clicks.`,
    note: 'Setup time of 3 minutes',
    btnLabel: `Create your first ${app.t('Canned_Reply')}`,
    drawerType: DRAWER_TYPE_CANNED_REPLIES_UPDATE,
    flagName: 'skip_onboarding_canned_reply',
    usageKey: 'comment_templates_created',
  },
  rule: {
    name: 'Add a Rule',
    img: rule,
    imgWidth: 278,
    imgHeight: 218,
    title: 'Automate tedious tasks with rules!',
    subtitle: `With rules, you can set up powerful automations to take care of repetitive tasks using simple if/then logic.`,
    note: 'Setup time of 3 minutes',
    btnLabel: 'View all our Rule templates',
    to: {
      type: SETTINGS_RULES_PAGE,
      payload: {
        tab: 'templates',
      },
    },
    usageKey: 'automation_rules_created',
    flagName: 'skip_onboarding_rule',
  },
  kb: {
    name: 'Create a Knowledge Base',
    img: kb,
    imgWidth: 477,
    imgHeight: 237,
    title: 'Cut your support volume down by 30%',
    subtitle: `Our fully customizable Knowledge Base lets customers find their own answers quickly, making them happier, and your team more productive.`,
    note: 'Setup time of 5 minutes',
    btnLabel: `Create your Knowledge base`,
    to: { type: LANDING_PAGE },
    usageKey: 'kb_created',
    flagName: 'skip_onboarding_kb',
    isBool: true,
  },
  payment_method: {
    name: 'Add a Payment Method',
    img: creditCard,
    imgWidth: 303,
    imgHeight: 217,
    title: 'Add your payment method',
    subtitle: `Ensure your account isn't cut off at the end of your trial. You will only be billed once your trial ends.`,
    btnLabel: 'Go to billing page',
    to: {
      type: SETTINGS_BILLING_PAGE,
    },
    flagName: 'skip_onboarding_payment_method',
    usageKey: 'payment_method_created',
    isBool: true,
  },
  import: {
    name: `Import your data to Groove`,
    img: emailTemplates,
    imgWidth: 263,
    imgHeight: 207,
    title: `Import your data from another help desk`,
    subtitle: `Click the button below to see the list of available import sources.`,
    btnLabel: `Go to Import page`,
    to: {
      type: SETTINGS_IMPORT_PAGE,
    },
    flagName: 'skip_onboarding_import2',
    usageKey: 'import2_created',
  },
}
