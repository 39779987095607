import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { camelize } from 'util/strings'
import {
  FEATURE_INBOX_MAX_RULES,
  FEATURE_INBOX_RULES,
} from 'ducks/billing/featureTypes'
import { selectPendingDowngradePlans } from 'ducks/billing/selectors/selectPendingDowngradePlans'
import { selectIsBillingDataLoaded } from 'ducks/billing/selectors/selectIsBillingDataLoaded'
import {
  ruleTriggers,
  ruleActionsForPaid,
  ruleConditions,
} from '../drawers/RuleCreateEdit/data'

const flattenAutomationsToParameterArray = data =>
  Object.values(data.parameters).flat(1)

const triggerParameterArray = flattenAutomationsToParameterArray(ruleTriggers)
const actionParameterArray = flattenAutomationsToParameterArray(
  ruleActionsForPaid
)
const conditionParameterArray = flattenAutomationsToParameterArray(
  ruleConditions
)

const isAdvancedLimitedFeature = (feature, planFeatures = {}) => {
  if (!feature) return false
  const camelizedFeatureName = camelize(feature)
  // rules/maxRules feature isn't an advanded feature
  if (
    [FEATURE_INBOX_RULES, FEATURE_INBOX_MAX_RULES].includes(
      camelizedFeatureName
    )
  ) {
    return false
  }
  return !planFeatures[camelizedFeatureName]
}

const automationTypes = {
  triggers: {
    params: triggerParameterArray,
    properties: ['type', 'value'],
  },
  conditions: {
    params: conditionParameterArray,
    properties: ['param', 'value'],
  },
  actions: {
    params: actionParameterArray,
    properties: ['type', 'type'],
  },
}

const hasAutomationWithLimitedAdvancedFeatureUsage = (
  automations,
  plan,
  type
) => {
  const { properties = [], params } = automationTypes[type] || {}
  return automations.some(trigger => {
    const feature = params.find(
      param => trigger[properties[0]] === param[properties[1]]
    )?.feature
    return isAdvancedLimitedFeature(feature, plan?.features)
  })
}

const useAddFeatureUsageIndicator = rules => {
  const { inbox: pendingDowngradeInboxPlan } = useSelector(
    selectPendingDowngradePlans
  )
  const isBillingLoaded = useSelector(selectIsBillingDataLoaded)
  const [data, setData] = useState([])

  useEffect(
    () => {
      if (!isBillingLoaded || !pendingDowngradeInboxPlan) {
        setData(rules)
        return
      }

      const newRules = rules.map(rule => {
        const withLimitedFeatureUsage = Object.keys(automationTypes).some(
          type => {
            return hasAutomationWithLimitedAdvancedFeatureUsage(
              rule[type],
              pendingDowngradeInboxPlan,
              type
            )
          }
        )
        if (withLimitedFeatureUsage) {
          return {
            ...rule,
            limited: true,
          }
        }

        return rule
      })

      setData(newRules)
    },
    [rules, isBillingLoaded, pendingDowngradeInboxPlan]
  )

  return data
}

export default useAddFeatureUsageIndicator
