export const SIGNUP_REQUEST = 'onboarding/signup/SIGNUP_REQUEST'
export const SIGNUP_SUCCESS = 'onboarding/signup/SIGNUP_SUCCESS'
export const SIGNUP_FAILURE = 'onboarding/signup/SIGNUP_FAILURE'

export const ONBOARDING_COMPLETE_SUCCESS =
  'onboarding/ONBOARDING_COMPLETE_SUCCESS'

export const BOOTSTRAP_ONBOARDING = 'onboarding/BOOTSTRAP_ONBOARDING'
export const BOOTSTRAP_ONBOARDING_REQUEST =
  'onboarding/BOOTSTRAP_ONBOARDING_REQUEST'
export const BOOTSTRAP_ONBOARDING_SUCCESS =
  'onboarding/BOOTSTRAP_ONBOARDING_SUCCESS'
export const BOOTSTRAP_ONBOARDING_FAILED =
  'onboarding/BOOTSTRAP_ONBOARDING_FAILED'
