import { selectAgent } from 'selectors/agents/base'
import { getAgentUsernameOrYou } from 'util/agents'
import { selectGroup, selectGroupsByName } from 'selectors/app/base'

// used by performance simulator

export const getAgentUsernameOrYouById = agentId => {
  const state = app.store.getState()
  const agent = selectAgent(state, agentId)
  return getAgentUsernameOrYou(agent, state.currentUser)
}

export const selectGroupById = groupId => {
  const state = app.store.getState()
  return selectGroup(state, groupId)
}

export const selectGroupByName = name => {
  const state = app.store.getState()
  return selectGroupsByName(state, name)
}
