import { parseQueryParams, toQueryString } from 'util/params'
import storage from 'util/storage'

export function persist(key, value, { toUrl = false, toStorage = true } = {}) {
  if (toStorage) storage.set(key, value)
  if (toUrl) {
    const existingParams = parseQueryParams(document.location.search)
    const newParams = toQueryString({
      ...existingParams,
      [key]: encodeURI(JSON.stringify(value)),
    })
    history.replaceState({}, document.title, `?${newParams}`)
  }
  return value
}

export function read(
  key,
  currentValue,
  { meta: { location: { current: { query } = {} } = {} } = {} },
  { check = null, default: defaultValue = null, toUrl = false } = {}
) {
  // This will keep previous history state urls inline with the current value
  if (toUrl && query && query[key] !== currentValue) {
    persist(key, currentValue, { toUrl: true, toStorage: false })
  }
  if (check === null && currentValue !== null && currentValue !== undefined) {
    return currentValue
  } else if (check === true) {
    return currentValue
  }
  const params = parseQueryParams(document.location.search)
  const urlValue = params[key]
  if (urlValue !== undefined) {
    const decodedUrlValue = JSON.parse(decodeURI(params[key]))
    storage.set(key, decodedUrlValue)
    return decodedUrlValue
  }
  const localStorageValue = storage.get(key)
  if (localStorageValue !== undefined && localStorageValue !== null)
    return persist(key, localStorageValue, { toUrl })
  return persist(key, defaultValue, { toUrl })
}
