import groove from 'api/groove'
import storage from 'util/storage'
import config from 'config'
import debugLib from 'util/debug'
import { getUserAgent, detect } from 'util/browser'
import { captureVersion } from 'util/version'
import { doRefreshIfNecessary } from 'ducks/tokens/actions'

function getCpuCores() {
  try {
    return navigator && navigator.hardwareConcurrency
  } catch (_) {
    return null
  }
}

function getGraphics() {
  const canvas = document.getElementById('performance-glcanvas')
  let gl
  let vendor
  let renderer
  try {
    gl = canvas.getContext('experimental-webgl')
    if (gl) {
      gl.viewportWidth = canvas.width
      gl.viewportHeight = canvas.height
      const extension = gl.getExtension('WEBGL_debug_renderer_info')
      if (extension !== undefined) {
        vendor = gl.getParameter(extension.UNMASKED_VENDOR_WEBGL)
        renderer = gl.getParameter(extension.UNMASKED_RENDERER_WEBGL)
      } else {
        vendor = gl.getParameter(extension.VENDOR)
        renderer = gl.getParameter(extension.RENDERER)
      }
    }
  } catch (e) {
    // pass
  }
  return {
    vendor,
    renderer,
  }
}

function getMemory() {
  try {
    const ram = navigator && navigator.deviceMemory
    const memory = (performance && performance.memory) || {}
    return {
      jsHeapSizeLimit: memory.jsHeapSizeLimit,
      totalJSHeapSize: memory.totalJSHeapSize,
      usedJSHeapSize: memory.usedJSHeapSize,
      ram,
    }
  } catch (e) {
    return {}
  }
}

function debug(msg, data) {
  // eslint-disable-next-line no-console
  if (config.isDevelopment || debugLib.enabled) console.debug(msg, data)
}

// Extract the token without actually loading
// the storage. We need to do this so the `metrics`
// module can be loaded safely
let tokenCache = null
function getToken() {
  try {
    if (tokenCache) return tokenCache
    const cachedAuth = storage.get('auth')
    if (cachedAuth) tokenCache = cachedAuth.token
    return tokenCache
  } catch (e) {
    return null
  }
}

function getSharedConstants() {
  let browser
  let cores
  let graphics
  try {
    browser = detect(getUserAgent())
  } catch (e) {
    // pass
  }
  try {
    cores = getCpuCores()
  } catch (e) {
    // pass
  }
  try {
    graphics = getGraphics()
  } catch (e) {
    // pass
  }

  return {
    ...browser,
    appVersion: captureVersion(),
    cores,
    graphics,
  }
}

const buffer = []
const BUFFER_FLUSH_INTERVAL = 30000 // 30 seconds
let isSending = false
const constants = getSharedConstants()

async function send() {
  if (buffer.length === 0) return
  if (isSending) return
  const token = getToken()
  if (!token) return
  isSending = true
  const metrics = []
  let metric
  // drain the buffer, but keep an upper bound on metrics payload
  // eslint-disable-next-line no-cond-assign
  while ((metric = buffer.shift()) && metrics.length < 50) {
    metrics.push(metric)
  }
  let ticketPreload = false
  let draftsV2 = false
  let crmSidebarEnabled = false
  try {
    const state = app.store.getState()
    const preferences = state.currentUser.preferences
    ticketPreload = preferences.speedy_groove || false
    const accountPreferences = state.app.account.preferences
    draftsV2 = accountPreferences.should_use_fixed_drafts_v2
    crmSidebarEnabled = accountPreferences.crm_sidebar
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
    // pass
  }
  const useKubesEndpoints = !!window.USE_NEW_ENDPOINTS
  const shared = {
    ...constants,
    host: window.location.host,
    ticketPreload,
    draftsV2,
    crmSidebarEnabled,
    memory: getMemory(),
    useKubesEndpoints,
  }
  await app.store.dispatch(doRefreshIfNecessary())
  groove
    .post(token, 'v1/metrics.json', null, JSON.stringify({ metrics, shared }))
    .then(() => {
      debug('Metrics send successfuly')
    })
    .catch(() => {
      debug('Metrics sending failed')

      // buffer is too large, drop these metrics
      if (metrics.length > 250) return

      // we failed, put them back
      // eslint-disable-next-line no-cond-assign
      while ((metric = metrics.pop())) {
        buffer.unshift(metric)
      }
    })
    .finally(() => {
      isSending = false
    })
}

function increment(name, value) {
  const type = 'increment'
  // eslint-disable-next-line no-param-reassign
  value = value || 1
  const found = buffer.find(
    metric => metric.type === type && metric.name === name
  )
  if (found) {
    found.value += value
  } else {
    buffer.push({ type, name, value })
  }
  if (buffer.length > 50) send()
}

function gauge(name, value) {
  const type = 'gauge'
  buffer.push({ type, name, value })
  if (buffer.length > 50) send()
}

function bool(name, value) {
  const type = 'bool'
  buffer.push({ type, name, value: !!value })
  if (buffer.length > 50) send()
}

function log(values) {
  buffer.push({ type: 'log', ...values })
  if (buffer.length > 50) send()
}

const timers = {}
function startTimer(id, values = {}) {
  debug('startTimer', { id, values })
  const timer = timers[id]
  if (timer) return
  timers[id] = { start: new Date().getTime(), values }
  setTimeout(() => {
    delete timers[id]
  }, 30000) // 30 second timeout
}

function enhanceTimer(id, values = {}) {
  debug('enhanceTimer', { id, values })
  const timer = timers[id]
  if (!timer) return
  timers[id] = {
    ...timer,
    values: {
      ...timer.values,
      ...values,
    },
  }
}

function startOrEnhanceTimer(id, values = {}) {
  const timer = timers[id]
  if (timer) enhanceTimer(id, values)
  if (!timer) startTimer(id, values)
}

function stopTimer(id, values = {}) {
  if (!timers[id]) return
  const timer = timers[id]
  delete timers[id]
  const elapsed = new Date().getTime() - timer.start
  const payload = { ...timer.values, ...values, elapsed }
  debug('stopTimer', { id, payload })
  log(payload)
}

if (!config.isTest) {
  setInterval(send, BUFFER_FLUSH_INTERVAL)
}

const mod = {
  increment,
  gauge,
  bool,
  log,
  startTimer,
  startOrEnhanceTimer,
  stopTimer,
  enhanceTimer,
  timers,
}
if (
  config.isDevelopment ||
  config.isTest ||
  config.isAlpha ||
  config.isDocker
) {
  mod.buffer = buffer
  mod.send = send
}
module.exports = mod
