import { useCallback, useEffect, useRef } from 'react'

// Will create a delayed function that is automatically cancelled if the component unmounts
export function useDelayedFunction(func, delay = 0) {
  const timeoutRef = useRef()
  useEffect(() => () => clearTimeout(timeoutRef.current), []) // Clear the timeout on unmount
  return useCallback(
    () => {
      timeoutRef.current = setTimeout(func, delay)
    },
    [func, timeoutRef, delay]
  )
}
