import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util'

const controls = theme => css`
  button {
    width: 100%;

    + button {
      margin-top: ${theme.spacing[5]};
    }
  }
`

const fullWidth = css`
  max-width: 100%;
`

const content = css`
  width: ${rem(400)};
`

const forwardingWrapper = css`
  display: none;
  width: auto;
  min-width: 236px;

  @media screen and (min-width: 1200px) {
    display: block;
  }
`

const forwardingGuidesBtn = theme => css`
  width: 100%;
  border-radius: ${rem(6)};
  background-color: ${theme.color.monochrome.superLight};
  margin-top: ${rem(8)};
  padding-left: ${rem(12)};
  padding-right: ${rem(12)};
  justify-content: flex-start;
  font-size: ${theme.fontSize.large};
  color: ${theme.color.info['900']};

  &:hover {
    background-color: ${theme.color.monochrome.white};
    border-color: ${theme.color.monochrome.white};
  }

  img {
    width: ${rem(22)};
    margin-right: ${rem(9)};
  }
`

export const styles = {
  controls,
  fullWidth,
  content,
  forwardingWrapper,
  forwardingGuidesBtn,
}
