import React, { useCallback } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { AdminAccessDrawer } from 'subapps/settings/components/drawers/NoAccess'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import { useConfirmHoldsCallback, useRhfDirtyHold } from 'util/dirtyHolds'
import useFetchEntityById from 'ducks/entities/hooks/useFetchEntityById'
import { doFetchWebhookV0ById, doSaveWebhook } from 'ducks/webhooks/actions'
import { FETCH_WEBHOOK_BYID } from 'ducks/webhooks/actionTypes'
import validator from 'validator'
import WebhookCreateEditContent from './Content'
import { inputsData } from './data'

const { webhookUrl } = inputsData

const FORM_SCHEMA = yup.object().shape({
  [webhookUrl.name]: yup
    .string()
    .label(webhookUrl.label)
    .required(`${webhookUrl.label} is required`)
    // Validate url, yup.url() is too strict
    .test(value => validator.isURL(value)),
  event: yup.string().required('Webhook is required'),
})

function WebhookCreateEditDrawer({
  drawerResourceId: webhookId,
  previousDrawer,
  entityType,
  open,
  onClose,
  onExit,
  drawerId,
  ...rest
}) {
  const doFetchWebhookV0ByIdWithOptions = useCallback(input => {
    return doFetchWebhookV0ById(input.id, { convertScatterToRaw: true })
  }, [])

  const { requestState, entity: webhook } = useFetchEntityById(
    webhookId,
    'webhook',
    doFetchWebhookV0ByIdWithOptions,
    FETCH_WEBHOOK_BYID
  )
  const dispatch = useDispatch()
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      [webhookUrl.name]: '',
      webhook: null,
    },
    resolver: yupResolver(FORM_SCHEMA),
    delayError: 300,
  })
  const {
    handleSubmit,
    control,
    formState: { hasError, isValid, isDirty, isSubmitting },
  } = methods
  const isUpdate = webhookId && webhookId !== 'new'

  const { releaseHold } = useRhfDirtyHold(drawerId, control)

  const handleOnExit = useConfirmHoldsCallback(null, onExit, [onExit])

  const onSubmit = useCallback(
    async fields => {
      await dispatch(
        doSaveWebhook(webhookId, fields, { convertScatterToRaw: true })
      )
      const closeParams = {}
      releaseHold()
      onClose(closeParams)
    },
    [dispatch, webhookId, onClose, releaseHold]
  )

  const DrawerForm = useCallback(
    props => <form onSubmit={handleSubmit(onSubmit)} {...props} />,
    [handleSubmit, onSubmit]
  )

  const disableSave =
    requestState.loading || isSubmitting || hasError || !isValid || !isDirty

  return (
    <FormProvider {...methods}>
      <AdminAccessDrawer
        {...rest}
        open={open}
        onClose={handleOnExit}
        size="narrow"
        title={isUpdate ? 'Edit webhook' : 'New webhook'}
        isLoading={requestState.loading}
        isError={requestState.error}
        isNoResultFound={isUpdate && requestState.loaded && !webhook}
        footer={
          <ModalBtns
            saveBtnDisabled={disableSave}
            saveBtnText={isUpdate ? 'Save' : 'Create'}
            saveBtnHtmlType="submit"
            tertiaryBtnText="Cancel"
            onClickTertiaryBtn={onClose}
          />
        }
        container={DrawerForm}
      >
        <WebhookCreateEditContent onSubmit={onSubmit} />
      </AdminAccessDrawer>
    </FormProvider>
  )
}

export default WebhookCreateEditDrawer
