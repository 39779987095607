import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  selectCurrentUserIsAdminOrOwner,
  selectCurrentUserIsOwner,
} from 'ducks/currentUser/selectors/base'

import Drawer from '@groovehq/internal-design-system/lib/components/Drawer/UnmanagedDrawer'
import { selectIsImpersonating } from 'selectors/app/selectIsImpersonating'

export function AdminAccessDrawer({
  open,
  onClose,
  title,
  isLoading,
  hasError,
  footer,
  container,
  isNoResultFound,
  children,
  ...rest
}) {
  const isCurrentUserManager = useSelector(selectCurrentUserIsAdminOrOwner)
  useEffect(
    () => {
      if (!isCurrentUserManager) onClose()
    },
    [onClose, isCurrentUserManager]
  )

  return (
    <Drawer
      {...rest}
      open={open}
      onClose={onClose}
      title={title}
      isLoading={isLoading}
      isError={hasError}
      footer={footer}
      container={container}
      isNoResultFound={isNoResultFound}
    >
      {children}
    </Drawer>
  )
}

export function OwnerAccessDrawer({
  open,
  onClose,
  title,
  isLoading,
  hasError,
  footer,
  container,
  isNoResultFound,
  children,
  ...rest
}) {
  const isCurrentUserOwner = useSelector(selectCurrentUserIsOwner)
  useEffect(
    () => {
      if (!isCurrentUserOwner) onClose()
    },
    [onClose, isCurrentUserOwner]
  )

  return (
    <Drawer
      {...rest}
      open={open}
      onClose={onClose}
      title={title}
      isLoading={isLoading}
      isError={hasError}
      footer={footer}
      container={container}
      isNoResultFound={isNoResultFound}
    >
      {children}
    </Drawer>
  )
}

export function ImpersonateAccessDrawer({
  open,
  onClose,
  title,
  isLoading,
  hasError,
  footer,
  container,
  isNoResultFound,
  children,
  ...rest
}) {
  const isImpersonating = useSelector(selectIsImpersonating)
  useEffect(
    () => {
      if (!isImpersonating) onClose()
    },
    [onClose, isImpersonating]
  )

  return (
    <Drawer
      {...rest}
      open={open}
      onClose={onClose}
      title={title}
      isLoading={isLoading}
      isError={hasError}
      footer={footer}
      container={container}
      isNoResultFound={isNoResultFound}
    >
      {children}
    </Drawer>
  )
}
