import { Checkbox } from 'util/ui'

import { SettingRow, Tabs } from '../../shared/SettingsModal'

export default function SidebarTab({ active, formFields, onChange }) {
  return (
    <Tabs.Content active={active}>
      <p>
        Choose which fields you want to display in the sidebar of all your
        linked Jira server issues.
      </p>
      <SettingRow>
        <Checkbox
          checked={formFields['showSidebarField.type']}
          name="showSidebarField.type"
          onChange={onChange}
        />{' '}
        <span>Type</span>
      </SettingRow>
      <SettingRow>
        <Checkbox
          checked={formFields['showSidebarField.status']}
          name="showSidebarField.status"
          onChange={onChange}
        />{' '}
        <span>Status</span>
      </SettingRow>
      <SettingRow>
        <Checkbox
          checked={formFields['showSidebarField.resolution']}
          name="showSidebarField.resolution"
          onChange={onChange}
        />{' '}
        <span>Resolution</span>
      </SettingRow>
      <SettingRow>
        <Checkbox
          checked={formFields['showSidebarField.dueDate']}
          name="showSidebarField.dueDate"
          onChange={onChange}
        />{' '}
        <span>Due date</span>
      </SettingRow>
      <SettingRow>
        <Checkbox
          checked={formFields['showSidebarField.created']}
          name="showSidebarField.created"
          onChange={onChange}
        />{' '}
        <span>Created at</span>
      </SettingRow>
      <SettingRow>
        <Checkbox
          checked={formFields['showSidebarField.updated']}
          name="showSidebarField.updated"
          onChange={onChange}
        />{' '}
        <span>Updated at</span>
      </SettingRow>
      <SettingRow>
        <Checkbox
          checked={formFields['showSidebarField.assignee']}
          name="showSidebarField.assignee"
          onChange={onChange}
        />{' '}
        <span>Assignee</span>
      </SettingRow>
      <SettingRow>
        <Checkbox
          checked={formFields['showSidebarField.labels']}
          name="showSidebarField.labels"
          onChange={onChange}
        />{' '}
        <span>Labels</span>
      </SettingRow>
      <SettingRow>
        <Checkbox
          checked={formFields['showSidebarField.project']}
          name="showSidebarField.project"
          onChange={onChange}
        />{' '}
        <span>Project</span>
      </SettingRow>
    </Tabs.Content>
  )
}
