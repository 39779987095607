import React, { useCallback } from 'react'
import { useFieldArray } from 'react-hook-form'
import {
  ruleActionsForUnpaid,
  ruleActionsForPaid,
  ruleActionsParemetersSectionTitles,
} from './data'
import ActionWithSearch from '../../Action/ActionWithSearch'
import AutomationItem from '../../AutomationItem'

export default function RuleActions({
  control,
  trigger,
  setValue,
  isPaidAccount,
  errors,
}) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'actions',
    // default keyName is 'id', which overrides and removes our ids
    // before submit, to prevent that we set it to 'key' and
    // let useFieldArray handles it by itself
    keyName: 'key',
  })

  const onAdd = useCallback(
    () => {
      // Don't add key value for new entity, useFieldArray will add it
      // so the form won't be dirty if only the key is different
      const newEntity = {
        type: null,
        value: '',
      }
      append(newEntity)
    },
    [append]
  )

  const onRemove = useCallback(
    (_, index) => {
      remove(index)
    },
    [remove]
  )

  const onChange = useCallback(
    (newAction, index, shouldValidate = true) => {
      setValue(`actions.${index}`, newAction, {
        shouldDirty: true,
        shouldValidate,
      })
      // shouldValidate isn't enough for revalidate the field
      trigger(`actions.${index}`)
    },
    [setValue, trigger]
  )

  const onBlur = useCallback(
    index => {
      trigger(`actions.${index}`)
    },
    [trigger]
  )

  return (
    <div>
      {fields.length === 0 && (
        <AutomationItem.Button onClick={onAdd}>
          Add an action
        </AutomationItem.Button>
      )}
      {fields.map((item, index) => {
        // eslint-disable-next-line camelcase
        const { id, key } = item
        return (
          <ActionWithSearch
            // eslint-disable-next-line react/no-array-index-key
            control={control}
            name={`actions.${index}`}
            key={`action-${id}-${key}`}
            itemKey={`action-${id}-${key}`}
            variables={
              isPaidAccount ? ruleActionsForPaid : ruleActionsForUnpaid
            }
            index={index}
            onRemoveAction={onRemove}
            onBlur={onBlur}
            onActionChange={onChange}
            onInput={onChange}
            parameterSectionTitles={ruleActionsParemetersSectionTitles}
            actionErrorMessage={errors && errors[index]?.message}
          />
        )
      })}
      {fields.length > 0 && (
        <AutomationItem.Button onClick={onAdd}>
          Add another action
        </AutomationItem.Button>
      )}
    </div>
  )
}
