import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { AdminAccessDrawer } from 'subapps/settings/components/drawers/NoAccess'
import FolderDataTable from 'subapps/settings/components/FolderDataTable'
import Tabbed from 'subapps/settings/components/drawers/Tabbed'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import { useDrawer } from 'ducks/drawers/hooks'
import { DRAWER_TYPE_FOLDERS_UPDATE } from 'ducks/drawers/types'
import { doBuildInboxMenuFromMailboxes } from 'ducks/folders/operations/collections'
import { FEATURE_INBOX_MAX_FOLDERS } from 'ducks/billing/featureTypes'
import { useFeature } from 'ducks/billing/hooks'

const tabsData = [
  {
    key: 'active',
    tabName: 'Active',
    component: FolderDataTable,
    state: 'ACTIVE',
  },
  {
    key: 'inactive',
    tabName: 'Inactive',
    component: FolderDataTable,
    state: 'INACTIVE',
  },
]

const FolderOverview = ({
  drawerTabId = tabsData[0].key,
  open,
  onExit,
  updateDrawerParameter,
}) => {
  const dispatch = useDispatch()
  const { canUseFeature } = useFeature(FEATURE_INBOX_MAX_FOLDERS)
  const showPlanLimitation = !canUseFeature

  const handleTabKeyChange = useCallback(
    key => {
      if (key === drawerTabId) return
      updateDrawerParameter('drawerTabId', key)
    },
    [drawerTabId, updateDrawerParameter]
  )
  const activeTab = useMemo(
    () => {
      return tabsData.find(t => t.key === drawerTabId) || tabsData[0]
    },
    [drawerTabId]
  )

  const { openDrawer: openCreateDrawer } = useDrawer({
    type: DRAWER_TYPE_FOLDERS_UPDATE,
  })

  const handleOnCreate = useCallback(
    () => {
      openCreateDrawer('new')
    },
    [openCreateDrawer]
  )

  const HeaderButtons = useCallback(
    () => {
      return (
        <div>
          <Button
            size="small"
            onClick={handleOnCreate}
            disabled={showPlanLimitation}
          >
            Create {app.t('folder')}
          </Button>
        </div>
      )
    },
    [handleOnCreate, showPlanLimitation]
  )

  const beforeDrawerClose = useCallback(
    () => {
      dispatch(doBuildInboxMenuFromMailboxes())
    },
    [dispatch]
  )

  const handleOnExit = useCallback(
    () => {
      beforeDrawerClose()
      onExit()
    },
    [beforeDrawerClose, onExit]
  )

  return (
    <AdminAccessDrawer
      title={app.t('Folders')}
      open={open}
      onClose={handleOnExit}
      size="wide"
    >
      <Tabbed
        tabsData={tabsData}
        activeTabKey={drawerTabId}
        onTabKeyChange={handleTabKeyChange}
        HeaderButtons={HeaderButtons}
        compact
        state={activeTab.state}
      />
    </AdminAccessDrawer>
  )
}

export default FolderOverview
