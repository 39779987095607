import * as dt from 'constants/draft_types'
import { emptyObj } from 'util/objects'
import { humanFileSize } from 'util/strings'

// This body size includes the inlined attachments
export const DRAFT_MAX_BODY_SIZE = 1024 * 1024 * 10 // 10MB
export const DRAFT_BODY_SIZE_EXCEED_MAX_LIMIT_MSG = `Message Content Exceeds ${humanFileSize(
  DRAFT_MAX_BODY_SIZE
)} Limit`

export const ticketHasAttachments = ticket =>
  ticket && ticket.attachments && !!Object.keys(ticket.attachments).length

export const translateDraftTypeToConversationType = draftType => {
  switch (draftType) {
    case dt.NOTE:
      return 'internal'
    case dt.FORWARD:
      return 'third_party'
    default:
      return 'enduser'
  }
}

// NOTE (jscheel): draft.type is the proper way to check moving forward. the is*
// functions are deprecated and should not be relied on. Leaving them in for now
// until we are certain these have become totally unused. This function has a lot
// of deprecated assumptions that can go away, but it is a safeguard for now.
export const calculateConversationType = draft => {
  if (draft.isNote || draft.type === dt.NOTE) {
    return translateDraftTypeToConversationType(dt.NOTE)
  } else if (
    draft.isForwarding ||
    draft.forward ||
    draft.type === dt.FORWARD ||
    (draft.to && draft.to.length > 0 && draft.to[0].type === 'Collaborator')
  ) {
    return translateDraftTypeToConversationType(dt.FORWARD)
  }
  return translateDraftTypeToConversationType(dt.REPLY_ALL)
}

export const isAddingNote = type => type === dt.NOTE
export const isForwarding = type => type === dt.FORWARD
export const isReplyingAll = type => type === dt.REPLY_ALL
export const isReplyingDirect = type => type === dt.REPLY_DIRECT
export const isReplying = type => isReplyingAll(type) || isReplyingDirect(type)

export const getFirstOrEmptyFromDraftRecipient = (type, draft) =>
  draft && Array.isArray(draft[type]) && draft[type].length > 0
    ? draft[type][0]
    : emptyObj

export const trimEmptyDrafts = (draftLookup = {}) => {
  const trimmedDrafts = {}
  Object.keys(draftLookup).forEach(key => {
    if (draftLookup[key].body) {
      trimmedDrafts[key] = draftLookup[key]
    }
  })
  return trimmedDrafts
}

export const isDraftBodySizeWithinLimit = draftBodySizeBytes => {
  if (!draftBodySizeBytes) return true

  return draftBodySizeBytes <= DRAFT_MAX_BODY_SIZE
}
