import { deepCopy } from 'util/objects'
import debug from 'util/debug'
import md5 from 'util/md5'

let currentSnapshot = null

const takeSnapshot = state => {
  const sizes = {}
  const keys = []
  for (const key in state) {
    try {
      sizes[key] = JSON.stringify(state[key]).length
    } catch (e) {
      sizes[key] = 0
    }
    for (const subkey in state[key]) {
      const value = state[key][subkey]
      const sizeKey = `${key}.${subkey}`
      try {
        sizes[sizeKey] = value ? JSON.stringify(value).length : 0
      } catch (e) {
        sizes[sizeKey] = 0
      }
    }
  }
  return sizes
}

const printSnapshot = ({ title, totalDiff, diff, sizesBefore, sizesAfter }) => {
  const headerCSS = [
    'color: gray; font-weight: lighter;',
    'color: black; font-weight: bold;',
  ]
  const parts = ['memory']
  let sign = ''
  if (totalDiff > 0) {
    headerCSS.push('color: red; font-weight: bold;')
    sign = '+'
  } else if (totalDiff < 0) {
    headerCSS.push('color: green; font-weight: bold;')
  } else {
    headerCSS.push('color: #03A9F4; font-weight: bold;')
  }
  parts.push(`%c${title}`)
  parts.push(`%c${sign}${String(totalDiff)}`)

  console.groupCollapsed(`%c ${parts.join(' ')}`, ...headerCSS)
  if (sizesBefore) {
    const styles = `color: #F20404; font-weight: bold;`
    console.log('%c before', styles, sizesBefore)
  }
  if (diff) {
    const styles = `color: #03A9F4; font-weight: bold;`
    console.log('%c diff', styles, diff)
  }
  if (sizesAfter) {
    const styles = `color: #4CAF50; font-weight: bold;`
    console.log('%c after', styles, sizesAfter)
  }
  console.groupEnd()
}

const computeDiff = (sizesBefore, sizesAfter) => {
  let total = 0
  const details = {}

  const allKeys = [...Object.keys(sizesBefore), ...Object.keys(sizesAfter)]
  const uniqueKeys = Array.from(new Set(allKeys))

  uniqueKeys.forEach(key => {
    const keyDiff = (sizesAfter[key] || 0) - (sizesBefore[key] || 0)
    if (keyDiff != 0) {
      details[key] = keyDiff
      if (!key.match(/\./)) total += keyDiff
    }
  })

  return { total, details }
}

let previousUsedJSHeapSize = null
let monitorUsageInterval = null
const monitorUsage = () => {
  const jsHeapSizeLimit = window.performance.memory.usedJSHeapSize
  if (previousUsedJSHeapSize) {
    printSnapshot({
      title: 'MANUAL PROFILE',
      totalDiff: jsHeapSizeLimit - previousUsedJSHeapSize,
    })
  }
  previousUsedJSHeapSize = jsHeapSizeLimit
}

const startMonitoringUsage = () => {
  monitorUsageInterval = setInterval(monitorUsage, 5000)
}

const stopMonitoringUsage = () => {
  clearInterval(monitorUsageInterval)
}

const captureMemory = () => {
  return {
    totalJSHeapSize: window.performance.memory.totalJSHeapSize,
    jsHeapSizeLimit: window.performance.memory.jsHeapSizeLimit,
    usedJSHeapSize: window.performance.memory.usedJSHeapSize,
  }
}

const runProfile = func => {
  const sizesBefore = captureMemory()
  func()
  const sizesAfter = captureMemory()
  const diff = computeDiff(sizesBefore, sizesAfter)
  diff.total = sizesAfter.usedJSHeapSize - sizesBefore.usedJSHeapSize

  printSnapshot({
    title: 'RAN PROFILE',
    totalDiff: diff.total,
    diff: diff.details,
    sizesBefore,
    sizesAfter,
  })
}

const startProfile = app => () => {
  const sizesBefore = captureMemory()
  currentSnapshot = sizesBefore
}

const stopProfile = app => () => {
  const sizesAfter = captureMemory()
  const sizesBefore = currentSnapshot
  const diff = computeDiff(sizesBefore, sizesAfter)
  diff.total = sizesAfter.usedJSHeapSize - sizesBefore.usedJSHeapSize

  printSnapshot({
    title: 'MANUAL PROFILE',
    totalDiff: diff.total,
    diff: diff.details,
    sizesBefore,
    sizesAfter,
  })

  currentSnapshot = null
}

const middleware = ({ getState }) => next => action => {
  const sizesBefore = takeSnapshot(getState())

  const memBefore = captureMemory()
  const returnedValue = next(action)
  const memAfter = captureMemory()
  const diff = computeDiff(memBefore, memAfter)
  diff.total = memAfter.usedJSHeapSize - memBefore.usedJSHeapSize

  printSnapshot({
    title: action.type,
    totalDiff: diff.total,
    diff: diff.details,
    sizesBefore: memBefore,
    sizesAfter: memAfter,
  })

  return returnedValue
}

const installProfiling = app => {
  return {
    startProfile: startProfile(app),
    stopProfile: stopProfile(app),
    runProfile,
    startMonitoringUsage,
    stopMonitoringUsage,
  }
}

module.exports = {
  installProfiling,
  middleware,
}
