export function hasCookiesEnabled() {
  let cookieEnabled = navigator.cookieEnabled
  if (!cookieEnabled) {
    document.cookie = 'grv-testcookie'
    cookieEnabled = document.cookie.indexOf('grv-testcookie') !== -1
  }
  return cookieEnabled
}

let visibilityNames
if (document.hidden !== undefined) {
  visibilityNames = {
    hidden: 'hidden',
    visibilityChange: 'visibilitychange',
    visibilityState: 'visibilityState',
  }
} else if (document.msHidden !== undefined) {
  visibilityNames = {
    hidden: 'msHidden',
    visibilityChange: 'msvisibilitychange',
    visibilityState: 'msVisibilityState',
  }
} else if (document.webkitHidden !== undefined) {
  visibilityNames = {
    hidden: 'webkitHidden',
    visibilityChange: 'webkitvisibilitychange',
    visibilityState: 'webkitVisibilityState',
  }
}
export const isPageVisibilityApiEnabled = !!visibilityNames
export const prefixedHiddenKey = visibilityNames && visibilityNames.hidden
export const prefixedVisibilityState = visibilityNames
  ? visibilityNames.visibilityState
  : null
export const prefixedVisibilityChangeEventType = visibilityNames
  ? visibilityNames.visibilityChange
  : null
export function isDocumentHidden() {
  if (isPageVisibilityApiEnabled) {
    return document[prefixedHiddenKey]
  }
  return false
}
export function getVisibilityState() {
  if (isPageVisibilityApiEnabled) {
    return document[prefixedVisibilityState]
  }
  return 'visible'
}

export const isStandalone = !!window.navigator.standalone

export function isIOS() {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
}

export const isWebPushSupported = () =>
  navigator &&
  window &&
  'serviceWorker' in navigator &&
  'Notification' in window &&
  'PushManager' in window

export const getUserAgent = () =>
  navigator.userAgent || navigator.vendor || window.opera || ''

// Basic device detection based on [given] userAgent.
//
// References https://github.com/barisaydinoglu/Detectizr, but does not include
// version information, like the original. This is so we can do direct comparison
// of stringified objects, to see if the device+browser are the 'same'
//
// Returns an object with this structure;
//
//    {
//      browser: { engine: 'webkit', name: 'chrome' },
//      device: { model: 'mac', type: 'desktop' },
//      os: { name: 'mac os' },
//    }
//
export const detect = userAgent => {
  if (!userAgent) return undefined

  const ua = userAgent.toLowerCase()
  const deviceTypes = ['tv', 'tablet', 'mobile', 'desktop']

  // Helpers
  const is = key => ua.indexOf(key) > -1
  const test = regex => regex.test(ua)
  const exec = regex => regex.exec(ua)

  const device = {
    type: '',
    model: '',
  }
  if (
    test(
      // eslint-disable-next-line no-useless-escape
      /googletv|smarttv|smart-tv|internet.tv|netcast|nettv|appletv|boxee|kylo|roku|dlnadoc|roku|pov_tv|hbbtv|ce\-html/
    )
  ) {
    // Check if user agent is a smart tv
    device.type = deviceTypes[0]
    device.model = 'smartTv'
  } else if (test(/xbox|playstation.3|wii/)) {
    // Check if user agent is a game console
    device.type = deviceTypes[0]
    device.model = 'gameConsole'
  } else if (test(/ip(a|ro)d/)) {
    // Check if user agent is a iPad
    device.type = deviceTypes[1]
    device.model = 'ipad'
  } else if (
    (test(/tablet/) && !test(/rx-34/) && !test(/shield/)) ||
    test(/folio/)
  ) {
    // Check if user agent is a Tablet
    device.type = deviceTypes[1]
    device.model = String(exec(/playbook/) || '')
  } else if (
    test(/linux/) &&
    test(/android/) &&
    !test(/fennec|mobi|htc.magic|htcX06ht|nexus.one|sc-02b|fone.945/)
  ) {
    // Check if user agent is an Android Tablet
    device.type = deviceTypes[1]
    device.model = 'android'
  } else if (test(/kindle/) || (test(/mac.os/) && test(/silk/))) {
    // Check if user agent is a Kindle or Kindle Fire
    device.type = deviceTypes[1]
    device.model = 'kindle'
  } else if (
    test(
      // eslint-disable-next-line no-useless-escape
      /gt-p10|sc-01c|shw-m180s|sgh-t849|sch-i800|shw-m180l|sph-p100|sgh-i987|zt180|htc(.flyer|\_flyer)|sprint.atp51|viewpad7|pandigital(sprnova|nova)|ideos.s7|dell.streak.7|advent.vega|a101it|a70bht|mid7015|next2|nook/
    ) ||
    (test(/mb511/) && test(/rutem/))
  ) {
    // Check if user agent is a pre Android 3.0 Tablet
    device.type = deviceTypes[1]
    device.model = 'android'
  } else if (test(/bb10/)) {
    // Check if user agent is a BB10 device
    device.type = deviceTypes[2]
    device.model = 'blackberry'
  } else {
    // Check if user agent is one of common mobile types
    device.model = exec(
      /iphone|ipod|android|blackberry|opera mini|opera mobi|skyfire|maemo|windows phone|palm|iemobile|symbian|symbianos|fennec|j2me/
    )
    if (device.model !== null) {
      device.type = deviceTypes[2]
      device.model = String(device.model)
    } else {
      device.model = ''
      if (
        test(
          /bolt|fennec|iris|maemo|minimo|mobi|mowser|netfront|novarra|prism|rx-34|skyfire|tear|xv6875|xv6975|google.wireless.transcoder/
        )
      ) {
        // Check if user agent is unique Mobile User Agent
        device.type = deviceTypes[2]
      } else if (
        test(/opera/) &&
        test(/windows.nt.5/) &&
        // eslint-disable-next-line no-useless-escape
        test(/htc|xda|mini|vario|samsung\-gt\-i8000|samsung\-sgh\-i9/)
      ) {
        // Check if user agent is an odd Opera User Agent - http://goo.gl/nK90K
        device.type = deviceTypes[2]
      } else if (
        (test(/windows.(nt|xp|me|9)/) && !test(/phone/)) ||
        test(/win(9|.9|nt)/) ||
        test(/\(windows 8\)/)
      ) {
        // Check if user agent is Windows Desktop, '(Windows 8)' Chrome extra exception
        device.type = deviceTypes[3]
      } else if (test(/macintosh|powerpc/) && !test(/silk/)) {
        // Check if agent is Mac Desktop
        device.type = deviceTypes[3]
        device.model = 'mac'
      } else if (test(/linux/) && test(/x11/)) {
        // Check if user agent is a Linux Desktop
        device.type = deviceTypes[3]
      } else if (test(/solaris|sunos|bsd/)) {
        // Check if user agent is a Solaris, SunOS, BSD Desktop
        device.type = deviceTypes[3]
      } else if (test(/cros/)) {
        // Check if user agent is a Chromebook
        device.type = deviceTypes[3]
      } else if (
        test(
          /bot|crawler|spider|yahoo|ia_archiver|covario-ids|findlinks|dataparksearch|larbin|mediapartners-google|ng-search|snappy|teoma|jeeves|tineye/
        ) &&
        !test(/mobile/)
      ) {
        // Check if user agent is a Desktop BOT/Crawler/Spider
        device.type = deviceTypes[3]
        device.model = 'crawler'
      } else {
        // Otherwise assume it is a Mobile Device
        device.type = deviceTypes[2]
      }
    }
  }

  const os = {}
  if (device.model !== '') {
    if (
      device.model === 'ipad' ||
      device.model === 'iphone' ||
      device.model === 'ipod'
    ) {
      os.name = 'ios'
    } else if (device.model === 'android') {
      os.name = 'android'
    } else if (device.model === 'blackberry') {
      os.name = 'blackberry'
    } else if (device.model === 'playbook') {
      os.name = 'blackberry'
    }
  }
  if (!os.name) {
    if (is('win') || is('16bit')) {
      os.name = 'windows'
    } else if (is('mac') || is('darwin')) {
      os.name = 'mac os'
    } else if (is('webtv')) {
      os.name = 'webtv'
    } else if (is('x11') || is('inux')) {
      os.name = 'linux'
    } else if (is('sunos')) {
      os.name = 'sun'
    } else if (is('irix')) {
      os.name = 'irix'
    } else if (is('freebsd')) {
      os.name = 'freebsd'
    } else if (is('bsd')) {
      os.name = 'bsd'
    }
  }

  const browser = {}
  // eslint-disable-next-line no-useless-escape
  if (!test(/opera|webtv/) && (test(/msie\s([\d\w\.]+)/) || is('trident'))) {
    browser.engine = 'trident'
    browser.name = 'ie'
  } else if (is('firefox')) {
    browser.engine = 'gecko'
    browser.name = 'firefox'
  } else if (is('gecko/')) {
    browser.engine = 'gecko'
  } else if (is('opera')) {
    browser.name = 'opera'
    browser.engine = 'presto'
  } else if (is('konqueror')) {
    browser.name = 'konqueror'
  } else if (is('edge')) {
    browser.engine = 'webkit'
    browser.name = 'edge'
  } else if (is('chrome')) {
    browser.engine = 'webkit'
    browser.name = 'chrome'
  } else if (is('iron')) {
    browser.engine = 'webkit'
    browser.name = 'iron'
  } else if (is('crios')) {
    browser.name = 'chrome'
    browser.engine = 'webkit'
  } else if (is('fxios')) {
    browser.name = 'firefox'
    browser.engine = 'webkit'
  } else if (is('applewebkit/')) {
    browser.name = 'safari'
    browser.engine = 'webkit'
  } else if (is('safari')) {
    browser.name = 'safari'
    browser.engine = 'webkit'
  } else if (is('mozilla/')) {
    browser.engine = 'gecko'
  }

  return {
    device,
    os,
    browser,
  }
}

export const getBrowser = () => {
  return detect(getUserAgent())
}

export function isMobile() {
  const { device } = getBrowser()
  if (!device) return false
  const type = device.type
  return type === 'mobile'
}

export const isMac = () => {
  return detect(getUserAgent()).os.name === 'mac os'
}

export const isWindows = () => {
  return detect(getUserAgent()).os.name === 'windows'
}

export function isSafari(userAgent) {
  const { browser } = detect(userAgent || getUserAgent())
  if (!browser) return false
  return /safari/.test(browser.name)
}

export function isEdge(userAgent) {
  const { browser } = detect(userAgent || getUserAgent())
  if (!browser) return false
  return /edge/.test(browser.name)
}

export function isSafari10(userAgent) {
  const ua = userAgent || getUserAgent()
  if (!isSafari(ua)) return false
  return /Version\/10\./.test(ua)
}

export function isIE11(userAgent) {
  const ua = userAgent || getUserAgent()
  const { browser } = detect(ua)
  if (!browser) return false
  return /ie/.test(browser.name) && /rv:11\./.test(ua)
}

// Source: https://github.com/react-dropzone/react-dropzone/blob/master/src/utils/index.js
export function getDataTransferItems(event) {
  let dataTransferItemsList = []
  if (event.dataTransfer) {
    const dt = event.dataTransfer
    if (dt.files && dt.files.length) {
      dataTransferItemsList = dt.files
    } else if (dt.items && dt.items.length) {
      // During the drag even the dataTransfer.files is null
      // but Chrome implements some drag store, which is accesible via dataTransfer.items
      dataTransferItemsList = dt.items
    }
  } else if (event.target && event.target.files) {
    dataTransferItemsList = event.target.files
  }
  // Convert from DataTransferItemsList to the native Array
  return Array.prototype.slice.call(dataTransferItemsList)
}

export function convertImageToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      const image = new Image()
      image.src = reader.result
      image.onload = () => {
        resolve({
          name: file.name,
          type: file.type,
          size: file.size,
          src: reader.result,
          width: image.width,
          height: image.height,
        })
      }
    }
    reader.onerror = error => reject(error)
  })
}
