import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { doDeleteCustomerRatingSetting } from 'ducks/customerRatingSettings/operations'

import EntityDeleteDrawer from '../EntityDelete'

const CustomeRatingSettingDelete = props => {
  const { deleteBtnText, open, drawerResourceId, onClose, onExit } = props

  const dispatch = useDispatch()

  const onRemove = useCallback(
    () => {
      dispatch(doDeleteCustomerRatingSetting(drawerResourceId))
    },
    [dispatch, drawerResourceId]
  )

  return (
    <EntityDeleteDrawer
      {...props}
      onRemove={onRemove}
      onClose={onClose}
      onExit={onExit}
      onCancel={onClose}
      title="Delete satisfaction ratings"
      deleteBtnText={deleteBtnText}
      deleteBtn
      tertiaryBtnText={'Cancel'}
      open={open}
      skipProceedQuestion
      hardDelete
    >
      Deleting this will not delete any responses that have been collected
      already.
    </EntityDeleteDrawer>
  )
}

export default CustomeRatingSettingDelete
