import React, { useCallback, useMemo } from 'react'
import { useController } from 'react-hook-form'
import ChannelSelectionTable from 'subapps/settings/components/ChannelSelectionTable'
import { styles } from '../../styles'

const MailboxesTable = React.memo(
  ({ defaultValue = [], control, name, ...rest }) => {
    const {
      field: { onChange },
    } = useController({
      name,
      control,
      defaultValue,
    })

    const defaultSelectedRowIds = useMemo(
      () => {
        return defaultValue.reduce((memo, id) => {
          // eslint-disable-next-line no-param-reassign
          memo[id] = true
          return memo
        }, {})
      },
      [defaultValue]
    )

    const handleIdsChange = useCallback(
      ids => {
        onChange(Object.keys(ids))
      },
      [onChange]
    )

    return (
      <ChannelSelectionTable
        separate
        css={styles.table}
        onSelectedRowIdsChange={handleIdsChange}
        defaultSelectedRowIds={defaultSelectedRowIds}
        {...rest}
      />
    )
  }
)

export default MailboxesTable
