import React, { useState, useMemo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import ButtonList from '@groovehq/internal-design-system/lib/components/ButtonList/ButtonList'
import { AdminAccessDrawer } from 'subapps/settings/components/drawers/NoAccess'
import { useDrawer } from 'ducks/drawers/hooks'
import { buildDrawerQueryParam, getCurrentDrawerId } from 'ducks/drawers/util'
import HeaderWithImage from '@groovehq/internal-design-system/lib/components/HeaderWithImage/HeaderWithImage'
import { selectIsInChat, selectQueryParams } from 'selectors/location'
import { useFeature } from 'ducks/billing/hooks'
import { FEATURE_INBOX_LIVE_CHAT } from 'ducks/billing/featureTypes'
import PlanLimitationCard from 'subapps/settings/components/PlanLimitationCard'
import { CHANNEL_MAIN_TYPES, CHANNEL_ADDITIONAL_TYPES } from '../Channels.data'

const channelKeysInOrder = [
  'email',
  'chat',
  'contact_widget',
  'instagram',
  'facebook',
]

const AddChannelDrawer = ({ onExit }) => {
  const dispatch = useDispatch()
  const [selectedChannel, setSelectedChannel] = useState(null)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const queryParams = useSelector(selectQueryParams)
  const isInChat = useSelector(selectIsInChat)
  const { canUseFeature: canUseLiveChat } = useFeature(FEATURE_INBOX_LIVE_CHAT)
  // get the current drawer id from the url string in browser address bar
  const currentDrawerId = getCurrentDrawerId(queryParams)
  const ALL_CHANNEL_TYPES = Object.assign(
    CHANNEL_MAIN_TYPES,
    CHANNEL_ADDITIONAL_TYPES
  )
  const currentChannelType = useMemo(
    () => ALL_CHANNEL_TYPES[selectedChannel] || {},
    [ALL_CHANNEL_TYPES, selectedChannel]
  )

  const { drawerId: nextDrawerId, openDrawer: openNextDrawer } = useDrawer(
    currentChannelType.drawer
  )

  const channelList = useMemo(
    () => {
      return channelKeysInOrder
        .map(type => {
          const channel = {
            type,
            ...ALL_CHANNEL_TYPES[type],
          }
          return channel
        })
        .filter(c => !isInChat || c.type !== 'email')
        .filter(c => canUseLiveChat || c.type === 'email')
    },
    [ALL_CHANNEL_TYPES, isInChat, canUseLiveChat]
  )

  useEffect(
    () => {
      if (isDrawerOpen && selectedChannel && currentDrawerId !== nextDrawerId) {
        // prevent drawer from being triggered to open again if the drawer is closed
        // i.e. closed being, the previous state's drawer is missing/changed from address bar query
        setSelectedChannel(null)
        setIsDrawerOpen(false)
      } else if (selectedChannel && currentDrawerId !== nextDrawerId) {
        let showDrawer = true
        if (currentChannelType.doBeforeDrawer) {
          showDrawer = dispatch(currentChannelType.doBeforeDrawer())
        }
        setIsDrawerOpen(true)
        if (!showDrawer) return

        if (currentChannelType?.drawer) {
          // now useDrawer is initialised with value from currentDrawer
          // invoke openDrawer
          openNextDrawer('new', {
            query: {
              ...buildDrawerQueryParam(
                nextDrawerId,
                'drawerChannelType',
                selectedChannel
              ),
            },
          })
        }
      }
    },
    [
      currentDrawerId,
      nextDrawerId,
      selectedChannel,
      openNextDrawer,
      isDrawerOpen,
      currentChannelType,
      dispatch,
    ]
  )

  const footer = (
    <ModalBtns tertiaryBtnText="Close" onClickTertiaryBtn={onExit} />
  )

  return (
    <AdminAccessDrawer
      title="Add a channel"
      footer={footer}
      onClose={onExit}
      data-test-id="drawer-choose-channel"
    >
      <HeaderWithImage
        className="grui mt-14 mb-12"
        title="Choose a channel"
        intro="Let's get your conversations flowing into Groove."
      />
      <ButtonList
        list={channelList}
        onSelect={setSelectedChannel}
        selected={selectedChannel}
      />
      {!canUseLiveChat && (
        <PlanLimitationCard
          className="grui mt-8"
          title="Need to Live chat, Facebook or Instagram?"
          size="small"
          trackingFeatureType={FEATURE_INBOX_LIVE_CHAT}
        >
          Upgrade your account to add live chat, Facebook or Instagram.
        </PlanLimitationCard>
      )}
    </AdminAccessDrawer>
  )
}
export default AddChannelDrawer
