import React, { useCallback } from 'react'
import { AdminAccessDrawer } from 'subapps/settings/components/drawers/NoAccess'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import MainMenu from '@groovehq/internal-design-system/lib/components/MainMenu/MainMenu'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import TeamsAccordion from 'subapps/settings/components/TeamsAccordion'
import { DRAWER_TYPE_TEAM_UPDATE } from 'ducks/drawers/types'
import { useDrawer } from 'ducks/drawers/hooks'

const TeamsOverview = ({ open, onClose, onExit }) => {
  const { openDrawer: openEditTeamDrawer } = useDrawer(DRAWER_TYPE_TEAM_UPDATE)

  const handleOnCreateTeam = useCallback(
    () => {
      openEditTeamDrawer('new')
    },
    [openEditTeamDrawer]
  )

  return (
    <AdminAccessDrawer
      title={app.t('Teams')}
      open={open}
      onClose={onExit}
      size="wideXL"
      footer={
        <ModalBtns tertiaryBtnText="Close" onClickTertiaryBtn={onClose} />
      }
    >
      <MainMenu>
        <Button size="small" onClick={handleOnCreateTeam}>
          Create {app.t('team_with_article')}
        </Button>
      </MainMenu>
      <TeamsAccordion />
    </AdminAccessDrawer>
  )
}

export default TeamsOverview
