import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import Table from '@groovehq/internal-design-system/lib/components/Table/Table'
import { useDrawer } from 'ducks/drawers/hooks'
import { DRAWER_TYPE_SHOPIFY_STORE_UPDATE } from 'ducks/drawers/types'
import { selectPendingIntegrationInstallStateProviderValuesByProviderId } from 'ducks/integrations/selectors/settings'

import useColumns from './useColumns'

const ShopifyStoresTable = ({ providerId }) => {
  const columns = useColumns()

  const stores = useSelector(state =>
    selectPendingIntegrationInstallStateProviderValuesByProviderId(
      state,
      providerId
    )
  )

  const { openDrawer: openEditStore } = useDrawer(
    DRAWER_TYPE_SHOPIFY_STORE_UPDATE
  )

  const onEditStore = useCallback(
    id => {
      openEditStore(id)
    },
    [openEditStore]
  )

  return (
    <Table
      columns={columns}
      data={stores}
      separate
      onEdit={onEditStore}
      emptyHint="No stores added"
    />
  )
}

export default ShopifyStoresTable
