import { useRef } from 'react'

const buckets = {}
const getUniqueId = ({ bucket: inputBucket }) => {
  const bucket = inputBucket || 'default'
  if (!buckets[bucket]) buckets[bucket] = 0
  buckets[bucket] += 1

  return buckets[bucket].toString()
}

export default function useComponentId(options = {}) {
  const idRef = useRef(getUniqueId(options))
  return idRef.current
}
