import React from 'react'
import { string, arrayOf } from 'prop-types'
import {
  text,
  paragraph,
} from '@groovehq/internal-design-system/lib/styles/elements'
import { useSlackApp } from 'ducks/integrations/slack/hooks'

const ChannelDeleteContent = ({ ids }) => {
  const {
    slackApp,
    channelsBySlackId,
    isLoading,
    isMissing,
    hasError,
  } = useSlackApp({
    preferCached: true,
  })

  if (isMissing || hasError) {
    return <span css={text.styles.fontMedium}>No results...</span>
  }

  if (isLoading || !slackApp) {
    return <span css={text.styles.fontMedium}>Loading...</span>
  }

  const appChannels = slackApp?.channels?.filter(c => ids.includes(c.gid)) || []

  if (ids.length === 1) {
    if (!channelsBySlackId || !appChannels?.[0]) return null

    const slackChannel = channelsBySlackId[appChannels[0].slack_id]
    return (
      <>
        <p css={[paragraph.styles.preflight, text.styles.textNormal]}>
          You are about to delete the channel “<span
            css={text.styles.fontMedium}
          >
            #{slackChannel.name}
          </span>”.
        </p>
      </>
    )
  }
  const totalToBeRemoved = ids.length
  const additional = totalToBeRemoved - appChannels.length

  return (
    <div css={text.styles.textNormal}>
      You are about to delete:
      {appChannels.map(appChannel => (
        <p key={appChannel.id} css={paragraph.styles.preflight}>
          <span css={text.styles.fontMedium}>
            <>“</>
            #{channelsBySlackId[appChannel.slack_id]}
            <>”</>
          </span>
        </p>
      ))}
      {additional > 0 && <p>and {additional} more channels.</p>}
    </div>
  )
}

ChannelDeleteContent.propTypes = {
  ids: arrayOf(string).isRequired,
}

export default ChannelDeleteContent
