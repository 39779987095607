import { createSelector } from 'reselect'
import { memoize } from 'util/memoization'
import {
  selectCurrentPath as currentPathSelector,
  selectCurrentPayload,
} from 'selectors/location'
import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'
import { selectAgentsByIdIncludingArchived } from 'selectors/agents/base'
import { selectCurrentKnowledgeBaseId } from 'subapps/kb/selectors/knowledge_bases'
import { selectActiveCategory } from 'subapps/kb/selectors/categories'

import { emptyObj, isEmpty, pick, mapObject } from 'util/objects'
import { emptyArr } from 'util/arrays'
import { selectCurrentUserIsAdminOrOwnerOrAgent } from 'ducks/currentUser/selectors'
import { base } from './base'

export const selectIds = state => base(state).ids || emptyArr
export const selectSearchIds = state => base(state).search.ids || emptyArr
export const selectById = state => base(state).byId || emptyObj
export const selectByContext = state => base(state).byContext || emptyObj

const DEFAULT_ARTICLE = {
  authorId: null,
  categoryId: null,
  createdAt: null,
  description: '',
  featured: false,
  id: 'new',
  knowledgeBaseId: null,
  metaDescription: '',
  metaRobots: 'INDEX,FOLLOW',
  ogDescription: '',
  ogImageUrl: '',
  ogTitle: '',
  pageTitle: '',
  slug: '',
  state: 'draft',
  tags: emptyArr,
  title: '',
  updatedAt: null,
  body: '',
}

// TODO (jscheel): This should not be here. Eventually, we either need to move
// this logic to GQL, or we need to change the data model so that index and
// follow are stored separately and the final robots tag value is computed at
// render time in the backend.
const decorateArticleSEOSettings = memoize(article => {
  const metaRobots = (article.metaRobots || '').split(',')
  return {
    metaIndex: metaRobots.includes('INDEX'),
  }
})

const decorateArticle = memoize(agentsById => {
  return memoize(article => {
    return {
      ...DEFAULT_ARTICLE,
      ...article,
      ...decorateArticleSEOSettings(article),
      author: agentsById[article.authorId],
    }
  })
})

export const selectArticlesById = createSelector(
  selectById,
  selectAgentsByIdIncludingArchived,
  (byId, agentsById) => {
    return mapObject(decorateArticle(agentsById))(byId || emptyObj)
  }
)

/*
 * Selectors for an active article
 */
export const selectArticleIsSaving = state => base(state).isSaving
export const selectIsArticleLoading = state => base(state).isLoading

// TODO (jscheel): I think the page props should be the source of this.
export const selectActiveArticleId = createSelector(
  currentPathSelector,
  path => {
    if (!path) return null
    if (path.match(/\/kb\/[0-9]+\/articles\/create/)) {
      return 'new'
    }
    if (path.match(/\/kb\/[0-9]+\/articles\/edit\/[0-9]+/)) {
      return path.match(/\/kb\/[0-9]+\/articles\/edit\/([0-9]+)/)[1]
    }
    return null
  }
)

export const selectActiveArticle = createSelector(
  selectArticlesById,
  selectActiveArticleId,
  selectCurrentUser,
  selectAgentsByIdIncludingArchived,
  selectCurrentKnowledgeBaseId,
  (byId, articleId, currentUser, agentsById, knowledgeBaseId) => {
    let found = byId[articleId]
    if (articleId === 'new') {
      found = decorateArticle(agentsById)({
        id: 'new',
        authorId: currentUser.id,
        state: 'draft',
        knowledgeBaseId,
      })
    }
    return found || emptyObj
  }
)

export const selectArticleIsSaved = createSelector(
  selectActiveArticle,
  article => !isEmpty(article) && article.id !== 'new'
)

export const selectArticleIsPublished = createSelector(
  selectActiveArticle,
  article => article.state === 'published'
)

export const selectArticleIsWip = createSelector(
  selectActiveArticle,
  article => article.state === 'wip'
)

export const selectArticleIsDraft = createSelector(
  selectActiveArticle,
  article => article.state === 'draft'
)

export const selectActiveArticleSEOSettings = createSelector(
  selectActiveArticle,
  article => {
    return pick(
      ['pageTitle', 'metaDescription', 'metaIndex'],
      { ...DEFAULT_ARTICLE, ...article },
      true
    )
  }
)

export const selectActiveArticleOgSettings = createSelector(
  selectActiveArticle,
  article => {
    return pick(
      ['ogTitle', 'ogDescription', 'ogImageUrl'],
      { ...DEFAULT_ARTICLE, ...article },
      true
    )
  }
)

export const selectActiveArticleTagSettings = createSelector(
  selectActiveArticle,
  article => {
    return pick(['tags'], { ...DEFAULT_ARTICLE, ...article }, true)
  }
)

export const selectActiveArticleMetaSettings = createSelector(
  selectActiveArticle,
  article => {
    return pick(
      ['authorId', 'categoryId', 'featured'],
      { ...DEFAULT_ARTICLE, ...article },
      true
    )
  }
)

export const selectActiveArticleSlug = createSelector(
  selectActiveArticle,
  article => {
    return article.slug === undefined || article.slug === null
      ? DEFAULT_ARTICLE.slug
      : article.slug
  }
)

export const selectActiveArticleSlugSettings = createSelector(
  selectActiveArticleSlug,
  slug => {
    return { slug }
  }
)

export const selectActiveArticleAuthorSettings = createSelector(
  selectActiveArticle,
  article => {
    return pick(['authorId'], { ...DEFAULT_ARTICLE, ...article }, true)
  }
)

export const selectActiveArticleContentFields = createSelector(
  selectActiveArticle,
  article => {
    return pick(
      ['title', 'description', 'body'],
      { ...DEFAULT_ARTICLE, ...article },
      true
    )
  }
)

export const selectActiveArticleAuthor = createSelector(
  selectActiveArticle,
  selectAgentsByIdIncludingArchived,
  (article, agentsById) => {
    return agentsById[article.authorId] || emptyObj
  }
)

export const selectActiveArticleUpdatedAt = createSelector(
  selectActiveArticle,
  article => article.updatedAt
)

export const selectActiveArticleRelatedArticleIds = createSelector(
  selectActiveArticle,
  article => {
    return pick(['relatedIds'], { ...DEFAULT_ARTICLE, ...article }, true)
  }
)

export const selectActiveArticleRelatedArticles = createSelector(
  selectActiveArticle,
  article => article.relatedArticles || emptyArr
)

/*
 * Selectors for search context articles
 */

function createContextualizedArticlesSelectors(contextName) {
  // eslint-disable-next-line no-underscore-dangle
  const _selectContext = createSelector(
    selectByContext,
    byContext => byContext[contextName] || emptyObj
  )

  // eslint-disable-next-line no-underscore-dangle
  const _selectContextIds = createSelector(
    _selectContext,
    context => context.ids || emptyArr
  )

  // eslint-disable-next-line no-underscore-dangle
  const _selectArticles = createSelector(
    selectArticlesById,
    _selectContextIds,
    (byId, ids) => ids.map(id => byId[id])
  )

  return {
    selectContext: _selectContext,
    selectArticles: _selectArticles,
  }
}

/*
 * Article list context
 */
const contextualizedSelectorsForArticleList = createContextualizedArticlesSelectors(
  'ARTICLE_LIST'
)
// Never use the entire context as prop, as it would cause performance issues
export const selectContextForArticleList =
  contextualizedSelectorsForArticleList.selectContext
export const selectArticlesForArticleList =
  contextualizedSelectorsForArticleList.selectArticles

/*
 * Category context
 */
const contextualizedSelectorsForCategoryList = createContextualizedArticlesSelectors(
  'CATEGORY_LIST'
)
// Never use the entire context as prop, as it would cause performance issues
export const selectContextForCategoryList =
  contextualizedSelectorsForCategoryList.selectContext
export const selectArticlesForCategoryList = createSelector(
  contextualizedSelectorsForCategoryList.selectArticles,
  selectActiveCategory,
  (articles, { articleIds }) =>
    articles.filter(a => a && articleIds?.includes(a.id))
)

/*
 * Category article list context
 */
const contextualizedSelectorsForCategoryArticleList = createContextualizedArticlesSelectors(
  'CATEGORY_ARTICLE_LIST'
)
// Never use the entire context as prop, as it would cause performance issues
export const selectContextForCategoryArticleList =
  contextualizedSelectorsForCategoryArticleList.selectContext
export const selectArticlesForCategoryArticleList = createSelector(
  contextualizedSelectorsForCategoryArticleList.selectArticles,
  selectActiveCategory,
  // exclude articles in the current category
  // since already added to it
  (articles, { articleIds }) =>
    articles.filter(a => !articleIds?.includes(a.id))
)

/*
 * Related articles context
 */
const contextualizedSelectorsForRelatedArticles = createContextualizedArticlesSelectors(
  'RELATED_ARTICLES'
)
export const selectContextForRelatedArticles =
  contextualizedSelectorsForRelatedArticles.selectContext
export const selectArticlesForRelatedArticles =
  contextualizedSelectorsForRelatedArticles.selectArticles

export const selectArticlesForRelatedArticlesForDropdown = createSelector(
  selectActiveArticle,
  selectArticlesForRelatedArticles,
  (currentArticle, foundArticles) =>
    foundArticles.reduce((articles, a) => {
      if (a.id !== currentArticle.id) {
        articles.push({ key: a.id, text: a.title, value: a.id })
      }
      return articles
    }, [])
)

export const selectCurrentArticlesState = createSelector(
  selectCurrentPayload,
  selectContextForArticleList,
  (payload, context) => payload.state || context.selectedState
)

export const selectSearchArticles = createSelector(
  selectArticlesById,
  selectSearchIds,
  (byId, ids) => ids.map(id => byId[id])
)

export const selectArticlesViews = state => base(state).views || emptyObj

export const selectArticlesStale = state => !!base(state).stale

export const selectIsLoadingArticlesCounts = state =>
  base(state).isLoadingCounts !== false

export const selectAllowedKbActions = createSelector(
  selectCurrentUserIsAdminOrOwnerOrAgent,
  isAdminOrOwnerOrAgent => {
    if (isAdminOrOwnerOrAgent) {
      return { edit: true, view: true, restore: true, delete: true }
    }
    return { edit: false, view: true, restore: false, delete: false }
  }
)
