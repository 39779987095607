import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import Avatar from '@groovehq/internal-design-system/lib/components/Avatar/Avatar'
import { selectCurrentUnarchivedAgentsFilteredByTeamId } from 'ducks/agents/selectors'
import { isBlank } from 'util/strings'
import { styles } from './styles'

function TeamsAccordionTitle({ teamId, name }) {
  const agents = useSelector(state =>
    selectCurrentUnarchivedAgentsFilteredByTeamId(state, teamId)
  )
  const limitedAgents = useMemo(() => agents.slice(0, 5), [agents])

  return (
    <>
      <div className="grui flex items-center">
        {`${name} (${agents.length})`}
        <div css={styles.avatars}>
          {limitedAgents.map(
            ({ id: agentId, avatar_url: avatarUrl, username, email }) => (
              <div key={agentId}>
                <Avatar
                  key={agentId}
                  avatar={avatarUrl}
                  userName={isBlank(username) ? email : username}
                />
              </div>
            )
          )}
        </div>
      </div>
    </>
  )
}

TeamsAccordionTitle.displayName = 'TeamsAccordionTitle'

export default TeamsAccordionTitle
