import graphql from 'api/graphql'
import { isBlank } from 'util/strings'
import * as types from 'subapps/kb/actions'
import { oauthTokenSelector } from 'selectors/app'
import { selectCurrentKnowledgeBaseId } from 'subapps/kb/selectors/knowledge_bases'

export function doFetchCategoriesCounts(_opts) {
  return (dispatch, getState) => {
    const opts = _opts || {}
    const state = getState()
    const token = oauthTokenSelector(state)
    const knowledgeBaseId = selectCurrentKnowledgeBaseId(state)

    const groups = opts.groups || ['state']

    dispatch({
      type: types.FETCH_CATEGORIES_COUNTS_REQUEST,
    })

    const query = `
      query CategoriesCountsQuery($knowledgeBaseId: ID!, $groups: [String]) {
        categoryCounts(
          knowledgeBaseId: $knowledgeBaseId
          groups: $groups
          keyword: "${isBlank(opts.keyword) ? '*' : opts.keyword}"
        ){
          counts
        }
      }
    `

    const variables = {
      knowledgeBaseId,
      groups,
    }

    return graphql(token, query, variables)
      .then(res => {
        const data = res.json.data
        dispatch({
          type: types.FETCH_CATEGORIES_COUNTS_SUCCESS,
          data: data.categoryCounts.counts,
        })
      })
      .catch(() => {
        dispatch({
          type: types.FETCH_CATEGORIES_COUNTS_FAILURE,
        })
      })
  }
}
