export default function wrapSearchValueInQuotesIfNeeded(value) {
  if (
    value &&
    value.match &&
    value.match(/\s|:/) &&
    // do not wrap if already wrapped
    !value.match(/^"/) &&
    !value.match(/"$/)
  )
    return `"${value}"`
  return value
}
