import Bugsnag from '@bugsnag/js'
import debug from 'debug'

const grooveDebug = debug('groove')
export const logError = debug('groove.error')

logError.log = console.error.bind(console) // eslint-disable-line no-console

const enableDebugs = () => {
  grooveDebug.enabled = true
  logError.enabled = true
}

const env = process.env.NODE_ENV || 'development'
if (env === 'development') {
  enableDebugs()
}
try {
  const subdomain = window.location.host.split('.')[0]
  if (subdomain === 'groove' || localStorage.getItem('debugMode')) {
    enableDebugs()
  }
} catch (e) {} // eslint-disable-line no-empty

export function consolePropDiff(props, previousProps) {
  Object.keys(props).forEach(key => {
    if (props[key] !== previousProps[key]) {
      console.log(key, props[key], previousProps[key]) // eslint-disable-line no-console
    }
  })
}

let enableBreadcrumbLogs = true
if (process.env.BREADCRUMB_LOGS?.toLowerCase() === 'off') {
  enableBreadcrumbLogs = false
}

export function leaveBreadcrumb(name, details) {
  Bugsnag.leaveBreadcrumb(name, details)
  if (enableBreadcrumbLogs) {
    grooveDebug(name, details)
  }
}

export default grooveDebug
