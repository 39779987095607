import { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useDispatch } from 'react-redux'
import MessageCard from '@groovehq/internal-design-system/lib/components/MessageCard/MessageCard'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'

import { doUpdateSlackAppV0 } from 'ducks/integrations/slack/operations'
import { useRhfDirtyHold } from 'util/dirtyHolds'
import { convertGidToRaw } from 'util/scatterSwap'

import DynamicPortal from 'components/DynamicPortal'
import ChannelDropdown from './ChannelDropdown'
import MailboxesTable from './MailboxesTable'
import NotificationsTable from './NotificationsTable'
import MailboxSpecificityToggle from './MailboxSpecificityToggle'

export const FORM_ID = 'slack-channel-form'

const FORM_SCHEMA = yup.object().shape({
  slackId: yup.string().required('Please select a Slack channel'),
  allMailboxes: yup.boolean(),
  mailboxGids: yup
    .array()
    .of(yup.string())
    .when('allMailboxes', {
      is: true,
      then: schema => schema.min(0),
      otherwise: schema => schema.min(1),
    }),
  notificationTypes: yup
    .array()
    .of(yup.string())
    .min(1),
})

const SlackChannelForm = ({
  slackApp,
  channelGid,
  formId,
  actionsPortalRef,
  actionsComponent: ActionsComponent,
  actionsComponentAdditionalProps,
  onSubmit: onSubmitProp,
  postInstall,
}) => {
  // NOTE (jscheel): It's easier to just generate a basic representation of the
  // form and then process the values as necessary on submit than to try and
  // use RHF to manage the entire slackApp's data structure as-is.
  const initialData = useMemo(
    () => {
      const isNew = channelGid === 'new'
      const channel = slackApp.channels.find(c => c.gid === channelGid) || {
        slackId: null,
        allMailboxes: false,
      }
      const retVal = {
        slackId: channel.slack_id,
        allMailboxes: isNew ? true : !!channel.all_mailboxes,
        mailboxGids: [],
        notificationTypes: [],
      }
      ;(slackApp.mailbox_settings || []).forEach(settings => {
        if (settings.channel_gid === channelGid) {
          retVal.mailboxGids.push(settings.mailbox_gid)
        }
      })
      ;(slackApp.notifications || []).forEach(notification => {
        if (notification.channel_gid === channelGid) {
          retVal.notificationTypes.push(notification.type)
        }
      })

      return retVal
    },
    [slackApp, channelGid]
  )

  const { handleSubmit, control, watch } = useForm({
    mode: 'all',
    defaultValues: initialData,
    resolver: yupResolver(FORM_SCHEMA),
    delayError: 300,
  })

  const { releaseHold } = useRhfDirtyHold(formId, control)
  const dispatch = useDispatch()

  const onSubmit = useCallback(
    async data => {
      const isNew = channelGid === 'new'
      const rawChannelId = convertGidToRaw(channelGid)
      const channelPayload = {}
      const payload = { channels: [channelPayload] }

      // Channel
      channelPayload.slack_id = data.slackId
      if (!isNew) {
        channelPayload.id = rawChannelId
      }

      // Mailboxes
      if (data.allMailboxes) {
        channelPayload.mailbox_settings = slackApp.mailbox_settings
          .filter(s => s.channel_gid === channelGid)
          .map(settings => {
            return { id: settings.id, _destroy: true }
          })
        channelPayload.all_mailboxes = true
      } else {
        channelPayload.all_mailboxes = false
        const existingMailboxGids = slackApp.mailbox_settings
          .filter(s => s.channel_gid === channelGid)
          .map(s => s.mailbox_gid)
        const addedGids = data.mailboxGids.filter(
          gid => !existingMailboxGids.includes(gid)
        )
        const deletedIds = existingMailboxGids.filter(
          gid => !data.mailboxGids.includes(gid)
        )
        channelPayload.mailbox_settings = addedGids
          .map(gid => {
            return {
              ...(!isNew && { channel_id: rawChannelId }),
              mailbox_id: convertGidToRaw(gid),
            }
          })
          .concat(
            deletedIds.map(gid => {
              return { id: convertGidToRaw(gid), _destroy: true }
            })
          )
      }

      // Notifications
      const existingNotifications = slackApp.notifications.filter(
        n => n.channel_gid === channelGid
      )
      const existingNotificationTypes = existingNotifications.map(n => n.type)
      const existingNotificationByType = existingNotifications.reduce(
        (memo, notification) => {
          // eslint-disable-next-line no-param-reassign
          memo[notification.type] = notification
          return memo
        },
        {}
      )
      const addedTypes = data.notificationTypes.filter(
        type => !existingNotificationTypes.includes(type)
      )
      const deletedTypes = existingNotificationTypes.filter(
        type => !data.notificationTypes.includes(type)
      )
      channelPayload.notifications = addedTypes
        .map(type => {
          return {
            type,
            ...(!isNew && { channel_id: rawChannelId }),
          }
        })
        .concat(
          deletedTypes.map(type => {
            return { id: existingNotificationByType[type].id, _destroy: true }
          })
        )

      await dispatch(doUpdateSlackAppV0(payload))
      releaseHold()
      onSubmitProp()
    },
    [slackApp, channelGid, dispatch, releaseHold, onSubmitProp]
  )

  const showMailboxesTable = !watch('allMailboxes')

  if (!slackApp) return null

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)}>
      {postInstall && (
        <MessageCard className="grui mt-8 mb-10">
          <strong css={text.styles.fontMedium}>Heads up!</strong> You will be
          able to setup additional Slack channels to receive notifications in
          later from the overview screen.
        </MessageCard>
      )}

      <div css={text.styles.fontMedium} className="grui mt-8 mb-3">
        What channel would you like to receive notifications on?
      </div>

      <ChannelDropdown
        control={control}
        defaultValue={initialData.slackId}
        name="slackId"
      />

      <div css={text.styles.fontMedium} className="grui mt-14 mb-3">
        Which {app.t('mailboxes')} would you like to receive notifications for?
      </div>

      <MailboxSpecificityToggle name="allMailboxes" control={control} />

      {showMailboxesTable && (
        <MailboxesTable
          name="mailboxGids"
          defaultValue={initialData.mailboxGids}
          control={control}
          className="grui mt-10"
        />
      )}
      <div css={text.styles.fontMedium} className="grui mt-14 mb-3">
        What actions would you like to receive notifications for?
      </div>
      <NotificationsTable
        name="notificationTypes"
        defaultValue={initialData.notificationTypes}
        control={control}
      />
      <DynamicPortal portalRef={actionsPortalRef} debug>
        <ActionsComponent
          {...actionsComponentAdditionalProps}
          control={control}
          formId={formId}
        />
      </DynamicPortal>
    </form>
  )
}

export default SlackChannelForm
