import styled from '@emotion/styled'

export default styled('div')`
  flex-grow: 1;
  flex-shrink: 1;

  h4 {
    margin-bottom: ${props => props.theme.spacing['10']};
  }
`
