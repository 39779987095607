import React, { useCallback } from 'react'
import Drawer from '@groovehq/internal-design-system/lib/components/Drawer/UnmanagedDrawer'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import {
  heading,
  paragraph,
  text,
} from '@groovehq/internal-design-system/lib/styles/elements'
import cannedRepliesImg from '@groovehq/internal-design-system/lib/assets/images/canned-replies.png'
import { useDrawer } from 'ducks/drawers/hooks'
import { DRAWER_TYPE_CANNED_REPLIES_UPDATE } from 'ducks/drawers/types'
import { styles } from './styles'

const CanndReplyEmpty = ({ open, onClose }) => {
  const { openDrawer: openCreateDrawer } = useDrawer({
    type: DRAWER_TYPE_CANNED_REPLIES_UPDATE,
  })
  const handleOnCreate = useCallback(
    () => {
      openCreateDrawer('new')
    },
    [openCreateDrawer]
  )

  return (
    <Drawer
      title={app.t('Canned_replies')}
      open={open}
      onClose={onClose}
      size="wide"
      footer={
        <ModalBtns tertiaryBtnText="Close" onClickTertiaryBtn={onClose} />
      }
    >
      <div className="grui text-center">
        <div css={styles.imgBox} className="grui mt-12 mb-20">
          <img src={cannedRepliesImg} alt="Boost efficiency" />
        </div>
        <h1 css={heading.styles.h1}>Ready to boost your efficiency?</h1>
        <p
          css={[paragraph.styles.preflight, text.styles.textNormal]}
          className="grui mt-8 mb-14"
        >
          Reply instantly to your customers with one-click replies.
          <br />
          Saving you tons of time and effort.
        </p>
        <Button onClick={handleOnCreate}>
          Create {app.t('canned_reply_with_article')}
        </Button>
      </div>
    </Drawer>
  )
}

export default CanndReplyEmpty
