import themeVars from 'ui_theme/site/globals/site.variables'

export const addAlpha = (color, alpha) => {
  if (!color) return null

  if (!alpha) return color

  const roundTo3digits = n => Math.round(n * 1000) / 1000

  if (color.startsWith('rgba')) {
    const [r, g, b, a] = color.substring(5, color.length - 1).split(', ')
    return `rgba(${r}, ${g}, ${b}, ${roundTo3digits(
      parseFloat(a) * parseFloat(alpha)
    )})`
  }
  if (color.startsWith('rgb')) {
    const [r, g, b] = color.substring(4, color.length - 1).split(', ')
    return `rgba(${r}, ${g}, ${b}, ${alpha})`
  }
  if (color.startsWith('hsla')) {
    const [h, s, l, a] = color.substring(5, color.length - 1).split(', ')
    const newAlpha = roundTo3digits(parseFloat(a.substring(-1)) * alpha)
    return `hsla(${h}, ${s}, ${l}, ${newAlpha}%)`
  }
  if (color.startsWith('hsl')) {
    const [h, s, l] = color.substring(4, color.length - 1).split(', ')
    return `hsla(${h}, ${s}, ${l}, ${Math.round(100 * alpha)}%)`
  }

  return null
}

export function convertRGBToHex(col) {
  const re = new RegExp(
    'rgb\\s*\\(\\s*([0-9]+).*,\\s*([0-9]+).*,\\s*([0-9]+).*\\)',
    'gi'
  )

  const rgb = col.replace(re, '$1,$2,$3').split(',')
  if (rgb.length === 3) {
    let r = parseInt(rgb[0], 10).toString(16)
    let g = parseInt(rgb[1], 10).toString(16)
    let b = parseInt(rgb[2], 10).toString(16)

    r = r.length === 1 ? `0${r}` : r
    g = g.length === 1 ? `0${g}` : g
    b = b.length === 1 ? `0${b}` : b

    return `#${r}${g}${b}`
  }

  return col
}

export function convertHexToRGB(col, getRGBObject = false) {
  let localCol = col
  if (col.indexOf('#') !== -1) {
    localCol = col.replace(new RegExp('[^0-9A-F]', 'gi'), '')
    localCol = localCol.length === 3 ? `${localCol}${localCol}` : localCol

    const r = parseInt(localCol.substring(0, 2), 16)
    const g = parseInt(localCol.substring(2, 4), 16)
    const b = parseInt(localCol.substring(4, 6), 16)

    if (getRGBObject) return { r, g, b }

    return `rgb(${r},${g},${b})`
  }

  return localCol
}

export const isValidHex = hex => /^#([A-Fa-f0-9]{3,4}){1,2}$/.test(hex)

const getChunksFromString = (st, chunkSize) =>
  st.match(new RegExp(`.{${chunkSize}}`, 'g'))

const convertHexUnitTo256 = hexStr =>
  parseInt(hexStr.repeat(2 / hexStr.length), 16)

const getAlphafloat = (a, alpha) => {
  if (typeof a !== 'undefined') {
    return a / 255
  }
  if (typeof alpha !== 'number' || alpha < 0 || alpha > 1) {
    return 1
  }
  return alpha
}

// https://stackoverflow.com/a/53936623
export const convertHexToRGBA = (hex, alpha) => {
  if (!isValidHex(hex)) return hex
  const chunkSize = Math.floor((hex.length - 1) / 3)
  const hexArr = getChunksFromString(hex.slice(1), chunkSize)
  const [r, g, b, a] = hexArr.map(convertHexUnitTo256)
  return `rgba(${r}, ${g}, ${b}, ${getAlphafloat(a, alpha)})`
}

// https://css-tricks.com/converting-color-spaces-in-javascript/
export function convertHslToHex(hsl) {
  const sep = hsl.indexOf(',') > -1 ? ',' : ' '
  const hslArray = hsl
    .substr(4)
    .split(')')[0]
    .split(sep)

  let h = hslArray[0]
  const s = hslArray[1].substr(0, hslArray[1].length - 1) / 100
  const l = hslArray[2].substr(0, hslArray[2].length - 1) / 100

  // Strip label and convert to degrees (if necessary)
  if (h.indexOf('deg') > -1) h = h.substr(0, h.length - 3)
  else if (h.indexOf('rad') > -1)
    h = Math.round(h.substr(0, h.length - 3) * (180 / Math.PI))
  else if (h.indexOf('turn') > -1)
    h = Math.round(h.substr(0, h.length - 4) * 360)
  if (h >= 360) h %= 360

  const c = (1 - Math.abs(2 * l - 1)) * s
  const x = c * (1 - Math.abs((h / 60) % 2 - 1))
  const m = l - c / 2
  let r = 0
  let g = 0
  let b = 0

  if (h >= 0 && h < 60) {
    r = c
    g = x
    b = 0
  } else if (h >= 60 && h < 120) {
    r = x
    g = c
    b = 0
  } else if (h >= 120 && h < 180) {
    r = 0
    g = c
    b = x
  } else if (h >= 180 && h < 240) {
    r = 0
    g = x
    b = c
  } else if (h >= 240 && h < 300) {
    r = x
    g = 0
    b = c
  } else if (h >= 300 && h < 360) {
    r = c
    g = 0
    b = x
  }

  // Having obtained RGB, convert channels to hex
  r = Math.round((r + m) * 255).toString(16)
  g = Math.round((g + m) * 255).toString(16)
  b = Math.round((b + m) * 255).toString(16)

  // Prepend 0s, if necessary
  if (r.length === 1) r = `0${r}`
  if (g.length === 1) g = `0${g}`
  if (b.length === 1) b = `0${b}`

  return `#${r}${g}${b}`
}

// https://www.w3.org/TR/AERT/#color-contrast
export const HEX_REGEX = /^#?([0-9A-F]{6})$/i
export function brightness(color) {
  let red
  let green
  let blue
  if (color.startsWith('hsl')) {
    // eslint-disable-next-line no-param-reassign
    color = convertHslToHex(color)
  }
  const [, hexCode] = color.match(HEX_REGEX) || []
  if (hexCode) {
    red = parseInt(hexCode.substring(0, 2), 16)
    green = parseInt(hexCode.substring(2, 4), 16)
    blue = parseInt(hexCode.substring(4, 6), 16)
  } else if (color.startsWith('rgba')) {
    ;[red, green, blue] = color.substring(5, color.length - 1).split(', ')
  } else if (color.startsWith('rgb')) {
    ;[red, green, blue] = color.substring(4, color.length - 1).split(', ')
  }
  if ((red || red === 0) && (green || green === 0) && (blue || blue === 0))
    return (
      (parseInt(red, 10) * 299 +
        parseInt(green, 10) * 587 +
        parseInt(blue, 10) * 114) /
      1000
    )
  return null
}

export function contrast(
  color,
  light = themeVars.ultraDust,
  dark = themeVars.dark,
  threshold = 175
) {
  if (brightness(color) < threshold) return light
  return dark
}

export function stringToColor(str) {
  if (!str) str = '' // eslint-disable-line no-param-reassign
  let hash = 0
  for (let i = 0; i < str.length; i += 1) {
    // eslint-disable-next-line no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  let color = '#'
  for (let i = 0; i < 3; i += 1) {
    // eslint-disable-next-line no-bitwise
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.substr(-2)
  }
  return color
}

function luminance(r, g, b) {
  const normalizedValues = [r, g, b].map(value => {
    const normalized = value * (1 / 255)
    return normalized <= 0.03928
      ? normalized / 12.92
      : ((normalized + 0.055) / 1.055) ** 2.4
  })
  return (
    normalizedValues[0] * 0.2126 +
    normalizedValues[1] * 0.7152 +
    normalizedValues[2] * 0.0722
  )
}

function contrastRatio(hex1, hex2) {
  const rgb1 = convertHexToRGB(hex1, true)
  const rgb2 = convertHexToRGB(hex2, true)
  const lum1 = luminance(rgb1.r, rgb1.g, rgb1.b)
  const lum2 = luminance(rgb2.r, rgb2.g, rgb2.b)
  const brightest = Math.max(lum1, lum2)
  const darkest = Math.min(lum1, lum2)
  return (brightest + 0.05) / (darkest + 0.05)
}

export function hasLowContrast(hex1, hex2, threshold = 2) {
  if (!hex1 || !hex2) return false
  return contrastRatio(hex1, hex2) < threshold
}

// eslint-disable-next-line no-useless-escape
export const RGBA_REGEXP = /^[Rr][Gg][Bb][Aa][\(](((([\d]{1,3}|[\d\.]{1,4})[\,]{0,1})[\s]*){4})[\)]$/
// eslint-disable-next-line no-useless-escape
export const HSL_REGEXP = /^[Hh][Ss][Ll][\(](((([\d]{1,3}|[\d\%]{2,4})[\,]{0,1})[\s]*){3})[\)]$/
// eslint-disable-next-line no-useless-escape
export const HSLA_REGEXP = /^[Hh][Ss][Ll][Aa][\(](((([\d]{1,3}|[\d\%]{2,4}|[\d\.]{1,4})[\,]{0,1})[\s]*){4})[\)]$/
