import { createSelectorCreator, defaultMemoize } from 'reselect'

// A selector creator that doesn't do a full deep equality check, but will check
// each value in an array.
export const createArraySelector = createSelectorCreator(
  defaultMemoize,
  (a, b) => {
    if (a && a.reduce && b && b.reduce) {
      return (
        a.length === b.length &&
        a.reduce((result, item, index) => {
          return result && item === b[index]
        }, true)
      )
    }
    return a === b
  }
)

export function asArray(...values) {
  return [...values]
}

export function asObject(...values) {
  return values.reduce((result, value) => {
    result[value.id] = value
    return result
  }, {})
}
