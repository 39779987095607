import { connect } from 'react-redux'

import { doSelectAllTickets, doToggleSearchPinned } from 'actions/app'

import {
  selectAccountCanSeePinnedSearches,
  selectCurrentTicketListType,
  selectShowExperimentalLabelUi,
} from 'selectors/app'
import { selectIsTicketSelectionMode } from 'selectors/ticket_list'
import {
  selectIsCurrentTicketSearchPinnable,
  selectCurrentTicketSearchQueryId,
} from 'selectors/search/base'
import { selectCurrentSearchPin } from 'selectors/pinned_searches'
import {
  doDeleteSelectedTickets,
  doMarkSelectedTicketsAsSpam,
  doMarkSelectedTicketsAsUnread,
  doUnmarkBulkSelectionMode,
} from 'actions/tickets'
import { doOpenChangeInboxMenu } from 'actions/menus'
import { selectCurrentUserIsAdminOrOwnerOrAgent } from 'ducks/currentUser/selectors'

import TitleBarView from 'components/ConversationList/Header/TitleBar'
import SortMenu from './SortMenu'
import BulkActionBar from './BulkActionBar'

const select = state => ({
  canSeePinnedSearches: selectAccountCanSeePinnedSearches(state),
  isSearchPinnable: selectIsCurrentTicketSearchPinnable(state),
  isSelectionMode: selectIsTicketSelectionMode(state),
  pinnedSearch: selectCurrentSearchPin(state),
  queryId: selectCurrentTicketSearchQueryId(state),
  titleType: selectCurrentTicketListType(state),
  isExperimentalLabelUiEnabled: selectShowExperimentalLabelUi(state),
  isAdminOrOwnerOrAgent: selectCurrentUserIsAdminOrOwnerOrAgent(state),
  SortMenuComponent: SortMenu,
  BulkActionBarComponent: BulkActionBar,
})

const perform = {
  onPinUnpinClick: doToggleSearchPinned,
  onSelectAllClick: doSelectAllTickets,
  onUnselectAllClick: doUnmarkBulkSelectionMode,
  onDeleteClick: doDeleteSelectedTickets,
  onMarkAsSpamClick: doMarkSelectedTicketsAsSpam,
  onMarkAsUnreadClick: doMarkSelectedTicketsAsUnread,
  onChangeMailboxClick: doOpenChangeInboxMenu,
}

export default connect(select, perform)(TitleBarView)
