import { words } from './strings'

export function isExternal(url) {
  return !!(
    !url.match(/^https?:\/\/[^/]*groovehq\.(com)|(docker)/) &&
    url.match(/^https?:\/\//)
  )
}

const slugifyAccents = 'àáäâèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;'
const slugifyTransliterated = 'aaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------'
const slugifyPattern = new RegExp(slugifyAccents.split('').join('|'), 'g')

/* eslint-disable no-useless-escape */
export function slugify(str) {
  return str
    .toString()
    .toLowerCase()
    .replace(/(\s|%20)+/g, '-') // Replace spaces with -
    .replace(slugifyPattern, c =>
      slugifyTransliterated.charAt(slugifyAccents.indexOf(c))
    ) // Replace special chars
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
}

export const fixDownloadUrl = (url, subdomain) => {
  let newUrl = url

  if (!newUrl) {
    return null
  }

  if (newUrl.indexOf('files.')) {
    newUrl = newUrl.replace('files.', `${subdomain}.`)
  }

  if (newUrl.indexOf('/f/attachment')) {
    newUrl = newUrl.replace('/f/attachment', `/f/download`)
  }

  return newUrl
}
// We have searches paths from react-first-router that are partially decoded
// inbox%3ASupport%20contains%2010%25 => inbox%3ASupport contains 10%
// Avoid causing URIError: malformed URI sequence
// such as decoding the % symbol in a search term
export const decodeURIIfNecessary = searchTerm => {
  if (!searchTerm) return searchTerm
  return words(searchTerm)
    .map(word => {
      try {
        return decodeURIComponent(word)
      } catch (_e) {
        return word
      }
    })
    .filter(Boolean)
    .join(' ')
}
