import { css } from '@emotion/react'

const content = css`
  display: flex;
  flex-direction: column;
`

export const styles = {
  content,
}
