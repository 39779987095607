import { pluralize } from 'util/strings'

export function numberMessage(count, word, suffix) {
  return `${count === 1 ? 'Only ' : ''} ${count} ${pluralize(
    count,
    word,
    suffix
  )}`
}
export function checkNumberLimit(used, max) {
  if (max === 'unlimited') return false
  return used > max
}

export function checkBooleanLimit(used, allowed) {
  if (!allowed && typeof used === 'number' && used > 0) return true
  if (!allowed && typeof used === 'boolean' && used) return true
  return false
}

export function checkNumberRemoved(currentLimit, newLimit) {
  if (currentLimit === newLimit) return false
  return currentLimit === 'unlimited' && newLimit !== 'unlimited'
}

export function checkBooleanRemoved(currentLimit, newLimit) {
  if (currentLimit === newLimit) return false
  if (typeof newLimit === 'number' && newLimit > 0) return false

  return !newLimit
}
