import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  text,
  heading,
  paragraph,
} from '@groovehq/internal-design-system/lib/styles/elements'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import Field from '@groovehq/internal-design-system/lib/components/Field/Field'

import { AdminAccessDrawer } from 'subapps/settings/components/drawers/NoAccess'
import { string, bool } from 'prop-types'
import { selectCurrentAgentById } from 'ducks/agents/selectors'
import { doChangeToOwner } from 'ducks/agents/operations'

import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

const FORM_SCHEMA = yup.object().shape({
  password: yup.string().required(),
})

const ChangeOwnerDrawer = props => {
  const { open, drawerResourceId: agentId, onClose, onExit } = props

  const dispatch = useDispatch()

  const {
    handleSubmit,
    formState: { isValid },
    register,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(FORM_SCHEMA),
    defaultValues: {
      password: '',
    },
  })

  const handleOnClose = useCallback(
    () => {
      const closeOptions = {}
      onClose(closeOptions)
    },
    [onClose]
  )

  const onSubmit = useCallback(
    data => {
      const { password } = data
      dispatch(doChangeToOwner(agentId, password)).then(() => {
        handleOnClose()
        setTimeout(() => {
          window.location.reload()
        }, 3000)
      })
    },
    [dispatch, handleOnClose, agentId]
  )

  const DrawerForm = useCallback(
    subprops => <form onSubmit={handleSubmit(onSubmit)} {...subprops} />,
    [handleSubmit, onSubmit]
  )

  const agent = useSelector(state => selectCurrentAgentById(state, agentId))
  if (!agent) return null
  const { email } = agent

  const footer = (
    <ModalBtns
      warningBtnText="Change account owner"
      warningBtnDisabled={!isValid}
      warningBtnHtmlType="submit"
      tertiaryBtnText="Cancel"
      onClickTertiaryBtn={onClose}
    />
  )

  return (
    <AdminAccessDrawer
      title="Change account owner"
      open={open}
      onClose={onExit}
      footer={footer}
      container={DrawerForm}
    >
      <div className="grui mt-7">
        <div css={text.styles.textNormal}>
          <h4 className="grui mb-8" css={heading.styles.h4}>
            You are about to change the designated owner of the account.
          </h4>
          <p
            css={[
              paragraph.styles.preflight,
              text.styles.textNormal,
              text.styles.textSuperDark,
            ]}
          >
            {`This change will make “${email}” the official owner of the account,
          and change your account to an admin status.`}
            <br />
            <br />
            By changing the account owner, you will no longer have the ability
            to access billing nor cancel the account.
            <br />
            <br />
            Would you like to continue?
          </p>
          <Field
            className="grui mt-12"
            label="Type your password to continue"
            htmlType="password"
            {...register('password')}
          />
        </div>
      </div>
    </AdminAccessDrawer>
  )
}

ChangeOwnerDrawer.propTypes = {
  open: bool.isRequired,
  drawerResourceId: string,
}

ChangeOwnerDrawer.defaultProps = {
  drawerResourceId: '',
}

export default ChangeOwnerDrawer
