import { useMemo } from 'react'

const useColumns = () => {
  const columns = useMemo(() => {
    const formatName = ({
      row: {
        original: { id, name },
      },
    }) => {
      return (
        <label htmlFor={id}>
          <span>{name}</span>
        </label>
      )
    }

    return [
      {
        Header: app.t('Teams'),
        accessor: 'name',
        disableSortBy: true,
        Cell: formatName,
      },
    ]
  }, [])
  return columns
}

export default useColumns
