import { useEffect, useCallback, useRef } from 'react'

// Dropdown Menus with More Forgiving Mouse Movement Paths
// https://css-tricks.com/dropdown-menus-with-more-forgiving-mouse-movement-paths/
// https://github.com/kamens/jQuery-menu-aim/blob/master/jquery.menu-aim.js
const MOUSE_LOCS_TRACKED = 3 // number of past mouse locations to track
const DELAY = 300 // ms delay when user appears to be entering submenu
const TOLERANCE = 75

function getOffset(el) {
  const box = el.getBoundingClientRect()
  return {
    top: box.top + document.body.scrollTop,
    left: box.left + document.body.scrollLeft,
  }
}

export default function useMenuAim({
  menuElement,
  activeItemId,
  activeItemIdChange,
  options = {},
}) {
  const mouseLocsRef = useRef([])
  const timeoutIdRef = useRef()
  const lastDelayLocRef = useRef()
  const tolerance = options.tolerance || TOLERANCE
  const delay = options.delay || DELAY

  const activationDelay = useCallback(
    () => {
      if (!activeItemId || !menuElement) {
        // If there is no other submenu row already active, then
        // go ahead and activate immediately.
        return 0
      }
      const offset = getOffset(menuElement)
      const upperLeft = {
        x: offset.left,
        y: offset.top - tolerance,
      }
      const upperRight = {
        x: offset.left + menuElement.offsetWidth,
        y: upperLeft.y,
      }
      const lowerLeft = {
        x: offset.left,
        y: offset.top + menuElement.offsetHeight + tolerance,
      }
      const lowerRight = {
        x: offset.left + menuElement.offsetWidth,
        y: lowerLeft.y,
      }
      const loc = mouseLocsRef.current[mouseLocsRef.current.length - 1]
      let prevLoc = mouseLocsRef.current[0]

      if (!loc) {
        return 0
      }

      if (!prevLoc) {
        prevLoc = loc
      }

      if (
        prevLoc.x < offset.left ||
        prevLoc.x > lowerRight.x ||
        prevLoc.y < offset.top ||
        prevLoc.y > lowerRight.y
      ) {
        // If the previous mouse location was outside of the entire
        // menu's bounds, immediately activate.
        return 0
      }
      if (
        lastDelayLocRef.current &&
        loc.x === lastDelayLocRef.current.x &&
        loc.y === lastDelayLocRef.current.y
      ) {
        // If the mouse hasn't moved since the last time we checked
        // for activation status, immediately activate.
        return 0
      }

      // Detect if the user is moving towards the currently activated
      // submenu.
      //
      // If the mouse is heading relatively clearly towards
      // the submenu's content, we should wait and give the user more
      // time before activating a new row. If the mouse is heading
      // elsewhere, we can immediately activate a new row.
      //
      // We detect this by calculating the slope formed between the
      // current mouse location and the upper/lower right points of
      // the menu. We do the same for the previous mouse location.
      // If the current mouse location's slopes are
      // increasing/decreasing appropriately compared to the
      // previous's, we know the user is moving toward the submenu.
      //
      // Note that since the y-axis increases as the cursor moves
      // down the screen, we are looking for the slope between the
      // cursor and the upper right corner to decrease over time, not
      // increase (somewhat counterintuitively).
      function slope(a, b) {
        return (b.y - a.y) / (b.x - a.x)
      }

      const decreasingCorner = upperRight
      const increasingCorner = lowerRight

      const decreasingSlope = slope(loc, decreasingCorner)
      const increasingSlope = slope(loc, increasingCorner)
      const prevDecreasingSlope = slope(prevLoc, decreasingCorner)
      const prevIncreasingSlope = slope(prevLoc, increasingCorner)
      if (
        decreasingSlope < prevDecreasingSlope &&
        increasingSlope > prevIncreasingSlope
      ) {
        // Mouse is moving from previous location towards the
        // currently activated submenu. Delay before activating a
        // new menu row, because user may be moving into submenu.
        lastDelayLocRef.current = loc
        return delay
      }
      lastDelayLocRef.current = null
      return 0
    },
    [menuElement, activeItemId, delay, tolerance]
  )

  const activate = useCallback(
    (data = {}, e) => {
      if (data.id === activeItemId) {
        return
      }
      activeItemIdChange(data, e)
    },
    [activeItemId, activeItemIdChange]
  )

  const possiblyActivate = useCallback(
    (...args) => {
      const delayAmount = activationDelay()
      if (delayAmount) {
        timeoutIdRef.current = setTimeout(() => {
          possiblyActivate(...args)
        }, delayAmount)
      } else {
        activate(...args)
      }
    },
    [activate, activationDelay]
  )

  const handleMouseEnterItem = useCallback(
    (data, e) => {
      e.persist()
      if (timeoutIdRef.current) {
        // Cancel any previous activation delays
        clearTimeout(timeoutIdRef.current)
      }
      possiblyActivate(data, e)
    },
    [possiblyActivate]
  )

  const handleMouseLeaveMenu = useCallback(() => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current)
    }
  }, [])

  useEffect(() => {
    const handleMouseMoveDoc = e => {
      mouseLocsRef.current = [
        ...mouseLocsRef.current,
        { x: e.pageX, y: e.pageY },
      ]
      if (mouseLocsRef.current.length > MOUSE_LOCS_TRACKED) {
        mouseLocsRef.current = mouseLocsRef.current.slice(1)
      }
    }
    document.addEventListener('mousemove', handleMouseMoveDoc)
    return () => {
      lastDelayLocRef.current = null
      if (timeoutIdRef.current) {
        timeoutIdRef.current = null
        clearTimeout(timeoutIdRef.current)
      }
      document.removeEventListener('mousemove', handleMouseMoveDoc)
    }
  }, [])
  return { handleMouseEnterItem, handleMouseLeaveMenu }
}
