import React, { useCallback, useEffect, useState } from 'react'
import { AdminAccessDrawer } from 'subapps/settings/components/drawers/NoAccess'
import Field from '@groovehq/internal-design-system/lib/components/Field/Field'
import Checkbox from '@groovehq/internal-design-system/lib/components/Checkbox/Checkbox'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { selectCurrentActiveOrInvitedAgents } from 'ducks/agents/selectors'
import doFetchGroups from 'actions/groups/doFetchGroups'
import { selectPendingTeamById } from 'ducks/teams/selectors'
import { doCreateTeamDraft, doSaveTeam } from 'ducks/teams/actions'
import { DRAWER_TYPE_TEAM_DELETE } from 'ducks/drawers/types'
import { useDrawer } from 'ducks/drawers/hooks'
import { isBlank } from 'util/strings'
import { useRhfDirtyHold, useSimpleDrawerConfirmHolds } from 'util/dirtyHolds'
import Footer from './Footer'
import { styles } from './styles'
// keys should preferably match the object being stored in the entity store
// that way for updates, you can just spread the entity in reset() and not have to set each property individually
const FORM_SCHEMA = yup.object().shape({
  id: yup.string().nullable(),
  name: yup.string().required(`${app.t('Team')} name is required`),
})

export default function TeamCreateEditDrawer({
  drawerId,
  drawerResourceId: teamId,
  drawerIsAfterDelete: isAfterDelete = false,
  previousDrawer,
  open,
  onClose,
  onExit,
  drawerHideName = false,
  ...rest
}) {
  const dispatch = useDispatch()
  const agents = useSelector(selectCurrentActiveOrInvitedAgents)
  const isUpdate = !!teamId && teamId !== 'new'
  const [loadingState, setLoadingState] = useState(
    isUpdate ? 'loading' : 'loaded'
  )

  const { openDrawer: openDeleteTeamDrawer } = useDrawer(
    DRAWER_TYPE_TEAM_DELETE
  )

  useEffect(
    () => {
      // need to check drawer open state before fetch dispatches
      // otherwise the dispatch methods in useEffect below will run immediately
      // after this Drawer component is instantiated via `useDrawer` (before openDrawer is even called)
      // Which is unnecessary double dispatch triggering
      if (!open) return

      dispatch(doFetchGroups())
        .then(() => {
          if (isUpdate) {
            dispatch(doCreateTeamDraft(teamId))
            setLoadingState('loaded')
          }
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.debug('Unable to load groups', { error })
          setLoadingState('error')
        })
    },
    [dispatch, isUpdate, teamId, open]
  )

  useEffect(
    () => {
      if (isAfterDelete) onClose()
    },
    [isAfterDelete, onClose]
  )

  const isLoading = loadingState === 'loading'
  const hasError = loadingState === 'error'
  // not needed for creates but cannot have conditional hooks
  // call anyway, it'll just return null
  const teamToUpdate = useSelector(state =>
    selectPendingTeamById(state, teamId)
  )

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isValid, isDirty },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(FORM_SCHEMA),
    defaultValues: {
      // defaultValue has to match yup form schema
      id: null,
      name: '',
    },
  })

  const { releaseHold, holdKey } = useRhfDirtyHold(drawerId, control)

  useEffect(
    () => {
      if (isUpdate && open) {
        // load fields with template values
        reset({
          // just a spread here cos the needed keys should match the form schema
          ...teamToUpdate,
          ...(teamToUpdate?.agents.reduce(
            (lookup, agent) => {
              // eslint-disable-next-line no-param-reassign
              lookup.agents[agent.id] = true
              return lookup
            },
            { agents: {} }
          ) || {}),
        })
        // When the drawer gets closed, clear the form
      } else if (!open) {
        reset({})
      }
    },
    [teamToUpdate, isUpdate, reset, open]
  )

  const onSubmit = useCallback(
    async data => {
      const fields = {
        ...data,
        agents: Object.entries(data.agents).reduce((agentsArr, keyValue) => {
          const [agentId, isSelected] = keyValue
          if (isSelected) {
            agentsArr.push({ id: agentId })
          }
          return agentsArr
        }, []),
      }
      dispatch(doSaveTeam(teamId, fields))
      releaseHold()
      onClose()
    },
    [dispatch, onClose, teamId, releaseHold]
  )

  const handleOnDelete = useCallback(
    async () => {
      releaseHold()
      openDeleteTeamDrawer(teamId)
    },
    [openDeleteTeamDrawer, teamId, releaseHold]
  )

  const [handleOnClose, handleOnExit] = useSimpleDrawerConfirmHolds(
    holdKey,
    onClose,
    onExit
  )

  const DrawerForm = useCallback(
    props => {
      return <form onSubmit={handleSubmit(onSubmit)} {...props} />
    },
    [handleSubmit, onSubmit]
  )

  const { name: teamName } = teamToUpdate || {}

  return (
    <AdminAccessDrawer
      {...rest}
      open={open}
      onClose={handleOnExit}
      title={
        <>
          {!isLoading && isUpdate && `Edit ${app.t('team')}: "${teamName}"`}
          {!isLoading && !isUpdate && `Create ${app.t('team')}`}
        </>
      }
      isLoading={isLoading}
      isError={hasError}
      footer={
        <Footer
          isUpdate={isUpdate}
          allowDelete={isUpdate && !drawerHideName}
          disabled={isLoading || hasError || !isValid || !isDirty}
          onClose={handleOnClose}
          onDelete={handleOnDelete}
        />
      }
      container={DrawerForm}
    >
      <div className="grui mt-7">
        <Field
          {...register('name')}
          label="Name"
          placeholder={`Enter ${app.t('team')} name...`}
          validateStatus={errors?.name ? 'error' : undefined}
          help={errors?.name?.message}
          css={[styles.field, drawerHideName && styles.hidden]}
        />
        <div>
          <div css={[text.styles.fontMedium]}>Select {app.t('agents')}</div>
          <div css={styles.checkboxWrapper}>
            {agents.map(({ id, name, email }) => {
              return (
                <Checkbox key={id} id={id} {...register(`agents.${id}`)}>
                  {isBlank(name) ? email : name}
                </Checkbox>
              )
            })}
          </div>
        </div>
      </div>
    </AdminAccessDrawer>
  )
}
