import { emptyArr } from 'util/arrays'
import {
  FETCH_FACEBOOK_PAGES_REQUEST,
  FETCH_FACEBOOK_PAGES_SUCCESS,
  FETCH_FACEBOOK_PAGES_FAILURE,
} from './types'

const defaultState = {
  pages: [],
}
const reducers = {}

reducers[FETCH_FACEBOOK_PAGES_REQUEST] = (state, action) => {
  return {
    ...state,
    pages: emptyArr,
    error: null,
    loading: true,
  }
}

reducers[FETCH_FACEBOOK_PAGES_SUCCESS] = (state, action) => {
  return {
    ...state,
    pages: action.payload.pages || emptyArr,
    error: null,
    loading: false,
  }
}

reducers[FETCH_FACEBOOK_PAGES_FAILURE] = (state, action) => {
  return {
    ...state,
    facebookPages: emptyArr,
    error: action.payload.err,
    loading: false,
  }
}

export default function channelReducer(state = defaultState, action) {
  const handler = reducers[action.type]
  if (handler) return handler(state, action)
  return state
}
