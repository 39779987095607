import {
  FETCH_MAILBOXES_STARTED,
  FETCH_MAILBOXES_SUCCESS,
  FETCH_MAILBOXES_FAILED,
} from 'ducks/mailboxes/actionTypes'
import { isAllowedOnOverview } from './util'
import { CHANNEL_OVERVIEW_PAGE } from '../pages'

const defaultChannelOverview = {
  shouldLoadData: false,
  isLoading: false,
  reload: false,
  channels: [],
  error: null,
}

const reducers = {}

reducers[CHANNEL_OVERVIEW_PAGE] = state => {
  return {
    ...state,
    shouldLoadData: true,
  }
}

reducers[FETCH_MAILBOXES_STARTED] = state => {
  return {
    ...state,
    isLoading: true,
    shouldLoadData: false,
  }
}

reducers[FETCH_MAILBOXES_SUCCESS] = (state, { data = {} }) => {
  const channels = []
  let fbIdx = null
  data.mailboxes.forEach(mailbox => {
    let id = mailbox.id
    let name = mailbox.name
    if (mailbox.channel_type === 'FACEBOOK') {
      id = 'facebook'
      name = channels.facebook
        ? `${channels.facebook.name}, ${mailbox.name}`
        : mailbox.name
    } else if (mailbox.channel_type === 'GOOGLE') {
      name = mailbox.name
    }
    const newChannel = {
      ...channels[id],
      id,
      name,
      type: mailbox.channel_type,
      isValid: true,
      tooltip: '',
    }
    newChannel.isAllowedOnOverview = isAllowedOnOverview(newChannel)
    // NOTE (jscheel): We only want one facebook channel listed, not one for each page.
    if (mailbox.channel_type === 'FACEBOOK' && fbIdx !== null) {
      channels[fbIdx] = newChannel
    } else {
      channels.push(newChannel)
    }
    if (mailbox.channel_type === 'FACEBOOK' && fbIdx === null) {
      fbIdx = id
    }
  })
  return {
    ...state,
    isLoading: false,
    channels,
  }
}

reducers[FETCH_MAILBOXES_FAILED] = (state, { data = {} }) => {
  return {
    ...state,
    isLoading: false,
    error: data.err,
  }
}

export default function channelOverviewReducer(
  state = defaultChannelOverview,
  action
) {
  const handler = reducers[action.type]
  if (handler) return handler(state, action)
  return state
}
