import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import TableWithCheckbox from '@groovehq/internal-design-system/lib/components/TableWithCheckbox/TableWithCheckbox'
import { selectMailboxesIncludingInaccessible } from 'selectors/mailboxes/selectMailboxesIncludingInaccessible'
import { filter } from 'util/objects'
import useColumns from './useColumns'

const MailboxSelectionTable = ({ onSelectedRowIdsChange }) => {
  const columns = useColumns()

  const mailboxes = useSelector(selectMailboxesIncludingInaccessible)

  const mailboxRowData = useMemo(
    () => {
      return mailboxes
        .map(mailbox => {
          return {
            ...mailbox,
            isDisabled: ['everyone', 'group'].includes(
              mailbox.restriction_type
            ),
          }
        })
        .sort((a, b) => a.isDisabled - b.isDisabled)
    },
    [mailboxes]
  )

  const defaultSelectedRowIds = useMemo(
    () => {
      return mailboxRowData.reduce((obj, mailbox) => {
        // eslint-disable-next-line no-param-reassign
        obj[mailbox.id] = mailbox.restriction_type === 'everyone'

        return obj
      }, {})
    },
    [mailboxRowData]
  )

  useEffect(
    () => {
      onSelectedRowIdsChange(filter(v => !!v, defaultSelectedRowIds))
    },
    [defaultSelectedRowIds, onSelectedRowIdsChange]
  )

  return (
    <TableWithCheckbox
      columns={columns}
      onSelectedRowIdsChange={onSelectedRowIdsChange}
      data={mailboxRowData}
      defaultSelectedRowIds={defaultSelectedRowIds}
    />
  )
}

export default MailboxSelectionTable
