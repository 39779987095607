import styled from '@emotion/styled'

import themeVars from 'ui_theme/site/globals/site.variables'
import { Button, Form } from 'util/ui'
import { Label } from 'shared/components/ui/Typography'

export const CheckboxColumn = styled('div')`
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: ${props => props.theme.spacing['14']};

  h4 {
    margin-bottom: ${props => props.theme.spacing['10']};
  }
`

export const CancelButton = styled(Button)`
  display: block;
  flex-grow: 1;
  flex-shrink: 1;
  margin-right: 0px !important;
`

export const SectionLabel = styled(Label)`
  margin-bottom: 8px !important;
`

export const LabelColumn = styled('div')`
  flex-grow: 1;
  flex-shrink: 1;

  h4 {
    margin-bottom: ${props => props.theme.spacing['10']};
  }
`

export const ModalContent = styled('div')`
  padding: ${themeVars.massive} ${props => props.theme.spacing['12']};

  h2 {
    margin-top: 0;
  }
`

export const InstallLogo = styled('img')`
  .sui & {
    height: 96px;
    width: 96px;
    padding: 16px;
    background: white;
    border: 1px solid ${props => props.theme.color.monochrome.medium};
    float: left;
    margin-right: ${props => props.theme.spacing['11']};
    margin-bottom: ${props => props.theme.spacing['11']};
  }
`

export const InstallScreenShot = styled('img')`
  .sui & {
    width: 100%;
    border: 1px solid ${props => props.theme.color.monochrome.medium};
    margin-bottom: 8px;
  }
`

export const InstallModalHead = styled('div')`
  margin-bottom: ${props => props.theme.spacing['12']};
`

export const SettingRow = styled('div')`
  line-height: ${props => props.theme.spacing['11']};
  margin-bottom: ${props => props.theme.spacing['7']};

  ${CheckboxColumn} & {
    text-align: right;
  }

  ${LabelColumn} & {
    line-height: ${props => props.theme.spacing['11']};
  }

  > *,
  .sui & > .ui.checkbox {
    vertical-align: middle;
  }

  .sui & > .ui.checkbox {
    margin-right: ${props => props.theme.spacing['5']};
  }
`

export const SettingSubRow = styled(SettingRow)`
  margin-left: 28px;
`

export const StyledForm = styled(Form)`
  padding: 0;

  .sui &.form.ui > p {
    margin-top: 0;
  }
`

export const UninstallButton = styled(Button)`
  display: block;
  flex-grow: 0.1;
  flex-shrink: 0;
`

export const TabHeader = styled('div')`
  display: flex;
  padding: ${themeVars.massive} ${props => props.theme.spacing['12']};
  padding-top: 15px;
  padding-bottom: 0px;
  border-bottom: 1px solid #ccc;
`

export const TabLink = styled('a')`
  display: block;
  padding-bottom: 5px;
  font-family: 'Graphik', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: 2px;
  text-transform: uppercase;
  border-bottom: 2px solid #fff;
  cursor: pointer;
  margin-right: 20px;

  .sui & {
    color: var(--color-monochrome-dark);
  }

  &.active {
    border-bottom: 2px solid var(--color-primary-brand);
    color: var(--color-primary-brand);
    font-weight: bold;
  }
`

export const Tab = styled('div')`
  display: ${props => (props.visible ? 'block' : 'none')};
`

export const TabContent = styled('div')`
  padding: ${themeVars.massive} ${props => props.theme.spacing['12']};
  padding-top: 20px;

  &.description {
    padding-top: 0px;
  }
`

export const AccordionContainer = styled('div')`
  .sui & .ui.accordion.menu.vertical .title:not(:first-child) {
    border-top: 1px solid var(--color-monochrome-medium);
  }

  .sui & .ui.accordion.menu.vertical .content {
    max-height: 240px;
    overflow: auto;
  }

  span.accordion_title {
    color: black;
  }
  span.accordion_subtitle {
    font-size: 0.9em;
  }
`
