import React from 'react'
import { Check } from '@groovehq/internal-design-system/lib/assets/icons'
import { list } from '@groovehq/internal-design-system/lib/styles/elements'
import { styles } from './styles'

const List = ({ data = [], className }) => {
  return (
    <ul css={[list.styles.preflight, styles.list]} className={className}>
      {data.map(item => (
        <li className="grui flex">
          <Check className="grui mr-3 mt-1" />
          {item}
        </li>
      ))}
    </ul>
  )
}

export default List
