import { css } from '@emotion/react'

const content = css`
  max-width: 720px;
  margin: 30px 30px 100px 30px;
  text-align: center;
`

export const styles = {
  content,
}
