import { DEFAULT_SORT_ORDER } from 'constants/defaults'
import * as TicketListTypes from 'constants/conversation_list_types'
import { isEmpty } from 'util/objects'
import {
  constructGraphQLSearchQueryObject,
  constructSearchQueryId,
  constructSearchQueryObject,
} from 'util/search'
import storage from 'util/storage'
import { decodeURIIfNecessary } from './url'

const getDefaultList = () => {
  const cached = storage.get('ticketListForLastViewedTicket') || {}
  const lastTicket = Object.values(cached)[0]

  return {
    type: 'SEARCH',
    id: 'is:open',
    sortOrder: lastTicket?.sortOrder || DEFAULT_SORT_ORDER,
  }
}

export function getDefaultTicketList(path) {
  const list =
    getCachedTicketList(path) || getTicketListFromPath(path) || getDefaultList()

  // When launching sorting for the first time (Q3 18), the sortOrder wont be
  // cached in LStorage. So we decorate it here. This can be removed after a while
  return {
    sortOrder: DEFAULT_SORT_ORDER,
    ...list,
  }
}

// On bootstrap, if we are loading a ticket, we check to see if there is a
// saved TicketList object for the current ticket id (if requesting /tickets/:id)
function getCachedTicketList(path) {
  return getCachedTicketListForTicket(path) || getCachedFirstPinnedSearch()
}

// On bootstrap, if we are loading a ticket, we check to see if there is a
// saved TicketList object for the current ticket id (if requesting /tickets/:id)
function getCachedTicketListForTicket(path) {
  const ticketId = getTicketId(path)
  if (!ticketId) return null // Not loading a /tickets page
  const cached = storage.get('ticketListForLastViewedTicket')
  if (cached && cached[ticketId]) return cached[ticketId]

  return null
}

// Note: has a side effect!
export function cacheTicketListForTicket(ticketId, list) {
  return storage.set('ticketListForLastViewedTicket', {
    [ticketId]: list,
  })
}

function getCachedFirstPinnedSearch() {
  return storage.get('ticketListFromFirstPinnedSearch')
}

export function cacheTicketListForPinnedSearch(
  pinnedSearches,
  latestMailbox,
  mailboxFolderMap,
  mailboxIds,
  hasUnifiedInbox
) {
  const list = buildTicketListForPinnedSearch(
    pinnedSearches,
    latestMailbox,
    mailboxFolderMap,
    mailboxIds,
    hasUnifiedInbox
  )
  return storage.set('ticketListFromFirstPinnedSearch', list)
}

function buildTicketListForPinnedSearch(
  pinnedSearches,
  latestMailbox,
  mailboxFolderMap,
  mailboxIds,
  hasUnifiedInbox
) {
  const pinned = pinnedSearchQueries(pinnedSearches)
  const searchObject = getFirstPinnedSearch(
    pinned,
    latestMailbox,
    mailboxFolderMap
  )

  if (!searchObject) return {} // No pinned search loaded (?!)

  let mailbox
  if (!searchObject.mailbox && latestMailbox) {
    searchObject.mailbox = [latestMailbox]
    mailbox = { name: latestMailbox }
  }
  if (!searchObject.mailbox && !hasUnifiedInbox) {
    const mailboxId = mailboxIds[0]
    searchObject.mailbox = [mailboxId]
    mailbox = { id: mailboxId }
  }
  const query = constructSearchQueryId(searchObject)

  return {
    type: 'SEARCH',
    id: query,
    mailbox,
    sortOrder: DEFAULT_SORT_ORDER,
  }
}

export function getTicketListFromPath(
  path,
  currentMailbox,
  sortOrder = undefined,
  payload
) {
  const updatedMailbox = getMailboxFromPath(path, payload)
  // keep the current mailbox unless it changed
  const mailbox = !isEmpty(updatedMailbox) ? updatedMailbox : currentMailbox

  if (path.match('/search/')) {
    return {
      type: TicketListTypes.SEARCH,
      // The pathname for search is double encoded, we should use search term to get the query id instead.
      // See routesMap[pages.SEARCH_PAGE].toPath in src/routesMap.js
      id: payload?.term || getSearchQuery(path),
      mailbox,
      sortOrder,
    }
  }

  if (path.match('/folders/') || path.match('/mailbox/')) {
    return {
      type: TicketListTypes.FOLDER,
      id: getCurrentFolder(path),
      mailbox,
    }
  }

  return null
}

function getTicketId(path) {
  const match = path.match(/\/tickets\/(\w*)/)
  if (!match) return null
  return match[1]
}

function getCurrentFolder(path = '') {
  return path.match('/folders/') && path.split('/').pop()
}

function getMailboxFromPath(path, payload) {
  if (path === '/') return { id: null }

  if (path.match('/search/')) {
    const searchObject = constructGraphQLSearchQueryObject(
      payload?.term || getSearchQuery(path),
      undefined,
      { isForSingleMailbox: true }
    )
    if (searchObject) {
      return { name: searchObject.mailbox }
    }
  }

  if (path.match('/mailbox/')) {
    // eslint-disable-next-line no-useless-escape
    return { id: path.match(/\/mailbox\/([^\/]+)/).pop() }
  }

  // if just a folder page without mailbox, then you changed to an All
  // Mailboxes folder
  if (path.match('/folders/')) return { id: null }

  // Otherwise not a path that triggers a mailbox change
  return {}
}

function getSearchQuery(path = '') {
  return decodeURIIfNecessary(path.split('/search/').pop())
}

function getFirstPinnedSearch(searches, mailbox, map) {
  const folders = map[mailbox]
  if (!folders) return searches[0]
  const applicableSearches = searches.filter(
    search => !search.folder || folders.indexOf(search.folder[0]) !== -1
  )

  return applicableSearches[0]
}

function pinnedSearchQueries(pinned) {
  return pinned && pinned.map(pin => constructSearchQueryObject(pin.query))
}
