import { propFunc } from 'util/functions'

import withListController from './withListController'
import ScrollPane from './ScrollPane'
import ListItem from './TicketListItem'

function buildItems({
  delayedTicketIds,
  focusedIndex,
  hideCustomer,
  items = [],
  onSelect,
}) {
  return items.filter(e => e).map((ticket, index) => {
    const sendingDelayed = delayedTicketIds.includes(ticket.id.toString())
    return (
      <ListItem
        hideCustomer={hideCustomer}
        index={index}
        key={ticket.id}
        onClick={!sendingDelayed ? propFunc(onSelect, ticket) : undefined}
        selected={index === focusedIndex}
        sendingDelayed={sendingDelayed}
        ticket={ticket}
      />
    )
  })
}

const Options = ({
  delayedTicketIds,
  focusedIndex,
  hasMore,
  hideCustomer = false,
  isLoadingMore,
  items,
  loadMore,
  onSelect,
}) => {
  return (
    <ScrollPane
      hasMore={hasMore}
      loadMore={loadMore}
      isLoadingMore={isLoadingMore}
    >
      {buildItems({
        delayedTicketIds,
        focusedIndex,
        hideCustomer,
        items,
        onSelect,
      })}
    </ScrollPane>
  )
}

export default withListController(Options)
