import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util/strings'

const btn = theme => css`
  button {
    font-family: ${theme.font.secondary};
    border-radius: ${rem(999)};
    width: 100%;
  }
`

const image = css`
  max-width: ${rem(216)};
  img {
    display: block;
    width: 100%;
  }
`

const content = theme => css`
  h2 {
    font-size: ${theme.fontSize.x5large};
  }
  p {
    font-size: ${theme.fontSize.x2large};
  }
`

export const styles = {
  btn,
  image,
  content,
}
