import React, { useCallback, useMemo } from 'react'
import { useController } from 'react-hook-form'

import TableWithCheckbox from '@groovehq/internal-design-system/lib/components/TableWithCheckbox/TableWithCheckbox'

import useColumns from './useColumns'
import { styles } from '../../styles'

const NOTIFICATION_TYPES = [
  { id: 'new_ticket', name: `A new ${app.t('ticket')} is created` },
  {
    id: 'ticket_assigned_to_agent',
    name: `A ${app.t('ticket')} is assigned to ${app.t('agent_with_article')}`,
  },
  {
    id: 'ticket_assigned_to_group',
    name: `A ${app.t('ticket')} is assigned to ${app.t('group_with_article')}`,
  },
  {
    id: 'new_reply_from_enduser',
    name: `A customer replies to ${app.t('a_ticket')}`,
  },
  {
    id: 'new_reply_from_agent',
    name: `${app.t('agent_with_Article')} replies to ${app.t('a_ticket')}`,
  },
  {
    id: 'new_note_from_agent',
    name: `${app.t('agent_with_Article')} leaves a note on ${app.t(
      'a_ticket'
    )}`,
  },
  {
    id: 'new_reply_from_collaborator',
    name: `A collaborator replies to a forwarded ${app.t('ticket')}`,
  },
  {
    id: 'new_forward_from_agent',
    name: `${app.t('agent_with_Article')} forward ${app.t('a_ticket')}`,
  },
  {
    id: 'new_awesome_rating',
    name: `${app.t('A_ticket')} has received an awesome rating`,
  },
  {
    id: 'new_ok_rating',
    name: `${app.t('A_ticket')} has received an ok rating`,
  },
  {
    id: 'new_bad_rating',
    name: `${app.t('A_ticket')} has received a bad rating`,
  },
]

const NotificationsTable = React.memo(
  ({ defaultValue = [], control, name, ...rest }) => {
    const {
      field: { onChange },
    } = useController({
      name,
      control,
      defaultValue,
    })
    const columns = useColumns()
    const defaultSelectedRowIds = useMemo(
      () => {
        return defaultValue.reduce((memo, id) => {
          // eslint-disable-next-line no-param-reassign
          memo[id] = true
          return memo
        }, {})
      },
      [defaultValue]
    )

    const handleIdsChange = useCallback(
      types => {
        onChange(Object.keys(types))
      },
      [onChange]
    )

    return (
      <TableWithCheckbox
        separate
        columns={columns}
        data={NOTIFICATION_TYPES}
        css={styles.table}
        onSelectedRowIdsChange={handleIdsChange}
        columnWithCheckbox="name"
        defaultSelectedRowIds={defaultSelectedRowIds}
        {...rest}
      />
    )
  }
)

export default NotificationsTable
