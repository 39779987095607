import { useCallback, useEffect, useRef, useState } from 'react'

export function useAutofocus() {
  const autoFocusedElement = useRef()
  useEffect(
    () => {
      if (autoFocusedElement && autoFocusedElement.current) {
        autoFocusedElement.current.focus()
      }
    },
    [autoFocusedElement]
  )
  return autoFocusedElement
}

export function useIsScrolled({
  threshold = 0,
  upThreshold = threshold,
  downThreshold = threshold,
}) {
  const [isScrolled, setIsScrolled] = useState()
  const scrollPosition = useRef()
  const elementRef = useRef()
  const onScroll = useCallback(
    event => {
      const { target } = event
      if (elementRef.current && elementRef.current !== event.target) return
      requestAnimationFrame(() => {
        const newScrolled =
          target.scrollTop > scrollPosition.current
            ? target.scrollTop > downThreshold
            : target.scrollTop > upThreshold
        setIsScrolled(newScrolled)
        scrollPosition.current = target.scrollTop
      })
    },
    [setIsScrolled, scrollPosition, downThreshold, upThreshold]
  )
  return {
    isScrolled,
    onScroll,
    elementRef,
  }
}
