import React from 'react'
import ActionAccessWarning from '../../shared/ActionAccessWarning'

export default function GitHubInstallModal() {
  return (
    <React.Fragment>
      <p>
        Quickly create a new GitHub issue directly from a conversation, and
        attach the conversation to either a new or an existing issue for
        frictionless logging. When a issue is updated in GitHub you can receive
        the actions right in your conversation history, and you can even have
        all these actions trigger a re-open on the linked conversations. No more
        back and forth between Groove and GitHub, and no more manually having to
        keep track of your issues!
      </p>
      <p>With this integration you can:</p>
      <ul>
        <li>Create a new issue from the {app.t('Mailbox')}</li>
        <li>Attach conversations to new or existing issues</li>
        <li>View GitHub issue actions in your conversation history</li>
        <li>
          Have conversations re-opened when a GitHub issue has been updated
        </li>
        <li>View all linked issues and their details in the sidebar</li>
      </ul>
      <ActionAccessWarning />
    </React.Fragment>
  )
}
