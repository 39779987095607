import React, { useCallback } from 'react'
import Drawer from '@groovehq/internal-design-system/lib/components/Drawer/UnmanagedDrawer'
import ModalBtns from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns'
import Tabbed from 'subapps/settings/components/drawers/Tabbed'
import { useFetchIntegrationProvider } from 'ducks/integrations/hooks'

const CommonConfigure = ({
  drawerResourceId: providerId,
  drawerTabId,
  open,
  onClose,
  onExit,
  drawerId,
  drawer,
  updateDrawerParameter,
  tabsData,
  title,
  size = 'wide',
  ...rest
}) => {
  const { isLoading, hasError, isMissing } = useFetchIntegrationProvider(
    providerId
  )

  const handleTabKeyChange = useCallback(
    key => {
      if (key === drawerTabId) return
      updateDrawerParameter('drawerTabId', key)
    },
    [drawerTabId, updateDrawerParameter]
  )

  return (
    <Drawer
      {...rest}
      onClose={onExit}
      isLoading={isLoading}
      isError={hasError}
      isNoResultFound={isMissing}
      size={size}
      title={title}
      open={open}
      footer={
        <ModalBtns tertiaryBtnText="Cancel" onClickTertiaryBtn={onClose} />
      }
    >
      <Tabbed
        tabsData={tabsData}
        activeTabKey={drawerTabId}
        onTabKeyChange={handleTabKeyChange}
        providerId={providerId}
      />
    </Drawer>
  )
}

export default CommonConfigure
