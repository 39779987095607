// Utility library for interfacing with Browser Notifications API for permissions
import { isWebPushSupported } from 'util/browser'

export const getPermission = () => {
  if (
    !isWebPushSupported ||
    typeof window === 'undefined' ||
    !window.Notification
  ) {
    return 'unsupported'
  }

  return window.Notification.permission
}

export const isGranted = () => getPermission() === 'granted'
export const isDenied = () => getPermission() === 'denied'
export const isDefault = () => getPermission() === 'default'

// The API for getting permission is relatively simple, the downside is that
// the API recently changed from taking a callback to returning a Promise. The
// problem with this, is that we can't tell what version of the API is
// implemented by the current browser, so you have to implement both and handle
// both. Sauce: https://developers.google.com/web/fundamentals/engage-and-
// retain/push-notifications/subscribing-a-user
export const askPermission = () =>
  new Promise((resolve, reject) => {
    const permissionResult = Notification.requestPermission(res => resolve(res))
    if (permissionResult) permissionResult.then(resolve, reject)
  }).then(permissionResult => {
    if (permissionResult !== 'granted') throw new Error(permissionResult)
    return permissionResult // granted
  })
