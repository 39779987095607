/** ***************************************************************
 *  Knowledge base Actions
 *  Scopes:
 *   kb
 *   availability
 *   themes
 *   theme-backgrounds
 *   settings
 *   category
 *   categories
 *   article
 *   articles
 *   search
 * **************************************************************** */

/* Global */
export const BOOTSTRAP = 'kb/BOOTSTRAP'
export const SWITCH_KNOWLEDGE_BASE = 'kb/SWITCH'

export const FETCH_KNOWLEDGE_BASES_REQUEST = 'kb/FETCH_REQUEST'
export const FETCH_KNOWLEDGE_BASES_SUCCESS = 'kb/FETCH_SUCCESS'
export const FETCH_KNOWLEDGE_BASES_FAILURE = 'kb/FETCH_FAILURE'

/* Themes */
export const FETCH_THEMES_REQUEST = 'kb/themes/FETCH_REQUEST'
export const FETCH_THEMES_SUCCESS = 'kb/themes/FETCH_SUCCESS'
export const FETCH_THEMES_FAILURE = 'kb/themes/FETCH_FAILURE'

/* Theme backgrounds */
export const FETCH_THEME_BACKGROUNDS_REQUEST =
  'kb/theme-backgrounds/FETCH_REQUEST'
export const FETCH_THEME_BACKGROUNDS_SUCCESS =
  'kb/theme-backgrounds/FETCH_SUCCESS'
export const FETCH_THEME_BACKGROUNDS_FAILURE =
  'kb/theme-backgrounds/FETCH_FAILURE'

export const FETCH_THEME_BACKGROUND_REQUEST =
  'kb/theme-background/FETCH_REQUEST'
export const FETCH_THEME_BACKGROUND_SUCCESS =
  'kb/theme-background/FETCH_SUCCESS'
export const FETCH_THEME_BACKGROUND_FAILURE =
  'kb/theme-background/FETCH_FAILURE'

/* Single kb */
export const FETCH_KNOWLEDGE_BASE_REQUEST = 'kb/kb/FETCH_REQUEST'
export const FETCH_KNOWLEDGE_BASE_SUCCESS = 'kb/kb/FETCH_SUCCESS'
export const FETCH_KNOWLEDGE_BASE_FAILURE = 'kb/kb/FETCH_FAILURE'

export const CREATE_KNOWLEDGE_BASE_REQUEST = 'kb/kb/CREATE_REQUEST'
export const CREATE_KNOWLEDGE_BASE_SUCCESS = 'kb/kb/CREATE_SUCCESS'
export const CREATE_KNOWLEDGE_BASE_FAILURE = 'kb/kb/CREATE_FAILURE'

export const UPDATE_KNOWLEDGE_BASE = 'kb/kb/UPDATE'
export const UPDATE_KNOWLEDGE_BASE_SUCCESS = 'kb/kb/UPDATE_SUCCESS'
export const UPDATE_KNOWLEDGE_BASE_FAILED = 'kb/kb/UPDATE_FAILED'

export const DELETE_KNOWLEDGE_BASE_REQUEST = 'kb/kb/DELETE_REQUEST'
export const DELETE_KNOWLEDGE_BASE_SUCCESS = 'kb/kb/DELETE_SUCCESS'
export const DELETE_KNOWLEDGE_BASE_FAILURE = 'kb/kb/DELETE_FAILURE'

export const REVERT_KNOWLEDGE_BASE_THEME = 'kb/kb/REVERT_THEME'

/* Availability */
export const FETCH_SUBDOMAIN_AVAILABILITY =
  'kb/availability/FETCH_SUBDOMAIN_AVAILABILITY'
export const FETCH_SUBDOMAIN_AVAILABILITY_STARTED =
  'kb/availability/FETCH_SUBDOMAIN_AVAILABILITY_STARTED'
export const FETCH_SUBDOMAIN_AVAILABILITY_SUCCESS =
  'kb/availability/FETCH_SUBDOMAIN_AVAILABILITY_SUCCESS'
export const FETCH_SUBDOMAIN_AVAILABILITY_FAILED =
  'kb/availability/FETCH_SUBDOMAIN_AVAILABILITY_FAILED'

export const FETCH_SLUG_AVAILABILITY = 'kb/availability/FETCH_SLUG_AVAILABILITY'
export const FETCH_SLUG_AVAILABILITY_STARTED =
  'kb/availability/FETCH_SLUG_AVAILABILITY_STARTED'
export const FETCH_SLUG_AVAILABILITY_SUCCESS =
  'kb/availability/FETCH_SLUG_AVAILABILITY_SUCCESS'
export const FETCH_SLUG_AVAILABILITY_FAILED =
  'kb/availability/FETCH_SLUG_AVAILABILITY_FAILED'

/* Settings */
export const FETCH_SETTINGS_REQUEST = 'kb/settings/FETCH_REQUEST'
export const FETCH_SETTINGS_SUCCESS = 'kb/settings/FETCH_SUCCESS'
export const FETCH_SETTINGS_FAILURE = 'kb/settings/FETCH_FAILURE'

export const UPDATE_SETTINGS_REQUEST = 'kb/theme/UPDATE_REQUEST'
export const UPDATE_SETTINGS_SUCCESS = 'kb/theme/UPDATE_SUCCESS'
export const UPDATE_SETTINGS_FAILURE = 'kb/theme/UPDATE_FAILURE'

export const PUBLISH_SETTINGS_REQUEST = 'kb/theme/PUBLISH_REQUEST'
export const PUBLISH_SETTINGS_SUCCESS = 'kb/theme/PUBLISH_SUCCESS'
export const PUBLISH_SETTINGS_FAILURE = 'kb/theme/PUBLISH_FAILURE'

export const REVERT_SETTINGS_REQUEST = 'kb/theme/REVERT_REQUEST'
export const REVERT_SETTINGS_SUCCESS = 'kb/theme/REVERT_SUCCESS'
export const REVERT_SETTINGS_FAILURE = 'kb/theme/REVERT_FAILURE'

export const RESET_SETTINGS_REQUEST = 'kb/theme/RESET_REQUEST'
export const RESET_SETTINGS_SUCCESS = 'kb/theme/RESET_SUCCESS'
export const RESET_SETTINGS_FAILURE = 'kb/theme/RESET_FAILURE'

export const UPDATE_THEME_DEVICE_PREVIEW_SIZE =
  'kb/theme/UPDATE_THEME_DEVICE_PREVIEW_SIZE'

export const UPDATE_THEME_HIDE_PREVIEW_SIZE_WARNING =
  'kb/theme/UPDATE_THEME_HIDE_PREVIEW_SIZE_WARNING'

/* Category */
export const FETCH_CATEGORY_REQUEST = 'kb/category/FETCH_REQUEST'
export const FETCH_CATEGORY_SUCCESS = 'kb/category/FETCH_SUCCESS'
export const FETCH_CATEGORY_FAILURE = 'kb/category/FETCH_FAILURE'

export const CREATE_CATEGORY_REQUEST = 'kb/category/CREATE_REQUEST'
export const CREATE_CATEGORY_SUCCESS = 'kb/category/CREATE_SUCCESS'
export const CREATE_CATEGORY_FAILURE = 'kb/category/CREATE_FAILURE'

export const UPDATE_CATEGORY_REQUEST = 'kb/category/UPDATE_REQUEST'
export const UPDATE_CATEGORY_SUCCESS = 'kb/category/UPDATE_SUCCESS'
export const UPDATE_CATEGORY_FAILURE = 'kb/category/UPDATE_FAILURE'
export const UPDATE_CATEGORY_ARTICLE_IDS_SUCCESS =
  'kb/category/UPDATE_CATEGORY_ARTICLE_IDS_SUCCESS'

export const DELETE_CATEGORY_REQUEST = 'kb/category/DELETE_REQUEST'
export const DELETE_CATEGORY_SUCCESS = 'kb/category/DELETE_SUCCESS'
export const DELETE_CATEGORY_FAILURE = 'kb/category/DELETE_FAILURE'

export const PUBLISH_CATEGORY_REQUEST = 'kb/category/PUBLISH_REQUEST'
export const PUBLISH_CATEGORY_SUCCESS = 'kb/category/PUBLISH_SUCCESS'
export const PUBLISH_CATEGORY_FAILURE = 'kb/category/PUBLISH_FAILURE'

export const UNPUBLISH_CATEGORY_REQUEST = 'kb/category/UNPUBLISH_REQUEST'
export const UNPUBLISH_CATEGORY_SUCCESS = 'kb/category/UNPUBLISH_SUCCESS'
export const UNPUBLISH_CATEGORY_FAILURE = 'kb/category/UNPUBLISH_FAILURE'

export const REVERT_CATEGORY_REQUEST = 'kb/category/REVERT_REQUEST'
export const REVERT_CATEGORY_SUCCESS = 'kb/category/REVERT_SUCCESS'
export const REVERT_CATEGORY_FAILURE = 'kb/category/REVERT_FAILURE'

export const CATEGORY_SAVE_CLOSE = 'kb/category/SAVE_CLOSE'
export const CATEGORY_CANCEL_CLOSE = 'kb/category/CANCEL_CLOSE'

export const REMOVE_CATEGORY_ARTICLE = 'kb/category/REMOVE_CATEGORY_ARTICLE'

/* Categories */
export const FETCH_CATEGORIES_REQUEST = 'kb/categories/FETCH_REQUEST'
export const FETCH_CATEGORIES_SUCCESS = 'kb/categories/FETCH_SUCCESS'
export const FETCH_CATEGORIES_FAILURE = 'kb/categories/FETCH_FAILURE'

export const FETCH_CATEGORIES_TITLES_REQUEST =
  'kb/categories/FETCH_TITLES_REQUEST'
export const FETCH_CATEGORIES_TITLES_SUCCESS =
  'kb/categories/FETCH_TITLES_SUCCESS'
export const FETCH_CATEGORIES_TITLES_FAILURE =
  'kb/categories/FETCH_TITLES_FAILURE'

export const REPOSITION_CATEGORIES_REQUEST = 'kb/categories/REPOSITION_REQUEST'
export const REPOSITION_CATEGORIES_SUCCESS = 'kb/categories/REPOSITION_SUCCESS'
export const REPOSITION_CATEGORIES_FAILURE = 'kb/categories/REPOSITION_FAILURE'

export const FETCH_CATEGORIES_COUNTS_REQUEST =
  'kb/categories/FETCH_COUNTS_REQUEST'
export const FETCH_CATEGORIES_COUNTS_SUCCESS =
  'kb/categories/FETCH_COUNTS_SUCCESS'
export const FETCH_CATEGORIES_COUNTS_FAILURE =
  'kb/categories/FETCH_COUNTS_FAILURE'

/* Article */
export const CREATE_ARTICLE_REQUEST = 'kb/article/CREATE_REQUEST'
export const CREATE_ARTICLE_SUCCESS = 'kb/article/CREATE_SUCCESS'
export const CREATE_ARTICLE_FAILURE = 'kb/article/CREATE_FAILURE'

export const UPDATE_ARTICLE_REQUEST = 'kb/article/UPDATE_REQUEST'
export const UPDATE_ARTICLE_SUCCESS = 'kb/article/UPDATE_SUCCESS'
export const UPDATE_ARTICLE_FAILURE = 'kb/article/UPDATE_FAILURE'

export const TRASH_ARTICLE_REQUEST = 'kb/article/TRASH_REQUEST'
export const TRASH_ARTICLE_SUCCESS = 'kb/article/TRASH_SUCCESS'
export const TRASH_ARTICLE_FAILURE = 'kb/article/TRASH_FAILURE'

export const DELETE_ARTICLE = 'kb/article/DELETE'
export const DELETE_ARTICLE_STARTED = 'kb/article/DELETE_STARTED'
export const DELETE_ARTICLE_SUCCESS = 'kb/article/DELETE_SUCCESS'
export const DELETE_ARTICLE_FAILURE = 'kb/article/DELETE_FAILURE'

export const RESTORE_ARTICLE_REQUEST = 'kb/article/RESTORE_REQUEST'
export const RESTORE_ARTICLE_SUCCESS = 'kb/article/RESTORE_SUCCESS'
export const RESTORE_ARTICLE_FAILURE = 'kb/article/RESTORE_FAILURE'

export const PUBLISH_ARTICLE_REQUEST = 'kb/article/PUBLISH_REQUEST'
export const PUBLISH_ARTICLE_SUCCESS = 'kb/article/PUBLISH_SUCCESS'
export const PUBLISH_ARTICLE_FAILURE = 'kb/article/PUBLISH_FAILURE'

export const UNPUBLISH_ARTICLE_REQUEST = 'kb/article/UNPUBLISH_REQUEST'
export const UNPUBLISH_ARTICLE_SUCCESS = 'kb/article/UNPUBLISH_SUCCESS'
export const UNPUBLISH_ARTICLE_FAILURE = 'kb/article/UNPUBLISH_FAILURE'

export const REVERT_ARTICLE_REQUEST = 'kb/article/REVERT_REQUEST'
export const REVERT_ARTICLE_SUCCESS = 'kb/article/REVERT_SUCCESS'
export const REVERT_ARTICLE_FAILURE = 'kb/article/REVERTFAILURE'

export const ARTICLE_SAVE_CLOSE = 'kb/article/SAVE_CLOSE'
export const ARTICLE_CANCEL_CLOSE = 'kb/article/CANCEL_CLOSE'

export const FETCH_ARTICLE_REQUEST = 'kb/article/FETCH_REQUEST'
export const FETCH_ARTICLE_SUCCESS = 'kb/article/FETCH_SUCCESS'
export const FETCH_ARTICLE_FAILURE = 'kb/article/FETCH_FAILURE'

/* Articles */
export const FETCH_ARTICLE_TAGS_REQUEST = 'kb/article/FETCH_TAGS_REQUEST'
export const FETCH_ARTICLE_TAGS_SUCCESS = 'kb/article/FETCH_TAGS_SUCCESS'
export const FETCH_ARTICLE_TAGS_FAILURE = 'kb/article/FETCH_TAGS_FAILURE'
export const ADD_ARTICLE_TAG = 'kb/article/ADD_TAG'

export const REPOSITION_ARTICLES_REQUEST = 'kb/articles/REPOSITION_REQUEST'
export const REPOSITION_ARTICLES_SUCCESS = 'kb/articles/REPOSITION_SUCCESS'
export const REPOSITION_ARTICLES_FAILURE = 'kb/articles/REPOSITION_FAILURE'

export const FETCH_ARTICLES_REQUEST = 'kb/articles/FETCH_REQUEST'
export const FETCH_ARTICLES_SUCCESS = 'kb/articles/FETCH_SUCCESS'
export const FETCH_ARTICLES_FAILURE = 'kb/articles/FETCH_FAILURE'

export const FETCH_ARTICLES_COUNTS_REQUEST = 'kb/articles/FETCH_COUNTS_REQUEST'
export const FETCH_ARTICLES_COUNTS_SUCCESS = 'kb/articles/FETCH_COUNTS_SUCCESS'
export const FETCH_ARTICLES_COUNTS_FAILURE = 'kb/articles/FETCH_COUNTS_FAILURE'

export const FETCH_ARTICLES_VIEWS_REQUEST = 'kb/articles/FETCH_VIEWS_REQUEST'
export const FETCH_ARTICLES_VIEWS_SUCCESS = 'kb/articles/FETCH_VIEWS_SUCCESS'
export const FETCH_ARTICLES_VIEWS_FAILURE = 'kb/articles/FETCH_VIEWS_FAILURE'

export const SET_SELECTED_ARTICLES = 'kb/articles/SET_SELECTED'
export const SET_SELECTED_ALL_ARTICLES = 'kb/articles/SET_SELECTED_ALL'
