import styled from '@emotion/styled'
import themeVars from 'ui_theme/site/globals/site.variables'

export const Logo = styled('img')`
  margin: auto;
  display: block;
`

export const ModalContent = styled('div')`
  padding: ${themeVars.massive} ${props => props.theme.spacing['12']};

  h2 {
    margin-top: 0;
  }
`
