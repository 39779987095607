import React from 'react'

import { useLink } from 'ducks/location/hooks'

import { SETTINGS_INTEGRATIONS_PROVIDER_PAGE } from '../../../../types'

import { Tabs } from '../../shared/SettingsModal'
import ActionsTab from './ActionsTab'
import SidebarTab from './SidebarTab'

const toActionsTab = {
  type: SETTINGS_INTEGRATIONS_PROVIDER_PAGE,
  payload: { provider: 'jira_cloud', tab: null },
}
const toSidebarTab = {
  type: SETTINGS_INTEGRATIONS_PROVIDER_PAGE,
  payload: { provider: 'jira_cloud', tab: 'sidebar' },
}

export default function JiraCloudSettingsForm({ formFields, tab, onChange }) {
  const toActionsTabAction = useLink(toActionsTab, { preserveQuery: true })
  const toSidebarTabAction = useLink(toSidebarTab, { preserveQuery: true })

  return (
    <Tabs>
      <Tabs.Header>
        <Tabs.Link active={!tab} to={toActionsTabAction}>
          Actions
        </Tabs.Link>
        <Tabs.Link active={tab === 'sidebar'} to={toSidebarTabAction}>
          Sidebar
        </Tabs.Link>
      </Tabs.Header>
      <ActionsTab active={!tab} formFields={formFields} onChange={onChange} />
      <SidebarTab
        active={tab === 'sidebar'}
        formFields={formFields}
        onChange={onChange}
      />
    </Tabs>
  )
}
