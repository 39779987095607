import graphql from 'api/graphql'
import { NOT_FOUND, redirect } from 'redux-first-router'
import { arrayMove } from 'react-sortable-hoc'

import * as pages from 'subapps/kb/pages'
import * as types from 'subapps/kb/actions'
import { doFetchArticles } from 'subapps/kb/actions/articles'
import {
  selectActiveCategory,
  selectCurrentCategoriesState,
  selectById,
} from 'subapps/kb/selectors/categories'
import { oauthTokenSelector } from 'selectors/app'
import { selectCurrentKnowledgeBaseId } from 'subapps/kb/selectors/knowledge_bases'
import {
  selectArticlesForCategoryList,
  selectContextForCategoryList,
} from 'subapps/kb/selectors/articles'
import { pick } from 'util/objects'
import { capture } from 'ducks/tracking/actions'
import { doFetchArticlesCounts } from '../articles/doFetchArticlesCounts'
import { doLoadCategoriesTitles } from './doLoadCategoriesTitles'
import { doFetchCategories } from './doFetchCategories'
import { categoryFields } from './categoryFields'
import { doFetchCategoriesTitles } from './doFetchCategoriesTitles'

export function doLoadCategories() {
  return (dispatch, getState) => {
    dispatch(
      doFetchCategories({
        selectedState: selectCurrentCategoriesState(getState()),
        // In order not to confuse user with limited result set,
        // we reset keyword search every time they load categories
        keyword: '',
      })
    )
  }
}

export function doLoadCategoryEditor() {
  return async (dispatch, getState) => {
    const { id, preventReload } = getState().location.payload
    // HACK (jscheel): This is terrible.
    if (preventReload) {
      return
    }
    // Should wait for category before handling kb/articles/FETCH_SUCCESS
    // Otherwise CATEGORY_LIST will not include the article that has just been added into the category
    await dispatch(doFetchCategory(id))
    dispatch(
      doFetchArticles({
        selectedCategoryId: id,
        // In order not to confuse user with limited result set,
        // we reset keyword search every time they load articles
        keyword: '',
        context: 'CATEGORY_LIST',
      })
    )
    dispatch(doLoadCategoriesTitles())
  }
}

export function doFetchCategory(categoryId) {
  return (dispatch, getState) => {
    const state = getState()
    const token = oauthTokenSelector(state)
    const knowledgeBaseId = selectCurrentKnowledgeBaseId(state)

    dispatch({
      type: types.FETCH_CATEGORY_REQUEST,
    })

    // fetch article counts for current kb
    dispatch(doFetchArticlesCounts())

    const query = `
      query CategoryQuery($knowledgeBaseId: ID!, $id: ID!) {
        category(knowledgeBaseId: $knowledgeBaseId, id: $id) {
          ${categoryFields}
        }
      }
    `
    const variables = {
      knowledgeBaseId,
      id: categoryId,
    }

    return graphql(token, query, variables)
      .then(res => {
        const data = res.json.data
        dispatch({
          type: types.FETCH_CATEGORY_SUCCESS,
          data: { category: data.category },
        })
      })
      .catch(err => {
        dispatch({
          type: types.FETCH_CATEGORY_FAILURE,
        })
        if (err.errors[0].status === 404) {
          dispatch({ type: NOT_FOUND })
        }
      })
  }
}

export function doRepositionArticlesByCategory(
  context,
  oldIndex,
  newIndex,
  categoryId
) {
  return (dispatch, getState) => {
    const state = getState()
    const token = oauthTokenSelector(state)
    const knowledgeBaseId = selectCurrentKnowledgeBaseId(state)
    const articles = selectArticlesForCategoryList(state)
    const { perPage, currentPage } = selectContextForCategoryList(state)
    const { articleIds: positionOrderedAllArticleIds } = selectActiveCategory(
      state
    )
    const sortedArticles = arrayMove(articles, oldIndex, newIndex)
    const articleIds = sortedArticles.map(a => a.id)
    // dispatch right away for UI not to glitch
    dispatch({
      type: types.REPOSITION_ARTICLES_REQUEST,
      context,
      data: {
        categoryId,
        articleIds,
      },
    })

    const oldIndexInTotal = perPage * (currentPage - 1) + oldIndex
    const newIndexInTotal = perPage * (currentPage - 1) + newIndex
    const finalAllArticleIds = arrayMove(
      positionOrderedAllArticleIds,
      oldIndexInTotal,
      newIndexInTotal
    )

    dispatch({
      type: types.UPDATE_CATEGORY_ARTICLE_IDS_SUCCESS,
      payload: {
        categoryId,
        articleIds: finalAllArticleIds,
      },
    })

    // then try actual server update
    const mutation = `
      mutation repositionArticles(
        $knowledgeBaseId: ID!,
        $ids: [ID],
        $byCategory: ID
      ){
        repositionArticles(
          knowledgeBaseId: $knowledgeBaseId,
          ids: $ids,
          byCategory: $byCategory)
      }
    `

    const variables = {
      knowledgeBaseId,
      ids: finalAllArticleIds,
      byCategory: categoryId,
    }

    return graphql(token, mutation, variables)
      .then(res => {
        const data = res.json.data
        dispatch({
          type: types.REPOSITION_ARTICLES_SUCCESS,
          data: data.articles,
        })
      })
      .catch(() => {
        dispatch({
          type: types.REPOSITION_ARTICLES_FAILURE,
        })
      })
  }
}

export function doRemoveCategoryArticle(categoryId, articleId) {
  return (dispatch, getState) => {
    dispatch({
      type: types.REMOVE_CATEGORY_ARTICLE,
      data: { categoryId, articleId },
    })
    // Update category, so can show correct category's articles table
    dispatch({
      type: types.UPDATE_CATEGORY_SUCCESS,
      data: { updatedCategory: selectActiveCategory(getState()) },
    })
  }
}

export function doCreateCategory(category, { shouldRedirect = true } = {}) {
  return (dispatch, getState) => {
    const state = getState()
    const token = oauthTokenSelector(state)
    const knowledgeBaseId = selectCurrentKnowledgeBaseId(state)

    dispatch({
      type: types.CREATE_CATEGORY_REQUEST,
      data: category,
    })

    const mutation = `
      mutation CreateCategory(
        $knowledgeBaseId: ID!,
        $input: CategoryInput!
      ){
        createCategory(knowledgeBaseId: $knowledgeBaseId, input: $input) {
          ${categoryFields}
        }
      }
    `

    const variables = {
      knowledgeBaseId,
      input: {
        description: category.description,
        metaDescription: category.metaDescription,
        metaRobots: category.metaRobots,
        ogDescription: category.ogDescription,
        ogImageUrl: category.ogImageUrl,
        ogTitle: category.ogTitle,
        pageTitle: category.pageTitle,
        title: category.title,
      },
    }

    return graphql(token, mutation, variables)
      .then(res => {
        capture('kb create category')
        const data = res.json.data
        const createdCategory = data.createCategory
        const createdCategoryId = createdCategory.id

        dispatch({
          type: types.CREATE_CATEGORY_SUCCESS,
          data: { createdCategory, createdCategoryId },
        })

        if (shouldRedirect) {
          dispatch(
            redirect({
              type: pages.CATEGORY_EDIT_PAGE,
              payload: {
                id: createdCategoryId,
                knowledgeBaseId: category.knowledgeBaseId,
                preventReload: true,
              },
            })
          )
        }
      })
      .catch(err => {
        dispatch({
          type: types.CREATE_CATEGORY_FAILURE,
          data: { err },
        })
      })
  }
}

export function doUpdateCategory(category, categoryId, fields) {
  if (categoryId === 'new') {
    return doCreateCategory(category)
  }
  return (dispatch, getState) => {
    const state = getState()
    const token = oauthTokenSelector(state)
    const knowledgeBaseId = selectCurrentKnowledgeBaseId(state)
    const existingCategory = selectById(state)[categoryId]

    dispatch({
      type: types.UPDATE_CATEGORY_REQUEST,
      data: { category, categoryId },
    })

    const mutation = `
      mutation UpdateCategory(
        $knowledgeBaseId: ID!,
        $id: ID!,
        $input: CategoryInput!
      ){
        updateCategory(
          knowledgeBaseId: $knowledgeBaseId,
          id: $id,
          input: $input
        ){
          ${categoryFields}
        }
      }
    `

    const fieldsForUpdate = [
      'articleIds',
      'description',
      'metaDescription',
      'metaIndex',
      'ogDescription',
      'ogImageUrl',
      'ogTitle',
      'pageTitle',
      'slug',
      'title',
    ]
    const input = pick(fieldsForUpdate, fields)
    if ('metaIndex' in input) {
      // TODO (jscheel): We really should not have this computed property here.
      // Eventually, we either need to move this logic to GQL, or we need to
      // change the data model so that index and follow are stored separately and
      // the final robots tag value is computed at render time in the backend.
      // Currently we also have to decorate the category in selectors to break
      // this value back apart, which is terrible.
      input.metaRobots = `${input.metaIndex ? 'INDEX' : 'NOINDEX'},FOLLOW`
      delete input.metaIndex
    }
    const variables = {
      knowledgeBaseId,
      id: categoryId,
      input,
    }

    return graphql(token, mutation, variables)
      .then(res => {
        capture('kb update category')
        const data = res.json.data
        const updatedCategory = data.updateCategory

        dispatch({
          type: types.UPDATE_CATEGORY_SUCCESS,
          data: { updatedCategory },
        })

        // If category title been updated, refetch all fetched titles
        if (existingCategory.title !== updatedCategory.title) {
          dispatch(doFetchCategoriesTitles())
        }
      })
      .catch(err => {
        dispatch({
          type: types.UPDATE_CATEGORY_FAILURE,
          data: { err },
        })
      })
  }
}

export function doUpdateCurrentCategory(fields) {
  return (dispatch, getState) => {
    const state = getState()
    const category = { ...selectActiveCategory(state), ...fields }
    return dispatch(doUpdateCategory(category, category.id, fields))
  }
}

export function doDeleteCategory(categoryId) {
  return (dispatch, getState) => {
    const state = getState()
    const token = oauthTokenSelector(state)
    const knowledgeBaseId = selectCurrentKnowledgeBaseId(state)

    dispatch({
      type: types.DELETE_CATEGORY_REQUEST,
      data: { categoryId },
    })
    const mutation = `
      mutation destroyCategory($knowledgeBaseId: ID!, $id: ID!) {
        destroyCategory(knowledgeBaseId: $knowledgeBaseId, id: $id)
      }
    `

    const variables = {
      knowledgeBaseId,
      id: categoryId,
    }

    return graphql(token, mutation, variables)
      .then(() => {
        capture('kb delete category')
        dispatch({
          type: types.DELETE_CATEGORY_SUCCESS,
          data: { categoryId },
        })
      })
      .catch(err => {
        dispatch({
          type: types.DELETE_CATEGORY_FAILURE,
          data: { err },
        })
      })
  }
}

export function doPublishCategory(categoryId) {
  return (dispatch, getState) => {
    const state = getState()
    const token = oauthTokenSelector(state)
    const knowledgeBaseId = selectCurrentKnowledgeBaseId(state)

    dispatch({
      type: types.PUBLISH_CATEGORY_REQUEST,
      data: { categoryId },
    })
    const mutation = `
      mutation publishCategory($knowledgeBaseId: ID!, $id: ID!) {
        publishCategory(knowledgeBaseId: $knowledgeBaseId, id: $id) {
          ${categoryFields}
        }
      }
    `
    const variables = {
      knowledgeBaseId,
      id: categoryId,
    }

    return graphql(token, mutation, variables)
      .then(res => {
        capture('kb publish category')
        const data = res.json.data
        const publishedCategory = data.publishCategory
        dispatch({
          type: types.PUBLISH_CATEGORY_SUCCESS,
          data: { publishedCategory },
        })
      })
      .catch(err => {
        dispatch({
          type: types.PUBLISH_CATEGORY_FAILURE,
          data: { err },
        })
      })
  }
}

export function doUnpublishCategory(categoryId) {
  return (dispatch, getState) => {
    const state = getState()
    const token = oauthTokenSelector(state)
    const knowledgeBaseId = selectCurrentKnowledgeBaseId(state)

    dispatch({
      type: types.UNPUBLISH_CATEGORY_REQUEST,
      data: { categoryId },
    })

    const mutation = `
      mutation unpublishCategory($knowledgeBaseId: ID!, $id: ID!) {
        unpublishCategory(knowledgeBaseId: $knowledgeBaseId, id: $id) {
          ${categoryFields}
        }
      }
    `
    const variables = {
      knowledgeBaseId,
      id: categoryId,
    }

    return graphql(token, mutation, variables)
      .then(res => {
        capture('kb unpublish category')
        const data = res.json.data
        const unpublishedCategory = data.unpublishCategory
        dispatch({
          type: types.UNPUBLISH_CATEGORY_SUCCESS,
          data: { unpublishedCategory },
        })
      })
      .catch(err => {
        dispatch({
          type: types.UNPUBLISH_CATEGORY_FAILURE,
          data: { err },
        })
      })
  }
}

export function doRevertCategory(categoryId) {
  return (dispatch, getState) => {
    const state = getState()
    const token = oauthTokenSelector(state)
    const knowledgeBaseId = selectCurrentKnowledgeBaseId(state)

    dispatch({
      type: types.REVERT_CATEGORY_REQUEST,
      data: { categoryId },
    })

    const mutation = `
      mutation revertCategory($knowledgeBaseId: ID!, $id: ID!) {
        revertCategory(knowledgeBaseId: $knowledgeBaseId, id: $id) {
          ${categoryFields}
        }
      }
    `
    const variables = {
      knowledgeBaseId,
      id: categoryId,
    }

    return graphql(token, mutation, variables)
      .then(res => {
        capture('kb revert category')
        const data = res.json.data
        const revertedCategory = data.revertCategory

        dispatch({
          type: types.REVERT_CATEGORY_SUCCESS,
          data: { revertedCategory },
        })
      })
      .catch(err => {
        dispatch({
          type: types.REVERT_CATEGORY_FAILURE,
          data: { err },
        })
      })
  }
}

export function doRepositionCategories(oldIndex, newIndex) {
  return (dispatch, getState) => {
    const state = getState()
    const token = oauthTokenSelector(state)
    const knowledgeBaseId = selectCurrentKnowledgeBaseId(state)

    const { ids } = state.kb.categories || []

    const sortedIds = arrayMove(ids, oldIndex, newIndex)

    // dispatch right away for UI not to glitch
    dispatch({
      type: types.REPOSITION_CATEGORIES_REQUEST,
      data: { sortedIds },
    })

    // then try actual server update
    const mutation = `
      mutation repositionCategories($knowledgeBaseId: ID!, $ids: [ID]) {
        repositionCategories(knowledgeBaseId: $knowledgeBaseId, ids: $ids)
      }
    `

    const variables = {
      knowledgeBaseId,
      ids: sortedIds,
    }

    return graphql(token, mutation, variables)
      .then(res => {
        capture('kb resposition category')
        const data = res.json.data
        dispatch({
          type: types.REPOSITION_CATEGORIES_SUCCESS,
          data: data.categories,
        })
      })
      .catch(() => {
        dispatch({
          type: types.REPOSITION_CATEGORIES_FAILURE,
        })
      })
  }
}

export function doCancel() {
  return dispatch => {
    dispatch({ type: types.CATEGORY_CANCEL_CLOSE })
  }
}

export function doCancelAndClose() {
  return (dispatch, getState) => {
    const state = getState()
    const knowledgeBaseId = selectCurrentKnowledgeBaseId(state)

    dispatch(doCancel())
    dispatch({ type: pages.CATEGORIES_PAGE, payload: { knowledgeBaseId } })
  }
}

export function doSaveAndClose() {
  return (dispatch, getState) => {
    const state = getState()
    const knowledgeBaseId = selectCurrentKnowledgeBaseId(state)

    dispatch({ type: types.CATEGORY_SAVE_CLOSE })
    dispatch({
      type: pages.CATEGORIES_PAGE,
      payload: { knowledgeBaseId },
    })
  }
}
