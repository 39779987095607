import { v4 as uuidV4 } from 'uuid'

let tabId = null
try {
  tabId = sessionStorage.getItem('tabId')
  if (!tabId) tabId = uuidV4()
  sessionStorage.setItem('tabId', tabId)
} catch (e) {
  // IE11 does not support session storage,
  // so storing in memory will need to suffice
  tabId = uuidV4()
}

export function getTabId() {
  return tabId
}
