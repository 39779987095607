import React, { useState, useMemo, useCallback, useRef } from 'react'
import { useSelector } from 'react-redux'
import { func, shape, string } from 'prop-types'
import { useWatch } from 'react-hook-form'
import Dropdown from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import { selectCurrentPaidActiveAgentsWithCurrentUserSort } from 'ducks/agents/selectors'
import { selectGroupsSortedByName } from 'selectors/app/groups'
import { selectMailboxesIncludingInaccessible } from 'selectors/mailboxes/selectMailboxesIncludingInaccessible'
import MessageTemplateEditor from 'subapps/settings/components/MessageTemplateEditor'
import RuleNote from 'components/App/DesktopView/CommunicationForms/Reply/RuleNote'
import EmailReply from 'subapps/settings/components/RuleValue/EmailReply'
import { omit } from 'util/objects'
import { propFunc } from 'util/functions'

import RuleValue from '../RuleValue'
import { actionVariableTypes, subjectVariableTypes } from './data'
import { styles as conditionStyles } from '../Condition/Condition.styles'
import MenuWithSearch, {
  useShowHeaderShadow,
  useSearchItems,
  styles as menuWithSearchStyles,
} from '../MenuWithSearch'
import {
  buildPossibleValueOptions,
  getActionKey,
  selectValue,
  selectParameter,
} from './util'
import AutomationItem from '../AutomationItem'
import { styles as automationItemStyles } from '../AutomationItem/styles'
import { isWrapAllowed } from '../Condition/util'

const ActionWithSearch = ({
  variables,
  onRemoveAction,
  onInput,
  onBlur,
  onActionChange,
  index,
  name,
  control,
  itemKey,
  parameterSectionTitles,
  actionErrorMessage,
}) => {
  const action = useWatch({
    control,
    name,
  })

  const { parameters: allParameters = {}, values = {} } = variables
  const parameterKey = action?.type
  const toAgentKey = action?.to_agent
  const [initialParameterKey] = useState(parameterKey)

  const searchRef = useRef()
  const [filteredParameters, setFilteredParameters] = useState({})
  const [search, setSearch] = useState('')
  const [parameterMenuVisible, setParameterMenuVisibleChange] = useState(
    !!action && !parameterKey
  )
  const [isAutomationItemFocused, setIsAutomationItemFocused] = useState(false)
  const { handleScroll, shouldShowHeaderShadow } = useShowHeaderShadow(
    parameterMenuVisible
  )

  const agents = useSelector(selectCurrentPaidActiveAgentsWithCurrentUserSort)
  const groups = useSelector(selectGroupsSortedByName)
  const mailboxes = useSelector(selectMailboxesIncludingInaccessible)

  const {
    label,
    dataType,
    paramType,
    dataOperator,
    toAgentDataType,
    allowSelectAssigned,
  } =
    values[parameterKey] || {}
  const wrapAllowed = isWrapAllowed(toAgentDataType || dataType)

  const possibleOptionsForParameterKey = useCallback(
    key => buildPossibleValueOptions(values, key, agents, groups, mailboxes),
    [agents, groups, mailboxes, values]
  )

  const possibleOptionsArray = useMemo(
    () => possibleOptionsForParameterKey(parameterKey),
    [parameterKey, possibleOptionsForParameterKey]
  )

  const selectedParameter = useMemo(
    () => selectParameter(allParameters, parameterKey, action?.value),
    [parameterKey, allParameters, action?.value]
  )

  const selectedValue = useMemo(
    () => {
      return Array.isArray(possibleOptionsArray)
        ? selectValue(possibleOptionsArray, action?.value)
        : null
    },
    [possibleOptionsArray, action?.value]
  )

  // Handle select parameter
  const handleSelectParameter = useCallback(
    key => {
      const [type, actionValue] = key.split('-')
      const valueOptions = values[type]?.options || []

      let lastValue = actionValue || valueOptions[0]?.value
      let lastToAgent = null

      const newPossibleOptionsArray = possibleOptionsForParameterKey(type)

      if (Array.isArray(newPossibleOptionsArray)) {
        lastValue = newPossibleOptionsArray[0]?.value
      }

      if (
        ['send_email_reply', 'add_note', 'forward_conversation'].includes(key)
      ) {
        lastToAgent = null
      }

      // Pass select values to action
      onActionChange(
        {
          ...action,
          type,
          value: lastValue,
          to_agent: lastToAgent,
          messageTemplate: null,
        },
        index
      )
      setParameterMenuVisibleChange(false)
    },
    [action, index, onActionChange, values, possibleOptionsForParameterKey]
  )

  // Handle select value
  const handleSelectValueKey = useCallback(
    key => {
      onActionChange(
        {
          ...action,
          value: key,
        },
        index
      )
    },
    [action, onActionChange, index]
  )

  const handleMessageTemplateBodyChange = useCallback(
    body => {
      onActionChange(
        {
          ...action,
          value: body,
          messageTemplate: {
            ...action?.messageTemplate,
            body,
          },
        },
        index
      )
    },
    [action, onActionChange, index]
  )

  const handleMessageTemplateAttachmentsChange = useCallback(
    ({ pendingUploads, attachments }) => {
      onActionChange(
        {
          ...action,
          messageTemplate: {
            ...action?.messageTemplate,
            ...(pendingUploads !== undefined ? { pendingUploads } : {}),
            ...(attachments !== undefined ? { attachments } : {}),
          },
        },
        index
      )
    },
    [action, onActionChange, index]
  )

  const handleSelectToAgentKey = useCallback(
    key => {
      onActionChange(
        {
          ...action,
          to_agent: key,
        },
        index
      )
    },
    [action, onActionChange, index]
  )

  const handleDelete = useCallback(
    () => {
      onRemoveAction(action, index)
    },
    [action, onRemoveAction, index]
  )

  const handleInput = useCallback(
    e => {
      const newValue = e.target.value
      onInput(
        {
          ...action,
          value: newValue,
        },
        index,
        false
      )
    },
    [onInput, action, index]
  )

  const handleBlur = useCallback(
    () => {
      onBlur(index)
    },
    [onBlur, index]
  )

  const handleChangeSearch = useCallback(({ target }) => {
    setSearch(target.value)
  }, [])

  const parameters = useMemo(
    () => {
      return Object.keys(allParameters).reduce((accumulator, key) => {
        // eslint-disable-next-line no-param-reassign
        accumulator[key] = allParameters[key]
          .filter(p => !p.deprecated || p.type === initialParameterKey)
          .map(p => omit(['deprecated'], p))
        return accumulator
      }, {})
    },
    [allParameters, initialParameterKey]
  )

  useSearchItems({
    data: parameters,
    search,
    onSearchResultChange: setFilteredParameters,
  })

  const handleParametersMenuVisibleChange = useCallback(
    visible => {
      if (!visible && search) {
        setSearch('')
      }
      setParameterMenuVisibleChange(visible)
    },
    [search]
  )

  const handleOpenParameterMenu = useCallback(
    () => {
      if (!parameterMenuVisible) {
        setParameterMenuVisibleChange(true)
      }
    },
    [parameterMenuVisible]
  )

  const actionIcon = useMemo(
    () => {
      const actionSectionKey = Object.keys(variables.parameters).find(
        sectionKey => {
          return variables.parameters[sectionKey].some(
            item => item.type === parameterKey
          )
        }
      )
      return parameterSectionTitles[actionSectionKey]?.icon
    },
    [parameterKey, variables, parameterSectionTitles]
  )

  const header = useMemo(
    () => (
      <MenuWithSearch.Search
        placeholder="Search actions..."
        value={search}
        onChange={handleChangeSearch}
        ref={searchRef}
        shouldFocus={parameterMenuVisible}
      />
    ),
    [search, handleChangeSearch, parameterMenuVisible]
  )

  const parametersMenu = useMemo(
    () => (
      <MenuWithSearch
        focusElementRef={searchRef}
        search={search}
        className="grui pt-3 pb-8"
        onScroll={handleScroll}
      >
        {Object.keys(filteredParameters)
          ?.filter(key => filteredParameters[key]?.length)
          .map(key => {
            const { title, icon } = parameterSectionTitles[key] || {}
            return (
              <div key={key}>
                <MenuWithSearch.Header icon={icon}>
                  {title}
                </MenuWithSearch.Header>
                {filteredParameters[key]?.map(
                  ({
                    value: actionValue,
                    type,
                    name: actionName,
                    tooltip,
                    product,
                    feature,
                  }) => (
                    <MenuWithSearch.Item
                      key={getActionKey(type, actionValue)}
                      itemKey={getActionKey(type, actionValue)}
                      tooltip={tooltip}
                      onSelect={handleSelectParameter}
                      selectedKey={getActionKey(
                        parameterKey,
                        actionValue && action?.value
                      )}
                      visible={parameterMenuVisible}
                      feature={feature}
                      product={product}
                      checkProductFeatureLimit
                      type="action"
                    >
                      {actionName}
                    </MenuWithSearch.Item>
                  )
                )}
              </div>
            )
          })}
      </MenuWithSearch>
    ),
    [
      filteredParameters,
      parameterSectionTitles,
      handleSelectParameter,
      parameterKey,
      parameterMenuVisible,
      search,
      handleScroll,
      action?.value,
    ]
  )

  return (
    <div>
      <AutomationItem
        icon={actionIcon}
        onDelete={handleDelete}
        shouldWrapInnerItems={wrapAllowed}
        onClick={handleOpenParameterMenu}
        onBlur={propFunc(setIsAutomationItemFocused, false)}
        onFocus={propFunc(setIsAutomationItemFocused, true)}
        isError={!!actionErrorMessage}
      >
        <div css={conditionStyles.dropdownContainer} title={selectedValue}>
          <Dropdown
            header={header}
            overlay={parametersMenu}
            css={[
              menuWithSearchStyles.dropdownContainer,
              shouldShowHeaderShadow && menuWithSearchStyles.showHeaderShadow,
            ]}
            onVisibleChange={handleParametersMenuVisibleChange}
            visible={parameterMenuVisible}
            autoHeight
            // Don't let dropdown's arrow event handlers handle the keyboard events
            // MenuWithSearch will do it
            isNavByArrowsDisabled
            position="top-start"
          >
            <span>{selectedParameter || 'Select an action...'}</span>
          </Dropdown>
        </div>

        {parameterKey &&
          values[parameterKey] &&
          dataType !== 'SUBJECT' && (
            <RuleValue
              value={possibleOptionsArray}
              onDropdownSelect={
                toAgentDataType ? handleSelectToAgentKey : handleSelectValueKey
              }
              selectedValue={selectedValue}
              onInputBlur={handleBlur}
              onInputChange={handleInput}
              valueKey={toAgentDataType ? toAgentKey : action?.value}
              dataType={toAgentDataType || dataType}
              paramType={paramType}
              itemKey={itemKey}
              actionType={action?.type}
              errorMessage={actionErrorMessage}
              // Remount this component if parameterKey is changed
              // then autoFocus will be working for new inputs
              key={parameterKey}
              dataOperator={dataOperator}
              allowSelectAssigned={allowSelectAssigned}
              hasMinWidth
              isAutomationItemFocused={isAutomationItemFocused}
            />
          )}

        {parameterKey === 'forward_conversation' && (
          <EmailReply
            onDropdownSelect={handleSelectToAgentKey}
            valueKey={toAgentKey}
            actionType={action?.type}
            className="automation-full-field"
          />
        )}
      </AutomationItem>
      {/* Show editor on a new line */}
      {parameterKey &&
        dataType &&
        dataType === 'MESSAGE_TEMPLATE' && (
          <MessageTemplateEditor
            // eslint-disable-next-line camelcase
            type="Rule"
            id={action?.rule_reply_template}
            entityType="ruleActionMessageTemplate"
            open={open}
            mode="managed"
            showPluginImageUpload
            showPluginSourceEditing
            showAddAttachment
            showPluginVariablesPlaceholder
            pluginVariablesPlaceholderTypes={actionVariableTypes}
            subjectVariableTypes={subjectVariableTypes}
            isFormInputSubjectHidden
            isFormInputSubjectWithVariablesHidden={
              parameterKey !== 'send_email_to_requester'
            }
            formInputSubjectName={`actions.${index}.messageTemplate.subject`}
            formInputBodyName={`actions.${index}.messageTemplate.body`}
            formInputAttachmentIdsName={`actions.${index}.messageTemplate.attachments`}
            formInputPendingUploadsName={`actions.${index}.messageTemplate.pendingUploads`}
            formInputBodyLabel={null}
            onMessageChange={handleBlur}
            autoFocus={!action?.messageTemplate?.body}
          />
        )}
      {parameterKey &&
        dataType &&
        dataType === 'NOTE' && (
          <RuleNote
            id={action?.rule_reply_template}
            value={action?.value}
            onChange={handleMessageTemplateBodyChange}
            onAttachmentsChange={handleMessageTemplateAttachmentsChange}
            pendingUploads={action?.messageTemplate?.pendingUploads}
          />
        )}
      {parameterKey &&
        dataType &&
        dataType === 'SUBJECT' && (
          <RuleValue
            value={possibleOptionsArray}
            onInputBlur={handleBlur}
            onInputChange={handleInput}
            valueKey={action.value}
            dataType={dataType}
            paramType={paramType}
            itemKey={itemKey}
            actionType={action.type}
            errorMessage={actionErrorMessage}
            // Remount this component if parameterKey is changed
            // then autoFocus will be working for new inputs
            key={parameterKey}
            dataOperator={dataOperator}
            allowSelectAssigned={allowSelectAssigned}
            hasMinWidth
            isAutomationItemFocused={isAutomationItemFocused}
            subjectVariableTypes={subjectVariableTypes}
          />
        )}
      {parameterKey &&
        dataType &&
        dataType === 'TEXT' && (
          <RuleValue
            label={label}
            value={possibleOptionsArray}
            onInputBlur={handleBlur}
            onInputChange={handleInput}
            valueKey={action.value}
            dataType={dataType}
            paramType={paramType}
            itemKey={itemKey}
            actionType={action.type}
            errorMessage={actionErrorMessage}
            // Remount this component if parameterKey is changed
            // then autoFocus will be working for new inputs
            key={parameterKey}
            dataOperator={dataOperator}
            allowSelectAssigned={allowSelectAssigned}
            isAutomationItemFocused={isAutomationItemFocused}
            className="grui mt-8 mw-100"
          />
        )}
      <div css={[automationItemStyles.operator]}>AND</div>
    </div>
  )
}

ActionWithSearch.propTypes = {
  variables: shape({}),
  onRemoveAction: func,
  onInput: func,
  onBlur: func,
  onActionChange: func,
  actionErrorMessage: string,
}

ActionWithSearch.defaultProps = {
  variables: {},
  onRemoveAction() {},
  action: {},
  onInput() {},
  onBlur() {},
  onActionChange() {},
  onEditorData() {},
  actionErrorMessage: undefined,
}

export default React.memo(ActionWithSearch)
