export const USERS_RANGES = {
  everyone: 'everyone',
  certainMembers: 'users',
  me: 'me',
  // For AddAgents in Channels
  certainTeams: 'group',
}

export const userRanges = [
  {
    id: USERS_RANGES.certainMembers,
    name: `Select ${app.t('agents')}`,
  },
  {
    id: USERS_RANGES.certainTeams,
    name: `Select ${app.t('teams')}`,
  },
  {
    id: USERS_RANGES.everyone,
    name: 'Everyone',
  },
  {
    id: USERS_RANGES.me,
    name: 'Only me',
  },
]
