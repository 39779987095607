import {
  CREATE_MAILBOX_REQUEST,
  CREATE_MAILBOX_FAILURE,
  CONVERT_MAILBOX_TO_DEMO_FAILURE,
} from 'constants/action_types'
import {
  ERR_POPUP_BLOCKED,
  ERR_USER_CLOSED_POPUP,
  ERR_USER_REJECTED,
  ERR_UNKNOWN,
} from 'util/oauth_window'
import {
  CONNECT_CHANNEL_SUCCESS,
  CONNECT_CHANNEL_FAILURE,
} from 'subapps/onboarding/ducks/channels'
import {
  CHANNEL_OVERVIEW_PAGE,
  CUSTOMER_INFORMATION_PAGE,
  ADD_CHANNEL_PAGE,
  COMPLETED_PAGE,
} from '../../pages'
import { ONBOARDING_DEMO_MAILBOX_CREATED } from './types'

const reducers = {}

const defaultAddChannelState = {
  backPageType: CHANNEL_OVERVIEW_PAGE,
  hasCreatedChannel: false,
  mailboxError: null,
}

reducers[COMPLETED_PAGE] = state => {
  return {
    ...state,
    removeDemoMailbox: false,
    demoMailboxId: null,
  }
}

reducers[CHANNEL_OVERVIEW_PAGE] = state => {
  return {
    ...state,
    backPageType: CHANNEL_OVERVIEW_PAGE,
    hasCreatedChannel: true,
    // You can only reach the overview page by adding atleast one channel.
    // When you've created atleast one channel, you no longer need a demo mailbox

    // TODO: We should replace this check with something like channels.lenght > 0
    //       when we've implemented the channels
    removeDemoMailbox: !!state.demoMailboxId,
  }
}

reducers[CUSTOMER_INFORMATION_PAGE] = state => {
  return {
    ...state,
    backPageType: CUSTOMER_INFORMATION_PAGE,
  }
}

// eslint-disable-next-line no-multi-assign
reducers[ADD_CHANNEL_PAGE] = reducers[CREATE_MAILBOX_REQUEST] = reducers[
  CONNECT_CHANNEL_SUCCESS
] = state => {
  return {
    ...state,
    mailboxError: null,
  }
}

// eslint-disable-next-line no-multi-assign
reducers[CREATE_MAILBOX_FAILURE] = reducers[CONVERT_MAILBOX_TO_DEMO_FAILURE] = (
  state,
  { payload: { error } = {} }
) => {
  return {
    ...state,
    mailboxError: error,
  }
}

reducers[CONNECT_CHANNEL_FAILURE] = (state, { payload: { error } = {} }) => {
  switch (error) {
    case ERR_POPUP_BLOCKED:
      return {
        ...state,
        mailboxError: 'Your popup blocker prevented the window from opening.',
      }
    case ERR_USER_CLOSED_POPUP:
    case ERR_USER_REJECTED:
      // NOTE (jscheel): We don't care about these errors.
      return state
    case ERR_UNKNOWN:
      return {
        ...state,
        mailboxError: 'There was a problem connecting the channel.',
      }
    default:
      return {
        ...state,
        mailboxError: error,
      }
  }
}

reducers[ONBOARDING_DEMO_MAILBOX_CREATED] = (
  state,
  { payload: { id } = {} }
) => {
  return {
    ...state,
    demoMailboxId: id,
  }
}

export default function selectAddChannelReducer(
  state = defaultAddChannelState,
  action
) {
  const handler = reducers[action.type]
  if (handler) return handler(state, action)
  return state
}
