import { css } from '@emotion/react'
import { rem } from '@groovehq/internal-design-system/lib/util'
import { FormControl } from '@groovehq/internal-design-system/lib/components/Field/Field'

const dropdownContainer = css`
  & > * {
    width: 100%;
  }
`

const dropdownBtn = css`
  min-width: unset;
  width: 100%;
  & > * {
    padding-right: ${rem(10)};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

const field = theme => css`
  & > ${FormControl} input {
    font-size: ${theme.fontSize.base};
    padding-top: ${rem(5)};
    padding-bottom: ${rem(5)};
  }
`

const dateField = theme => css`
  & .ui.input input {
    font-size: ${theme.fontSize.base};
    padding-top: ${rem(5)};
    padding-bottom: ${rem(5)};
    padding-left: ${rem(13)};
  }

  .ui.dropdown .menu {
    margin-top: ${rem(4)};
    margin-bottom: ${rem(4)};
  }

  .DatePicker {
    top: ${rem(35)};
  }
`

export const styles = {
  dropdownContainer,
  dropdownBtn,
  field,
  dateField,
}
