import React, { useCallback, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { useDataTable } from 'ducks/tables/hooks'
import Table from '@groovehq/internal-design-system/lib/components/Table/Table'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import Checkbox from '@groovehq/internal-design-system/lib/components/Checkbox/Checkbox'
import { Trash } from '@groovehq/internal-design-system/lib/assets/icons'
import { doFetchCannedRepliesV1 } from 'ducks/cannedReplies/operations'
import { SETTINGS_CANNED_REPLY_TABLE_ID } from 'ducks/tables/ids'
import {
  DRAWER_TYPE_CANNED_REPLIES_UPDATE,
  DRAWER_TYPE_CANNED_REPLIES_DELETE,
  DRAWER_TYPE_CANNED_REPLIES_CHANGE_CATEGORY_UPDATE,
} from 'ducks/drawers/types'
import { useDrawer } from 'ducks/drawers/hooks'
import { buildDrawerQueryParam } from 'ducks/drawers/util'
import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'
import { useFeature } from 'ducks/billing/hooks'
import { FEATURE_INBOX_MAX_CANNED_REPLIES } from 'ducks/billing/featureTypes'
import CategorySelection from '../CannedReplyCategorySelection'
import useColumns from './useColumns'
import { styles } from './styles'
import PlanLimitationCard from '../PlanLimitationCard'

const CannedReplyDataTable = ({ compact } = {}) => {
  const {
    pagination: {
      currentDenormalizedEntities: cannedReplies,
      gotoPage,
      changePageSize,
    },
    pagination,
    filtering: { changeFilterDelayed, changeFilter },
    sorting: { changeSort },
    sorting,
    query: { categoryId, search },
    queryId,
    isLoading,
    shouldLoad,
    isError,
    selection: {
      ids: selectedIds,
      mode: selectionMode,
      handleOnSelectedRowIdsChange,
      handleOnSelectedModeChange,
    },
    scroll,
  } = useDataTable(
    SETTINGS_CANNED_REPLY_TABLE_ID,
    'cannedReply',
    doFetchCannedRepliesV1,
    {
      pagination: {
        defaultPageSize: 20,
        defaultCursor: 1,
        showSizeChanger: true,
        pageSizeOptions: [10, 20, 30],
        filterDelay: 1000,
        parseToIntKeys: ['cursor'],
        defaultOrderBy: 'NAME_ASC',
      },
      sorting: {
        transformSort: 'lowercase',
      },
      scroll: {
        persistScrollPosition: true,
      },
    }
  )

  const { canUseFeature, featureLimit: cannedReplyLimit } = useFeature(
    FEATURE_INBOX_MAX_CANNED_REPLIES
  )
  const showPlanLimitationForCannedReplies = !canUseFeature
  const currentUser = useSelector(selectCurrentUser)
  const currentUserIsManager = ['admin', 'owner'].includes(currentUser.role)
  const columns = useColumns({ compact, currentUser })
  const tableActionsRef = useRef({})

  // We intentially take the initial value and never change it because we dont actually want
  // the default value to ever change
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultGlobalFilter = useMemo(() => search || null, [])

  const handleOnTableStateChange = useCallback(
    (newState, action = {}) => {
      const { type } = action || {}
      if (type === 'gotoPage') {
        const { pageIndex } = action
        gotoPage(pageIndex + 1)
      } else if (type === 'setPageSize') {
        const { pageSize } = action
        changePageSize(pageSize)
      } else if (type === 'toggleSortBy' || type === 'setSortBy') {
        const { id, desc } = newState.sortBy[0] || {}
        changeSort(id ? `${id.toUpperCase()}_${desc ? 'DESC' : 'ASC'}` : null)
      } else if (type === 'setGlobalFilter') {
        const { filterValue } = action
        // When setting filter, debounce for 1 second, but when removing a filter
        // trigger that immediately
        if (filterValue) {
          changeFilterDelayed('search', filterValue, {
            resetPage: true,
            persist: true,
          })
        } else {
          changeFilter('search', filterValue, {
            resetPage: true,
            persist: true,
          })
        }
      }
    },
    [changeSort, changeFilterDelayed, changeFilter, gotoPage, changePageSize]
  )

  const { openDrawer: openUpdateDrawer } = useDrawer(
    DRAWER_TYPE_CANNED_REPLIES_UPDATE
  )

  const { drawerId: deleteDrawerId, openDrawer: openDeleteDrawer } = useDrawer(
    DRAWER_TYPE_CANNED_REPLIES_DELETE
  )

  const {
    drawerId: changeCategoryDrawerId,
    openDrawer: openCategoryDrawer,
  } = useDrawer(DRAWER_TYPE_CANNED_REPLIES_CHANGE_CATEGORY_UPDATE)

  const handleOnSelectCategory = useCallback(
    cId => {
      changeFilter('categoryId', cId === 'all' ? null : cId, {
        resetPage: true,
        persist: true,
      })
    },
    [changeFilter]
  )

  const handleClearSelection = useCallback(() => {
    const { current: { toggleAllRowsSelected } = {} } = tableActionsRef
    if (toggleAllRowsSelected) {
      toggleAllRowsSelected(false, 'ids')
    }
  }, [])

  const handleCreate = useCallback(
    () => {
      openUpdateDrawer('new', {
        additionalProps: {
          queryId,
        },
      })
    },
    [openUpdateDrawer, queryId]
  )

  const handleDelete = useCallback(
    id => {
      openDeleteDrawer(id, {
        query: {
          ...buildDrawerQueryParam(deleteDrawerId, 'drawerDeleteMode', 'ids'),
        },
        additionalProps: {
          queryId,
          onRemove: handleClearSelection,
        },
      })
    },
    [openDeleteDrawer, deleteDrawerId, handleClearSelection, queryId]
  )
  const handleDeleteAll = useCallback(
    () => {
      openDeleteDrawer(selectedIds.join(','), {
        query: {
          ...buildDrawerQueryParam(
            deleteDrawerId,
            'drawerDeleteMode',
            selectionMode === 'all' ? 'query' : 'ids'
          ),
        },
        additionalProps: {
          queryId,
          onRemove: handleClearSelection,
        },
      })
    },
    [
      selectedIds,
      selectionMode,
      openDeleteDrawer,
      deleteDrawerId,
      queryId,
      handleClearSelection,
    ]
  )

  const handleEdit = useCallback(
    id => {
      openUpdateDrawer(id, {
        additionalProps: {
          queryId,
        },
      })
    },
    [openUpdateDrawer, queryId]
  )

  const handleChangeCategory = useCallback(
    () => {
      openCategoryDrawer(selectedIds.join(','), {
        query: {
          ...buildDrawerQueryParam(
            changeCategoryDrawerId,
            'drawerChangeCategoryMode',
            selectionMode === 'all' ? 'query' : 'ids'
          ),
        },
        additionalProps: {
          queryId,
          onChange: handleClearSelection,
        },
      })
    },
    [
      selectedIds,
      selectionMode,
      openCategoryDrawer,
      changeCategoryDrawerId,
      queryId,
      handleClearSelection,
    ]
  )

  const controls = useCallback(
    (_, getToggleAllPageRowsSelectedProps) => {
      const {
        checked,
        indeterminate,
        onChange,
      } = getToggleAllPageRowsSelectedProps()

      if (!checked && !indeterminate) {
        return (
          <div>
            <CategorySelection
              key="canned-reply-category-selection"
              css={styles.dropdownBtn}
              size="small"
              onSelectCategory={handleOnSelectCategory}
              categoryId={categoryId}
              showAll
            />
          </div>
        )
      }
      return (
        <>
          <Checkbox
            id={uuidv4()}
            onChange={onChange}
            checked={checked}
            indeterminate={indeterminate}
            className="grui ml-10"
          />
          <span className="grui ml-12">
            <Button
              type="tertiary"
              size="small"
              css={styles.changeBtn}
              onClick={handleChangeCategory}
            >
              Move to another category
            </Button>
          </span>
          {currentUserIsManager && (
            <span className="grui ml-5">
              <Button
                type="tertiary"
                size="small"
                customIcon={<Trash />}
                onClick={handleDeleteAll}
              >
                Delete
              </Button>
            </span>
          )}
        </>
      )
    },
    [
      categoryId,
      handleOnSelectCategory,
      handleDeleteAll,
      handleChangeCategory,
      currentUserIsManager,
    ]
  )

  const emptyContent = (
    <Table.Empty
      title={`No ${app.t('canned_replies')}`}
      subtitle={`You have not added any ${app.t(
        'canned_replies'
      )} to this category yet.`}
    >
      <Button onClick={handleCreate}>Create new {app.t('canned_reply')}</Button>
    </Table.Empty>
  )

  return (
    <>
      {showPlanLimitationForCannedReplies && (
        <PlanLimitationCard
          className="grui mb-9 mt-3"
          title={`Need to create another ${app.t('Canned_Reply')}`}
          trackingFeatureType={FEATURE_INBOX_MAX_CANNED_REPLIES}
        >
          You’ve reached your{' '}
          <strong>
            limit of {cannedReplyLimit} {app.t('canned_replies')}
          </strong>{' '}
          on your account.
          <br />
          To add more, please upgrade your account.
        </PlanLimitationCard>
      )}
      <Table
        separate
        controls={controls}
        columns={columns}
        css={compact ? styles.tableCompact : styles.tableStandard}
        data={cannedReplies}
        showPagination
        pagination={pagination}
        onTableStateChange={handleOnTableStateChange}
        sorting={sorting}
        hasSearch
        searchPlaceholder={app.t('canned_replies')}
        emptyContent={emptyContent}
        loading={shouldLoad || isLoading}
        defaultGlobalFilter={defaultGlobalFilter}
        emptyHintVisible={false}
        hasError={isError}
        updateData={handleEdit}
        deleteData={handleDelete}
        onEdit={handleEdit}
        dataType="canned replies"
        onSelectedRowIdsChange={handleOnSelectedRowIdsChange}
        onSelectedModeChange={handleOnSelectedModeChange}
        tableActionsRef={tableActionsRef}
        scroll={scroll}
        disableSortRemove
      />
    </>
  )
}

CannedReplyDataTable.displayName = 'CannedReplyDataTable'

export default CannedReplyDataTable
