import { produce } from 'immer'
import merge from 'deepmerge'
import { emptyObj } from 'util/objects'

// Given an entity type this reducer factory will retrieve any matching
// entities on any action and place them in its state branch.
function overwriteMerge(destinationArray, sourceArray) {
  return sourceArray
}
export default function createEntityReducer(
  entityType,
  { reduce, transformEntity = x => x } = {}
) {
  return produce((draftState, action) => {
    const {
      entities = {},
      meta: { mergeEntities = false, removeEntities = false } = {},
    } =
      action || {}
    if (!entities) return draftState
    const entitiesOfType = entities[entityType]
    if (entitiesOfType) {
      Object.values(entitiesOfType).forEach(entity => {
        const { id } = entity
        const transformedEntity = transformEntity(entity)
        const newEntity = mergeEntities
          ? merge(draftState[id] || {}, transformedEntity, {
              arrayMerge: overwriteMerge,
              clone: false,
            })
          : transformedEntity
        // eslint-disable-next-line no-param-reassign
        if (removeEntities) {
          // eslint-disable-next-line no-param-reassign
          delete draftState[id]
        } else {
          // eslint-disable-next-line no-param-reassign
          draftState[id] = newEntity
        }
      })
    }
    return reduce ? reduce(draftState, action) : draftState
  }, emptyObj)
}
