import { v4 as uuidV4 } from 'uuid'

export const EDITOR_MAX_INLINE_IMAGE_SIZE = 3 * 1024 * 1024 // 3mb
export const EDITOR_MAX_UPLOAD_SIZE = 20 * 1024 * 1024 // 20mb
// Converts a File object (i.e. Blob) to a plain object (which is needed by redux)
export function toPlainObject(fileBlob) {
  const {
    lastModified,
    lastModifiedDate,
    name,
    size,
    type,
    webkitRelativePath,
  } = fileBlob
  return {
    lastModified,
    lastModifiedDate,
    name,
    size,
    type,
    webkitRelativePath,
  }
}

export function normalizeAttachment(attachment) {
  const file = attachment.file || {}
  return {
    ...attachment,
    file_name:
      (file && file.name) ||
      attachment.file_name ||
      attachment.filename ||
      file.type,
    file_type: attachment.file_type || attachment.content_type || file.type,
    file_size: attachment.file_size || file.size,
    key: attachment.key || attachment.id,
    url: attachment.expiringUrl || attachment.url,
  }
}

export function convertDataUrlToFile(dataUrl, mimeType, fileName) {
  if (
    !dataUrl ||
    !(typeof dataUrl === 'string') ||
    !dataUrl.startsWith('data:')
  ) {
    return Promise.reject('Not a valid dataUrl')
  }

  const blobFileName = fileName || uuidV4()
  return fetch(dataUrl)
    .then(res => res.blob())
    .then(blob => new File([blob], blobFileName, { type: mimeType }))
}

export function readAsDataURL(file) {
  const reader = new FileReader()
  return new Promise(resolve => {
    reader.onloadend = () => {
      resolve(reader.result)
    }

    if (file) {
      reader.readAsDataURL(file)
    } else {
      resolve(null)
    }
  })
}
