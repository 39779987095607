import React, { useCallback, useRef } from 'react'
import cn from 'classnames'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import Close from '@groovehq/internal-design-system/lib/assets/icons/Close'
import AddButton from './Button'
import { styles } from './styles'

const AutomationItem = ({
  children,
  className,
  icon: Icon,
  onDelete,
  shouldWrapInnerItems = true,
  onClick,
  onBlur,
  onFocus,
  isError,
}) => {
  const containerRef = useRef()
  const handleDelete = useCallback(
    e => {
      e.stopPropagation()
      onDelete(e)
    },
    [onDelete]
  )

  const handleBlur = useCallback(
    e => {
      if (!containerRef?.current.contains(e.relatedTarget) && onBlur) {
        onBlur(e)
      }
    },
    [onBlur]
  )

  return (
    <div
      css={styles.base}
      className={cn(className, { error: isError })}
      tabIndex={0}
      onClick={onClick}
      onBlur={handleBlur}
      onFocus={onFocus}
      ref={containerRef}
    >
      <div
        className={cn('grui flex items-center', {
          'flex-wrap': shouldWrapInnerItems,
        })}
        css={styles.content}
      >
        {Icon && (
          <Icon
            className="grui -ml-2 -mr-3 automation-item-icon"
            css={styles.icon}
          />
        )}
        {children}
      </div>
      {onDelete && (
        <Button
          type="icon"
          size="small"
          className="grui -mr-3 ml-3 mt-3 self-start"
          css={styles.removeBtn}
          onClick={handleDelete}
        >
          <Close />
        </Button>
      )}
    </div>
  )
}

AutomationItem.Button = AddButton

export default AutomationItem
